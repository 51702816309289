import {
  Component, OnInit, Input, ViewChild,
  Output, ElementRef, EventEmitter, AfterViewInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgForm
} from '@angular/forms';

import { JwtService } from '../../../services/jwt/jwt.service';
import { EmailSenderIdentityService } from '../../../services/email-sender-identity/email-sender-identity.service';
@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild('campaignForm') form: NgForm;

  @Output() formChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() valid: string;

  @Input()
  template: any;

  @Input()
  templateHtml: any;

  testData;

  @Input()
  market;

  @Input()
  data;

  @Input()
  model;

  loading = true;
  dataKeys = [];

  senderDataKeys = [
    {
      key: 'Sender_Name',
      displayKey: 'Name',
      required: true
    },
    {
      key: 'Sender_Company',
      displayKey: 'Company',
      required: true
    },
    {
      key: 'Sender_Title',
      displayKey: 'Title',
      required: true
    }, {
      key: 'Sender_Email',
      displayKey: 'Email',
      required: true
    }, {
      key: 'Sender_Phone',
      displayKey: 'Phone',
      required: true
    }, {
      key: 'Sender_Website',
      displayKey: 'Website URL',
      required: false
    },
    {
      key: 'Sender_Address',
      displayKey: 'Address',
      required: true
    }, {
      key: 'Sender_City',
      displayKey: 'City',
      required: true
    }, {
      key: 'Sender_State',
      displayKey: 'State',
      required: true
    }, {
      key: 'Sender_Zip',
      displayKey: 'Zip',
      required: true
    },
    {
      key: 'facebook_page_url',
      displayKey: 'Facebook Page URL',
      required: false
    },
    {
      key: 'instagram_page_url',
      displayKey: 'Instagram Page URL',
      required: false
    },
    {
      key: 'linkedin_page_url',
      displayKey: 'LinkedIn Page URL',
      required: false
    }
  ];
  marketDataKeys = [{
    key: 'region_zip',
    displayKey: 'Region Zip',
    required: true
  },
  {
    key: 'region_name',
    displayKey: 'Region Name',
    required: true
  },
  {
    key: 'previous_month',
    displayKey: 'Previous_Month',
    required: true
  },
  {
    key: 'current_month',
    displayKey: 'Current Month',
    required: true
  },

  {
    key: 'avail_listings',
    displayKey: 'Available Listings',
    required: true
  },
  {
    key: 'avail_listings_delta',
    displayKey: 'Available Listings Change',
    required: true
  },
  {
    key: 'sold_listings',
    displayKey: 'Sold Listings',
    required: true
  },
  {
    key: 'sold_listings_delta',
    displayKey: 'Sold Listings Change',
    required: true
  },
  {
    key: 'avg_price_sqft',
    displayKey: 'Average Price Per Sqft',
    required: true
  },
  {
    key: 'avg_price_sqft_delta',
    displayKey: 'Average Price Per Change',
    required: true
  },
  {
    key: 'med_list_price',
    displayKey: 'Median List Price',
    required: true
  },
  {
    key: 'med_list_price_delta',
    displayKey: 'Median List Price Change',
    required: true
  },

  {
    key: 'pending_listings',
    displayKey: 'Pending Listings',
    required: true
  },
  {
    key: 'pending_listings_delta',
    displayKey: 'Pending Listings Change',
    required: true
  },
  {
    key: 'most_expensive',
    displayKey: 'Most Expensive List Price',
    required: true
  },
  {
    key: 'most_expensive_delta',
    displayKey: 'Most Expensive List Price Change',
    required: true
  }];

  customer;
  senderIdentity;
  htmlOutput;
  htmlDataMap;
  subject;
  preheader;
  // hiddenFields = ['headshot_url', 'logo_url', 'unsubscribe_link', 'average_dom', 'average_dom_delta'];
  // nonRequiredFields = ['Sender_Website', 'facebook_page_url', 'instagram_page_url', 'linkedin_page_url'];



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailSenderIdentityService: EmailSenderIdentityService,
    private jwtService: JwtService,
  ) { }

  ngOnInit() {
    this.subject = this.model.subject;
    this.preheader = this.model.preheader;
    this.fetchEmailSenderIdentities();
    const c = this.jwtService.getSavedCustomer();
    this.customer = c;
    this.testData = JSON.parse(this.template.test_data);
    this.htmlDataMap = JSON.parse(this.template.test_data);
    this.mapDataKeys();
  }

  ngAfterViewInit() {

  }

  fetchEmailSenderIdentities() {
    this.loading = true;
    this.emailSenderIdentityService.get()
      .subscribe(res => {
        if (res && res.data.length) {
          let sender = res.data[0];
          this.senderIdentity = sender;
        }
        this.mapDataKeys();
        this.loading = false;
      }, err => {
        this.loading = false;
      });
  }

  mapDataKeys() {
    Object.keys(this.htmlDataMap).forEach(dataKey => {
      this.htmlDataMap[dataKey] = '';
    });
    this.setTemplateData();
  }

  setTemplateData() {
    //customer
    this.htmlDataMap.Sender_Company = this.customer?.brokerage;
    this.htmlDataMap.Sender_Title = this.customer?.preferred_title;

    this.htmlDataMap.facebook_page_url = this.customer?.fb_page_id ? `https://facebook.com/${this.customer?.fb_page_id}` : '';
    this.htmlDataMap.headshot_url = this.customer?.image.large;
    this.htmlDataMap.logo_url = this.customer?.logo.original;
    this.htmlDataMap.Sender_Phone = this.customer?.display_phone;
    this.htmlDataMap.Sender_Website = this.customer?.website_url;
    // sender idendtity
    if (this.senderIdentity) {
      this.htmlDataMap.Sender_Name = this.senderIdentity.name;
      this.htmlDataMap.Sender_Address = this.senderIdentity.address;
      this.htmlDataMap.Sender_City = this.senderIdentity.city;
      this.htmlDataMap.Sender_State = this.senderIdentity.state;
      this.htmlDataMap.Sender_Zip = this.senderIdentity.zip;
      this.htmlDataMap.Sender_Email = this.senderIdentity.name;
    }

    //market data
    this.htmlDataMap.region_zip = this.market.location_attributes.zip_code;
    this.htmlDataMap.region_name = this.market.region_name;

    this.htmlDataMap.previous_month = this.data["previous_month"];

    this.htmlDataMap.previous_month = this.data["previous_month"];
    this.htmlDataMap.current_month = this.data["current_month"];

    // analysis
    // this.htmlDataMap.average_dom = this.data.analysis?.avgDom?.current;
    // this.htmlDataMap.average_dom_delta = this.data.analysis?.avgDom?.delta_percentage && this.data.analysis?.avgDom?.direction === "+" ? "increase" : "decrease";
    this.htmlDataMap.avail_listings = this.data["analysis"]["forSaleCount"]["current"];
    this.htmlDataMap.avail_listings_delta = `${this.data["analysis"]["forSaleCount"]["delta_percentage"]} ${this.getDirection(this.data["analysis"]["forSaleCount"]["direction"])}`;
    this.htmlDataMap.sold_listings = this.data["analysis"]["soldCount"]["current"];
    this.htmlDataMap.sold_listings_delta = `${this.data["analysis"]["soldCount"]["delta_percentage"]} ${this.getDirection(this.data["analysis"]["soldCount"]["direction"])}`;
    this.htmlDataMap.avg_price_sqft = this.data["analysis"]["avgListPerSqft"]["current"];
    this.htmlDataMap.avg_price_sqft_delta = `${this.data["analysis"]["avgListPerSqft"]["delta_percentage"]} ${this.getDirection(this.data["analysis"]["avgListPerSqft"]["direction"])}`;
    this.htmlDataMap.med_list_price = this.data["analysis"]["medianListPrice"]["current"];
    this.htmlDataMap.med_list_price_delta = `${this.data["analysis"]["medianListPrice"]["delta_percentage"]} ${this.getDirection(this.data["analysis"]["medianListPrice"]["direction"])}`;
    this.htmlDataMap.pending_listings = this.data.analysis?.pendingCount?.current;
    this.htmlDataMap.pending_listings_delta = `${this.data?.analysis?.pendingCount?.delta_percentage || ''} ${this.getDirection(this.data?.analysis?.pendingCount?.direction)}`;
    this.htmlDataMap.most_expensive = this.data["analysis"]["mostExpensive"]["current"];
    this.htmlDataMap.most_expensive_delta = `${this.data["analysis"]["mostExpensive"]["delta_percentage"]} ${this.getDirection(this.data["analysis"]["mostExpensive"]["direction"])}`;
    this.mapTestDataFields();
  }

  mapTestDataFields() {
    setTimeout(() => {
      this.htmlOutput = this.template.html_content;
      this.loading = true;
      this.model.dynamic_template_data = this.htmlDataMap;
      // setting dynamic subject & preheader values
      this.model.subject = this.subject?.replace('{{region_name}}', this.htmlDataMap.region_name);
      this.model.preheader = this.preheader?.replace('{{Sender_Name}}', this.htmlDataMap.Sender_Name);

      Object.keys(this.htmlDataMap).forEach(dataKey => {
        this.replaceKeyValue(dataKey, this.htmlDataMap[dataKey]);
      });

      this.setIframeReady(this.iframe);

      this.loading = false;
      this.formChange.emit(this.form.valid);
    }, 100);
  }

  replaceKeyValue(key, value) {
    const dataKey = '{{' + key + '}}';
    this.htmlOutput = this.htmlOutput?.replaceAll(dataKey, value);
  }

  private setIframeReady(iframe: ElementRef): void {
    let win: Window;
    setTimeout(() => {
      win = iframe?.nativeElement?.contentWindow;
      if (win) {
        const doc: Document = win.document;
        doc.open();
        doc.write(this.htmlOutput);
        this.templateHtml.htmlOutput = this.htmlOutput;
        doc.close();
      }
    }, 500);
  }

  getDirection(key) {
    let direction = 'decrease';
    if (key == '+') {
      direction = 'increase';
    }
    return direction;
  }

}
