import { Component, Input, OnInit, Optional, inject } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { ImagesService } from '../../services/images/images.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { GlobalsService } from 'src/app/services/globals/globals.service';
import { Image as ImageClass } from '../../vos/image/image';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { environment } from '../../../environments/environment';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { CustomersService } from '../../services/customers/customers.service';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { ImageConfig } from 'src/app/models/interfaces/modals/image-config';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
  @Input() config: any;
  @Input() images: any;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: ImageConfig = inject(NZ_MODAL_DATA);
  imageChangedEvent: any = '';
  currentStep = 0;
  base64Image: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  aspectRatio = 500;
  maintainAspectRatio = true;
  croppedWidth;
  croppedHeight;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  btnSelected = false;
  customerId: number;
  imageWidth = 100;
  imageHeight = 100;
  validImage = true;
  loading = false;
  wrongFile = false;
  fileFormat = 'jpeg';
  uploadIcon = ['fas', 'file-upload'];
  urls = [];
  currentImageIndex = 0;
  galleryConfig = {
    aspectRatio: '2:1',
    cols: 3,
    gutterSize: '10px',
    instantSelection: true
  };
  selected: any;
  order = 0;
  selectedImages = [];
  cropperImages = [];
  loadingRecent = true;

  showStock = false;
  configs = {
    nzFooter: null,
    nzData: {
      config: {
        aspectRatio: '2:1',
        cols: 3,
        gutterSize: '10px',
        instantSelection: true
      }
    },
    nzWidth: '75%'
  };
  currentOrder: any;
  currentIndex: any;
  newImageIndex: any;
  allImages: any;
  loadingAllImages = true;
  // gallery
  // selected: number;
  // @Input() galleryConfig: GalleryConfig;
  @Input() selectedGImages: any;
  // @Output() selectedImagesEmitter = new EventEmitter();
  stockImages: any;
  // images?: Image[];
  imageFilters = [];
  imageFiltersAvalible = [];
  filter: string;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  page = 1;
  per_page = 15;
  reload = true;
  stockLoading = true;
  constructor(
    private modal: NzModalRef,
    private imagesService: ImagesService,
    private authService: AuthenticationService,
    private globalService: GlobalsService,
    private cableService: ActionCableService,
    private modalService: NzModalService,
    private customersService: CustomersService,
    private messageService: NzMessageService,

  ) { }
  ngOnInit() {
    if(this.nzModalData.config){
      this.config = this.nzModalData.config
    }
    if(this.nzModalData.images){
      this.images = this.nzModalData.images
    }
    if (this.config.step) {
      this.currentStep = this.config.step;
    }
    this.loadingRecent = false;
    this.setAspectRatio();
    const photoParams = this.imagesService.getPhotoParams(this.config, this.croppedImage);
    this.config.requiredImages = photoParams.requiredImages;
    this.authService.currentCustomer.subscribe(customer => {
      if (customer) {
        this.customerId = customer.id;
      }
    });
    if (this.config.requiredImages !== 1 || this.config.isCoverPhotos) {
      this.getTags();
      this.getStockImages();
    }
  }
  fileChangeEvent(event: any, fileNumber): void {
    this.wrongFile = false;
    const allowedFiles = ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg'];
    const maxFileSize = 25000000;
    this.loading = true;
    this.showCropper = true;
    this.btnSelected = true;
    this.cropperImages = [];
    let files = event.target.files;
    if (this.config.isCoverPhotos && files.length > 1) {
      files = [files[0]];
    }
    for (const tempFile of files) {
      const allowed = allowedFiles.indexOf(tempFile.type);
      if (tempFile.size > maxFileSize) {
        this.messageService.create('error', `File is to big, maximum is 25MB. You file size is: ${tempFile.size / 1000000}MB`);
      }
      if (allowed === -1) {
        this.wrongFile = true;
      }
    }

    if (files) {
      this.currentImageIndex = 0;
      for (const tempFile of files) {
        if (tempFile.size <= maxFileSize) {
          this.showCropper = true;
          this.btnSelected = true;
          const image: any = tempFile;
          const file = new FileReader;
          file.onload = (e: any) => { // when file has loaded
            const img = new Image();
            let uid;
            if (this.config.type === 'attestant_photo' || this.config.type === 'family_photo'
              || this.config.type === 'cover_photo' || this.config.type === 'testimony_attestant_photo') {
              uid = this.config.uid;
            } else {
              uid = `${this.config.imageableType}_${this.globalService.randomString()}`;
            }
            this.urls.push({ url: e.target.result, index: tempFile, uid: uid });
            if (this.images) {
              this.newImageIndex = this.images?.findIndex(i => (!i.thumb && !i.loading && !i.url && !i.id));
              if (this.newImageIndex === -1) {
                this.images.push({ url: e.target.result, index: tempFile, uid: uid, order: this.images.length });
              } else if (this.newImageIndex > -1) {
                this.images[this.newImageIndex] = { url: e.target.result, index: tempFile, uid: uid, order: this.newImageIndex };
              }
            }
            this.cropperImages.push({ url: e.target.result, index: tempFile, uid: uid });
            this.base64Image = this.urls[0].url;
            img.onload = () => {
              this.imageWidth = img.width;
              this.imageHeight = img.height;
            };
            const file_string = file.result.toString();
            const file_mime = file_string ? file_string.split(',')[0].replace(';base64', '') : '';
            const file_extension = file_mime.split('/')[1];

            if (file_extension) { this.fileFormat = file_extension; }

            img.src = file.result as string; // This is the data URL
          };
          file.readAsDataURL(image);
        } else {
          this.showCropper = false;
          this.btnSelected = false;
        }
      }
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.validateImage();
    if (this.validImage) {
      this.showCropper = true;
      this.btnSelected = true;
    } else {
      this.btnSelected = false;
    }
    this.loading = false;
  }
  cropperReady(sourceImageDimensions: Dimensions) {
  }
  validateImage() {
    return this.validImage = this.imageWidth >= this.config.minWidth && this.imageHeight >= this.config.minHeight;
  }
  loadImageFailed() {
  }
  saveImage() {
    if (this.config.requiredImages === 1 && !this.config.isCoverPhotos) {
      this.modal.destroy(this.croppedImage);
    }
    this.config.uid = this.urls[this.currentImageIndex].uid;
    this.currentImageIndex = this.currentImageIndex + 1;
    const photoParams = this.imagesService.getPhotoParams(this.config, this.croppedImage);
    const order = (this.config.type === 'headshot' || this.config.type === 'logo'
      || this.config.type === 'team_photo' || this.config.type === 'attestant_photo'
      || this.config.type === 'team_member_photo' || this.config.type === 'family_photo') ?
      null : this.images?.findIndex(x => x.uid === this.config.uid);
    if (this.config.requiredImages !== 1 || this.config.isCoverPhotos) {
      photoParams.params.image.order = order;
    }
    if (photoParams.isNew) {
      this.imagesService.createImage(photoParams.params, this.config.type, photoParams.url).subscribe();
      if (this.config.requiredImages !== 1 || this.config.isCoverPhotos || this.config.type === 'announcement_video') {
        this.ImageListener(this.config.uid);
      }
    } else {
      this.imagesService.updateImage(photoParams.params, photoParams.url).subscribe();
      if (this.config.requiredImages !== 1 || this.config.isCoverPhotos) {
        this.ImageListener(this.config.uid);
      }
    }
    if (this.cropperImages.length > this.currentImageIndex) {
      this.base64Image = this.cropperImages[this.currentImageIndex].url;
    } else {
      this.showCropper = false;
      this.btnSelected = false;
      this.currentImageIndex = 0;
      this.urls = [];
    }
  }
  ImageListener(uid) {
    const index = this.images?.findIndex(x => x.uid === uid);
    this.images[index].loading = true;
    const channel: Channel = this.cableService.cable(environment.wss_url).channel('CreateImageChannel', { uid });
    channel.received().subscribe(response => {
      if (response) {
        const image = JSON.parse(response.data);
        this.upload(image, uid);
      }
    });
  }

  upload(asset, uid) {
    if (!asset) { return; }
    const newImage = asset as ImageClass;
    const index = this.images?.findIndex(x => x.uid === uid);
    newImage.loading = false;
    newImage.stock_photo_id = null;
    newImage.changed = true;
    this.images[index] = newImage;
    this.images[index].loading = false;
  }
  cancel() {
    this.modal.close();
  }
  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }
  setAspectRatio() {
    this.maintainAspectRatio = this.config.maintainRatio !== undefined ? this.config.maintainRatio : true;
    this.aspectRatio = this.config.aspectRatio;
    this.croppedWidth = this.config.minWidth;
    this.croppedHeight = this.config.minHeight;
  }
  select(image, stockPhoto) {
    if (this.config.content.id) {
      const index = this.images.indexOf(image);
      if (index >= 0) {
        this.images[this.currentIndex].order = image.order;
        this.images[index] = this.images[this.currentIndex];
      }
      // replace new image
      const pushImage = Object.assign({}, image);
      pushImage.order = this.currentOrder;
      pushImage.changed = true;
      if (stockPhoto) {
        pushImage.id = this.images[this.currentIndex].id;
        pushImage.stock_photo_id = image.id;
      }
      this.images[this.currentIndex] = pushImage;
      // image.order = null;
      this.currentOrder = null;
      this.currentIndex = null;
    } else {
      this.selected = image;
      if (stockPhoto) {
        this.selected.stock_photo_id = image.id;
        this.selected.id = null;
      }
      const indexOfSelected = this.images.indexOf(this.selected);
      if (indexOfSelected >= 0) {
        this.images[this.currentIndex].order = image.order;
        this.images[indexOfSelected] = this.images[this.currentIndex];
        const pushImage = Object.assign({}, image);
        pushImage.order = this.currentOrder;
        pushImage.changed = true;
        if (stockPhoto) {
          pushImage.id = null;
          pushImage.stock_photo_id = image.id;
        }
        this.images[this.currentIndex] = pushImage;
      } else if (this.images.length < 5) {
        this.images.push(this.selected);
        const newOrder = this.images.indexOf(this.selected);
        this.images[newOrder].order = newOrder;
        this.images[newOrder].changed = true;
      } else {
        const pushImage = image;
        pushImage.order = this.currentOrder;
        this.images[this.currentIndex] = pushImage;
        const newOrder = this.images.indexOf(this.selected);
      }
    }

    this.images.forEach((img, i) => {
      this.images[i].order = i;
      this.images[i].changed = true;
    });

    this.currentOrder = null;
    this.currentIndex = null;

  }
  saveAndClose() {
    if (this.config.requiredImages !== 1) {
      this.images.map((image, index) => {
        if (image) {
          this.images[index].order = index;
          this.images[index].changed = true;
        }
      });
    }
    this.modal.close(this.config.isCoverPhotos ? this.images[0] : this.images);
  }
  selectOrignal(image) {
    // if (image.order === null  && this.currentOrder >= 0 && this.currentIndex >= 0 ){
    //   this.images[this.currentIndex].order = this.currentOrder;
    // } else {
    //   if (this.currentOrder !== null  && this.currentOrder >= 0 && this.currentIndex >= 0){
    //     this.images[this.currentIndex].order = this.currentOrder;
    //   }
    //   this.currentOrder = image.order;
    //   this.currentIndex = this.images.indexOf(image);
    //   image.order = null;
    // }

  }
  drop(event: any, stockImage) {
    if (stockImage && event.previousContainer?.data?.image) {
      const image = Object.assign({}, event.previousContainer.data.image);
      image.id = null;
      image.stock_photo_id = event.previousContainer.data?.image.id;
      const newIndex = this.images?.findIndex(i => (!i.thumb && !i.loading && !i.url));
      if (this.config.isCoverPhotos) {
        this.images[0] = image;
      } else if (newIndex === -1) {
        this.images.push(image);
      } else {
        this.images[newIndex] = image;
      }
    } else if (!stockImage) {
      this.images[event.previousContainer.data.index] = event.container?.data?.image;
      this.images[event.container.data.index] = event.previousContainer?.data?.image;
    }
    this.updateIndexes();
  }

  moveRight(imageIndex) {
    let currentIndex = imageIndex;
    const previousIndex = imageIndex;
    if (imageIndex < 4) {
      currentIndex = currentIndex + 1;
    } else {
      currentIndex = 0;
    }
    moveItemInArray(this.images, previousIndex, currentIndex);

    // update indexes
    this.updateIndexes();
  }
  moveLeft(imageIndex) {
    let currentIndex = imageIndex;
    const previousIndex = imageIndex;
    if (imageIndex > 0) {
      currentIndex = currentIndex - 1;
    } else {
      currentIndex = 4;
    }
    moveItemInArray(this.images, previousIndex, currentIndex);

    // update indexes
    this.updateIndexes();
  }
  updateIndexes() {
    this.images.map((image, index) => {
      if (image) {
        if (image.order !== index) {
          image.order = index;
          image.changed = true;
        }
      }
    });
  }
  showImage(image) {
    if (image && image.id) {
      if (!image?.thumb) {
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  delete_image(image) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete image?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '30%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true && image.id) {
        image.delete_loading = true;
        this.imagesService.destroy(image).subscribe(res => {
          const index = this.images.indexOf(image);
          if (index > -1) {
            this.deleteImageFromArray(image, index);
            this.updateIndexes();
          }
        });
      } else if (response === true && !image.id) {
        const index = this.images.indexOf(image);
        if (index > -1) {
          this.deleteImageFromArray(image, index);
          this.updateIndexes();
        }
      }
    });
  }
  deleteImageFromArray(image, index) {
    if (image.order < 5) {
      this.images[index] = { changed: false, uid: null };
    } else {
      this.images.splice(index, 1);
    }
  }
  getTags() {
    const params = {
      'q[name_eq]': 'stock_photos'
    };
    this.customersService.listFrom('tags', params).subscribe(tags => {
      this.imageFiltersAvalible = tags.data[0]['children'];
    });
  }

  getStockImages() {
    this.stockLoading = true;
    const params: Record<string, any> = {
      per_page: this.per_page,
      page: this.page
    };
    if (this.filter) {
      params['q[tags_name_eq]'] = this.filter;
    }
    this.customersService.listFrom('stock_photos', params).subscribe(images => {
      this.reload = true;
      this.stockLoading = false;
      if (this.page === 1) {
        this.stockImages = images.data;
      } else {
        this.stockImages = [...this.stockImages, ...images.data];
      }
      this.loadingAllImages = false;
    });
  }
  cancelCropper() {
    this.loading = false;
    this.showCropper = false;
    this.btnSelected = false;
  }
  toggleFilter(checked: boolean, filter: string): void {
    this.page = 1;
    this.filter = '';
    this.imageFilters = [];
    const elem = document.getElementsByClassName('scollable-section')[0];
    elem.scrollTop = 0;
    this.reload = false;
    if (checked) {
      this.imageFilters.push(filter);
      this.filter = filter['name'].toLowerCase();
    }
    this.getStockImages();
  }

  photoTags(tags) {
    return tags.split(',');
  }
  onModalScrollDown() {
    this.page += 1;
    this.getStockImages();
  }
  // selectedImage(image){
  //   const index  = this.selectedImages.indexOf(image);
  //   return index;
  // }
  get selectedImagesList() {
    return this.images?.filter((image) => image.order <= 4);
  }
  get otherImagesList() {
    return this.images?.filter((image) => image.order > 4);
  }
}
