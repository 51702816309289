import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ValidationErrors,

} from '@angular/forms';
import { GlobalsService } from '../../services/globals/globals.service';
import { AiTemplateService } from '../../services/ai-template/ai-template.service'
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  categories: string[]= ['Professionals'];
  subCategories: string[]= ['Realtors'];
  prospectTypes: string[]= ['Buying prospect', 'Selling prospect'];
  templates = [];
  languages = [];
  voiceTones = [];
  writingStyles = [];
  @Output() validChange = new EventEmitter<boolean>();
  @Output() retry = new EventEmitter<any>();
  @Input() valid = false;
  @Output() errors = new EventEmitter<ValidationErrors>();
  @Input() requiredFields = [
    'template',
    'language',
    'voice_tone',
    'writing_style',
    'prospect_type',
    'property_type_location',
    'total_characters',
    'property_description',
    'real_estate_related_terms',
    'CTA'
  ];
  @Input() showFields = [
    'template',
    'language',
    'voice_tone',
    'writing_style',
    'prospect_type',
    'property_type_location',
    'total_characters',
    'property_description',
    'real_estate_related_terms',
    'CTA'
  ];

  selectedTemplate: string;
  selectedLanguage: string;
  selectedVoiceTone: string;
  selectedWritingStyle: string;
  selectedProspectType: string;
  propertyTtypeLocation: string;
  totalCharacters: string;
  propertyDescription: string;
  realEstateRelatedTerms: string;
  CTA: string;
  response: string;
  loading = false;

  @Input() limitShow = false;
  @Input()  model: any = { 
    template: '',
    language: '',
    voice_tone: '',
    writing_style: '',
    prospect_type: '',
    property_type_location: '',
    total_characters: '400',
    property_description: '',
    real_estate_related_terms: '',
    CTA: 'reply back to schedule a call',
    response: '',
  };
  constructor(
    private globalsService: GlobalsService,
    private aiTemplateService: AiTemplateService,
    private breadcrumService: BreadCrumService,
    ) { 
    this.templates = this.globalsService.getTemplates();
    this.languages = this.globalsService.getLanguages();
    this.voiceTones = this.globalsService.getVoiceTones();
    this.writingStyles = this.globalsService.getWritingStyles();
  }


  ngOnInit(): void {
    this.breadcrumService.set_breadcrum();
  }

  changeTemplate() {
    this.selectedTemplate = this.model.template;
  }

  changeLanguage() {
    this.selectedLanguage = this.model.language;
  }

  changeVoiceTone() {
    this.selectedVoiceTone = this.model.voice_tone;
  }

  changeWritingStyle() {
    this.selectedWritingStyle = this.model.writing_style;
  }

  changeProspectType() {
    this.selectedProspectType = this.model.prospect_type;
  }

  getResponse(): void {
    this.loading = true;
    const payload = {
      template: this.selectedTemplate,
      language: this.selectedLanguage,
      voice_tone: this.selectedVoiceTone,
      writing_style: this.selectedWritingStyle,
      prospect_type: this.selectedProspectType,
      total_characters: this.model.total_characters,
      property_type_location: this.model.property_type_location,
      property_description: this.model.property_description,
      real_estate_related_terms: this.model.real_estate_related_terms,
      cta: this.model.CTA
    }

    this.aiTemplateService.generate_ai_template(payload).subscribe(res => {
      this.model.response = res["template"];
      this.loading = false;
    });
  }

}