import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketReportsService } from 'src/app/services/market-reports/market-reports.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { MarketReport} from 'src/app/vos/market-report/market-report';
import {NzMessageService} from 'ng-zorro-antd/message';
import {MarketReportFormComponent} from '../../../shared/market-report-form/market-report-form.component';

@Component({
  selector: 'app-edit-listing',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditMarketReportComponent implements OnInit {
  marketReport = new MarketReport();
  isNew = true;
  loading = false;
  error: string;
  queryName: string;
  @ViewChild('marketReportForm') marketReportForm: MarketReportFormComponent;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private marketReportService: MarketReportsService,
    private _location: Location,
    public message: NzMessageService
  ) { }

  ngOnInit() {
    this.route.data.subscribe(
      (res: {data: MarketReport}) => {
        if (res && res.data) {
          this.marketReport = res.data;
          this.isNew = false;
        }
      });

      this.route.queryParams
      .subscribe(params => {
        this.queryName = params['name'];
      });
  }
  cancel() {
    this._location.back();
  }
  save() {
    this.marketReportForm.checkForm();
    if (!this.marketReportForm.valid || !this.marketReport.region_name || !this.marketReport.location_attributes.zip_code)  {
      this.message.create('error', 'You have invalid fields.');
      return;
    }
    this.loading = true;
    this.error = null;
    let request: Observable<MarketReport>;
    if (this.isNew) {
      const payload = {
        'region_name': this.marketReport.region_name,
        'location_attributes': {
          'zip_code': this.marketReport.location_attributes.zip_code,
        }
      };
      request = this.marketReportService.create(payload);
    } else {
      request = this.marketReportService.update(this.marketReport);
    }
    request.subscribe((res) => {
      this.loading = false;
      if (res && this.queryName) {
        this.router.navigate(['/market_report', res.id, this.queryName, 'new'], { queryParams: { step: 2 } });
      } else if (res) {
        this.router.navigate(['/my_markets', res.id]);
      }
    }, err => {
      this.loading = false;
      if (err.status === 422) {
        this.error = err.error['location.zip_code'] ?
          err.error['location.zip_code'].slice(-1)[0] :  'Check that you have included all required fields and resubmit.';
          this.message?.remove();
        this.message?.create('error', this.error);
      } else {
        const msgErrors = err.error;
        // tslint:disable-next-line:forin
        for (const key in msgErrors) {
          const errors = msgErrors[key];
          errors.forEach((m) => {
            this.error = m;
            this.message.remove();
            this.message.create('error', m);
          });
        }
      }
    });
  }
  get diagnostic() { return JSON.stringify(this.marketReport); }

}
