import {
  Component,
  OnInit,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { Product } from 'src/app/vos/product/product';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductsService } from '../../services/products/product-api.service';
import { AnnouncementService } from '../../services/announcement/announcement.service';
import { CardButtonConfig } from '../../models/interfaces';
import { AnnouncementPreviewDialogComponent } from '../../shared/announcement-preview-dialog/announcement-preview-dialog.component';
import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { NpsScoreDialogComponent } from '../../shared/nps-score-dialog/nps-score-dialog.component';

import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import {CustomersService} from '../../services/customers/customers.service';
import {NpsScoreService} from '../../services/nps-score/nps-score.service';
import { DisclaimerDialogComponent } from '../disclaimer-dialog/disclaimer-dialog.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import {CancelledCustomerDialogComponent} from '../../shared/cancelled-customer-dialog/cancelled-customer-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  productsConfigs: CardButtonConfig[] = [];
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  productSwipe = this.globalsService.getSwiperOptions();
  public innerWidth: any;
  customer: Customer;
  cardType = 'dashboard-card';
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.productSwipe.slidesPerView = Math.floor(event.target.innerWidth / 250);
    this.productSwipe.slidesPerGroup = Math.floor(event.target.innerWidth / 250);
  }
  constructor(
    private authService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private productsService: ProductsService,
    private breadcrumService: BreadCrumService,
    private globalsService: GlobalsService,
    private modalService: NzModalService,
    private announcementService: AnnouncementService,
    private customersFacebookService: CustomersFacebookService,
    private npsService: NpsScoreService,
    private router: Router,
  ) { }
  ngOnInit() {
    this.customer = this.authService?.currentCustomerValue;
    this.checkModals();
    if (this.customer.ask_for_nps && !this.isAdmin && this.customer.tier !== 'free') {
      this.showNpsScoreWidget();
    }
    if (this.customer.tier === 'free'){
      this.showCancelledWidget();
    }
    this.authService?.currentCustomer?.subscribe(c => {
      if (c) {
        // Dont Add anything here, it will trigger whenever customer value changes.
        this.setCustomer(c);

      }
    });

    for (let i = 0; i < 8; i++) {
      this.productsConfigs.push({ id: '', title: '', actionText: '', isSkeleton: true });
    }
    this.fetchCustomerProducts();
    this.breadcrumService?.set_breadcrum();
    this.detectDevice();

    if (this.customer.render_only && this.customer.account_name=='HOI') {
      this.openDisclaimerDialog();
    }
  }

  openDisclaimerDialog(){
    const modal = this.modalService.create({
      nzContent: DisclaimerDialogComponent,
      nzFooter: null,
      nzWidth: '40%',
      nzCentered: true
    });
    modal.afterClose.subscribe(resp => {

    });
  }

  checkModals() {
    if (this.customer.tier !== 'free'){
      this.checkCustomerConectedFacebookPage();
    }
    this.announcementService.get().subscribe(data => {
      if (data && data.data && data.data.active && !this.isAdmin) {
        this.ShowAnnouncement(data.data);
      }
    });
  }

  detectDevice() {
    this.isMobile = this.deviceService?.isMobile();
    this.isTablet = this.deviceService?.isTablet();
    this.isDesktop = this.deviceService?.isDesktop();
  }
  setCustomer(c: Customer) {
    this.customer = c;
  }
  fetchCustomerProducts() {
    const params: Record<string, any> = {
      'q[additional_service_true]': 'false',
      'q[featured_true]': 'true'
    };


    if (!this.customer) {
      this.authService.logout();
      this.router.navigate(['/login']);
      return;
    }

    this.productsService.productList(
      `customers/${this.customer?.id}/products`,
      params
    ).subscribe(resp => {
      if (resp) {
        const products: Product[] = resp?.data?.map((product: Product) => new Product(product));
        this.productsConfigs = products?.map(pd => pd.cardButton(this.customer, pd.parent_type[0]));
      }
    });
  }

  checkCustomerConectedFacebookPage() {
    this.customersFacebookService.getCustomerConectedFacebookPage()
      .subscribe(res => {
        const { user_id_on_provider = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
        if (!user_id_on_provider && !this.customer.fb_page_id && !this.customer.render_only) {
          this.showFacebnook();
        }
      });
  }

  socialMediaConnected(sm: string): boolean {
    switch (sm) {
      case 'facebook':
        return true;
      default:
        return false;
    }
  }

  ShowAnnouncement(config) {
    const modal = this.modalService.create<AnnouncementPreviewDialogComponent, IModalData>({
      nzContent: AnnouncementPreviewDialogComponent,
      nzData: {
        config
      },
      nzFooter: null,
      nzWidth: '50%'
    });
    modal.afterClose.subscribe(response => {
    });
  }

  showFacebnook() {
    const modal = this.modalService.create<FacebookOnboardingDialogComponent, IModalData>({
      nzContent: FacebookOnboardingDialogComponent,
      nzData: {

      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
    });
  }

  showNpsScoreWidget() {
    const modal = this.modalService.create({
      nzContent: NpsScoreDialogComponent,
      nzData: {

      },
      nzFooter: null,
      nzWidth: '40%',
      nzCentered: true
    });
    modal.afterClose.subscribe(resp => {
      this.npsService.nps_asked().subscribe( res => {
        this.authService.refresh().subscribe();
      });
    });
  }
  showCancelledWidget() {
    const modal = this.modalService.create({
      nzContent: CancelledCustomerDialogComponent,
      nzData: {

      },
      nzFooter: null,
      nzWidth: '40%',
      nzCentered: true
    });
  }


  get isAdmin() {
    return !!this.authService.decodedUser?.aid;
  }

}
