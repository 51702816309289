<h1 mat-dialog-title>Pick an Image</h1>
<div mat-dialog-content>
  <nz-tag *ngFor="let filter of imageFiltersAvalible" nzMode="checkable" [nzChecked]="imageFilters.indexOf(filter) > -1"
          (nzCheckedChange)="toggleFilter($event, filter)" class="tag-filter">
    {{filter.name.capitalize()}}
  </nz-tag>
  <nz-divider></nz-divider>
  <!-- infinite scroll div -->
  <div *ngIf="stockImages" >
    <div class="scollable-section" nz-row nzJustify="space-around"
         infinite-scroll
         [infiniteScrollDistance]="modalScrollDistance"
         [infiniteScrollThrottle]="modalScrollThrottle"
         [scrollWindow]="false"
         (scrolled)="onModalScrollDown()" *ngIf="reload">
      <div nz-col nzSpan="7" class="back-image" *ngFor="let image of stockImages; index as i"
        [style.backgroundImage]="'url(' + image.large + ')'"
        [class.selected]="selectedImage(image) > -1"
        (click)="select(image)">
        <span class="image-tags" *ngFor="let tag of photoTags(image.tags)">{{tag}}</span>
        <span class="image-count" *ngIf="selectedImage(image) > -1 ">{{selectedImage(image) + 1}}</span>
      </div>
    </div>
  </div>
  <nz-empty nzNotFoundContent="No Image Found!" *ngIf="stockImages && stockImages.length === 0" class="no-content"></nz-empty>
</div>
