import {
  Component,
  OnInit
} from '@angular/core';
import { NotificationService } from '../../services/notification/notification.service';
import {AuthenticationService} from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading = false;
  isVisible:boolean = false;
  customer;
  constructor(
    private notificationService: NotificationService,
    private authService: AuthenticationService,
  ) { }
  ngOnInit() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent?.indexOf('chrome') === -1 && !userAgent?.match('CriOS')) {
      this.showModal();
    }
    this.authService.currentCustomer.subscribe((c) => {
      if (c && c.settings.show_price === undefined) {
        c.settings.show_price = true;
      }
      if (c) {
        this.customer = c;
      }
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
