import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { MarketReport } from 'src/app/vos/market-report/market-report';
import { MarketReportsService } from 'src/app/services/market-reports/market-reports.service';

import { Customer } from 'src/app/vos/customer/customer';
import { GlobalsService } from 'src/app/services/globals/globals.service';
import { BrokerageService } from '../../services/brokerage/brokerage.service';
import { RegionService } from '../../services/region/region.service';

@Component({
  selector: 'app-registration-form.',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})

export class RegistrationFormComponent implements OnInit {
  tabs = [
    { name: 'Personal Details', icon: 'user' },
    { name: 'Branding', icon: 'highlight' },
    { name: 'Team', icon: 'team' }
  ];
  submitted = false;
  loading = false;
  titles = Array<string>();
  credentials = Array<string>();
  brokerageList = [];
  regionList = [];

  selectedBrokerage;
  selectedRegion;

  error;
  currentTab = 0;
  model: any;
  marketReport = new MarketReport();
  colors = Array<string>();
  password;
  hasMarket = false;
  confirmPassword;
  filteredOptions: string[] = [];
  clonedCustomer: Customer;
  constructor(
    private authService: AuthenticationService,
    private globalsService: GlobalsService,
    private customerService: CustomersService,
    private router: Router,
    private marketReportService: MarketReportsService,
    private brokerageService: BrokerageService,
    private regionService: RegionService,
  ) { }
  ngOnInit() {
    // this.checkMarkets();
    this.fetchBrokreage();
    this.fetchRegions();
    this.credentials = this.globalsService.getCredentials();
    this.titles = this.globalsService.getTitles();
    this.filteredOptions = this.titles;
    this.colors = this.globalsService.getColors();
    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.setCustomerDetails(c);
      }
    });
  }

  setCustomerDetails(c) {
    this.model = { ...c };
    this.model.display_name = c.first_name + ' ' + c.last_name;
    this.model.display_email = c.display_email || c.email;
    this.model.primary_phone = c.primary_phone;
    this.model.display_phone = c.display_phone;
    this.model.onboarded = false;
    if (c.onboarded) {
      this.currentTab = 2;
    }
    if (!this.model.settings.text_color) {
      this.model.settings.text_color = '#222222';
    }

    if (!this.model.settings.bg_color) {
      this.model.settings.bg_color = '#FFFFFF';
    }
    if (!this.model?.region?.id) {
      this.model.region = { id: '' };
    }
    this.clonedCustomer = JSON.parse(JSON.stringify(this.model));

  }

  fetchBrokreage() {
    this.brokerageService.brokerageList()
      .subscribe(res => {
        this.brokerageList = res.data;
      }, err => {
        console.error(err);
      })
  }
  fetchRegions() {
    this.regionService.regionList()
      .subscribe(res => {
        this.regionList = res.data;
        if (this.model?.region?.id) {
          const selectedRegion = this.regionList.find(r => r.id === this.model?.region?.id);
          this.selectedRegion = selectedRegion.name;
        } else {
          this.model.region = { id: '' };
        }
      }, err => {
        console.error(err);
      })
  }

  changeBrokerage() {
    this.selectedBrokerage = this.brokerageList?.find(br => br.title === this.model.brokerage);
    if (this.selectedBrokerage) {
      this.model.settings.bg_color = this.selectedBrokerage.secondary;
      this.model.settings.text_color = this.selectedBrokerage.primary;
      this.model.logo.original = this.selectedBrokerage.original;
      this.model.logo.thumb = this.selectedBrokerage?.thumb;
      this.model.logo.large = this.selectedBrokerage.large;
      this.model.stock_logo_id = this.selectedBrokerage.id;
    } else {
      this.model.settings.bg_color = '#FFFFFF';
      this.model.settings.text_color = '#222222';
      this.model.logo = this.clonedCustomer?.logo ? JSON.parse(JSON.stringify(this.clonedCustomer.logo)) : null;
      this.model.stock_logo_id = null;
    }
  }
  checkMarkets() {
    this.loading = true;
    this.marketReportService.list().subscribe(response => {
      if (response && response.data && response.data.length > 0) {
        this.router.navigateByUrl('/');
        this.hasMarket = true;
      } else {
        this.hasMarket = false;
        this.tabs.push({ name: 'Market', icon: 'area-chart' });
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      console.error(err);
    });
  }

  submitCustomer(redirect) {
    this.submitted = true;
    this.customerService.update(this.model).subscribe(c => {
      if (c) {
        if (c.onboarded) {
          this.clonedCustomer = new Customer(this.model);
          this.currentTab = 2;
          this.submitted = false;
          if (redirect) {this.router.navigateByUrl('/'); }
        }
      }
    });
  }

  nextTab() {
    this.currentTab++;
  }

  setLogo(object) {
    if (object && object.image) {
      this.model.logo.original = object.image.logo;
      this.clonedCustomer = JSON.parse(JSON.stringify(this.model));
    }
  }
  setHeadshot(object) {
    if (object && object.image) {
      this.model.image.original = object.image.headshot;
    }
  }

  submitBranding(redirect) {
    this.submitCustomer(redirect);
  }

  changeIndex(nextTab) {
    this.currentTab = nextTab;
  }

  get isBrandingFormValid() {
    return this.model && this.model.settings && this.model.settings.bg_color &&
      this.model.settings.text_color &&
      this.model.image &&
      this.model.image.original &&
      this.model.logo &&
      this.model.logo.original;
  }

  checkWhiteSpaces() {
    if (this.model.display_name) {
      this.model.display_name = this.model.display_name.trim();
    }
    if (this.model.brokerage) {
      this.model.brokerage = this.model.brokerage.trim();
    }
    if (this.model.preferred_title) {
      this.model.preferred_title = this.model.preferred_title.trim();
    }
    if (this.model.display_email) {
      this.model.display_email = this.model.display_email.trim();
    }
    if (this.model.display_phone) {
      this.model.display_phone = this.model.display_phone.trim();
    }
  }

  get isPersonalDetailsValid() {
    return this.model.display_name &&
      this.model.brokerage &&
      this.model.preferred_title &&
      this.model.display_email &&
      this.model.primary_region &&
      this.model.display_phone;
  }

  get isCustomerDetailsValid() {
    return this.isBrandingFormValid &&
      this.isPersonalDetailsValid;
  }

  get headshotConfig() {
    const target = {
      customer_id: this.model.id
    };
    const config = {
      id: this.model.id,
      imageableType: 'Customer',
      type: 'headshot',
      aspectRatio: 1,
      minWidth: 50,
      minHeight: 50,
    };
    return {
      configMeta: config,
      image: this.model && this.model.image ? this.model.image.original : '',
      isNew: !!(this.model && this.model.image && this.model.image.original),
      channel: 'HeadshotChannel',
      target
    };
  }
  get logoConfig() {
    const target = {
      customer_id: this.model.id
    };
    const config = {
      id: this.model.id,
      imageableType: 'Customer',
      type: 'logo',
      aspectRatio: 1,
      minWidth: 50,
      minHeight: 50,
      maintainRatio: false
    };
    return {
      configMeta: config,
      image: this.model && this.model.logo ? this.model.logo.original : '',
      isNew: !!(this.model && this.model.logo && this.model.logo.original),
      channel: 'LogoChannel',
      target
    };
  }

  submitMarketReport() {
    this.submitted = true;
    if (this.isValidZip) {
      this.error = 'Zip code is invalid.';
    } else if (this.marketReport.location_attributes.zip_code) {
      this.marketReportService.create(this.marketReport).subscribe((res) => {
        this.submitted = false;
        this.router.navigateByUrl('/');
      }, error => {
        this.submitted = false;
        this.error = error.error;
      });
    } else {
      this.error = 'Zip code is required.';
    }
  }

  onChangetitle(value: string): void {
    this.filteredOptions = this.titles?.filter(option => option.toLowerCase()?.indexOf(value.toLowerCase()) === 0);
  }

  setTeamPhoto(object) {
    if (object && object.image.team_photo) {
      this.model.team_photo.large = object.image.team_photo;
    }
  }
  get teamPhotoConfig() {
    const target = {
      customer_id: this.model?.id
    };
    const config = {
      id: this.model?.id,
      imageableType: 'Customer',
      type: 'team_photo',
      aspectRatio: 1,
      minWidth: 50,
      minHeight: 50,
    };
    return {
      configMeta: config,
      image: this.model ? this.model.team_photo.large : '',
      isNew: !!this.model.team_photo.large,
      channel: 'TeamPhotoChannel',
      target: target
    };
  }

  get isValidZip() {
    const isValidZip = this.marketReport.location_attributes.zip_code && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.marketReport.zip);
    return isValidZip;
  }

}
