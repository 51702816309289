import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { DataResponse } from 'src/app/models/data-response/data-response';
import { Customer, Integration } from 'src/app/vos/customer/customer';
import { CustomerResourceService } from '../customer-resource.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConnectLinkedinService } from '../connect-linkedin/connect-linkedin.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.api_url}`;
}

@Injectable()

export class CustomersYoutubeService extends CustomerResourceService<Customer> {
  public endpoint = 'customers';
  public data_key = 'customer';
  integration;
  public googleUri = environment.google_app_configs.auth_uri;
  public clientId = environment.google_app_configs.client_id;
  public redirectUri = environment.google_app_configs.youtube_redirect_uri;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Customer, http, authService);
  }

  authorizeYoutube(customerId?) {
    if(window?.location?.href.indexOf('beta.zentap') > -1) {
      this.redirectUri = 'https://beta.zentap.com/youtube'
    }
    localStorage.removeItem('linkedInCode');
    const state = customerId ? `&state=${customerId}` : '';
    const url = `${this.googleUri}&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&scope=openid https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtube.force-ssl&include_granted_scopes=true&response_type=code&access_type=offline&prompt=consent`;

    window.open(url,
      'popUpWindow',
      'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  setYoutubeAuthorizationCode(code) {
    if (BroadcastChannel) {
      const bc = new BroadcastChannel('youtube');
      bc.postMessage({ code }); /* send */
      localStorage.setItem('youtubeCode', JSON.stringify(code));
    }
  }

  getYoutubeAuthorizationCode() {
    return JSON.parse(localStorage.getItem('youtubeCode'));
  }


  createYoutubeUserConnection(code: string): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/youtube.json`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'youtube'
            },
            code
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
  updateYoutubeUserConnection(code: string, id: any): Observable<any> {
    const url = `${this._uri}youtube/${id}`;
    return this.http
      .put<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'youtube'
            },
            code
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
  getYoutubeUserConnection(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('youtube.json')}`,
        { headers: this.headers })
      .pipe(map(resp => (resp.data)));
  }
  deleteYoutubeConnection(youtube): Observable<any> {
    return this.http
        .delete<DataResponse<any>>(`${this._uri}youtube/${youtube.id}`,
            { headers: this.headers })
        .pipe(map(resp => (resp.data)));
  }
  markYoutubeMessageAsShown(integration: any): Observable<any> {
    const url = `${this._uri}youtube/${integration?.id}`;
    const body = {
      integration: {
        is_youtube_message_show: true
      }
    }

    return this.http.put(url, 
      JSON.stringify(body),
      { headers: this.headers }).pipe(map(resp => resp)
    );
  }
  setAutopost(value: any, integration: any) {
    const url = `${this._uri}youtube/${integration?.id}`;
    return this.http
      .put(
        url,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
}
