<!-- <app-sidebar> -->
  <div nz-row nzType="flex" nzJustify="start" nzGutter="2">
    <h1 class="section-title title page-title">
      <i nz-icon nzType="user"></i>
      Edit Your Profile
    </h1>
  </div>
  <nz-divider></nz-divider>
  <nz-spin [nzSize]="'large'" *ngIf="loading">
    <app-customer-form #customerForm [valid]="valid" (retry)="save()" (validChange)="this.valid = $event" [model]="customer">
    </app-customer-form>
  </nz-spin>
  <app-customer-form #customerForm *ngIf="!loading" [valid]="valid" (retry)="save()" (validChange)="this.valid = $event" [model]="customer">
  </app-customer-form>
  <p class="error" *ngIf="error">{{ error | titlecase }}</p>
  <!-- <nz-divider></nz-divider> -->
  <!-- <nz-spin nzTip="Uploading Image..." [nzSize]="'large'" *ngIf="imageLoadEnd" class="image-loading">
    <nz-alert
        nzType="info"
        nzMessage="Loading..."
        nzDescription="Image is uploading."
      ></nz-alert>
  </nz-spin> -->
<!-- <ng-template #actionsbtns> -->
  <button nz-button nzType="primary" class="space-right" (click)="save()">Save</button>
  <button nz-button (click)="cancel()">Cancel</button>
<!-- </ng-template> -->


  <!-- <ng-template #notloading>
    <ng-container [ngTemplateOutlet]="actionsbtns"></ng-container>
  </ng-template> -->

  <div class="spinner" *ngIf="loading">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
<!-- </app-sidebar> -->
