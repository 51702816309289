<!-- <app-sidebar> -->
  <div>
    <h1 class="section-title title">
       {{isNew ? 'New ' : 'Update '}} {{isTeamMarketingVideo ? 'Team' : ''}} Marketing  {{ isMarketingFlyer ?  'Flyers' : 'Videos'}}
    </h1>
     <span>Video Type: </span>
    <nz-tag *ngFor="let category of contentFiltersAvailable;let i = index" nzMode="checkable" (nzCheckedChange)="handleChange($event, i)" [nzChecked]="selectedCategory === category">
      {{ category.titleize() }}
    </nz-tag>
    <br>
    <br>
    <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
    <app-select-content (posted)="cancel()" (pagechange)="paginateContent($event)" product="MV"
                        [resource]="contentSubject"
                        [isMarketVideo]="true"
                        [pageInfo]="pageInfo" [contents]="paginatedData">
    </app-select-content>
  </div>
<!-- </app-sidebar> -->
