<div class="container">
  <div class="title-header" *ngTemplateOutlet="WebsiteContent">
  </div>
  <h2 class="type title" *ngIf="!selectedType">Create New Website</h2>
  <div nz-row nzType="flex" nzJustify="start" *ngIf="!selectedType">
    <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="products-container">
      <app-card-button (click)="setType('Agent')" [config]="agentCardButton" cardType="product-card"></app-card-button>
    </div>
<!--    <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="products-container">-->
<!--      <app-card-button (click)="setType('Listing')" [config]="listCardButton" cardType="product-card"></app-card-button>-->
<!--    </div>-->
  </div>
  <h2 class="type title" *ngIf="selectedType">{{selectedType}} Website</h2>
  <nz-steps [nzCurrent]="current" *ngIf="selectedType" class="content-stepper">
    <nz-step nzTitle="Select a Listing" *ngIf="selectedType === 'Listing'"></nz-step>
    <nz-step nzTitle="Choose Template"></nz-step>
  </nz-steps>
  <br>
  <br>
  <div class="steps-content" *ngIf="current === 0 && selectedType === 'Listing'">
    <h2 class="type title">My Listings</h2>
    <app-listings-selector (select)="selectList($event)" [isChild]="true"></app-listings-selector>
  </div>
  <div class="steps-content"
    *ngIf="(current === 0 && selectedType === 'Agent') || (current === 1 && selectedType === 'Listing')">
    <div *ngIf="!isCreated">
      <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
        <ng-container [ngTemplateOutlet]="webtemplates"></ng-container>
      </nz-spin>
      <ng-template #notloading>
        <ng-container [ngTemplateOutlet]="webtemplates"></ng-container>
      </ng-template>
      <ng-template #webtemplates>
        <div nz-row>
          <div  nz-col class="gutter-row" nzSpan="6" *ngFor="let c of templates">
            <app-select-image-card [content]="c" [horizontalMargin]="'0px'" width="95%" (cardClicked)="selectTemplate(c)"
              [disableDeselect]="true" fxFlex></app-select-image-card>
          </div>
        </div>
      </ng-template>
      <br>
      <button nz-button nzType="primary" [disabled]="!selectedTemplate" (click)="done();">Next - Create Website</button>
    </div>
    <div class="success-checkmark" *ngIf="isCreated">
      <div class="check-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
      </div>
    </div>
  </div>
  <ng-template #WebsiteContent>
    <h1 class="section-title title">
      <i nz-icon nzType="layout"></i>
      Websites
    </h1>
  </ng-template>
