import { Pipe, PipeTransform } from '@angular/core';
import { Content } from 'src/app/vos/content/content';

@Pipe({
  name: 'contentStatus'
})
export class ContentStatusPipe implements PipeTransform {

  transform(content: Content[], status?: string[]): Content[] {
    return (status && status.length > 0) ? content?.filter(c => status.includes(c.status)) : content;
  }

}
