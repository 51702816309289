<!-- <app-sidebar> -->
<nz-collapse nzBordered="false" class="is_mobile">
  <nz-collapse-panel nzActive [nzHeader]="creation">
    <ng-container *ngTemplateOutlet="creationCards"></ng-container>
  </nz-collapse-panel>
</nz-collapse>

<div class="is_desktop">

  <div class="title-header" *ngTemplateOutlet="creation"></div>
  <ng-container *ngTemplateOutlet="creationCards"></ng-container>
</div>

<!--    CREATION CARD HEADING & TEMPLATE   -->
<ng-template #creation>
  <h1 class="sec-title title-space">
    Create Content
  </h1>
  <h3>What kind of content do you want to make?</h3>
</ng-template>
<ng-template #creationCards>
  <div nz-row >
    <div nz-col class="products-container" *ngFor="let product of productsConfigs">
      <app-content-card-button [config]="product" cardType="product-card"></app-content-card-button>
    </div>
  </div>
</ng-template>
