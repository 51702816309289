<div *ngIf="receipt">
    <div class="content">
        <img class="internal-content" [src]="'assets/images/'+receipt.image">
        <h1 class="header">{{receipt.header}}</h1>
        <p class="caption">{{receipt.caption}}
        </p>
    </div>
  <div nz-row nzType="flex" nzJustify="space-between">
    <button nz-button nzSize="large" nzType="default" (click)="close();">{{receipt.close}}</button>
    <button nz-button nzSize="large" nzType="default" class="action-btn details" (click)="openLink()">{{receipt.linkText}}</button>
  </div>
</div>
