import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import {HttpClient} from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import {Style} from '../../vos/styles/style';
import {Observable} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable()

export class StylesService {

  public endpoint = 'styles';
  public campaign;

  constructor(private http: HttpClient) {
  }

  public list(searchQuery?): Observable<DataResponse<Style[]>> {
    let url = `${environment.api_url}${this.endpoint}.json?per_page=100`;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<Style[]>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
}
