import { Component, OnInit } from '@angular/core';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';

@Component({
  selector: 'app-leads',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  constructor(
    private breadcrumService: BreadCrumService,
  ) { }

  ngOnInit() {
    this.breadcrumService.set_breadcrum();
  }
}
