import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { CardButtonConfig, CardColors } from '../../models';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'app-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss']
})
export class CardButtonComponent implements OnInit {
  @Input()
  selected: Boolean;
  highlighted: boolean;
  @Input()
  config: CardButtonConfig;
  @Input()
  canSelect = true;
  @Input()
  disabled = false;
  // Currently we have 2 card types (Products & listings)
  // Temporary variable cardType, will be removed once we move listings card to listing views
  @Input()
  cardType = '';
  @Output()
  select = new EventEmitter();

  @Output()
  highlight = new EventEmitter<{ element: ElementRef; highlighted: boolean }>();
  defaultColors = {
    bg: 'blue',
    bgSelected: 'red',
    text: 'white',
    textSelected: 'black'
  };
  colors: CardColors;
  bgColor: string;
  textColor: string;
  icon: string[];
  disabledIcon = ['fas', 'lock'];
  onClick() {
    if (this.config.link) {
      if (this.config.link.indexOf('http') > -1) {
        window.open(this.config.link, '_blank');
      } else {
        const qParam = this.config.queryParams ? this.config.queryParams : {};
        this.router.navigate([this.config.link], { queryParams: qParam });
      }
    } else if (this.config.unavailable) {

      const config = this.config.unavailable
      const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
        nzContent: GenericDialogComponent,
        nzData: {
          config
        },
        nzFooter: null,
        nzWidth: '60%'
      });
    } else {
      this.select.emit(this.config);
    }
  }

  goToPath(path: string) {
    if (path.indexOf('http') > -1) {
      window.open(path, '_blank');
    } else {
      this.router.navigate([path]);
    }
  }

  constructor(
    private router: Router,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.colors = this.config.colors ? Object.assign(this.defaultColors, this.config.colors) : this.defaultColors;
    this.bgColor = this.disabled || this.config.unavailable ? '#636363' : this.selected ? this.colors.bgSelected : this.colors.bg;
    this.textColor = this.selected || this.highlighted ? this.colors.textSelected : this.colors?.text;
    this.icon = this.config.icon ? this.config.icon.split(' ') : null;
  }
  truncateChar(text: string): string {
    const charlimit = 80;
    if (!text || text.length <= charlimit ) {
      return text;
    }
    const shortened = text.substring(0, charlimit) + '...';
    return shortened;
  }
}
