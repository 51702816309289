import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Listing } from 'src/app/vos/listing/listing';
import { CardButtonConfig } from 'src/app/models';
import { Product } from 'src/app/vos/product/product';
import { ProductsService } from 'src/app/services/products/product-api.service';
import { Content } from 'src/app/vos/content/content';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { BreadCrumService } from '../../../services/breadcrum/bread-crum.service';


@Component({
  selector: 'app-select-listings-content',
  templateUrl: './select-content.component.html',
  styleUrls: ['./select-content.component.scss']
})
export class SelectListingContentComponent implements OnInit {
  listing: Listing;
  products: CardButtonConfig[] = [];
  contents: Content[];
  customer: Customer;
  loading = false;
  constructor(
    private breadcrumService: BreadCrumService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private productService: ProductsService,
  ) { }

  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    this.listing = this.route.snapshot.data['data'];
    for (let i = 0; i < 9; i++) {
      this.products.push({ id: '', title: '', actionText: '', isSkeleton: true });
    }
    this.fetchProducts();
    this.loading = true;
    this.breadcrumService.set_breadcrum();
    this.breadcrumService.replaceItem(`${this.listing.id}`, this.listing.address);

    // this.breadcrumService.push_breadcrum({ name:  `${this.listing.address}`});
    // this.breadcrumService.push_breadcrum({ name:  `Make Content`}, { name:  `${this.listing.address}`});
  }

  fetchProducts() {
    const params: Record<string, any> = {
      'q[additional_service_true]': 'false',
      'q[available_true]': 'true'
    };

    this.productService.productList(`listings/${this.listing.id}/products`, params).subscribe(resp => {
      if (resp) {
        const products: Product[] = resp.data.map((product: Product) => new Product(product));
        this.products = products.map(pd => pd.cardButton(this.customer, 'listing', this.listing));
        this.products = this.products.map(p => {
          p.link = `listings/${this.listing.id}/create_content/${p.id}/new`;
          if (p.id === 'websites') {
            p.queryParams = {
              type: 'listing'
            }
          }
          return p;
        })
      }
    });
  }
}
