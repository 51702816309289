import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { DownloaderService } from '../../services/downloader.service';
import { EmailUserService } from '../../services/email-user/email-user.service';

@Component({
  selector: 'app-accept-aup-dialog',
  templateUrl: './accept-aup-dialog.component.html',
  styleUrls: ['./accept-aup-dialog.component.scss']
})

export class AcceptAUPDialogComponent implements OnInit {
  @Input()
  config: any;
  terms
  constructor(
    private emailUserService: EmailUserService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  close() {
    if (this.terms) {
      this.modal.close(true);
    }
  }
  accept() {
    this.emailUserService.update({user: {accepted_aup: this.terms}})
    .subscribe(res=>{
      this.close();
    })
  }
}
