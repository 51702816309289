import {
  Component,
  OnInit,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import { CustomersService } from '../../services/customers/customers.service';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';

import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  customer: Customer;
  isLoading = false;
  acceptAll = false;
  tableItems = [{
    name: 'AGREEMENT TO TERMS',
    toId: 'agreement'
  },
  {
    name: 'INTELLECTUAL PROPERTY RIGHTS',
    toId: 'ip'
  },
  {
    name: 'USER REPRESENTATIONS',
    toId: 'userreps'
  },
  {
    name: 'USER REGISTRATION',
    toId: 'userreg'
  },
  {
    name: 'FEES AND PAYMENT',
    toId: 'payment'
  },
  {
    name: 'CANCELLATION',
    toId: 'cancel'
  },
  {
    name: 'PROHIBITED ACTIVITIES',
    toId: 'prohibited'
  },
  {
    name: 'USER GENERATED CONTRIBUTIONS',
    toId: 'ugc'
  },
  {
    name: 'CONTRIBUTION LICENSE',
    toId: 'license'
  },
  {
    name: 'MOBILE APPLICATION LICENSE',
    toId: 'mobile'
  },
  {
    name: 'SOCIAL MEDIA',
    toId: 'socialmedia'
  },
  {
    name: 'SUBMISSIONS',
    toId: 'submissions'
  },
  {
    name: 'U.S. GOVERNMENT RIGHTS',
    toId: 'usrights'
  },
  {
    name: 'SITE MANAGEMENT',
    toId: 'sitemanage'
  },
  {
    name: 'PRIVACY POLICY',
    toId: 'privacypolicy1'
  },
  {
    name: 'TERM AND TERMINATION',
    toId: 'terms'
  },
  {
    name: 'MODIFICATIONS AND INTERRUPTIONS',
    toId: 'modifications'
  },
  {
    name: 'GOVERNING LAW',
    toId: 'law'
  },
  {
    name: 'DISPUTE RESOLUTION',
    toId: 'disputes'
  },
  {
    name: 'CORRECTIONS',
    toId: 'corrections'
  },
  {
    name: 'DISCLAIMER',
    toId: 'disclaimer'
  },
  {
    name: 'LIMITATIONS OF LIABILITY',
    toId: 'liability'
  },
  {
    name: 'INDEMNIFICATION',
    toId: 'indemnification'
  },
  {
    name: 'USER DATA',
    toId: 'userdata'
  },
  {
    name: 'ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES',
    toId: 'electronic'
  },
  {
    name: 'CALIFORNIA USERS AND RESIDENTS',
    toId: 'california'
  },
  {
    name: 'DESCRIPTION OF SERVICES',
    toId: 'misc'
  },
  {
    name: 'CONTACT US',
    toId: 'contact'
  },
  ];


  constructor(
    private authService: AuthenticationService,
    private modalService: NzModalService,
    private router: Router,
    private customerService: CustomersService,
  ) { }
  ngOnInit() {
    this.customer = this.authService?.currentCustomerValue;
    this.acceptAll = this.customer?.accepted_tos;
    // if (!this.customer?.accepted_tos) {
    //   this.openTermsAndConditionsModal();
    // }

    this.authService?.currentCustomer?.subscribe(c => {
      if (c) {
        // Dont Add anything here, it will trigger whenever customer value changes.
        if (this.customer?.accepted_tos && this.router.url  === '/terms') {
          this.router.navigateByUrl('/');
        }
      }
    });
  }

  toSection(id) {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  acceptTermsandConditions() {
    this.isLoading = true;
    this.customer = this.authService.currentCustomerValue;
    this.customerService.acceptTermsAndConditions().subscribe((c) => {
      this.customer.accepted_tos = true;
      this.authService.updateCurrentCustomer(this.customer);
      setTimeout(() => {
        this.isLoading = false;
        this.router.navigateByUrl('/');
      }, 5000);
    });
  }

}
