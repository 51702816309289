import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Testimony } from 'src/app/vos/testimony/testimony';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
// import * as zipcodes from 'zipcodes';
/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */

@Injectable()
/**
 * Service class. 
 */
export class TestimonyService extends CustomerResourceService<Testimony> {
  public endpoint = 'testimonies';
  public data_key = '';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Testimony, http, authService);
  }
  /**
   * Pulls a list of MarketReport objects.
   * @returns {Observable<MarketReport[]>}
   */

}
