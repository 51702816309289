<ng-template #onboarding>
  <div *ngIf="isEmailAuthorized; else onboardingprompt">
    <nz-steps [nzCurrent]="current" class="content-stepper">
      <nz-step nzTitle="Sender Identity"></nz-step>
      <nz-step nzTitle="Verify Sender Identity"></nz-step>
      <nz-step nzTitle="Done"></nz-step>
    </nz-steps>
    <div *ngIf="current === 0">
      <h3 class="center-text copy">
        Please fill in your name, email address you will be sending emails from, and physical business address. These
        are necessary to send emails. If you need help please call our support line at <a
          href="https://calendly.com/client-success-call/success-call" target="_blank">(310) 997-4142</a> or Schedule a call
        <a href="https://calendly.com/client-success-call/success-call" target="_blank">here</a>.
      </h3>
      <div nz-row nzJustify="space-between" class="listing-form">
        <div nz-col nzSpan="12">
          <app-sender-identity-form [sender]="sender" (onSave)="onSubmitSender($event)"></app-sender-identity-form>
        </div>
        <nz-divider class="splitter sender" nzType="vertical"></nz-divider>
        <div nz-col nzSpan="10" *ngIf="sender">
          <nz-card class="w-full domain-card">
            <nz-card-meta [nzAvatar]="avatarTemplate" [nzTitle]="smpltitle" [nzDescription]="cradDescription">
            </nz-card-meta>
            <nz-skeleton [nzParagraph]="{ rows: 4 }"></nz-skeleton>
            <div class="center-text sender-display">
              <a href="javascript:void(0);">{{sender.name}}</a>
              <a href="javascript:void(0);">{{sender.address}}</a>
              <a href="javascript:void(0);">{{sender.city}}, {{sender.state}} {{sender.zip}}</a>
            </div>
          </nz-card>
          <ng-template #smpltitle>
            <h3 class="name-title"> {{sender.name ? sender.name  :'Jane Doe'}}</h3>
          </ng-template>
          <!-- <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
            <button nz-button nzType="primary" class="action-btn-primary" (click)="nextFromSender()">Next</button>
          </div> -->
        </div>
        <ng-template #avatarTemplate>
          <nz-avatar nzIcon="user"></nz-avatar>
        </ng-template>
      </div>
    </div>

    <div *ngIf="current === 1">
      <h3 class="center-text copy">
        We sent an email to {{sender.email}} so we can verify this email and start sending emails from that address.
        Please open that email and click the verification link to become verified.
      </h3>
      <form #domain="ngForm">
        <div class="empty-websites">
          <div nz-row nzJustify="space-around" nzAlign="middle">
            <img class="email-image" src="../../../assets/images/Email_Image.png" />
          </div>
          <div nz-row nzJustify="space-around" nzAlign="middle">
            <button nz-button nzType="primary" (click)="fetchEmailSenderIdentities(true)">
              Confirm Verification
            </button>
          </div>
          <p class="verification-text">Didn't get the email? Click <a [routerLink]=""
              (click)="reSendSenderVerification()">here</a> to resend the email...</p>
        </div>
      </form>
    </div>
    <div *ngIf="current === 2">

      <div class="congrats">
        <i nz-icon nzType="check-circle" nzTheme="outline"></i>
        <h2>Congratulations</h2>
        <h3 class="center-text copy">
          Your identity have been verified. You are all set to start launching email campaigns with Zentap.
          Click “Send
          First Email” below to start your first campaign
        </h3>
      </div>
      <form #domain="ngForm">
        <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
          <button nz-button nzType="primary" class="action-btn-primary" (click)="gotoCampaignNew()">Send First
            Email</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="onboarding"></ng-container>
</nz-spin>

<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="onboarding"></ng-container>
</ng-template>
<ng-template #onboardingprompt>
  <app-onboarding-prompt-dialog (start)="emailAuth()"></app-onboarding-prompt-dialog>
</ng-template>