import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Topic } from 'src/app/vos/topic/topic';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
@Injectable()

export class TopicsService extends CustomerResourceService<Topic> {

  public endpoint = 'topics';
  public data_key = 'topics';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Topic, http, authService);
  }
}
