import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/vos/product/product';
import { Content } from 'src/app/vos/content/content';
import { Resource } from 'src/app/vos/resource/resource';
import { ProductsService } from 'src/app/services/products/product-api.service';

@Component({
  selector: 'app-select-content',
  templateUrl: './select-content.component.html',
  styleUrls: ['./select-content.component.scss']
})
export class SelectContentComponent implements OnInit {
  @Input()
  product: string | Product;
  @Input()
  isMarketVideo: boolean;
  @Input()
  resource: Resource;
  @Output()
  posted = new EventEmitter<Content>();
  @Output()
  pagechange = new EventEmitter<any>();
  _contents: Content[] = [];
  filteredContents: Content[] = [];
  @Input()
  set contents(c: Content[]) {
    this._contents = c;
    this.contentLength = Number(c.length);

    this.updateFilter();
  }

  page = 1;
  pagination;
  pageSize = 10;
  currentPage;
  pageSizeOptions = [10, 12, 20, 50, 100];
  contentLength = 0;
  pageEvent: any;
  @Input()
  get pageInfo() {
    return this.pagination;
  }
  set pageInfo(p: any) {
    if (p) {
      this.pageSize = p.per_page;
      this.contentLength = Number(p.total_entries);
      this.page = p.current_page;
      this.currentPage = p.current_page;
      this.pagination = p;
    }
  }
  constructor(
    private productService: ProductsService
  ) { }

  ngOnInit() {}
  editingChange($event, editing: boolean) {
    $event?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  didPage(page: number) {
    this.currentPage = page;
    this.page = page;
    this.pagechange.emit(this.page);
    this.updateFilter();
  }

  updateFilter() {
    this.filteredContents = this._contents;
  }
}
