<!-- <app-sidebar> -->
  <div>
    <h1 class="section-title title">
       Email Signature
    </h1>
    <!-- <span>Video Type: </span>
    <nz-tag *ngFor="let filter of contentFiltersAvailable" nzMode="checkable" [nzChecked]="contentFilters.indexOf(filter) > -1"
            (nzCheckedChange)="toggleFilter($event, filter)">
      {{filter.capitalize()}}
    </nz-tag> -->
    <!-- <nz-divider></nz-divider> -->
    <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
    <app-select-content (posted)="cancel()" (pagechange)="paginateContent($event)" product="MV"
                        [resource]="contentSubject"
                        [isMarketVideo]="true"
                        [pageInfo]="pageInfo" [contents]="paginatedData">
    </app-select-content>
  </div>
<!-- </app-sidebar> -->
