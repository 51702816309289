<ng-template #magiclinkvalidation>
  <div nz-row class="login-form-first-div">
    <div class="login-form-second-div">
      <div nz-row class="flex-layout">
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-form">
          <div>
            <img width="200px" src="assets/logos/blue-logo.png" />
            <h2 class="sign-in" *ngIf="!invalidLink">Signing In</h2>
            <div class="invalid-box" *ngIf="invalidLink">
              <h2 class="resend-instrcutions">Magic login link is invalid!</h2>
              <button nz-button nzType="primary" class="login-button" (click)="goto('/login/magic')" type="button">
                Resend Magic Login Link</button>
            </div>
          </div>
        </div>
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-img second-bg">
          <img class="login-form-forgot" class="side-image" src="assets/images/media.png" />
        </div>
      </div>
    </div>
  </div>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="magiclinkvalidation"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="magiclinkvalidation"></ng-container>
</ng-template>