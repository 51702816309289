import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { Customer } from 'src/app/vos/customer/customer';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { InsightsService } from '../../services/insights/insights.service';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';


@Component({
  selector: 'app-roi-reports',
  templateUrl: './roi-reports.component.html',
  styleUrls: ['./roi-reports.component.scss']
})
export class RoiReportsComponent implements OnInit, AfterViewInit {
  isVisibleInfo = false;
  isConfirmLoading = false;
  since: any;
  untilDate: any;
  rawData: any;
  @Input()
  customer: Customer = new Customer();
  loading = true;
  selectedValue = '';
  insightsDates;
  subscription: Subscription;
  insights = [
    {
      display_name: 'Time Saved', unit: 'hours', key: 'time_saved', description: 'The Amount of time saved from all the content creation, ad creation, website creation, and posting content on your account.',
      value: '', change: ''
    },
    {
      display_name: 'Money Saved', unit: 'USD', key: 'money_saved', description: 'The Amount of money saved using Zentap instead of hiring someone at normal design and marketing rates.',
      value: '', change: ''
    },
    {
      display_name: 'Posts Made', unit: '', key: 'posts_count', description: 'Here is the number of posts you made in the last duration',
      value: '', change: ''
    },
    {
      display_name: 'Leads', unit: '', key: 'leads_count', description: 'Here are the number of leads you have generated in the last duration',
      value: '', change: ''
    }
  ];
  isVisible = false;
  isfbConnected = true;
  showButton = false;
  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;
  constructor(
    private authService: AuthenticationService,
    private insightsService: InsightsService,
    private cableService: ActionCableService,
    private cdRef: ChangeDetectorRef,
    private customerService: CustomersService,
    private modalService: NzModalService,
    private route: ActivatedRoute,
    private breadcrumService: BreadCrumService
  ) { }
  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    this.fetchInsightsAnalyticsDates();
    this.breadcrumService.set_breadcrum();
  }
  ngAfterViewInit() {
  }
  checkFacebookPageAccess() {
    this.customerService.checkFBAccess().subscribe(c => {
      this.isfbConnected = true;
    }, err => {
      this.isfbConnected = false;
      this.showButton = true;
      this.cdRef.detectChanges();
    });
  }
  // Update Current Analytics Date
  updateInsights(event) {
    this.loading = true;
    this.selectedValue = event;
    this.fetchAllInsights();
  }
  fetchInsightsAnalyticsDates() {
    this.fetchAllInsights();
  }
  fetchAllInsights() {
    this.loading = true;
    this.fetchInsightsAnalytics();
  }
  fetchInsightsAnalytics() {
    this.loading = true;
    const customerId = this.customer?.id;
    if (this.authService.currentCustomerValue) {
      const channel: Channel = this.cableService.cable(environment.wss_url).channel('InsightsChannel',
        { uid: `${this.authService.currentCustomerValue?.id}_roi_report` });
      // Subscribe to incoming insights
      channel.connected().subscribe(data => {
        this.route.queryParams
          .subscribe(params => {
            this.since = params.since;
            this.untilDate = params.until_date;
            this.insightsService.fetchRoiReport(params.since, params.until_date).subscribe(ins => {
            });
          });
      });
      channel.received().subscribe(insights => {
        if (insights && insights.data) {
          this.rawData = insights.data;
          this.loading = false;
          this.showButton = false;
          this.insights.forEach((i) => {
            i.value = insights.data[i.key];
            i.change = insights.data[`${i.key}_change`];
          });

          this.cdRef.detectChanges();
          this.destroySubscription(channel);
        }
      });
    } else {
      this.authService.logout();
    }
  }
  destroySubscription(channel) {
    channel.unsubscribe();
  }
}
