import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Content } from 'src/app/vos/content/content';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import {Subject} from 'rxjs';


@Injectable()
/**
 * Service class.
 */
export class LinkedinPagesService extends CustomerResourceService<any> {
  public endpoint = 'linkedin_pages';
  public data_key = 'linkedin_pages';
  private subject = new Subject<any>();
  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Content, http, authService);
  }
}
