import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import {environment} from '../../../environments/environment';
import {CustomerResourceService} from '../customer-resource.service';
import {Observable} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
import {Resource} from '../../vos/resource/resource';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
/**
 * Service class.
 */
export class ManualFacebookService extends CustomerResourceService<any> {
  public endpoint = 'manual_facebook_connection';
  public data_key = 'integration';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Resource, http, authService);
  }
  setAutopost(value: any, integration: any) {
    return this.http
      .put(
        `${this._uri}manual_facebook_connection/${integration?.id}.json`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
}
