import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Customer } from 'src/app/vos/customer/customer';
import { trigger, transition, style, animate } from '@angular/animations';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [
    trigger('flyInOut', [
      // state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s')
      ]),
      transition(':leave',
        animate('0.5s', style({ opacity: 0 })))
    ])
  ]
})
/**
 * Blank view.
 */
export class RegisterComponent implements OnInit {
  regForm: UntypedFormGroup;
  state = 'register';
  code: string;
  errorMessage: string;
  loading = false;
  hide = false;
  passwordVisible = false;

  /**
   * Component constructor and DI injection point.
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private onboardingService: OnboardingService
  ) {
  }
  PasswordMatch: ValidatorFn = (fg: UntypedFormGroup) => {
    const valid = fg.controls['password']?.value === fg.controls['password_confirmation']?.value;
    return valid
      ? null
      : { mismatch: true };
  }
  /**
 * Called part of the component lifecycle. Best first
 * place to start adding your code.
 */
  ngOnInit() {
    const d = { ...this.route.snapshot.data, ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
    this.setFormsWith(d);
      const { action, ...reqParams } = this.route.snapshot.queryParams;
      this.regForm.patchValue(reqParams, { onlySelf: false });
      if (d.action === 'submit') {
        this.signup(reqParams);
      }
  }

  setFormsWith({ state: _, ...obj }: any = {}) {
    this.regForm = this.fb.group({
      ...obj,
      email: ['', Validators.required],
      password: ['', Validators.required],
      primary_phone: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      source: ['client_dashboard']
    });
  }

  signup(details?: any) {
    const customer = new Customer(details || this.regForm.value);
    customer.password_confirmation = customer.password;
    this.loading = true;
    this.authService.signup(customer).subscribe(c => {
      if (c) {
        this.loading = false;
        this.router.navigateByUrl('/');
        // this.checkOnboardingSteps(c.id);
      }
    },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.errorMessage = err.error.message;
      });
  }

  toUrl(url) {
    this.router.navigateByUrl(url);
  }
}
