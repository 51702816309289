import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';


@Injectable()
/**
 * Service class.
 */
export class WebsiteService extends CustomerResourceService<any> {

  public endpoint = 'websites';
  public data_key = 'website';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Object, http, authService);
  }
}
