import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, inject } from '@angular/core';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import {ActivatedRoute, Router} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import Swiper, { Autoplay } from 'swiper';

import { UpgradeDialogComponent } from '../upgrade-dialog/upgrade-dialog.component';
import { ConnectLinkedinService } from '../../services/connect-linkedin/connect-linkedin.service';
import { CustomersService } from '../../services/customers/customers.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { DownloaderService } from 'src/app/services/downloader.service';
import { ContentsService } from '../../services/contents/contents.service';
import { BrandedInfographicsService } from 'src/app/services/branded-infogrpahics/branded-infographics.service';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import { CustomersGoogleService } from '../../services/customers-google/customers-google.service';
import { CustomersYoutubeService } from '../../services/customers-youtube/customers-youtube.service';

import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { GoogleOnboardingDialogComponent } from '../../shared/google-onboarding-dialog/google-onboarding-dialog.component';
import { GlobalsService } from '../../services/globals/globals.service';
import { ProductsService } from '../../services/products/product-api.service';


import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { Customer } from '../../vos/customer/customer';
import moment from 'moment-timezone';
import { CaptionsService } from '../../services/captions/captions.service';
import { ConnectInstagramService } from '../../services/connect-instagram/connect-instagram.service';
import {CustomersTiktokService} from '../../services/customers-tiktok/customers-tiktok.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

Swiper.use([Autoplay]);
@Component({
  selector: 'app-content-preview-dialog',
  templateUrl: './content-preview-dialog.component.html',
  styleUrls: ['./content-preview-dialog.component.scss']
})
export class ContentPreviewDialogComponent implements OnInit, OnDestroy {
  @ViewChild('caption', { static: false }) captionInput: ElementRef;
  socialConfig;
  submitted = false;

  @Input()
  showActions = true;
  @Input() config: any;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  isEdit = false;
  isRescheduled = false;
  content;
  posts;
  privacyStatus = 'public';
  videoTags;
  isUnscheduled = false;
  schedule_time: Date;
  schedule_date: Date;
  customer: Customer;
  all = false;
  facebook = false;
  linkedin = false;
  instagram = false;
  google = false;
  youtube = false;

  isFacebookConnected = false;
  isGoogleConnected = false;
  isYoutubeConnected = false;
  hideGoogle = false;

  integration;
  linkedinStatus = false;
  linkedinAutopost = false;
  linkedinConnected = false;

  instagramIntegration;
  instagramStatus = false;
  instagramAutopost = false;
  instagramConnected = false;

  loading = false;
  status = false;
  showCaption = true;
  showEmojiPicker = false;
  set = 'twitter';
  PaidTier = false;
  hideFacebook = true;
  hidePosting = false;
  isScheduledPost = false;
  isDateLoading = false;
  facebookSuccess = false;
  linkedinSuccess = false;
  isHashtagLimit = false;
  hashtags: string[];
  contentUpdateSubscription: Subscription;
  // tiktok settings
  tiktokIntegration: any;
  tiktokConnected: boolean;
  tiktokAutopost: any;
  stitch = false;
  duet = false;
  comment = false;
  tiktokTitle;
  contentDisclourse = false;
  yourBrand = false;
  brandedContent = false;
  tiktokPrivacyStatus;
  tiktokAllowed = false;
  sanitizedContent: SafeHtml;

  disabledHours(): number[] {
    return this.schedule_date && new Date(this.schedule_date).getDate() - new Date().getDate() < 1
      ? this.range(1, new Date().getHours() + 12) : [];
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and after 90 days
    const dateDiff = current && (current.getDate() - new Date().getDate() < 1) && (new Date().getHours() > 12) ? -1 : 0
    const daysDiff = differenceInCalendarDays(new Date(), current);
    return daysDiff > dateDiff || differenceInCalendarDays(current, new Date()) > 60;
  };

  shouldShowRenderButton(): boolean {
    return this.config.isCard && !this.config.isPost && (this.config.isMarketingVideo || this.config.isInfomercials);
  }

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  constructor(
    private contentsService: ContentsService,
    // private modal: NzModalRef,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private router: Router,
    private message: NzMessageService,
    private authService: AuthenticationService,
    private linkedInService: ConnectLinkedinService,
    private instagramService: ConnectInstagramService,
    private customersService: CustomersService,
    private brandedInfographicsService: BrandedInfographicsService,
    private notification: NzNotificationService,
    private captionsService: CaptionsService,
    private customersFacebookService: CustomersFacebookService,
    private customersGoogleService: CustomersGoogleService,
    private customersYoutubeService: CustomersYoutubeService,
    private globalsService: GlobalsService,
    private productsService: ProductsService,
    private activatedRoute: ActivatedRoute,
    private customersTiktokService: CustomersTiktokService,
    private sanitizer: DomSanitizer

  ) {
  }
  ngOnInit() {
    if(this.nzModalData.config){
      this.config = this.nzModalData.config;
    }
    if(this.nzModalData.showActions != undefined ){
      this.showActions = this.nzModalData.showActions;
    }
    if (!this.config){
      this.loadContentConfig();
    } else{
      this.setContentConfigForInit();
    }

    this.tiktokAllowed = this.checkTikTokAllowed();

    this.getPosts();
  }

  checkTikTokAllowed(): boolean {
    return this.content.is_vertical;
  }

  setContentConfigForInit(){
    this.contentChannelSub();
    this.setSocialContentConfig();
    this.hideGoogle = this.config?.content?.media_type === 'video';
    if (this.config.content.last_post && this.config.content.last_post.id &&
      this.config?.content.last_post?.posted_on) {
      this.socialConfig.facebook = this.config.content.last_post.posted_on.indexOf('facebook') > -1;
      this.socialConfig.linkedin = this.config.content.last_post.posted_on.indexOf('linkedin') > -1;
      this.socialConfig.instagram = this.config.content.last_post.posted_on.indexOf('instagram') > -1;
      this.socialConfig.google = this.config.content.last_post.posted_on.indexOf('google_business') > -1;
      this.socialConfig.youtube = this.config.content.last_post.posted_on.indexOf('youtube') > -1;

      const tempDate = this.config.content?.last_post?.scheduled_for ? moment(this.config.content?.last_post?.scheduled_for) : null;
      this.config.content.last_post.scheduled_for = tempDate ? `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}` : tempDate;
      this.config.content.scheduled_for = this.config.content.last_post.scheduled_for;
    }

    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
        this.PaidTier = c?.tier === 'ultra';
        this.socialConfig.facebook = this.customer?.settings?.auto_posting;
        // this.fetch_linkedin_autopost_settings();
        // this.fetch_instagram_autopost_settings();
        this.setSocialContentConfig();
      }

    });
    this.content = JSON.parse(JSON.stringify(this.config.content));
    // this.schedule_time = this.content.scheduled_for;
    // this.schedule_date = this.content.scheduled_for;
    this.isEdit = this.config.isEdit;
    // this.isScheduledPost = !!this.config.content.last_post;
    if (!this.content.id || this.content.category === 'IF') {
      this.captionsService.generate_caption(this.content).subscribe(res => {
        this.content.caption = res["text"];
      });
    }
    this.captionsService.generate_title(this.content).subscribe(res => {
      this.content.title = res["text"];
    });
    this.fetchCustomerProducts();
    if(this.content.caption){
      this.hashtags = this.content.caption.match(/#\S+/g) || [];
    }

    this.updateSanitizedContent();
  }

  contentChannelSub() {
    this.contentUpdateSubscription = this.contentsService.contentUpdateChannel()
      .received().subscribe(res => {
        if (res) {
          const contentResponse = res.data;
          if (this.config.content.id === contentResponse.content_id) {
            this.config.content.status = contentResponse.status;
            if (this.config.content.last_post) {
              this.config.content.last_post.status = contentResponse.status;
            }
          }
        }
      })
  }

  loadContentConfig(){
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.contentsService.show(params.id).subscribe(response => {
          this.config = { content: response.data, isEdit: true };
          this.setContentConfigForInit();
        }, error => {
          this.router.navigate(['NotFound']);
        });
      }
    });
    this.fetch_tiktok_autopost_settings();
  }

  fetch_tiktok_autopost_settings() {
    this.loading = true;
    this.customersTiktokService.getTiktokUserConnection()
      .subscribe(res => {
        if (res && res?.length > 0) {
          this.tiktokIntegration = res[0];
          this.tiktokConnected = true;
          this.tiktokAutopost = this.tiktokIntegration.auto_posting;
          this.tiktokPrivacyStatus = this.tiktokIntegration.tiktok_creator_info.data.privacy_level_options[0];
          this.comment = !this.tiktokIntegration.tiktok_creator_info.data.comment_disabled;
          this.duet = !this.tiktokIntegration.tiktok_creator_info.data.duet_disabled;
          this.stitch = !this.tiktokIntegration.tiktok_creator_info.data.stitch_disabled;
          this.contentDisclourse = true;
          this.yourBrand = true;
          this.brandedContent = true;
        }
      });
    this.loading = false;
  }


  refreshContent() {
    if (this.config.content.id && !this.config.isInfomercials && !this.config.isMarketingVideo) {
      this.contentsService.show(this.config.content.id)
        .subscribe(res => {
          if (res) {
            this.content = res.data;
            if (!this.config.content) {
              this.config = { ...this.config, content: {} }
            }
            this.config.content = JSON.parse(JSON.stringify(this.content));
            if (this.config && this.config.refresh)
              this.config.refresh({ ...res.data });
            this.loading = false;
          }
        });
    }
  }

  render() {
    if (this.shouldShowRenderButton()) {
      if (this.config.isMarketingVideo) {
        this.postMarketingVideo(null, true);
      } else if (this.config.isInfomercials) {
        this.createBrandedInformercial(null, true);
      }
    }
  }

  postMarketingVideo(platforms?, skip_post?) {
    this.loading = true;
    const request = this.contentsService.create({
      category: this.content.category,
      caption: this.content.caption,
      style: this.content.style,
      contentable_type: this.content.contentable_type,
      contentable_id: this.content.contentable_id,
      scheduled_for: this.content.scheduled_for,
      extra_attributes: this.content.extra_attributes,
      platforms,
      skip_post
    });
    request.subscribe(c => {
      this.loading = false;
      const is_template = this.content.is_template;

      // this.message.create('success', 'successfully created, we will notify when its created!');
      const config = {
        type: 'marketing_videos',
        link: 'branding/marketing_videos/new'
      };
      this.removeDialog();
      this.notification.create(
        'success',
        'Created',
        'Content has successfully been created'
      );
      this.router.navigateByUrl(`/content?new=${c.id}`);
      // this.receipt(config, `/content?content_category_eq=${this.content.category}`);
    }, err => {
      this.loading = false;

    });
  }

  createBrandedInformercial(platforms?, skip_post?) {
    this.loading = true;

    const request = this.brandedInfographicsService.create({
      infographic_id: this.content.id,
      caption: this.content.caption,
      scheduled_for: this.content.scheduled_for,
      platforms,
      skip_post
    });
    request.subscribe(c => {
      this.loading = false;
      const is_template = this.content.is_template;

      const config = {
        type: 'social_media_content',
        link: 'social_media_content/new'
      };
      // this.refreshContent();
      this.removeDialog();
      this.notification.create(
        'success',
        'Created',
        'Content has successfully been created'
      );
      this.router.navigateByUrl(`/content?new=${c.id}`);
    }, err => {
      this.loading = false;
      // this.message.remove();
      // this.message.create('error', 'Error creating Branded Infomercial.');
    });
  }

  post() {
    const platforms = [];
    if (this.socialConfig.facebook) {
      platforms.push('facebook');
    }
    if (this.socialConfig.linkedin) {
      platforms.push('linkedin');
    }

    if (this.socialConfig.instagram) {
      platforms.push('instagram');
    }
    if (this.socialConfig.google) {
      platforms.push('google_business');
    }
    if (this.socialConfig.youtube) {
      platforms.push('youtube');
    }
    if (this.socialConfig.tiktok) {
      platforms.push('tiktok');
    }
    if (this.socialConfig.tiktok) {
      // tslint:disable-next-line:max-line-length
      if (!this.tiktokAllowed) {
        this.message?.remove();
        this.message.create('error', 'Non-vertical content cannot be posted to TikTok.');
        return;
      }

      if (this.tiktokPrivacyStatus === null || this.tiktokTitle === null || this.comment === null || this.duet === null || this.stitch === null) {
        this.message?.remove();
        this.message?.create('error', 'Please complete tiktok required fields before Posting!');
        return;
      }
    }

    if (platforms.length === 0) {
      this.message?.remove();
      this.message?.create('error', 'Please select platform!');
      return;
    }

    if (this.config.isCard && this.config.isMarketingVideo && !this.config.isPost) {
      this.postMarketingVideo(platforms);
    } else if (this.config.isCard && this.config.isInfomercials && !this.config.isPost) {
      this.createBrandedInformercial(platforms);
    } else {
      if (this.socialConfig.facebook && this.config.content.status === 'ready') {
        this.postFB();
      }
      if (this.socialConfig.linkedin && this.config.content.status === 'ready') {
        this.postLN();
      }
      if (this.socialConfig.instagram && this.config.content.status === 'ready') {
        this.postInstagram();
      }

      if (this.socialConfig.google && this.config.content.status === 'ready') {
        this.postGoogle();
      }
      if (this.socialConfig.youtube && this.config.content.status === 'ready') {
        this.postYoutube();
      }
      if (this.socialConfig.tiktok && this.config.content.status === 'ready') {
        this.postTiktok();
      }
      this.submitted = true;
      this.showActions = false;
    }
  }

  postLN() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'linkedin',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent();
      this.linkedinSuccess = true;

      // this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isEdit = false;
      this.isScheduledPost = false;
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }

  postFB() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'facebook',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');
    request.subscribe(c => {
      const caption = this.content.caption;
      this.loading = false;
      this.content = c['content_attributes'];
      this.content.caption = caption;

      // this.message.create('success', 'Your facebook post has posted Sucessfully');
      this.resetContent();
      this.status = true;
      this.isEdit = false;
      this.isScheduledPost = false;
      // }
      this.content.status = 'posted';
      // });
      // this.message.create('success', 'successfully requested your posting, we will notify when its posted!');
      this.status = true;
      this.facebookSuccess = true;
      // this.posted.emit(c);
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your facebook post has failed');
      this.status = false;
    });
  }

  postInstagram() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'instagram',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent();
      this.linkedinSuccess = true;

      // this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isEdit = false;
      this.isScheduledPost = false;
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }

  postGoogle() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'google_business',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent();
      this.linkedinSuccess = true;

      // this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isEdit = false;
      this.isScheduledPost = false;
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }

  postTiktok() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'tiktok',
        scheduled_for: this.content.scheduled_for,
        extra_attributes: { title: this.tiktokTitle , privacy_status: this.tiktokPrivacyStatus,
          comment: this.comment, duet: this.duet, stitch: this.stitch,
          content_disclourse: this.contentDisclourse, your_brand: this.yourBrand, branded_content: this.brandedContent}
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent();
      this.linkedinSuccess = true;

      // this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isEdit = false;
      this.isScheduledPost = false;
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }
  postYoutube() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'youtube',
        scheduled_for: this.content.scheduled_for,
        extra_attributes: { title: this.content.title , privacy_status: this.privacyStatus , tags: this.videoTags}
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent();
      this.linkedinSuccess = true;

      // this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isEdit = false;
      this.isScheduledPost = false;
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }
  removeDialog() {
    this.modalService.closeAll();
    this.router.navigateByUrl(`/content`);
  }

  facebookSupport() {
    //this.modal.close();
    const modal = this.modalService.create<UpgradeDialogComponent, IModalData>({
      nzContent: UpgradeDialogComponent,
      nzFooter: null,
      nzWidth: 600,
      nzData: {
        facebookSupport: true
      },
    });
  }

  cancelSchedule() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to cancel your scheduled post?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentsService.unschedulPost(this.config.content)
          .subscribe(res => {
            this.refreshContent();
            this.loading = false;
            this.notification.create(
              'success',
              'Scheduled post cancelled',
              'Your scheduled post is cancelled'
            );
          }, e => {
            this.loading = false;
            this.message?.remove();
            this.notification.create(
              'error',
              'Cancelling scheduled post',
              'Error cancelling the scheduled post. Please try again'
            );
          });
      }
    });
  }

  setSocialContentConfig() {
    const content = this.config?.content;
    const hideGoogle = content?.media_type === 'video';
    this.socialConfig = { content: content, facebook: false, linkedin: false, google: false, youtube: false, hideGoogle };
    this.socialConfig.facebook = this.customer?.settings?.auto_posting;
  }

  resetContent() {
    this.refreshContent();
  }


  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }



  async download() {
    const content = this.config.content;
    let url = content.url;
    if (!url && content?.fileURLs && content.fileURLs.length) {
      await this.downloader.createZip(content?.fileURLs, content.display_name);
    } else if (url && !url.includes('https')) {
      url = url?.replace('http', 'https');
    }
    this.downloader.save(url, content.filename);
  }
  enablePost() {
    this.isEdit = true;
  }

  // post() {
  //   if (this.config.content.status === 'ready') {
  //     const modal = this.modalService.create({
  //       nzContent: SocialPostDialogComponent,
  //       nzData: {
  //         content: this.config.content
  //       },
  //       nzFooter: null
  //     });
  //     modal.afterClose.subscribe(response => {
  //     });
  //   }
  // }

  gotToEdit() {
    //this.modal.close();
    this.router.navigate([`listings/${this.config.content.contentable_id}/edit`]);
  }

  changeDate(current) {
    if (current) {
      this.content.scheduled_for = new Date(current);
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    } else if (!this.schedule_time) {
      this.schedule_time = null;
    }
    if (this.schedule_time && current) {
      const scheduleDate = new Date(current);
      this.schedule_time = new Date(this.schedule_time);
      this.schedule_time.setDate(scheduleDate.getDate());
      this.schedule_time.setMonth(scheduleDate.getMonth());
      this.schedule_time.setFullYear(scheduleDate.getFullYear());
      const tempDate = moment(this.schedule_time);
      this.content.scheduled_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    }
    // if (this.schedule_date) {
    //   this.schedule_time = this.schedule_time ? new Date(this.schedule_time) : new Date(current);
    //   this.schedule_time.setDate(this.schedule_date.getDate());
    //   this.schedule_time.setMonth(this.schedule_date.getMonth());
    //   this.schedule_time.setFullYear(this.schedule_date.getFullYear());
    // } else {
    //   this.schedule_time = null;
    // }


  }
  isPosted(content) {
    // console.log(content.last_post.scheduled_for)

  }

  changeTime(current) {
    this.schedule_date = new Date(this.schedule_date);
    if (this.schedule_date.getDate() - new Date().getDate() < 1 &&
      this.schedule_date.getMonth() <= new Date().getMonth() &&
      this.schedule_date.getFullYear() <= new Date().getFullYear() &&
      current &&
      current.getHours() - new Date().getHours() < 6) {
      // this.schedule_time = new Date(current);
      this.schedule_time = null;
      this.message.create('error', 'You must schedule posts to be at least 6 hours from content creation');
      // this.message.create('error', 'You cannot select time before 12 hours');
    } else {
      this.schedule_time = new Date(current);
    }
    if (this.schedule_time && this.schedule_date) {
      this.schedule_time.setDate(this.schedule_date.getDate());
      this.schedule_time.setMonth(this.schedule_date.getMonth());
      this.schedule_time.setFullYear(this.schedule_date.getFullYear());
      const tempDate = moment(this.schedule_time).tz('America/California');
      this.content.scheduled_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    }
  }

  addEmoji(event) {
    const { caption = '' } = this.content;
    const text = `${caption}${event.emoji.native}`;
    this.content.caption = text;
    this.captionInput.nativeElement.focus();
    this.captionInput.nativeElement.click();
    this.showEmojiPicker = false;
  }

  showEdit() {
    this.modalService.closeAll();
    this.isEdit = true;
    this.router.navigate(['/content', this.content.id, 'post']);
  }

  cancelEdit() {
    this.content = { ...this.config.content };
    this.isEdit = false;
    this.isScheduledPost = false;
    this.#modal.destroy();
  }
  showReschedule() {
    this.isEdit = true;
    this.isScheduledPost = true;
    this.schedule_date = this.config.content.last_post.scheduled_for;
    this.schedule_time = this.config.content.last_post.scheduled_for;
  }

  onChangeScheduleStatus(event) {
    if (this.isUnscheduled) {
      this.schedule_time = null;
      this.schedule_date = null;
      this.content.scheduled_for = null;
    } else {
      this.schedule_time = this.config.content.scheduled_for;
      this.schedule_date = this.config.content.scheduled_for;
      this.content.scheduled_for = this.config.content.scheduled_for;
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    }
  }

  contentDeleted(content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.contentsService.destroy(content)
          .subscribe(res => {
            this.message.create('success', `Content has been successfully deleted.`);
            //this.modal.close({ action: 'deleted' });
          }, e => {
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }


  onSave() {
    if (this.isUnscheduled) {
      this.unSchedule();
    } else if (this.config.content.scheduled_for && this.config.content.scheduled_for !== this.content.scheduled_for) {
      this.reSchedule();
    } else if (this.content.caption) {
      this.contentsService.update(this.content)
        .subscribe(res => {
          this.config.content = this.content;
          this.message.create('success', 'You post is saved!');

        }, err => {
          this.message.create('error', 'Error whiel updating post');

        });
    }
    // TODO: update content caption if there is change in caption

  }

  unSchedule() {
    this.contentsService.unschedulPost(this.content)
      .subscribe(res => {
        this.isEdit = false;
        this.config.content = this.content;
        this.message.create('success', 'You post is unscheduled!');
      });
  }
  finalReschedule() {
    if (this.facebook && this.config.content.status === 'ready') {
      this.reSchedule('facebook');
    }
    if (this.linkedin && this.config.content.status === 'ready') {
      this.reSchedule('linkedin');
    }
  }

  reSchedule(posted_on?) {
    const contentPayload = { ...this.content };
    contentPayload.posted_on = posted_on || this.content.last_post.posted_on;

    this.contentsService.reschedulPost(contentPayload)
      .subscribe(res => {
        this.isEdit = false;
        this.isRescheduled = true;
        this.refreshContent();
        this.message.create('success', 'You post is rescheduled!');
      }, err => {
        // this.notification.create(
        //   'error',
        //   'Error',
        //   'Something went wrong!'
        // );
      });
  }
  clearSubscriptions() {
    if (this.contentUpdateSubscription) {
      this.contentUpdateSubscription.unsubscribe();
    }
  }
  ngOnDestroy() {
    this.clearSubscriptions();
  }
  fetchCustomerProducts() {
    if (this.showGMB) {
      return;
    }
    this.loading = true;
    const params: Record<string, any> = {
      'per_page': 40,
    };

    this.productsService.productList(
      `customers/${this.customer?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const googleProduct = data ? data?.find(p => p.abbreviation === 'GMB' && p.locked === false) : null;
        if (googleProduct && googleProduct != null && googleProduct?.tier === 'ultra') {
          this.globalsService.setIsGMBAllowed(true);
        }
        this.loading = false;
      }
    }, err => {
      this.loading = false;
    })

  }
  get getLinkedInCode() {
    const payload = this.linkedInService.getAuthorizationCode();
    if (payload) {
      this.linkedinConnected = (payload && payload.status === 'success');
      // this.cdr.detectChanges();
    }
    return payload;
  }

  get isDateChanged() {
    return this.content?.scheduled_for !== this.config?.content?.last_post?.scheduled_for;
  }

  get isCarousel(): boolean {
    return this.content?.media_type.toLowerCase() === 'carousel';
  }

  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }

  onInput(value: string): void {
    this.hashtags = value.match(/#\S+/g) || [];
    if (this.hashtags.length > 30) {
      this.isHashtagLimit = true;
    } else {
      this.isHashtagLimit = false;
    }
  }

  handlePreventDefault(evt){
    evt = (evt) ? evt : window.event;
    if(this.hashtags.length >= 30 && evt.key == '#'){
      evt.preventDefault();
    }
  }

  preprocessOption(option: string): string {
    return option.replace(/_/g, ' ');
  }

  updateSanitizedContent() {
    const caption = this.config?.content?.caption;

    if (!caption) this.sanitizedContent = null;
    else this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(caption.replace(/\n/g, '<br/>'));
  }
  copyText() {
    const caption = this.config?.content?.caption;
    navigator.clipboard.writeText(caption).then(() => {
      this.notification.create(
        'success',
        'Copied',
        'Caption successfully copied to clipboard'
      );
    }).catch(err => {
    });
  }
  getPosts(){
    this.contentsService.posts(this.content).subscribe(resp => {
      this.posts = resp
    });
  }
}
