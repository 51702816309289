import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import {environment} from '../../../environments/environment';
import {CustomerResourceService} from '../customer-resource.service';
import {Observable} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
import {Resource} from '../../vos/resource/resource';
import {HttpClient} from '@angular/common/http';
import {Customer} from '../../vos/customer/customer';

@Injectable({
  providedIn: 'root',
})
/**
 * Service class.
 */
export class GhlService extends CustomerResourceService<any> {
  public endpoint = 'ghl';
  public data_key = 'integration';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Resource, http, authService);
  }

  getGhlUserConnection(): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/ghl.json`;
    return this.http
      .get<DataResponse<Customer>>(
        url,
        { headers: this.headers, observe: 'response' }
      );
  }
}
