import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-rss-already-posted',
  templateUrl: './rss-already-posted.component.html',
  styleUrls: ['./rss-already-posted.component.scss']
})
export class RssAlreadyPostedComponent implements OnInit {

  constructor(private modal: NzModalRef) { }

  ngOnInit(): void {
  }
  
  handleClose(value) {
    this.modal.close(value)
  }
}
