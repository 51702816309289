<div cdkDropListGroup>
  <div>
    <div class="upload-tab">
      <div *ngIf="currentStep === 0">
        <h3 class="ic-heading" *ngIf="btnSelected">Crop and Add this image</h3>
        <div class="image-popup-container">
          <div class="upload-btn-wrapper" [ngClass]="wrongFile == false ? 'upload-sucess' : 'upload-error'"
            *ngIf="!btnSelected">
            <input type="file" #coverFilesInput (change)="fileChangeEvent($event, 0)"
              accept="image/x-png,image/jpg,image/jpeg" multiple />
            <p class="ant-upload-drag-icon">
              <span *ngIf="wrongFile == false">
                <!-- <fa-icon [icon]="uploadIcon" size="3x" class="upload-icon"></fa-icon> -->
              </span>
              <i nz-icon nzType="warning" class="warn-icon" *ngIf="wrongFile == true"></i>
            </p>
            <p class="ant-upload-text" *ngIf="wrongFile == false">Click or drag file to this area to upload, Only Files
              with
              following extensions are allowed: png, jgp, jpeg</p>
            <p class="ant-upload-text warn-color" *ngIf="wrongFile == true">Wrong file type!
              <br>
              Only Files with following extensions are allowed: png, jpg, jpeg
            </p>
            <p class="ant-upload-text" *ngIf="wrongFile == false">or</p>
            <button nz-button nzType="default" (click)="coverFilesInput.click()" class="browse-button">Browse</button>
          </div>
          <div *ngIf="!validImage" class="imageError">Image size is too small (Min size required
            {{config.minWidth}}x{{config.minHeight}}), Recommended size {{config.idealWidth ? config.idealWidth :
            config.minWidth}}x{{config.idealHeight ? config.idealHeight : config.minHeight}}
          </div>
          <div>
            <image-cropper class="image-cropper-container" [imageBase64]="base64Image" output="base64"
              [maintainAspectRatio]=maintainAspectRatio [containWithinAspectRatio]="containWithinAspectRatio"
              [aspectRatio]=aspectRatio [cropperMinWidth]=croppedWidth [cropperMinHeight]=croppedWidth
              [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
              [alignImage]="'center'" [style.display]="showCropper ? null : 'none'" [format]="fileFormat"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
              (loadImageFailed)="loadImageFailed()">
            </image-cropper>
          </div>
          <!-- <nz-spin nzSimple *ngIf="loading"></nz-spin> -->
        </div>
        <div class="image-popup-footer">
          <div *ngIf="urls.length && showCropper">
            <div style="padding-bottom: 10px" *ngIf="showCropper">
              <div nz-row>
                <div *ngFor="let url of urls; let idx = index" class="space-right">
                  <div nz-col nzSpan="4">
                    <img [ngStyle]="{'border': (currentImageIndex === idx) ? '3px solid #1990ff' : 'none'}"
                      *ngIf="url.url" [src]="url.url" class="image-preview" width="100" height="60">
                  </div>
                </div>
              </div>
            </div>
            <button nz-button nzType="primary" class="space-right done-btn" (click)="saveImage()">{{
              config.requiredImages ===
              1 ? 'Crop and Save': (currentImageIndex === urls.length - 1 ? 'Crop and Upload' : 'Crop and Next') }}
            </button>
            <button nz-button class="cancel-btn" (click)="cancelCropper()">Back</button>
          </div>
          <div *ngIf="(config.requiredImages !== 1 && !showCropper) || config.isCoverPhotos">
            <nz-divider></nz-divider>
            <ng-container [ngTemplateOutlet]="selectedImagesSection"></ng-container>
            <nz-divider></nz-divider>
            <ng-container [ngTemplateOutlet]="stockPhotos"
              *ngIf="(config.requiredImages !== 1 && config.branding) || config.isCoverPhotos">
            </ng-container>
          </div>
        </div>
      </div>
      <!-- <nz-tab  nzTitle="Stock Images" *ngIf="config.requiredImages !== 1 && config.branding">-->
      <!--      <ng-container [ngTemplateOutlet]="selectedImagesSection"></ng-container>-->
      <!--      <ng-container [ngTemplateOutlet]="stockPhotos" *ngIf="config.requiredImages !== 1 && config.branding"></ng-container>-->

      <!--&lt;!&ndash;      <app-image-gallery [config]="configs" (selectedImagesEmitter)="select($event, true)" [selectedImages]="selectedImages"></app-image-gallery>&ndash;&gt;-->
      <!--  </nz-tab>-->
      <div *ngIf="currentStep === 1">
        <ng-container [ngTemplateOutlet]="selectedImagesSection"></ng-container>
        <div class="gallery">
          <h1>All Images</h1>
          <div>
            <div class="scollable-section" nz-row nzJustify="start"
              *ngIf="allImages && allImages.length; else notimage">
              <div *ngFor="let image of allImages; index as i">
                <div class="image-contain">
                  <img *ngIf="image.thumb" [src]="image.thumb" (click)="select(image, false)" class="image-preview"
                    width="200">
                  <div class="selection" *ngIf="!config.content.id">{{
                    image.order + 1 }}</div>
                  <div *ngIf="image.loading" class="spin-width">
                    <nz-spin nzSimple class="center-spinner"></nz-spin>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #notimage>
              <nz-empty nzNotFoundContent="No Image Found!" class="no-content" *ngIf="!loadingAllImages"></nz-empty>
            </ng-template>
          </div>
          <div nz-row nzJustify="start" nzType="flex" *ngIf="loadingAllImages">
            <div style="width: 200px; padding-left: 10px" *ngFor="let i of [].constructor(5)">
              <nz-skeleton nzType="avatar" nzActive="true" nzSize="large" nzShape="square"></nz-skeleton>
            </div>
          </div>
        </div>
      </div>
      <button nz-button nzType="primary" class="space-right done-btn" (click)="saveAndClose()"
        *ngIf="(config.requiredImages !== 1 && !showCropper) || config.isCoverPhotos">Done</button>
    </div>

  </div>
  <ng-template #selectedImagesSection>
    <h1 style="margin-bottom: 0px;">Selected Images</h1>
    <h4 style="margin-bottom: 14px;">
      {{ config.isCoverPhotos ?  'Drag and drop an image to apply as your background.' :  'Drag and drop images to change order.'}}
      {{ config.isCoverPhotos ? '' : 'The first 5 images will be used in your content.'}}
    </h4>
    <div class="categories" nz-row nzType="flex" nzJustify="start" cdkDropListGroup>
      <ng-container *ngFor="let image of images;let i=index">
        <div class="categories-item" cdkDropList cdkDropListOrientation="horizontal"
          [cdkDropListData]="{image:image,index:i}" (cdkDropListDropped)="drop($event, false)" *ngIf="showImage(image)">
          <div class="inner" cdkDrag>
            <div nz-col nzSpan="21" class="image-holder image-container" *cdkDragPlaceholder>
              <div class="image-contain">
                <nz-spin nzSimple></nz-spin>
              </div>
            </div>
            <div class="image-card overflow-visible custom-place-holder" nz-row nzType="flex" nzJustify="center"
              (click)="selectOrignal(image)">
              <button nz-button nzType="primary" class="cross-button" nzSize="small" nzShape="circle" nzDanger
                *ngIf="image.thumb || image.remote_image_url || image.id"><i nz-icon nzType="close"
                  (click)="delete_image(image)"></i></button>

              <div nz-row [ngClass]="{'empty-border' : !image.thumb || image.loading , 'normal-border': image.thumb }">
                <div nz-col nzSpan="3" class="white-back"
                  *ngIf="!image.loading  && image.thumb || image.remote_image_url">
                  <i class="drag" nz-icon nzType="more" nzTheme="outline"></i>
                  <i class="drag drag-pair" nz-icon nzType="more" nzTheme="outline"></i>
                </div>
                <div nz-col nzSpan="21" class="image-holder image-container dark-image"
                  *ngIf="image.thumb || image.remote_image_url || image.id">
                  <div class="image-contain">
                    <img [src]=" image.thumb ? image.thumb : (image.remote_image_url || image.original)" />
                    <div class="selection"
                      *ngIf="(!config.isCoverPhotos && (image.thumb || image.stock_photo_id) && image.order >=0 && image.order !== null && image.order <= 4) || (config.isCoverPhotos  && image.order === 0 && (image.thumb || image.stock_photo_id))">
                      {{ image.order + 1 }}</div>
                    <div class="selection-wait" *ngIf="image.thumb && image.order == null"></div>
                    <nz-spin nzSimple *ngIf="image.delete_loading" class="delete-spin"></nz-spin>

                  </div>
                  <!--                <div  class="selection" >-->
                  <!--                </div>-->
                </div>

                <div nz-col nzSpan="21" class="image-holder image-container" *ngIf="image.loading">
                  <div class="image-contain">
                    <nz-spin nzSimple></nz-spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #stockPhotos>
    <h1 mat-dialog-title>Pick an Image</h1>
    <div mat-dialog-content>
      <nz-tag *ngFor="let filter of imageFiltersAvalible" nzMode="checkable"
        [nzChecked]="imageFilters.indexOf(filter) > -1" (nzCheckedChange)="toggleFilter($event, filter)"
        class="tag-filter">
        {{filter.name.capitalize()}}
      </nz-tag>
      <nz-divider></nz-divider>
      <!-- infinite scroll div -->
      <div *ngIf="stockImages">
        <div class="scollable-section" nz-row nzJustify="space-around" infinite-scroll
          [infiniteScrollDistance]="modalScrollDistance" [infiniteScrollThrottle]="modalScrollThrottle"
          [scrollWindow]="false" (scrolled)="onModalScrollDown()" *ngIf="reload">
          <div *ngFor="let image of stockImages; index as i">


            <div class="categories-item" cdkDropList cdkDropListOrientation="horizontal"
              [cdkDropListData]="{image:image,index:i}" (cdkDropListDropped)="drop($event, true)">
              <div class="inner" cdkDrag>
                <div nz-col nzSpan="21" class="image-holder image-container" *cdkDragPlaceholder>
                  <div class="image-contain">
                    <nz-spin nzSimple></nz-spin>
                  </div>
                </div>
                <div class="image-card overflow-visible" nz-row nzType="flex" nzJustify="center">
                  <div nz-row class="normal-border">
                    <div nz-col nzSpan="3" class="white-back">
                      <i class="drag" nz-icon nzType="more" nzTheme="outline" *ngIf="image.large"></i>
                      <i class="drag drag-pair" nz-icon nzType="more" nzTheme="outline" *ngIf="image.large"></i>
                    </div>
                    <div nz-col nzSpan="21" class="image-holder image-container" *ngIf="image.large">
                      <div class="image-contain">
                        <img [src]="image.large" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nz-spin [nzSize]="'large'" *ngIf="stockLoading" class="stock-image-loader">
      </nz-spin>
      <nz-empty nzNotFoundContent="No Image Found!" *ngIf="stockImages && stockImages.length === 0" class="no-content">
      </nz-empty>
    </div>

  </ng-template>
</div>
