import {Resource} from '../resource/resource';


export class Caption extends Resource {
  id: string;
  text: string;
  caption_type: string;
  fields: string[];
  title: string;

}
