<div class="container">
  <div *ngIf="account && account.name">
  <div nz-row nzType="flex" nzJustify="space-between" class="title-container">
    <h1 class="section-title title">
      <nz-avatar class="user-icon-button mat-elevation-z8" nzIcon="user" style="margin-right: 8px;"></nz-avatar>
      Account: ({{account.name}})
    </h1>
    <div class="edit-container">
      <a (click)="teamModalFormOpen()">
        <i nz-icon [style.color]="'#fff'" nzType="edit" nzTheme="outline"></i>
      </a>
    </div>
  </div>
  <br/>
<!--  <div nz-row nzType="flex" nzJustify="space-between" class="title-container" style="margin: 41px;">-->
<!--    <i nz-icon nzType="phone" nzTheme="outline"></i>-->
<!--    {{customer.team_phone}}-->
<!--  </div>-->
  <nz-divider></nz-divider>
  <div nz-row>
    <div nz-col nzSpan="12">
      <h1 class="section-title section-title-small" >Account members</h1>
<!--      <h5 class="section-description">Please input Account member details, 4 members are allowed</h5>-->
    </div>
    <div nz-col nzSpan="4" nzOffset="8">
<!--      [disabled]="this.customer.team_members_attributes.length >= 4"-->
      <button nz-button  nzType="primary" (click)="teamMemberFormSubmitSet()" >Invite Member</button>
    </div>
  </div>

    <div nz-col nzSpan="12" *ngIf="customer && customer.allow_stripe_for_team">

      <nz-alert
        nzShowIcon
        nzType="info"
        nzMessage="Buy Seats!"
        [nzDescription]="descriptionTemplate2"
      >
      </nz-alert>
      <ng-template #descriptionTemplate2>
        <p>You need to buy seat before you can Invite your team members!</p>
        <nz-space nzDirection="vertical">
          <button *nzSpaceItem nz-button nzSize="small" nzType="primary" nz-dropdown [nzDropdownMenu]="menu">Buy Now!
            <span nz-icon nzType="down"></span></button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="buySeats('per-seat-monthly')">
                <a>Monthly</a>
              </li>
              <li nz-menu-item (click)="buySeats('per-seat-yearly')">
                <a>Yearly</a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </nz-space>
      </ng-template>
    </div>

  <br />
  <br />
  <nz-table nzTemplateMode>
    <thead>
    <tr>
      <th>Email</th>
      <th>Invitation</th>
      <th>Invited At</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of account.accepted" class="editable-row">
      <td>
        <div class="editable-cell">
          {{ data.email }}
        </div>
      </td>
      <td>
        <div class="editable-cell">
          {{ data.invitation_accepted ? "Accepted" : "Pending" }}
        </div>
      </td>
      <td>
        <div class="editable-cell">
          {{ data.invitation_sent_at }}
        </div>
      </td>
      <td>
        <button nz-button nzType="primary" (click)="removeAccountMember(data.id);">Remove</button>
        <span *ngIf="!data.invitation_accepted" style="margin: 5px">
          <button nz-button nzType="primary" (click)="resendInvite(data.id);">Resend invite!</button>
        </span>
      </td>
    </tr>
    </tbody>
  </nz-table>


  <!-- <app-sidebar> -->
<!--<nz-collapse nzBordered="false" class="is_mobile">-->
<!--  <nz-collapse-panel nzActive [nzHeader]="creation">-->
<!--    <ng-container *ngTemplateOutlet="creationCards"></ng-container>-->
<!--  </nz-collapse-panel>-->
<!--</nz-collapse>-->

<!--<div class="is_desktop">-->

<!--  <div class="title-header" *ngTemplateOutlet="creation"></div>-->
<!--  <ng-container *ngTemplateOutlet="creationCards"></ng-container>-->
<!--</div>-->

<!--    CREATION CARD HEADING & TEMPLATE   -->
<ng-template #creation>
  <nz-divider></nz-divider>
  <!-- <h1 class="sec-title title-space">
    Create Content
  </h1> -->
</ng-template>
<!--<ng-template #creationCards>-->
<!--  <div nz-row [nzGutter]="8">-->
<!--    <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="products-container" *ngFor="let product of productsConfigs">-->
<!--      <app-card-button [config]="product" cardType="product-card"></app-card-button>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
  <nz-modal
    [(nzVisible)]="modalFormVisible"
    nzTitle="Invite Team Member"
    nzCentered
    [nzFooter]="modalFooter"
    (nzOnCancel)="modalFormCancel()"
    [nzContent]="modalContent">

    <ng-template #modalContent>
      <nz-steps [nzCurrent]="current" *ngIf="member.new">
        <nz-step nzTitle="Basic Information"></nz-step>
      </nz-steps>
      <br/>
      <br/>
      <div *ngIf="current == 0">
        <form nz-form [formGroup]="validateTeamMemberForm">
<!--        <nz-form-item>-->
<!--          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">First Name</nz-form-label>-->
<!--          <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--            <input nz-input formControlName="name" [(ngModel)]="member.fname" />-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--          <nz-form-item>-->
<!--            <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">Last Name</nz-form-label>-->
<!--            <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--              <input nz-input formControlName="name" [(ngModel)]="member.lname" />-->
<!--            </nz-form-control>-->
<!--          </nz-form-item>-->
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber" nzRequired>Email</nz-form-label>
          <nz-form-control
            [nzSm]="14"
            [nzXs]="24">
            <input nz-input formControlName="email" [(ngModel)]="member.email" />
          </nz-form-control>
        </nz-form-item>
<!--        <nz-form-item>-->
<!--          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="title">Phone</nz-form-label>-->
<!--          <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--            <input nz-input formControlName="phone"  [(ngModel)]="member.phone"/>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--        <nz-form-item>-->
<!--          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="title">Title</nz-form-label>-->
<!--          <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--            <input nz-input formControlName="title"  [(ngModel)]="member.title"/>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--        <nz-form-item>-->
<!--          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="License">License</nz-form-label>-->
<!--          <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--            <input nz-input formControlName="license" [(ngModel)]="member.license" />-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->

<!--        <nz-form-item>-->
<!--          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="website">Website</nz-form-label>-->
<!--          <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--            <input nz-input formControlName="website_url" [(ngModel)]="member.website_url" />-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
        </form>


      </div>

    </ng-template>
    <ng-template #modalFooter>
      <div nz-row>
        <div nz-col nzSpan="4">
         <button nz-button nzType="default" (click)="modalFormCancel()">Cancel</button>
        </div>
        <div nz-col nzSpan="8" nzOffset="12">
         <button nz-button nzType="primary" (click)="teamMemberFormSubmit()" [nzLoading]="saving">{{member.new ? 'Create' : 'Update'}}</button>
<!--          <button nz-button nzType="primary" *ngIf="current == 1" (click)="modalFormCancel()">Done</button>-->
        </div>
       </div>

    </ng-template>
  </nz-modal>
  </div>

  <div class="image-holder" *ngIf="account && !account.name">
    <img src="../../../assets/images/listings-empty.png" />
    <h2 class="create-title mar-zero">Create your Team Account!</h2>
<!--    <p class="sub-title">After creating team, you can add team members.-->
<!--    </p>-->
    <button nz-button nzType="primary" (click)="teamModalFormOpen();">Let's Go</button>
  </div>



  <nz-modal
    [(nzVisible)]="teamModalFormVisible"
    nzTitle="Account Details"
    nzCentered
    [nzFooter]="teamModalFooter"
    (nzOnCancel)="teamModalFormCancel()"
    [nzContent]="teamModalContent">

    <ng-template #teamModalContent>
      <form nz-form [formGroup]="validateForm">
        <div>
<!--          <div>-->
<!--            <div class="label-text" style="margin-left: 23px;">Team Photo:</div>-->
<!--            <app-image-handler [content]="customer" (imageObject)="setTeamPhoto($event)" [imageable_type]="'team_photo'" [config]="teamPhotoConfig"></app-image-handler>-->
<!--          </div>-->

          <div>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24"  nzRequired nzFor="email">Team Name</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your Team name!">
                <input nz-input formControlName="team_name" id="email" [(ngModel)]="account.name" />
              </nz-form-control>
            </nz-form-item>
<!--            <nz-form-item>-->
<!--              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber" nzRequired>Team Phone</nz-form-label>-->
<!--              <nz-form-control [nzSm]="14" [nzXs]="24"-->
<!--                               [nzValidateStatus]="validateForm.controls['phoneNumber']"-->
<!--                               nzErrorTip="Please input your Team phone!">-->
<!--                <input nz-input formControlName="team_phone" id="phone"  [(ngModel)]="customer.team_phone"/>-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template #teamModalFooter>
      <div nz-row>
        <div nz-col nzSpan="4">
          <button nz-button nzType="default" (click)="teamModalFormCancel()">Cancel</button>
        </div>
        <div nz-col nzSpan="8" nzOffset="12">
          <button nz-button nzType="primary" *ngIf="current == 0" (click)="teamFormSubmit()" [nzLoading]="saving">Save</button>
        </div>
      </div>

    </ng-template>
  </nz-modal>


</div>
