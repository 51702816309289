import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { Customer, TeamMember } from '../../vos/customer/customer';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { CardButtonConfig } from '../../models/interfaces';
import { CustomersService } from '../../services/customers/customers.service';
import { AccountMembersService } from '../../services/account-members/account-members.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { Product } from 'src/app/vos/product/product';
import { environment } from 'src/environments/environment';
import {GenericDialogComponent} from '../../shared/generic-dialog/generic-dialog.component';
import {GenericDialogConfig} from '../../models/interfaces/modals/generic-modal-config';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-team-members-form',
  templateUrl: './account-members-form.component.html',
  styleUrls: ['./account-members-form.component.scss'],
})
export class AccountMembersFormComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  validateTeamMemberForm!: UntypedFormGroup;
  productsConfigs: CardButtonConfig[] = [];
  customer: Customer;
  i = 0;
  editId: string | null = null;
  loading = false;
  modalFormVisible = false;
  teamModalFormVisible = false;
  saving = false;
  current = 0;
  member: any;

  accountName: null;
  account: any;

  constructor(private fb: UntypedFormBuilder,
              private authService: AuthenticationService,
              private message: NzMessageService,
              private cd: ChangeDetectorRef,
              private accountMembersService: AccountMembersService,
              private breadcrumService: BreadCrumService,
              private globalsService: GlobalsService,
              private customerService: CustomersService,
              private router: Router,
              private modalService: NzModalService,
  ) { }

  ngOnInit(): void {
    this.breadcrumService.set_breadcrum();

    this.getCustomerAccount();
    // this.mapTeamProducts();
    this.authService.refresh()
      .subscribe(c => {
        this.customer = c;
      });


    this.validateForm = this.fb.group({
      // team_phone: [null, [Validators.required]],
      team_name: [null, [Validators.required]],
    });
    this.validateTeamMemberForm = this.fb.group({
      // name: [null, [Validators.required]],
      email: [null, [Validators.required]]
    });

  }

  getCustomerAccount(){
    this.accountMembersService.details().subscribe(res => {
      this.account = res;
    });
  }

  startEdit(id: any): void {
    // this.editId = id;
  }

  stopEdit(): void {
    this.editId = null;
  }

  addRow(): void {
    this.modalFormVisible = true;
  }
  update(member) {
    this.accountMembersService.update(member).subscribe((response) => {
      if (response) {
        this.message.create('success', 'Success! Team member updated.');
        this.modalFormVisible = false;
      }
    },
      error => {
        this.showError(error);
      });
  }
  create(member) {
    this.accountMembersService.invite({customer: {name: member.name, email: member.email} }).subscribe((response) => {
      if (response) {
        // this.member = response;
        // this.member.new = true;
        this.message.create('success', 'Success! Account member Invited.');
        this.saving = false;
        // this.current = 1;
        this.modalFormVisible = false;
        this.getCustomerAccount();
      }
    },
      error => {
        this.showError(error);
      });
  }

  saveRow(member) {
    // if (member.new) {
      this.create(member);
    // } else {
    //   this.update(member);
    // }
  }

  deleteRow(data): void {
    // @ts-ignore
    this.teamMembersService.destroy(data).subscribe((response) => {
      this.member.image.large = null;
      this.message?.create('success', 'Success! Team member Deleted.');
      this.customer.team_members_attributes = this.customer?.team_members_attributes?.filter(d => d.id !== data.id);
      this.cd.detectChanges();
    },
      error => {
        this.showError(error);
      });
  }
  showError(error) {
    let errorMessage = error.error && error.error.message ? error.error.message : error.error;
    errorMessage = (typeof errorMessage === 'object') ? JSON.stringify(errorMessage) : errorMessage;
    errorMessage = errorMessage?.replace(/[{()}]/g, '');
    this.message?.remove();
    this.message?.create('error', errorMessage);
  }

  setTeamPhoto(object) {
    this.cd.detectChanges();
    if (object && object.team_photo) {
      this.customer.team_photo.large = object.team_photo;
    }
    this.cd.detectChanges();
  }
  setTeamMemberPhoto(object, data) {
    this.cd.detectChanges();
    if (object && object.image) {
      this.modalFormVisible = false;
      this.authService.refresh()
        .subscribe(c => {
          this.customer = c;
          this.cd.detectChanges();
        });
      this.cd.detectChanges();
    }
  }

  get teamPhotoConfig() {
    const target = {
      customer_id: this.customer?.id
    };
    const config = {
      id: this.customer?.id,
      imageableType: 'Customer',
      type: 'team_photo',
      aspectRatio: 1,
      minWidth: 50,
      minHeight: 50,
    };
    return {
      configMeta: config,
      image: this.customer ? this.customer.team_photo.large : '',
      isNew: !!this.customer.team_photo.large,
      channel: 'TeamPhotoChannel',
      target
    };
  }
  modalFormCancel() {
    this.authService.refresh()
      .subscribe(c => {
        this.customer = c;
        this.cd.detectChanges();
      });
    this.modalFormVisible = false;
  }
  teamMemberFormSubmit() {
    // for (const i in this.validateTeamMemberForm.controls) {
    //   this.validateTeamMemberForm.controls[i].markAsDirty();
    //   this.validateTeamMemberForm.controls[i].updateValueAndValidity();
    // }
    if (this.validateTeamMemberForm.valid) {
      this.saveRow(this.member);
    }
  }
  teamMemberPhotoConfig(data) {
    const target = {
      team_member_id: data.id
    };
    const config = {
      id: data.id,
      imageableType: 'team_member_photo',
      type: 'team_member_photo',
      aspectRatio: 1,
      minWidth: 50,
      minHeight: 50,
    };
    return {
      configMeta: config,
      image: data && data.image && data.image.large ? data.image.large : '',
      isNew: !!data?.image?.large,
      channel: 'TeamMemberPhotoChannel',
      target
    };
  }

  teamMemberFormEdit(member) {
    this.member = member;
    this.current = 0;
    this.member.new = false;
    this.modalFormVisible = true;
  }
  teamMemberFormSubmitSet() {
    this.current = 0;
    this.member = new TeamMember();
    this.member.new = true;
    this.modalFormVisible = true;
  }

  teamModalFormCancel() {
    this.teamModalFormVisible = false;

  }
  teamModalFormOpen() {
    this.teamModalFormVisible = true;

  }

  teamFormSubmit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let service;
    if (this.validateForm.valid) {
      if (this.account && this.account.id) {
        service = this.accountMembersService.update({id: this.account.id, name: this.account.name});
      } else {
        service = this.accountMembersService.create({name: this.account.name});
      }
      service.subscribe((response) => {
          if (response) {
            this.message?.create('success', 'Success! Account saved.');
            this.teamModalFormVisible = false;
          }
        },
        error => {
          const errors = Object.keys(error.error).map((e) => e.replace('_', ' ').replace('.', ' ') + ' ' + error.error[e]);
          this.message?.remove();
          this.message?.create('error', error.join('.\n'));
        });
    }
  }

  mapTeamProducts() {
    const products: Product[] = this.globalsService.getTeamProducts().map((product) => new Product(product));
    this.productsConfigs = products?.map(pd => {
      const path = pd.path;
      const prd = pd.cardButton(this.customer, pd.parent_type[0]);
      prd.link = `team/${path}`;
      return prd;
    }
    );
  }

  buySeats(recuring){
    window.open(`${environment.api_url}/checkout?price=${recuring}&customer_email=${this.customer.email}`, '_blank');
  }

  removeAccountMember(id){
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete Account member?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.accountMembersService.remove({customer_id: id, account_id: this.account.id}).subscribe((response) => {
            if (response) {
              this.message.create('success', 'Success! Account member deleted!.');
              this.getCustomerAccount();
            }
          },
          error => {
            this.showError(error);
          });
      }
    });
  }

  resendInvite(id){
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to resend invitation email?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.accountMembersService.resendInvite({customer_id: id, account_id: this.account.id}).subscribe((response) => {
            if (response) {
              this.message.create('success', 'Success! Account member invitation Sent!.');
              this.getCustomerAccount();
            }
          },
          error => {
            this.showError(error);
          });
      }
    });
  }
}
