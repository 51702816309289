import {
  Component, OnInit, OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Notification } from '../../vos/notification/notification';
import { NotificationService } from '../../services/notification/notification.service';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Customer } from '../../vos/customer/customer';
import { ListingsService } from '../../services/listings/listings.service';
import { SocialPostDialogComponent } from '../../shared/social-post-dialog/social-post-dialog.component';
import { Content } from '../../vos/content/content';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ContentsService } from '../../services/contents/contents.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-dashboard-notification',
  templateUrl: './dashboard-notification.component.html',
  styleUrls: ['./dashboard-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardNotificationComponent implements OnInit, OnDestroy {
  loading = false;
  visible = false;
  notifications: Notification[];
  allNotifications: Notification[];
  subscription: Subscription;
  notificationSub;
  Dot = true;
  customer: Customer;
  content: Content = new Content();
  // Notifications
  newCount = 0;
  mark_all_clear: false;
  notificationPagination = {
    limit: 10,
    total: 100,
    page: 2,
    totalPages: 10
  };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdrf: ChangeDetectorRef,
    private notificationService: NotificationService,
    private cableService: ActionCableService,
    private pushNotification: NzMessageService,
    private authService: AuthenticationService,
    private notificationsService: NzNotificationService,
    private listingService: ListingsService,
    private modalService: NzModalService,
    private contentService: ContentsService
  ) { }
  ngOnInit() {
    this.notifications = this.notificationService?.allNotifications;
    this.allNotifications = this.notificationService.allNotifications;
    this.newCount = this.notificationService.unreadCount;

    this.notificationSub = this.notificationService.notificationsSub()
      .subscribe(res => {
        if (res) {
          this.notifications = res?.data;
          this.allNotifications = res.data;
          this.newCount = res['unopened_count'];
          this.notificationPagination.total = res['total_entries'];
          this.notificationPagination.page = 2;
          this.check_unread();
          this.cdrf.detectChanges();
        }
      });

    this.push_notification();
    // this.clearAll();
    this.refreshNotification();
    this.observeListingNotification();
  }
  ngOnDestroy() {
    if (this.notificationSub) {
      this.notificationSub
        .unsubscribe();
    }
  }

  push_notification() {
    this.customer = this.authService.currentCustomerValue;
    if (this.customer) {
      const channel: Channel = this.notificationService.pushNotification();
      // Subscribe to incoming messages
      this.subscription = channel.received().subscribe(message => {
        this.notificationService.fetchNotifications();
      });
    }
  }

  mark_read(notification, event?) {
    if (!notification?.opened) {
      if (event) {
        event.target.closest('nz-list-item')?.classList?.remove('un-read');
      }
      document.getElementById('notification-item-' + notification.id);
      this.notificationService.mark_read(notification.id).subscribe();
      this.newCount > 0 ? this.newCount -= 1 : this.newCount = 0;
    }
    this.visible = false;
    if (notification.key === 'content.linkedin.expired') {
      this.connectLinkedin(notification.notifiable_id);
    }
  }

  check_unread() {
    this.newCount = this.notificationService.unreadCount;
  }

  refreshNotification() {
    this.subscription = this.notificationService.clear_notification().subscribe(data => {
      if (data.mark_single) {
        const index = this.notifications?.map(e => e.id)?.indexOf(data.id);
        if (index > -1 && this.notifications[index]) {
          this.notifications[index].opened = 'true';
        }
        this.check_unread();
      } else {
        this.mark_all_clear = data.status;
        this.newCount = 0;
      }
    });
  }

  checkNotificationLink(notification) {
    return notification.group_member_count === 0 && notification.type === 'Website';
  }
  observeListingNotification() {
    this.subscription = this.listingService.clearListingNotification().subscribe(data => {
      const index = this.notifications?.map(e => e.notifiable_path)?.indexOf('listings/' + data.id);
      if (index !== -1) {
        this.allNotifications.splice(index, 1);
        this.notifications = this.allNotifications.slice(0, 5);
      }
    });
  }
  getNotificationIcon(notification) {
    if (notification.key === 'customer.linkedin.product') {
      return 'linkedin';
    } else if (notification.key === 'website.create.later') {
      return 'global';
    } else if (notification.key === 'listing.create.later') {
      return 'unordered-list';
    } else if (notification.key === 'customer.update.settings.auto_posting.later') {
      return 'setting';
    } else if (notification.key === 'content.update.later') {
      return 'play-square';
    } else if (notification.key === 'content.market_report.later') {
      return 'line-chart';
    } else if (notification.key === 'post.posted') {
      return 'share-alt';
    } else if (notification.key === 'lead.create.later') {
      return 'contacts';
    } else if (notification.key === 'customer.linkedin.expired') {
      return 'linkedin';
    } else if (notification.key === 'content.linkedin.expired') {
      return 'linkedin';
    } else if (notification.key === 'customer.linkedin.login') {
      return 'linkedin';
    } else {
      return 'notification';
    }
  }
  connectLinkedin(contentId) {
    this.fetchContent(contentId);
    const modal = this.modalService.create<SocialPostDialogComponent, IModalData>({
      nzContent: SocialPostDialogComponent,
      nzData: {
        content: this.content,
        hideFacebook: false
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
    });
  }

  fetchContent(contentId) {
    this.contentService.show(contentId).subscribe(content => {
      this.content = content.data;
    });
  }

  openNotification(item, event) {
    this.mark_read(item, event);
    this.openContentNotification(item);
    if (this.checkNotificationLink(item) && item.key !== 'content.linkedin.expired' && item.key !== 'content.market_report.later') {
      window.open(item.notifiable_path, '_blank');
    }
  }

  openContentNotification(item) {
    const queryParams = {};
    if (item.notifiable_path?.indexOf('selectedContent') > -1) {
      const afterEqual = item?.notifiable_path?.substring(item?.notifiable_path?.indexOf('=') + 1);
      queryParams['selectedContent'] = afterEqual;
    }
    if (item.notifiable_path?.indexOf('approval=true') > -1) {
      queryParams['approval'] = true;
    }
    if (item.key === 'customer.roi.insights') {
      queryParams['since'] = item.parameters['since_date'];
      queryParams['until_date'] = item.parameters['until_date'];
    }

    if (queryParams && queryParams['selectedContent']) {
      this.router.navigate([`/content`], { queryParams });
    } else if (item.key === 'customer.roi.insights') {
      this.router.navigate(['/roi_reports'], { queryParams });
    }
    else if (item.notifiable_path) {
      this.router.navigate([`/${item.notifiable_path}`]);
    }
  }
  onScroll() {
    if (this.notificationPagination.total > this.notifications.length) {
      this.fetchNotifications();
    }
  }
  fetchNotifications() {
    this.loading = true;
    const pageNo = this.notificationPagination.page;
    const query = `?page=${pageNo}`;
    this.notificationService.list(query).subscribe(n => {
      this.notificationPagination.limit = n['per_page'];
      this.notificationPagination.total = n['total_entries'];
      this.newCount = n['unopened_count'];
      const limit = this.notificationPagination.limit;
      const totalItems = this.notificationPagination.total;
      this.notificationPagination.totalPages = Math.ceil(totalItems / limit);
      n.data.forEach(element => this.notifications.push(element));
      this.notificationPagination.page = n['current_page'] + 1;
      this.loading = false;
      this.cdrf.detectChanges();
    });
  }
}
