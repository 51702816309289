import { Injectable } from '@angular/core';
import { Listing } from 'src/app/vos/listing/listing';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import {Observable, Subject} from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class ListingsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class ListingsService extends CustomerResourceService<Listing> {
  public endpoint = 'listings';
  public data_key = 'listing';
  private subject = new Subject<any>();

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Listing, http, authService);
  }
  clearListingNotification(): Observable<any> {
    return this.subject.asObservable();
  }
  sendClearListingNotification(data) {
    this.subject.next(data);
  }

  massDeleteListings(payload): Observable<any> {
    return this.http.post(
      `${this._customerURI}${this.customerEndpoint}/mass_delete`,
      payload
    );
  }
}
