<div class='container'>
  <div class='title-header'>
    <h1 class='section-title title'>
      <i nz-icon nzType='solution' ></i>
      Reels
    </h1>
  </div>
  <nz-steps [nzCurrent]="current">
    <nz-step nzTitle="Select Topic"></nz-step>
    <nz-step nzTitle="Record"></nz-step>
    <nz-step nzTitle="Post"></nz-step>
  </nz-steps>
  <div *ngIf="current == 0">
    <div nz-row>
      <div style="padding:30px;">
        <div nz-row [nzGutter]="8">
          <div *ngFor="let topic of topics;">
            <div nz-col [nzSpan]="8">
              <nz-card nzTitle='{{topic.category}}' style="width: 200px; height: 200px" (click)="selectTopic(topic)">
                <p>{{topic.title}}</p>
              </nz-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="current == 1">
    <ng-container [ngTemplateOutlet]="recordVideoTemplate"></ng-container>
  </div>
  <div *ngIf="current == 2">
    <div nz-row  style="padding-top: 40px">
      <div nz-col [nzSpan]="12">
        <video class="video" style="width:450px !important;" [src]="video_url" autoplay controls>
        </video>
        <ng-container [ngTemplateOutlet]="tiktok"></ng-container>

      </div>
      <div nz-col [nzSpan]="12">
        <h3>Review Subtitles</h3>
        <button nz-button nzType="primary"  (click)="saveSubtitles()" style="margin-bottom: 5px;" [nzLoading]="loading">Save & Reload!

        </button>
        <br>
        <div *ngFor="let trans of parsedSrt;">
<!--          <div>{{trans.startTime}} - {{trans.endTime}}-->
            <input nz-input  [(ngModel)]="trans['text']"  style="margin-bottom: 5px;"/>
<!--          </div>-->

        </div>
<!--        <textarea rows="8" columns="4" nz-input  [(ngModel)]="transscript" class="preview"></textarea>-->
      </div>
    </div>
  </div>
  <div class="steps-action" style="margin-top: 20px">
    <button nz-button nzType="default" (click)="pre()" *ngIf="current > 0">
      <span>Previous</span>
    </button>
    <button nz-button nzType="primary" class="pull-right" (click)="next()" *ngIf="current < 2">
      <span>Next</span>
    </button>
    <button nz-button nzType="primary" class="pull-right" (click)="submit()" *ngIf="current == 2">
      <span>Done</span>
    </button>
  </div>
</div>

<ng-template #recordVideoTemplate>
  <div nz-row  style="padding-top: 40px">
    <div nz-col [nzSpan]="12" [style.display]="recordVideo ? 'block': 'none' ">
      <div style="text-align:left;padding-bottom: 20px">
        <video class="video" #video autoplay [style.display]="!downloadUrl ? 'block': 'none' "></video>
        <br><br>
        <nz-spin [nzSize]="'medium'" *ngIf="loading">
        </nz-spin>
        <video class="video" style="width:450px !important;" controls #recordedVideo [style.display]="downloadUrl ? 'block': 'none' " [muted]="muted"></video>
<!--        <nz-slider nzRange [(ngModel)]="range" [nzMax]="duration" *ngIf="downloadUrl"></nz-slider>-->
        <br>
        <button nz-button nzType="primary" *ngIf="!isRecording" (click)="startRecording()" class="marging-right-20" nzDanger>
          <span nz-icon nzType="video-camera" nzTheme="outline"></span>
          Start Recording</button>
<!--        <button nz-button nzType="primary" (click)="recordVideo = false" >-->
<!--          <span nz-icon [nzType]="'upload'" ></span>Upload Video</button>-->

        <button nz-button nzType="primary" *ngIf="isRecording" (click)="stopRecording()" >
          <span nz-icon [nzType]="'sync'" [nzSpin]="true"></span>Stop</button>
<!--        <button nz-button nzType="primary" *ngIf="downloadUrl" (click)="trimVideo()">-->
<!--          <span nz-icon [nzType]="'sync'" [nzSpin]="cropping"></span>-->
<!--          Crop</button>-->
        <button nz-button nzType="primary" *ngIf="downloadUrl" (click)="uploadFile()">
          <span nz-icon [nzType]="'upload'" *ngIf="!loading"></span>
          <span nz-icon [nzType]="'sync'" [nzSpin]="loading"></span>
          Save </button>
      </div>
    </div>
    <div nz-col [nzSpan]="12" [style.display]="!recordVideo ? 'block': 'none' ">
      <div style="margin: 35px">
        <nz-upload
          nzType="drag"
          [nzMultiple]="false"
          [nzCustomRequest]="uploadCustomFile"
          [nzLimit]="0"
          [nzFileType]="'video/mp4,video/webm'"
        >
          <p class="ant-upload-drag-icon">
            <i nz-icon nzType="inbox"></i>
          </p>
          <p class="ant-upload-text">
            Click or drag  Video to this area to upload </p>
          <p class="ant-upload-hint">
            Allowed files mp4.
          </p>

        </nz-upload>
      </div>

      <div class="text-center " style="margin-top: 20px">
        <button nz-button nzType="primary" (click)="recordVideo = true">
        <span nz-icon nzType="video-camera"></span>Record Video</button>
      </div>

    </div>
    <div nz-col [nzSpan]="12">
      <h3 *ngIf="topics && currenTopic">Use Script to Record Video</h3>
      <textarea *ngIf="topics && currenTopic" rows="8" columns="4" nz-input  [(ngModel)]="currenTopic.script" [disabled]="true" class="preview"></textarea>
    </div>
  </div>
</ng-template>
<ng-template #tiktok>
  <div class="left-spacing margin-bottom">
    <label>Hastags: <p>these hastags will post to Instagram</p></label>
    <textarea rows="2" columns="4" nz-input [(ngModel)]="hashtags" style="margin-bottom: 5px;"></textarea>
    <label>Caption: <p>for Instagram posts</p></label>
    <textarea rows="4" columns="4" nz-input [(ngModel)]="caption"></textarea>

    <br>
    <span nz-typography>Select to share your content.</span>

    <ul class="social-selection">

      <li>
        <label nz-checkbox [(ngModel)]="tiktokSelected" [nzDisabled]="!tiktokConnected || !tiktokAllowed">
          <img class="box-image" src=".././../../assets/icons/tiktok.png" alt="" />
        </label>
        <span class="fw-600">Tiktok</span>
<!--        <button nz-button nzType="default" class="social-btns"-->
<!--                *ngIf="!tiktokConnected">Connect</button>-->
        <button nz-button nzType="default" class="btn-connected social-btns right-spacing"
                *ngIf="(tiktokConnected && tiktokAllowed)">Connected!</button>
<!--        <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"-->
<!--                *ngIf="!tiktokAllowed">Upgrade</button>-->
      </li>
      <li>
        <label nz-checkbox [(ngModel)]="instagramSelected" [nzDisabled]="!instagramConnected">
          <img class="box-image" src=".././../../assets/icons/instagram.png" alt="" />
        </label>
        <span class="fw-600">Instagram</span>
<!--        <button nz-button nzType="default" class="social-btns"-->
<!--                *ngIf="instagramStatus  && !instagramConnected" >Connect</button>-->

<!--        <button nz-button nzType="default" class="social-btns"-->
<!--                *ngIf="instagramStatus && instagramIntegration && !instagramConnected" >Refresh</button>-->

        <button nz-button nzType="default" class="btn-connected social-btns right-spacing"
                *ngIf="(instagramConnected && instagramAllowed)">Connected!</button>
<!--        <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"-->
<!--                *ngIf="!instagramStatus">Upgrade</button>-->
      </li>

    </ul>
  </div>

</ng-template>
