import { Pipe, PipeTransform } from '@angular/core';
import { Content } from 'src/app/vos/content/content';

@Pipe({
  name: 'contentCreated'
})
export class ContentCreatedPipe implements PipeTransform {

  transform(value: Content[], dates?: [Date, Date?]): any {
    return null;
  }

}
