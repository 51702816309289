import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { EmailSenderIdentityService } from '../../../services/email-sender-identity/email-sender-identity.service';
import { SenderIdentity } from '../../../vos/sender-identity/sender-identity';

@Component({
  selector: 'app-sender-identity-form',
  templateUrl: './sender-form.component.html',
  styleUrls: ['./sender-form.component.scss']
})

export class SenderFormComponent implements OnInit {
  @Input()
  sender: any;
  @Input()
  actionButton: any;
  @Input()
  showStatus: boolean = false;

  @Output()
  onSave = new EventEmitter();
  loading = false;
  constructor(
    private notification: NzNotificationService,
    private emailSenderIdentityService: EmailSenderIdentityService
  ) { }
  ngOnInit() {
  }

  reSendSenderVerification() {
    this.emailSenderIdentityService.reSendSenderVerification(this.sender.id)
      .subscribe(res => {
        if (res && res.data?.verified) {
          this.notification.create(
            'success',
            'Verified',
            'Sender Identity has been successfully verified.'
          );
        }
      }, err => {
        this.notification.create(
          'error',
          'Not verified',
          err.message || 'Sender Identity is not verified.'
        );
        console.error(err);
      })
  }


  CreateSender(valid) {
    if (valid) {
      this.emailSenderIdentityService.create(this.sender)
        .subscribe(res => {
          this.sender = res;
          this.onSave.emit({ action: 'created', data: this.sender });
        });
    }
  }

  updateIdenity(valid) {
    if (valid) {
      this.emailSenderIdentityService.update(this.sender, this.sender.id)
        .subscribe(res => {

          this.notification.create(
            'success',
            'Success',
            'Sender Identity has been successfully updated.'
          );
          this.onSave.emit({ action: 'updated', data: this.sender });
        }, err => {
          this.notification.create(
            'error',
            'Error',
            err.message || 'Sender Identity has been failed.'
          );
          console.error(err);
        });
    }
  }

  DeleteSender() {
    this.emailSenderIdentityService.destroy(this.sender.id)
      .subscribe(res => {
        this.resetSender();
        this.onSave.emit({ action: 'deleted', data: this.sender });
      });
  }
  next() {
    this.onSave.emit({ action: 'next', data: this.sender });
  }

  resetSender() {
    this.sender = new SenderIdentity();
  }
}
