import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap, concatMap, toArray, map } from 'rxjs/operators';
import { of, from, forkJoin } from 'rxjs';

import { Contact } from '../../vos/contact/contact';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class ContactsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class ContactsService extends CustomerResourceService<Contact> {
  public endpoint = 'contacts';
  public data_key = 'contact';

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Contact, http, authService);
  }

  updateContact(payload: any): Observable<any>{
    return this.http.put(
      `${this._uri}${this.endpoint}/${payload.id}`,
      payload.data,
    );
  }

  deleteContacts(payload): Observable<any> {
    return this.http.post(
      `${this._customerURI}${this.customerEndpoint}/destroy_mutiple`,
      payload,
    );
  }
}
