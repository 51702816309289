<div class="container">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="10" >
      <form #campaignForm="ngForm" nz-form>
        <h3 class="margin-top-0" *ngIf="htmlDataMap">
          <nz-divider class="margin-top-0" nzText="Sender Details"></nz-divider>
          </h3>
        <div class="data-fields" *ngIf="htmlDataMap">
          <div class="customer-form " nz-row nzType="flex" nzJustify="space-between" *ngFor="let k of senderDataKeys">
            <nz-form-item class="gutter-row" nz-col nzSpan="24">
              <nz-form-label class="titelize" [nzFor]="k.key" [nzRequired]="k.required">{{k.displayKey}}
              </nz-form-label>
              <nz-form-control [nzErrorTip]="errormsg">
                <nz-input-group>
                  <input type="text" nz-input id="{{k.key}}" (ngModelChange)="mapTestDataFields();" [(ngModel)]="htmlDataMap[k.key]" name="{{k.key}}" [required]="k.required" [placeholder]="k.displayKey"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <ng-template #errormsg>{{k.displayKey}} is required!</ng-template>

          </div>
        </div>
      
        <h3 *ngIf="htmlDataMap">
          <nz-divider nzText="Market Details"></nz-divider>
         </h3>
        <div class="data-fields" *ngIf="htmlDataMap">

        <div class="customer-form" nz-row nzType="flex" nzJustify="space-between" *ngFor="let k of marketDataKeys">
          <nz-form-item class="gutter-row" nz-col nzSpan="24">
            <nz-form-label class="titelize" [nzFor]="k.key" [nzRequired]="k.required">{{k.displayKey}}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="errormsg">
              <nz-input-group>
                <input type="text" nz-input id="{{k.key}}" (ngModelChange)="mapTestDataFields();" [(ngModel)]="htmlDataMap[k.key]" name="{{k.key}}" [required]="k.required" [placeholder]="k.displayKey"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <ng-template #errormsg>{{k.displayKey}} is required!</ng-template>

        </div>
        </div>
      </form>
    </div>
    <nz-divider nzType="vertical" class="template-seperator"></nz-divider>
    <div nz-col nzSpan="14" class="preview-container" id="img-conatiner">
        <iframe #iframe frameborder="0" width="102%" height="100%" style="border:none">
          </iframe>
    </div>
  </div>

</div>
