export class Image {
  /**
   * id
   */
  id?: number;

  /**
   * url for new image
   */

  /**
   * original
   */
  original?: string;

  /**
   * thumb
   */
  thumb?: string;

  /**
   * large
   */
  large?: string;

  order?: number | null;
  remote_image_url?: string;
  tags?: string;
  stock_photo_id?: number;
  uid?: string;
  loading?: boolean;
  changed?: boolean;
}
