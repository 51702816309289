import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardButtonConfig } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { Listing } from 'src/app/vos/listing/listing';
import { MarketReportsService } from 'src/app/services/market-reports/market-reports.service';
import { MarketReport } from 'src/app/vos/market-report/market-report';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { PaginationService } from 'src/app/services/pagination/pagination.service';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { MarketReportFormModalComponent } from '../market-reports/market-report-form-modal/market-report-form-modal.component';
import { TestimonyService } from 'src/app/services/testimonies/testimonies.service';
import { Testimony } from 'src/app/vos/testimony/testimony';

@Component({
  selector: 'app-reach-people',
  templateUrl: './select-resource.component.html',
  styleUrls: ['./select-resource.component.scss']
})
export class SelectResourceComponent implements OnInit {
  loading = true;

  @Output() change = new EventEmitter<boolean>();
  @Input() model;
  @Input() type;
  @Input() name;
  @Input() selectedItem;
  // allListings: Listing[] = [];
  listings: Listing[] = [];
  marketreports: MarketReport[] = [];
  testimonies: Testimony[] = [];

  selectedResourceid;
  resources: [];
  customer: Customer;
  productName: string;
  productTitle: string;
  productType: string;
  productTypeTitle: string;
  newButtonLink: string;
  search = false;
  resourceLength = 0;
  sortings = ['address', 'region_name'];
  searchText: string;
  mapOfSort: { [key: string]: string | null } = {
    created_at: null,
    address: null,
    region_name: null
  };
  selectedSort = {
    by: 'address',
    order: 'asc'
  };
  showEmpty = false;
  isListing = false;
  isMarketing = false;
  isInformative = false;
  currentRouteName;
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private listingService: ListingsService,
    private breadcrumService: BreadCrumService,
    private marketreportService: MarketReportsService,
    private globalsService: GlobalsService,
    private router: Router,
    private paginationService: PaginationService,
    private modalService: NzModalService,
    private testimonyService: TestimonyService,
  ) {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        // this.listingPagination.page = 1;
        if (this.isTestimonial) {
          this.fetchTestimonies(newText);
        } else if (this.productType === 'listing') {
          if (newText) {
            this.search = true;
            this.searchContent(newText);
          } else {
            this.fetchContent();
          }
        } else {
          if (newText) {
            this.search = true;
            this.fetchMarketReports(newText);
          } else {
            this.fetchMarketReports();
          }
        }

      });
  }
  pageSizeOptions = [10];
  listingPagination = {
    limit: 10,
    total: 10,
    page: 1,
    totalPages: 10
  };
  ConfigListingTitle;
  newCardButton: CardButtonConfig;
  newMarketBtnConfig: CardButtonConfig;
  ngOnInit() {
    this.checkRouting();
    if (this.selectedItem) {
      this.selectedResourceid = this.selectedItem.id
    }
    this.productType = this.type;
    this.productName = this.name;
    this.setProductTitels();
    this.route.queryParams.subscribe(params => {
      this.productType = this.type || params['type'];
      this.setProductTitels();
    });
    this.route.params.subscribe(params => {
      this.productName = params['name'] || this.type;
      // this.productType = this.productType || this.type || params['type'];
      this.setProductTitels();
    });
    this.customer = this.authService.currentCustomerValue;

    this.authService.currentCustomer.subscribe(c => {
      if (c) {
        this.customer = c;
      }
    });
    this.breadcrumService.set_breadcrum();
    // this.breadcrumService.push_breadcrum({ name: this.ConfigListingTitle.title });
  }

  setProductTitels() {
    this.productTitle = this.globalsService.normalizeTitle(this.productName);
    this.productTypeTitle = this.globalsService.normalizeTitle(this.productType);
    if (this.isTestimonial) {
      this.fetchTestimonies();
    } else if (this.productType === 'listing') {
      this.fetchContent();
    } else {
      this.fetchMarketReports();
    }
    this.newCardButton = this.globalsService.getNewCard(this.productType, this.productName);
    this.ConfigListingTitle = {
      title: this.productTitle,
      class: 'section-title',
      icon: 'home',
      colors: '#30d289',
    };
  }
  didPage(pageIndex) {
    this.listingPagination.page = pageIndex;
    this.loading = true;
    if (this.productType === 'listing') {
      this.fetchContent();
    } else {
      this.fetchMarketReports();
    }
  }
  getLink(resourceId) {
    return `${this.productType}/${resourceId}/${this.productName}/new`;
  }
  checkRouting() {
    this.isListing = this.router.url.indexOf('listings') > -1;
    this.isMarketing = this.router.url.indexOf('marketing') > -1;
    this.isInformative = this.router.url.indexOf('informative') > -1;
    if (this.isListing) {
      this.currentRouteName = 'listings';
    }
    if (this.isMarketing) {
      this.currentRouteName = 'marketing';
    }
    if (this.isInformative) {
      this.currentRouteName = 'informative';
    }
  }

  selectTestimony(resource) {
    this.router.navigate([`create_content/${this.currentRouteName}/${this.productName}/${resource.id}/new`], { queryParams: { type: this.productType } });
  }
  select(resource) {
    if (this.type) {
      this.selectedResourceid = resource.id;
      this.change.emit(resource);
    } else {
      this.router.navigate([`create_content/${this.currentRouteName}/${this.productName}/${resource.id}/new`], { queryParams: { type: this.productType } });
    }
  }
  getTitle(resource) {
    return this.productType === 'listing' ? resource.address : `${resource.region_name} (${resource.location_attributes.zip_code})`;
  }
  sort(sort: { key: string; value: string }): void {
    for (const key in this.mapOfSort) {
      this.mapOfSort[key] = key === sort.key ? sort.value : null;
    }
    const valuesMap = {
      descend: 'desc',
      ascend: 'asc'
    };
    if (this.productType === 'listing') {
      this.fetchContent(null, `q[s]=${sort.key} ${valuesMap[sort.value]}`);
    } else {
      this.fetchMarketReports(null, `q[s]=${sort.key} ${valuesMap[sort.value]}`);
    }
  }
  fetchContent(text?, sort?) {
    this.loading = true;
    if (this.searchText || text) {
      this.searchContent(this.searchText);
      return;
    }
    let query = `?page=${this.listingPagination.page}`;
    if (sort) {
      query = `${query}&${sort}`;
    }
    if (text) {
      query = `${query}&q[address_cont]=${text}`;
    }
    this.listingService.listingsList(this.productName, query).subscribe(response => {
      if (response) {
        this.listingPagination.limit = response['per_page'];
        this.listingPagination.total = response['total_entries'];
        const limit = this.listingPagination.limit;
        const total_items = this.listingPagination.total;
        this.listingPagination.totalPages = Math.ceil(total_items / limit);
        this.listings = response.data;
        this.resourceLength = response.data.length;
        this.showEmpty = response.data.length === 0;
        this.loading = false;
      }
    }, err => {
      this.loading = false;
    }
    );
  }

  searchContent(text?, sort?) {
    this.loading = true;
    let query = `?page=${this.listingPagination.page}`;
    if (sort) {
      query = `${query}&${sort}`;
    }
    if (text) {
      query = `${query}&q[address_cont]=${text}`;
    }
    this.listingService.list(query).subscribe(response => {
      if (response) {
        this.listingPagination.limit = response['per_page'];
        this.listingPagination.total = response['total_entries'];
        const limit = this.listingPagination.limit;
        const total_items = this.listingPagination.total;
        this.listingPagination.totalPages = Math.ceil(total_items / limit);
        this.listings = response.data;
        this.resourceLength = response.data.length;
        this.showEmpty = response.data.length === 0;
        this.loading = false;
      }
    });
  }
  fetchMarketReports(text?, sort?) {
    this.loading = true;
    let query = `?page=${this.listingPagination.page}`;
    if (sort) {
      query = `${query}&${sort}`;
    }
    if (text) {
      query = `${query}&q[region_name_cont]=${text}`;
    }
    this.marketreportService.list(query).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.listingPagination.limit = response['per_page'];
        this.listingPagination.total = response['total_entries'];
        const limit = this.listingPagination.limit;
        const total_items = this.listingPagination.total;
        this.listingPagination.totalPages = Math.ceil(total_items / limit);
        this.marketreports = response.data;
        this.resourceLength = response.data.length;
        this.showEmpty = response.data.length === 0;
      }
    });
  }

  fetchTestimonies(text?, sort?) {
    this.loading = true;
    let query = `?page=${this.listingPagination.page}`;
    if (sort) {
      query = `${query}&${sort}`;
    }
    if (text) {
      query = `${query}&q[attestant_name_cont]=${text}`;
    }
    this.testimonyService.list(query).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.listingPagination.limit = response['per_page'];
        this.listingPagination.total = response['total_entries'];
        const limit = this.listingPagination.limit;
        const total_items = this.listingPagination.total;
        this.listingPagination.totalPages = Math.ceil(total_items / limit);
        this.testimonies = response.data;
        this.resourceLength = response.data.length;
        this.showEmpty = response.data.length === 0;
      }
    });
  }

  createNewResource() {
    if (this.productType.includes('testimonial')) {
      return this.router.navigateByUrl(`testimonials/new/${this.productType}`);
    } else if (this.ConfigListingTitle?.title === 'Commercial Listing Videos') {
      return this.router.navigateByUrl('listings/new/commercial_listing_videos');
    } else if (this.productType === 'listing') {
      return this.router.navigateByUrl('listings/new');
    } else {
      this.createMarketReport();
    }
  }

  public createMarketReport() {
    const modal = this.modalService.create({
      nzContent: MarketReportFormModalComponent,
      nzFooter: null,
    });
    modal.afterClose.subscribe((response) => {
      this.fetchMarketReports();
    });
  }
  get isTestimonial() {
    return this.productType?.includes('testimonial');
  }
}
