import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Resource } from '../vos/resource/resource';
import { ListingsService } from './listings/listings.service';
import { CustomersService } from './customers/customers.service';
import { ContentsService } from './contents/contents.service';
import { MarketReportsService } from './market-reports/market-reports.service';
import { map } from 'rxjs/operators';
import { ResourceService } from './customer-resource.service';

@Injectable({
  providedIn: 'root'
})
export class TypeResolverService  {

  constructor(
    private listingService: ListingsService,
    private customerService: CustomersService,
    private contentService: ContentsService,
    private marketReportService: MarketReportsService

  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ) {
    const id = route.params['id'];
    const dirtyType = route.queryParams.type || route.params['type'];
    const service = this.serviceForType(dirtyType);
    return service.show(id).pipe(
      map((res) => {
        return { object: res.data, type: this.standardizedType(dirtyType)};
      })
    );
  }

  serviceForType(type: string): ResourceService<Resource> {
    switch (type) {
      case 'listing':
      case 'listings':
        return this.listingService;
      case 'content':
      case 'contents':
        return this.contentService;
      case 'marketReport':
      case 'my_markets':
      case 'market_report':
      case 'report':
      case 'reports':
        return this.marketReportService;
      default:
      return this.customerService;
    }
  }

  standardizedType(type: string): string {
    switch (type) {
      case 'listing':
      case 'listings':
        return 'Listing';
      case 'content':
      case 'contents':
        return 'Content';
      case 'marketReport':
      case 'my_markets':
      case 'market_report':
      case 'report':
      case 'reports':
        return 'Content';
      case 'customer':
      case 'customers':
      return 'Customer';
      default:
      return undefined;
    }
  }
}
