import { Component, OnInit, Input, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-listing-dialog',
  templateUrl: './listing-dialog.component.html',
  styleUrls: ['./listing-dialog.component.scss']
})
export class ListingDialogComponent implements OnInit {
  @Input() listings: any;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private modal: NzModalRef
  ) { }

  ngOnInit(): void {
    this.listings = this.nzModalData.listings;
  }

  selectedListing(listing: Object) {
    this.modal.close(listing);
  }

}
