import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { EmailDomainService } from '../../../services/email-domain/email-domain.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { BreadCrumService } from '../../../services/breadcrum/bread-crum.service';
import { SenderIdentity } from '../../../vos/sender-identity/sender-identity';
import { EmailSenderIdentityService } from '../../../services/email-sender-identity/email-sender-identity.service';
import { GlobalsService } from '../../../services/globals/globals.service';
import { JwtService } from '../../../services/jwt/jwt.service';
import { ProductsService } from '../../../services/products/product-api.service';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})

export class EmailSettingsComponent implements OnInit, AfterViewInit {
  domainData;
  dnsKeys;
  showStatus = true;
  selectedTab = 'sender-identity';
  sender = new SenderIdentity();
  isDomainVerified = false;
  loading = true;
  current = 0;
  myDomain;
  constructor(
    private router: Router,
    private emailDomainService: EmailDomainService,
    private message: NzMessageService,
    private authService: AuthenticationService,
    private breadcrumservice: BreadCrumService,
    private modal: NzModalService,
    private emailSenderIdentityService: EmailSenderIdentityService,
    private globalsService: GlobalsService,
    private jwtService: JwtService,
    private productsService: ProductsService,
  ) { }
  ngOnInit() {
    // this.fetchEmailSenderIdentities();
    this.breadcrumservice.set_breadcrum();
    this.loading = true;

    // const allowedEmails = this.globalsService.getEmailCustomers();
    const c = this.jwtService.getSavedCustomer();
    if (!c) {
      this.authService.logout();
      this.router.navigate(['/login']);
      return;
    }
    const params: Record<string, any> = {
      'per_page': 40,
    };
    this.productsService.productList(
      `customers/${c?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const emailProduct = data ? data?.find(p => p.abbreviation === 'PML') : null;
        if (emailProduct != null && !emailProduct.locked) {
          this.authService.getEmailAuthentication()
            .subscribe(res => {
              const emailAuth = res.data;
              if (emailAuth && emailAuth.id && emailAuth.user_id_on_provider) {
                this.fetchEmailSenderIdentities();
              } else {
                this.goToOnboarding();
              }
            }, (err) => {
              this.goToOnboarding();
              this.loading = false;
            });
        }
      } else {
        this.router.navigateByUrl('/404');
      }
    }, err => {
      this.loading = false;
    })
    // if (allowedEmails.indexOf(c.id) === -1) {
    //   this.router.navigateByUrl('/404');
    // } else {

    // }
  }
  ngAfterViewInit() {
  }

  gotoStep(stepNumber) {
    if (this.current > stepNumber) {
      this.current = stepNumber;
    }
  }

  emailAuth() {
    this.loading = true;
    this.authService.createEmailAuthentication()
      .subscribe(res => {
        this.fetchEmailDomains();
      }, err => {
        // this.openEmailSettingError();
        console.error(err);
      });
  }

  fetchEmailDomains() {
    this.loading = true;
    this.authService.emailAuthentication()
      .subscribe(res => {
        this.fetchEmailSenderIdentities();
        this.emailDomainService.get()
          .subscribe(response => {
            if (response && response.data.length) {
              this.domainData = response.data[0];
              this.dnsKeys = Object.keys(this.domainData.sendgrid_metadata.dns);
              this.current = 1;
            } else {
              this.openEmailSettingError();
            }
            this.loading = false;
          });
      })
  }

  fetchEmailSenderIdentities() {
    this.loading = true;
    this.authService.emailAuthentication()
      .subscribe(res => {
        this.emailSenderIdentityService.get()
          .subscribe(res => {
            if (res && res.data.length) {
              let sender = res.data[0];
              this.sender = sender;
              this.loading = false;
            }
          }, err => {
            this.loading = false;
            console.error(err);
          });
      });

  }

  createDomain() {
    this.emailDomainService.create({ domain: { name: this.myDomain } })
      .subscribe(res => {
        this.domainData = Array.isArray(res) ? res[0] : res;
        this.dnsKeys = Object.keys(this.domainData.sendgrid_metadata.dns);
        this.message.create('success', 'Your domain is created!');
        this.delayedNext();
      }, err => {
        let errorMessage = err.error && err.error.message ? err.error.message : err.error;
        errorMessage = (typeof errorMessage == 'object') ? JSON.stringify(errorMessage) : errorMessage;
        errorMessage = errorMessage?.replace(/[{()}]/g, '');
        this.message?.remove();
        this.message?.create('error', errorMessage);
      });
  }

  delayedNext() {
    setTimeout(() => {
      this.current += 1;
    });
  }

  verifyDomain(tab?) {
    this.emailDomainService.createCustom({}, `domains/${this.domainData.id}/validate`)
      .subscribe(res => {
        this.loading = false;
        if (res.valid) {
          this.isDomainVerified = res.valid;
          if (tab) {
            this.changeTab(tab);
          }
        }
      });
  }

  changeTab(tab) {
    this.selectedTab = tab;
  }

  openEmailSettingError() {
    this.modal.error({
      nzTitle: 'Your are not onborded on email!',
      nzContent: 'Please complete your onboarding by clicking "Onbaording" button!',
      nzOkText: 'Onboarding',
      nzOnOk: () => {
        this.goToOnboarding();
      }
    });

  }

  goToOnboarding() {
    this.router.navigateByUrl('/email_campaigns/onboarding');
  }

  get cradDescription() {
    let domain = this.myDomain || 'example.com';
    return `<jane.doe@${domain}>`
  }

}
