// export class Propmix {

//     /**
//      * id
//      */
//     id: number;

//     /**
//      * title
//      */
//     title: string;

// }

export class PMMLSBoard {

  FeedId: number;
  MLSId: string;
  State: string;
  MLSDescription: string;
  get MLSName(): string {
    return `${this.MLSDescription} (${this.State})`;
  }
  constructor(vals: any = {}) {
    Object.assign(this, vals);
  }

}
