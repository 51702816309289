import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { EmailResourceService } from '../email-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';


/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.email_api_url}`;
}

@Injectable()

export class EmailSenderIdentityService extends EmailResourceService<any> {
  public endpoint = 'sender_identities';
  public data_key = 'data';

  constructor(
    http: HttpClient,
    authService: AuthenticationService) {
    super(http, authService);
  }

  public reSendSenderVerification(senderId): Observable<any> {
    const url = `${this._uri}${this.endpoint}/${senderId}/resend_verification`;
    return this.http
      .post<any>(
        url,
        JSON.stringify({}),
        { headers: this.headers }
      )
  }

}
