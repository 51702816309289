import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';

import { CardButtonConfig } from 'src/app/models';
import { Product } from 'src/app/vos/product/product';
import { ProductsService } from 'src/app/services/products/product-api.service';
import { Content } from 'src/app/vos/content/content';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs/operators';
import { MarketReport } from '../../../vos/market-report/market-report';
import { MarketReportsService } from '../../../services/market-reports/market-reports.service';
import { BreadCrumService } from '../../../services/breadcrum/bread-crum.service';
import { ContentsService } from '../../../services/contents/contents.service';
import { GenericDialogComponent } from '../../../shared/generic-dialog/generic-dialog.component';
import { MarketReportFormModalComponent } from '../market-report-form-modal/market-report-form-modal.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'app-listings-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class MarketReportsViewComponent implements OnInit, OnDestroy {
  marketreport_id;
  marketreport: MarketReport;
  products: CardButtonConfig[] = [];
  makeContentPath: string;
  contents: Content[];
  customer: Customer;
  pageSizeOptions = [10];
  loading = false;
  insights = [];
  contentPagination = {
    limit: 10,
    total: 100,
    page: 1,
    totalPages: 10
  };
  nocontents = false;
  contentUpdateSubscription: Subscription;

  get editLink(): string {
    return `/my_markets/${this.marketreport_id}/edit`;
  }
  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private marketreportService: MarketReportsService,
    private contentService: ContentsService,
    private breadcrumService: BreadCrumService,
    private router: Router,
    public message: NzMessageService,
    private modalService: NzModalService,
    private productService: ProductsService,
  ) { }

  ngOnInit() {
    this.contentChannelSub();
    this.loading = true;
    this.customer = this.authService.currentCustomerValue;
    this.marketreport = this.route.snapshot.data['data'];
    this.marketreport_id = this.route.snapshot.paramMap.get('id');
    this.makeContentPath = `/my_markets/${this.marketreport_id}/create_content`;
    for (let i = 0; i < 4; i++) {
      this.products.push({ id: '', title: '', actionText: '', isSkeleton: true });
    }
    this.fetchMarketProducts();
    this.contentService.listFilter(this.marketreport.id).subscribe(response => {
      if (response) {
        this.contents = response?.data;
        this.nocontents = this.contents.length === 0;
      }
    });

    this.fetchContent();
    this.breadcrumService.set_breadcrum();
    this.breadcrumService.replaceItem(`${this.marketreport_id}`, this.marketreport.region_name);
    // this.breadcrumService.push_breadcrum({ name:  `${this.marketreport.region_name} (${this.marketreport.location_attributes.zip_code})`});

  }

  contentChannelSub() {
    this.contentUpdateSubscription = this.contentService.contentUpdateChannel()
      .received().subscribe(res => {
        const contentResponse = res.data;
        const contentIndex = this.contents?.findIndex(c => c.id === contentResponse.content_id);
        if (contentIndex > -1) {
          this.contents[contentIndex].status = contentResponse.status;
          if (this.contents[contentIndex].last_post) {
            this.contents[contentIndex].last_post.status = contentResponse.status;
          }
        }
      })
  }

  didPage(pageIndex) {
    this.contentPagination.page = pageIndex;
    this.loading = true;
    this.fetchContent();
  }

  fetchContent() {
    this.contentService
      .list(`?page=${this.contentPagination.page}&q[contentable_type_eq]=MarketReport&q[contentable_id_eq]=${this.marketreport.id}`)
      .pipe(
        map((res) => {
          this.contentPagination.limit = res['per_page'];
          this.contentPagination.total = res['total_entries'];
          const limit = this.contentPagination.limit;
          const total_items = this.contentPagination.total;
          this.contentPagination.totalPages = Math.ceil(total_items / limit);
          return res.data;
        })
      ).subscribe(contents => {
        this.contents = contents;
        this.loading = false;
        this.nocontents = this.contents.length === 0;
      });
  }

  fetchMarketProducts() {
    const params: Record<string, any> = {
      'q[additional_service_true]': 'false',
      'q[available_true]': 'true',
      'q[s]': 'rank asc'
    };

    if (!this.customer) {
      this.authService.logout();
      this.router.navigate(['/login']);
      return;
    }

    this.productService.productList(
      `customers/${this.customer?.id}/products`,
      params
    ).subscribe(resp => {
      if (resp) {
        const products: Product[] = resp.data?.filter(pr => pr.parent_type.includes('market_report'))
          .map((product: Product) => new Product(product));
        this.products = products.map(pd => pd.cardButton(this.customer, 'my_markets', this.marketreport));
      }
    });
  }
  contentDeleted(content: Content) {
    this.contents = this.contents?.filter(c => c.id !== content.id);
  }
  deleteReport() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this market report?',
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.marketreportService.destroy(this.marketreport).subscribe(r => {
          this.router.navigateByUrl('/my_markets');
        }, e => {
          this.loading = false;
          this.message?.remove();
          this.message?.create('error', 'Error deleting this market report. Please try again');
        });
      }
    });
  }
  updateMarketReport() {
    const modal = this.modalService.create<MarketReportFormModalComponent, IModalData>({
      nzContent: MarketReportFormModalComponent,
      nzFooter: null,
      nzData: {
        marketReport: this.marketreport
      },
    });
    modal.afterClose.subscribe(response => {
      this.marketreportService.show(this.marketreport.id).subscribe(res => {
        return this.marketreport = res.data;
      });
    });
  }
  makeContent() {
    this.router.navigateByUrl(`/my_markets/${this.marketreport.id}/create_content`);
  }

  clearSubscriptions() {
    if (this.contentUpdateSubscription) {
      this.contentUpdateSubscription.unsubscribe();
    }
  }
  ngOnDestroy() {
    this.clearSubscriptions();
  }
}
