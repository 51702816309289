<div class="title-header">
  <h1 class="section-title title">
    <i nz-icon nzType="area-chart"></i>
    ROI Report
  </h1>
  <p>({{ since | date : "MM-dd-y"}} - {{ untilDate | date : "MM-dd-y"}})</p>
</div>
<br>
<div nz-row nzGutter="16">
  <ng-container *ngFor="let insight of insights">
    <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="gutter-row">
      <div class="gutter-box">
        <nz-card class="custom-card">
          <span nz-tooltip [nzTooltipTitle]="insight.description" class="float-icon">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </span>
          <h2 class="analytics-heading">{{insight.display_name}}</h2>
          <nz-skeleton [nzActive]="isfbConnected" [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzLoading]="loading">
            <div class="insight-container">
                <span class="analytics-count">{{insight.value | number}}<span class="unit"> {{insight.unit}}</span></span>
            </div>
            <div class="insights-card-footer">
              {{insight.change}}%
              <i nz-icon nzType="caret-up" nzTheme="outline" style="color:#52c41a;"  *ngIf="insight.change > 0 || insight.change == 0"></i>
              <i nz-icon nzType="caret-down" nzTheme="outline" style="color:#f5222d;" *ngIf="insight.change < 0"></i>
            </div>
          </nz-skeleton>
        </nz-card>
      </div>
    </div>
  </ng-container>
</div>
<nz-table nzTemplateMode	#headerTable>
    <thead>
    <tr>
      <th>Type</th>
      <th>Count</th>
      <th>Rate</th>
      <th>Total</th>
    </tr>
    </thead>
    <tbody *ngIf="rawData">
    <tr>
      <td>Posts</td>
      <td>{{rawData.posts_count}}</td>
      <td>${{rawData.posts_rate}}/hour</td>
      <td>{{rawData.posts_total.money_saved}}</td>
    </tr>
    <tr>
      <td>Websites</td>
      <td>{{rawData.websites_count}}</td>
      <td>${{rawData.website_rate}}/hour</td>
      <td>{{rawData.websites_total.money_saved}}</td>
    </tr>
    <tr>
      <td>Ads</td>
      <td>{{rawData.ads_count}}</td>
      <td>${{rawData.ads_rate}}/hour</td>
      <td>{{rawData.ads_total.money_saved}}</td>
    </tr>
    <tr>
      <td>Leads</td>
      <td>{{rawData.leads_count}}</td>
      <td> - </td>
      <td> - </td>
    </tr>
    <tr>
      <td>Content</td>
      <td>{{rawData.content_count}}</td>
      <td>$0.5 to $2 /hour </td>
      <td>{{rawData.content_total.money_saved}}</td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>Total</td>
      <td>${{rawData.money_saved}}</td>
    </tr>
    </tbody>
</nz-table>

