export class SenderIdentity {

  name: string = '';
  email: string = '';
  address: string = '';
  address_2: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  country: string = '';
  id: any = null;
  verified: boolean;

  constructor(vals: any = {}) {
    Object.assign(this, vals);
  }
}
