<div class="custom-table">
  <table>
    <thead>
      <tr>
        <th class="set-width">Address</th>
        <th class="set-row-width">Listing Status</th>
        <th class="set-row-width">Listing Type</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let listing of listings" class="cursor-pointer" (click)="selectedListing(listing)">
        <td class="set-width">
          <a class="text-center">
            {{listing.address}}
          </a>
        </td>
        <td class="text-center set-row-width">
          {{listing.listing_status}}
        </td>
        <td class="text-center set-row-width">
          {{listing.listing_type}}
        </td>
      </tr>
    </tbody>
  </table>
</div>