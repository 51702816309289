<div class="container">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="12">
      <form nz-form>
        <div class="customer-form" nz-row nzType="flex" nzJustify="space-between">
          <nz-form-item class="gutter-row" nz-col nzSpan="24">
            <nz-form-label nzFor="subject" nzRequired>Give your campaign a name
            </nz-form-label>
            <nz-form-control nzErrorTip="Please input your campaign name!">
              <nz-input-group nzPrefixIcon="user">
                <input type="text" nz-input id="subject" (ngModelChange)="changeModel()" [(ngModel)]="name"
                  name="campaign_name" required placeholder="Campaign Name" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>

    </div>
    <div nz-col nzSpan="10" class="preview-template">
      <img [src]="template.thumbnail_url" alt="">
    </div>
  </div>

</div>
