import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Lead } from 'src/app/vos/lead/lead';
import { environment } from 'src/environments/environment';
/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class IntegrationsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class IntegrationsService extends CustomerResourceService<any> {

  public endpoint = 'mls';
  public data_key = 'integration';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Lead, http, authService);
  }
  deleteIntegration(value){
    this.endpoint = 'integrations';
    const url = `${this._uri}${this.endpoint}/${value.id}.json`;
    return this.http.delete<void>(url, { headers: this.headers });
  }

}
