import { Component, OnInit, Input, inject} from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { GlobalsService } from '../../services/globals/globals.service';
import { AnnouncementService } from '../../services/announcement/announcement.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-announcement-preview-dialog',
  templateUrl: './announcement-preview-dialog.component.html',
  styleUrls: ['./announcement-preview-dialog.component.scss']
})

export class AnnouncementPreviewDialogComponent implements OnInit {
  @Input()
  config: any;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private globalsService: GlobalsService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private router: Router,
    private announcementService: AnnouncementService
  ) { }
  ngOnInit() {
    this.config = this.nzModalData.config;
  }

  open() {
    this.modal.close();
    this.announcementService.readAnnouncement(this.config.id)
    .subscribe(resp => {
      if (this.validURL(this.config.call_to_action)){
        window.open(this.config.call_to_action);
      }else{
        this.router.navigateByUrl(this.config.call_to_action);
      }
    });
  }

  get isVideo() {
    return this.config.graphic.includes('mp4') || this.config.graphic.includes('m4v');
  }

  validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
}
