import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GlobalsService } from '../../services/globals/globals.service';
import { AnnouncementService } from '../../services/announcement/announcement.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CustomersGoogleService } from '../../services/customers-google/customers-google.service';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { Customer } from '../../vos/customer/customer';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'app-google-onboarding-dialog',
  templateUrl: './google-onboarding-dialog.component.html',
  styleUrls: ['./google-onboarding-dialog.component.scss']
})

export class GoogleOnboardingDialogComponent implements OnInit, OnDestroy {
  @Input()
  config: any;
  loading = false;
  code: string;
  googleAccounts = [];
  page_token = null;
  showAccounts = false;
  selectedAccountId;
  locationId;
  integrationId;
  integration;
  paging = null;
  pageSize = 10;
  totalPages = 0;
  isDone = false;
  currentPage: any = 0;
  customer: Customer;
  PaidTier = false;
  broadcastChannel: BroadcastChannel;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private globalsService: GlobalsService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private router: Router,
    private authService: AuthenticationService,
    private customersGoogleService: CustomersGoogleService,
    private notification: NzNotificationService,

  ) {
    this.authService.refresh()
      .subscribe(c => {
        if (c) {
          this.customer = c;
          this.PaidTier = c?.tier === 'ultra';
        }
      });
  }
  ngOnInit() {
    this.config = this.nzModalData.config;
    this.getCustomerGoogleAccountLocations();
  }


  loginWithGoogle(code?: string): void {
    this.loading = true;
    this.customersGoogleService.getGoogleUserConnection()
      .subscribe(res => {
        if (res && res?.body.data && res?.body.data.length) {
          this.integration = res?.body?.data[0];
          this.integrationId = this.integration.id;
          this.getCustomerGoogleAccountLocations();
        } else {
          this.customersGoogleService.authorizeGoogle();
        }
      });

  }

  selectOption(account) {
    if (!account.checked) {
      this.selectedAccountId = account.id;
    } else {
      this.selectedAccountId = null;
    }
    this.googleAccounts = this.googleAccounts.map(googleAccount => {
      if (googleAccount.id !== account.id && googleAccount.checked) {
        googleAccount.checked = false;
      }
      return googleAccount;
    })
    account.checked = !account.checked;
  }

  createAccount() {
    this.customersGoogleService.createCustomerConectedGoogleLocation(this.integrationId, this.selectedAccountId)
      .subscribe(res => {
        this.isDone = true;
        // this.modal.close();
        this.notification.create(
          'success',
          'Connected',
          'Your google business account has successfully been connected'
        );
      })
  }

  close() {
    this.modal.close();
  }
  getCustomerGoogleAccountLocations(params?) {
    this.loading = true;
    this.customersGoogleService.getGoogleAccountLocations(this.page_token)
      .subscribe(res => {
        if (res && res?.body && res?.body?.locations) {
          const accounts = res?.body?.locations.map(page => {
            page.checked = false;
            return page;
          });
          ++this.totalPages;
          ++this.currentPage;
          this.googleAccounts = [...this.googleAccounts, ...accounts];
          this.page_token = res?.body?.next_page_token;
          // this.paging = res?.body.paging;
          this.showAccounts = true;
          this.loading = false;
        }
      })
  }

  previuosPage() {
    --this.currentPage;
  }

  nextPage() {
    if(this.page_token && this.currentPage === this.totalPages) {
      this.getCustomerGoogleAccountLocations();
    } else {
      ++this.currentPage;
    }
  }


  selectAccount(account) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Select which page Zentap should manage.',
        singleSelect: account,
        buttonLabel: 'skip'
      },
      nzFooter: null
    });
  }

  ngOnDestroy() {
    this.broadcastChannel.close();
  }

}
