<!-- <app-sidebar> -->
<div *ngIf="customer">
  <nz-steps [nzCurrent]="current" class="fb-steps">
    <nz-step nzTitle="Link facebook page" [nzStatus]="customer.fb_page_id ? 'finish': 'wait'" (click)="setCurrent(0)"></nz-step>
    <nz-step nzTitle="Check Access" (click)="setCurrent(1)"></nz-step>
  </nz-steps>
  <div class="steps-content">
    <div *ngIf="current === 0">
      <div class="text-center">
        <button class="link-fb-btn" nz-button nzType="primary" (click)="selectPage()">Link Facebook page</button>
      </div>
    </div>
    <div *ngIf="current === 1">
      <ol>
        <li class="fb-step" *ngFor="let step of checkAccesSteps">{{step}}</li>
      </ol>
      <div class="text-center">
        <button nz-button nzType="primary" class="link-fb-btn" (click)="checkFBAccess()">Check Access</button>
      </div>
    </div>
  </div>
</div>
<!-- </app-sidebar> -->
