import {Component, Input, Output, OnInit, AfterViewChecked, ElementRef, HostListener} from '@angular/core';
import { ViewChild, EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MarketReport } from '../../vos/market-report/market-report';
import { NgForm, ValidationErrors } from '@angular/forms';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormGroup, FormArray } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';
import {NzInputDirective} from 'ng-zorro-antd/input';
import numeral from 'numeral';

@Component({
  selector: 'app-single-data-snapshot-form',
  templateUrl: './single-data-snapshot-form.component.html',
  styleUrls: ['./single-data-snapshot-form.component.scss']
})

export class SingleDataSnapShotFormComponent implements OnInit, AfterViewChecked {
  @ViewChild('snapShotForm') form: NgForm;

  // Output
  @Output() validChange = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @Output() zipDataChange = new EventEmitter<any>();
  @Output() propertyTypeChange = new EventEmitter<any>();
  @Output() errors = new EventEmitter<ValidationErrors>();
  @Output() zeroValuesChange = new EventEmitter<any>();

  // Input
  @Input() valid = false;
  @Input() limitShow = false;
  @Input() model = new MarketReport();
  @Input() contentData = {};
  @Input() extraFields = [];
  @Input() errorStateMatcher: any;
  @Input() lockedFields = [];

  zeroValueCounts = 0;
  selectedType;
  selectedDataPoint: any;
  highlightInvalid = false;
  dataSnapPoints = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  disabled = false;
  zipData: any;
  editId: string | null;
  dataPoints = [];
  propertyTypes = [{key: 'residential', name: 'Residential'},
    {key: 'NonResidential', name: 'Non Residential'},
    {key: 'singleFamily', name: 'Single Family'},
    {key: 'condominium', name: 'Condominium'},
    {key: 'townHouse', name: 'Town House'},
    {key: 'multifamily', name: 'Multifamily'},
    {key: 'apartment', name: 'Apartment'},
    {key: 'all', name: 'All'}];
  loading = false;
  propertyType: any;
  @ViewChild(NzInputDirective, { read: ElementRef }) inputElement: ElementRef;
  @HostListener('window:click', ['$event'])
  handleClick(e: MouseEvent): void {
    if (this.editId && this.inputElement && this.inputElement.nativeElement !== e.target) {
      this.editId = null;
    }
  }

  constructor(
    private marketReportService: MarketReportsService,
    private notification: NzNotificationService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewChecked() {
  }
  ngOnInit() {
    for (let i = 1; i <= 25; i++){
      this.dataPoints.push({key: i, value: false});
    }
    this.propertyType = this.propertyTypes[2].key;
    this.fetchDataPoints();
    this.limitShow = true;
    if (this.contentData['extra_attributes'] && this.contentData['extra_attributes'].data_points.length) {
     this.selectedType = this.contentData['extra_attributes'].data_points[0];
    }
    this.marketReportService.data(this.model.id, 'singleFamily').subscribe(response => {
      this.zipData = response;
      this.zeroValueCounts = this.getZeroValueCount(this.zipData);
      this.zeroValuesChange.emit(this.zeroValueCounts);
    });
  }

  fetchDataPoints() {
    this.marketReportService.dataSnapPoints().subscribe(res => {
      this.dataSnapPoints = res;
    });
  }
  checkControls(controls: AbstractControl[]) {
  }
  checkForm() {
  }
  formatNumber(unit, value, key, type) {
    let displayValue;

    switch (unit) {
      case '#':
        displayValue = numeral(value).format('0,0');
        break;
      case '$':
        displayValue = numeral(value).format('$0,0');
        break;
      case '%':
        displayValue = `${numeral(value).format('0,0')}%`;
        break;
      case 'sqft':
        displayValue = `${numeral(value).format('0,0')} Sq. Ft.`;
        break;
      case '$/sqft':
        displayValue = `${numeral(value).format('0,0')} Per Sq. Ft.`;
        break;
      case 'days':
        displayValue = `${numeral(value).format('0,0')} Days`;
        break;
      case 'months':
        displayValue = `${numeral(value).format('0,0')} Months`;
        break;
      case 'years':
        displayValue = `${numeral(value).format('0,0')} Years`;
        break;
      default:
        break;
    }
    this.zipData.analysis[key][type] = displayValue;
  }


  startEdit(id: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.editId = id;
  }
  selectValue(data){
    this.change.emit(data);
    this.zipDataChange.emit(this.zipData);
    this.propertyTypeChange.emit(this.propertyType);

  }
  refreshTable(event){
    this.loading = true;
    this.marketReportService.data(
      this.model.id, event).subscribe(response => {
      this.zipData = response;
      this.loading = false;
      this.zipDataChange.emit(this.zipData);
    });
  }

  getZeroValueCount(zipData) {
    let count = 0;
    Object.keys(zipData?.analysis).forEach(element => {
      const rawVal = zipData?.analysis[element]?.current_raw;
      if (rawVal=== 0 || rawVal === null || !rawVal ) {
        count++;
      }
    });
    return count;
  }

}
