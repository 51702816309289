import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LeadsRoutes } from './leads.routing.module';

import { DemoNgZorroAntdModule } from '../../ng-zorro-antd.module';

import { LeadsComponent } from '../../components/leads/leads.component';
import {ConversationsService} from '../../services/conversations/conversations.service';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    LeadsComponent
  ],
  imports: [
    // RouterModule.forChild(LeadsRoutes),

    DemoNgZorroAntdModule,
    FormsModule
  ],
  exports: [
    LeadsComponent
  ],
  providers: [ConversationsService]
})

/**
 * Main app module. Import your submodules here.
 */
export class LeadsModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    // bugsnagClient.notify(new Error('Test error'));
  }
}
