import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { PMMLSBoard } from 'src/app/vos/propmix/propmix';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import {  distinctUntilChanged, switchMap, map, startWith, debounceTime } from 'rxjs/operators';
import { DataResponse } from 'src/app/models/data-response/data-response';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class PropmixServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class PropmixService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = environment.api_url;

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = 'propmix';

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private mlsBoards = new BehaviorSubject<PMMLSBoard[]>([]);
  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   * @param {PropmixServiceConfig} config
   */
  constructor(private http: HttpClient, @Optional() config: PropmixServiceConfig) {
    if (config) {
      this._uri = config.uri;
    }
  }

  /**
   * Pulls a list of Propmix objects.
   * @returns {Observable<Propmix[]>}
   */
  listMLSBoards(text: string): Observable<PMMLSBoard[]> {
    if (this.mlsBoards?.value.length > 0) {
      return this.mlsBoards.asObservable();
    }
    return this.http
      .get<DataResponse<PMMLSBoard[]>>(
        `${this._uri}propmix/mls_boards.json?search=${text}`
      ).pipe(map(boards => {
        this.mlsBoards.next(boards.data.map(b => new PMMLSBoard(b)));
        return boards.data.map(b => new PMMLSBoard(b));
      }));
  }
  searchMLSTerm(text: Observable<string>): Observable<PMMLSBoard[]> {
    return text
      // .pipe(debounceTime(400))
      .pipe(startWith(''))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => this.searchMLSBoards(term)));
  }
  /**
  * Pulls a list of Propmix objects.
  * @returns {Observable<Propmix[]>}
  */
  private searchMLSBoards(text: string): Observable<PMMLSBoard[]> {
    const terms = text.trim().split(' ').map(t => new RegExp(t.regEscape(), 'i'));
    if (terms.length === 0) {
      return this.listMLSBoards(text);
    }
    return this.listMLSBoards(text).pipe(map(boards => {
      return boards?.filter(b =>
        terms.every(t =>
          b.MLSDescription.search(t) >= 0 ||
          b.MLSId.search(t) >= 0)
      );
    }));
  }

  mlsBoardForId(mlsId: string): Observable<PMMLSBoard> {

    return this.listMLSBoards('')
      .pipe(map(boards => {
        const board = boards?.find(b => b.MLSId === mlsId);
        return board;
      }));
  }

  mlsBoardForFeedId(feedId): Observable<PMMLSBoard> {
    return this.listMLSBoards('')
      .pipe(map(boards => {
        const board = boards?.find(b => b.FeedId === parseInt(feedId, 10));
        return board;
      }));
  }
}
