import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as FileSaver from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class DownloaderService {

  constructor(
    protected http: HttpClient
  ) { }

  save(url: string, filename: string) {

    this.http.get(url,
      {
        observe: 'response',
        responseType: 'blob',
        params: { cachebuster: `${Math.random() * 999999}` }
      }).subscribe(r => {
        this.saveToSystem(r, filename);
      });
  }

  private saveToSystem(response: HttpResponse<any>, filename: string) {
    const contentType = response?.headers.get('Content-Type');
    const blob = new Blob([ response?.body ], { type: contentType });
    saveAs(blob, filename);
  }
  async getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      params: { cachebuster: `${Math.random() * 999999}` }
    };
    const res = await this.http.get(url, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  }
  async createZip(files: any[], zipName: string) {
    const zip = new JSZip();
    const name = zipName + '.zip';
    // tslint:disable-next-line:prefer-for-of
    for (let counter = 0; counter < files.length; counter++) {
      const element = files[counter];
      const fileData: any = await this.getFile(element);
      const b: any = new Blob([fileData], { type: '' + fileData.type + '' });
      zip.file(element.substring(element.lastIndexOf('/') + 1), b);
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
  }
}
