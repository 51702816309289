import { Component, OnInit } from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-cancelled-customer-dialog',
  templateUrl: './cancelled-customer-dialog.component.html',
  styleUrls: ['./cancelled-customer-dialog.component.scss']
})

export class CancelledCustomerDialogComponent implements OnInit {

  constructor(private modalRef: NzModalRef) {}
  ngOnInit() {
  }


}
