<div class="container">
  <nz-alert *ngIf="zeroValueCounts >= 7" nzType="warning" nzMessage="Warning"
    nzDescription="Looks like we don’t have a lot of meaningful data for this zip code, we recommend that you try another one."
    nzShowIcon></nz-alert>
  <div nz-row>
    <div nz-col nzSpan="12">
      <nz-form-label class="input-title">Select Property Type</nz-form-label>
      <nz-input-group>
        <nz-select nzSize="large" [(ngModel)]="propertyType" (ngModelChange)="refreshTable($event)" style="width:50%;">
          <nz-option *ngFor="let option of propertyTypes" [nzValue]="option.key" [nzLabel]="option.name"></nz-option>
        </nz-select>
      </nz-input-group>
    </div>
  </div>
  <br>
  <p class="data-explainer">
    Select {{allowedValues()}} metrics you would like to display on your local market content.
    <br>
    If you do not agree with the data displayed for your metric, click the number to enter the data you want.
    Selected({{this.selectedDataMultiplePoints.length}} - {{allowedValues()}})
  </p>
  <div class="notice">
    Note: The data is pulled from a third-party vendor. Please review it to be sure it's accurate and provide updates if
    necessary.
  </div>
  <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
    <ng-container [ngTemplateOutlet]="dataSelectorTable"></ng-container>
  </nz-spin>

  <ng-template #notloading>
    <ng-container [ngTemplateOutlet]="dataSelectorTable"></ng-container>
  </ng-template>
</div>
<ng-template #dataSelectorTable>
  <nz-table #basicTable [nzShowPagination]="false" [nzSize]="'small'" nzTemplateMode>
    <thead>
      <tr>
        <th>Select</th>
        <th>Category</th>
        <th *ngIf="zipData">{{zipData.previous_month}}</th>
        <th *ngIf="zipData">{{zipData.current_month}}</th>
      </tr>
    </thead>
    <tbody *ngIf="zipData">
      <tr *ngFor="let data of zipData.analysis | keyvalue">
        <td nzShowCheckbox nzShowRowSelection [nzChecked]="data.value.selected"
          (nzCheckedChange)="selectValue(data.key)"
          [nzDisabled]="disableValues() && selectedDataMultiplePoints.indexOf(data.key) === -1 || data.value.current_raw === null || data.value.previous_raw === null">
        </td>
        <!-- <td>
        <nz-radio-group [(ngModel)]="selectedDataPoint" nzName="selectDatapointRadio"  (ngModelChange)="selectValue(data.key)">
          <label class="single-data-snap" nz-radio [nzValue]="data.key"></label>
        </nz-radio-group>
      </td> -->
        <td>{{ data.value?.title }}</td>
        <td>
          <div class="editable-cell" *ngIf="editId!==data.key +''+ data.value.previous; else editDataB"
            (click)="startEdit(data.key +''+ data.value.previous, $event)">
            <div class="editable-cell-value-wrap">
              {{ data.value.previous }}
              <i nz-icon nzType="edit" nzTheme="fill" class="show-edit-icon"></i>
            </div>
          </div>
          <ng-template #editDataB>
            <input type="number" nz-input [(ngModel)]="data.value.previous_raw"
              (focusout)="formatNumber(data.value.units, data.value.previous_raw, data.key, 'previous')" />
          </ng-template>
        </td>
        <td>
          <div class="editable-cell" *ngIf="editId!==data.key +''+ data.value.current; else editDataA"
            (click)="startEdit(data.key +''+ data.value.current, $event)">
            <div class="editable-cell-value-wrap">
              {{ data.value.current }}
              <i nz-icon nzType="edit" nzTheme="fill" class="show-edit-icon"></i>
            </div>
          </div>
          <ng-template #editDataA>
            <input type="number" nz-input [(ngModel)]="data.value.current_raw"
              (focusout)="formatNumber(data.value.units, data.value.current_raw, data.key, 'current')" />
          </ng-template>
        </td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>