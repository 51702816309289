import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Content } from 'src/app/vos/content/content';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { DataResponse } from '../../models/data-response/data-response';
import { ActionCableService, Channel } from 'angular2-actioncable';

import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class ContentsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class ContentsService extends CustomerResourceService<Content> {
  public endpoint = 'contents';
  public data_key = 'content';
  private subject = new Subject<any>();
  public _urls = `${environment.api_url}customers`;

  private contentChannel: Channel;


  constructor(http: HttpClient,
    private cableService: ActionCableService,
    authService: AuthenticationService) {
    super(Content, http, authService);
  }

  post(content: Content): Observable<Content> {
    return super.putTo(content, 'post', true, false);
  }

  rerender(content: Content, data: any): Observable<any> {
    const url = `${this._uri}${this.endpoint}/${content.id}/rerender.json`;
    return this.http.put(url, data);
  }
  posts(content: Content): Observable<any> {
    const url = `${this._uri}${this.endpoint}/${content.id}/posts.json`;
    return this.http.get(url);
  }

  unschedulPost(content: Content): Observable<any> {
    const url = `${this._uri}posts/${content.last_post.id}/unschedule.json`;
    return this.http.put(url, {});
  }

  reschedulPost(content): Observable<any> {
    const url = `${this._uri}posts/${content.last_post.id}/reschedule.json`;
    return this.http.put(url, {
      post:
      {
        scheduled_for: content.scheduled_for,
        caption: content.caption,
        content_id: content.id,
        posted_on: content.posted_on
      }
    });
  }

  calenderSchedule(searchQuery): Observable<any> {
    let url = `${this._uri}customers/${this.authService.currentCustomerValue?.id}/content_calendar.json`;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<any[]>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  postSchedule(searchQuery): Observable<any> {
    let url = `${this._uri}customers/${this.authService.currentCustomerValue?.id}/posts.json`;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<any[]>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  contentUpdateChannel(): Channel {
    return this.cableService.cable(environment.wss_url).channel(
      "ContentPostUpdateChannel",
      {customer_id: this.authService.currentCustomerValue?.id}
    );
  }
}
