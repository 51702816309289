import moment from 'moment';
import {Resource} from '../resource/resource';

export class Testimony extends Resource {

  /**
   * id
   */
  id?: number;

  /**
   * copy
   */
  copy: string;

  /**
  * attestant_name
  */
  attestant_name: string;

  /**
  * attestant_photo
  */
  attestant_photo?: string;
 /**
  * image_attributes
  */
  image_attributes?: any;

 /**
  * image_attributes
  */
  image?: any

  /**
   * created_at
   */
  created_at?: moment.Moment;

  /**
   * updated_at
   */
  // updated_at: moment.Moment;

  constructor(vals: any = {}) {
    super(vals);
    Object.assign(this, vals);
  }

}
