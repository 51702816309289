import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { OnboardingStep } from 'src/app/vos/onboarding-step/onboarding-step';
import { AuthenticationService } from '../authentication/authentication.service';
import { CustomerResourceService } from '../customer-resource.service';
import { map } from 'rxjs/operators';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class TemplatesServiceConfig {
  uri = '';
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service class.
 */
export class OnboardingService extends CustomerResourceService<OnboardingStep> {
  public onboardingSteps:  Array<OnboardingStep> = [];
  public data_key = 'template';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(OnboardingStep, http, authService);
  }

  public loadOnboardingSteps(cId?): Observable<any> {
    if (this.customer_id || cId) {
      const customerId =  cId || this.customer_id;
      const url = `${this._uri}customers/${customerId}/onboarding_steps.json`;
      return this.http.get<OnboardingStep[]>(
        url
      ).pipe(map((resp) => {
        this.onboardingSteps = resp;
        return resp;
      }));
    }
}

public hasIncompleteStep() {
  let hasIncomplete = false;
  if (this.onboardingSteps && this.onboardingSteps.length) {
    this.onboardingSteps.forEach(step => {
      if (!step.completed) {
        hasIncomplete = !step.completed;
        return;
      }
    });
  }
  return hasIncomplete;
}

public stepsCompltionPercent() {
  const completedCount =  this.onboardingSteps?.filter(step => step.completed).length;
  return Math.round((completedCount / this.onboardingSteps.length) * 100);
}

}
