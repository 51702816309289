<ng-template #contentcreateform>
  <div class="container">
    <form #contentForm="ngForm">
      <div>
        <h3 *ngIf="contentStyles && contentStyles.length">Select a style below, then choose an image from the gallery or
          upload your own. When you click on 'Create' choose the 'Render' option to preview it first, or 'Post Now' to
          see it appear in your feed to update your cover with!</h3>
        <div *ngIf="submitted && !model.style" class="ant-form-item-explain ant-form-item-explain-error">
          <div class="ng-trigger ng-trigger-helpMotion">Please select video style.</div>
        </div>
        <nz-radio-group [(ngModel)]="styleRatio" [ngModelOptions]="{standalone: true}">
          <label nz-radio-button nzValue="regular" *ngIf="!isFlyer">Regular</label>
          <label nz-radio-button nzValue="square">Square</label>
          <label nz-radio-button nzValue="vertical">Vertical</label>
        </nz-radio-group>
        <div class="mt-25" *ngIf="filteredStyles && filteredStyles.length">
          <div nz-row>
            <div nz-col class="gutter-row" nzSpan="6" *ngFor="let c of filteredStyles; index as i">
              <app-select-image-card [content]="c" [index]="i" width="95%" [isCoverPhotos]="isCoverPhotos"
                [horizontalMargin]="'0px'" (cardClicked)="selectStyle(c)" [disableDeselect]="true"
                fxFlex></app-select-image-card>
            </div>
          </div>
<!--          <div class="swiper-pagination"></div>-->
<!--          <div class="swiper-button-next" (click)="nextSlide($event)"></div>-->
<!--          <div class="swiper-button-prev" (click)="prevSlide($event)"></div>-->
        </div>
        <nz-divider></nz-divider>
        <h3 *ngIf="filteredStyles && !filteredStyles.length" class="text-center error">
          No content style available!
        </h3>
      </div>
      <div *ngIf="isCoverPhotos" class="mt-25 ">
        <h1 class="section-title section-title-small" *ngIf="isCoverPhotos">
          Select or Upload background Photo
        </h1>
        <div *ngIf="submitted && !model.extra_attributes.background_photo"
          class="ant-form-item-explain ant-form-item-explain-error">
          <div class="ng-trigger ng-trigger-helpMotion">Please select background Photo</div>
        </div>

        <!-- <app-images-reorder #imageReorder [images]="images_attributes" [content]="model" [imageable_type]="'Customer'">
        </app-images-reorder> -->

        <div class="cover-photo-selector">
          <app-image-handler [imageable_type]="'cover_photo'" [content]="model" [config]="coverPhotoConfig"
            (imageObject)="setCoverImage($event)">
          </app-image-handler>
        </div>
        <!-- <nz-tag *ngFor="let filter of imageFiltersAvalible" nzMode="checkable"
          [nzChecked]="imageFilters.indexOf(filter) > -1" (nzCheckedChange)="toggleFilter($event, filter)"
          class="tag-filter">
          {{filter.name.capitalize()}}
        </nz-tag> -->
        <!-- <nz-divider></nz-divider> -->
      </div>
      <!-- <swiper [config]="config" #usefulCoverPhotoSwiper class="swiper-c mt-25" *ngIf="isCoverPhotos">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let c of contentCoverStyles">
            <app-select-image-card [content]="c" [horizontalMargin]="'0px'" (cardClicked)="selectStockImage(c)"
              [disableDeselect]="true" fxFlex></app-select-image-card>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next" (click)="nextCoverPhotoSlide($event)"></div>
        <div class="swiper-button-prev" (click)="prevCoverPhotoSlide($event)"></div>
      </swiper> -->

      <!-- </div> -->
      <!-- <nz-divider></nz-divider> -->
      <nz-divider [style.margin-bottom]="'30px'" *ngIf="isCoverPhotos"></nz-divider>
      <h1 class="section-title section-title-small" *ngIf="showColors">Brand Colors</h1>
      <h5 class="section-description" *ngIf="showColors">These colors will be used to brand your content..
      </h5>

      <div nz-row nzGutter="16" *ngIf="showColors">
        <div nz-col class="gutter-row" nzSpan="12">
          <div class="gutter-box">
            <span class="input-title">Background Color</span>
            <input class="color-picker-input" [value]="model.bg_color" [style.background]="model.bg_color"
              [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" [(colorPicker)]="model.bg_color" />

            <nz-form-item appearance="fill" class="form-field">
              <!-- <nz-form-label for="bg_color">Background</nz-form-label> -->
              <nz-form-control>
                <input nz-input type="text" id="bg_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                  [(ngModel)]="model.bg_color" name="bg_color" />
                <!-- <div *ngIf="submitted && !model.bg_color" class="ant-form-item-explain ant-form-item-explain-error"><div class="ng-trigger ng-trigger-helpMotion">Background color is required</div></div> -->

              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-col class="gutter-row" nzSpan="12">
          <div class="gutter-box">
            <span class="input-title">Text Color</span>
            <input class="color-picker-input" [value]="model.text_color" [style.background]="model.text_color"
              [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" [(colorPicker)]="model.text_color" />
            <nz-form-item appearance="fill" class="form-field">
              <!-- <nz-form-label for="text_color">Text Color</nz-form-label> -->
              <nz-form-control>
                <input nz-input type="text" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" id="text_color"
                  [(ngModel)]="model.text_color" name="text_color" />
                <!-- <div *ngIf="submitted && !model.text_color" class="ant-form-item-explain ant-form-item-explain-error"><div class="ng-trigger ng-trigger-helpMotion">Text color is required</div></div> -->
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <!--      <div *ngIf="model && model.extra_attributes && isCoverPhotos" class="space-bottom-48">-->
      <!--        <label nz-checkbox [(ngModel)]="model.extra_attributes.make_cover_photo" name="make_cover_photo">Make this my-->
      <!--          Facebook cover photo.</label>-->
      <!--        <h5>-->
      <!--          <span class="info-icon">-->
      <!--            <i nz-icon nzType="info-circle" nzTheme="outline"></i>-->
      <!--          </span>-->
      <!--          <span nz-typography>-->
      <!--            If this is selected, your current Facebook cover photo will be replaced by the one being created now.-->
      <!--          </span>-->
      <!--        </h5>-->
      <!--      </div>-->
      <div nz-row>
        <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
          <span nz-typography>Caption:
            <nz-switch [(ngModel)]="aiCaption" [ngModelOptions]="{standalone: true}" nzCheckedChildren="Caption"
              (ngModelChange)="loadAiCaption($event)" [nzLoading]="aiLoading"
              nzUnCheckedChildren="AI Caption"></nz-switch>
          </span>
          <span nz-icon [nzType]="'sync'" [nzSpin]="aiLoading" (click)="getAiCaption()" class="refresh"></span>
          <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
            <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
              <nz-spin [nzSpinning]="aiLoading" [nzDelay]="500">
                <textarea #caption nz-input class="form-control content-caption-area" id="caption"
                  [(ngModel)]="model.caption" [ngModelOptions]="{standalone: true}" name="caption" [required]="requiredFields.includes('caption')" nzAutosize
                  maxlength="2200" (ngModelChange)="onInput($event)" (keypress)="handlePreventDefault($event)"></textarea>
              </nz-spin>
            </nz-form-control>
            <span class="remaning">{{model.caption?.length ? model.caption?.length : 0}} / 2200</span>
            <div style="color: red;" *ngIf="model.caption?.length >= 2200">
              Caption must not exceed 2200 characters.
            </div>
            <div style="color: red;" *ngIf="isHashtagLimit">
              Hashtag Limit Exceeded: You've reached the maximum allowed number of hashtags. Limit: 30 hashtags
            </div>
            <button nz-dropdown nz-button nzTrigger="click" class="text-left " nzType="text" nzBlock
              [(nzVisible)]="showEmojiPicker" [nzDropdownMenu]="menu" nzPlacement="bottomRight"
              (click)="toggleEmojiPicker()">
              😀
            </button>
            <div class="hashtag">
              <span># {{ hashtags?.length ? hashtags?.length : 0}} / 30</span>
            </div>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <emoji-mart class="emoji-mart" set="{{set}}" isNative="false" (emojiSelect)="addEmoji($event);" title="Pick your emoji…">
              </emoji-mart>
            </nz-dropdown-menu>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="12">
          <div class="left-spacing content-details-section">
            <div nz-row class="edit-form-container">
              <nz-card style="width: 100%; margin-top: 20px; margin-bottom: 20px;" nzTitle="Youtube Required Fields">
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
                  <span nz-typography>Title: </span>
                  <h5 class="section-description">This is the text that will be included as your title in YouTube videos.</h5>
                  <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
                    <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
                      <input #caption nz-input class="form-control" id="title" [(ngModel)]="model.title" [ngModelOptions]="{standalone: true}" name="caption" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px">
                  <span nz-typography>YouTube video's privacy status: </span><br>
                  <nz-select [(ngModel)]="privacyStatus" [ngModelOptions]="{standalone: true}">
                    <nz-option nzValue="private" nzLabel="Private"></nz-option>
                    <nz-option nzValue="public" nzLabel="Public"></nz-option>
                    <nz-option nzValue="unlisted" nzLabel="Unlisted"></nz-option>
                  </nz-select>
                </div>
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
                  <span nz-typography>Tags: </span>
                  <h5 class="section-description">These tags will be used in YouTube videos.</h5>
                  <nz-select nzMode="tags" nzPlaceHolder="video tags" [(ngModel)]="videoTags" [ngModelOptions]="{standalone: true}" style="margin-bottom: 20px; width: 100%">
                  </nz-select>
                </div>
              </nz-card>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div class="left-spacing content-details-section">
            <div class="edit-form-container">

              <nz-card style="width: 100%; margin-top: 20px; margin-bottom: 20px;" nzTitle="Tiktok Required Fields" *ngIf="tiktokIntegration">
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24">
                  <span nz-typography>Title: </span>
                  <h5 class="section-description">This is the text that will be included as your title in Tiktok videos.</h5>
                  <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
                    <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
                      <input #caption nz-input class="form-control" id="tiktok_title" [(ngModel)]="model.extra_attributes.tiktokTitle" [ngModelOptions]="{standalone: true}" name="caption" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px">
                  <span nz-typography>Tiktok video's privacy status: </span><br>
                  <nz-select [(ngModel)]="model.extra_attributes.tiktokPrivacyStatus" [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select Tiktok privacy">
                    <nz-option *ngFor="let option of tiktokIntegration.tiktok_creator_info.data.privacy_level_options" [nzValue]="option" [nzDisabled]="contentDisclourse && option === 'SELF_ONLY'" [nzLabel]="preprocessOption(option)"></nz-option>
                  </nz-select>
                </div>
                <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px" >
                  <span nz-typography>Allow Users to: </span>
                  <label nz-checkbox  [(ngModel)]="model.extra_attributes.comment" [ngModelOptions]="{standalone: true}" [disabled]="tiktokIntegration.tiktok_creator_info.data.comment_disabled">Comment</label>
                  <label nz-checkbox [(ngModel)]="model.extra_attributes.duet"  [ngModelOptions]="{standalone: true}" *ngIf="model.media_type === 'video'"  [disabled]="tiktokIntegration.tiktok_creator_info.data.duet_disabled">Duet</label>
                  <label nz-checkbox [(ngModel)]="model.extra_attributes.stitch" [ngModelOptions]="{standalone: true}"  *ngIf="model.media_type === 'video'"  [disabled]="tiktokIntegration.tiktok_creator_info.data.stitch_disabled">Stitch</label>
                </div>
                <div  nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption" style="margin-bottom: 20px">
                  <span nz-typography>Disclose Video Content: </span>
                  <nz-switch [(ngModel)]="model.extra_attributes.contentDisclourse" [ngModelOptions]="{standalone: true}"></nz-switch>
                  <p>Your video will be labeled "Promotion Content" this cannot be changed once your video is posted.</p>

                  <span *ngIf="contentDisclourse">
                  <label nz-checkbox [(ngModel)]="model.extra_attributes.yourBrand" [ngModelOptions]="{standalone: true}">Your Brand</label>
                <p>Your photo/video will be labeled as 'Promotional content'</p>

                <label nz-checkbox [(ngModel)]="model.extra_attributes.brandedContent" [ngModelOptions]="{standalone: true}">Branded Content</label>
                <p>Your photo/video will be labeled as 'Paid partnership'</p>
                  <ul>
                    <li *ngIf="yourBrand && !brandedContent">By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Music Usage Confirmation</a>.</li>
                    <li *ngIf="brandedContent && !yourBrand">By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Music Usage Confirmation.</a></li>
                    <li *ngIf="brandedContent && yourBrand">By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" rel="noopener noreferrer" title="" data-e2e="src-link-a" style="white-space: normal;">Music Usage Confirmation.</a></li>
                  </ul>
                </span>

                </div>
              </nz-card>
            </div>
          </div>
        </div>
      </div>

      <div nz-row>
        <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="11" class="schedule-input-holder has-caption" *ngIf="showSchedule">
          <span nz-typography>Schedule (Select a date and time in the future to post your content)</span>
          <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="top-spacing">
            <nz-form-label [nzSm]="24" [nzXs]="24" for="scheduled_for_date">Date
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Date is required">
              <nz-date-picker [ngClass]="{'has-error': submitted && !schedule_date}" class="schedule-picker_input"
                required nzFormat="MM-dd-y" [(ngModel)]="schedule_date" name="scheduled_for_date"
                [nzDisabledDate]="disabledDate" (ngModelChange)="changeDate($event);">
              </nz-date-picker>
              <div *ngIf="submitted && !schedule_date" class="ant-form-item-explain ant-form-item-explain-error">
                <div class="ng-trigger ng-trigger-helpMotion">Date is required</div>
              </div>
              <h5 class="schedule-date-info">
                <span nz-typography>you are only allowed to schedule within 2 months (60 days).</span>
              </h5>
            </nz-form-control>

          </nz-form-item>
          <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24">
            <nz-form-label [nzSm]="24" [nzXs]="24" for="scheduled_for_time">Time
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Time is required">
              <nz-time-picker [ngClass]="{'has-error': submitted && !schedule_time}" class="schedule-picker_input"
                required [(ngModel)]="schedule_time" name="scheduled_for_time" [nzUse12Hours]="true"
                [nzDisabledHours]="disabledHours" [nzDisabled]="!schedule_date" nzFormat="h:mm a"
                (ngModelChange)="changeTime($event);">
              </nz-time-picker>
              <div *ngIf="submitted && !schedule_time" class="ant-form-item-explain ant-form-item-explain-error">
                <div class="ng-trigger ng-trigger-helpMotion">Time is required</div>
              </div>

            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="showSchedule ? 12 : 24"
          *ngIf="socialConfig && socialConfig.content">
          <app-select-social-platform [submitted]="submitted" class="spacing-bottom" [config]="socialConfig">
          </app-select-social-platform>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="contentcreateform"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="contentcreateform"></ng-container>
</ng-template>
