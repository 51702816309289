import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  NgForm,
  ValidationErrors,
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-register-ghl-location-dialog',
  templateUrl: './register-ghl-location-dialog.component.html',
  styleUrls: ['./register-ghl-location-dialog.component.scss']
})
export class RegisterGhlLocationDialogComponent implements OnInit {
  @ViewChild('ghlForm') form: NgForm;
  timezones = ['EST', 'PST']
  submitted = false;
  @Input()  model: any = { 
    name: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    website: '',
    timezone: '',
    firstName: '',
    lastName: '',
    email: '',
    facebookUrl: '',
    googlePlus: '',
    linkedIn: '',
    foursquare: '',
    twitter: '',
    yelp: '',
    instagram: '',
    youtube: '',
    pinterest: '',
    blogRss: '',
    googlePlacesId: '',
    snapshotId: '',
   
  };
  @Input() requiredFields = [
    'name',
    'firstName',
    'lastName',
    'email',
  ];
  @Input() showFields = [
    'name',
    'phone',
    'city',
    'state',
    'country',
    'postalCode',
    'website',
    'timezone',
    'firstName',
    'lastName',
    'email'
  ];
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private authService: AuthenticationService,
    private campaignService: CampaignService,
    private notification: NzNotificationService
    ) { }

  ngOnInit(): void {
    const customer = this.authService.currentCustomerValue;
    this.model.firstName = customer.first_name;
    this.model.lastName = customer.last_name;
    this.model.email = customer.email;
    this.model.website = customer.website_url;
    this.model.phone = customer.display_phone;
    this.model.city = customer.primary_city;
    this.model.state = customer.primary_state;
    this.model.postalCode = customer.primary_zip;
    this.model.instagram = customer.instagram_url;
    this.model.facebookUrl = customer.fb_business_url;
    this.model.linkedIn = customer.linkedin_url;
    this.model.youtube = customer.youtube_url;
    this.model.googlePlus = customer.google_business_url;

    console.log(customer)
  }

  createGhlLocation() {
    if (this.form.valid) {
      const payload = {
        "name": this.model.name,
        "phone": this.model.phone,
        "companyId": '0-022-857',
        "city": this.model.city,
        "state": this.model.state,
        "country": this.model.country,
        "postalCode": this.model.postalCode,
        "website": this.model.website,
        "timezone": this.model.timezone,
        "prospectInfo": {
          "firstName": this.model.firstName,
          "lastName": this.model.lastName,
          "email": this.model.email
        },
        "social": {
          "facebookUrl": this.model.facebookUrl,
          "googlePlus": this.model.googlePlus,
          "linkedIn": this.model.linkedIn,
          "foursquare": this.model.foursquare,
          "twitter": this.model.twitter,
          "yelp": this.model.yelp,
          "instagram": this.model.instagram,
          "youtube": this.model.youtube,
          "pinterest": this.model.pinterest,
          "blogRss": this.model.blogRss,
          "googlePlacesId": this.model.googlePlacesId
        },
        "snapshotId": this.model.snapshotId
      }
      this.campaignService.create_ghl_location(payload).subscribe(res => {
        if(res.message){
          this.notification.create(
            'success',
            'Created',
            res.message
          )
        }
      });
      this.handleClose();
    } else {
      this.message.create('error', 'You have invalid fields.');
      this.checkForm();
    }
  }

  checkControls(controls: AbstractControl[]) {
    controls.forEach(c => {
      if (c instanceof UntypedFormControl) {
        c.markAsTouched();
        c.updateValueAndValidity();
      } else if (c instanceof UntypedFormGroup) {
        this.checkControls(Object.keys(c.controls).map(k => c.controls[k]));
      } else if (c instanceof UntypedFormArray) {
        this.checkControls(c.controls);
      }
    });
  }

  checkForm() {
    this.form.valid;
    this.submitted = true;
    this.checkControls(Object.keys(this.form.controls).map(k => this.form.controls[k]));
  }
  
  handleClose(){
    this.modal.close();
  }
}
