import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Caption } from 'src/app/vos/caption/caption';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
@Injectable()

export class CaptionsService extends CustomerResourceService<Caption> {

  public endpoint = 'captions';
  public data_key = 'caption';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Caption, http, authService);
  }
  generate_caption(value: any): Observable<any> {
    return this.http
      .post<DataResponse<any>>(
        `${this._customerURI}${this.customerEndpoint}/generate_caption.json`,
        JSON.stringify({  Content: value }),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
  generate_ai_caption(value: any): Observable<any> {
    return this.http
      .post<DataResponse<any>>(
        `${this._customerURI}${this.customerEndpoint}/generate_ai_caption.json`,
        JSON.stringify({  Content: value }),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
  generate_title(value: any): Observable<any> {
    return this.http
      .post<DataResponse<any>>(
        `${this._customerURI}${this.customerEndpoint}/generate_title.json`,
        JSON.stringify({  Content: value }),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }

}
