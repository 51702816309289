import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';


@Injectable()
/**
 * Service class.
 */
export class AccountMembersService extends CustomerResourceService<any> {

  public endpoint = 'accounts';
  public data_key = 'account';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Object, http, authService);
  }

  details(){
    return this.http.get(
      `${this._customerURI}${this.customerEndpoint}/details.json`,
      { responseType: 'json' }
    );
  }
  invite(value) {
    return this.http
      .post<DataResponse<any>>(
        `${this._customerURI}${this.customerEndpoint}/invite.json`,
        JSON.stringify(value),
        { headers: this.headers, responseType: 'json' },
      );
  }
  remove(value) {
    return this.http
      .post<DataResponse<any>>(
        `${this._customerURI}${this.customerEndpoint}/remove.json`,
        JSON.stringify(value),
        { headers: this.headers, responseType: 'json' },
      );
  }
  resendInvite(value) {
    return this.http
      .post<DataResponse<any>>(
        `${this._customerURI}${this.customerEndpoint}/resend_invite.json`,
        JSON.stringify(value),
        { headers: this.headers, responseType: 'json' },
      );
  }
}
