import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewChecked,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {from, Subject, Subscription} from 'rxjs';

import { NzMessageService } from 'ng-zorro-antd/message';
import { Router, ActivatedRoute } from '@angular/router';
import { Listing } from '../../vos/listing/listing';
import { ListingsService } from '../../services/listings/listings.service';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';
import { ImagesReorderComponent } from '../images-reorder/images-reorder.component';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import {
  NgForm,
  ValidationErrors,
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray
} from '@angular/forms';
import moment from 'moment';
import { NumberInputFormatterPipe } from 'src/app/pipes/formatting/number-input-formatter.pipe';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
import {LocationClient, SearchPlaceIndexForPositionCommand, SearchPlaceIndexForTextCommand} from '@aws-sdk/client-location';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-listing-form',
  templateUrl: './listing-form.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./listing-form.component.scss']
})
export class ListingFormComponent implements OnInit, AfterViewChecked, OnChanges {
  @ViewChild('imageReorder') imageReorder: ImagesReorderComponent;
  isExtraLargeScreen: boolean;
  isOpenHouse = false;
  isCongrats = false;
  isCMA = false;
  isCommercialListingVideo = false;
  isCommercialListingFlyers = false;
  isListingVideo = false;
  isListingflyer = false;
  isTestimonial = false;
  occupancy = 0;
  proFormaCapRate = 0;
  capRate = 0;
  numberKeys = ['price', 'sq_ft', 'lot_size', 'price_per_sqft', 'lease_rate', 'price_per_unit', 'lease_rate'];
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  public searchSubject = new Subject<string>();
  public searchSubscription: Subscription;
  @ViewChild('listingForm') form: NgForm;
  @Output() validChange = new EventEmitter<boolean>();
  @Input() valid = false;
  @Output() errors = new EventEmitter<ValidationErrors>();
  @Output() requiredChange = new EventEmitter<Array<any>>();
  @Output() showChange = new EventEmitter<Array<any>>();

  @Input() requiredFields = [
    'listing_type',
    'address',
    'listing_status',
    'price',
    'beds',
    'baths',
    'images',
    'oh_from_date',
    'mls_status',
    'city',
    'state',
    'zip',
    'street'
  ];
  @Input() showFields = [
    'address',
    'listing_status',
    'price',
    'sq_ft',
    'beds',
    'baths',
    'images',
    'mls_status',
    'city',
    'state',
    'zip',
    'street'
  ];
  @Input() limitShow = false;
  @Input() model = new Listing();
  @Input() property = null;

  @Input() extraFields = [];

  @Input() lockedFields = [];

  // @Input()
  // disableDynamic = false;
  submitted = false;
  ctas = [
    'Looking to buy or sell your home. I can help! Contact me for a consultation.',

    'Looking for your dream home? I can make it happen! Contact me today.',

    'Want to know how much your home is worth and what`s on the market now? Contact me for more info!',

    'You could be next! Allow me to help you find the home of your dreams. Contact me for a consultation.',

    'Are you ready to sell your home or see what your options are? Contact me to help you with the process.'
  ];
  status = [
    'Now Available',
    'Pending',
    'Just Sold',
    'Off-Market',
    'Under Contract',
    'Coming Soon',
    'Accepting Backups',
    'For Rent',
    'Rented',
    'Just Listed',
    'Still Available',
    'Reduced Price',
    // 'Purchase Made',
    'LOT Available',
    'Auction',
    'Active',
    'Cancel/Withdrawn',
    'Contingent',
    'Delisted',
    'Pending',
    'Sold',
    'Sold- Represented Buyer',
    'Unknown',
    'Unlisted',
    'Accepted Offer',
    'Leased'
  ].sort();
  mlsStatusList = [
    'Active',
    'Pending',
    'Withdrawn',
    'Contingent',
    'Sold'
  ].sort();

  propertyTypes = [
    'Custom-Built Home',
    'Commercial',
    'Single Family Home',
    'Apartment',
    'Detached',
    'Townhome',
    'Condominium',
    'Lot',
    'Land',
    'Office',
    'Retail',
    'Industrial',
    'Multi Family',
    'Agriculture',
    'Ranch',
    'Cabin',
    'Stock Cooperative',
    'Duplex',
    'Quadruplex',
    'Timeshare',
    'Triplex',
    'Farmhouse',
    'Mobile Home',
    'Manufactured Home'
  ].sort();
  filteredPropertyTypes = [];
  transactionTypes = [
    'Sale',
    'Lease',
    'Purchase',
  ].sort();

  tenancies = [
    'Single',
    'Multiple'
  ];
  unitTypes = [
    'acres',
    'sqft',
    'N/A'
  ];
  buildingClasses = [
    'A',
    'B',
    'C'
  ];
  highlightInvalid = false;
  loading = false;
  suggestions;
  results;
  formatterPercent = (value: number) => `${value} %`;
  get minDate(): moment.Moment {
    return moment().add(1, 'd');
  }
  get listingImages(): any[] {
    if (!this.model || (this.model && !this.model.images_attributes)) { return []; }
    const IMAGES = [];
    this.model.images_attributes?.forEach((image, i) => {
      if (image) {
        IMAGES[i] = image;
      }
    });
    return IMAGES;
  }
  constructor(
    private marketReportService: MarketReportsService,
    private listingService: ListingsService,
    private numberPipe: NumberInputFormatterPipe,
    private cdr: ChangeDetectorRef,
    private message: NzMessageService,
    public router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {
    this.filteredPropertyTypes = this.propertyTypes;
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )?.subscribe(newInput => {
        const isnum = /^\d+$/.test(newInput);
        if (!isnum || newInput.length < 5) {
          this.message?.remove();
          this.message?.create('error', 'Zip Code input accepts 5 digits number only.');
        }
      });

    this.searchSubscription = this.searchSubject.pipe(
      debounceTime(200), // Adjust the debounce time as needed
      distinctUntilChanged(),
      switchMap(query => from(this.searchAddress(query)))
    ).subscribe(result => {
      // Handle the search result
    });

    this.breakpointObserver.observe([Breakpoints.XLarge])
      .subscribe(result => {
        this.isExtraLargeScreen = result.matches;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.requiredFields && changes.requiredFields.previousValue && changes.requiredFields.currentValue &&
      changes.requiredFields.currentValue.toString() !== changes.requiredFields.previousValue.toString()) {
      if (this.isCMA) {
        this.requiredFields.push('price_per_sqft');
        this.showFields.push('price_per_sqft');
      }
    }
  }

  ngAfterViewChecked() {
    this.form.valueChanges?.subscribe(() => {
      this.validChange.emit(this.form.valid);
      this.valid = this.form.valid && this.imagesValid('images');
    });
    if (this.property && this.isCommercialListingVideo) {
      this.updateCommercialPropertyFields();
      this.model.lot_size_type = this.model.lot_size_type || this.unitTypes[0];
    }
    if (this.isCongrats && !this.model.extra_attributes) {
      this.model.extra_attributes = { CTA: '' };
    }
    if(this.isListingflyer) {
      this.requiredFields.push('description');
    }

    // if (isNaN(+this.model.occupancy)) {
    //   this.model.occupancy = '0%';
    // }
  }
  ngOnInit() {
    this.route.params?.subscribe(param => {
      this.isListingflyer = param.form === 'listing_flyers' || param.form === 'open_house_flyers';
      this.isOpenHouse = param.form === 'open_house_videos' || param.form === 'open_house_flyers';
      this.isCongrats = param.form === 'congrats_videos';
      this.isTestimonial = param.form === 'testimonial_videos';
      this.isCommercialListingVideo = param.form === 'commercial_listing_videos';
      this.isCMA = param.form === 'comparative_market_analysis';
      this.isCommercialListingFlyers = param.form === 'commercial_listing_flyers';

      for (const key of this.numberKeys) {
        this.setNumberFormatter(key);
      }
      if (this.isCMA) {
        this.model.price_per_sqft = (parseInt(this.model.price?.replace(',', '')) / this.model.sq_ft?.replace(',', '')).toFixed(2).toLocaleString();
      }

      // if (this.isCommercialListingVideo) {
      //   this.model.listing_type = 'Multi Family';
      //   this.changePropertyType(this.model.listing_type);
      // }
      this.changePropertyType();
    });
    this.limitShow = true;
    if (this.model.occupancy) {
      let occupancy = this.model.occupancy?.replace(/^%+/i, '');
      this.occupancy = occupancy ? Number.parseInt(occupancy, 10) : 0;
    }

    if (this.model.pro_forma_cap_rate) {
      let pro_forma_cap_rate = this.model.pro_forma_cap_rate?.replace(/^%+/i, '');
      this.proFormaCapRate = pro_forma_cap_rate ? Number.parseInt(pro_forma_cap_rate, 10) : 0;
    }

    if (this.model.cap_rate) {
      let cap_rate = this.model.cap_rate?.replace(/^%+/i, '');
      this.capRate = cap_rate ? Number.parseInt(cap_rate, 10) : 0;
    }
    this.extraFields = this.extraFields.concat(['latitude', 'longitude']);
    this.updateVisibleFields();
    this.updateRequiredfields();
  }

  setModelValue(attr: string, value, decimalPlaces) {
    this.model[attr] = this.numberPipe.parse(value, decimalPlaces);
  }
  changeLotsize() {
    if (this.model.lot_size_type === 'N/A') {
      this.model.lot_size = 'N/A';
    } else {
      this.model.lot_size = null;
    }
  }

  onUpdateFamilyImage(res) {
    this.model.family_photo.original = res.image.family_photo;
  }

  imageUploaded(event, index) {
    if (index === 'family_photo') {
      this.model.remote_family_photo_url = event['cdnUrl'];
      return;
    }

    if (!this.model.images_attributes) {
      this.model.images_attributes = [];
      this.model.images_attributes.length = 5;
    }
    const imageURL = event['cdnUrl'];
    const imageId = this.model.images[index]
      ? this.model.images[index].id
      : null;
    this.model.images_attributes[index] = {
      id: imageId,
      remote_image_url: imageURL
    };
  }
  checkControls(controls: AbstractControl[]) {
    controls.forEach(c => {
      if (c instanceof UntypedFormControl) {
        c.markAsTouched();
        c.updateValueAndValidity();
      } else if (c instanceof UntypedFormGroup) {
        this.checkControls(Object.keys(c.controls).map(k => c.controls[k]));
      } else if (c instanceof UntypedFormArray) {
        this.checkControls(c.controls);
      }
    });
  }
  checkForm() {
    this.submitted = true;
    this.highlightInvalid = true;
    this.valid = this.form.valid && this.imagesValid('images');
    this.checkNumberInputs();
    // this.form.control.updateValueAndValidity();
    this.checkControls(Object.keys(this.form.controls).map(k => this.form.controls[k]));
    // this.form.controls.forEach(c => {})
  }
  imagesValid(imageType: string): boolean {
    switch (imageType) {
      case 'images':
        return (
          !this.requiredFields.includes('images') ||
          this.listingImages?.filter(i => (i && i.id) || (i.stock_photo_id)).length >= 5
        );
    }
  }

  changePropertyType(event?) {
    // this.model.listing_type = event;
    this.updateVisibleFields();
    this.updateRequiredfields();
  }

  changeTransactionType(event) {
    this.model.transaction_type = event;
    this.updateVisibleFields();
  }

  changeCTA(event) {
    // this.model.extra_attributes.CTA = event;
    this.cdr.detectChanges();
  }

  updateRequiredfields() {
    // remove beds and baths for land
    const bathIndex = this.requiredFields.indexOf('baths');
    const bedIndex = this.requiredFields.indexOf('beds');
    if (this.model.listing_type === 'Land' && bathIndex > -1 && bedIndex > -1) {
      this.requiredFields = this.requiredFields.filter(reqFiled => reqFiled !== 'baths');
      this.requiredFields = this.requiredFields.filter(reqFiled => reqFiled !== 'beds');
      this.requiredFields = this.requiredFields.filter(reqFiled => reqFiled !== 'year_build');
    } else {
      this.requiredFields.push('beds');
      this.requiredFields.push('baths');
    }

    if (this.isCommercialListingVideo && this.property) {
      this.requiredFields = [...this.requiredFields, ...this.property.fields];
    }
    if (this.model.listing_type === 'Land' && this.isCommercialListingVideo) {
      this.requiredFields.push('lot_size');
    }
    if (this.property && this.model.listing_type === 'Land') {
      this.requiredFields = this.requiredFields.filter(reqFiled => reqFiled !== 'price');
    }
    if (this.model.listing_type === 'Multi Family') {
      this.requiredFields = [
        'listing_status',
        'address',
        'sq_ft',
        'year_build',
        'occupancy',
        'tenancy',
        'lot_size',
        'lot_size_type',
        'price',
        'price_per_sqft',
        'listing_type',
        'city',
        'state',
        'zip',
        'street'
      ];
    }

    if (this.isCMA) {
      this.requiredFields.push('price_per_sqft');
    }

    if (this.isCommercialListingFlyers) {
      this.requiredFields.push('cap_rate');
      this.requiredFields.push('units');
      this.requiredFields.push('description');
    }

    if (this.model.listing_type === 'Office') {
      this.requiredFields.push('parking');
    }
    if(this.isListingflyer) {
      this.requiredFields.push('description');
    }

    this.cdr.detectChanges();
    this.requiredChange.emit(this.requiredFields);

    console.log(this.requiredFields)
  }

  updateCommercialPropertyFields() {
    let baseFields = [
      'address',
      'listing_status',
      'listing_type',
      'mls_status',
      'price',
      'images',
      'lot_size',
      'city',
      'state',
      'zip',
      'street',
      'units'
    ];
    const fields = [...baseFields, ...this.extraFields, ...this.property.fields];
    this.extraFields = this.extraFields.concat(this.property.fields);
    this.showFields = fields;
    if (this.model.listing_type === 'Land' && this.isCommercialListingVideo) {
      this.showFields = this.showFields.filter(reqFiled => reqFiled !== 'units');
    }
    this.setDefaultVlaues();
  }

  updateVisibleFields() {
    let baseFields = [
      'address',
      'listing_status',
      'listing_type',
      'price',
      'sq_ft',
      'images',
      'lot_size',
      'year_build',
      'city',
      'state',
      'zip',
      'street'
    ];
    let residentialFields = [
      'beds',
      'baths',
      'description'
    ];
    if (this.model.listing_type === 'Land' || this.model.listing_type === 'Lot') {
      residentialFields = ['description'];
      this.model.lot_size_type = 'acres';
      baseFields = [
        'address',
        'listing_status',
        'listing_type',
        'price',
        'images',
        'lot_size',
        'city',
        'state',
        'zip',
        'street'
        // 'year_build'
      ];
    }

    const commercialFields = [
      'zoning',
      'occupancy',
      'docks',
      'description'
    ];
    const multiFamilyFields = [
      'units',
      'unit_mix',
      'price_per_unit',
      'grm',
      'pro_forma_cap_rate'
    ];
    const saleFields = [
      'cap_rate',
      'building_class'
    ];
    const leaseFields = [
      'lease_type',
      'lease_rate'
    ];

    if (this.model.listing_type === 'Multi Family') {
      this.lockedFields.push('transaction_type');
      this.model.transaction_type = 'Sale';
    } else {
      this.lockedFields = [];
    }

    let fields = [...baseFields, ...this.extraFields];
    if (this.model.isCommercial) {
      fields = [...fields, ...commercialFields];
      if (this.model.transaction_type === 'Sale') {
        fields = [...fields, ...saleFields];
      } else {
        fields = [...fields, ...leaseFields];
      }
      if (this.model.listing_type === 'Multi Family') {
        // fields = [...fields, ...multiFamilyFields];

        fields = [
          'parking',
          'listing_status',
          'address',
          'sq_ft',
          'year_build',
          'occupancy',
          'tenancy',
          'lot_size',
          'lot_size_type',
          'price',
          'price_per_sqft',
          'listing_type',
          'city',
          'state',
          'zip',
          'street'
        ];
      }
    } else {
      fields = [...fields, ...residentialFields];
    }

    this.showFields = fields;
    if (this.isOpenHouse) {
      this.showFields.push('open_house');
    }

    if (this.isCongrats) {
      this.showFields = [];
      this.showFields.push('congrats');
      this.showFields.push('transaction_type');
    }
    if (this.model.listing_type === 'Retail' || this.model.listing_type === 'Industrial') {
      this.showFields.push('parking');
    }

    if (this.model.listing_type === 'Retail' || this.model.listing_type === 'Industrial' ||  this.model.listing_type === 'Office') {
      this.showFields.push('tenancy');
    }

    // if (this.model.listing_type === 'Office') {
    //   this.showFields.push('parking');
    // }
    if (this.isCMA) {
      this.showFields.push('price_per_sqft');
    }

    if (this.isCommercialListingFlyers) {
      this.showFields.push('cap_rate');
      this.showFields.push('units');
      this.requiredFields.push('units');
    }

    if (this.router.url.indexOf('create_content') === -1) {
      this.showFields.push('mls_status');
    }
    this.setDefaultVlaues();
    this.cdr.detectChanges();
  }

  setDefaultVlaues() {
    if (this.showFields.includes('tenancy') && !this.model.tenancy) {
      this.model.tenancy = this.tenancies[0];
    }

    if (this.showFields.includes('building_class') && !this.model.building_class) {
      this.model.building_class = this.buildingClasses[0];
    }

    if (this.showFields.includes('occupancy') && !this.model.occupancy) {
      this.model.occupancy = '0';
    }
    this.cdr.detectChanges();
  }

  checkNumberInputs() {
    this.message?.remove();
    if (this.model.price && this.showFields.indexOf('price') > -1 && isNaN(+this.model.price)) {
      this.message?.create('error', 'Price input accepts number only.');
    }
    if (this.model.beds && this.showFields.indexOf('beds') > -1 && isNaN(+this.model.beds)) {
      this.message?.create('error', 'Beds input accepts number only.');
    }
    if (this.model.baths && this.showFields.indexOf('baths') > -1 && isNaN(+this.model.baths)) {
      this.message?.create('error', 'Baths input accepts number only.');
    }
    if (this.model.year_build && this.showFields.indexOf('year_build') > -1 && isNaN(+this.model.year_build)) {
      this.message?.create('error', 'Year build input accepts number only.');
    }
  }

  onKey($event, key) {
    if (this.model[key]) {
      this.model[key] = this.model[key] && this.model[key].charAt(0) === '.' ? ('0' + this.model[key]) : this.model[key];
      this.model[key] = this.model[key].toString();
    }
  }

  occupancyChange(val) {
    this.model.occupancy = `${val} %`;
  }
  proFormaCapRateChange(val) {
    this.model.pro_forma_cap_rate = `${val} %`;
  }

  capRateChange(val) {
    this.model.cap_rate = `${val} %`;
  }

  stripText(event) {
    const seperator = '^([0-9])';
    const maskSeperator = new RegExp(seperator, 'g');
    const result = maskSeperator.test(event.key);
    return result;
  }

  setNumberFormatter(key) {
    this.removeCommas(key);
    if (!isNaN(this.model[key]) && this.model[key]) {
      this.model[key] = parseFloat(this.model[key]).toLocaleString();
    }
  }

  removeCommas(key) {
    this.model[key] = this.model[key] ? this.model[key].toString().replace(/,/g, '') : this.model[key];
  }

  get familyPhotoConfig() {
    const target = {
      listing_id: this.model.id
    };
    const config = {
      id: this.model.id,
      type: 'family_photo',
      aspectRatio: 1,
      minWidth: 275,
      minHeight: 105,
      idealWidth: 1280,
      idealHeight: 720
    };

    const removeFamilyPhoto = () => {
      // this.model.family_photo = null;
      this.model.remove_family_photo = true;
      this.listingService.update(this.model)
        .subscribe(res => {
          this.model = res;
        });
    };
    return {
      configMeta: config,
      channel: 'FamilyPhotoChannel',
      target,
      image: this.model.family_photo.original,
      isNew: !!this.model.family_photo.original,
      removeFamilyPhoto,
    };
  }


  filterProperties(value: string): void {
    this.filteredPropertyTypes = this.propertyTypes.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    this.changePropertyType(value);
  }

  openGallery(step?) {
    this.imageReorder.openImageDialog(this.model.images_attributes[0], step);
  }

  async searchAddress(query: string){
    this.loading = true;
    const authHelper = await withAPIKey(environment.aws.LOCATION); // use API Key id for credentials
    const client = new LocationClient({
      region: 'us-east-1',
      ...authHelper.getLocationClientConfig() // sets up the Location client to use the API Key defined above
    });
    const result = await client.send(new SearchPlaceIndexForTextCommand({
      IndexName: 'ZentapDashAddressLookUp',
      Text: query,
      FilterCountries: ['USA', 'CAN']
    }));
    this.suggestions = result.Results.map(place => place.Place.Label);
    this.results = result;
    this.loading = false;
  }

  onInputChangeSearchAddress(query: string) {
    // Push the query to the searchSubject
    this.searchSubject.next(query);
  }
  onAddressSelected(selectedValue: string) {
    if (!this.results || !this.results.Results){
      return;
    }
    const selectedPlace = this.results.Results.find(result => result.Place.Label === selectedValue);
    const zipCodeRegex = /\d+/;
    if (!selectedPlace){
      return;
    }
    this.model.city = selectedPlace.Place.Municipality || '';
    this.model.street = `${selectedPlace.Place.AddressNumber} ${selectedPlace.Place.Street}` || '';
    this.model.state = selectedPlace.Place.Region || '';
    this.model.zip = selectedPlace.Place.PostalCode.match(zipCodeRegex)[0] || '';
    this.model.longitude = selectedPlace.Place.Geometry.Point[1] || '';
    this.model.latitude = selectedPlace.Place.Geometry.Point[0] || '';

  }

}
