<div>
  <nz-skeleton-element class="skeleton-image" *ngIf="refreshing" nzType="image" nzActive="true"></nz-skeleton-element>
  <img *ngIf="content.status === 'rendering' && !refreshing" [style.width]="previewWidth + 'px'" class="internal-content" src="assets/styles/Rendering.gif"
    >
  <img *ngIf="content.status !== 'rendering' && content.media_type === 'image'" [src]="content.url"
     [alt]="content.caption" class="internal-content" />
     <img *ngIf="content.status !== 'rendering' && content.media_type === 'carousel' && content.fileURLs.length > 0" [src]="content.fileURLs[0]"
     [alt]="content.caption" class="internal-content" />
  <div class="outer-container" *ngIf="content.status !== 'rendering' && content.media_type === 'video'"
    >
    <div class="inner-container">
      <div class="video-overlay"></div>
        <div class="video">
          <img class="thumnail-img" [src]="content.thumbnail" />
          <a href="javascript: void(0);" ></a>
        </div>
    </div>
  </div>
  <nz-spin class="refreshing-spin" *ngIf="refreshing; else notrefreshing">
    <ng-container [ngTemplateOutlet]="previewtext"></ng-container>
  </nz-spin>
  <ng-template #notrefreshing>
    <ng-container [ngTemplateOutlet]="previewtext"></ng-container>
  </ng-template>
  <ng-template #previewtext>
    <p nz-tooltip nzTooltipPlacement="bottomLeft" [style.maxWidth]="previewWidth + 'px'" [nzTooltipTitle]="content.display_name" class="content-type content-d-name">
      {{truncateChar(content.display_name)}}</p>
  </ng-template>
</div>
