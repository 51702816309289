import { OnInit, Directive } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication/authentication.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../models/data-response/data-response';
import { Resource } from '../vos/resource/resource';
import { map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
export abstract class ResourceService<T extends Resource> {
  // public abstract endpoint: string;
  abstract list(): Observable<DataResponse<T[]>>;
  abstract show(id: number | string): Observable<DataResponse<T>>;
  abstract create(value: T): Observable<T>;
  abstract update(value: T): Observable<T>;
  abstract destroy(value: T): Observable<void>;
  // abstract exportContacts();
}

// @Injectable({
//   providedIn: 'root'
// })
@Directive()
export class EmailResourceService<T extends Resource>
  extends ResourceService<T>
  implements OnInit {
  /**
   * Path uri.
   * @type {string}
   * @private
   */

  protected _uri = `${environment.email_api_url}`;
  /**
   * Url to endpoint api.
   * @type {string}
   */

  public endpoint: string;
  public data_key: string;

  protected get customerEndpoint(): string {
    return `${this.authService.currentCustomerValue?.id}/${this.endpoint}`;
  }
  private get object_key(): string {
    return `${this.data_key}`;
  }

  get resourceEndpoint(): string {
    return `${this._uri}${this.endpoint}`;
  }

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  // protected csvheaders = new HttpHeaders({  Accept: 'application/csv', 'Content-Type': 'text/csv' });
  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   *
   */
  constructor(
    protected http: HttpClient,
    protected authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
  }

  /**
   * Pulls a list of T objects.
   * @returns {Observable<DataResponse<T[]>>}
   */
  public list(searchQuery?): Observable<DataResponse<T[]>> {
    let url = this.resourceEndpoint;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<T[]>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  public get(searchQuery?): Observable<any> {
    let url = this.resourceEndpoint;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<T[]>>(
        url
      )
      .pipe(
        map(resp => {
          // resp.data = resp.data ? resp.data.map(o => new this.resource(o)) : [];
          return resp;
        })
      );
  }

  /**
   * Pulls a single T object.
   * @param {number | string} id to retrieve.
   * @returns {Observable<DataResponse<T>>}
   */
  show(id: number | string, query?): Observable<DataResponse<T>> {
    let url = this.resourceEndpoint + '/' + id;
    if(query){
      url = `${url}?${query}`
    }
    return this.http.get<DataResponse<T>>(url).pipe(
      map(resp => {
        resp.data = resp.data;
        return resp;
      })
    );
  }

  /**
   * Creates a single T object.
   * @param {} value to create.
   * @returns {Observable<DataResponse<T>>}
   */
  create(value: any): Observable<T> {
    return this.http
      .post<DataResponse<any>>(
        `${this.resourceEndpoint}`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp.data || resp));
  }
  /**
   * Creates a single T object.
   * @param {} value to create.
   * @returns {Observable<DataResponse<T>>}
   */
  createCustom(value: any, customUrl): Observable<T> {
    return this.http
      .post<DataResponse<any>>(
        `${this._uri}${customUrl}`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp.data || resp));
  }

  /**
   * Updates a single T object.
   * @param {} value to update.
   * @returns {Observable<DataResponse<T>>}
   */
  update(value: T, id?): Observable<T> {
    let url = this.resourceEndpoint;

    url = id ? this.resourceEndpoint + '/' + id : this.resourceEndpoint;
    return this.http
      .put<DataResponse<T>>(url, JSON.stringify(value), {
        headers: this.headers
      })
      .pipe(map(resp => resp.data));
  }

  destroy(value: T): Observable<void> {
    const url = `${this.resourceEndpoint}/${value}`;
    return this.http.delete<void>(url, { headers: this.headers });
  }
}
