import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Content } from '../../vos/content/content';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ContentsService } from '../../services/contents/contents.service';
import { ProductsService } from '../../services/products/product-api.service';
import { Product, ProductStyle } from '../../vos/product/product';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ResourceService } from '../../services/customer-resource.service';
import { CustomersService } from '../../services/customers/customers.service';
import { ListingsService } from '../../services/listings/listings.service';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';
import { map, mergeMap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';
import { Listing } from '../../vos/listing/listing';
import { Customer } from '../../vos/customer/customer';
import { WebsiteAttributes } from '../../shared/interfaces/website-attributes';
import moment from 'moment';
import { ContentFormComponent } from '../../shared/content-form/content-form.component';
import { CustomerFormComponent } from '../../shared/customer-form/customer-form.component';
import { ListingFormComponent } from '../../shared/listing-form/listing-form.component';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { ContentReceiptDialogComponent } from '../../shared/content-receipt-dialog/content-receipt-dialog.component';
import { GlobalsService } from '../../services/globals/globals.service';
import { CmaService } from '../../services/cma/cma.service';
import { TemplatesService } from '../../services/templates/templates.service';
import { WebsiteService } from '../../services/websites/website.service';
import { ConnectLinkedinService } from '../../services/connect-linkedin/connect-linkedin.service';
import { CaptionsService } from '../../services/captions/captions.service';

@Component({
  selector: 'app-content-request',
  templateUrl: './content-request.component.html',
  styleUrls: ['./content-request.component.scss']
})
export class ContentRequestComponent implements OnInit, AfterViewInit {

  form: string;
  type: string;
  selectedStyle: string;
  product: Product;
  socialConfig;
  zeroValueCount = 0;
  dateFormat = moment;
  @ViewChild('contentForm') contentForm: ContentFormComponent;
  @ViewChild('rForm') resourceForm:
    | CustomerFormComponent
    | ListingFormComponent;
  content = new Content();
  isNew = true;
  isSingleSnapShot = false;
  isListingFlyers = false;
  isWebsite = false;
  isVideo = false;
  hideFirstCreateList = [
    'banner_videos', 'comparative_market_analysis',
    'instagram_content', 'congrats_videos',
    'congrats_video', 'congrats_videos',
    'listing_flyers', 'listing_videos',
    'open_house_flyers', 'open_house_videos', 'websites', 'commercial_listing_flyers'];
  firstStepHideCreate = false;
  eventNmae;
  selectedTemplate;
  isSocialMediaPosts = false;
  isLocalMarketVideo = false;
  isFacebookAds = false;
  isBannerVideo = false;
  isCommercialListingVideo = false;
  isCommercialListingFlyers = false;
  isInstagramFlyer = false;
  isCoverPhotos = false;
  isTeamCoverPhotos = false;
  isCongratsVideo = false;
  isMarketVideo = false;
  isCMA = false;
  submitted = false;
  currentPage = 1;
  editDetailStep = 0;
  customizeContentStep = 1;
  pagination = {
    limit: 10,
    total: 100,
    page: 1,
    totalPages: 10
  };
  contentSubject: Customer | Listing;
  selectedProperty;
  clonedContentSubject: Customer | Listing;
  resourceService: ResourceService<Listing | Customer | any>;
  loading = true;
  error: string;
  contentCompleted = false;
  resourceCompleted = false;
  styles: ProductStyle[] = [];
  //Stock images
  stockImages;
  stockImagesPagination = {
    page: 1,
    per_page: 20,
    total: 100,
    totalPages: 2
  }
  allReqFields = [];
  stepToMove;
  contents: Content[] = [];
  current = 0;
  customer: Customer;
  bgColour = '';
  textColour = '';
  updatedBgColour = '';
  updatedTextColour = '';
  stylesRequested = false;
  showSchedule = false;
  integration;
  linkedinStatus = false;
  linkedinAutopost = false;
  ishashTagLimit = false;
  get showFields(): string[] {
    const fields = this.product && this.product.shownFields ? [...this.product.shownFields] : [];
    return fields;
  }
  get requiredFields(): string[] {
    return this.allReqFields;
  }
  updateRequiredFields($event) {
    this.allReqFields = $event;
    this.cdRef.detectChanges();
  }

  constructor(
    private route: ActivatedRoute,
    private contentService: ContentsService,
    private cmaService: CmaService,
    private productService: ProductsService,
    private authService: AuthenticationService,
    private customerService: CustomersService,
    private listingService: ListingsService,
    private marketReportsService: MarketReportsService,
    private message: NzMessageService,
    private router: Router,
    protected _location: Location,
    private breadcrumService: BreadCrumService,
    private cdRef: ChangeDetectorRef,
    private modalService: NzModalService,
    private globalService: GlobalsService,
    private notification: NzNotificationService,
    private templatesService: TemplatesService,
    private websiteService: WebsiteService,
    private linkedInService: ConnectLinkedinService,
    private captionsService: CaptionsService,

  ) { }

  ngAfterViewInit() {
    if (this.stepToMove === '2') {
      this.cdRef.detectChanges();
      setTimeout(() => {
        this.saveResource();
      }, 1000);
    }
    this.setSocialContentConfig();
    this.fetch_linkedin_autopost_settings();
  }

  ngAfterViewChecked() {
    if (this.contentForm && this.contentForm.model.title) {
      this.content.extra_attributes = {
        ...this.content.extra_attributes, // Spread existing extra_attributes
        title: this.contentForm.model.title // Add/overwrite the title attribute
      };
    }
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.selectedStyle = params['style'];
      });
    if (history.state.data) {
      this.onChangePropertyType(history.state.data);
      this.current = 1;
    }
    const content: Content = this.route.snapshot.data['content'];
    if (content) {
      this.isNew = false;
      this.content = content;
      this.setSocialContentConfig();
    }
    const contentSubject: { object: Customer | Listing; type: string } = this
      .route.snapshot.data['data'];
    if (contentSubject) {
      this.contentSubject = contentSubject.object;
      this.type = contentSubject.type;
      this.content.contentable_id = contentSubject.object.id;
      this.content.contentable_type = contentSubject.type;
      this.resourceService =
        contentSubject.type === 'Customer'
          ? this.customerService
          : this.listingService;
      if (contentSubject.type === 'Content') {
        this.resourceService = this.marketReportsService;
        this.content.contentable_type = 'MarketReport';
      }
    } else {
      this.contentSubject = this.authService.currentCustomerValue;
      this.type = 'Customer';
      this.resourceService = this.customerService;
      this.textColour = this.contentSubject.settings['text_color'];
      this.bgColour = this.contentSubject.settings['bg_color'];
    }
    this.loading = true;
    this.route.params.subscribe(params => {
      if (params['form']) {
        if (params['form'] === 'team_cover_photos') {
          this.isTeamCoverPhotos = true;
        }
        const formParam = params['form'] === 'team_cover_photos' ? 'cover_photos' : params['form'];
        this.customer = this.authService.currentCustomerValue;
        const endpoint = `customers/${this.customer?.id}/products`;
        const search_params = { 'q[name_eq]': formParam };
        if (!this.customer) {
          this.authService.logout();
          this.router.navigate(['/login']);
          return;
        }
        this.productService.productList(endpoint, search_params).subscribe(p => {
          this.contentSubject.products = p.data;
          const products = this.contentSubject.products.map((product: Product) => new Product(product));
          this.product = products?.find((prod) => prod.name === formParam);
          if (this.product) {
            this.isVideo = this.product?.media_type === 'video';
            this.socialConfig.hideGoogle = this.isVideo;
            this.setEventName(this.product?.name);
            this.isCommercialListingVideo = this.product.name === 'commercial_listing_videos';
            this.isCommercialListingFlyers = this.product.name === 'commercial_listing_flyers';
            this.isWebsite = this.product.name === 'websites';
            this.isCongratsVideo = this.product.name === 'congrats_videos';
            this.isListingFlyers = this.product.name === 'listing_flyers' || formParam === 'listing_flyers';
            this.isSingleSnapShot = this.product?.name?.indexOf('single_data') > -1 || this.product.name === 'single_data_snapshot' || this.product.name === 'single_data_snapshot_video';
            this.isCMA = this.product.name === 'comparative_market_analysis';
            this.isBannerVideo = this.product.name === 'banner_video' || this.product.name === 'banner_videos';
            this.isCoverPhotos = this.product.name === 'cover_photo' || this.product.name === 'cover_photos';
            this.isMarketVideo = this.product.name === 'marketing_video';
            this.isInstagramFlyer = this.product.name === 'instagram_flyer';
            this.isFacebookAds = this.product.name === 'facebook_ads';
            this.isLocalMarketVideo = this.product.name === 'local_market_videos';
            this.isSocialMediaPosts = this.product.name === 'social_media_posts';

            this.firstStepHideCreate = this.hideFirstCreateList?.indexOf(this.product.name) > -1;

            if ((history.state.data && !this.selectedStyle) || this.isCoverPhotos || this.isBannerVideo) {
              this.fetchStyles();
            }

            if (!this.selectedStyle && this.product && this.content.contentable_type === 'MarketReport') {
              this.fetchStyles(true);
              this.cdRef.detectChanges();
              this.pre();
            }

            if (this.isSingleSnapShot) {
              this.content.extra_attributes = { data_points: [] };

            }
            if (this.isCommercialListingVideo || this.isSingleSnapShot) {
              this.editDetailStep = 1;
              this.customizeContentStep = 2;
            }

            if (this.isCoverPhotos) {
              this.customizeContentStep = 0;
            }

            if (this.isCommercialListingVideo) {
              const commPropTypes = this.globalService.getCommercialPropertyTypes();
              const selectedProperty = commPropTypes?.find(t => t.title === this.contentSubject['listing_type']);
              if (selectedProperty && this.content.contentable_type !== 'MarketReport') {
                this.onChangePropertyType(selectedProperty);
                this.current = 1;
              }
            }
            this.allReqFields = this.product ? [...this.product.requiredFields] : [];
            this.cdRef.detectChanges();
            this.content.category = this.product.abbreviation;
            if (this.product.parent_type.includes('customer')) {
              this.content.contentable_type = 'Customer';
              this.content.contentable_id = this.authService?.currentCustomerValue?.id;
            }
            if (
              this.product.category === 'website' &&
              this.product?.styles?.length <= 1
            ) {
              this.save();
            }
            let product_name = '';
            if (product_name) {
              product_name = this.product.normalizedName;
            }
            this.breadcrumService.set_breadcrum();
            const routeParts = this.router.url.split('/');
            if (contentSubject && contentSubject.object && routeParts[0] === 'create_content') {
              this.breadcrumService.removeItem(`${contentSubject.object.id}`);
            } else if (contentSubject && contentSubject.object) {
              this.breadcrumService.replaceItem(`${contentSubject.object.id}`,
                contentSubject.type == 'Listing' ? contentSubject.object['address'] : contentSubject.object['region_name']);
            }

            if (this.isTeamCoverPhotos) {
              this.breadcrumService.removeItem(`New`);
              this.breadcrumService.replaceItem(`Team Cover Photos`, 'Cover Photos', '/team/team_cover_photos/new');
            }
            this.cdRef.detectChanges();

            this.setSocialContentConfig();
          }
        });
        // if (this.listingService.listingPropertyType) {
        //   this.selectedProperty = this.listingService.listingPropertyType;
        //   this.current = 2;
        // }
      }
      this.loading = false;
    });
    if (this.content && this.selectedStyle) {
      this.content.style = this.selectedStyle;
      this.setSocialContentConfig();
    }
    this.clonedContentSubject = JSON.parse(JSON.stringify(this.contentSubject));
    this.route.queryParams
      .subscribe(params => {
        this.stepToMove = params['step'];
      });
  }

  setEventName(productName) {
    if (productName === 'cover_photos') {
      this.eventNmae = 'created_cover_banner'
    }
    if (productName === 'local_market_videos') {
      this.eventNmae = 'created_a_local_market'
    }
    if (productName === 'listing_videos') {
      this.eventNmae = 'created_a_listing_video'
    }
  }
  cancel() {
    this._location.back();
  }
  pathFor(form: string): string {
    return this.form !== undefined ? '../' + form : form;
  }

  setSocialContentConfig() {
    this.socialConfig = { content: this.content, facebook: false, linkedin: false, google: false, hideGoogle: this.isVideo };
    this.socialConfig.facebook = this.customer?.settings?.auto_posting;

    if (this.isVideo) {
      this.socialConfig.hideGoogle = this.isVideo;
    }
    if (this.isCoverPhotos) {
      this.content.extra_attributes = {
        background_photo: '',
        make_cover_photo: true
      };
    }
    if (this.isTeamCoverPhotos && this.content.extra_attributes) {
      this.content.extra_attributes['team_content'] = true;
    }
  }

  onChangeSnapType(snapData) {
    this.content.extra_attributes = { data_points: [snapData] };
  }
  onChangeDataPoints(snapData) {
    this.content.extra_attributes = { data_points: snapData };
  }
  onChangeZipData(zipData) {
    this.content.contentable_to_render = zipData;
  }
  onPropertyTypeChange(property) {
    if (this.content.extra_attributes) {
      this.content.extra_attributes['property_type'] = property;
    } else {
      this.content.extra_attributes = { property_type: property };

    }
  }

  onChangePropertyType(property) {
    this.selectedProperty = property;
    if (this.selectedProperty?.title === 'Land') {
      this.allReqFields = [
        'address',
        'listing_status',
        'price',
        'lot_size'];
    }
    if (this.selectedProperty?.title === 'Multi Family') {
      this.contentSubject['listing_type'] = 'Residential';
    }
    this.allReqFields = [...this.allReqFields, ...this.selectedProperty.fields];
  }

  save() {
    if (this.product.category === 'content') {
      this.saveContent();
    } else if (this.product.category === 'website') {
      this.saveWebsite().subscribe(w => {
        window.open(w.view_url, '_blank');
      });
    }
  }

  saveSnapShot() {
    this.delayedNext();
  }

  saveResource() {
    this.currentPage = 1;
    if (this.type === 'Listing') {
      const numberKeys = ['price', 'sq_ft', 'lot_size', 'price_per_sqft', 'lease_rate', 'price_per_unit', 'lease_rate'];
      for (const key of numberKeys) {
        this.contentSubject[key] = this.contentSubject[key] ? this.contentSubject[key].toString().replace(/,/g, '')
          : this.contentSubject[key];
      }
    }
    this.resourceForm.checkForm();
    if (
      this.resourceForm && !this.resourceForm.valid
    ) {
      this.message?.remove();
      this.message?.create('error', 'You have invalid fields.');
      return;
    }
    this.loading = true;
    this.styles = [];
    if (this.type === 'Customer') {
      this.updateColors();
    }
    if (this.isContentChanged) {
      this.resourceService
        .update(this.contentSubject)
        .subscribe(res => {
          if (this.selectedStyle || this.isBannerVideo) {
            this.submit();
          } else {
            if (this.isWebsite) {
              this.getTemplates()
            } else {
              this.fetchStyles();
            }
          }
        }, err => {
          this.loading = false;
          this.message?.remove();
          this.message?.create('error', err.message || err);
        });
    } else {
      if (this.selectedStyle || this.isBannerVideo) {
        this.submit();
      } else {
        this.fetchStyles();
      }
    }
  }

  fetchStyles($event?) {
    if (this.isCoverPhotos) {
      this.getStockImages();
    } if (this.product) {
      if (this.isCommercialListingVideo && this.selectedProperty) {
        const tags = [this.selectedProperty?.title];
        if (this.selectedProperty.subtitle) {
          tags.push(this.selectedProperty.subtitle);
        }
        this.contentSubject.tags = tags.toString();
      }
      if (this.isCongratsVideo) {
        this.contentSubject.tags = '';
      }
      if (this.isCoverPhotos && !this.isTeamCoverPhotos) {
        this.contentSubject.tags = 'single_agent';
      }
      if (this.isTeamCoverPhotos) {
        this.contentSubject.tags = 'team';
      }
      if (this.product) {
        this.productService.styles(this.product, this.contentSubject, this.currentPage)
          .subscribe(
            res => {
              if (res) {
                this.styles = this.styles.concat(res.data);
                // if (this.isCoverPhotos) {
                //   this.styles = this.styles.filter(style => style.name.toLocaleLowerCase() === 'zen');
                // }
                const limit = this.pagination.limit = res.per_page;
                const total_items = this.pagination.total = res.total_entries;
                if (this.styles.length == 1) {
                  this.content.style = this.styles[0].effective_name;
                  this.styles[0].selected = true;
                }
                this.pagination.totalPages = Math.ceil(total_items / limit);
                this.resourceCompleted = true;
                if (!$event) {
                  this.delayedNext();
                }
                this.loading = false;
              }
            },
            err => {
              this.loading = false;
              this.error = err.message;
              this.message?.remove();
              this.message?.create('error', err.message);
            }
          );
      }
    }
  }

  getStockImages(filter?) {
    const params: Record<string, any> = {
      page: filter && filter.page ? filter.page : this.stockImagesPagination.page,
      per_page: this.stockImagesPagination.per_page
    };

    if (filter && filter.name) {
      this.stockImagesPagination.page = filter.page;
      params['q[tags_name_eq]'] = filter.name;
      params.page = filter.page;
    }

    if (filter && filter.page) {
      this.stockImagesPagination.page = filter.page;
      params.page = filter.page;
    }


    if (!this.stockImages || this.stockImagesPagination.totalPages >= params.page) {
      this.customerService.listFrom('stock_photos', params).subscribe(images => {
        if (params.page == 1) {
          this.stockImages = images.data;
        } else {
          this.stockImages = [...this.stockImages, ...images.data];
        }
        this.stockImagesPagination.total = images['total_entries'];
        this.stockImagesPagination.page = this.stockImagesPagination?.page + 1;
        this.stockImagesPagination.totalPages = Math.ceil(this.stockImagesPagination.total / this.stockImagesPagination.per_page);
        this.cdRef.detectChanges();
      });
    }
  }

  finalSubmit() {
    this.submitted = true;
    // if ((this.showSchedule && !this.isScheduleFormValid) || !this.content.style) {
    //   return;
    // }
    const platforms = [];
    if (this.isBannerVideo) {
      this.saveResource();
    } else {
      if (this.socialConfig.facebook) {
        platforms.push('facebook');
      }
      if (this.socialConfig.tiktok) {
        platforms.push('tiktok');
      }
      if (this.socialConfig.linkedin) {
        platforms.push('linkedin');
      }
      if (this.socialConfig.instagram) {
        platforms.push('instagram');
      }

      if (this.socialConfig && this.socialConfig.google) {
        platforms.push('google_business');
      }
      if (this.socialConfig && this.socialConfig.youtube) {
        platforms.push('youtube');
      }
      if (platforms.length === 0) {
        this.message?.remove();
        this.message?.create('error', 'Please select platform!');
      } else {
        this.submit(platforms);
      }
    }
  }
  render() {
    this.submitted = true;
    if ((this.showSchedule && !this.isScheduleFormValid) || !this.content.style) {
      return;
    }
    this.submit(false, true);
  }

  createListingSite() {
    const url = `/listings/${this.contentSubject.id}/websites.json`;
    this.websiteService.createCustom({ template_id: this.selectedTemplate.id }, url)
      .subscribe(res => {
        window.open(res.edit_url, '_blank');
        this.router.navigate(['websites'], { queryParams: { type: 'listings' } });
      });
  }

  fetch_linkedin_autopost_settings() {
    this.customerService.getLinkedin().subscribe((c) => {
      if (c.length > 0) {
        this.linkedinStatus = true;
        this.linkedInService.getAutopost().subscribe(int => {
          this.integration = int.data[0];
          this.linkedinAutopost = this.integration.auto_posting;
          this.socialConfig.linkedin = this.linkedinAutopost;
          this.socialConfig.facebook = this.customer?.settings?.auto_posting;
        });
      }
    });
  }

  getTemplates() {
    this.loading = true;
    const params = 'typeId=1';
    this.templatesService.getPublicUpTemplates(params)
      .subscribe(res => {
        this.styles = res.map(templ => {
          templ.url = templ?.image;
          templ.caption = templ.name;
          templ.media_type = 'image';
          return templ;
        });
        if (this.styles.length === 1) {
          this.selectTemplate(this.styles[0]);
        }
        this.loading = false;
        this.delayedNext();
      });
  }

  selectTemplate(c) {
    this.selectedTemplate = c;
    this.styles.map(template => {
      if (template.id === this.selectedTemplate.id) {
        template.selected = true;
      } else {
        template.selected = false;
      }
    });
  }

  submit(platforms?, skip_post?) {
    if (!this.selectedStyle && this.contentForm) {
      this.contentForm.checkForm();
      if (
        !this.contentForm.valid
      ) {
        this.message?.remove();
        this.message?.create('error', 'You have invalid fields.');
        return;
      }
    }

    if (this.isTeamCoverPhotos) {
      this.content.category = 'SB';

    }
    let contentRequest: Observable<Content>;
    const contentPayload: any = this.content;
    if (platforms && platforms.length) {
      contentPayload.platforms = platforms;
    }
    if (skip_post) {
      contentPayload.skip_post = true;
    }
    if (this.contentSubject.extra_attributes && this.contentSubject.extra_attributes.CTA && this.isCongratsVideo) {
      // contentPayload.extra_attributes = this.contentSubject.extra_attributes;
      contentPayload.extra_attributes = {
        ...contentPayload.extra_attributes,
        ...this.contentSubject.extra_attributes,
      };
    }
    const oneDay = 6 * 60 * 60 * 1000;
    if (this.content.scheduled_for && (+ new Date(this.content.scheduled_for)) - (+ new Date()) < oneDay) {
      this.message.create('error', 'You cannot schedule before 6 hours');
    } else if (this.isCMA) {
      this.loading = true;
      contentRequest = this.cmaService.create(contentPayload);
    } else if (this.isNew) {
      this.loading = true;
      contentRequest = this.contentService.create(contentPayload);
    } else {
      this.loading = true;
      contentRequest = this.contentService.update(contentPayload);
    }


    contentRequest.subscribe(
      res => {
        if (res) {
          this.contentCompleted = true;
          this.delayedNext();
          const route = this.product.path.split('/');
          const config = {
            type: this.product.name,
            link: this.product.name === 'banner_videos' ? 'branding/banner_videos/new' : `${route[0]}s/` + this.contentSubject.id
          };

          this.notification.create(
            'success',
            'Created',
            'Content has successfully been created'
          );
          contentPayload.posted_on = null;
          if (this.product.parent_type.includes('listing')) {
            config.link = this.router.url;
            // this.receipt(config, '/listings/' + this.content.contentable_id);
            this.router.navigateByUrl(`/content?new=${res.id}`);
            // this.router.navigateByUrl(`/content?selectedContent=${res.id}`);
          } else if (this.content.contentable_type === 'MarketReport') {
            const redirectUrl = `/my_markets/${this.content.contentable_id}`;
            config.link = `listings/${this.content.contentable_id}/create_content`;
            // this.receipt(config, redirectUrl);
            this.router.navigateByUrl(`/content?new=${res.id}`);
          } else if (this.content.contentable_type === 'Customer') {
            const redirectUrl = `/content?new=${res.id}`;
            // this.receipt(config, redirectUrl);
            this.router.navigateByUrl(redirectUrl);
          } else {
            this.router.navigateByUrl('/');
            // this.receipt(config, '/');
          }
        }
      },
      err => {
        this.loading = false;
        if (err.error.data) {
          this.error = err.error.data[0];
        } else if (err.error['tier']) {
          this.error = err.error['tier'][0];
        } else if (err.message) {
          this.error = err.message;
        } else {
          this.error = 'Something Went wrong, please try again later!';
        }
      });

  }
  gotoStep(stepNumber) {
    if (this.current > stepNumber) {
      this.current = stepNumber;
    }
  }
  delayedNext() {
    setTimeout(() => {
      this.current += 1;
    });
  }
  pre(goHome = false): void {
    if (goHome) {
      this.router.navigate([
        '/my_markets/' + this.content.contentable_id
      ]);
    }
    if (this.current > 0) {
      this.current -= 1;
    }
    this.showSchedule = false;
  }
  saveContent() {
    this.setColors();
    this.contentForm.checkForm();
    this.resourceForm.checkForm();

    if (
      !this.contentForm.valid ||
      (this.resourceForm && !this.resourceForm.valid)
    ) {
      this.message?.remove();
      this.message?.create('error', 'You have invalid fields.');
      return;
    }
    this.loading = true;
    let contentRequest: Observable<Content>;
    if (this.isNew) {
      contentRequest = this.contentService.create(this.content);
    } else {
      contentRequest = this.contentService.update(this.content);
    }
    this.resourceService
      .update(this.contentSubject)
      .pipe(mergeMap(data => contentRequest))
      .subscribe(
        res => {
          if (res) {
            if (this.product.parent_type.includes('listing')) {

              this.router.navigate([
                '/listings/' + this.content.contentable_id
              ]);
            } else {

              this.router.navigateByUrl('/branding');
            }
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
          this.error = err.message;
        }
      );
  }

  saveWebsite(): Observable<WebsiteAttributes> {
    if (
      this.contentSubject.websites_attributes.length > 0 &&
      this.contentSubject.websites_attributes[0].id
    ) {
      return of(this.contentSubject.websites_attributes[0]);
    } else {
      if (this.contentSubject.websites_attributes.length === 0) {
        this.contentSubject.websites_attributes.push({
          template_id: 2
        });
      }
      return this.resourceService
        .update(this.contentSubject)
        .pipe(map(r => r.websites_attributes[0]));
    }
  }

  contentFor(i: ProductStyle): Content {
    return new Content({
      caption: '',
      url: i.preview,
      category: this.content.category,
      style: i.name,
      thumbnail: i.preview,
      media_type: 'image',
      contentable_id: this.contentSubject.id,
      contentable_type: 'Customer',
      is_template: true
    });
  }

  nextPage() {
    if (this.pagination.totalPages > this.currentPage) {
      this.currentPage++;
      this.fetchStyles(true);
    }
  }

  hashTagLimit(value: boolean) {
    this.ishashTagLimit = value;
  }

  // receipt(config, redirectUrl) {
  // const modal = this.modalService.create({
  //   nzContent: ContentReceiptDialogComponent,
  //   nzData: {
  //     config: config
  //   },
  //   nzFooter: null
  // });
  // }
  updateColors() {
    this.updatedTextColour = this.contentSubject['settings']['text_color'];
    this.updatedBgColour = this.contentSubject['settings']['bg_color'];
    this.contentSubject['settings']['text_color'] = this.textColour;
    this.contentSubject['settings']['bg_color'] = this.bgColour;
  }

  setColors() {
    if (this.type === 'Customer') {
      this.content.bg_color = this.updatedBgColour;
      this.content.text_color = this.updatedTextColour;
    }
  }
  onChangeZeroValues(zeroCount) {
    this.zeroValueCount = zeroCount;
  }

  get diagnostic() {
    return JSON.stringify(this.content);
  }

  get isContentChanged() {
    return (JSON.stringify(this.contentSubject) !== JSON.stringify(this.clonedContentSubject));
  }
  get imageLoadEnd() {
    return this.globalService?.imageLoaded();
  }

  get pickedProperty() {
    return `(${this.selectedProperty?.title}  ${this.selectedProperty?.subtitle})`;
  }

  get isScheduleFormValid() {
    return (this.socialConfig.facebook || this.socialConfig.linkedin) && this.socialConfig && this.socialConfig.google &&
      this.content.scheduled_for
  }

  get isNormalFormValid() {
    return (this.socialConfig.facebook || this.socialConfig.linkedin)
  }
}
