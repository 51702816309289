import { Resource } from '../resource/resource';

export class Insights extends Resource {

  id: number;
  tag: string;
  description: string;
  start: Date;
  end: Date;
  resolution: string;
  stats: any;
}
