<div class="gutter-box">
  <p>
    We want to inform you that our partnership with HOI has come to an end. Despite this change, we’re excited to
    continue serving HOI agents like you with our comprehensive dashboard services throughout the rest of 2023.
    Beginning the New Year, as a token of appreciation for your loyalty, we will offer special pricing exclusively
    for HOI agents.
    <br>
    Please reach out to us at 310-997-4142 to learn more about the exclusive pricing and how our dashboard can
    continue to support your success.
  </p>
</div>