<div class="container">
  <div nz-row nzJustify="space-between">
    <div nz-col nzSpan="10" class="data-fields">
      <form #campaignForm="ngForm" nz-form>
        <div class="customer-form" nz-row nzType="flex" nzJustify="space-between">
          <nz-form-item class="gutter-row" nz-col nzSpan="24">
            <nz-form-label nzFor="campaign_name" nzRequired>Give your campaign a name
            </nz-form-label>
            <nz-form-control nzErrorTip="Please input your campaign name!">
              <nz-input-group>
                <input type="text" nz-input id="campaign_name"  [(ngModel)]="campaign.name" name="campaign_name" required placeholder="Campaign Name"/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="customer-form" nz-row nzType="flex" nzJustify="space-between">
          <nz-form-item class="gutter-row" nz-col nzSpan="24">
            <nz-form-label nzFor="subject" nzRequired>Email Subject
            </nz-form-label>
            <nz-form-control nzErrorTip="Please input your email subject!">
              <nz-input-group>
                <input type="text" nz-input id="subject" [(ngModel)]="campaign.subject" name="subject" required placeholder="Email Subject"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- <div class="customer-form" nz-row nzType="flex" nzJustify="space-between">
          <nz-form-item class="gutter-row" nz-col nzSpan="24">
            <nz-form-label nzFor="text">Preview Text
            </nz-form-label>
            <nz-form-control>
              <nz-input-group>
                <input type="text" nz-input id="text" [(ngModel)]="campaign.preheader" name="text" placeholder="Preview Text" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div> -->
        <h4>Sending to:</h4>
          <div nz-row nzJustify="space-between" class="sub-heading">
            <div>
              <span>{{sendingList}}</span>
            </div>
            <div>
              <a href="javascript:void(0);" (click)="changeStep()">Edit</a>
            </div>
          </div>
      </form>
    </div>
    <nz-divider nzType="vertical" class="template-seperator"></nz-divider>
    <div nz-col nzSpan="14" class="preview-container" id="img-conatiner">
        <iframe #iframe frameborder="0" width="102%" height="100%" style="border:none">
          </iframe>
    </div>
  </div>
</div>
