<h1 mat-dialog-title>Pick an Image</h1>
<div mat-dialog-content>
  <nz-divider></nz-divider>
  <div class="scollable-section" nz-row nzJustify="center" *ngIf="images && images.length; else notimage">
      <div nz-col nzSpan="7" class="back-image" *ngFor="let image of images; index as i"
        [style.backgroundImage]="'url(' + image.large + ')'"
        [class.selected]="i === selected"
        (click)="select(image)">
      </div>
  </div>
  <ng-template #notimage>
    <nz-empty nzNotFoundContent="No Image Found!" class="no-content"></nz-empty>
  </ng-template>
</div>
