<ng-template #editReport>
  <h1 class="title page-title">{{isNew ? 'Create a New' : 'Update Your'}} Market Report</h1>
  <nz-divider></nz-divider>
  <app-market-report-form #marketReportForm [model]="marketReport"></app-market-report-form>
  <nz-divider></nz-divider>
  <span class="error" *ngIf="error">{{ error }}</span>
  <button class="save-btn" nz-button nzType="primary" (click)="save()">{{isNew ? 'Create' : 'Save'}}</button>
  <button nz-button  (click)="cancel()">Cancel</button>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container *ngTemplateOutlet="editReport"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container *ngTemplateOutlet="editReport"></ng-container>
</ng-template>
