import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
/**
 * Blank view guard for view access and data preloading.
 */
export class LoginGuard  {

  /**
   * Method to determine if we can activate the view based on custom logic.
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private jwtService: JwtService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (next.queryParams[ 'override' ]) {
      const token = next.queryParams[ 'override' ];
      return this.authService.overrideLogin(token).pipe(
        map((c) => {
          if (c) {
            this.router.navigateByUrl('/');
            return false;
          }
          return true;
        },
          catchError(e => {
            if (e instanceof HttpErrorResponse && e.status === 401) {
              this.authService.logout();
            }
            return of(true);
          }))
      );
      // this.jwtService.destroyToken();
    }
    return true;
  }

  invalidCustomer(): boolean {
    return !this.authService.currentCustomerValue || !this.jwtService.getToken();
  }
}
