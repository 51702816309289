import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Infographic } from 'src/app/vos/infographic/infographic';
import { AuthenticationService } from '../authentication/authentication.service';
import { map } from 'rxjs/operators';
import { CustomerResourceService } from '../customer-resource.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */

@Injectable()
/**
 * Service class.
 */
export class BrandedInfographicsService extends CustomerResourceService<Infographic> {

  public endpoint = 'branded_infographics';
  public data_key = 'branded_infographic';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Infographic, http, authService);
  }
}
