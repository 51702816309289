import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { Customer, Integration } from '../../vos/customer/customer';
import {
  NgForm,
  ValidationErrors,
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray
} from '@angular/forms';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { GlobalsService } from '../../services/globals/globals.service';
import { BrokerageService } from '../../services/brokerage/brokerage.service';
import { RegionService } from '../../services/region/region.service';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ImagesReorderComponent } from '../images-reorder/images-reorder.component';
import {CustomersService} from '../../services/customers/customers.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, AfterViewInit {
  console = console;
  brokerageList = [];
  regionList = [];
  selectedBrokerage;
  @ViewChild('customerForm') form: NgForm;
  @ViewChild('imageReorder') imageReorder: ImagesReorderComponent;
  @Output() validChange = new EventEmitter<boolean>();
  @Output() retry = new EventEmitter<any>();
  @Input() valid = false;
  @Output() errors = new EventEmitter<ValidationErrors>();
  @Input() requiredFields = [
    // 'first_name',
    // 'last_name',
    // 'email',
    'name',
    'brokerage',
    'display_phone',
    // 'primary_phone',
    'display_email',
    'primary_email',
    'headshot',
    'logo',
    'display_name',
    'preferred_title',
    'primary_region',
    // 'license_number'
  ];
  @Input() showFields = [
    'name',
    'brokerage',
    'display_phone',
    // 'primary_phone',
    'display_email',
    'primary_email',
    'headshot',
    'logo',
    'team',
    'images',
    'slogan'
  ];
  @Input() limitShow = false;
  @Input() model: any;
  colors = Array<string>();
  submitted = false;
  highlightInvalid = false;
  titles = Array<string>();
  credentials = Array<string>();

  loading = false;
  filteredOptions: string[] = [];
  clonedCustomer: Customer;
  get headshotConfig() {
    const target = {
      customer_id: this.model.id
    };
    const config = {
      id: this.model.id,
      imageableType: 'Customer',
      type: 'headshot',
      aspectRatio: 1,
      minWidth: 50,
      minHeight: 50,
    };
    return {
      configMeta: config,
      image: this.model ? this.model?.image.large : '',
      isNew: !!this.model?.image.large,
      channel: 'HeadshotChannel',
      target: target
    };
  }
  get logoConfig() {
    const target = {
      customer_id: this.model.id
    };
    const config = {
      id: this.model.id,
      imageableType: 'Customer',
      type: 'logo',
      aspectRatio: 1,
      minWidth: 50,
      minHeight: 50,
      maintainRatio: false
    };
    return {
      configMeta: config,
      image: this.model ? this.model.logo.original : '',
      isNew: !!this.model.logo.original,
      channel: 'LogoChannel',
      target: target
    };
  }
  constructor(
    private authenticationService: AuthenticationService,
    private brokerageService: BrokerageService,
    private regionService: RegionService,
    private customerService: CustomersService,
    private globalsService: GlobalsService) {
    this.credentials = this.globalsService.getCredentials();
    this.titles = this.globalsService.getTitles();
    this.filteredOptions = this.titles;

  }

  ngAfterViewInit() {
    this.form.valueChanges.subscribe(() => {
      this.validChange.emit(this.form.valid);
      this.valid =
        this.form.valid &&
        this.imgaesValid('image') &&
        this.imgaesValid('logo') &&
        this.imgaesValid('banner_images');
    });
  }

  ngOnInit() {
    this.clonedCustomer = JSON.parse(JSON.stringify(this.model));
    this.fetchBrokreage();
    this.fetchRegions();
    if (!this.model.settings.text_color) {
      this.model.settings.text_color = '#222222';

    }

    if (!this.model.settings.bg_color) {
      this.model.settings.bg_color = '#FFFFFF';
    }

    if (!this.model?.region?.id) {
      this.model.region = { id: '' };
    }
    this.colors = this.globalsService.getColors();

    this.authenticationService.refresh()
      .subscribe(c => {
        this.authenticationService.updateCurrentCustomer(c);
      });
  }

  fetchBrokreage() {
    this.brokerageService.brokerageList()
      .subscribe(res => {
        if (res) {
          this.brokerageList = res.data;
          if (this.model.brokerage) {
            this.selectedBrokerage = this.brokerageList.find(br => br.title === this.model.brokerage);
          }
        }
      }, err => {
        console.error(err);
      })
  }
  fetchRegions() {
    this.regionService.regionList()
      .subscribe(res => {
        if (res) {
          this.regionList = res.data;
          if (this.model?.region?.id) {
            const selectedRegion = this.regionList.find(r => r.id === this.model?.region?.id);
          } else {
            this.model.region = { id: '' };
          }
        }
      }, err => {
        console.error(err);
      })
  }
  changeBrokerage() {
    this.loading = true;
    this.selectedBrokerage = this.brokerageList?.find(br => br.title === this.model.brokerage);
    if (this.selectedBrokerage) {
      this.model.settings.bg_color = this.selectedBrokerage.secondary;
      this.model.settings.text_color = this.selectedBrokerage.primary;
      this.model.logo.original = this.selectedBrokerage.original;
      this.model.logo.thumb = this.selectedBrokerage?.thumb;
      this.model.logo.large = this.selectedBrokerage.large;
      this.model.stock_logo_id = this.selectedBrokerage.id;
    } else {
      // this.model.settings.bg_color = '#FFFFFF';
      // this.model.settings.text_color = '#222222';
      // this.brandColor.bg_color = '#FFFFFF';
      // this.brandColor.text_color = '#222222';
      // this.model.logo = JSON.parse(JSON.stringify(this.clonedCustomer.logo));
      // this.model.stock_logo_id = null;
    }
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  checkControls(controls: AbstractControl[]) {
    controls.forEach(c => {
      if (c instanceof UntypedFormControl) {
        c.markAsTouched();
        c.updateValueAndValidity();
      } else if (c instanceof UntypedFormGroup) {
        this.checkControls(Object.keys(c.controls).map(k => c.controls[k]));
      } else if (c instanceof UntypedFormArray) {
        this.checkControls(c.controls);
      }
    });
  }

  checkForm() {
    this.highlightInvalid = true;
    this.valid = true
      this.form.valid &&
      this.imgaesValid('image') &&
      this.imgaesValid('logo') &&
      this.imgaesValid('banner_images');
    this.checkControls(Object.keys(this.form.controls).map(k => this.form.controls[k]));
  }
  imageUploaded(event, field) {
    const imageURL = event.cdnUrl;
    this.model['remote_' + field + '_url'] = imageURL;
  }

  imgaesValid(imageType: string): boolean {
    switch (imageType) {
      case 'image':
      case 'logo':
        return (
          !this.requiredFields.includes(imageType) ||
          this.model[imageType].large ||
          this.model['remote_' + imageType + '_url']
        );
      case 'banner_images':
        return (
          !this.requiredFields.includes('banner_images') ||
          this.model?.images_attributes?.filter(i => (i.id || i.stock_photo_id)).length >= 5
        );
    }
  }
  bannerUploaded(event, index) {
    if (!this.model.images_attributes) {
      this.model.images_attributes = [];
      this.model.images_attributes.length = 5;
    }
    const imageURL = event.cdnUrl;
    const imageId = this.model.images[index]
      ? this.model.images[index].id
      : null;
    this.model.images_attributes[index] = {
      id: imageId,
      remote_image_url: imageURL
    };
  }

  setLogo(object) {
    this.model.logo.original = object?.image.logo;
    this.clonedCustomer = JSON.parse(JSON.stringify(this.model));
    this.model.stock_logo_id = null;
  }
  setHeadshot(object) {
    this.model.image.large = object?.image.headshot;
  }
  onChangetitle(value: string): void {
    this.filteredOptions = this.titles?.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }

  openGallery(step?) {
    this.imageReorder.openImageDialog(this.model?.images_attributes[0], step);
  }
  toggleSetting() {
    this.customerService.update(this.model).subscribe((c) => {
      this.model = c;
    });
  }

  get realtorSpan() {
    let span = 7;
    if (this.showFields.includes('brokerage') && !this.showFields.includes('credential') && this.showFields.includes('license_number')) {
      span = 11
    }
    return span;
  }
}
