<div class="steps-content congrats-container">
  <div class="congrats">
    <i nz-icon nzType="warning" nzTheme="outline" style="text-align: center; display: block; margin: 0 auto;"></i>
    <h2 style="text-align: center; display: block; margin: 0 auto;">Warning!</h2>
    <h3>Are you sure? Whenever you create a listing of the type {{template}}, it will automatically trigger an SMS campaign.
    </h3>
  </div>
  <nz-divider></nz-divider>
  <div class="ok-btn" style="display: flex; justify-content: space-between;">
    <button nz-button nzType="primary" (click)="handleClose(true)">Confirm</button>
  </div>
</div>
