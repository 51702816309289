import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GlobalsService } from '../../services/globals/globals.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from '../../vos/customer/customer';
import {CustomersTiktokService} from '../../services/customers-tiktok/customers-tiktok.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-tiktok-onboarding-dialog',
  templateUrl: './tiktok-onboarding-dialog.component.html',
  styleUrls: ['./tiktok-onboarding-dialog.component.scss']
})

export class TiktokOnboardingDialogComponent implements OnInit {
  @Input()
  config: any;
  loading = false;
  code: string;
  facebookPages = [];
  showPages = false;
  selectedPageId;
  paging = null;
  isDone = false;
  currentPage: any;
  customer: Customer;
  PaidTier = false;
  broadcastChannel: BroadcastChannel;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private globalsService: GlobalsService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private router: Router,
    private authService: AuthenticationService,
    private customersTiktokService: CustomersTiktokService,
    private notification: NzNotificationService,

  ) {
    this.authService.refresh()
      .subscribe(c => {
        if (c) {
          this.customer = c;
          this.PaidTier = c?.tier === 'ultra';
        }
      });
  }
  ngOnInit() {
    if(this.nzModalData.config){
      this.config = this.nzModalData.config;
    }
    try {
      if (BroadcastChannel) {
        this.broadcastChannel = new BroadcastChannel('tiktok');
        this.broadcastChannel.onmessage = (ev) => {
          this.close();
        };
        if (this.config && this.config?.autoConnect) {
          this.loading = true;
          this.customersTiktokService.authorizeTiktok();
        }
      }
    }
    catch {
    }
  }

  close() {
    this.modal.close();
  }
}
