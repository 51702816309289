<!-- <pdf-viewer [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              style="width: 400px; height: 500px"
  ></pdf-viewer> -->
  <pdf-viewer
  [src]="pdfSrc"
  [rotation]="0"
  [original-size]="false"
  [show-all]="true"
  [fit-to-page]="false"
  [zoom]="1"
  [zoom-scale]="'page-width'"
  [stick-to-page]="false"
  [render-text]="true"
  [external-link-target]="'blank'"
  [autoresize]="true"
  [show-borders]="false"
  style="width: 100%; height: 600px;"
></pdf-viewer>

