import { Component, OnInit, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { GlobalsService } from '../../services/globals/globals.service';
import { EventEmitter } from '@angular/core';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { ImageConfig } from 'src/app/models/interfaces/modals/image-config';

@Component({
  selector: 'app-image-handler',
  templateUrl: './image-handler.component.html',
  styleUrls: ['./image-handler.component.scss']
})
export class ImageHandlerComponent implements OnInit {
  @Input() imageable_type;
  @Input() content: any;
  // @Input() config: any;
  @Input() fullWidth: boolean = false;
  @Output() imageObject = new EventEmitter();

  private _config: any;

  @Input() set config(value) {
    this._config = value;
    if (this.content && this._config) {
      this.image = this.config?.image;
      this.isNew = this.config.isNew;
    }
  }

  get config() {
    return this._config;
  }
  subscription: Subscription;
  isNew = false;
  image;
  newImageType;
  updatedImage: string;
  updateImage = false;
  newImage;
  // config: any;
  constructor(
    private modalService: NzModalService,
    private cableService: ActionCableService,
    private globalsService: GlobalsService,
    private notification: NzNotificationService
  ) { }

  ngOnInit() {
    if (this.content && this.config) {
      this.image = this.config?.image;
      this.isNew = this.config.isNew;
    }
  }
  selectFromGallery() {
    const configs = {
      nzFooter: null,
      nzData: {
        config: {
          aspectRatio: '2:1',
          cols: 3,
          gutterSize: '10px',
          instantSelection: true
        }
      },
      nzWidth: '75%'
    };
    configs['nzContent'] = ImageGalleryComponent;
    const modal = this.modalService.create(configs);

    modal.afterClose.subscribe(image => {
      if (image) {
        this.imageObject.emit({ ...image, 'id': image.id, 'type': 'gallery' });
        this.newImage = image?.thumb ? image?.thumb : image.original;
        this.isNew = true;
      }
    });
  }
  openImageDialog(type) {
    this.setImageLoadingEnd(true);
    this.newImageType = type;
    const modal = this.modalService.create<ImageCropperDialogComponent, ImageConfig>({
      nzContent: ImageCropperDialogComponent,
      nzData: {
        config: this.config.configMeta,
        images: this.config.images
      },
      nzWidth: '80%',
      nzFooter: null,
      nzStyle: {
        top: '24px'
      }
    });
    modal.afterClose.subscribe(image => {
      if (image) {
        if (this.newImageType === 'cover_photo') {
          this.imageObject.emit(image);
          this.newImage = image.original;
          this.image = image.original;
          this.isNew = true;
        } else {
          this.ImageListener();
        }
      } else {
        this.setImageLoadingEnd(false);
      }
    });
  }
  ImageListener() {
    this.updateImage = true;
    const channel: Channel = this.cableService.cable(environment.wss_url).channel(this.config.channel, this.config.target);
    // Subscribe to incoming messages
    this.subscription = channel.received().subscribe(response => {
      if (response && response.errors) {
        for (const error of response.errors) {
          this.notification?.remove();
          this.notification?.create(
            'error',
            'Error',
            error
          );
        }
      } else if (response) {
        // NEED TO UPDATE
        if (this.newImageType === 'attestant_photo' || this.newImageType === 'cover_photo') {
          const image = JSON.parse(response.data);
          this.imageObject.emit({ 'id': image.id, 'image': image });
          this.newImage = image.original;
        } else {
          this.imageObject.emit({ 'image': response.data });
          this.newImage = this.updatedImage;
        }
        this.isNew = true;
      }
      setTimeout(() => {
        this.updateImage = false;
      }, 2000);
      this.setImageLoadingEnd(false);
    }, err => {
      console.log(err);
    });
  }
  setImageLoadingEnd(imageLoading) {
    this.globalsService.imageLoadEnd(imageLoading);
  }
  removeImage($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.imageObject.emit({ image: {
      family_photo: null
    } });
    this.config?.removeFamilyPhoto()
  }
}

