import { Component, OnInit } from '@angular/core';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { LeadsService } from '../../services/leads/leads.service';
import { Customer } from '../../vos/customer/customer';
import { AudiencesService } from '../../services/audiences/audiences.service';

@Component({
  selector: 'app-audiences',
  templateUrl: './audiences.component.html',
})
export class AudiencesComponent implements OnInit {
  constructor(
    private breadcrumService: BreadCrumService,
    // private audienceService: AudiencesService
  ) { }

  ngOnInit() {
    this.breadcrumService.set_breadcrum();
  }
}
