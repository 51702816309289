import moment from 'moment';
import { Resource } from '../resource/resource';
import { WebsiteAttributes } from 'src/app/shared/interfaces/website-attributes';
import { Content } from '../content/content';
export class Lead extends Resource {
  /**
   * id
   */
  id?: number;

  /**
   * lead_type : buyer/seller
   */
  lead_type: string;

  /**
   * status
   */
  name: string;

  /**
   * phone
   */
  phone: string;

  /**
   * email
   */
  email: string;

  address: string;

  transaction_in: string;

}
