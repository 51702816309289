import { Resource } from '../resource/resource';

export class Template extends Resource {

  id: number;
  name: string;
  customer_id: number;
  template_type: string;
  preview_image: string;
  raw_body: string;
  templateable_type: any;
  created_at: string;
  updated_at: string;
  url: string;
  subject: string;
  status: string;
}
