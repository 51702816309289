import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Infographic } from 'src/app/vos/infographic/infographic';
import { AuthenticationService } from '../authentication/authentication.service';
import { CustomerResourceService } from '../customer-resource.service';

@Injectable()
export class CustomContentService extends CustomerResourceService<Infographic> {

  public endpoint = 'custom_contents';
  public data_key = 'custom_content';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Infographic, http, authService);
  }
}
