import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Lead } from 'src/app/vos/lead/lead';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class LeadsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class LeadsService extends CustomerResourceService<Lead> {

  public endpoint = 'leads';
  public data_key = 'lead';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Lead, http, authService);
  }

  exportLeads(): Observable<any> {
    return this.http.get(
      `${this._customerURI}${this.customerEndpoint}.csv`,
      { responseType: 'text' }
    );
  }


  downloadFile(data, filename = 'contacts') {
    let csvData = this.ConvertToCSV(data, ['name', 'phone', 'email', 'lead_type', 'source', 'address', 'created_ad']);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document?.body.appendChild(dwldLink);
    dwldLink.click();
    document?.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerList) {
      if (headerList[index] === 'created_ad') {
        row += 'Created On' + ',';
      } else {
        row += headerList[index].titleize() + ',';
      }

    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line;
      for (let index in headerList) {
        let head = headerList[index];
        if (line) {
          line += ',' + array[i][head] || '';
        } else {
          line = array[i][head] || '';
        }

      }
      str += line + '\r\n';
    }
    return str;
  }

}
