import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class PaginationServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class PaginationService {
  private  currentPage = 1;
  constructor(http: HttpClient, authService: AuthenticationService) {
    // super(http, authService);
  }
  getCurrentPage() {
    return this.currentPage;
  }
  setCurrentPage(data) {
    this.currentPage = data.page;
  }
}
