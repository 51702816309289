import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Subscription, StripeCoupon } from 'src/app/vos/subscription/subscription';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { tap, map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { DataResponse } from '../../models/data-response/data-response';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class SubscriptionsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class SubscriptionService extends CustomerResourceService<Subscription> {
  public endpoint = 'stripe_subscriptions';
  public data_key = 'subscription';
  private currentSubscriptionObject: BehaviorSubject<Subscription> = new BehaviorSubject<Subscription>(null);

  // private currentSubscriptionSubject = new BehaviorSubject<Subscription>(new Subscription({}));

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Subscription, http, authService);
    // this.list().subscribe();
    this.authService.currentCustomer.subscribe(c => {
      if (!c) {
        this.currentSubscriptionObject.next(null);
      } else if (!this.currentSubscriptionObject?.value) {
        this.list().subscribe();
      }
    });
  }
  currentPlan(): Observable<string> {
    return this.currentSubscription().pipe(map(s => s.plan.id));
  }
  currentSubscription(): Observable<Subscription> {
    if (!this.currentSubscriptionObject?.value) {
      this.list().subscribe();
    }
    return this.currentSubscriptionObject.asObservable();
  }

  updateSubscription(newVals: { [ key: string ]: any }) {
    const newVal = Object.assign(this.currentSubscriptionObject?.value, newVals);
    if (newVal.id) {
      return super.update(newVal, true).pipe(
        tap(_ => this.authService.refresh().subscribe()),
        tap(s => this.currentSubscriptionObject.next(s))
      );
    } else {
      return super.create(newVal).pipe(
        tap(_ => this.authService.refresh().subscribe()),
        tap(s => this.currentSubscriptionObject.next(s))
      );
    }
  }

  getCoupon(code: string): Observable<StripeCoupon> {
    const url = `${this._customerURI}${this.customerEndpoint}/coupon.json?code=${code}`;
    return this.http.get<DataResponse<StripeCoupon>>(url).pipe(
      map(resp => {
        return resp.data;
      })
    );
  }
}
