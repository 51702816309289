import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { CardButtonConfig } from '../../models';
import { Product } from '../../vos/product/product';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Customer } from '../../vos/customer/customer';
import { TipsService } from '../../services/tips/tips.service';
import { AdsService } from '../../services/ads/ads.service';
import { Ad } from '../../vos/ads/ads';
import { DataResponse } from '../../models/data-response/data-response';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { PdfViewerDialogComponent } from '../../shared/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { SwiperOptions } from "swiper";
import { SwiperComponent } from "ngx-useful-swiper";
import { IModalData } from "src/app/models/interfaces/modals/modal";
@Component({
  selector: "app-tips",
  templateUrl: "./tips.component.html",
  styleUrls: ["./tips.component.scss"],
})
export class TipsComponent implements OnInit {
  @ViewChild("usefulSwiper", { static: false }) usefulSwiper: SwiperComponent;
  config: SwiperOptions = {
    // pagination: { el: '.swiper-pagination', clickable: true },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    allowTouchMove: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      slideChange: (e) => {
        // this.nextPage.emit();
      },
    },
    // spaceBetween: 10,
    // slidesPerView: 3,
    // slidesPerGroup: 3,
    // slidesPerView: Math.floor(window.innerWidth / 300),
    // slidesPerGroup: Math.floor(window.innerWidth / 300),
    // loop: true,
    // Responsive breakpoints
    breakpoints: {
      2500: {
        slidesPerView: 4,
        spaceBetween: 5,
        slidesPerGroup: 4,
      },
      2200: {
        slidesPerView: 3,
        spaceBetween: 5,
        slidesPerGroup: 4,
      },
      1920: {
        slidesPerView: 3,
        spaceBetween: 4,
        slidesPerGroup: 4,
      },
      1850: {
        slidesPerView: 3,
        spaceBetween: 5,
        slidesPerGroup: 3,
      },

      1700: {
        slidesPerView: 2,
        spaceBetween: 6,
        slidesPerGroup: 3,
      },
      1250: {
        slidesPerView: 2,
        spaceBetween: 10,
        slidesPerGroup: 2,
      },
      // 1024: {
      //   slidesPerView: 2,
      //   spaceBetween: 10,
      //   slidesPerGroup: 2
      // },
      940: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 2,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
        slidesPerGroup: 1,
      },
    },
    // loopFillGroupWithBlank: true,
  };

  @Input() sliderView = false;
  loading = true;
  customer: Customer;
  marketingTips = [];
  page = 1;
  pageInfo = { total_entries: 0, per_page: 10, current_page: 1 };

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private tipsService: TipsService,
    private adsService: AdsService,
    private breadcrumService: BreadCrumService,
    private router: Router,
    private modalService: NzModalService
  ) {}

  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    this.getTips();
    this.breadcrumService.set_breadcrum();
  }

  getTips() {
    this.loading = true;
    const customerId = this.customer?.id;
    // const page = `?page=${this.page}`;
    // 'q[s]': 'created_at desc',
    this.tipsService
      .listFrom("marketing_tips", { page: this.page, 'q[s]': 'created_at desc' })
      .subscribe((res: any) => {
        if (res && res.data.length > 0) {
          this.pageInfo = {
            total_entries: res.total_entries,
            per_page: res.per_page,
            current_page: res.current_page,
          };
          const tips = res.data;
          tips.forEach((element) => {
            const index = this.marketingTips?.findIndex(
              (lead) => lead.id === element.id
            );
            if (index === -1) {
              this.marketingTips.push(element);
            }
          });
          // for(let i = 0; i < 10 ; i++) {
          //   this.marketingTips.push(this.marketingTips[0]);
          // }
        }
        this.loading = false;
      });
  }

  didPage(page: number) {
    this.page = page;
    this.getTips();
  }

  openPdf(pdfSrc) {
    const modal = this.modalService.create<PdfViewerDialogComponent, IModalData>({
      nzContent: PdfViewerDialogComponent,
      nzData: {
        pdfSrc,
      },
      nzFooter: null,
      nzWidth: "50%",
    });
    modal.afterClose.subscribe((response) => {});
  }
}
