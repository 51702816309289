import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Notification } from 'src/app/vos/notification/notification';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { environment } from '../../../environments/environment';

import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class NotificationServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class NotificationService extends CustomerResourceService<Notification> {

  public endpoint = 'notifications';
  public data_key = 'notification';
  public loading = false;
  private subject = new Subject<any>();
  private notificationsChange = new Subject<any>();

  private notificationData = {
    data: [],
    unopenedCount: 0
  }

  constructor(
    http: HttpClient,
    authService: AuthenticationService,
    private cableService: ActionCableService,
  ) {
    super(Notification, http, authService);
  }
  mark_read(id: number) {
    return this.http
      .post(
        `${this._customerURI}${this.customerEndpoint}/${id}/open`,
        { headers: this.headers }
      )
      .pipe(map(resp => {
        this.fetchNotifications();
        return resp;
      }));
  }
  mark_read_all() {
    return this.http
      .post(
        `${this._customerURI}${this.customerEndpoint}/open_all`,
        { headers: this.headers }
      )
      .pipe(map(resp => {
        this.fetchNotifications();
        return resp;
      }));
  }
  clear_notification(): Observable<any> {
    return this.subject.asObservable();
  }
  sendClearNotification(data) {
    this.subject.next(data);
  }

  setNotifications(notifications) {
    this.notificationData.data = notifications
  }

  setUnreadCount(count) {
    this.notificationData.unopenedCount = count;
  }

  notificationsSub(): Observable<any> {
    return this.notificationsChange.asObservable();
  }

  pushNotification(): Channel {
    const customer = this.authService.currentCustomerValue;
    if (customer) {
      const customer_id = customer.id;
      const target = {
        target_type: 'Customer',
        target_id: customer_id
      };
      return this.cableService.cable(environment.wss_url)
        .channel('ActivityNotification::NotificationChannel', target);
    }
  }

  fetchNotifications() {
    this.loading = true;
    this.list().subscribe(res => {
      this.notificationData.data = res.data;
      this.notificationData.unopenedCount = res['unopened_count'];
      this.notificationsChange.next(res);
      this.loading = false;
    });
  }

  get unreadCount() {
    return this.notificationData.unopenedCount;
  }

  get allNotifications() {
    return this.notificationData.data || [];
  }
}
