import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-magic-login',
  templateUrl: './magic-login.component.html',
  styleUrls: ['./magic-login.component.scss'],
  animations: [
    trigger('flyInOut', [
      // state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s')
      ]),
      transition(':leave',
        animate('0.5s', style({ opacity: 0 })))
    ])
  ]
})
/**
 * Blank view.
 */
export class MagicLoginComponent implements OnInit {
  authForm: UntypedFormGroup;
  invited = false;

  errorMessage: string;
  emailValid = true;
  emailExists = false;
  email: string;
  loading = false;
  resendWait = 0;


  /**
   * Component constructor and DI injection point.
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private message: NzMessageService,
  ) {
  }
  EmailExists: ValidatorFn = (fg: UntypedFormGroup) => {
    const valid = this.emailValid;
    return valid
      ? null
      : { missing: true };
  }
  /**
 * Called part of the component lifecycle. Best first
 * place to start adding your code.
 */
  ngOnInit() {
    const d = { ...this.route.snapshot.data, ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
    this.emailExists = false;
    this.setFormsWith(d);
  }

  setFormsWith({ state: _, ...obj }: any = {}) {
    this.authForm = this.fb.group({
      ...obj,
      email: ['', Validators.required],
    });
  }


  checkAuthForm() {
    for (const i in this.authForm.controls) {
      this.authForm.controls[i].markAsDirty();
      this.authForm.controls[i].updateValueAndValidity();
    }
  }

  invite() {
    this.loading = true;
    this.errorMessage = null;
    this.checkAuthForm();
    const creds = this.authForm?.value;
    this.email = creds.email;
    this.authService.magicInvite(creds.email).subscribe(customer => {
      this.invited = true;
      this.message.create('success', 'Email is sent');
    },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.errorMessage = err.error.message;
      });
  }

  goto(url) {
    this.router.navigateByUrl(url);
  }

}
