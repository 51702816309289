import { Component, OnInit, ViewChild } from '@angular/core';
import { Customer } from 'src/app/vos/customer/customer';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerFormComponent } from '../../shared/customer-form/customer-form.component';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { GlobalsService } from 'src/app/services/globals/globals.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  customer: Customer;
  error: string;
  valid = false;
  loading = false;
  errorMessage: string;
  @ViewChild('customerForm') customerForm: CustomerFormComponent;
  constructor(
    private authService: AuthenticationService,
    private customerService: CustomersService,
    private message: NzMessageService,
    private _location: Location,
    private breadcrumServices: BreadCrumService,
    private modalService: NzModalService,
    private globalService: GlobalsService
  ) {
    this.authService.currentCustomer.subscribe(c => {
      if (c) {
        this.customer = c;
        if (!this.customer?.region) {
          this.customer.region = { id: '' };
        }
      }
    });
    // if (!this.customer.team_members_attributes) {
    //   this.customer.team_members_attributes = this.customer['team_members'];
    // }
  }

  ngOnInit() {
    this.authService.refresh()
      .subscribe(c => {
        this.customer = c;
        if (!this.customer?.region?.id) {
          this.customer.region = { id: '' };
        }
      });
    this.breadcrumServices.set_breadcrum();

  }
  cancel() {
    this._location.back();
  }
  formChanged(event) {

  }
  submitCustomer(success_message = 'Success! Profile saved.') {
    this.loading = true;
    this.customerService.update(this.customer).subscribe((response) => {
      if (response) {
        this.customer = response;
        if (!this.customer?.region?.id) {
          this.customer.region = { id: '' };
        }
        this.authService.updateCurrentCustomer(response);
        this.message.create('success', success_message);
      }
      this.loading = false;
    },
      error => {
        this.loading = false;
        this.error = error.error;
        this.message?.remove();
        this.message?.create('error', this.error);
      });
  }
  save() {
    if (!this.customerForm.valid || !this.customer?.region?.id) {
      this.customerForm.checkForm();
      this.message?.remove();
      this.message?.create('error', 'You have invalid fields.');
      return;
    }
    this.submitCustomer();
  }
  get diagnostic(): string {
    return JSON.stringify(this.customer);
  }

  // selectPage() {
  //   this.fbService?.getLoginStatus()
  //     .then((status) => {

  //       if (status.authResponse && status.status === 'connected') {
  //         this.fbService?.api(`/v3.2/me/accounts?fields=name,id,picture`)
  //           .then(response => {
  //             if (response) {
  //               const pages = response.data?.map(p => ({ label: p.name, value: p.id, image: p.picture.data.url }));
  //               const modal = this.modalService.create({
  //                 nzContent: GenericDialogComponent,
  //                 nzData: {
  //                   config: {
  //                     title: 'Select which page Universal Promote should manage.',
  //                     singleSelect: pages,
  //                     buttonLabel: 'skip'
  //                   }
  //                 },
  //                 nzFooter: null
  //               });
  //               modal.afterClose.subscribe(pageId => {
  //                 if (pageId) {
  //                   this.customer.fb_page_id = pageId;
  //                   this.submitCustomer('Please check your facebook account to accept our request to manage your page.');
  //                 }
  //               });
  //             }
  //           });
  //       } else {
  //         this.linkFB();
  //       }
  //     });
  // }

  // connectFB(response: AuthResponse) {
  //   this.loading = true;
  //   this.customerService.linkFB(response.accessToken).subscribe(customer => {
  //     this.loading = false;
  //     this.customer = customer;
  //     if (!this.customer?.region?.id) {
  //       this.customer.region = { id: '' };
  //     }
  //     this.message.create('success', 'Connected');
  //     this.selectPage();
  //   },
  //     (err: HttpErrorResponse) => {
  //       this.loading = false;
  //       this.errorMessage = err.error.message;
  //     });
  // }
  // linkFB(): void {
  //   this.loading = true;
  //   // this.errorMessage = null;
  //   // this.fbService.getLoginStatus()
  //   //   .then((status) => {

  //   //     if (status.authResponse && status.status === 'connected') {
  //   //       this.connectFB(status.authResponse);
  //   //     } else {
  //   this.fbService?.login({
  //     scope: 'public_profile,user_friends,email,pages_show_list,pages_manage_metadata',
  //     return_scopes: true,
  //     enable_profile_selector: true
  //   })
  //     .then((response: LoginResponse) => this.connectFB(response.authResponse)
  //     )
  //     .catch((error: any) => {
  //       this.loading = false;
  //       console.error(error);
  //     });
  // }
  // })
  // .catch((error: any) => {
  //   this.errorMessage = 'Could not sign in with Facebook. An error occurred';
  //   this.loading = false;
  //   console.error(error);
  // });
  get imageLoadEnd() {
    return this.globalService?.imageLoaded();
  }
}
