<div>
  <h5>
    Click and drag an image to change the order of the images in your video
    <b>or</b>
    use the arrows at the bottom to easily rearrange the the photos for your video.
  </h5>

  <div (cdkDropListDropped)="drop($event)" cdkDropList cdkDropListOrientation="horizontal" class="image-list" nz-row
       nzJustify="start"
       nzType="flex">
    <div *ngFor="let image of images.slice(0,maxCount); index as i">
      <div class="image-card" cdkDrag nz-row nzType="flex" nzJustify="center">
        <div class="overlay" nz-row>
          <div class="upload-button" nz-col nzSpan="24" [style.backgroundColor]="'lightgray'" (click)="openImageDialog(image)">
            <p class="upload-text-single">Upload</p>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="3" class="white-back" *ngIf="image.thumb || image.remote_image_url || image.original">
            <i class="drag" nz-icon nzType="more" nzTheme="outline"></i>
            <i class="drag drag-pair" nz-icon nzType="more" nzTheme="outline"></i>
          </div>
          <div nz-col nzSpan="21" class="image-holder image-container" *ngIf="image.thumb || image.remote_image_url || image.original">
            <img  [src]="image.thumb || image.remote_image_url || image.original" />
          </div>
          <nz-spin nzSimple class="photo-loading" *ngIf="image.loading"></nz-spin>
          <i nz-col nzSpan="20" nz-icon nzType="plus" nzTheme="outline" class="icon-padding" *ngIf="!(image.thumb || image.remote_image_url || image.original)"></i>
        </div>
      </div>

      <div nz-row nzJustify="space-between" class="move-icons-holder">
        <div nz-col nzSpan="12" class="left">
          <i class="replace-icon" (click)="moveLeft(i)"  nz-icon nzType="left" nzTheme="outline"></i>
        </div>
        <div nz-col nzSpan="12" class="right">
          <i class="replace-icon right-icon" (click)="moveRight(i)"  nz-icon nzType="right" nzTheme="outline"></i>
        </div>
      </div>
    </div>
  </div>
</div>

