import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  animations: [
    trigger('flyInOut', [
      // state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s')
      ]),
      transition(':leave',
        animate('0.5s', style({ opacity: 0 })))
    ])
  ]
})
/**
 * Blank view.
 */
export class ConfirmationComponent implements OnInit {
  code: string;
  errorMessage: string;
  emailValid = true;
  emailExists = false;
  email: string;
  loading = false;
  showSignupLink = false;
  hide = false;
  resendWait = 0;
  resend = false;


  /**
   * Component constructor and DI injection point.
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private onboardingService: OnboardingService
  ) {
  }

  /**
 * Called part of the component lifecycle. Best first
 * place to start adding your code.
 */
  ngOnInit() {
    const d = { ...this.route.snapshot.data, ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
    this.emailExists = false;
    this.code = d.code;
    this.email = d.email;
    this.resend = d.resend;
    if (this.code) {
      this.confirm(this.code);
    }
  }

  resendConfirmation() {
    this.loading = true;
    this.errorMessage = null;
    if (this.email) {
      this.authService.resendConfirmation(this.email).subscribe(valid => {
        this.loading = false;
        this.resendWait = 30;
        const timer = setInterval(() => {
          this.resendWait -= 1;
          if (this.resendWait === 0) {
            clearInterval(timer);
          }
        }, 1000);
      },
        (err: HttpErrorResponse) => {
          this.loading = false;
          this.errorMessage = err.error.message;
        });
    } else {
      this.router.navigateByUrl('/login');
      this.errorMessage = 'Could not resend password confirmation. Email did not exist. Please try again.';
    }
  }

  confirm(token: string) {
    this.loading = true;
    this.authService.confirmCustomer(token).subscribe(customer => {
      this.router.navigateByUrl('/registration');
    },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.errorMessage = err.message;
      });
  }

}
