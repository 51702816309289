<ng-template #fbonboarding>
  <div>
    <div class="content-media">
      <div mat-card-image class="text-center">
        <img src="assets/images/media.png" class="internal-content" />
      </div>

    </div>
    <h1 class="center-text">Welcome!
    </h1>
    <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
      <button nz-button nzType="primary" class="action-btn-primary">Connect my Tiktok</button>
    </div>
    <h3 class="center-text">Before you can leverage the full power of Zentap, you'll need to connect your Tiktok!
    </h3>
  </div>

  <div class="steps-content congrats-container" *ngIf="isDone">
    <div class="congrats">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      <h2>Success</h2>
      <p>Your Tiktok has been connected successfully.</p>
    </div>
    <nz-divider></nz-divider>
    <div class="ok-btn">
      <button nz-button nzType="primary" (click)="close()">Ok</button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="fbonboarding"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="fbonboarding"></ng-container>
</ng-template>
