import { Component, OnInit, Output, Input, EventEmitter,
  ViewChild, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { JwtService } from '../../../services/jwt/jwt.service';
import { EmailSenderIdentityService } from '../../../services/email-sender-identity/email-sender-identity.service';
@Component({
  selector: 'app-email-template-detail-preview',
  templateUrl: './email-template-detail-preview.component.html',
  styleUrls: ['./email-template-detail-preview.component.scss']
})
export class EmailTemplateDetailPreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe') iframe: ElementRef;

  @Input()
  htmlOutput: any;
  @Input()
  sendingList;

  @Input()
  campaign;

  @Output()
  onStep = new EventEmitter();

  loading = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailSenderIdentityService: EmailSenderIdentityService,
    private jwtService: JwtService,
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.setIframeReady(this.iframe);
  }

  changeStep() {
    this.onStep.emit(3);
  }

  private setIframeReady(iframe: ElementRef): void {
    let win: Window;
    setTimeout(() => {
      win = iframe?.nativeElement?.contentWindow;
      if(win) {
        const doc: Document = win?.document;
        doc.open();
        doc.write(this.htmlOutput);
        doc.close();
      }
    }, 500);
  }

}
