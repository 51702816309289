import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { DataResponse } from 'src/app/models/data-response/data-response';
import { Customer, Integration } from 'src/app/vos/customer/customer';
import { CustomerResourceService } from '../customer-resource.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConnectLinkedinService } from '../connect-linkedin/connect-linkedin.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.api_url}`;
}

@Injectable()

export class CustomersFacebookService extends CustomerResourceService<Customer> {
  public endpoint = 'customers';
  public data_key = 'customer';
  integration;
  public faecbookUri = environment.facebook_app_configs.facebook_url;
  public clientId = environment.facebook_app_configs.client_id;
  public redirectUri = environment.facebook_app_configs.redirect_uri;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Customer, http, authService);
  }

  authorizeFacebook(customerId?, isReAuth?) {
    localStorage.removeItem('linkedInCode');
    const state = customerId ? `&state=${customerId}` : '';
    let url = `${this.faecbookUri}&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&scope=business_management,ads_management,email,leads_retrieval,pages_manage_ads,pages_manage_posts,pages_read_engagement,pages_read_user_content,pages_show_list,instagram_basic,instagram_content_publish,pages_manage_metadata`;
    if (isReAuth) {
      url = url + '&auth_type=rerequest'
    }

    window.open(url,
      'popUpWindow',
      'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  setFacebookAuthorizationCode(code) {
    if (BroadcastChannel) {
      const bc = new BroadcastChannel('facebookConnection');
      bc.postMessage({ code }); /* send */
      localStorage.setItem('facebookCode', JSON.stringify(code));
    }
  }

  getFacebookAuthorizationCode() {
    return JSON.parse(localStorage.getItem('faacebookCode'));
  }


  createFacebookUserConnection(code: string): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/facebook/user.json`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'facebook_user'
            },
            code
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
  updateFacebookUserConnection(code: string, id: any): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/facebook/user.json`;
    return this.http
      .put<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'facebook_user'
            },
            code,
            id
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }

  updateFacebookPageConnection(integration: any, id: any): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/facebook/page.json`;
    // @ts-ignore
    return this.http
      .put<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration,
            id
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }

  getFacebookUserConnection(): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/facebook/user`;
    return this.http
      .get<DataResponse<Customer>>(
        url,
        { headers: this.headers, observe: 'response' }
      )
  }

  getFacebookPages(params?): Observable<any> {
    let url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/facebook/user/pages`;
    if (params) {
      url = url + params;
    }
    return this.http
      .get<DataResponse<Customer>>(
        url,
        { headers: this.headers, observe: 'response' }
      )
  }

  getCustomerConectedFacebookPage(): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/facebook/page`;
    return this.http
      .get<DataResponse<Customer>>(
        url,
        { headers: this.headers, observe: 'response' }
      )
  }

  createCustomerConectedFacebookPage(pageId: string, currentPage: any): Observable<any> {
    // return of({ facebook_page: { facebook_page: "123434" } });
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/facebook/page`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'facebook_page',
              user_id_on_provider: pageId,
              cursor: currentPage
            }
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }

}
