import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {GlobalsService} from '../../services/globals/globals.service';

@Component({
  selector: 'app-support-popup',
  templateUrl: './support-popup.component.html',
  styleUrls: [ './support-popup.component.scss' ]
})

export class SupportPopupComponent implements OnInit {
  @Input()
  setVisible: boolean;
  @Output() hideModal = new EventEmitter();
  supportEmail: string;
  supportPhoneNumber: string;
  constructor(
    private globalsService: GlobalsService
  ) {}
  ngOnInit() {
    this.supportEmail = this.globalsService.getEmail();
    this.supportPhoneNumber = this.globalsService.getPhoneNumber();
    this.setVisible = false;
  }
  handleCancel(): void {
    this.setVisible = false;
    this.hideModal.emit(false);
  }
}
