import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Content } from 'src/app/vos/content/content';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import {Observable} from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
@Injectable()
export class SociallinksService extends CustomerResourceService<any> {
  public endpoint = 'social_links';
  public data_key = 'social_link';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Content, http, authService);
  }

  get_customer(slug): Observable<any> {
    return this.http.get(`${environment.api_url}/${this.endpoint}/${slug}.json`, { responseType: 'json' });
  }


}
