import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Setting } from 'src/app/vos/setting/setting';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class SettingsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class SettingsService extends CustomerResourceService<Setting> {

  public endpoint = 'subscriptions';
  public data_key = 'subscription';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Setting, http, authService);
  }
  public settingList(): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/settings.json`;
    return this.http
      .get<any>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
  public subscriptionsList(): Observable<any> {
    const url = `${this._customerURI}${this.customerEndpoint}.json`;
    return this.http
      .get<any>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
  public update_subscription(value: any, subscription, params?): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/subscriptions/${value}/${subscription}.json`;
    return this.http
      .post<any>(
        url,
        JSON.stringify(params),
        { headers: this.headers }
      )
      .pipe(map(resp => resp.data || resp));
  }
  public update_digest_subscription(value: any, subscription): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/settings/${value}/${subscription}`;
    return this.http
      .put<any>(
        url,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp.data || resp));
  }
}
