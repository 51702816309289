<div *ngIf="captions">
  <h5 class="section-description">
    View and customize the captions that are posted along with your content.
  </h5>
  <div nz-row>
    <div nz-col nzSpan="6">
      <nz-form-label [nzSm]="24" [nzXs]="24" for="content_type">Content Type</nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24">
        <nz-select [disabled]="showPreview" id="content_type" [(ngModel)]="selectedContentType" name="listing_status"
          (ngModelChange)="changeType($event)">
          <nz-option *ngFor="let cap of captions" [nzValue]="cap.caption_type" [nzLabel]="cap.title"></nz-option>
        </nz-select>
      </nz-form-control>
    </div>
    <div nz-col nzSpan="6">
      <nz-form-label [nzSm]="24" [nzXs]="24" for="fields">Allowed Fields</nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24">
        <nz-select [disabled]="showPreview" id="fields" [(ngModel)]="currentSelectedField" name="listing_status"
          (ngModelChange)="selectFieldype($event)">
          <nz-option *ngFor="let field of currentAllowedFields" [nzValue]="field" [nzLabel]="field"></nz-option>
        </nz-select>
      </nz-form-control>
    </div>
    <div nz-col nzSpan="12">
      <i nz-icon class="show-preview pull-right" [nzType]="showPreview ? 'eye':'eye-invisible' "
        (click)="fetchPreview()"></i>
    </div>
  </div>
  <h5 class="disclaimer-color">
    Captions for scheduled posts will not automatically update by updating the caption template
  </h5>
  <!-- <textarea *ngIf="!showPreview" rows="8" columns="4" nz-input [(ngModel)]="currentCaption.text" #myTextArea (click)="getCaretPos(myTextArea)" (keyup)="getCaretPos(myTextArea)"></textarea> -->
  <textarea *ngIf="showPreview" rows="8" columns="4" nz-input [(ngModel)]="this.preview" [disabled]="true"
    class="preview"></textarea>

  <nz-form-item *ngIf="!showPreview" appearance="fill" rows="8" columns="4" class="caption-item top-spacing">
    <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
      <textarea nz-input [(ngModel)]="currentCaption.text" #myTextArea (click)="getCaretPos(myTextArea)"
        (keyup)="getCaretPos(myTextArea)" nzAutosize maxlength="2200" (ngModelChange)="onInput($event)" (keypress)="handlePreventDefault($event)"></textarea>
    </nz-form-control>
    <span class="hashtag"># {{ hashtags?.length ? hashtags?.length : 0}} / 25</span>
    <span class="remaning">{{currentCaption.text?.length ? currentCaption.text?.length : 0}} / 2200</span>
    <div style="color: red;" *ngIf="currentCaption.text?.length >= 2200">
      Caption must not exceed 2200 characters.
    </div>
    <div style="color: red;" *ngIf="isHashtagLimit">
      Hashtag Limit Exceeded: You've reached the maximum allowed number of hashtags. Limit: 25 hashtags
    </div>
    <button nz-dropdown nz-button nzTrigger="click" class="text-left " nzType="text" nzBlock
      [(nzVisible)]="showEmojiPicker" [nzDropdownMenu]="menu" nzPlacement="bottomRight" (click)="toggleEmojiPicker()">
      😀
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <emoji-mart class="emoji-mart" set="{{set}}" isNative="false" (emojiSelect)="addEmoji($event);" title="Pick your emoji…">
      </emoji-mart>
    </nz-dropdown-menu>
  </nz-form-item>
  <div>
    If you are planning to post to Google, please do not write out phone numbers in your captions. Google will reject the post.
  </div>
  <button nz-col nz-button nzType="primary" [disabled]="isHashtagLimit" style="margin-top: 10px;"
    (click)="saveCaption()" class="create-button">Save</button>

</div>
