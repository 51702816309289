import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { Customer, Integration } from '../../vos/customer/customer';
import { Observable } from 'rxjs';
import { PropmixService } from '../../services/propmix/propmix.service';
import { IntegrationsService } from '../../services/integrations/integrations.service';
import { MlsService } from '../../services/mls/mls.service';

import { Subject } from 'rxjs';
import { PMMLSBoard } from '../../vos/propmix/propmix';
import { map, first } from 'rxjs/operators';
import { GlobalsService } from '../../services/globals/globals.service';
import {NzMessageService} from 'ng-zorro-antd/message';
@Component({
  selector: 'app-mls-connection',
  templateUrl: './mls-connection.component.html',
  styleUrls: ['./mls-connection.component.scss']
})
export class MlsConnectionComponent implements OnInit, AfterViewInit {
  console = console;
  @Input() model: any;
  @Input() mlsRealtor: any;
  mlsConnections = [];
  integrationAttributes = [];
  mlsSearchTerm = new Subject<string>();
  mlsBoardResults$: Observable<PMMLSBoard[]>;
  mlsError = '';
  loading = false;
  filteredOptions: string[] = [];
  submitted = false;
  newInteg;
  constructor(private propmixService: PropmixService,
              private integrationsService: IntegrationsService,
              private globalsService: GlobalsService,
              private mlsService: MlsService,
              private message: NzMessageService,
  ) {
    this.mlsBoardResults$ = this.propmixService.searchMLSTerm(this.mlsSearchTerm);
    this.getIntegrations();
  }

  ngAfterViewInit() {

  }

  ngOnInit() {

  }

  setDisplayName(conn: Integration) {
    this.propmixService.mlsBoardForId(conn.user_id_on_provider.trim())
      .pipe(first())
      .subscribe(b => {
        conn.display_name = b ? b.MLSDescription : null;
      });
  }

  setMLSSearchTerm(term: string, index: number) {
    this.mlsSearchTerm.next(term);
    this.mlsConnections[index].display_name = null;
  }

  addMLSConnection() {
    if (this.mlsRealtor?.brokerage
      && this.mlsRealtor?.brokerage.length > 0
      && this.mlsRealtor?.license_number
      && this.mlsRealtor?.license_number.length > 0) {
      this.mlsError = '';
      this.newInteg = new Integration({
        status: 'pending',
        token: '',
        user_id_on_provider: null,
        display_name: '',
        meta_data: { feed_id: null }
      });
      this.integrationAttributes = this.integrationAttributes || [];
      // this.integrationAttributes.push(newInteg);
      this.mlsConnections.push(this.newInteg);
      this.mlsConnections = this.mlsConnections.map(mlsCon => {
        let meta_data = mlsCon?.meta_data;
        if (
          typeof meta_data === 'object' &&
          !Array.isArray(meta_data) &&
          meta_data !== null
        ) {
          meta_data = mlsCon?.meta_data;
        } else {
          meta_data = { feed_id: null }
        }
        return { ...mlsCon, meta_data }
      });
    } else {
      this.mlsError = 'Please add your brokerage and license number to be able to connect your MLS.';
    }

  }

  getIntegrations() {
    this.integrationsService.list().subscribe(res => {
      if (res) {
        this.integrationAttributes = res.data;
        // if (this.integrationAttributes && this.integrationAttributes.length > 0
        //   && this.integrationAttributes[0] && this.mlsRealtor) {
        //   this.mlsRealtor.license_number = this.integrationAttributes[0].liscense_number;
        //   this.mlsRealtor.brokerage = this.integrationAttributes[0].brokerage;
        // }
        this.integrationAttributes.map(async intg => {
          if (intg['user_id_on_provider'] && !isNaN(intg['user_id_on_provider'])) {
            return this.propmixService.mlsBoardForFeedId(intg.user_id_on_provider)
              .subscribe(brd => {
                intg.user_id_on_provider = brd.MLSId;
                return intg;
              });
          } else {
            return intg;
          }
        });
        this.mlsConnections = this.integrationAttributes;
        this.mlsConnections = this.mlsConnections.map(mlsCon => {
          let meta_data = mlsCon?.meta_data;
          if (
            typeof meta_data === 'object' &&
            !Array.isArray(meta_data) &&
            meta_data !== null
          ) {
            meta_data = mlsCon?.meta_data;
          } else {
            meta_data = { feed_id: null }
          }
          return { ...mlsCon, meta_data }
        });
      }
    });
  }

  deleteIntegration(integration) {
    this.loading = true;
    const payload = {
      id: integration.id,
    };
    this.integrationsService.destroy(payload).subscribe(res => {
      this.getIntegrations();
      this.loading = false;
    });
  }

  updateIntegration(integration) {
    this.loading = true;
    const payload = {
      id: integration.id,
      meta_data: integration.meta_data,
      user_id_on_provider: integration.user_id_on_provider,
      token: integration.token,
      liscense_number: this.mlsRealtor?.license_number,
      brokerage: this.mlsRealtor?.brokerage
    };
    this.mlsService.retryMLS(payload).subscribe(res => {
      this.getIntegrations();
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  createIntegration(integration) {
    this.loading = true;
    const paylaod = {
      provider: 'mls',
      meta_data: integration.meta_data,
      user_id_on_provider: integration.user_id_on_provider,
      token: integration.token,
      // liscense_number for mls only
      liscense_number: this.mlsRealtor.license_number,
      brokerage: this.mlsRealtor.brokerage
    };
    this.mlsService.create(paylaod).subscribe(res => {
      integration.id = res.id;
      // this.integrationAttributes.push(integration);
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  onChangeMlsConnection(integrationIndex, $event) {
    const integration = this.mlsConnections[integrationIndex];
    this.propmixService.mlsBoardForFeedId($event)
      .subscribe(brd => {
        integration.user_id_on_provider = brd.MLSId;
      });
  }
  toggleShareWithTeam(integration, event: any){
    const share = event ? true : false;
    this.loading = true;
    const payload = {
        id: integration.id,
        share_with_team: share
      };
    this.mlsService.update(payload).subscribe(res => {
        this.getIntegrations();
        this.message.create('success', 'Success! Mls updated.');
        this.loading = false;
      }, err => {
        this.loading = false;
      });
  }
  submitMls(integration) {
    this.submitted = true;
    const isDataOk = integration.token && integration.user_id_on_provider;
    if (integration.id && isDataOk) {
      this.updateIntegration(integration);
    } else if (isDataOk) {
      this.createIntegration(integration);
    }
    this.submitted = false;
  }

  mlsBoardFeedId(mlsId: string): Observable<any> {
    return this.propmixService
      .mlsBoardForId(mlsId)
      .pipe(map(n => n ? n.FeedId : null));
  }

  mlsBoardDisplayName(mlsId: string): Observable<string> {
    return this.propmixService
      .mlsBoardForId(mlsId)
      .pipe(map(n => n ? n.MLSDescription : 'MLS Details'));
  }

  deleteMLS(conn: Integration) {
    if (!conn.id) {
      this.integrationAttributes.pop();
    } else {
      this.deleteIntegration(conn);
      conn._destroy = 1;
    }

  }
  get myMlsConnections() {
    return this.integrationAttributes ? this.integrationAttributes?.filter(i => i && i.provider.toLowerCase() === 'mls' && i._destroy !== 1)
      : [];
  }
}
