import { Component, OnInit } from '@angular/core';
import { RssFeedService } from '../../services/rss-feed/rss-feed.service';
import { RssFeedDialogComponent } from "../rss-feed-dialog/rss-feed-dialog.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';

@Component({
  selector: 'app-celeb-luxury',
  templateUrl: './celeb-luxury.component.html',
  styleUrls: ['./celeb-luxury.component.scss']
})
export class CelebLuxuryComponent implements OnInit {
  feeds: any[] = [];
  loading: boolean= false;
  calendlyUrl: string;
  constructor(
    private rssFeedService: RssFeedService,
    private modalService: NzModalService,
    private breadcrumService: BreadCrumService,
    ) { }

  ngOnInit(): void {
    this.fetchFeeds();
    this.breadcrumService.set_breadcrum();
  }

  fetchFeeds(): void {
    this.loading = true;
    this.rssFeedService.fetch_rss_feeds('RssFeed').subscribe(data => {
      this.feeds = data['feeds']; 
      this.calendlyUrl = data['calendly_url'] || "https://calendly.com/client-success-call/success-call";
      console.log(this.calendlyUrl);
      this.loading = false;
    });
  }

  openUrlInNewTab(url: string) {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  }

  createCustomContent(feed: any) {
    const modal = this.modalService.create<RssFeedDialogComponent, IModalData>({
      nzTitle: "RSS Feed Content",
      nzContent: RssFeedDialogComponent,
      nzData: {
        feed: feed
      },
      nzWidth: "50%",
      nzFooter: null,
    });
  }
}
