import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { Customer } from 'src/app/vos/customer/customer';
import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
@Component({
  selector: 'app-insights',
  templateUrl: './facebook-steps.component.html',
  styleUrls: ['./facebook-steps.component.scss']
})
export class FacebookStepsComponent implements OnInit {
  current = 0;
  steps = [];
  customer: Customer;
  isFbClicked = false;
  checkAccesSteps = [
    `Log into Facebook and go to your Business Page, which you can find by clicking
     on the ﹀ drop-down menu at the top-right corner of your Profile`,
    `Once you are on your Business Page, click on “Settings” at the top-right corner.`,
    `On the left-hand side of the page, click on “Page Roles”.`,
    `In the Page Roles section, scroll down until you see “Pending Partner Requests”.`,
    `You will see a pending request from Universal Promote, click on “Accept” and follow the prompts.`,
    `Lastly, enter your Facebook password to finalize the request and
     voilà! Now we can start working and posting everything we are creating for you on your Facebook page.`
  ];
  constructor(
    private onboardingService: OnboardingService,
    private authService: AuthenticationService,
    private customerService: CustomersService,
    private modal: NzModalRef,
    private message: NzMessageService,
    private router: Router,
    private modalService: NzModalService,
  ) {
  }

  ngOnInit() {
    // Step 1: Fetching Onboarding Steps
    this.fetchOnboardingSteps();
    // Step 2: Fetching Customer Details
    this.fetchCustomer();
    // Step 3: Initialize Facebook Service
  }
  
  fetchOnboardingSteps() {
    this.steps = this.onboardingService.onboardingSteps;
  }
  fetchCustomer() {
    this.authService.currentCustomer.subscribe((customer) => {
      if (customer && customer.settings['show_price'] === undefined) {
        customer.settings['show_price'] = true;
      }
      this.customer = customer;
      if (this.customer.fb_page_id) {
        this.current = 1;
      }
    });
  }
  selectPage() {
    // if (!this.isFbClicked) {
    //   this.fetchCustomer();
    //   this.isFbClicked = true;
    //   this.fbService?.getLoginStatus()
    //     .then((status) => {
    //       if (status.authResponse && status.status === 'connected') {
    //         this.fbService?.api(`/v3.2/me/accounts?fields=name,id,picture`)
    //           .then(response => {
    //             if (response) {
    //               this.isFbClicked = false;
    //               const pages = response.data?.map(p => ({ label: p.name, value: p.id, image: p.picture.data.url }));
    //               if (pages.length) {
    //                 const modal = this.modalService.create({
    //                   nzContent: GenericDialogComponent,
    //                   nzData: {
    //                     config: {
    //                       title: 'Select which page Universal Promote should manage.',
    //                       singleSelect: pages,
    //                       buttonLabel: 'skip'
    //                     }
    //                   },
    //                   nzFooter: null
    //                 });
    //                 modal.afterClose.subscribe(pageId => {
    //                   if (pageId) {
    //                     // this.customer.fb_page_id = pageId;
    //                     this.customerService.requestPageAccess(pageId).subscribe(c => {
    //                       // this.customer = c;
    //                       // CHECK REQUEST ACCESS
    //                       this.current = 1;
    //                     }, err => {
    //                       console.log(err);
    //                     });
    //                   }
    //                 });
    //               }
    //             }
    //           });
    //       }
    //     });

    // }

  }
  setCurrent(i) {
    if (i === 0 && this.customer.fb_page_id) {
      return;
    } else {
      this.current = i;
    }
  }
  checkFBAccess() {
    this.customerService.checkFBAccess().subscribe(c => {
      this.onboardingService.loadOnboardingSteps(this.customer?.id).subscribe(steps => {
        this.onboardingService.onboardingSteps = steps;
      });
      this.modal.destroy();
      this.message?.create('success', 'Facebook Page is connected');
    }, err => {
      this.message?.remove();
      this.message?.create('error', 'Facebook Page not yet connected yet');
    });
  }
}
