import { Component, OnInit, Input, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { FormFieldConfig } from 'src/app/models/interfaces/modals/form-field-config';

// export interface FormFieldConfig {
//   type: string;
//   key: string;
//   value: any;
//   label;
//   placeholder: string;
//   options?: { value: any, key: string }[];
//   required: boolean;
// }
// export interface GenericDialogConfig {
//   title?: string;
//   message?: string;
//   links?: {
//     label: string,
//     href: string,
//     icon?: string,
//     color?: string
//   }[];
//   buttonLabel?: string;
//   content?: {
//     type: string,
//     url: string,
//     autoplay?: boolean
//   };
//   hideButtons?: boolean;
//   extraButtons?: [
//     {
//       label: string,
//       value: any,
//       color?: string
//     }
//   ];
//   formFields?: FormFieldConfig[];
//   singleSelect?: { value: any, label: string, image: string }[];
// }

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {
  @Input()
  config: any;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: GenericDialogConfig = inject(NZ_MODAL_DATA);
  get buttonText(): string {
    return this.config.buttonLabel ? this.config.buttonLabel : 'Ok';
  }
  form: UntypedFormGroup;
  constructor(
    private modal: NzModalRef
  ) { }

  ngOnInit() {
    if(this.nzModalData.config){
      this.config = this.nzModalData.config;
    }else{
      this.config = this.nzModalData;
    }
    if (this.config.formFields) {
      this.form = this.toFormGroup(this.config.formFields);
    }
  }

  toFormGroup(fields: FormFieldConfig[]) {
    const group: any = {};

    fields.forEach(question => {
      group[question.key] = question.required ? new UntypedFormControl(question.value || '', Validators.required)
        : new UntypedFormControl(question.value || '');
    });
    return new UntypedFormGroup(group);
  }

  selectOption(val?) {
    // this.dialogRef.close(val);
    this.modal.destroy(val);
  }
}
