import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import Swiper, { Autoplay  } from 'swiper';

import { ConnectLinkedinService } from '../../services/connect-linkedin/connect-linkedin.service';
import { CustomersService } from '../../services/customers/customers.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { Customer } from '../../vos/customer/customer';
import { RssAlreadyPostedComponent } from 'src/app/shared/rss-already-posted/rss-already-posted.component';
import moment from 'moment-timezone';

Swiper.use([Autoplay]);
import AWSS3UploadAshClient from 'aws-s3-upload-ash';
import { environment } from '../../../environments/environment';
import { Content } from 'src/app/vos/content/content';
import {CustomContentService} from '../../services/custom-contents/custom-content.service';
import { RssFeedService } from 'src/app/services/rss-feed/rss-feed.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
@Component({
  selector: 'app-rss-feed-dialog',
  templateUrl: './rss-feed-dialog.component.html',
  styleUrls: ['./rss-feed-dialog.component.scss'],
})
export class RssFeedDialogComponent implements OnInit{

  @ViewChild('caption', { static: false }) captionInput: ElementRef;

  @Input() feed: any;
  socialConfig;
  submitted = false;
  showActions = true;
  config: any;
  isEdit = true;
  isRescheduled = false;
  content = new Content();
  schedule_time: Date;
  schedule_date: Date;
  schedule_for: any;
  customer: Customer;
  all = false;
  facebook = false;
  linkedin = false;
  isFacebookConnected = false;
  customClientS3;

  integration;
  linkedinStatus = false;
  linkedinAutopost = false;
  linkedinConnected = false;

  loading = false;
  status = false;
  showCaption = true;
  showEmojiPicker = false;
  set = 'twitter';
  PaidTier = false;
  isScheduledPost = false;
  isDateLoading = false;
  facebookSuccess = false;
  linkedinSuccess = false;
  aiLoading = false;
  isHashtagLimit = false;
  isAlreadyPosted = false;
  hashtags: string[];
  model: any = { caption: '' }
  contentUpdateSubscription: Subscription;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  disabledHours(): number[] {
    return this.schedule_date && new Date(this.schedule_date).getDate() - new Date().getDate() < 1
      ? this.range(1, new Date().getHours() + 12) : [];
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and after 90 days
    const dateDiff = current && (current.getDate() - new Date().getDate() < 1) && (new Date().getHours() > 12) ? -1 : 0
    const daysDiff = differenceInCalendarDays(new Date(), current);
    return daysDiff > dateDiff || differenceInCalendarDays(current, new Date()) > 60;
  };

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  constructor(
    private customContentService: CustomContentService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private router: Router,
    private message: NzMessageService,
    private authService: AuthenticationService,
    private linkedInService: ConnectLinkedinService,
    private customersService: CustomersService,
    private notification: NzNotificationService,
    private customersFacebookService: CustomersFacebookService,
    private rssFeedService: RssFeedService,
  ) { }

  ngOnInit() {
    this.feed = this.nzModalData.feed;
    const config = {
      bucketName: environment.aws.BUCKET,
      dirName: 'customContent',
      region: environment.aws.REGION,
      accessKeyId: environment.aws.AWS_ACCESS_KEY,
      secretAccessKey: environment.aws.AWS_SECRET_KEY,
      s3Url: `https://${environment.aws.BUCKET}.s3.${environment.aws.REGION}.amazonaws.com/`
    };
    this.customClientS3 = new AWSS3UploadAshClient(config);
    this.checkCustomerConectedFacebookPage();

    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
        this.PaidTier = c?.tier === 'ultra';
        this.facebook = this.customer?.settings?.auto_posting;
        this.fetch_linkedin_autopost_settings();
        this.setSocialContentConfig();
      }

    });
    this.checkLinkedin();
    this.loadAiCaption();
  }

  setSocialContentConfig() {
    const content = this.content;
    const hideGoogle = content?.media_type === 'video';
    const socialFeed = true
    this.socialConfig = { content: content, facebook: false, linkedin: false, youtube: false, google: false, hideGoogle, socialFeed };
    this.socialConfig.facebook = this.customer?.settings?.auto_posting;
  }


  checkCustomerConectedFacebookPage() {
    this.customersFacebookService.getCustomerConectedFacebookPage()
      .subscribe(res => {
        if (res) {
          const { user_id_on_provider = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
          if (!user_id_on_provider && !this.customer.fb_page_id) {
            this.isFacebookConnected = false;
          } else {
            this.isFacebookConnected = true;
          }
        }
      });
  }

  fetch_linkedin_autopost_settings() {
    this.customersService.getLinkedin().subscribe((c) => {
      if (c && c.length > 0) {
        this.linkedinStatus = true;
        this.linkedInService.getAutopost().subscribe(int => {
          this.integration = int.data[0];
          this.linkedinAutopost = this.integration.auto_posting;
          this.linkedin = this.linkedinAutopost && this.PaidTier;
          this.facebook = this.customer?.settings?.auto_posting;
          this.all = this.linkedin && this.facebook
        });
      }
    });
  }

  checkLinkedin() {
    localStorage.removeItem('linkedInCode');
    this.customersService.getLinkedin().subscribe((c) => {
      this.linkedinConnected = (c.length > 0);
    });
  }

  removeDialog() {
    this.modal.close();
  }

  addEmoji(event) {
    const { caption = '' } = this.content;
    const text = `${caption}${event.emoji.native}`;
    this.content.caption = text;
    this.captionInput.nativeElement.focus();
    this.captionInput.nativeElement.click();
    this.showEmojiPicker = false;
  }

  showEdit() {
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = true;
    this.isScheduledPost = false;
  }

  changeDate(current) {
    if (current) {
      this.content.scheduled_for = new Date(current);
    } else if (!this.schedule_time) {
      this.schedule_time = null;
    }
    if (this.schedule_time && current) {
      const scheduleDate = new Date(current);
      this.schedule_time = new Date(this.schedule_time);
      this.schedule_time.setDate(scheduleDate.getDate());
      this.schedule_time.setMonth(scheduleDate.getMonth());
      this.schedule_time.setFullYear(scheduleDate.getFullYear());
      const tempDate = moment(this.schedule_time);
      this.schedule_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
    }
  }

  changeTime(current) {
    this.schedule_date = new Date(this.schedule_date);
    if (this.schedule_date.getDate() - new Date().getDate() < 1 &&
      this.schedule_date.getMonth() <= new Date().getMonth() &&
      this.schedule_date.getFullYear() <= new Date().getFullYear() &&
      current &&
      current.getHours() - new Date().getHours() < 6) {
      // this.schedule_time = new Date(current);
      this.schedule_time = null;
      this.message.create('error', 'You must schedule posts to be at least 6 hours from content creation');
      // this.message.create('error', 'You cannot select time before 12 hours');
    } else {
      this.schedule_time = new Date(current);
    }
    if (this.schedule_time && this.schedule_date) {
      this.schedule_time.setDate(this.schedule_date.getDate());
      this.schedule_time.setMonth(this.schedule_date.getMonth());
      this.schedule_time.setFullYear(this.schedule_date.getFullYear());
      const tempDate = moment(this.schedule_time).tz('America/California');
      this.schedule_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
    }
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  showFacebnook() {
    const modal = this.modalService.create<FacebookOnboardingDialogComponent, IModalData>({
      nzContent: FacebookOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
    });
  }

  post() {
    const platforms = [];
    if (this.socialConfig.facebook) {
      platforms.push('facebook');
    }
    if (this.socialConfig.linkedin) {
      platforms.push('linkedin');
    }

    if (platforms.length === 0) {
      this.message?.remove();
      this.message?.create('error', 'Please select platform!');
      return;
    }

    this.createCustomContent(platforms)
  }

  createCustomContent(platforms?, skip_post?) {
    this.loading = true;
    this.submitted = true;
    const request = this.customContentService.create({
      caption: this.model.caption,
      url: this.feed.image_url,
      scheduled_for: this.schedule_for,
      category: 'RSS',
      platforms,
      skip_post,
      is_already_posted: this.isAlreadyPosted,
    });
    request.subscribe(c => {
      if(c.is_posted){
        this.isAlreadyPosted = true;
        this.alreadyPosted();
        this.loading = false;
        return
      }
      this.loading = false;
      this.facebookSuccess = true;
      this.linkedinSuccess = true;
      this.notification.create(
        'success',
        'Created',
        'Content has successfully been posted'
      );
    }, err => {
      this.loading = false;
    });
  }

  alreadyPosted() {
    const modal = this.modalService.create({
      nzContent: RssAlreadyPostedComponent,
      nzFooter: null,
      nzWidth: '25%',
    });
    modal.afterClose.subscribe(response => {
      if(response){
        this.post();
      }else{
        this.removeDialog();
      }
    });
  }

  loadAiCaption(){
    this.aiLoading = true;
    this.rssFeedService.generate_ai_caption(this.feed.url).subscribe(res => {
      this.model.caption = res["caption"];
      this.hashtags = this.model.caption.match(/#\S+/g) || [];
      this.aiLoading = false;
    });
  }

  onInput(value: string): void {
    this.hashtags = value.match(/#\S+/g) || [];
    if (this.hashtags.length > 30) {
      this.isHashtagLimit = true;
    } else {
      this.isHashtagLimit = false;
    }
  }

  handlePreventDefault(evt){
    evt = (evt) ? evt : window.event;
    if(this.hashtags.length >= 30 && evt.key == '#'){
      evt.preventDefault();
    }
  }
}
