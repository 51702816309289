import {
  Component, OnInit, Input, ChangeDetectionStrategy, ElementRef,
  ViewChild, Output, EventEmitter, ChangeDetectorRef, OnChanges
} from '@angular/core';

import { SwiperComponent } from 'ngx-useful-swiper';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInHours from 'date-fns/differenceInHours';

import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import setHours from 'date-fns/setHours';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Content } from 'src/app/vos/content/content';
import { ContentsService } from 'src/app/services/contents/contents.service';
import { NgForm, ValidationErrors, AbstractControl, UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ProductStyle } from 'src/app/vos/product/product';
import { GlobalsService } from 'src/app/services/globals/globals.service';
import { CustomersService } from '../../services/customers/customers.service';
import { SwiperOptions } from 'swiper';
import moment from 'moment-timezone';
import { CaptionsService } from '../../services/captions/captions.service';
import {CustomersTiktokService} from '../../services/customers-tiktok/customers-tiktok.service';

@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrls: ['./content-form.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFormComponent implements OnInit, OnChanges {
  @ViewChild('caption', { static: false }) captionInput: ElementRef;

  @ViewChild('usefulSwiper', { static: false }) usefulSwiper: SwiperComponent;
  @ViewChild('usefulCoverPhotoSwiper', { static: false }) usefulCoverPhotoSwiper: SwiperComponent;
  @ViewChild('contentForm') form: NgForm;
  @Output()
  validChange = new EventEmitter<boolean>();
  @Input()
  stockImages = [];
  @Input()
  isTestimonial: boolean = false;
  @Input()
  isCoverPhotos: boolean = false;
  @Input()
  isFlyer: boolean = false;
  @Input()
  valid = false;
  @Input()
  showSchedule = false;
  @Input()
  socialConfig;
  @Output()
  errors = new EventEmitter<ValidationErrors>();
  @Output()
  nextPage = new EventEmitter<any>();
  @Output()
  nextCoverPhotoPage = new EventEmitter<any>();
  @Output()
  changeStyle = new EventEmitter<any>();
  @Output()
  hashTagLimit = new EventEmitter<any>();
  @Input()
  requiredFields = ['address', 'status', 'photos'];
  @Input()
  onlyRequired = false;
  @Input()
  model: Content = new Content();
  @Input()
  showCaption = true;
  @Input()
  showColors = true;
  @Input()
  submitted = false;
  @Input()
  styles: ProductStyle[] = [];
  true = true;
  false = false;
  stylesExpanded = false;
  contentStyles: Content[];
  contentCoverStyles: Content[];
  styleRatio = 'regular'
  schedule_time: Date;
  schedule_date: Date;
  colors: string[];
  imageFilters = [];
  imageFiltersAvalible = [];
  filter: string;
  page = 1;
  per_page = 15;
  loading = false;
  aiCaption = false;
  aiLoading = false;
  hashtags: string[];
  isHashtagLimit =  false;

  // youtube & tiktok required fields

  privacyStatus = 'public';
  videoTags;

  // tiktok settings
  tiktokIntegration: any;
  tiktokConnected: boolean;
  tiktokAutopost: any;
  stitch = false;
  duet = false;
  comment = false;
  tiktokTitle;
  contentDisclourse = false;
  yourBrand = false;
  brandedContent = false;
  tiktokPrivacyStatus;

  // count the clicks
  private clickTimeout = null;
  // socialconfig;
  config: SwiperOptions = {
    // pagination: { el: '.swiper-pagination', clickable: true },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true
    },
    allowTouchMove: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    on: {
      slideChange: (e) => {
        // this.nextPage.emit();
      }
    },
    // spaceBetween: 10,
    // slidesPerView: 3,
    // slidesPerGroup: 3,
    // slidesPerView: Math.floor(window.innerWidth / 300),
    // slidesPerGroup: Math.floor(window.innerWidth / 300),
    // loop: true,
    // Responsive breakpoints
    breakpoints: {
      2500: {
        slidesPerView: 4,
        spaceBetween: 5,
        slidesPerGroup: 4
      },
      2200: {
        slidesPerView: 3,
        spaceBetween: 5,
        slidesPerGroup: 4
      },
      1920: {
        slidesPerView: 3,
        spaceBetween: 4,
        slidesPerGroup: 4
      },
      1850: {
        slidesPerView: 3,
        spaceBetween: 5,
        slidesPerGroup: 3
      },

      1700: {
        slidesPerView: 2,
        spaceBetween: 6,
        slidesPerGroup: 3
      },
      1250: {
        slidesPerView: 2,
        spaceBetween: 10,
        slidesPerGroup: 2
      },
      // 1024: {
      //   slidesPerView: 2,
      //   spaceBetween: 10,
      //   slidesPerGroup: 2
      // },
      940: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 2
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
        slidesPerGroup: 1
      }
    }
    // loopFillGroupWithBlank: true,
  };
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];
  set = 'twitter';
  uid = this.globalsService.randomString();
  images_attributes = [{
    id: 91000,
    large: '',
    order: 0,
    original: '',
    render: '',
    thumb: '',
    uid: `cover_photo_${this.uid}`
  }];
  coverPhotoConfig = {
    images: this.images_attributes,
    configMeta: {
      imageableType: 'Customer',
      type: 'cover_photo',
      aspectRatio: 2.83,
      minWidth: 275,
      minHeight: 105,
      idealWidth: 1280,
      idealHeight: 720,
      uid: `cover_photo_${this.uid}`,
      isCoverPhotos: true
    },
    channel: 'CreateImageChannel',
    target: {
      uid: `cover_photo_${this.uid}`
    }
  };

  disabledHours(): number[] {
    return this.schedule_date && new Date(this.schedule_date).getDate() - new Date().getDate() <= 1
      ? this.range(1, new Date(this.schedule_date).getHours() + 12) : [];
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and after 90 days
    const dateDiff = current && (current.getDate() - new Date().getDate() < 1) && (new Date().getHours() > 12) ? -1 : 0
    const daysDiff = differenceInCalendarDays(new Date(), current);
    return daysDiff > dateDiff || differenceInCalendarDays(current, new Date()) > 60;
  };

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  constructor(
    private contentService: ContentsService,
    private cd: ChangeDetectorRef,
    private globalsService: GlobalsService,
    private message: NzMessageService,
    private customersService: CustomersService,
    private captionsService: CaptionsService,
    private customersTiktokService: CustomersTiktokService,
  ) { }

  changeDate(current) {
    if (current && current.getDate() - new Date().getDate() < 1) {
      // this.schedule_time = new Date(current);
      this.model.scheduled_for = this.schedule_time;
    } else if (!this.schedule_time) {
      this.schedule_time = null;
    }
    if (this.schedule_time && this.schedule_date) {
      this.schedule_time = new Date(this.schedule_time);
      this.schedule_time.setDate(this.schedule_date.getDate());
      this.schedule_time.setMonth(this.schedule_date.getMonth());
      this.schedule_time.setFullYear(this.schedule_date.getFullYear());
      let tempDate = moment(this.schedule_time).tz('America/California');
      this.model.scheduled_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
      // this.model.scheduled_for = this.schedule_time;
    }
    // if (this.schedule_date) {
    //   this.schedule_time = this.schedule_time ? new Date(this.schedule_time) : new Date(current);
    //   this.schedule_time.setDate(this.schedule_date.getDate());
    //   this.schedule_time.setMonth(this.schedule_date.getMonth());
    //   this.schedule_time.setFullYear(this.schedule_date.getFullYear());
    // } else {
    //   this.schedule_time = null;
    // }
    // this.model.scheduled_for = this.schedule_time;

  }

  setCoverImage(image) {
    if (image) {
      this.model.extra_attributes.background_photo = image.original;
      // this.isPhotoValid =  true;
    }
  }

  changeTime(current) {
    if (this.schedule_date.getDate() - new Date().getDate() < 1 &&
      this.schedule_date.getMonth() <= new Date().getMonth() &&
      this.schedule_date.getFullYear() <= new Date().getFullYear() &&
      current &&
      current.getHours() - new Date().getHours() < 6) {
      this.schedule_time = null;
      this.message.create('error', 'You must schedule posts to be at least 6 hours from content creation');
      // this.message.create('error', 'You cannot select time before 12 hours');
    }
    if (this.schedule_time && this.schedule_date) {
      this.schedule_time = new Date(this.schedule_time);
      this.schedule_time.setDate(this.schedule_date.getDate());
      this.schedule_time.setMonth(this.schedule_date.getMonth());
      this.schedule_time.setFullYear(this.schedule_date.getFullYear());
      let tempDate = moment(this.schedule_time).tz('America/California');
      this.model.scheduled_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
      // this.model.scheduled_for = this.schedule_time;
    }
  }

  // ngAfterViewInit() {
  // this.form.valueChanges.subscribe(() => {
  //   this.validChange.emit(this.form.valid);
  //   this.valid = this.form.valid;
  // });
  // }

  ngOnChanges(changes: any) {
    if (changes.styles && JSON.stringify(changes.styles.currentValue) !== JSON.stringify(changes.styles.previousValue)) {
      this.mapProdStyles();
    }
    if (changes.stockImages && JSON.stringify(changes.stockImages.currentValue) !== JSON.stringify(changes.stockImages.previousValue)) {
      this.mapStockBanner();
      this.loading = false;
    }
  }
  ngOnInit() {
    // this.socialconfig = {content: this.model, facebook: false,  linkedin: false};
    this.colors = this.globalsService.getColors();
    if (!this.model.style && this.styles.length === 1) {
      this.model.style = this.styles[0].effective_name;
      this.styles[0].selected = true;
    }
    // this.cd.detectChanges();
    if (this.isCoverPhotos) {
      // this.getTags();
      // this.mapStockBanner()
    } else {
      this.mapProdStyles();
    }
    if (this.model.category) {
      this.captionsService.generate_caption(this.model).subscribe(res => {
        this.model.caption = res["text"];
        this.hashtags = this.model && this.model.caption && this.model.caption.match(/#\S+/g) || [];
      });
    }

    this.fetch_tiktok_autopost_settings();
  }

  loadAiCaption(event: any){
    this.aiLoading = true;
    if (this.aiCaption){
      this.getAiCaption();
    }else {
      this.captionsService.generate_caption(this.model).subscribe(res => {
        this.model.caption = res["text"];
        if (this.model.caption){
          this.hashtags = this.model.caption.match(/#\S+/g) || [];
        }
        this.aiLoading = false;
      });
    }
  }
  getAiCaption(){
    this.aiCaption = true;
    this.aiLoading = true;
    this.captionsService.generate_ai_caption(this.model).subscribe(res => {
      this.model.caption = res["text"];
      this.hashtags = this.model.caption.match(/#\S+/g) || [];
      this.aiLoading = false;
    });
  }


  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }


  addEmoji(event) {
    const { caption = '' } = this.model;
    // const text = `${caption}${event.emoji.native}`;
    this.captionInput.nativeElement.focus();
    const selectionStart = this.captionInput.nativeElement.selectionStart;
    const currentValue = this.captionInput.nativeElement.value;
    const newValue = currentValue.substring(0, selectionStart) + event.emoji.native + currentValue.substring(selectionStart);
    this.model.caption = newValue;
    // the following 2 lines set the caret position behind the emoji
    this.captionInput.nativeElement.selectionStart = selectionStart + event.emoji.native.length;
    this.captionInput.nativeElement.selectionEnd = selectionStart + event.emoji.native.length;
    this.captionInput.nativeElement.click();
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }
  mapProdStyles() {
    this.contentStyles = this.styles.map(s => {
      const imagepath = s.preview || s.image || '';
      const urlParts = imagepath.split('.');
      const extension = urlParts[urlParts.length - 1];
      return new Content({
        url: s.preview || s.image,
        style: s.name,
        styleEffectiveName: s.effective_name,
        caption: s.name,
        media_type: extension === 'mp4' ? 'video' : 'image',
        is_style: true,
        selected: s.selected,
        ratio: s.ratio
      });
    });
    if (this.contentStyles && this.contentStyles.length === 0){
       return true;
     }
    if (this.filteredStyles && this.filteredStyles.length === 0 && this.styleRatio === 'regular') {
      this.styleRatio = 'square';
    } else if (this.filteredStyles && this.filteredStyles.length === 0 && this.styleRatio === 'square') {
      this.styleRatio = 'vertical';
    } else if (this.filteredStyles && this.filteredStyles.length === 0 && this.styleRatio === 'square') {
      this.styleRatio = 'regular';
    }
  }
  mapStockBanner() {
    this.contentCoverStyles = this.stockImages.map(s => {
      const urlParts = s.original.split('.');
      const extension = urlParts[urlParts.length - 1];
      return new Content({
        url: s.original,
        style: '',
        caption: '',
        media_type: extension === 'mp4' ? 'video' : 'image',
        is_style: true,
        selected: false
      });
    });
  }
  gifloaded() {
    this.cd.detectChanges();
  }
  checkControls(controls: AbstractControl[]) {
    controls.forEach(c => {
      if (c instanceof UntypedFormControl) {
        c.markAsTouched();
        c.updateValueAndValidity();
      } else if (c instanceof UntypedFormGroup) {
        this.checkControls(Object.keys(c.controls).map(k => c.controls[k]));
      } else if (c instanceof UntypedFormArray) {
        this.checkControls(c.controls);
      }
    });
  }
  checkForm() {
    this.valid = this.form.valid;
    // this.form.control.updateValueAndValidity();
    this.checkControls(Object.keys(this.form.controls).map(k => this.form.controls[k]));
    // this.form.controls.forEach(c => {})
  }
  // showPreview() {
  //   window.open(this.previewForStyle, 'nil');
  // }

  selectStyle(content: Content) {
    this.model.style = content.styleEffectiveName;
    this.contentStyles.map(template => {
      if (template.style === content.style) {
        template.selected = true;
      } else {
        template.selected = false;
      }
    });
    this.changeStyle.emit(content);
    this.cd.detectChanges();
  }

  selectStockImage(content: Content) {
    this.model.extra_attributes.background_photo = content.url;
    this.contentCoverStyles.map(template => {
      if (template.url === content.url) {
        template.selected = true;
      } else {
        template.selected = false;
      }
    });
  }

  nextSlide(ev) {
    ev.stopPropagation();
    this.usefulSwiper.swiper.slideNext();
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      this.page = this.page + 1;
      this.nextPage.emit();
    }, 1000);
  }

  nextCoverPhotoSlide(ev) {
    ev.stopPropagation();
    this.usefulCoverPhotoSwiper.swiper.slideNext();
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      this.page = this.page + 1;
      this.nextCoverPhotoPage.emit({ name: this.filter, page: this.page });
    }, 1000);
  }

  prevSlide(ev) {
    ev.stopPropagation();
    this.usefulSwiper.swiper.slidePrev();
  }
  prevCoverPhotoSlide(ev) {
    ev.stopPropagation();
    this.usefulCoverPhotoSwiper.swiper.slidePrev();
  }

  getTags() {
    const params = {
      'q[name_eq]': 'stock_photos'
    };
    this.customersService.listFrom('tags', params).subscribe(tags => {
      this.imageFiltersAvalible = tags.data[0]['children'];
      this.cd.detectChanges();
    });
  }

  toggleFilter(checked: boolean, filter: string): void {
    this.loading = true;
    this.page = 1;
    this.filter = '';
    this.imageFilters = [];
    if (checked) {
      this.imageFilters.push(filter);
      this.filter = filter['name'].toLowerCase();
    }
    this.nextCoverPhotoPage.emit({ name: this.filter, page: this.page });

  }
  get filteredStyles() {
    return this.contentStyles.filter(style => style.ratio === this.styleRatio);
  }

  onInput(value: string): void {
    this.hashtags = value.match(/#\S+/g) || [];
    if (this.hashtags && this.hashtags.length > 30) {
      this.isHashtagLimit = true;
      this.hashTagLimit.emit(true);
    } else {
      this.isHashtagLimit = false;
      this.hashTagLimit.emit(false);
    }
  }

  handlePreventDefault(evt){
    evt = (evt) ? evt : window.event;
    if (this.hashtags && this.hashtags.length >= 30 && evt.key === '#'){
      evt.preventDefault();
    }
  }

  fetch_tiktok_autopost_settings() {
    this.loading = true;
    this.customersTiktokService.getTiktokUserConnection()
      .subscribe(res => {
        if (res && res?.length > 0) {
          this.tiktokIntegration = res[0];
          this.tiktokConnected = true;
          this.tiktokAutopost = this.tiktokIntegration.auto_posting;
          if(!this.model.extra_attributes){
            this.model.extra_attributes = {};
          }
          this.model.extra_attributes.tiktokPrivacyStatus = this.tiktokIntegration.tiktok_creator_info.data.privacy_level_options[0];
          this.model.extra_attributes.comment = !this.tiktokIntegration.tiktok_creator_info.data.comment_disabled;
          this.model.extra_attributes.duet = !this.tiktokIntegration.tiktok_creator_info.data.duet_disabled;
          this.model.extra_attributes.stitch = !this.tiktokIntegration.tiktok_creator_info.data.stitch_disabled;
          this.model.extra_attributes.contentDisclourse = true;
          this.model.extra_attributes.yourBrand = true;
          this.model.extra_attributes.brandedContent = true;
          this.model.extra_attributes.tiktokTitle = this.model.title;
        }
      });
    this.loading = false;
  }
  preprocessOption(option: string): string {
    return option.replace(/_/g, ' ');
  }


}
