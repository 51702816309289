import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { BreadCrumService } from 'src/app/services/breadcrum/bread-crum.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RssFeedService } from '../../../services/rss-feed/rss-feed.service';
import { RssFeedDialogComponent } from "../../rss-feed-dialog/rss-feed-dialog.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { IModalData } from 'src/app/models/interfaces/modals/modal';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit {
  customer: Customer;
  news: any[] = [];
  loading: boolean= false;
  calendlyUrl: string;
  @Input() isHomePage: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private breadcrumService: BreadCrumService,
    private rssFeedService: RssFeedService,
    private modalService: NzModalService,
  ) { }

  ngOnInit(): void {
    this.customer = this.authService.currentCustomerValue;
    this.breadcrumService.set_breadcrum();
    this.fetchNews();
  }

  fetchNews(): void {
    this.loading = true;
    this.rssFeedService.fetch_rss_feeds('RssFeed').subscribe(data => {
      this.news = data['feeds'];
      this.calendlyUrl = data['calendly_url'] || "https://calendly.com/client-success-call/success-call";
      this.loading = false;
    });
  }

  openUrlInNewTab(url: string) {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  }

  createCustomContent(news: any) {
    const modal = this.modalService.create<RssFeedDialogComponent, IModalData>({
      nzTitle: "News Feed Content",
      nzContent: RssFeedDialogComponent,
      nzData: {
        feed: news
      },
      nzWidth: "50%",
      nzFooter: null,
    });
  }
}
