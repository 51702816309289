import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardButtonConfig } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthenticationService } from '../../services/authentication/authentication.service';

import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { EmailContactListService } from '../../services/email-contact-list/email-contact-list.service';
import { EmailUserService } from '../../services/email-user/email-user.service';

import { GlobalsService } from '../../services/globals/globals.service';
import { PaginationService } from '../../services/pagination/pagination.service';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { AcceptAUPDialogComponent } from '../../shared/accept-aup-dialog/accept-aup-dialog.component';
import { CreateContactListDialogComponent } from '../../shared/create-contact-list-dialog/create-contact-list-dialog.component';
import { EmailSenderIdentityService } from '../../services/email-sender-identity/email-sender-identity.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-select-audience',
  templateUrl: './select-audience.component.html',
  styleUrls: ['./select-audience.component.scss']
})
export class SelectAudienceComponent implements OnInit {
  loading = true;

  @Output() change = new EventEmitter<any>();
  @Input() model;
  selection = [];
  audienceListings = [];
  numberOfChecked = 0;
  isAllListingsSelected = false;
  isAllDisplayDataChecked = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  isIndeterminate = false;
  selectedList;
  emailUserMe;
  listOfSelection = [
    {
      text: 'Select All',
      onSelect: () => {
        this.isAllListingsSelected = true;
        this.isAllDisplayDataChecked = true;
        this.mapOfCheckedId = {};
        this.refreshStatus();
        // this.allSelectedChange.emit(true);
      }
    },
    {
      text: 'None',
      onSelect: () => {
        this.isAllListingsSelected = false;
        this.isAllDisplayDataChecked = false;
        this.mapOfCheckedId = {};
        this.refreshStatus();
        // this.allSelectedChange.emit(false);
      }
    }
  ];
  search = false;

  searchText: string;

  selectedSort = {
    by: 'address',
    order: 'asc'
  };
  showEmpty = false;
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;

  constructor(
    private message: NzMessageService,
    private route: ActivatedRoute,
    private globalsService: GlobalsService,
    private router: Router,
    private paginationService: PaginationService,
    private modalService: NzModalService,
    private emailContactListService: EmailContactListService,
    private emailUserService: EmailUserService,
    private authService: AuthenticationService,
    private emailSenderIdentityService: EmailSenderIdentityService,

  ) {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;

      });
  }
  pageSizeOptions = [10];
  listingPagination = {
    limit: 10,
    total: 10,
    page: 1,
    totalPages: 10
  };
  ConfigListingTitle;
  newCardButton: CardButtonConfig;
  newMarketBtnConfig: CardButtonConfig;
  ngOnInit() {
    this.loading = false;
    this.fetchLists();
    this.getEmailUser();
  }


  fetchLists() {
    this.emailContactListService.list()
      .subscribe(res => {
        this.audienceListings = res.data;
        this.loading = false;
      })
  }

  didPage(pageIndex) {
    this.listingPagination.page = pageIndex;
    this.loading = true;
  }

  refreshStatus(): void {
    this.showUserAup();
    this.isAllDisplayDataChecked = this.isAllListingsSelected || this.audienceListings.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.audienceListings.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
    let selctedKeys = Object.keys(this.mapOfCheckedId);
    selctedKeys.forEach(k => {
      if (!this.mapOfCheckedId[k]) {
        delete this.mapOfCheckedId[k];
      }
    });
    selctedKeys = Object.keys(this.mapOfCheckedId);
    if (this.isAllListingsSelected) {
      this.numberOfChecked = this.listingPagination.total;
    } else {
      this.numberOfChecked = selctedKeys.length;
    }
    const selection = this.audienceListings ? this.audienceListings.filter(item => this.mapOfCheckedId[item.id]) : [];
    this.selectedList = selection.map(c => c.id);
    this.selection = selctedKeys.map(id => parseInt(id, 10));
    this.change.emit(selection);
  }

  checkAll(value: boolean): void {
    this.audienceListings.forEach(item => {
      if (!value && this.mapOfCheckedId[item.id]) {
        delete this.mapOfCheckedId[item.id];
      } else {
        this.mapOfCheckedId[item.id] = value;
      }
    });
    this.refreshStatus();
  }

  addList() {
    if (this.emailUserMe && this.emailUserMe.accepted_aup) {
      this.openAddList();
    } else {
      this.openAccept();
    }
  }

  showUserAup() {
    if (this.audienceListings.length > 0 && !this.emailUserMe.accepted_aup) {
      this.modalService.create({
        nzContent: AcceptAUPDialogComponent,
        nzFooter: null
      });
    }
  }

  getEmailUser() {
    this.emailUserService.list()
      .subscribe(res => {
        this.emailUserMe = res.data;
      }, err => {
        this.authService.getEmailAuthentication()
          .subscribe(res => {
            this.getEmailUser();
          })
      })
  }

  openAccept() {
    const modal = this.modalService.create({
      nzContent: AcceptAUPDialogComponent,
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      if (response) {
        this.openAddList();
      }
    });
  }

  openAddList() {
    const modal = this.modalService.create({
      nzContent: CreateContactListDialogComponent,
      nzData: {

      },
      nzFooter: null,
      nzWidth: '80%'
    });
    modal.afterClose.subscribe(response => {
      this.getEmailUser();
      this.fetchLists();
    });
  }

  deleteList(list) {
    this.modalService.confirm({
      nzTitle: '<i>Do you Want to delete the list?</i>',
      nzContent: '<b>This action will delete your list permanently.</b>',
      nzOnOk: () => {
        this.emailContactListService.destroy(list.id)
          .subscribe(res => {
            this.message?.create('success', `List has been deleted successfully.`);
          }, e => {
            this.message?.remove();
            this.message?.create('error', 'Error deleting the list. Please try again');
          })
      }
    });
  }

  editList(list) {
    const modal = this.modalService.create<CreateContactListDialogComponent, IModalData>({
      nzContent: CreateContactListDialogComponent,
      nzData: {
        editList: list
      },
      nzFooter: null,
      nzWidth: '80%'
    });
    modal.afterClose.subscribe(response => {
      this.fetchLists();
    });
  }

  goToOnboarding() {
    this.router.navigateByUrl('/email_campaigns/onboarding');
  }
}
