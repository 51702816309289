import { Pipe, PipeTransform } from '@angular/core';
import { Content } from 'src/app/vos/content/content';

@Pipe({
  name: 'contentType'
})
export class ContentTypePipe implements PipeTransform {

  transform(content: Content[], types?: string[]): Content[] {
    return (types && types.length > 0) ? content?.filter(c => types.includes(c.category)) : content;
  }

}
