<!-- <app-sidebar> -->
<!-- <div class="container"> -->
<ng-container>
  <h1 class="section-title title">
    <i nz-icon nzType="like"></i>
    <!-- Branded Infomercials -->
    Social Media Content
  </h1>
  
  <ng-container *ngTemplateOutlet="brandTabs"></ng-container>
  <ng-template #contentdata>
  <span>Categories: </span>
  <nz-tag *ngFor="let category of categories" nzMode="checkable" (nzCheckedChange)="handleChange($event, category.name)" [nzChecked]="selectedCategory === category.name">
    {{ category.name.titleize()}}
  </nz-tag>
  <nz-divider></nz-divider>
  <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
    <app-select-content product="IF" (pagechange)="loadGallery($event)" [resource]="customer" [contents]="content " [pageInfo]="pageInfo"></app-select-content>
  </nz-spin>
  <ng-template #notloading>
    <app-select-content product="IF" (pagechange)="loadGallery($event)" [resource]="customer" [contents]="content " [pageInfo]="pageInfo"></app-select-content>
  </ng-template>
  </ng-template>
  <ng-template #brandTabs>
<!--    <nz-tabset [nzSelectedIndex]="currentTab" (nzSelectedIndexChange)="changeIndex($event);" nzType="card">-->
<!--      <nz-tab *ngFor="let tab of tabs; let i = index" id="{tab+i}" [nzTitle]="tab">-->
<!--        <ng-container *ngTemplateOutlet="contentdata"></ng-container>-->
<!--      </nz-tab>-->
<!--    </nz-tabset>-->
    <ng-container *ngTemplateOutlet="contentdata"></ng-container>
  </ng-template>
</ng-container>


<!-- </div> -->
<!-- </app-sidebar> -->
