import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; // Import HttpParams
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { Contact } from '../../vos/contact/contact';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { DataResponse } from '../../models/data-response/data-response';
import { SmsCampaign } from 'src/app/vos/sms-campaign/sms-campaign';

export class CampaignServiceConfig {
  uri = '';
}

@Injectable()
export class CampaignService extends CustomerResourceService<SmsCampaign> {
  public endpoint = 'sms_campaigns';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(SmsCampaign, http, authService);
  }

  fetch_customer_audiences(): Observable<any> {
    const customer_id = this.authService.currentCustomerValue?.id;
    const url = `${this._customerURI}${customer_id}/audiences.json`;
    return this.http.get<any[]>(url).pipe(
      map(resp => {
        return resp;
      }
    ));
  }

  fetch_sms_campaigns(params: any): Observable<any> {
    const customer_id = this.authService.currentCustomerValue?.id;
    const url = `${this._customerURI}${customer_id}/sms_campaigns.json`;

    return this.http.get<any>(url, { params }).pipe(
      map(resp => {
        return resp;
      }
    ));
  }

  create_sms_campaign(payload: any): Observable<any> {
    const customer_id = this.authService.currentCustomerValue?.id;
    const url = `${this._customerURI}${customer_id}/sms_campaigns.json`;
    return this.http
      .post<DataResponse<any>>(url,
        JSON.stringify({  sms_campaign: payload }),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }

  update(payload: any, id: any): Observable<any> {
    const url = `${this._uri}${this.endpoint}/${id}`;
    const customer_id = this.authService.currentCustomerValue?.id;
    return this.http
      .put<DataResponse<any>>(url, JSON.stringify({ customer_id: customer_id, sms_campaign: payload }), {
        headers: this.headers
      }).pipe(
        map(resp => {
          return resp;
        })
      );
  }

  get_sms_campaign(id: any): Observable<any> {
    const url = `${this._uri}${this.endpoint}/${id}`;
    const customer_id = this.authService.currentCustomerValue?.id;
    const params = new HttpParams().set('customer_id', customer_id);
    return this.http.get<any[]>(url, { params }).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  destroy(id: any): Observable<void> {
    const url = `${this._uri}${this.endpoint}/${id}`;
    return this.http.delete<void>(url, { headers: this.headers });
  }

  create_ghl_location(payload: any): Observable<any> {
    const customer_id = this.authService.currentCustomerValue?.id;
    const url = `${this._customerURI}${customer_id}/ghl.json`;
    return this.http
      .post<DataResponse<any>>(url,
        JSON.stringify({  ghl_location: payload }),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }

}
