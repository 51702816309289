<!-- <app-sidebar> -->
  <div nz-row nzType="flex" nzJustify="space-between">
    <h1 class="section-title title">
      <i nz-icon nzType="flag"></i>
      Make Content
    </h1>
  </div>
  <div class="nz-mb-0">
    <div class="title-header" *ngTemplateOutlet="listingTContent"></div>
    <ng-container *ngTemplateOutlet="productsCards"></ng-container>
    <ng-template #listingTContent>
      <h2 class="mb-0 fw-600">
        Type of content
      </h2>
      <p class="fw-600">What type of content do you want to make?</p>
    </ng-template>
    <ng-template #productsCards>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="products-container" *ngFor="let product of products">
          <app-card-button [config]="product" cardType="product-card"></app-card-button>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- </app-sidebar> -->
