import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Infographic } from 'src/app/vos/infographic/infographic';
import { AuthenticationService } from '../authentication/authentication.service';
import { map } from 'rxjs/operators';
import { CustomerResourceService } from '../customer-resource.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class InfographicsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class InfographicsService extends CustomerResourceService<Infographic> {

  public endpoint = 'infographics';
  public data_key = 'infographic';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Infographic, http, authService);
  }
  gallery(filter, pageId, mediaType): Observable<any> {
    const params: Record<string, string> = {
      'page': pageId,
      'q[s]': 'created_at desc',
      'q[tags_name_eq]': filter
    };
    // if (mediaType === 'video') {
    //   params['q[media_type]'] = mediaType;
    // } else {
    //   params['q[media_type_eq]'] = mediaType;
    // }
    return super.listFrom('infographics', params).pipe(
      map(resp => {
        return resp;
      })
    );
  }
  tags(): Observable<any> {
    return super.listTags('infographics/tags');
  }
}

