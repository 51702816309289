import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { CardButtonConfig } from "../../models";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { map } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Content } from "src/app/vos/content/content";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Customer } from "src/app/vos/customer/customer";
import { BreadCrumService } from "../../services/breadcrum/bread-crum.service";
import { NotificationService } from "../../services/notification/notification.service";
import { ContentsService } from "../../services/contents/contents.service";
import { DownloaderService } from "src/app/services/downloader.service";
import { ContentPreviewDialogComponent } from "../../shared/content-preview-dialog/content-preview-dialog.component";
import { SocialPostDialogComponent } from "../../shared/social-post-dialog/social-post-dialog.component";
import { GenericDialogComponent } from "../../shared/generic-dialog/generic-dialog.component";
import { ContentApprovalModalComponent } from "../../shared/content-approval-modal/content-approval-modal.component";
import moment from "moment";
import { CustomContentDialogComponent } from "../custom-content-dialog/custom-content-dialog.component";
import { EventPreviewComponent } from './event-preview/event-preview.component'
import { IModalData } from "src/app/models/interfaces/modals/modal";
import {
  CalendarOptions,
  EventApi
} from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from '@fullcalendar/daygrid';
import { GenericDialogConfig } from "src/app/models/interfaces/modals/generic-modal-config";

@Component({
  selector: "app-content-calender",
  templateUrl: "./content-calender.component.html",
  styleUrls: ["./content-calender.component.scss"],
})

export class ContentCalenderComponent implements OnInit, OnDestroy {
  @Input() calendarHeader = {
    right: "dayGridMonth,timeGridWeek,timeGridDay title prev today next",
    center: "",
    left: null,
  };
  @Input() title = 'Content Calendar';
  @Input() hideCreate: boolean = false;

  TODAY_STR = new Date().toISOString().replace(/T.*$/, "");
  INITIAL_EVENTS: any[] = [];
  eventGuid = 0;
  createEventId() {
    return String(this.eventGuid++);
  }
  calendarVisible = true;
  calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];


  handleEventClick(arg: any) {
    let { content } = arg?.event?.extendedProps;
    this.modalService.create<EventPreviewComponent, IModalData>({
      nzTitle: '',
      nzContent: EventPreviewComponent,
      nzData: {
        content: content
      },
      nzFooter: null,
    });
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  currentContentId;
  notificationId: number;
  dateFilter;
  queuedData = [];
  scheduledContent = [];
  newId;
  approval;
  contentUpdateSubscription: Subscription;
  currentTab = 0;
  loading = true;
  products: CardButtonConfig[];
  contents: Content[];
  calenderContents: any;
  customer: Customer;
  pageSizeOptions = [10];
  searchText: string;
  contentPagination = {
    limit: 10,
    total: 100,
    page: 1,
    totalPages: 10,
  };
  checked: true;
  selectedValue = "all";
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  constructor(
    private authService: AuthenticationService,
    private breadcrumService: BreadCrumService,
    private contentService: ContentsService,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private router: Router,
    private cdrf: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.contentChannelSub();
    this.customer = this.authService.currentCustomerValue;
    this.breadcrumService.set_breadcrum();
    // this.fetchContent();

    this.setCalendarConfigs();

  }

  contentChannelSub() {
    this.contentUpdateSubscription = this.contentService
      .contentUpdateChannel()
      .received()
      .subscribe((res) => {
        if (res) {
          const contentResponse = res.data;
          const contentIndex = this.contents?.findIndex(
            (c) => c.id === contentResponse.content_id
          );
          if (contentIndex > -1) {
            this.contents[contentIndex].status = contentResponse.status;
            if (this.contents[contentIndex].last_post) {
              this.contents[contentIndex].last_post.status =
                contentResponse.status;
            }
          }
          const quedContentIndex = this.queuedData?.findIndex(
            (c) =>
              c.content_id === contentResponse.content_id &&
              c.id === contentResponse.post_id
          );
          if (quedContentIndex > -1) {
            this.queuedData[quedContentIndex].status = contentResponse.status;
            this.mapCalendarData();
          }
        }
      });
  }

  contentDeleted(content: Content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: "Confirm",
        message: `Are you sure you want to delete content?`,
        buttonLabel: "Cancel",
        extraButtons: [
          {
            label: "Confirm",
            value: true,
            color: "warn",
          },
        ],
      },
      nzFooter: null,
      nzWidth: "60%",
    });
    modal.afterClose.subscribe((response) => {
      if (response === true) {
        this.loading = true;
        this.contentService.destroy(content).subscribe(
          (res) => {
            this.contents = this.contents?.filter((c) => c.id !== content.id);
            this.message.create(
              "success",
              `Content has been successfully deleted.`
            );
            this.fetchContent();
          },
          (e) => {
            this.loading = false;
            this.message?.remove();
            this.message?.create(
              "error",
              "Error deleting the content. Please try again"
            );
          }
        );
      }
    });
  }

  onChangeDateFilter(event) {
    if (event) {
      this.router.navigate([], {
        queryParams: {
          created_at_gteq: this.dateFilter.length
            ? moment(this.dateFilter[0]).format("yyyy-MM-DD")
            : null,
          created_at_lteq: this.dateFilter.length
            ? moment(this.dateFilter[1]).format("yyyy-MM-DD")
            : null,
        },
        queryParamsHandling: "merge",
      });
      this.fetchContent();
    }
  }

  cancelSchedule(content: Content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: "Confirm",
        message: `Are you sure you want to cancel your scheduled post?`,
        buttonLabel: "Cancel",
        extraButtons: [
          {
            label: "Confirm",
            value: true,
            color: "warn",
          },
        ],
      },
      nzFooter: null,
      nzWidth: "60%",
    });
    modal.afterClose.subscribe((response) => {
      if (response === true) {
        this.loading = true;
        this.contentService.unschedulPost(content).subscribe(
          (res) => {
            this.contents = this.contents?.filter((c) => c.id !== content.id);
            this.message.create("success", `Your scheduled post is cancelled`);
            this.fetchContent();
          },
          (e) => {
            this.loading = false;
            this.message?.remove();
            this.message.create(
              "error",
              "Error cancelling the scheduled post. Please try again"
            );
          }
        );
      }
    });
  }

  selectContent(content: Content) {
    this.currentContentId = content.id;
    this.newId = null;
    this.router.navigate([], {
      queryParams: {
        id: this.currentContentId,
        new: null,
      },
      queryParamsHandling: "merge",
    });
  }
  onClosePreview(event) {
    this.currentContentId = null;
  }

  didPage(pageIndex) {
    this.contentPagination.page = pageIndex;
    this.fetchContent();
  }

  fetchContent(searchQuery?) {
    this.loading = true;
    let queryParams = `?page=${this.contentPagination.page}`;
    if (searchQuery) {
      queryParams = queryParams + searchQuery;
    } else if (this.searchText) {
      queryParams = `${queryParams}&q[contentable_of_MarketReport_type_zip_code_or_contentable_of_MarketReport_type_region_name_or_contentable_of_Listing_type_address_cont]=${this.searchText}`;
    }

    if (this.dateFilter && this.dateFilter.length) {
      queryParams = `${queryParams}&q[original_scheduled_at_gteq]=${this.dateFilter[0]}&q[original_scheduled_at_lteq]=${this.dateFilter[1]}`;
    } else{
      const today = moment(); // Get the current date
      const startDateOfMonth = moment(today).startOf('month'); // Get the start date of the current month
      const endDateOfMonth = moment(today).endOf('month'); // Get the end date of the current month

      const formattedStartDate = startDateOfMonth.format('YYYY-MM-DD'); // Format the start date
      const formattedEndDate = endDateOfMonth.format('YYYY-MM-DD'); // Format the end date
      queryParams = `${queryParams}&q[original_scheduled_at_gteq]=${formattedStartDate}&q[original_scheduled_at_lteq]=${formattedEndDate}`;
    }
    this.getQueuedData(queryParams);
  }

  getQueuedData(queryParams) {
    this.loading = true;
    const pageSize = 100;
    if (this.contentPagination.page === 1) {
      this.queuedData = [];
    }
    this.contentService
      .postSchedule(
        `${queryParams}&per_page=${pageSize}&page=${this.contentPagination.page}`
      )
      .subscribe((res) => {
        this.queuedData = [...this.queuedData, ...res.data];
        this.queuedData = this.queuedData?.map((entry) => {
          entry.isQueued = true;
          return entry;
        });
        if (res["data"].length > 0 && res["total_entries"] > pageSize &&
          this.queuedData.length < res["total_entries"]
        ) {
          this.contentPagination.page = this.contentPagination.page + 1;
          this.getQueuedData(queryParams);
          return;
        }
        this.getScheduledContent(queryParams);
      });
  }

  getContentData(queryParams, type) {
    this.contentService
      .list(queryParams, type)
      .pipe(
        map((res) => {
          if (res) {
            this.contentPagination.limit = res["per_page"];
            this.contentPagination.total = res["total_entries"];
            const limit = this.contentPagination.limit;
            const total_items = this.contentPagination.total;
            this.contentPagination.totalPages = Math.ceil(total_items / limit);
          }
          return res?.data;
        })
      )
      .subscribe((contents) => {
        this.contents = Array.isArray(contents) ? contents : [];
        this.loading = false;
      });
  }

  getScheduledContent(queryParams) {
    this.loading = true;
    this.contentService.calenderSchedule(queryParams).subscribe((contents) => {
      this.scheduledContent = contents.data;
      this.mapCalendarData();
      this.loading = false;
    });
  }

  mapCalendarData() {
    this.calenderContents = {};

    this.scheduledContent.forEach((content, index) => {
      let key = moment(content.scheduled_for).format("YYYY-MM-DD");
      this.mapFullCalendarData({ content_id: index, ...content }, key);
      if (this.calenderContents[key]) {
        this.calenderContents[key].push(content);
      } else {
        this.calenderContents[key] = [content];
      }
    });
    this.queuedData.forEach((content) => {
      const key = content.original_scheduled_at
          ? moment(content.original_scheduled_at).format("YYYY-MM-DD")
          : moment(content.created_at).format("YYYY-MM-DD");
      this.mapFullCalendarData(content, key);

      if (this.calenderContents[key]) {
        this.calenderContents[key].push(content);
      } else {
        this.calenderContents[key] = [content];
      }
    });

    this.calendarOptions.events  = this.INITIAL_EVENTS;

    // this.setCalendarConfigs();
  }

  mapFullCalendarData(content, key) {
    let eventObject = {};
    const green = '#52c41a';
    const pink = '#eb2f96';
    let selectedColor = green;
    eventObject["id"] = content.content_id;
    eventObject["title"] = content.content_attributes.display_name;
    eventObject["start"] = key;
    eventObject["content"] = content;
    if (content.auto_requested) {
      selectedColor = green;
    } else {
      selectedColor = pink;

    }
    eventObject["backgroundColor"] = selectedColor;
    eventObject["borderColor"] = selectedColor;


    eventObject['classNames'] = ['content-event'];
    this.INITIAL_EVENTS.push(eventObject);
  }

  setCalendarConfigs() {
    this.calendarOptions = {
      timeZone: 'false',
      headerToolbar: this.calendarHeader,
      plugins: [timeGridPlugin, dayGridPlugin],
      initialView: "dayGridMonth",
      // initialEvents: this.INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
      weekends: true,
      editable: false,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      // select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventsSet: this.handleEvents.bind(this),
      datesSet: this.handleDatesSet.bind(this),
      events: [],
      eventContent: this.handleEventRender.bind(this),
      // eventMouseEnter: this.handleEventMouseEnter.bind(this),
      // eventMouseLeave: this.handleMouseLeave.bind(this),
    };
  }

  handleDatesSet(info) {
    this.INITIAL_EVENTS = [];
    this.calendarOptions.events = [];
    this.dateFilter = [];
    const today = moment(); // Get the current date
    const startDateOfMonth = moment(info.startStr);
    const endDateOfMonth = moment(info.endStr);
    const formattedStartDate = startDateOfMonth.format('YYYY-MM-DD');
    const formattedEndDate = endDateOfMonth.format('YYYY-MM-DD');
    this.dateFilter[0] = formattedStartDate;
    this.dateFilter[1] = formattedEndDate;
    this.fetchContent();
  }

  handleEventMouseEnter(arg) {
    const { content } = arg?.event?.extendedProps;

    this.modalService.create<EventPreviewComponent, IModalData>({
      nzTitle: '',
      nzContent: EventPreviewComponent,
      nzData: {
        content: content
      },
    });

  }

  handleMouseLeave() {
    // this.modalService.closeAll();

  }
  handleEventRender(arg) {
    const { content } = arg?.event?.extendedProps;
    let imgSrc = '.././../../assets/icons/facebook_new.png';
    if (content.posted_on && content.posted_on === 'facebook') {
      imgSrc = '.././../../assets/icons/facebook_new.png';
    }
    if (content.posted_on && content.posted_on === 'linkedin') {
      imgSrc = '.././../../assets/icons/linkedin_new.png';
    }
    if (content.posted_on && content.posted_on === 'instagram') {
      imgSrc = '.././../../assets/icons/instagram_new.png';
    }

    if (content.posted_on && content.posted_on === 'google_business') {
      imgSrc = '.././../../assets/icons/google_new.png';
    }
    if (content.posted_on && content.posted_on === 'youtube') {
      imgSrc = '.././../../assets/icons/youtube.png';
    }
    if (content.posted_on && content.posted_on === 'tiktok') {
      imgSrc = '.././../../assets/icons/tiktok.png';
    }

    const html = `<img class="platform-image" src="${imgSrc}" alt="" /> <span class="event-title"> ${content.display_name || content.content_attributes.display_name} </span>`;
    return { html };
  }

  log(args: any[]): void {
    this.searchText = "";
    this.fetchContent();
  }

  download(content) {
    const newContenr = new Content(content);
    let url = content?.url || "";
    if (url) {
      let file_type_array = url.split(".");
      let file_type = file_type_array[file_type_array.length - 1];
      let filename = newContenr.filename || `download.${file_type}`;
      if (!url.includes("https")) {
        url = url.replace("http", "https");
      }
      this.downloader.save(url, filename);
    }
  }
  post(content) {
    if (content.status === "ready") {
      const modal = this.modalService.create<SocialPostDialogComponent, IModalData>({
        nzContent: SocialPostDialogComponent,
        nzData: {
          content: content
        },
        nzFooter: null,
      });
    }
  }

  sort(sort: { key: string; value: string }) {
    const sortName = sort.key || "descend";
    this.contents?.sort((a, b) =>
      sort.value === "ascend"
        ? a[sortName!] > b[sortName!]
          ? 1
          : -1
        : b[sortName!] > a[sortName!]
          ? 1
          : -1
    );
  }
  showPreview(content, isEdit?) {
    const that = this;
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content,
          isEdit,
          refresh: (updatedContent) => {
            const selectedContentIndex = that.contents?.findIndex(
              (c) => c.id === updatedContent.id
            );
            that.contents[selectedContentIndex] = updatedContent;
          },
        },
      },
      nzFooter: null,
      nzWidth: "50%",
    });
    modal.afterClose.subscribe((response) => {
      if (response && response.action === "deleted") {
        this.fetchContent();
      }
      this.currentContentId = null;
      this.router.navigate([], {
        queryParams: {
          id: null,
        },
        queryParamsHandling: "merge",
      });
    });
  }

  gotToEdit(content) {
    this.router.navigate([`listings/${content.contentable_id}/edit`]);
  }
  openApprovalModal(content: any) {
    const modal = this.modalService.create<ContentApprovalModalComponent, IModalData>({
      nzContent: ContentApprovalModalComponent,
      nzFooter: null,
      nzData: {
        content: content,
      },
    });
    this.cdrf.detectChanges();
    modal.afterClose.subscribe((closed) => {
      this.cdrf.detectChanges();
      this.router.navigate(["content"]);
    });
  }

  updateSingleChecked() {
    let params = "";
    if (params.length < 1) {
      this.router.navigate([], {
        queryParams: { content_category_eq: null },
        queryParamsHandling: "merge",
      });
    } else {
      this.router.navigate([], {
        queryParams: { content_category_eq: params },
        queryParamsHandling: "merge",
      });
    }
    this.fetchContent();
  }
  updateAllChecked(panel) {
    panel.childFilters.forEach((filter) => {
      filter.value = panel.checkbox;
    });
    this.updateSingleChecked();
  }

  mapFilters(filters) {
    let params = "";
    filters.forEach((filter) => {
      params = params + "&q[category_in][]=" + filter.filter_value;
    });
    return params;
  }
  truncateChar(text: string): string {
    const charlimit = 25;
    if (!text || text.length <= charlimit) {
      return text;
    }
    const shortened = text.substring(0, charlimit) + "...";
    return shortened;
  }

  changeIndex(contentType) {
    this.newId = null;
    let queryParams = {
      status: null,
      new: null,
    };
    this.contentPagination.page = 1;
    if (contentType === "Published") {
      queryParams = { status: "published", new: null };
    } else if (contentType === "Unpublished") {
      queryParams = { status: "unpublished", new: null };
    }
    this.router.navigate(["/content"], {
      queryParams,
      queryParamsHandling: "merge",
    });
    this.fetchContent();
  }

  clearSubscriptions() {
    if (this.contentUpdateSubscription) {
      this.contentUpdateSubscription.unsubscribe();
    }
  }
  ngOnDestroy() {
    this.clearSubscriptions();
  }

  createCustomContent() {
    const modal = this.modalService.create({
      nzTitle: "Create Custom Content",
      nzContent: CustomContentDialogComponent,
      nzWidth: "40%",
      nzFooter: null,
    });
  }
}
