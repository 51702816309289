<div nz-row class="login-form-first-div">
  <div class="login-form-second-div">
    <div nz-row class="flex-layout">
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-form">
        <div  *ngIf="!invited">
          <img width="200px"  src="assets/logos/blue-logo.png" />
          <h2  class="sign-in">Sign In With Magic Link</h2>
          <form nz-form [formGroup]="authForm" (ngSubmit)="invite()" class="login-form">
            <span class="error" *ngIf="errorMessage">{{ errorMessage }}</span>
            <nz-form-item>
              <!--<nz-form-label>Email</nz-form-label>-->
              <nz-form-control nzErrorTip="Email is required">
                <nz-input-group nzPrefixIcon="mail" [nzCompact]="true">
                  <input nz-input name="email" type="email" id="email" formControlName="email" placeholder="Email" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <button nz-button [nzLoading]="loading" nzType="primary" class="login-button" [disabled]="!authForm.valid && authForm.touched" size="large" type="submit">
              Send Magic Link
            </button>
            <button nz-button class="back-button" type="button"  (click)="goto('/login')"><i nz-icon nzType="left"></i> Go back</button>
          </form>
        </div>
        <div *ngIf="invited">
          <span class="reset-message">Check your email for a link to sigin!</span>
          <nz-divider></nz-divider>
          <span class="reset-notice">Link is sent to {{email}}</span>
          <button nz-button class="resend-button login-button" nzType="primary" (click)="invite()">
            Resend Magic Link
          </button>
        </div>
      </div>
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-img second-bg">
        <img class="login-form-forgot" class="side-image" src="assets/images/media.png" />
      </div>
    </div>
  </div>
</div>
