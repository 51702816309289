import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Content } from 'src/app/vos/content/content';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import {Subject} from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */

@Injectable()
/**
 * Service class.
 */
export class BillingService extends CustomerResourceService<Content> {
  public endpoint = 'stripe_billing';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Content, http, authService);
  }

  getSessionDetails(): Observable<any> {
    return this.http.get(`${this._customerURI}${this.customerEndpoint}`, { responseType: 'json' });
  }

  paymentHistory(): Observable<any> {
    return this.http.get(`${this._customerURI}${this.customerEndpoint}/payments`, { responseType: 'json' });
  }
}
