import { Injectable } from '@angular/core';
import { Customer } from 'src/app/vos/customer/customer';
import jwt_decode from "jwt-decode";

@Injectable()
export class JwtService {
  getToken(): String {
    return window.localStorage['jwtToken'];
  }

  getSavedCustomer(): Customer {
    const customerDetails = window?.localStorage?.getItem('customer');

    if (customerDetails !== undefined && customerDetails !== null) {
      const custDetails = JSON.parse(customerDetails);
      return new Customer(custDetails);
    } else {
      return undefined;
    }
  }

  saveToken(token: String) {
    if (token) {
      window.localStorage['jwtToken'] = token;
    }
  }

  saveCurrentCustomer(customer: Customer) {
    if (customer) {
      window.localStorage['customer'] = JSON.stringify(customer);
    } else {
      window?.localStorage?.removeItem('customer');
    }
  }

  destroyToken() {
    if (window?.localStorage) {
      window.localStorage.removeItem('jwtToken');
      window.localStorage.removeItem('customer');
      window.localStorage.removeItem('awsToken');
      window.localStorage.removeItem('awsIdentity');
      window.localStorage.removeItem('email_auth_token');
    }
  }

  saveAWSIdentity(identity: String, token: String) {
    window.localStorage['awsToken'] = token;
    window.localStorage['awsIdentity'] = identity;
  }

  saveEmailToken(token: String) {
    window.localStorage['email_auth_token'] = token;
  }

  getEmailToken(): String {
    return window.localStorage['email_auth_token'];
  }

  getAWSIdentity(): String {
    return window.localStorage['awsIdentity'];
  }

  getAWSToken(): String {
    return window.localStorage['awsToken'];
  }

  decodeJWTToken(token: string): string {
    return jwt_decode(token);
  }
  getCurrentDecodedJwt() : any {
    const jsonToken = window?.localStorage?.getItem('jwtToken');
    return jwt_decode(jsonToken);
  }
}
