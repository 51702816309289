import {Resource} from '../resource/resource';

export class Conversation extends Resource {
  id?: string;
  contactId: string;
  locationId: string;
  lastMessageBody: string;
  lastMessageType: string;
  type: string;
  unreadCount: string;
  fullName: string;
  contactName: string;
  email: string;
  phone: string;
  messages: any[];

  constructor(vals: any = {}) {
    super(vals);
    Object.assign(this, vals);
  }

}
