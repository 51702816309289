
<ng-template #campaignflow>
  <div class="container">
    <h1 class="title">Create Campaign Flow</h1>
    <nz-steps [nzCurrent]="current" class="content-stepper">
      <nz-step nzTitle="Select Template" (click)="gotoStep(0)"></nz-step>
      <nz-step nzTitle="Select Market" (click)="gotoStep(1)"></nz-step>
      <nz-step nzTitle="Preview Template" (click)="gotoStep(2)"></nz-step>
      <nz-step nzTitle="Select Audience" (click)="gotoStep(3)"></nz-step>
      <nz-step nzTitle="Campaign Details" (click)="gotoStep(4)"></nz-step>
    </nz-steps>
    <div>
      <h2 class="step-title" *ngIf="current===0">Select Template</h2>
      <h2 class="step-title" *ngIf="current===1">Select Market</h2>
      <h2 class="step-title" *ngIf="current===2">Preview Template</h2>
      <h2 class="step-title" *ngIf="current===3">Select Audience</h2>
      <h2 class="step-title" *ngIf="current===4">Campaign Details</h2>
    </div>

    <div class="steps-content" *ngIf="current > 0">
      <div nz-row class="top-actions">
        <div nz-col nzSpan="8">
          <button nz-button nzSpan="12" *ngIf="!loading" class="back-btn" (click)="pre();">
            Back</button>
        </div>
        <div nz-col nzSpan="8" nzOffset="8">
          <button nz-button nzSpan="12" class="fl-r" nzType="primary" [disabled]="isNotNext" color="primary"
            *ngIf="!loading &&  current < 4" (click)="delayedNext()">Next</button>
          <button nz-button nzSpan="12" class="fl-r" nzType="primary" [disabled]="isNotNext" color="primary"
            *ngIf="!loading && current ===4" (click)="saveCampaign()">Send</button>
        </div>
        <div class="spinner-custom" *ngIf="loading">
          <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        </div>
      </div>
    </div>
    <div class="steps-content" *ngIf="current === 0">
      <div nz-row nzJustify="end" class="marb-20">
        <button [disabled]="!selectedTemplate || !selectedTemplate.id" nz-button nzType="primary"
          (click)="delayedNext()">Next</button>
      </div>
      <div nz-row nzAlign="middle">
        <app-email-template-image-card class="email-template-card" *ngFor="let c of styles" [content]="c"
          [horizontalMargin]="'0px'" (cardClicked)="selectStyle(c)" fxFlex></app-email-template-image-card>
      </div>

      <h3 *ngIf="styles && !styles.length && !loading">
        No content style available!
      </h3>
      <div nz-row nzJustify="end" class="create-cont">
        <nz-pagination *ngIf="styles.length" [nzPageIndex]="pagination.page" [nzTotal]="pagination.total"
          (nzPageIndexChange)="didPage($event)">
        </nz-pagination>
      </div>
    </div>
    <div class="steps-content" *ngIf="current === 1">
      <app-reach-people [selectedItem]="selectedMarket" (change)="selectMarket($event)" [type]="'market_report'"
        [name]="'email_campaign'"></app-reach-people>
    </div>
    <div class="steps-content" *ngIf="current === 2">
      <app-template-preview (formChange)="templateFormChange($event)" [templateHtml]="templateHtml" [model]="campaign" [market]="selectedMarket"
        [data]="marketData" [template]="selectedTemplate"></app-template-preview>
    </div>
    <div class="steps-content" *ngIf="current === 3">
      <app-select-audience (change)="selectAudience($event)"></app-select-audience>
    </div>
    <div class="steps-content" *ngIf="current === 4">
      <h2>Almost Done!</h2>
      <p>Review your info and send!</p>
      <app-email-template-detail-preview (onStep)="gotoStep($event)" [sendingList]="sendingList"
        [htmlOutput]="templateHtml.htmlOutput" [campaign]="campaign"></app-email-template-detail-preview>
      <!-- <app-campaign-details [model]="campaign" [template]="selectedTemplate"></app-campaign-details> -->
    </div>
    <div class="steps-content" *ngIf="current === 7">
      <h2>Almost Done!</h2>
      <p>Review your info and send!</p>
      <div nz-row nzJustify="space-between">
        <div nz-col nzSpan="12" class="summary">
          <h4>Campaign Name:</h4>
          <div nz-row nzJustify="space-between" class="sub-heading">
            <div>
              <span>{{campaign.name}}</span>
            </div>
            <div>
              <a href="javascript:void(0);" (click)="current = 4">Edit</a>
            </div>
          </div>

          <h4>Sending to:</h4>
          <div nz-row nzJustify="space-between" class="sub-heading">
            <div>
              <span>{{sendingList}}</span>
            </div>
            <div>
              <a href="javascript:void(0);" (click)="current = 3">Edit</a>
            </div>
          </div>

          <h4>Target Market:</h4>
          <div nz-row nzJustify="space-between" class="sub-heading">
            <div>
              <span>Beverly hills 90210</span>
            </div>
            <div>
              <a href="javascript:void(0);" (click)="current = 1">Edit</a>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="10" class="preview-container">
          <img [src]="selectedTemplate.thumbnail_url" alt="">
        </div>
      </div>

    </div>

    <div class="steps-content congrats-container" *ngIf="current === 5">
      <div class="congrats">
        <i nz-icon nzType="check-circle" nzTheme="outline"></i>
        <h2>Congratulations</h2>
        <p>Your email campaign has been sent.</p>
      </div>
      <nz-divider></nz-divider>
      <span>See your existing campaigns or create another.</span>
      <div class="campaign-btn">
        <button nz-button nzType="primary" (click)="gotoCampaigns()">Campaign Page</button>
      </div>
    </div>
  </div>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="campaignflow"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="campaignflow"></ng-container>
</ng-template>
