import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { Listing } from 'src/app/vos/listing/listing';
import { CardButtonConfig } from 'src/app/models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { PaginationService } from '../../services/pagination/pagination.service';

@Component({
  selector: 'app-listings-selector',
  templateUrl: './listings-selector.component.html',
  styleUrls: ['./listings-selector.component.scss']
})
export class ListingsSelectorComponent implements OnInit {
  @Output() select = new EventEmitter();

  allListings: Listing[] = [];
  @Input()
  isChild: Boolean;
  listings: Listing[] = [];
  loading = false;
  sortings = ['address', 'status'];
  searchText: string;
  pageSizeOptions = [10];
  listingPagination = {
    limit: 10,
    total: 10,
    page: 1,
    totalPages: 10
  };
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  selectedSort = {
    by: 'address',
    order: 'asc'
  };
  newCardButton: CardButtonConfig = {
    id: 'new',
    title: 'CREATE',
    actionText: 'NEW LISTING',
    icon: 'fas plus',
    link: 'listings/new',
    colors: {
      bg: '#f4faff',
      text: '#326771',
      textSelected: '#f4faff'
    }
  };
  ConfigListingTitle = {
    title: 'Listings',
    class: 'section-title',
    icon: 'home',
    colors: '#30d289',
  };
  constructor(
    private listingService: ListingsService,
    private breadcrumService: BreadCrumService,
    private paginationService: PaginationService
  ) {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        // this.listingPagination.page = 1;
        if (newText) {
          this.fetchContent(newText);
        } else {
          this.fetchContent();
        }
      });
  }

  ngOnInit() {
    this.loading = true;
    this.fetchContent();
    this.breadcrumService.set_breadcrum();
  }

  clickCard(item) {
    this.select.emit(item);
  }

  setSortOption(sortBy) {
    this.selectedSort.by = sortBy;
    this.searchText = '';
    // this.listingPagination.page = 1;
    this.fetchContent();
  }

  toggelSortOrder() {
    const cOrder = this.selectedSort.order;
    if (cOrder === 'asc') {
      this.selectedSort.order = 'desc';
    } else {
      this.selectedSort.order = 'asc';
    }
    // this.listingPagination.page = 1;
    this.fetchContent();
  }

  configFor(listing: Listing): CardButtonConfig {
    return {
      id: listing.id,
      title: '',
      actionText: listing.listing_status,
      centerText: listing.address,
      link: this.isChild ? `` : `listings/${listing.id}`,
      createdAt: listing.created_at,
      colors: {
        bg: '#30d289',
        textSelected: 'white'
      },
    };
  }
  didPage(pageIndex) {
    this.listingPagination.page = pageIndex;
    this.loading = true;
    this.paginationService.setCurrentPage(this.listingPagination);
    this.fetchContent();
  }
  fetchContent(text?) {
    this.loading = true;
    const sortBy = this.selectedSort.by === 'status' ? 'listing_status' : this.selectedSort.by;
    // const pageNo = this.searchText ? 1 : this.listingPagination.page;
    const pageNo = this.paginationService.getCurrentPage();
    let query = `?page=${pageNo}&q[s]=${sortBy} ${this.selectedSort.order}`;
    if (text || this.searchText) {
      query = `${query}&q[address_cont]=${text || this.searchText}`;
    }
    this.listingService.list(query).subscribe(response => {
      if (response) {
        this.loading = false;
        this.listingPagination.page = pageNo;
        this.listingPagination.limit = response['per_page'];
        this.listingPagination.total = response['total_entries'];
        const limit = this.listingPagination.limit;
        const total_items = this.listingPagination.total;
        this.listingPagination.totalPages = Math.ceil(total_items / limit);

        this.allListings = response.data;
        this.listings = this.allListings;
      }
    });
  }
}
