<div>
  <div class="social-connect-container">
    <div class="social-headings" *ngIf="!facebookSupport">
      <h2>Upgrade Your Plan</h2>
      <p>Schedule a time for your Account Manager to call and upgrade your plan for you!</p>
    </div>
    <div class="social-headings mt-5" *ngIf="facebookSupport">
      <h2>Contact a Zentap Success rep to connect your facebook account.</h2>
      <p>For Immediate support call <a href="tel:3109974142">3109974142</a> or schedule an appointment below</p>
    </div>
  <ul class="social-selection">
    <li>
      <!-- Calendly inline widget begin -->
      <div #container class="calendly-inline-widget" style="min-width:320px;height:320px;" data-auto-load="false"></div>
      <!-- Calendly inline widget end -->
    </li>
  </ul>
  </div>
</div>
