import {
  Component,
  OnInit,
  HostListener
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { Product } from 'src/app/vos/product/product';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductsService } from '../../services/products/product-api.service';
import { AnnouncementService } from '../../services/announcement/announcement.service';
import { CardButtonConfig } from '../../models/interfaces';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-create-content',
  templateUrl: './create-content.component.html',
  styleUrls: ['./create-content.component.scss']
})
export class CreateContentComponent implements OnInit {
  productsConfigs:any[]= this.globalsService.getContentCrads();
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  productSwipe = this.globalsService.getSwiperOptions();
  public innerWidth: any;
  customer: Customer;
  cardType = 'dashboard-card';
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.productSwipe.slidesPerView = Math.floor(event.target.innerWidth / 250);
    this.productSwipe.slidesPerGroup = Math.floor(event.target.innerWidth / 250);
  }
  constructor(
    private authService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private productsService: ProductsService,
    private breadcrumService: BreadCrumService,
    private globalsService: GlobalsService,
    private modalService: NzModalService,
    private announcementService: AnnouncementService
  ) { }
  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    this.breadcrumService.set_breadcrum();
    this.detectDevice();
  }

  detectDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }

}
