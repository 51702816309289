<div class="image-card {{imageable_type}}" nz-row nzType="flex" nzJustify="center" *ngIf="imageable_type === 'photo0'">
  <div *ngIf="imageable_type === 'photo0' else withoutGallery" class="overlay" nz-row nzType="flex" nzJustify="start">
    <button class="upload-button" fxFlex="50%" [style.backgroundColor]="'lightgray'" (click)="openImageDialog(imageable_type)">Upload</button>
    <button class="upload-button upload-button-dark" fxFlex="50%" [style.backgroundColor]="'#008CBA'" (click)="selectFromGallery()">Gallery</button>
  </div>
  <ng-template #withoutGallery>
    <div class="overlay" nz-row nzType="flex" nzJustify="start">
      <button class="upload-button" fxFlex="100%" [style.backgroundColor]="'lightgray'" (click)="openImageDialog(imageable_type)">Upload</button>
    </div>
  </ng-template>
  <!-- [style.width]="fullWidth ? '90%': '200px'" [style.height]="fullWidth ? '200px': 'auto'"  -->
  <img *ngIf="isNew" [src]="newImage ? newImage : image"  />
  <nz-spin nzSimple class="photo-loading" *ngIf="updateImage"></nz-spin>
  <i nz-icon nzType="plus" nzTheme="outline" class="icon-padding" *ngIf="!isNew"></i>

</div>
<div class="image-card {{imageable_type}}" [ngClass]="isNew ?'back-transparent':'back-green '" (click)="openImageDialog(imageable_type)" nz-row nzType="flex" nzJustify="center"
  *ngIf="imageable_type != 'photo0'">
  <i nz-icon nzType="close-circle" nz-tooltip nzTooltipTitle="Remove Image" (click)="removeImage($event)" nzTheme="outline" class="cross-icon hidden" *ngIf="config?.removeFamilyPhoto && image"></i>
  <img *ngIf="isNew" [src]="newImage ? newImage : image"/>
  <nz-spin nzSimple class="photo-loading" *ngIf="updateImage"></nz-spin>
  <i nz-icon nzType="plus" nzTheme="outline" class="icon-padding-family" *ngIf="!isNew"></i>
</div>
