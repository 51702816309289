import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { MarketReportsService } from './services/market-reports/market-reports.service';

@Injectable()

export class AppGuard  {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private jwtService: JwtService,
    private marketReportsService: MarketReportsService
  ) { }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
    return this.checkCustomer(state);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkCustomer(state);
  }
  async checkCustomer(state) {
    const customer = this.authService.currentCustomerValue;
    if (!customer || !this.jwtService.getToken()) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    } else if (customer && !customer.onboarded) {
      this.refreshCustomer();
      if (state.url === '/registration') {
        return true;
      } else {
        this.router.navigateByUrl('/registration');
        return false;
      }
    } else if (customer && !customer.accepted_tos) {
      this.refreshCustomer();
      if (state.url === '/terms') {
        return true;
      }
      else {
        this.router.navigateByUrl('/terms');
        return false;
      }
    } else if (customer && customer.accepted_tos && state.url === '/terms') {
      this.router.navigateByUrl('/');
      return false;
    } else if (customer) {
      if (state.url === '/registration') {
        this.router.navigateByUrl('/');
        return false;
      } else {
        this.refreshCustomer();
        return true;
      }
      // const response = await this.marketReportsService.list().toPromise();
      // if (response.data && response.data.length > 0) {
      //   if (state.url === '/registration') {
      //     this.router.navigateByUrl('/');
      //     return false;
      //   } else {
      //     this.refreshCustomer();
      //     return true;
      //   }
      // } else {
      //   if (state.url === '/registration') {
      //     return true;
      //   } else {
      //     this.router.navigateByUrl('/registration');
      //     return false;
      //   }
      // }


    } else {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

  refreshCustomer() {
    this.authService.refresh()
      .subscribe(c => {
        this.authService.updateCurrentCustomer(c);
      });
  }
}
