import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; // Import HttpParams
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { Contact } from '../../vos/contact/contact';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { DataResponse } from '../../models/data-response/data-response';

export class LandingPageServiceConfig {
  uri = '';
}
@Injectable()
export class LandingPageService extends CustomerResourceService<Contact> {

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Contact, http, authService);
  }

  
  get_content(id: any): Observable<any> {
    const url = `${this._uri}agent_listing/${id}.json`;
    return this.http.get<any[]>(url).pipe(
      map(resp => {
        return resp;
      }
    ));
  }

  analytics(payload: any): Observable<any> {
    return this.http
    .post<DataResponse<any>>(
      `${this._uri}agent_listing/${payload['id']}/analytics.json`,
      JSON.stringify({ payload }),
      { headers: this.headers }
    )
    .pipe(map(resp => resp));
  }

}

