import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { EmailResourceService } from '../email-resource.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConnectLinkedinService } from '../connect-linkedin/connect-linkedin.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.email_api_url}`;
}

@Injectable()

export class EmailTemplateService extends EmailResourceService<any> {
  public endpoint = 'templates';
  public data_key = 'data';

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(http, authService);
  }
}
