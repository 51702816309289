import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Content } from 'src/app/vos/content/content';
import { BreadCrumService } from '../../../services/breadcrum/bread-crum.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { TestimonialAttributes } from 'src/app/shared/interfaces/testmonial-attributes';
import { ContentsService } from 'src/app/services/contents/contents.service';
import { NgForm } from '@angular/forms';
import { ProductsService } from 'src/app/services/products/product-api.service';
import { Customer } from 'src/app/vos/customer/customer';
import { ContentFormComponent } from 'src/app/shared/content-form/content-form.component';
import { GlobalsService } from '../../../services/globals/globals.service';
import { ConnectLinkedinService } from '../../../services/connect-linkedin/connect-linkedin.service';
import { TestimonyService } from '../../../services/testimonies/testimonies.service';
import { CustomersService } from '../../../services/customers/customers.service';
import { Testimony } from 'src/app/vos/testimony/testimony';

@Component({
  selector: 'app-edit-testimony',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditTestimonyComponent implements OnInit, AfterViewInit {
  content = new Content();
  testimony = new Testimony();
  testimonialType: string;
  images = [];
  submitted = false;
  textLength = 0;

  uid = this.globalService.randomString();
  galleryImage: any;
  @ViewChild('testimonialForm') form: NgForm;
  @ViewChild('contentForm') contentForm: ContentFormComponent;
  current = 0;
  requiredFields = [
    'attestant_name',
    'copy'
  ];
  isPhotoValid = false;
  isGalleryValid = false;
  valid = false;
  customer: Customer;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public message: NzMessageService,
    private breadcrumservce: BreadCrumService,
    private globalService: GlobalsService,
    private testimonyService: TestimonyService,
  ) { }

  ngAfterViewInit() {
    this.form.valueChanges.subscribe(() => {
      this.valid = this.form.valid;
    });
  }
  ngOnInit() {
    this.route.data.subscribe(
      (res: { data: Testimony }) => {
        if (res && res.data) {
          this.testimony = res.data;
        }
      });
    this.route.params.subscribe(params => {

      if (params.form) {
        this.testimonialType = params.form;
      }
    });

    // this.breadcrumservce.set_breadcrum();
  }

  saveData() {
    if (this.testimony?.id) {
      this.updateTestimony();
    } else {
      this.createTestimony();
    }
  }
  createTestimony() {
    this.testimonyService.create(this.testimony)
      .subscribe(res => {
        if (this.testimonialType) {
          this.router.navigate(['/create_content', 'marketing', this.testimonialType, res.id, 'new']);
        } else {
          this.router.navigate(['/testimonials']);
        }
        this.message?.create('success', `Your testimony is created!`);
      })
  }

  updateTestimony() {
    this.testimonyService.update(this.testimony)
      .subscribe(res => {
        this.message?.create('success', `Your testimony is updated!`);
        this.router.navigate(['/testimonials']);
      });
  }

  setFamilyImage(image) {
    if (image) {
      this.images[0] = image.id;
      this.testimony.image = image?.image;
      this.testimony.image_attributes = {
        id: image.id,
        changed: true,
      };
    }
  }
  previous() {
    this.current -= 1;
  }

  valueChange(value) {
    this.testimony.copy = value;
    this.textLength = value.length;
  }
  get attestantPhotoConfig() {
    return {
      configMeta: {
        imageableType: 'Content',
        type: 'testimony_attestant_photo',
        aspectRatio: 1,
        minWidth: 200,
        minHeight: 200,
        uid: `attestant_photo_${this.uid}`,
      },
      channel: 'CreateImageChannel',
      image: this.testimony?.image?.original,
      isNew: !!this.testimony?.image?.original,
      target: {
        uid: `attestant_photo_${this.uid}`
      }
    }
  }
}
