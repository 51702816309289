import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Image } from '../../vos/image/image';
import {CustomersService} from '../../services/customers/customers.service';
import { HttpClient } from '@angular/common/http';

export class GalleryConfig {
  images?: string[];
  imageObjects?: Image[];
  aspectRatio: string;
  cols = 3;
  gutterSize = '10px';
  instantSelection = true;
}
@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: [ './image-gallery.component.scss' ]
})
export class ImageGalleryComponent implements OnInit {
  selected: number;
  @Input() config: GalleryConfig;
  @Input() selectedImages: any;
  @Output() selectedImagesEmitter = new EventEmitter();
  stockImages: Image[];
  images?: Image[];
  imageFilters = [];
  imageFiltersAvalible = [];
  filter: string;
  modalScrollDistance = 2;
  modalScrollThrottle = 50;
  page = 1;
  per_page = 15;
  reload = true;
  constructor(
    private modal: NzModalRef,
    private customersService: CustomersService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getTags();
    this.getStockImages();
  }


  getTags() {
    const params = {
      'q[name_eq]': 'stock_photos'
    };
    this.customersService.listFrom('tags', params).subscribe(tags => {
      this.imageFiltersAvalible = tags.data[0]['children'];
    });
  }

  getStockImages() {
    const params: Record<string, any> = {
      per_page: this.per_page,
      page: this.page
    };
    if (this.filter) {
      params['q[tags_name_eq]'] = this.filter ;
    }
    this.customersService.listFrom('stock_photos', params).subscribe(images => {
       this.reload = true;
       if (this.page === 1) {
         this.stockImages = images.data;
       } else {
         this.stockImages = [...this.stockImages, ...images.data];
       }
    });
  }
  // select an image
  select(image) {
    this.selectedImagesEmitter.emit(image);
  }

  toggleFilter(checked: boolean, filter: string): void {
    this.page = 1;
    this.filter = '';
    this.imageFilters = [];
    const elem = document.getElementsByClassName('scollable-section')[0];
    elem.scrollTop = 0;
    this.reload = false;
    if (checked) {
      this.imageFilters.push(filter);
      this.filter = filter['name'].toLowerCase();
    }
    this.getStockImages();
  }

  photoTags(tags) {
    return tags.split(',');
  }
  onModalScrollDown() {
    this.page += 1;
    this.getStockImages();
  }
  selectedImage(image){
   const index  = this.selectedImages.indexOf(image);
   return index;
  }


}
