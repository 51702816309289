<div class="container">
    <h3>PropertyType</h3>
    <p>Select a type of property below, then click 'Next' to begin customizing the video.</p>
    <div nz-row nzJustify="start" class="width-60">
    <div (click)="selectProperty(property)" class="commercial-property-container" *ngFor="let property of commercialPropertyTypes">
      <div class="property-item" [ngClass]="{'selected': selectedType === property.value}">
        <img class="property-img" src="../../../assets/commercial_listing-icons/{{property.icon}}" />
      </div>
      <div class="property-title">
        {{property?.title}}
         <span *ngIf="property.subtitle"> ({{property?.subtitle}})</span>
      </div>
    </div>
    </div>
</div>
