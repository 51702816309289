import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent } from 'ngx-useful-swiper';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { EmailTemplateService } from '../../services/email-template/email-template.service';
import { EmailCampaignService } from '../../services/email-campaign/email-campaign.service';
import { EmailContactListService } from '../../services/email-contact-list/email-contact-list.service';
import { EmailSenderIdentityService } from '../../services/email-sender-identity/email-sender-identity.service';
import { EmailDomainService } from '../../services/email-domain/email-domain.service';

import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';

@Component({
  selector: 'app-campaign-flow',
  templateUrl: './campaign-flow.component.html',
  styleUrls: ['./campaign-flow.component.scss']
})
export class CampaignFlowComponent implements OnInit, AfterViewInit {
  @ViewChild('usefulSwiper', { static: false }) usefulSwiper: SwiperComponent;
  campaign = {
    name: '',
    subject: '',
    preheader: '',
    sendgrid_template_id: 0,
    sendgrid_version_id: 0,
    dynamic_template_data: {},
    contacts: []
  };
  templateHtml = {
    htmlOutput: ''
  };
  isNextLoading = false;
  isTemplatFormValid = false
  campaignModel = { campaign_name: '', subject: '', text: '' };
  selectedContacts;
  isNew = true;
  currentPage = 1;
  pagination = {
    limit: 10,
    total: 100,
    page: 1,
    totalPages: 10
  };
  selectedTemplate;
  loading = false;
  error: string;
  styles = [];
  allReqFields = [];
  stepToMove;
  current = 0;
  dataPoints;
  stylesRequested = false;
  selectedMarket;
  marketData;
  selectedAudienceList = [];
  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private message: NzMessageService,
    private router: Router,
    private breadcrumService: BreadCrumService,
    private emailTemplateService: EmailTemplateService,
    private emailCampaignService: EmailCampaignService,
    private marketReportsService: MarketReportsService,
    private emailContactListService: EmailContactListService,
    private emailSenderIdentityService: EmailSenderIdentityService,
    private emailDomainService: EmailDomainService,
  ) { }

  ngAfterViewInit() {

  }

  selectAudience(audienceList) {
    if (!audienceList.target) {
      this.selectedAudienceList = audienceList;
      this.selectedContacts = audienceList.map(c => c.id);
      // this.campaign.contacts = this.campaign.contacts.map(c => c.id);
    } else {
      audienceList.stopPropagation();
    }
  }

  ngOnInit() {
    this.breadcrumService.set_breadcrum();
    this.checkOnboarded();
    this.fetchStyles();
  }

  checkOnboarded() {
    this.loading = true;
    this.authService.getEmailAuthentication()
      .subscribe(res => {
        if (res) {
          const emailAuth = res?.data;
          if (emailAuth && emailAuth.id && emailAuth.user_id_on_provider) {
            this.emailSenderIdentityService.get()
              .subscribe(res => {
                if (!res.data.length) {
                  this.goToOnboarding();
                  this.message?.remove();
                  // this.message.create('error', 'Sender Identity is not added! Please add first');
                } else {
                  this.loading = false;
                }
              }, err => {
                this.goToOnboarding();
              });
          } else {
            this.goToOnboarding();
          }
        }
      }, (err) => {
        this.goToOnboarding();
        return false;
      });
  }

  templateFormChange(isvalid) {
    this.isTemplatFormValid = isvalid;
  }

  goToOnboarding() {
    this.router.navigateByUrl('/email_campaigns/onboarding');
  }
  cancel() {
    // this._location.back();
  }

  selectMarket(market) {
    this.selectedMarket = market;
    this.isNextLoading = true;
    this.getMarketDataPoints();
  }

  fetchStyles() {
    this.loading = true;
    const queryParams = `?page=${this.pagination.page}`;
    if (this.pagination.totalPages > this.pagination.page) {
      this.emailTemplateService.list(queryParams)
        .subscribe(res => {
          this.styles = [...this.styles, ...res.data];
          this.pagination.limit = res['per_page'];
          this.pagination.total = res['total_entries'];
          const limit = this.pagination.limit;
          const totalEntries = this.pagination.total;
          this.pagination.totalPages = Math.ceil(totalEntries / limit);
          this.styles.map(s => {
            s.select = false;
          });
          this.loading = false;
        });
    }
  }

  getTemplate() {
    this.emailTemplateService.show(this.selectedTemplate.template_id, `version_id=${this.selectedTemplate.id}`)
      .subscribe(res => {
        this.selectedTemplate = res?.data;
        this.campaign.subject = this.selectedTemplate.subject;
        this.campaign.preheader = this.selectedTemplate.preheader;
        // this.campaign.dynamic_template_data = res.data.html_content;
      });
  }

  getMarketDataPoints() {
    this.marketReportsService.dataPoints(this.selectedMarket.id)
      .subscribe(res => {
        this.marketData = res;
        this.delayedNext();
      })
  }

  gotoStep(stepNumber) {
    if (this.current > stepNumber) {
      this.current = stepNumber;
    }
  }

  delayedNext() {
    this.isNextLoading = true;
    setTimeout(() => {
      this.current += 1;
      this.isNextLoading = false;
      if (this.current == 4) {
        this.fetchListsAudiences();
      }
    });
  }

  pre(): void {
    this.current -= 1;
  }

  nextSlide() {
    this.usefulSwiper.swiper.slideNext();
  }

  prevSlide() {
    this.usefulSwiper.swiper.slidePrev();
  }

  nextPage() {
    if (this.pagination.totalPages < this.currentPage) {
      this.currentPage++;
      this.fetchStyles();
    }
  }

  didPage(pageIndex) {
    this.pagination.page = pageIndex;
    this.currentPage = pageIndex;
    this.loading = true;
    this.fetchStyles();
  }

  selectStyle(c) {
    this.selectedTemplate = c;
    this.campaign.sendgrid_template_id = this.selectedTemplate.template_id;
    this.campaign.sendgrid_version_id = this.selectedTemplate.id;

    this.getTemplate();
    this.styles.map(s => {
      if (s.id === c.id) {
        s.selected = true;
      } else {
        s.selected = false;
      }
    });
  }

  saveCampaign() {
    this.emailCampaignService.create({ campaign: this.campaign })
      .subscribe(res => {
        this.message.create('success', 'Campaign Created Successfully');
        this.delayedNext();
      }, err => {
        let errorMessage = err.error && err.error.message ? err.error.message : err.error;
        errorMessage = (typeof errorMessage == 'object') ? JSON.stringify(errorMessage) : errorMessage;
        errorMessage = errorMessage?.replace(/[{()}]/g, '');
        this.message?.remove();
        this.message?.create('error', errorMessage);
      });
  }

  gotoCampaigns() {
    this.router.navigateByUrl('/email_campaigns/campaigns');
  }

  fetchListsAudiences() {
    this.loading = true;
    this.campaign.contacts = [];
    this.emailContactListService.audiences(this.selectedContacts)
      .subscribe(audiencesList => {
        audiencesList.forEach(audiences => {
          audiences.forEach(contacts => {
            contacts.data.forEach(contact => {
              if (contact.email) {
                this.campaign.contacts.push({
                  name: `${contact.first_name || ''} ${contact.last_name || ''}`,
                  email: contact.email
                })
              }
            });
          });
        });
        this.loading = false;
      });
  }

  get isNotNext() {
    let valid = true;
    if (this.current == 1 && this.selectedMarket && this.selectedMarket.id) {
      valid = false;
    } else if (this.current == 2) {
      valid = !this.isTemplatFormValid;
    } else if (this.current == 3 && this.selectedContacts && this.selectedContacts.length > 0) {
      valid = false;
    } else if (this.current == 4 && this.campaign.name && this.campaign.subject) {
      valid = false;
    }
    return valid || this.isNextLoading;
  }

  get sendingList() {
    let lists = this.selectedAudienceList || [];
    lists = lists.map(c => c.name);
    lists = lists && lists.length ? lists : ['N/A']
    return lists;
  }
}
