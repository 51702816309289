<div>
  <div *ngIf="config.graphic" class="content-media">
    <div mat-card-image  *ngIf="!isVideo">
      <img [src]="config.graphic" class="internal-content" />
    </div>
    <video [autoplay]="true" class="internal-content" *ngIf="isVideo" controls loop [muted]="true">
      <source [src]="config.graphic" />
    </video>
  </div>
  <h2 class="center-text">{{config?.body}}</h2>
  <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
    <button nz-button nzType="primary" class="action-btn-primary" (click)="open()">{{config.call_to_action_text}}</button>
  </div>
</div>
