import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {ConnectLinkedinService} from '../../services/connect-linkedin/connect-linkedin.service';
import {CustomersService} from '../../services/customers/customers.service';
import {LinkedinPagesService} from '../../services/linkedin-pages/linkedin-pages.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzModalRef} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-linkedin-onboarding-dialog',
  templateUrl: './linkedin-onboarding-dialog.component.html',
  styleUrls: ['./linkedin-onboarding-dialog.component.scss']
})

export class LinkedinOnboardingDialogComponent implements OnInit, OnDestroy {
  loading = false;
  integration;
  linkedPages;
  isDone;
  selectedPage;

  constructor(
    private customerService: CustomersService,
    private linkedinPageService: LinkedinPagesService,
    private notification: NzNotificationService,
    private modal: NzModalRef,
  ) {}
  ngOnInit() {
    this.fetch_linkedin();
  }

  ngOnDestroy(): void {
  }
  loadLinkedinPages(){
  }
  fetch_linkedin() {
    this.customerService.getLinkedin().subscribe((c) => {
      if (c && c.length > 0 && c[0] && c[0].refresh_token_expires_at) {
        this.integration = c[0];
        this.linkedinPageService.list(`?id=${this.integration.id}`).subscribe(res => {

          const pages = res["pages"]?.map(page => {
            page.checked = false;
            return page;
          });
          this.linkedPages  = pages;
        });

      }
    });
  }
  selectOption(selectedPage) {
    this.linkedPages.forEach(page => {
      if (page.id !== selectedPage.id) {
        page.checked = false;
      }
    });
    this.selectedPage = selectedPage;
  }

  connectLinkedinPage() {
    this.loading = true;
    this.linkedinPageService.update({id: this.integration.id, linkedin_page_id: this.selectedPage.id}).subscribe(res => {
      this.notification.create(
        'success',
        'Connected',
        'Linkedin Page is Connected successfully!'
      );
    });
  this.modal.close();
  }
}
