import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Topic } from 'src/app/vos/topic/topic';
import { environment } from 'src/environments/environment';
@Injectable()

export class CustomerProductStylesService extends CustomerResourceService<any> {

  public endpoint = 'customer_content_calendar_styles';
  public data_key = 'customer_content_calendar_style';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Topic, http, authService);
  }
}
