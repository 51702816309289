<div>
  <div>
    <h2>
      General
    </h2>
    <p class="disclaimer">
      Automated posting of these articles to your social media is an additional feature.
      <a [href]="calendlyUrl" target="_blank">Click here </a> to talk to your account manager and set it up.
    </p>
  </div>
  <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
  <div nz-row nzJustify="start">
    <div *ngFor="let new of news" class="tip-card" nz-col 
         [nzSpan]="{ xxl: 6, xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }" 
         [ngClass]="{'home-page': isHomePage}">
      <nz-card nzHoverable class="fixed-card-height">
        <nz-card-meta [nzTitle]="titleTemplate"></nz-card-meta>
        <img alt="example" [src]="new.image_url" class="card-image" [ngClass]="{'home-page': isHomePage}" />
        <p class="card-description line-clamp-3">{{new.summary}}</p>
        <button nz-button nzType="primary" class="card-button" (click)="createCustomContent(new)">
          Post
        </button>
        <a class="article-link" [href]="new.url" target="_blank">View Article</a>
      </nz-card>
      <ng-template #titleTemplate>
        <div class="title-content" (click)="openUrlInNewTab(new.url)">{{new.title}} </div>
      </ng-template>
    </div>
  </div>
</div>
