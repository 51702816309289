import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LandingPageService } from 'src/app/services/landing-page/landing-page.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  videoUrl: string= "";
  name;
  brokerage;

  items = [
    {
      title: "Tom Ferry", rating: 5,
      image: 'https://www.zentap.com/wp-content/uploads/2022/01/Layer-19.png',
      description: "I have said it before I will say it again. You need to be shooting video content to stay relevant in today's market! Check out Zentap's 4 Reasons Real Estate Agents Need To Use Video, which discusses my recent episode on Going ALL IN on Video and much more!"
    },
    {
      title: "Ed D'Ambrosio", rating: 5,
      image: 'https://www.zentap.com/wp-content/uploads/2023/03/origin.jpg',
      description: "With over 35 years of experience in real estate, I have worked with so many different marketing companies but the level of support and value that Zentap provides is second to none. I have been a customer with them for over 5 years and they have helped me elevate my brand and business online and I continue to refer them to agents on my team."
    },
    {
      title: "Tony Giordano", rating: 4.5,
      image: 'https://www.zentap.com/wp-content/uploads/2023/02/tony-giordano.jpg',
      description: "Having a presence and posting relevant content on social media is a MUST for real estate agents to stand out and stay top of mind in today's competitive market. Zentap helps you save time and money and build your brand online. It's a no brainer for agents to succeed."
    }
  ];
  id: string = '';
  private routeSubscription: Subscription;
  private contentSubscription: Subscription;

  constructor(
    private landingPageService: LandingPageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getContent();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.contentSubscription) {
      this.contentSubscription.unsubscribe();
    }
  }

  onVideoPlayed(): void {
    const payload = {
      id: this.id,
      watch_video: true,
    };

    this.landingPageService.analytics(payload).subscribe(
      data => {
      },
      error => {
        console.error('Analytics error:', error);
      }
    );
  }

  getContent() {
    this.contentSubscription = this.landingPageService.get_content(this.id).subscribe(
      data => {
        this.videoUrl = data['video_url'];
        this.name = data['name'];
        this.brokerage = data['brokerage'];
      },
      error => {
        console.error('Get content error:', error);
      }
    );
  }
}
