<div *ngIf="currentStep == 1">
  <div nz-row>
    <div nz-col nzSpan="18" nzOffset="6">
      <h1 class="main-title">{{ isNew ? 'Add New Market' : 'Update Market'}}</h1>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzSpan="12" class="label-font">
      Market Name <br>
    </div>
    <div nz-col  nzSpan="12" class="label-font">
      Property Type
    </div>
  </div>
  <div nz-row>
      <nz-input-group nzSize="large"  nzCompact>
        <input nz-input placeholder="(i.g. City; Neighborhood)" type="text" nzSize="large" style="width:50%;"  [(ngModel)]="marketReport.region_name"/>
        <nz-select nzSize="large" [(ngModel)]="propertyType" style="width:50%;">
          <nz-option *ngFor="let option of propertyTypes" [nzValue]="option.key" [nzLabel]="option.name"></nz-option>
        </nz-select>
      </nz-input-group>
  </div>
  <div nz-row>
      <div nz-col class="label-font">
      Zip Code
      </div>
  </div>
  <div nz-row>
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input nz-input type="text" maxlength="5"
               placeholder="Enter Zip Code" [(ngModel)]="marketReport.location_attributes.zip_code"
               (ngModelChange)="checkForm()"/>
      </nz-input-group>
      <ng-template #suffixButton>
        <button  nz-button nzType="default" nzSize="large" [disabled]="this.error" (click)="searchZip()" nzSearch><i nz-icon nzType="search"></i>Search</button>
      </ng-template>
  </div>
  <div nz-row *ngIf="error" class="error" [innerHTML]="error"></div>
  <br>
  <nz-table [nzLoading]="loading" [nzShowPagination]="false" [nzSize]="'small'" nzNoResult=""
            [nzScroll]="{ y: '300px' }"
            nzTemplateMode>
    <thead>
    <tr>
      <th nzWidth="30">Category</th>
      <th nzWidth="30">{{zipData.PresentMonthYear}}</th>
      <th nzWidth="30">{{zipData.LastMonthYear}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of dataPoints">
      <td  *ngIf="zipData['Title'+data]">{{ zipData['Title'+data] }}</td>
      <td  *ngIf="zipData['Title'+data]">{{ zipData['DataDisplay'+data+'A'] }}</td>
      <td  *ngIf="zipData['Title'+data]">{{ zipData['DataDisplay'+data+'B'] }}</td>
    </tr>
    </tbody>
  </nz-table>
  <br>
  <div nz-row nzAlign="middle" class="nz-mb-15">
    <!-- <p><b>Note:</b> Only primary market posts will be automated.</p>
     -->
     <div class="notice">
      Note: The data is pulled from a third-party vendor. Please review it to be sure it's accurate and provide updates if necessary.
     </div>
  </div>
  <div nz-row nzJustify="space-around" >
      <button nz-button (click)="closeModal()">Cancel</button>
      <button nz-button nzType="primary" [disabled]="this.error" (click)="submit()" nzSearch>{{ isNew ? 'Create' : 'Update'}}</button>
  </div>
</div>
<div *ngIf="currentStep == 2">
    <div nz-row nzJustify="space-between">
      <div nz-col nzSpan="18" nzOffset="4">
        <h1 class="main-title">Market Report {{ isNew ? 'Created' : 'Updated'}}!</h1>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="4">
        <p>Click the 'Done' button to close this window.</p>
      </div>
    </div>
  <div nz-row>
    <div nz-col nzSpan="12" nzOffset="6">
      <div class="social-connect-posted">
        <div class="status-container" >
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div nz-row nzJustify="center">
      <button nz-button nzType="primary" (click)="closeModal()">
        <span>Done</span>
      </button>
  </div>
</div>
