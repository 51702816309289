import moment from 'moment';
import { Resource } from '../resource/resource';
import {Customer} from '../customer/customer';

export class Notification extends Resource {
  /**
   * id
   */
  id?: number;

  /**
   * created_at
   */
  created_at?: moment.Moment;
  parameters?: any;

  /**
   * read status
   */
  opened: string;

  /**
   * notifiable_path
   */
  notifiable_path: string;

  /**
   * notifiable_path
   */
  notifiable_id: number;
  /**
   * group
   */
  group_member_count: number;
  group_member_exists?: boolean;
  group_notification_count: number;
  /**
  * Notification Text
  */
  type: string;
  text: string;
  _icon: string;
  get icon(): string {
    this.setIcon();
    return this._icon;
  }
  private setIcon() {
    let notifi_icon;
    switch (this.type) {
      case 'Lead':
        notifi_icon = 'team';
        break;
      case  'Customer':
        notifi_icon = 'setting';
        break;
      case  'Listing':
        notifi_icon = 'unordered-list';
        break;
      case  'Website':
        notifi_icon = 'layout';
        break;
      case  'DefaultAd':
        notifi_icon = 'solution';
        break;
      default:
        notifi_icon = 'team';
    }
    this._icon = notifi_icon;
  }
}
