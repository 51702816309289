<ng-template #onboarding>
  <div class="main-container" *ngIf="model">
    <div class="registration-container">
      <p class="reg">Registration</p>
      <h2 class="header1">What will your billboard ad look like?</h2>
      <p class="description">Fill in your business information below to start playing with your Dashboard.</p>
      <div nz-row class="registration-tabs">
        <div nz-col [nzLg]="{ span: 13 }" [nzXl]="{ span: 13 }" class="tab-section">
          <nz-tabset [nzSelectedIndex]="currentTab" (nzSelectedIndexChange)="changeIndex($event);" #tabcontainer>
            <!-- [nzDisabled]="(i === 1 && !isPersonalDetailsValid || (i===2 && !isBrandingFormValid || ( i ==2 && !isPersonalDetailsValid"  -->
            <nz-tab *ngFor="let tab of tabs; let i = index" id="{tab+i}" [nzTitle]="titleTemplate" class="reg-tab"
              [nzDisabled]="(i === 1 && !isPersonalDetailsValid) || (i < 2 && model.onboarded) || (i == 2 && !model.onboarded)">
              <ng-template #titleTemplate>
                <span class="tab">
                  {{i + 1}}.
                  <i nz-icon [nzType]="tab.icon"></i>{{ tab.name }}
                </span>
              </ng-template>
              <div class="tab-content" [hidden]="currentTab !== 0">
                <form #detailForm="ngForm" nz-form>
                  <div nz-row nzGutter="16">
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label [nzSm]="24" [nzXs]="24" class="input-title" nzFor="display_name" nzRequired>
                            Display Name</nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">Shown on all
                            your
                            videos, flyers & more!</nz-form-label>
                          <nz-form-control>
                            <input nz-input required type="text" id="display_name" [(ngModel)]="model.display_name"
                              name="display_name" (blur)="checkWhiteSpaces()">
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label [nzSm]="24" [nzXs]="24" class="input-title" nzFor="brokerage" nzRequired>
                            Brokerage</nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">What company do
                            you work for?</nz-form-label>
                          <nz-form-control nzErrorTip="Please input your brokerage!">
                            <input placeholder="Brokerage" required nz-input (blur)="checkWhiteSpaces()"
                              [(ngModel)]="model.brokerage" name="brokerage" (ngModelChange)="changeBrokerage()"
                              [nzAutocomplete]="auto" />
                            <nz-autocomplete #auto>
                              <nz-auto-option *ngFor="let brokerage of brokerageList" [nzValue]="brokerage.title" >
                                {{brokerage.title}}
                              </nz-auto-option>
                            </nz-autocomplete>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>

                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="preferred_title" nzRequired>Job Title
                          </nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">Realtor?
                            Superman? Batman? Tell us!</nz-form-label>
                          <nz-form-control>
                            <input placeholder="Enter your title" nz-input [(ngModel)]="model.preferred_title"
                              (blur)="checkWhiteSpaces()" name="preferred_title"
                              (input)="onChangetitle($event.target?.value)" id="preferred_title"
                              [nzAutocomplete]="autoJob" />
                            <nz-autocomplete [nzDataSource]="filteredOptions" #autoJob> </nz-autocomplete>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="display_email" nzRequired>Business Email
                          </nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">How can your
                            prospects contact you?</nz-form-label>
                          <nz-form-control nzErrorTip="Please input your display email!">
                            <input nz-input type="email" class="form-control" id="display_email"
                              (blur)="checkWhiteSpaces()" [(ngModel)]="model.display_email" name="display_email"
                              placeholder="Display Email" />
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="display_phone" nzRequired>Display Number
                          </nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">Your main
                            business contact number.</nz-form-label>
                          <nz-form-control nzErrorTip="Please input your display phone!">
                            <input nz-input type="tel" class="form-control" id="display_phone"
                              (blur)="checkWhiteSpaces()" [(ngModel)]="model.display_phone" name="display_phone"
                              required placeholder="Display Phone" />
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <input nz-input placeholder="Display Number" /> -->
                      </div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="office_phone">Office Phone
                          </nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">Your office
                            contact number.<br> ex: (123) 456-7890 </nz-form-label>
                          <nz-form-control nzErrorTip="Please input your display phone!">
                            <input nz-input type="tel" class="form-control" id="office_phone"
                              (blur)="checkWhiteSpaces()" [(ngModel)]="model.office_phone" name="office_phone"
                              placeholder="Display Phone" />
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <input nz-input placeholder="Display Number" /> -->
                      </div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="website_url">Website</nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">
                            Your personal website domain.</nz-form-label>

                          <nz-form-control>
                            <input nz-input type="text" id="website_url" [(ngModel)]="model.website_url"
                              name="website_url" placeholder="Website" (blur)="checkWhiteSpaces()">
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <input nz-input placeholder="Website" /> -->
                      </div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="license_number">License #</nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle license-label">
                            Your real
                            estate
                            license number.</nz-form-label>
                          <nz-form-control nzErrorTip="Please input your License Number!">
                            <input type="text" nz-input type="text" id="license_number" (blur)="checkWhiteSpaces()"
                              [(ngModel)]="model.license_number" name="license_number" placeholder="License #" />
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <input nz-input placeholder="License #" /> -->
                      </div>
                    </div>
                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="credential">Designations</nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24"
                            class="input-subtitle designation-label">Your personal
                            agent
                            or brokerage designations.</nz-form-label>
                          <!-- <input nz-input placeholder="Credentials" /> -->
                          <nz-form-control>
                            <nz-select id="credential" [(ngModel)]="model.credential" name="credential"
                              nzMode="multiple" nzPlaceHolder="Select designations">
                              <nz-option *ngFor="let cred of credentials" [nzLabel]="cred" [nzValue]="cred"></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>


                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="credential" nzRequired>Primary Region</nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24"
                                         class="input-subtitle designation-label">This will appear in custom captions we create for you. ex: Looking to buy or sell in “Primary market” or surrounding areas?</nz-form-label>
                          <nz-form-control>
                            <input nz-input type="text" id="primary_region" [(ngModel)]="model.primary_region"
                                   required  name="primary_region" placeholder="Primary Region">
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>

                    <div nz-col class="gutter-row" nzSpan="12">
                      <div class="gutter-box input-box">
                        <nz-form-item class="mar-b">
                          <nz-form-label class="input-title" nzFor="credential" nzRequired>Region Description
                          </nz-form-label>
                          <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle designation-label">Please choose
                             which best describes your primary region.</nz-form-label>
                          <!-- <nz-form-control>
                            <input nz-input type="text" id="primary_region" [(ngModel)]="model.primary_region"
                                   required  name="primary_region" placeholder="Primary Region">
                          </nz-form-control> -->
                          <nz-form-control nzErrorTip="Please input your region!">
                            <nz-select  [(ngModel)]="model.region.id" >
                              <nz-option *ngFor="let region of regionList" [nzValue]="region.id" [nzLabel]="region.name"></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                    <!-- <div nz-col class="gutter-row" nzSpan="24">
                      <div class="gutter-box input-box">
                        <nz-form-label class="input-title" nzFor="slogan">Slogan</nz-form-label>
                        <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">Your
                          slogan phrase.</nz-form-label>
                        <nz-form-item class="mar-b">
                          <nz-form-control>
                            <input nz-input type="text" id="slogan" [(ngModel)]="model.slogan" name="slogan"
                            placeholder="Slogan" maxlength="65">
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div> -->
                    <div nz-col nzSpan="12">
                      <div class="gutter-box input-box">
                        <label nz-checkbox name="part_of_nar" [(ngModel)]="model.settings.part_of_nar">Part of
                          NAR</label>
                      </div>
                    </div>

                    <!-- <div nz-col class="gutter-row" nzSpan="12" *ngIf="model.settings.part_of_nar">
                        <div class="gutter-box">
                          <nz-form-item class="mar-b">
                            <nz-form-label [nzSm]="24" [nzXs]="24" class="input-title" nzFor="nar_license" nzRequired>
                              NAR License</nz-form-label>

                            <nz-form-control>
                              <input nz-input required type="text" id="nar_license" [(ngModel)]="model.nar_license"
                                name="nar_license">
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div> -->
                  </div>
                  <div nz-row nzJustify="center" class="input-box action-btn">
                    <button nz-button nzType="primary" (click)="nextTab()"
                      [disabled]="!detailForm.form.valid || !isPersonalDetailsValid">Next</button>
                  </div>
                </form>
              </div>

              <div class="tab-content" [hidden]="currentTab !== 1">
                <div nz-row nzGutter="16">
                  <div nz-col class="gutter-row" nzSpan="12">
                    <div class="gutter-box">
                      <span class="input-title">Headshot Photo</span>
                      <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">Show us those
                        pearly
                        whites!</nz-form-label>
                      <div class="image-selector">
                        <app-image-handler [content]="model" [imageable_type]="'headshot'" [config]="headshotConfig"
                          (imageObject)="setHeadshot($event)"></app-image-handler>
                      </div>
                    </div>
                  </div>
                  <div nz-col class="gutter-row" nzSpan="12">
                    <div class="gutter-box">
                      <span class="input-title">Business Logo</span>
                      <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">A clear high-res
                        logo
                        of your brokerage.</nz-form-label>
                      <div class="image-selector">
                        <app-image-handler [fullWidth]="true" [content]="model" [imageable_type]="'logo'"
                          [config]="logoConfig" (imageObject)="setLogo($event)"></app-image-handler>
                      </div>

                    </div>
                  </div>
                </div>
                <div nz-row>
                  <span class="input-title">Brand Colors</span>
                  <nz-form-label nzNoColon="true" [nzSm]="24" [nzXs]="24" class="input-subtitle">
                    The colors will be used to brand your content and you can change them from the profile section.
                  </nz-form-label>
                </div>

                <div nz-row nzGutter="16">
                  <div nz-col class="gutter-row" nzSpan="12">
                    <div class="gutter-box">
                      <span class="input-title">Background Color</span>
                      <input class="color-picker-input" [value]="model.settings.bg_color"
                        [style.background]="model.settings.bg_color" [cpAlphaChannel]="'disabled'"
                        [cpOutputFormat]="'hex'" [(colorPicker)]="model.settings.bg_color" />
                      <nz-form-item appearance="fill" class="form-field color-field">
                        <!-- <nz-form-label for="bg_color">Background</nz-form-label> -->
                        <nz-form-control>
                          <input nz-input type="text" id="bg_color" [(ngModel)]="model.settings.bg_color"
                            name="bg_color" [value]="model.settings.bg_color"
                            pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>

                  <div nz-col class="gutter-row" nzSpan="12">
                    <div class="gutter-box">
                      <span class="input-title">Text Color</span>
                      <input class="color-picker-input" [value]="model.settings.text_color"
                        [style.background]="model.settings.text_color" [cpAlphaChannel]="'disabled'"
                        [cpOutputFormat]="'hex'" [(colorPicker)]="model.settings.text_color" />
                      <nz-form-item appearance="fill" class="form-field color-field">
                        <!-- <nz-form-label for="text_color">Text Color</nz-form-label> -->
                        <nz-form-control>
                          <input nz-input type="text" id="text_color" [(ngModel)]="model.settings.text_color"
                            name="text_color" [value]="model.settings.text_color"
                            attern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                </div>
                <div nz-row nzJustify="center" class="input-box action-btn">
                  <button nz-button nzType="primary" (click)="submitBranding(false)"
                    [disabled]="!isBrandingFormValid || !isCustomerDetailsValid">Next!</button>
                </div>
              </div>

              <div class="tab-content" [hidden]="currentTab !== 2">
                  <div>
                    <nz-form-item>
                      <nz-form-label [nzSm]="6" [nzXs]="24"  nzFor="email">Team Photo</nz-form-label>
                      <app-image-handler [content]="model" (imageObject)="setTeamPhoto($event)" [imageable_type]="'team_photo'" [config]="teamPhotoConfig"></app-image-handler>
                    </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24"  nzFor="email">Team Name</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your Team name!">
                          <input nz-input  id="email" [(ngModel)]="model.team_name" />
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber">Team Phone</nz-form-label>
                        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your Team phone!">
                          <input nz-input id="phone"  [(ngModel)]="model.team_phone"/>
                        </nz-form-control>
                      </nz-form-item>
                  </div>
                <div nz-row nzJustify="center" class="input-box action-btn" >
                  <button nz-button nzType="secondary" (click)="submitBranding(true)"
                          [disabled]="!isBrandingFormValid || !isCustomerDetailsValid">Skip!</button>
                  <button nz-button nzType="primary" (click)="submitBranding(true)"
                          [disabled]="!isBrandingFormValid || !isCustomerDetailsValid" style="margin-left: 20px">Ready to Go!</button>
                </div>
              </div>

            </nz-tab>
          </nz-tabset>
        </div>
        <div nz-col [nzLg]="{ span: 10 }" [nzXl]="{ span: 10 }" class="display-info">
          <div class="img-cont">
            <div class="top-img"></div>
          </div>

          <div class="back-shadow">
            <div class="img" [style.color]="model.settings.text_color"
              [style.background-color]="model.settings.bg_color">

              <div nz-row class="banner-text" style="padding-top: 20px">
                <div nz-col nzSpan="8" class="img-container">
                  <img [src]="model.image.original" alt="" class="headshot-image"
                    *ngIf="model.image && model.image.original">
                </div>
                <div nz-col nzSpan="8" class="text">
                  <p class="text-spacing">{{model.brokerage}}</p>
                  <p>{{model.display_name}}</p>
                  <p class="sub">{{model.preferred_title}}</p>
                  <p class="sub text-spacing">{{model.credential}}</p>
                  <p class="text-spacing">{{model.display_phone}}</p>
                  <p class="sub">{{model.website_url}}</p>
                  <p class="sub">{{model.display_email}}</p>
                  <p class="sub" *ngIf="model.license_number">License #{{model.license_number}}</p>
                </div>
                <div nz-col nzSpan="8" class="img-container">
                  <img [src]="model.logo.original" alt="" class="logo-image" *ngIf="model.logo && model.logo.original">
                </div>
              </div>
              <img class="nar-image" src="assets/images/NAR.png" alt="" *ngIf="model.settings.part_of_nar">
            </div>
          </div>
          <div class="bottom-img-cont">
            <div class="bottom-img"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="onboarding"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="onboarding"></ng-container>
</ng-template>
