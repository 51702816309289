import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Image } from '../../vos/image/image';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ListingImageGalleryComponent } from '../listing-image-gallery/listing-image-gallery.component';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { environment } from '../../../environments/environment';
import { GlobalsService } from '../../services/globals/globals.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { OnChangeType } from 'ng-zorro-antd/core/types';
import { ImageConfig } from 'src/app/models/interfaces/modals/image-config';

@Component({
  selector: 'app-images-reorder',
  templateUrl: './images-reorder.component.html',
  styleUrls: ['./images-reorder.component.scss']
})
export class ImagesReorderComponent implements OnInit, OnChanges {
  @Input() imageSize = '960x540 minimum';
  @Input() images: Image[];
  @Input() maxCount: number = 5;
  @Input() type: string;

  @Output() imagesChange = new EventEmitter();
  @Input() imageable_type;
  show_gallery = false;
  @Input() content: any;
  imageLoading = false;
  constructor(
    private modalService: NzModalService,
    private cableService: ActionCableService,
    private globalsService: GlobalsService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.setImages();
    // decide wether to show gallery
    this.show_gallery = this.isCustomer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxCount?.currentValue !== changes.maxCount?.previousValue) {
      this.setImages();
    }
  }

  setImages() {
    // Set empty images to null and ensure array size is
    // at least 5
    for (let i = 0; i < 5; i++) {
      const uid = `${this.imageable_type}_${this.globalsService.randomString()}`;
      if (!this.images[i]) {
        const image = new Image();
        image.uid = uid;
        image.changed = false;
        this.images[i] = image;
      } else {
        this.images[i].uid = uid;
        this.images[i].changed = false;
      }
    }
  }
  selectFromGallery(index: number) {
    const configs = {
      nzFooter: null,
      nzData: {
        config: {
          aspectRatio: '2:1',
          cols: 3,
          gutterSize: '10px',
          instantSelection: true
        },
        images: this.images
      },
      nzWidth: '75%'
    };
    configs['nzContent'] = this.isCustomer() ? ImageGalleryComponent : ListingImageGalleryComponent;
    const modal = this.modalService.create(configs);

    modal.afterClose.subscribe(image => {
      if (image) {
        if (this.isCustomer()) {
          this.uploadStock(image, index);
        } else {
          this.swap(image, index);
        }
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);

    // update indexes
    this.images.map((image, index) => {
      if (image) {
        if (image.order !== index) {
          image.order = index;
          image.changed = true;
        }
      }
    });
  }

  moveRight(imageIndex) {
    let currentIndex = imageIndex;
    const previousIndex = imageIndex;
    if (imageIndex < 4) {
      currentIndex = currentIndex + 1;
    } else {
      currentIndex = 0;
    }
    moveItemInArray(this.images, previousIndex, currentIndex);

    // update indexes
    this.images.map((image, index) => {
      if (image) {
        if (image.order !== index) {
          image.order = index;
          image.changed = true;
        }
      }
    });
  }
  moveLeft(imageIndex) {
    let currentIndex = imageIndex;
    const previousIndex = imageIndex;
    if (imageIndex > 0) {
      currentIndex = currentIndex - 1;
    } else {
      currentIndex = 4;
    }
    moveItemInArray(this.images, previousIndex, currentIndex);

    // update indexes
    this.images.map((image, index) => {
      if (image) {
        if (image.order !== index) {
          image.order = index;
          image.changed = true;
        }
      }
    });
  }

  upload(asset, uid) {
    if (!asset) { return; }
    const newImage = <Image>asset;
    const index = this.images?.findIndex(x => x.uid === uid);
    newImage.loading = false;
    newImage.order = index;
    newImage.stock_photo_id = null;
    newImage.changed = true;
    this.images[index] = newImage;
  }
  openImageUploader(i) {
    const uid = `${this.imageable_type}_${this.globalsService.randomString()}`;
    const image = new Image();
    image.uid = uid;
    image.changed = false;
    const index = this.images.length;
    this.images[index] = image;
    this.openImageDialog(this.images[index]);
  }
  openImageDialog(image, step?) {
    // if (image.order) {
    //   const uid = `Customer_${this.globalsService.randomString()}`;
    //   image.uid = uid;
    //   this.images[image.order].uid = uid;
    // }
    let aspectRatio = this.isCustomer() ? 2.83 : 16 / 9
    const isAnnouncementVideo = this.type === 'announcement_video';
    if(isAnnouncementVideo) {
      aspectRatio = 1;
    }

    this.setImageLoadingEnd(true);
    const modal = this.modalService.create<ImageCropperDialogComponent, ImageConfig>({
      nzContent: ImageCropperDialogComponent,
      nzData: {
        images: this.images,
        config: {
          content: this.content,
          imageableType: this.imageable_type,
          order: image.order,
          type: this.type || 'listing',
          branding: this.imageable_type === 'Customer' ? true : false,
          aspectRatio,
          minWidth: 275,
          minHeight: 105,
          idealWidth: 1280,
          idealHeight: 720,
          requiredImages: 5,
          step
        },
      },
      nzFooter: null,
      nzWidth: '80%',
      nzStyle: {
        top: '24px'
      }
    });
    // minWidth: this.isCustomer() ? 820 : 960,
    // minHeight: this.isCustomer() ? 312 : 540,
    // idealWidth: this.isCustomer() ? 1640 : 1920,
    // idealHeight: this.isCustomer() ? 624 : 1080
    modal.afterClose.subscribe(response => {
      if (response) {
        // image.order = respone.order
        // image.loading = true;
        // this.ImageListener(image.uid);
        // response.forEach(img => {
        // });

        for (const [i, value] of response?.entries()) {
          this.images[i] = value;
        }
        // this.images = response;
        this.setImageLoadingEnd(false);
        this.imagesChange.emit(this.images);
      } else {
        this.setImageLoadingEnd(false);
      }
      if (this.imageable_type === 'Customer' && response && response?.entries()) {
        this.authenticationService.refresh()
          .subscribe(res => {
          })
      }
    });
  }

  ImageListener(uid) {
    const channel: Channel = this.cableService.cable(environment.wss_url).channel('CreateImageChannel', { uid: uid });
    channel.received().subscribe(response => {
      if (response) {
        const image = JSON.parse(response.data);
        this.upload(image, uid);
      }
      this.setImageLoadingEnd(false);
    });
  }

  isCustomer() {
    return this.imageable_type === 'Customer';
  }

  isAnnouncement() {
    return this.imageable_type === 'Announcement';
  }

  isListing() {
    return this.imageable_type === 'Listing';
  }

  swap(extra_image, index) {
    const old_image = this.images[index];
    const extra_image_index = this.images?.findIndex(image => image.id === extra_image.id);

    // set extra image order
    old_image.order = null;
    extra_image.order = index;

    // mark images as changed
    old_image.changed = true;
    extra_image.order = true;

    // swap images from array
    this.images[index] = extra_image;
    this.images[extra_image_index] = old_image;
  }

  uploadStock(stock_photo, index) {
    const image = this.images[index];

    image.stock_photo_id = stock_photo?.id;
    image.thumb = stock_photo?.thumb;
    image.original = stock_photo?.original;
    image.large = stock_photo?.large;
    image.order = index;
    image.changed = true;
  }
  setImageLoadingEnd(imageLoading) {
    this.globalsService.imageLoadEnd(imageLoading);
  }
}
