<div nz-row class="login-form-first-div">
  <div class="login-form-second-div">
    <div nz-row class="flex-layout">
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-form">
        <div>
          <img width="200px"  src="assets/logos/blue-logo.png" />
          <h2  class="sign-in">Confirm Your Account</h2>
          <p class="confirmation-expired" *ngIf="!resend">Looks like your confirmation link expired.<br> Please send the confirmation email again.</p>
          <p class="confirmation-expired" *ngIf="resend">Confirm your email to access your account.<br> Please send the confirmation email again.</p>
          <div class="login-form ng-tns-c360-0 ant-form ng-untouched ng-pristine ng-invalid ant-form-horizontal">
            <nz-input-group nzPrefixIcon="mail" [nzCompact]="true">
              <input nz-input name="email" required type="email" id="email" placeholder="Email" [(ngModel)]="email" />
            </nz-input-group>
            <button nz-button class="resend-button login-button" style="margin-top: 50px;" nzType="primary" size="large" (click)="resendConfirmation()" [disabled]="resendWait > 0">
              <span *ngIf="resendWait === 0">Send confirmation email</span>
              <span *ngIf="resendWait > 0" [style.fontSize]="'12px'">Try again in {{resendWait}}s</span>
            </button>
          </div>
        </div>
      </div>
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-img second-bg">
        <img class="login-form-forgot" class="side-image" src="assets/images/media.png" />
      </div>
    </div>
  </div>
</div>
