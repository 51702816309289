import {
  Injectable
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GlobalsService {
  private isEmailCampaignAllowed = false
  private isGMBAllowed = false

  private emailCustomers = [
    1, 8, 17378, 17899, 848, 17913, 17481, 17983, 4416, 6922, 14367,
    3179, 17492, 13214, 17857, 15815, 17554, 848, 15165,
    1088, 1454, 1455, 18093, 16855, 14636, 16485, 1359, 2548, 14645,
    15991, 17847, 16341, 17480, 17458, 16742, 16753, 16199, 16296,
    16586, 16662, 16458, 18244, 16867, 16311, 15771, 17302,
    17400, 15692, 16815, 17183, 17207, 17227, 17248, 16541,
    17565, 16262, 17722, 17793, 16830, 16834, 18125, 17972, 16672, 16759, 17619,
    17218, 16745, 18104, 15818, 17383, 16927, 17835, 18117, 17673, 17087,
    17448, 16359, 16161, 18114, 16984, 16851, 16946, 13663
  ];

  private credentials = [
    'ABR®',
    'ALC',
    'RLI',
    'CCIM',
    'CDPE',
    'CIPS',
    'CPM®',
    'CRB',
    'CRS',
    'CRE®',
    'GAA',
    'GREEN',
    'GRI',
    'PMN',
    'RCE',
    'RAA',
    'SRS',
    'SIOR',
    'SRES®',
    'AHWD',
    'BPOR',
    'e-PRO®',
    'MRP',
    'PSA',
    'RENE',
    'RSPS',
    'SFR®',
    'MilRES',
    'CNE®',
    'ALHS',
    'CLHMS',
    'CRP',
    'ABR',
    'PPS',
    'PMN',
    'CRMS',
    'CBR®',
    'CIRE',
    'RELO',
    'Cx',
    'MBA',
    'LLB',
    'LLM',
    'CDRS',
    'CREN',
    'CHMS',
    'CSRS'
  ];

  private colors: string[] = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#FFFF99',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];
  private titles = [
    'Realtor®',
    'REALTOR®',
    'REALTORS®',
    'Associate Broker',
    'Broker',
    'Brokers',
    'Broker/Owner',
    'Broker/Realtors',
    'Broker Associate',
    'Executive Broker',
    'Executive Assistant',
    'Homeowner Advocate',
    'Licensed Real Estate ',
    'Managing Broker',
    'Mortgage Broker',
    'Salesperson',
    'Real Estate Professional',
    'Real Estate Agent',
    'Real Estate Broker',
    'Real Estate Salesperson',
    'Real Estate Consultant',
    'Real Estate Associate Broker',
    'Real Estate Advisor',
    'Property Manager',
    'President CEO',
    'Team Leader',
    'Investor'
  ];

  private listingTemplates = [
    {
      title: 'Listings',
      children: [
        'New Listing (Residential)',
        'Recent Sale of Agent',
        'Recent Sale of Another Agent',
        'Open House',
      ]
    },
    {
      title: 'Market',
      children: ['Market Updates']
    },
    {
      title: 'Reviews',
      children: [
        'New Review Spotlight',
        'Request Google Review (Include QR code)',
      ]
    },
    {
      title: 'Referrals',
      children: ['Referrals']
    },
    {
      title: 'Buyers',
      children: ['Buyers Outreach']
    },
    {
      title: 'Sellers',
      children: ['Sellers Outreach']
    },
  ];

  private templates = [
    'Cold Email Outreach',
    'Listings Writer',
    'Buyer Preparation',
    'Real Estate Dictionary'
  ];
  private languages = [
    'English',
    'Spanish',
    'French',
    'German',
    'Chinese',
    'Japanese',
    'Korean',
    'Russian',
    'Arabic',
    'Portuguese',
    'Italian',
    'Dutch',
    'Swedish',
    'Hindi',
    'Turkish',
    'Greek',
    'Hebrew',
    'Polish',
    'Finnish',
    'Danish',
    'Norwegian',
    'Romanian',
    'Czech'
  ];
  private voiceTones = [
    "Formal",
    "Caring",
    "Informal",
    "Professional",
    "Casual",
    "Creative",
    "Technical",
    "Humorous",
    "Persuasive",
    "Friendly",
    "Authoritative",
    "Instructive",
    "Objective",
    "Dry"
  ];
  private writingStyles = [
    "Formal",
    "Informal",
    "Academic",
    "Technical",
    "Creative",
    "Narrative",
    "Persuasive",
    "Journalistic",
    "Instructional",
    "Conversational",
    "Humorous",
    "Poetic",
    "Professional",
    "Casual",
    "Legal",
    "Scientific",
    "Business",
    "Medical",
  ];
  private swiperOptions = {
    slidesPerView: 7,
    initialSlide: 0,
    spaceBetween: 8,
    slidesPerGroup: 7,
    allowTouchMove: false,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1600: {
        slidesPerView: 6,
        spaceBetween: 10,
        slidesPerGroup: 6
      },
      1400: {
        slidesPerView: 5,
        spaceBetween: 10,
        slidesPerGroup: 5
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 10,
        slidesPerGroup: 4
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 3
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
        slidesPerGroup: 2
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
        slidesPerGroup: 1
      }
    }
  };
  private phoneNumber = '(310) 997-4142';
  private email = 'support@zentap.com';
  private newCardConfig = {
    id: 'new',
    title: 'CREATE',
    actionText: '',
    icon: 'fas plus',
    link: '',
    colors: {
      bg: '#f4faff',
      text: '#326771',
      textSelected: '#f4faff'
    },
    queryParams: {
      name: ''
    }
  };
  private teamProducts = [
    {
      "id": 114,
      "name": "team_cover_photos",
      "category": "content",
      "available": true,
      "additional_service": false,
      "abbreviation": "SB",
      "tier": "paid",
      "parent_type": [
        "customer"
      ],
      "icon": "",
      "locked": false,
      "image": "https://universal-promote.s3-us-west-1.amazonaws.com/icons/icon_StaticBanner.svg",
      "featured": true,
      "rank": null,
      "description": "Improve your social media profile with a cover photo.",
      "path": "team_cover_photos/new"
    },
    {
      "id": 2,
      "name": "marketing_videos",
      "category": "content",
      "available": true,
      "additional_service": false,
      "abbreviation": "MV",
      "tier": "paid",
      "parent_type": [
        "customer"
      ],
      "icon": "",
      "locked": false,
      "image": "https://universal-promote.s3-us-west-1.amazonaws.com/icons/icon_MarketingVideo.svg",
      "featured": true,
      "rank": 1,
      "external_link": "",
      "description": "Promote more business with a call to action in this commercial about YOU.",
      "path": "team_marketing_videos/new"
    }
  ]

  private contentTabs = [{
    name: 'Listings',
    type: 'Listing',
    display: 'Listing'
  },
  {
    name: 'Marketing',
    type: 'Customer',
    display: 'Marketing'
  },
  // { name: 'Banner', type: 'Banner',  display: 'Banner'},
  {
    name: 'Market Reports',
    type: 'MarketReport',
    display: 'Market Report'
  }
  ];

  private receiptCopies = {
    'local_market_videos': {
      header: `Your video is on it’s way!`,
      caption: `Make more content, or view this market report details by clicking 'View Details'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'single_data_snapshot': {
      header: `Your flyer is on it’s way!`,
      caption: `Make more content, or view this market's insights by clicking 'View Details'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'email_newsletter': {
      header: `Your Email Newsletter is on!`,
      caption: `Congrats! Your email will be sent out once a month on the day & time you specified. To view email details, click View Details.`,
      image: `mail.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'listing_video': {
      header: `Your video is on it’s way!`,
      caption: `Make more content, or view this listing’s details by clicking 'View Details'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'comparative_market_analysis': {
      header: `Your video is on it’s way!`,
      caption: `Make more content, or view this listing’s details by clicking 'View Details'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'listing_flyer': {
      header: `Your flyer is on it’s way!`,
      caption: `Make more content, or view this listing’s details by clicking 'View Details'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'listing_image': {
      header: `Your flyer is on it’s way!`,
      caption: `Make more content, or view this listing’s details by clicking 'View Details'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'marketing_video': {
      header: `Your video is on it’s way!`,
      caption: `Make more content, or close this window by clicking 'Close Window'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'View Details'
    },
    'marketing_videos': {
      header: `Your video is on it’s way!`,
      caption: `Make more content, or close this window by clicking 'Close Window'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'Make More'
    },
    'branded_infomercials': {
      header: `Your Branded Informercial is on it’s way!`,
      caption: `Make more content, or close this window by clicking 'Close Window'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'Make More'
    },
    'social_media_content' :{
      header: `Your Social media content is on it’s way!`,
      caption: `Make more content, or close this window by clicking 'Close Window'.`,
      image: `content.png`,
      close: 'Maybe Later',
      linkText: 'Make More'
    },
    'website': {
      header: `Your website is ready to go!`,
      caption: `Make another website, or close this window clicking 'Close Window'.`,
      image: `content.png`,
      close: 'Close Window',
      linkText: 'Make More'
    },
    'content': {
      header: `Your video is on it’s way!`,
      caption: `Make more content, or close this window by clicking 'Close Window'.`,
      image: `content.png`,
      close: 'Close Window',
      linkText: 'Make More',
    },
    'listing_flyers': {
      header: `Your Listing flyer is on it’s way!`,
      caption: `Make more content, or close this window by clicking 'Close Window'.`,
      image: `content.png`,
      close: 'Close Window',
      linkText: 'Make More',
    },
  };
  private imagePending = false;

  private commercialPropertyTypes = [{
    icon: 'icon_OfficeSale.png',
    title: 'Office',
    subtitle: 'Sale',
    value: 'office_sale',
    fields: [
      'parking',
      // 'building_class',
      'sq_ft',
      'year_build',
      'occupancy',
      'tenancy',
      'lot_size',
      'lot_size_type',
      'price',
      'price_per_sqft'
    ]
  },
  // {
  //   icon: 'icon_OfficeLease.png',
  //   title: 'Office',
  //   subtitle: 'Lease',
  //   value: 'office_lease',
  //   fields: [
  //     'sq_ft',
  //     'lease_rate',
  //     'units',
  //     'unit_mix',
  //     'occupancy'
  //   ]
  // },
  {
    icon: 'icon_IndustrialSale.png',
    title: 'Industrial',
    subtitle: 'Sale',
    value: 'industrial_sale',
    fields: [
      'parking',
      // 'building_class',
      'sq_ft',
      'year_build',
      'occupancy',
      'tenancy',
      'lot_size',
      'lot_size_type',
      'price',
      'price_per_sqft'
    ]
  },
  // {
  //   icon: 'icon_IndustrialLease.png',
  //   title: 'Industrial',
  //   subtitle: 'Lease',
  //   value: 'industrial_lease',
  //   fields: [
  //     'year_build',
  //     'occupancy',
  //     'docks'
  //   ]
  // },
  {
    icon: 'icon_RetailSale.png',
    title: 'Retail',
    subtitle: 'Sale',
    value: 'retail_sale',
    fields: [
      'parking',
      // 'building_class',
      'sq_ft',
      'year_build',
      'occupancy',
      'tenancy',
      'lot_size',
      'lot_size_type',
      'price',
      'price_per_sqft'
    ]
  },
  // {
  //   icon: 'icon_RetailLease.png',
  //   title: 'Retail',
  //   subtitle: 'Lease',
  //   value: 'retail_lease',
  //   fields: [
  //     'year_build',
  //     'lease_rate',
  //     'units',
  //     'unit_mix',
  //     'occupancy',
  //     'building_class'
  //   ]
  // },
  {
    icon: 'icon_MultiFamily.png',
    title: 'Multi Family',
    subtitle: '',
    value: 'multi-family',
    fields: [
      'parking',
      // 'building_class',
      'sq_ft',
      'year_build',
      'occupancy',
      'tenancy',
      'lot_size',
      'lot_size_type',
      'price',
      'price_per_sqft',
      'pro_forma_cap_rate'
    ]
  },
  {
    icon: 'icon_LandLot.png',
    title: 'Land',
    subtitle: '',
    value: 'LAND',
    fields: [
      // 'year_build',
      // 'tenancy',
      'lot_size',
      'lot_size_type',
      'price',
    ]
  },

  ];

  private createContentCrads = [
    {
      title: 'Listings',
      link: 'create_content/listings',
      image: 'listing_content.png',
      colors: {
        bg: '#30d289',
        text: 'white',
        bgSelected: '#18754c',
        textSelected: 'white'
      },
      description: 'Promote your listings with a variety of different types of content.',
    },
    {
      title: 'Marketing',
      link: 'create_content/marketing',
      image: 'marketing_content.png',
      colors: {
        bg: '#30d289',
        text: 'white',
        bgSelected: '#18754c',
        textSelected: 'white'
      },
      description: 'Advertise your business and services to your online audience.',
    },
    {
      title: 'Informative',
      link: 'create_content/informative',
      image: 'informative_content.png',
      colors: {
        bg: '#30d289',
        text: 'white',
        bgSelected: '#18754c',
        textSelected: 'white'
      },
      description: 'Keep your prospects informed with the power of data-driven content.',
    }
  ]
  // private imageType; // Image Type , FILE: image-handler.component.ts[string]
  // private content; // Image Handler Content FILE: image-handler.component.ts[object]
  // private config; // Image Cropper Config FILE: image-cropper.component.ts[object]

  constructor() { }

  getCredentials(): string[] {
    return this.credentials.sort();
  }
  getTitles(): string[] {
    return this.titles;
  }
  getPhoneNumber(): string {
    return this.phoneNumber;
  }
  getEmail(): string {
    return this.email;
  }
  getNewCard(type, product) {
    this.newCardConfig.link = type === 'listing' ? 'listings/new' : 'my_markets/new';
    this.newCardConfig.actionText = `NEW ${this.normalizeTitle(type)}`;
    this.newCardConfig.queryParams.name = product;
    return this.newCardConfig;
  }
  getContentTabs() {
    return this.contentTabs;
  }
  normalizeTitle(name) {
    return name ? name
      .split('_')
      .join(' ')
      .capitalizeAll() : '';
  }
  getSwiperOptions() {
    return this.swiperOptions;
  }

  getReceipt() {
    return this.receiptCopies;
  }

  getColors() {
    return this.colors;
  }

  getTemplates(): string[] {
    return this.templates;
  }

  getListingTemplates(): any[] {
    return this.listingTemplates;
  }

  getLanguages(): string[] {
    return this.languages;
  }

  getVoiceTones(): string[] {
    return this.voiceTones;
  }

  getWritingStyles(): string[] {
    return this.writingStyles;
  }

  randomString() {
    let result = '';
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 10; i > 0; --i) {
      result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
  }
  imageLoaded() {
    return this.imagePending;
  }
  imageLoadEnd(status) {
    this.imagePending = status;
  }

  getCommercialPropertyTypes() {
    return this.commercialPropertyTypes;
  }
  getEmailCustomers() {
    return this.emailCustomers;
  }
  rgbaToHex(rgba) {
    let inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(this.trim(inParts[0].substring(1)), 10),
      g = parseInt(this.trim(inParts[1]), 10),
      b = parseInt(this.trim(inParts[2]), 10),
      a = parseFloat(this.trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
    let outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(parseFloat(a) * 255).toString(16).substring(0, 2)
    ];

    // Pad single-digit output values
    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    })

    return ('#' + outParts.join(''));
  }

  trim(str) {
    return str?.replace(/^\s+|\s+$/gm, '');
  }

  getTeamProducts() {
    return this.teamProducts;
  }

  getContentCrads() {
    return this.createContentCrads;
  }

  setIsEmailCampaignAllowed(val) {
    this.isEmailCampaignAllowed = val;
  }

  getIsEmailCampaignAllowed() {
    return this.isEmailCampaignAllowed;
  }

  setIsGMBAllowed(val) {
    this.isGMBAllowed = val;
  }

  getIsGMBAllowed() {
    return this.isGMBAllowed;
  }
}
