import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Content } from 'src/app/vos/content/content';
import { Customer } from 'src/app/vos/customer/customer';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-select-image-card',
  templateUrl: './select-image-card.component.html',
  styleUrls: ['./select-image-card.component.scss']
})
export class SelectImageCardComponent implements OnInit {

  @Input()
  content: Content;

  @Input()
  index: any;

  customer: Customer;

  @Input()
  width = 'auto';

  @Input()
  horizontalMargin = '25px';

  @Input()
  verticalMargin = '25px';

  @Input()
  caption_editable = false;

  @Input()
  isCoverPhotos = false;

  @Input()
  disableDeselect = false;

  editing = false;
  loading = false;
  initialWidth: string;
  canPost = false;
  elem: ElementRef;

  @Output() cardClicked = new EventEmitter();

  get height(): string {
    if (this.width === 'auto') {
      return 'auto';
    } else {
      return '' + parseInt(this.width?.replace(/\D/g, ''), 10) * 296 / 282 + 'px';
    }
  }
  constructor(
    private authService: AuthenticationService,
    element: ElementRef
  ) {
    this.elem = element;
  }

  ngOnInit() {
    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
      }
    });

    if (this.index && this.index === 0){
      this.cardClicked.emit(this.content);
    }
    // this.canPost = !this.customer.settings.auto_posting && ['ready', 'posting', 'posted'].includes(this.content.status);
    // this.initialWidth = this.width;
  }

  showPreview() {
    window.open(this.content.url, 'nil');
  }

  setContectCard(e: any, item: any) {
    this.content = item;
    this.cardClicked.emit(item);
  }

}
