<div nz-row class="login-form-first-div">
  <div class="login-form-second-div">
    <div nz-row class="flex-layout">
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-form">
          <img width="200px"  src="assets/logos/blue-logo.png" />
          <div *ngIf="state === 'set_pass'" class="login-box">
            <h2  class="sign-in">Set A Password</h2>
            <form [formGroup]="passForm" (ngSubmit)="changePass()" class="login-form">
              <div class="error error-box" *ngIf="errorMessage">{{ errorMessage }}</div>
              <nz-form-item>
                <nz-form-control nzErrorTip="Must Be At Least 6 Characters!">
                  <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" name="password" (ngModelChange)="updateConfirmValidator()" placeholder="Password" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control class="input-pad" [nzErrorTip]="errorTpl">
                  <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password_confirmation" name="password_confirmation" placeholder="Confirm Password"
                    />
                  </nz-input-group>
                
                </nz-form-control>
                <ng-template #errorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      Please confirm your password!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('confirm')">
                      Two passwords that you enter is inconsistent!
                    </ng-container>
                  </ng-template>
              </nz-form-item>
              <ng-template #suffixTemplate>
                <i nz-icon class="show-password " [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
              </ng-template>
              <button nz-button nzType="primary" [nzLoading]="loading" class="login-button" size="large" [disabled]="!passForm.valid" type="submit">
                Submit
              </button>
            </form>
          </div>
          <div *ngIf="state === 'forgot_pass'" class="forgot-box">
            <h2  class="sign-in">Reset Password</h2>
            <form [formGroup]="resetForm" (ngSubmit)="forgotPass()" class="login-form">
              <div class="error error-box" *ngIf="errorMessage">{{ errorMessage }}</div>
              <br />
              <nz-form-item>
                <nz-form-control nzErrorTip="Email is required" class="input-pad">
                  <nz-input-group nzPrefixIcon="mail">
                    <input nz-input name="email" type="email" id="email" formControlName="email" placeholder="Email" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <br />
              <button nz-button nzType="primary" [nzLoading]="loading" class="login-button" [disabled]="!resetForm.valid" type="submit">
                Submit
              </button>
            </form>
            <div *ngIf="state === 'reset_pass'">
              <span class="reset-message">Check your email for a link to reset your password!</span>
              <nz-divider></nz-divider>
              <span class="reset-notice">Link sent to {{email}}</span>
              <button nz-button class="resend-button login-button" nzType="primary" (click)="forgotPass()">
                Resend Email
              </button>
            </div>
          </div>


          <div *ngIf="state === 'expired_forgot_pass'" class="forgot-box">
            <h2 class="resend-instrcutions">Reset password link is expired</h2>
            <p>Enter your email address and we'll resend the link.</p>
            <form [formGroup]="resetForm" (ngSubmit)="forgotPass()" class="login-form">
              <div class="error error-box" *ngIf="errorMessage">{{ errorMessage }}</div>
              <br />
              <nz-form-item>
                <nz-form-control nzErrorTip="Email is required" class="input-pad">
                  <nz-input-group nzPrefixIcon="mail">
                    <input nz-input name="email" type="email" id="email" formControlName="email" placeholder="Email" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <br />
              <button nz-button nzType="primary" [nzLoading]="loading" class="login-button" [disabled]="!resetForm.valid" type="submit">
                Resend Reset Password Email
              </button>
            </form>
            <div *ngIf="state === 'reset_pass'">
              <span class="reset-message">Check your email for a link to reset your password!</span>
              <nz-divider></nz-divider>
              <span class="reset-notice">Link sent to {{email}}</span>
              <button nz-button class="resend-button login-button" nzType="primary" (click)="forgotPass()">
                Resend Email
              </button>
            </div>
          </div>
          <div *ngIf="state === 'resend_forgot_pass'" class="forgot-box">
            <h2 class="resend-instrcutions">Didn't receive confirmation instructions?</h2>
            <p>Enter your email address and we'll resend the instructions.</p>
            <form [formGroup]="resetForm" (ngSubmit)="forgotPass()" class="login-form">
              <div class="error error-box" *ngIf="errorMessage">{{ errorMessage }}</div>
              <br />
              <nz-form-item>
                <nz-form-control nzErrorTip="Email is required" class="input-pad">
                  <nz-input-group nzPrefixIcon="mail">
                    <input nz-input name="email" type="email" id="email" formControlName="email" placeholder="Email" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <br />
              <button nz-button nzType="primary" [nzLoading]="loading" class="login-button" [disabled]="!resetForm.valid" type="submit">
                Resend Confirmation Email
              </button>
            </form>
            <div *ngIf="state === 'reset_pass'">
              <span class="reset-message">Check your email for a link to reset your password!</span>
              <nz-divider></nz-divider>
              <span class="reset-notice">Link sent to {{email}}</span>
              <button nz-button class="resend-button login-button" nzType="primary" (click)="forgotPass()">
                Resend Email
              </button>
            </div>
          </div>
          <div *ngIf="state === 'reset_pass'" class="forgot-box">
            <h2  class="sign-in">Reset Password</h2>
            <div>
              <span class="reset-message">Check your email for a link to reset your password!</span>
              <br>
              <span class="reset-notice">Link sent to {{email}}</span>
              <nz-divider></nz-divider>
              <button nz-button  [nzLoading]="loading" class="resend-button login-button" nzType="primary" (click)="forgotPass()" [disabled]="resendWait > 0">
                <span *ngIf="resendWait === 0"> Resend Email</span>
                <span *ngIf="resendWait > 0" [style.fontSize]="'12px'">Try again in {{resendWait}}s</span>
              </button>
            </div>
          </div>
      </div>
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-img second-bg">
        <img class="login-form-forgot" class="side-image" src="assets/images/media.png" />
      </div>
    </div>
  </div>
</div>
