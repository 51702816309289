import { Component, OnInit } from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {NpsScoreService} from '../../services/nps-score/nps-score.service';
import {ReferralsService} from '../../services/referrals/referrals.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {SociallinksService} from '../../services/social-links/social-links.service';

@Component({
  selector: 'app-nps-score-dialog',
  templateUrl: './social-links-dialog.component.html',
  styleUrls: ['./social-links-dialog.component.scss']
})

export class SocialLinksDialogComponent implements OnInit {

  platform;
  url;

  constructor(
    private modalRef: NzModalRef,
    private sociallinksService: SociallinksService,
    private messageService: NzMessageService,
  ) {}
  ngOnInit() {
  }

  handleSubmit() {
    this.sociallinksService.create({platform: this.platform, link: this.url}).subscribe( res => {
        this.modalRef.destroy();
        this.messageService.success('Social links added to your Zentap Card!');
    });
  }

  handleCancel() {
    this.modalRef.destroy();
  }


}
