import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GlobalsService } from '../../services/globals/globals.service';
import { AnnouncementService } from '../../services/announcement/announcement.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { Customer } from '../../vos/customer/customer';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'app-facebook-onboarding-dialog',
  templateUrl: './facebook-onboarding-dialog.component.html',
  styleUrls: ['./facebook-onboarding-dialog.component.scss']
})

export class FacebookOnboardingDialogComponent implements OnInit, OnDestroy {
  @Input()
  config: any;
  loading = false;
  code: string;
  facebookPages = [];
  showPages = false;
  selectedPageId;
  paging = null;
  isDone = false;
  currentPage: any;
  customer: Customer;
  PaidTier = false;
  broadcastChannel: BroadcastChannel;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private globalsService: GlobalsService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private router: Router,
    private authService: AuthenticationService,
    private customersFacebookService: CustomersFacebookService,
    private notification: NzNotificationService,

  ) {
    this.authService.refresh()
      .subscribe(c => {
        if (c) {
          this.customer = c;
          this.PaidTier = c?.tier === 'ultra';
        }
      });
  }
  ngOnInit() {
    this.config = this.nzModalData.config;
    try {
      if (BroadcastChannel) {
        this.broadcastChannel = new BroadcastChannel('facebookConnection');
        this.broadcastChannel.onmessage = (ev) => {
          this.customersFacebookService.getCustomerConectedFacebookPage()
            .subscribe(res => {
              this.loading = true;
              if (res && res?.body?.data?.length === 0) {
                this.getCustomerFacebookPages();
              } else {
                this.close();
              }
            });
        };
        if (this.config && this.config?.autoConnect) {
          this.loading = true;
          this.customersFacebookService.authorizeFacebook(null, this.config.isReAuth);
          // this.loginWithFacebook()
        }
      }
    }
    catch {
      //do some alternative here...
    }
  }


  loginWithFacebook(code?: string, isReAuth?): void {
    this.loading = true;
    this.customersFacebookService.getFacebookUserConnection()
      .subscribe(res => {
        if (res && res?.body.data && res?.body.data.length) {
          this.getCustomerFacebookPages();
        } else {
          this.customersFacebookService.authorizeFacebook(null, isReAuth);
        }
      });
  }

  // handleFBLogin(response: AuthResponse) {
  //   this.loading = true;
  //   this.customersFacebookService.getFacebookUserConnection()
  //     .subscribe(res => {
  //       if (!(res.facebook_user?.status === 'created')) {
  //         this.customersFacebookService.createFacebookUserConnection(response.accessToken).subscribe(pageResponse => {
  //           this.getCustomerFacebookPages();
  //           this.loading = false;
  //         },
  //           (err: HttpErrorResponse) => {
  //             this.loading = false;
  //           });
  //       } else {
  //         this.getCustomerFacebookPages();
  //         this.loading = false;
  //       }
  //     });
  // }

  // handleFBReset(response: LoginResponse) {
  //   this.loading = true;
  //   this.authService.resetFacebook(response.authResponse.accessToken, this.code).subscribe(
  //     customer => {
  //       this.router.navigateByUrl('/');
  //     },
  //     (err: HttpErrorResponse) => {
  //       this.loading = false;
  //       console.log(err);

  //       // this.errorMessage = err.error.message;
  //       // switch (err.status) {
  //       //   case 422:
  //       //     this.handleFBLogin(response.authResponse);
  //       //     return;
  //       //   case 404:
  //       //     this.errorMessage = 'No account found. Please <a href="">contact us</a> to signup.';
  //       //     break;
  //       //   default:
  //       //     this.errorMessage = 'Could not login';
  //       // }
  //     });
  // }

  selectOption(page) {
    if (!page.checked) {
      this.selectedPageId = page.id;
    } else {
      this.selectedPageId = null;
    }
    this.facebookPages = this.facebookPages.map(fbPage => {
      if (fbPage.id !== page.id && fbPage.checked) {
        fbPage.checked = false;
      }
      return fbPage;
    })
    page.checked = !page.checked;
  }

  createPage() {
    this.customersFacebookService.createCustomerConectedFacebookPage(this.selectedPageId, this.currentPage)
      .subscribe(res => {
        this.isDone = true;
        // this.modal.close();
        this.notification.create(
          'success',
          'Connected',
          'Your Facebook page has successfully been connected'
        );
      })
  }

  close() {
    this.modal.close();
  }
  getCustomerFacebookPages(params?) {
    this.loading = true;
    this.customersFacebookService.getFacebookPages(params)
      .subscribe(res => {
        if (res) {
          const pages = res?.body.data.map(page => {
            page.checked = false;
            return page;
          });
          this.facebookPages = pages;
          this.paging = res?.body.paging;
          this.showPages = true;
          this.loading = false;
        }
      })
  }

  previuosPage(before) {
    this.currentPage = `&before=${before}`;
    this.getCustomerFacebookPages(`?before=${before}`);
  }

  nextPage(after) {
    this.currentPage = `&after=${after}`;
    this.getCustomerFacebookPages(`?after=${after}`);
  }


  selectPages(pages) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Select which page Zentap should manage.',
        singleSelect: pages,
        buttonLabel: 'skip'
      },
      nzFooter: null
    });
  }

  ngOnDestroy() {
    this.broadcastChannel.close();
  }

}
