import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { CustomersService } from 'src/app/services/customers/customers.service';

@Component({
  selector: 'app-usericon',
  templateUrl: './usericon.component.html',
  styleUrls: ['./usericon.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ width: '*' })),
      state('false', style({ width: '61px' })),
      transition('false => true', animate('.5s ease-in')),
      transition('true => false', animate('.5s ease-out')),
    ])
  ]
})
export class UsericonComponent implements OnInit, OnDestroy {
  opened = true;
  animating = false;
  visible: boolean;
  mobileQuery: MediaQueryList;
  customer: Customer;
  loading = false;
  private _mobileQueryListener: () => void;
  profileMenuItems: { text: string, action: (() => void) | string }[] = [
    { text: 'Profile', action: '/profile' },
    { text: 'Plan', action: '/plan' },
    { text: 'Logout', action: (() => this.logout()) }
  ];

  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'mnav-dropdown',
    listBackgroundColor: '#28484D',
    fontColor: `#FFFFFF`,
    backgroundColor: '#28484D',
    selectedListFontColor: `#FFFFFF`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    rtlLayout: false
  };
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private authService: AuthenticationService,
    private customerService: CustomersService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.opened = !this.mobileQuery.matches;
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
      }
      if (c && c.settings['show_price'] === undefined) {
        c.settings['show_price'] = true;
      }

    });
    this.routeEvent(this.router);
  }
  toggle() {
    this.opened = !this.opened;
  }
  start() {
    this.animating = true;
    this.tick();
  }

  done() {
    this.animating = false;
  }

  tick() {
    if (this.animating) {
      requestAnimationFrame(() => this.tick());
    }
  }
  toggleSetting(setting: string, event: any) {
    this.customer.settings[setting] = event.checked;
    this.customerService.update(this.customer).subscribe((c) => {
      this.customer = c;
    });
  }
  goHome() {
    this.router.navigate(['/']);
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/logout']);
  }

  routeEvent(router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.opened = !this.mobileQuery.matches;
      }
    });
  }

  handleMenuAction(action: (() => void) | string) {
    this.visible = false;
    if (action) {
      if (typeof action === 'string') {
        this.router.navigateByUrl(action);
      } else {
        action();
      }
    }
  }

}
