<!-- <app-sidebar> -->
  <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
    <ng-container *ngTemplateOutlet="welcomepage"></ng-container>
  </nz-spin>
  <ng-template #notloading>
    <ng-container *ngTemplateOutlet="welcomepage"></ng-container>
  </ng-template>

  <ng-template #welcomepage>
    <div class="header">
      <h1 class="welcome-heading">Welcome!</h1>
      <div *ngIf="welcomeData" nz-row>
        <p class="message" nz-col nzSpan="18">
          Welcome to Zentap! To get you to rolling on your empowered marketing journey, here are 3 marketing ads
          branded with your business information that are designed to drive results!
        </p>
        <button nz-col nzSpan="6" nz-button nzType="primary" (click)="goHome()">Create More!</button>
      </div>
      <p class="message" *ngIf="!welcomeData">
        Welcome to Zentap! To get you rolling on your empowered marketing journey, here are 3 marketing ads branded with your business
        information that are designed to drive results!
      </p>
    </div>
    <div class="empty" *ngIf="!welcomeData">
      <div class="conatiner">
        <img alt="" src="../../../../assets/images/empty_welcome.png">
        <h2 class="wait">Please wait while your branded videos are being created!</h2>
      </div>
    </div>

    <div *ngIf="welcomeData && welcomeData.length > 0" nz-row nzType="flex" nzJustify="start" nzGutter="2">
      <app-content-card *ngFor="let content of welcomeData" [content]="content" [horizontalMargin]="'0px'" showMoreMenu="true" fxFlex>
      </app-content-card>
    </div>
  </ng-template>
<!-- </app-sidebar> -->
