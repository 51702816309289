import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { Contact } from 'src/app/vos/contact/contact';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class ContactsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class WelcomePackageService extends CustomerResourceService<Contact> {
  public endpoint = 'welcome_package';
  public data_key = 'welcome_package';

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Contact, http, authService);
  }

  public renderWelcomePackage(cId?): Observable<any> {
    if (this.customer_id || cId) {
      const customerId =  cId || this.customer_id;
      const url = `${this._uri}customers/${customerId}/render_welcome_package`;
      return this.http.get<any>(
        url
      ).pipe(map((resp) => {
        return resp;
      }));
    }
}

}
