<div>
  <div>
    <h2>
      Celeb & Luxury
    </h2>
    <p class="disclaimer">
      Automated posting of these articles to your social media is an additional feature.
      <a [href]="calendlyUrl" target="_blank">Click here </a> to talk to your account manager and set it up.
    </p>
  </div>
  <nz-spin [nzSize]="'large'" *ngIf="loading"></nz-spin>
  <div nz-row nzJustify="start">
    <div *ngFor="let feed of feeds" class="tip-card">
      <nz-card nzHoverable class="fixed-card-height">
        <nz-card-meta [nzTitle]="titleTemplate"></nz-card-meta>
        <img alt="example" [src]="feed.image_url" class="card-image" />
        <p class="card-description line-clamp-3">{{feed.summary}}</p>
        <button nz-button nzType="primary" class="card-button" (click)="createCustomContent(feed)">
          Post
        </button>
        <a class="article-link" [href]="feed.url" target="_blank">View Article</a>
      </nz-card>
      <ng-template #titleTemplate>
        <div class="title-content" (click)="openUrlInNewTab(feed.url)">{{feed.title}} </div>
      </ng-template>
    </div>
  </div>
</div>