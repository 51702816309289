import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number | moment.Duration, args?: any): string {
    return moment.duration(value).format('d [days] h[h] m[m] s[s]', {
      largest: 2
    });
  }

}
