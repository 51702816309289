<h1 class="section-title section-title-small" *ngIf="!limitShow">Realtor Details</h1>
<div class="customer-form-realtor" nz-row nzType="flex" nzJustify="space-between">
  <nz-form-item class="gutter-row" nzSpan="7" nzOffset="1" *ngIf="!limitShow || showFields.includes('template')">
    <nz-form-label class="text-left" [nzSm]="24" [nzXs]="24" nzFor="template">Templates
    </nz-form-label>
    <nz-form-control nzErrorTip="Please input your template!">
      <input type="text" nz-input type="text" id="template" [(ngModel)]="model.template" name="template"
        [required]="requiredFields.includes('template')" placeholder="Select template"
        (ngModelChange)="changeTemplate()" [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let template of templates" [nzValue]="template">{{template}}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>
</div>
<div *ngIf="model.template" class="customer-form-realtor" nz-row nzType="flex" nzJustify="space-between">
  <nz-form-item class="gutter-row" nz-col [nzSpan]="7" *ngIf="!limitShow || showFields.includes('language')">
    <nz-form-label nzFor="language" nzRequired>Languages</nz-form-label>
    <nz-form-control nzErrorTip="Please input your language!">
      <input placeholder="Select Language" [required]="requiredFields.includes('langauge')" nz-input
        [(ngModel)]="model.language" name="language" (ngModelChange)="changeLanguage()" [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let language of languages" [nzValue]="language">{{language}}</nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="7" nzOffset="2"
    *ngIf="(!limitShow || showFields.includes('voice_tone')) && model.template != 'Real Estate Dictionary'">
    <nz-form-label nzFor="voice_tone">Voice Tones
    </nz-form-label>
    <nz-form-control nzErrorTip="Please input your voice tone!">
      <input type="text" nz-input type="text" id="voice_tone" [(ngModel)]="model.voice_tone" name="voice_tone"
        [required]="requiredFields.includes('voice_tone')" placeholder="Select Voice Tone"
        (ngModelChange)="changeVoiceTone()" [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let tone of voiceTones" [nzValue]="tone">{{tone}}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="gutter-row" nzSpan="7" nzOffset="1"
    *ngIf="(!limitShow || showFields.includes('writing_style')) && model.template != 'Real Estate Dictionary'">
    <nz-form-label class="text-left" [nzSm]="24" [nzXs]="24" nzFor="writing_style">Writing Styles
    </nz-form-label>
    <nz-form-control nzErrorTip="Please input your template!">
      <input type="text" nz-input type="text" id="writing_style" [(ngModel)]="model.writing_style" name="writing_style"
        [required]="requiredFields.includes('writing_style')" placeholder="Select writing style"
        (ngModelChange)="changeWritingStyle()" [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let style of writingStyles" [nzValue]="style">{{style}}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>
</div>
<div *ngIf="model.template == 'Cold Email Outreach' " class="customer-form-realtor" nz-row nzType="flex"
  nzJustify="space-between">
  <nz-form-item class="gutter-row" nz-col [nzSpan]="10" *ngIf="!limitShow || showFields.includes('prospect_type')">
    <nz-form-label nzFor="language" nzRequired>Prospect type</nz-form-label>
    <nz-form-control nzErrorTip="Please input your language!">
      <input placeholder="Select Language" [required]="requiredFields.includes('prospect_type')" nz-input
        [(ngModel)]="model.prospect_type" name="prospect_type" (ngModelChange)="changeProspectType()"
        [nzAutocomplete]="auto" />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let type of prospectTypes" [nzValue]="type">{{type}}</nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="10" nzOffset="2" *ngIf="!limitShow || showFields.includes('CTA')">
    <nz-form-label nzFor="cta">CTA
    </nz-form-label>
    <nz-form-control nzErrorTip="Please input your CTA!">
      <input type="text" nz-input type="text" id="cta" [(ngModel)]="model.CTA" name="CTA"
        [required]="requiredFields.includes('CTA')" placeholder="CTA" />
    </nz-form-control>
  </nz-form-item>
</div>
<div *ngIf="model.template == 'Listings Writer' || model.template == 'Buyer Preparation'  ">
  <div class="customer-form-realtor" nz-row nzType="flex" nzJustify="space-between">
    <nz-form-item class="gutter-row" nz-col [nzSpan]="14" nzOffset="2"
      *ngIf="!limitShow || showFields.includes('property_type_location')">
      <nz-form-label nzFor="property_type_location">Property Type & Location
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your Property Type & Location!">
        <input type="text" nz-input type="text" id="property_type_location" [(ngModel)]="model.property_type_location"
          name="property_type_location" [required]="requiredFields.includes('property_type_location')"
          placeholder="Property Type & Location" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="gutter-row" nz-col [nzSpan]="4" nzOffset="2"
      *ngIf="(!limitShow || showFields.includes('total_characters')) && model.template == 'Listings Writer'">
      <nz-form-label nzFor="total_characters">Total Characters
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your Total Characters!">
        <input type="text" nz-input type="text" id="total_characters" [(ngModel)]="model.total_characters"
          name="total_characters" [required]="requiredFields.includes('total_characters')"
          placeholder="Total Characters" />
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="customer-form-realtor" nz-row>
    <nz-form-item class="gutter-row" nz-col [nzSpan]="10" nzOffset="2"
      *ngIf="!limitShow || showFields.includes('property_description')">
      <nz-form-label nzFor="property_description">Property Description
      </nz-form-label>
      <nz-form-control nzErrorTip="Please input your Property Description!">
        <textarea nz-input type="text" id="property_type_location" [(ngModel)]="model.property_description"
          name="property_description" [required]="requiredFields.includes('property_description')"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
<div *ngIf="model.template == 'Real Estate Dictionary'" class="customer-form-realtor" nz-row>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="10" nzOffset="2"
    *ngIf="!limitShow || showFields.includes('real_estate_related_terms')">
    <nz-form-label nzFor="real_estate_related_terms">Real Estate Related Terms
    </nz-form-label>
    <nz-form-control nzErrorTip="Please input your Real Estate Related Terms!">
      <textarea nz-input type="text" id="real_estate_related_terms" [(ngModel)]="model.real_estate_related_terms"
        name="real_estate_related_terms" [required]="requiredFields.includes('real_estate_related_terms')"></textarea>
    </nz-form-control>
  </nz-form-item>
</div>

<button nz-button nzType="primary" class="space-right" (click)="getResponse()">Get Response</button>

<nz-divider *ngIf="!limitShow" [style.margin-bottom]="'30px'"></nz-divider>
<div class="container">
  <div>
    <h2>AI Response</h2>
  </div>
  <ng-template #templateflow class="mt-5">
    <div>
      <p>{{ model.response }}</p>
    </div>
  </ng-template>
</div>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="templateflow"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="templateflow"></ng-container>
</ng-template>