import {Component, OnInit, Input} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Image } from '../../vos/image/image';

export class GalleryConfig {
  aspectRatio: string;
  cols = 3;
  gutterSize = '10px';
  instantSelection = true;
}

@Component({
  selector: 'app-listing-image-gallery',
  templateUrl: './listing-image-gallery.component.html',
  styleUrls: [ './listing-image-gallery.component.scss' ]
})
export class ListingImageGalleryComponent implements OnInit {
  selected: number;
  @Input() config: GalleryConfig;
  @Input() images: Image[];
  constructor(
    private modal: NzModalRef,
  ) { }

  ngOnInit() {}

  // What is this?
  select(image: Image) {
    this.modal.destroy(image) ;
  }

}
