<!-- <nz-layout> -->
    <div nz-row class="login-container">

  <div nz-col [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 24, offset: 0 }"
       [nzMd]="{ span: 8, offset: 8 }" [nzLg]="{ span: 8, offset: 8 }" class="center">
    <div class="icon-container">
      <img class="up-icon" src="assets/logos/logo.png">
    </div>
    <div [@flyInOut]="'in'" class="login-box">
      <div class="spiner-center loading-overlay" *ngIf="loading">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
      </div>
      <div class="login-title">Creating Your Account
        <nz-divider></nz-divider>
      </div>
      <form nz-form [formGroup]="regForm" (ngSubmit)="signup()" class="login-form">
        <nz-form-item>
          <nz-form-control>
            <nz-input-group nzPrefixIcon="user">
              <input type="text" nz-input formControlName="first_name" name="first_name" placeholder="First Name" />
            </nz-input-group>
            <span class="error left" *ngIf="regForm.controls['first_name'].hasError('required') && regForm.controls['first_name'].touched">
              First name is required
            </span>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group nzPrefixIcon="user">
              <input type="text" nz-input formControlName="last_name" name="last_name" placeholder="Last Name" />
            </nz-input-group>
            <span class="error left" *ngIf="regForm.controls['last_name'].hasError('required') && regForm.controls['last_name'].touched">
              Last name is required
            </span>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group nzPrefixIcon="phone">
              <input nz-input formControlName="primary_phone" name="primary_phone" placeholder="Phone" />
            </nz-input-group>
            <span class="error left" *ngIf="regForm.controls['primary_phone'].hasError('required') && regForm.controls['primary_phone'].touched">Phone is required
            </span>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group nzPrefixIcon="mail">
              <input nz-input formControlName="email" name="email" placeholder="Email" />
            </nz-input-group>
            <span class="error left" *ngIf="regForm.controls['email'].hasError('required') && regForm.controls['email'].touched">Email is required
            </span>
          </nz-form-control>
        </nz-form-item>
        <!-- <br /> -->
        <nz-form-item>
          <nz-form-control>
            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
              <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Password" formControlName="password" />
            </nz-input-group>
            <ng-template #suffixTemplate>
              <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
            <span class="error left" *ngIf="regForm.controls['password'].hasError('required') && regForm.controls['password'].touched">Password is required
            </span>
          </nz-form-control>
        </nz-form-item>
        <span class="error" *ngIf="errorMessage">{{ errorMessage }}</span>
        <br />
        <button nz-button class="login-button" [disabled]="!regForm.valid" type="primary">
          Signup
        </button>
        <br />
        <button type="button" nz-button (click)="toUrl('/login')" class="signup-button">Login</button>
      </form>
    </div>
  </div>
  </div>
<!-- </nz-layout> -->
