import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { DataResponse } from 'src/app/models/data-response/data-response';
import { Customer, Integration } from 'src/app/vos/customer/customer';
import { CustomerResourceService } from '../customer-resource.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConnectLinkedinService } from '../connect-linkedin/connect-linkedin.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.api_url}`;
}

@Injectable()

export class CustomersService extends CustomerResourceService<Customer> {
  public endpoint = 'customers';
  public data_key = 'customer';
  integration;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Customer, http, authService);
  }
  update(customer: Customer): Observable<Customer> {
    return super.update(customer).pipe(
      map(c => {
        this.authService.updateCurrentCustomer(c);
        return c;
      })
    );
  }

  acceptTermsAndConditions(): Observable<any> {
    return this.http
      .post<DataResponse<any>>(`${this._uri}customers/tos.json`, JSON.stringify({}), {
        headers: this.headers
      })
      .pipe(map(resp => resp));
  }

  checkCustomer(): Observable<boolean> {
    if (!this.authService.currentCustomerValue) {
      return of(false);
    }
    return this.show(this.authService.currentCustomerValue?.id).pipe(
      map(resp => {
        this.authService.updateCurrentCustomer(resp.data);
        return resp.data !== undefined && resp.data !== null;
      })
    );
  }
  refreshCurrentCustomer(): void {
    this.show(this.authService.currentCustomerValue?.id).subscribe(resp => {
      this.authService.updateCurrentCustomer(resp.data);
    });
  }

  linkFB(access_token: string): Observable<Customer> {
    return this.http
      .put<DataResponse<Customer>>(`${this._uri}${this.customerEndpointFor('facebook')}.json`, JSON.stringify({ access_token }), {
        headers: this.headers
      })
      .pipe(map(resp => new Customer(resp.data)));
  }
  linkLN(value: any): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('linkedin.json')}`,
        { headers: this.headers })
      .pipe(map(response => {
        if (response.data.length > 0) {
          return this.http
            .put<DataResponse<any>>(`${this._uri}linkedin/${response.data[0].id}.json`,
              JSON.stringify(value),
              { headers: this.headers })
            .pipe(map(resp => (resp.data)));
        } else {
          return this.http
            .post<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('linkedin')}`,
              JSON.stringify(value),
              { headers: this.headers })
            .pipe(map(resp => (resp.data)));
        }
      }));
  }

  requestPageAccess(pageId: string): Observable<any> {
    return this.http
      .post<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('facebook_page')}.json`, JSON.stringify({ fb_page_id: pageId }), {
        headers: this.headers
      })
      .pipe(map(resp => (resp.data)));
  }
  checkFBAccess(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('check_fb_access')}.json`)
      .pipe(map(resp => (resp.data)));
  }
  deleteIntegration(integration: Integration): Observable<void> {
    return this.http
      .delete<void>(`${this._uri}integrations/${integration.id}.json`, {
        headers: this.headers
      });
  }
  getManualFacebook(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('manual_facebook_connection.json')}`,
        { headers: this.headers })
      .pipe(map(resp => (resp.data)));
  }
  getLinkedin(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('linkedin.json')}`,
        { headers: this.headers })
      .pipe(map(resp => (resp.data)));
  }
  getInstagram(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('instagram.json')}`,
        { headers: this.headers })
      .pipe(map(resp => (resp.data)));
  }
  logout() {
    return this.http
      .delete<void>(`${this._uri}/customers/logout.json`, {
        headers: this.headers
      });
  }

  trackings(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('tracking.json')}`,
        { headers: this.headers })
      .pipe(map(resp => (resp.data)));
  }

  dataPoints(): Observable<any> {
    //   return of([
    //     {
    //         "id": 6,
    //         "created_at": "2021-07-26T15:03:30.079Z",
    //         "updated_at": "2021-07-26T15:24:58.144Z",
    //         "defaults": {
    //             "soldCount": true,
    //             "medianSoldPrice": true,
    //             "medianSoldPerSqft": true,
    //             "medianSoldPerListPercent": true,
    //             "avgSoldToListPricePercent": true,
    //             "newListCount": true,
    //             "medianNewListPrice": true,
    //             "avgListPrice": true,
    //             "pendingCount": true,
    //             "medianPendingPrice": false,
    //             "expiredCount": false,
    //             "forSaleCount": false,
    //             "medianListPrice": false,
    //             "medianListPerSqft": false,
    //             "avgDom": false,
    //             "medianGla": false,
    //             "medianBed": false,
    //             "medianBath": false,
    //             "absorptionRate": false,
    //             "monthsOfSupply": false,
    //             "leastExpensive": false,
    //             "mostExpensive": false,
    //             "avgAge": false,
    //             "medianLot": false,
    //             "avgListPerSqft": false
    //         }
    //     }
    // ]);
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('default_data_points')}`,
        { headers: this.headers })
      .pipe(map(resp => (resp)));
  }

  updateDataPoints(payload, id: string): Observable<any> {
    return this.http.put(`${this._uri}${this.customerEndpointFor('default_data_points')}/${id}`,
      JSON.stringify(
        {
          "data_point": {
            "defaults": payload
          }
        }
      ), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(map(resp => (resp)));
  }



}
