  <ng-template #selectcontent>

<nz-collapse nzBordered="false" class="is_mobile">
  <nz-collapse-panel nzActive [nzHeader]="creation">
    <ng-container *ngTemplateOutlet="creationCards"></ng-container>
  </nz-collapse-panel>
</nz-collapse>

<div class="is_desktop">

  <div class="title-header" *ngTemplateOutlet="creation"></div>
  <ng-container *ngTemplateOutlet="creationCards"></ng-container>
</div>
</ng-template>
<ng-template #creation>
  <h1 class="sec-title title-space">
    Create Content : {{isListing ? 'Listings' : ''}} {{isMarketing ? 'Marketing' : ''}} {{isInformative ? 'Informative' : ''}}
  </h1>
  <div nz-row class="search">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="input search text" [ngModel]="searchText"
        (ngModelChange)='searchModelChanged?.next($event)' />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</ng-template>
<ng-template #creationCards>
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel
      #p
      [nzHeader]="'Videos'"
      [nzActive]="true"
    >
    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="products-container" *ngFor="let product of videos">
        <app-card-button [config]="product" cardType="product-card"></app-card-button>
      </div>
      <div *ngIf="videos.length == 0 && !loading">
        <h3>No data found</h3>
      </div>
    </div>
    </nz-collapse-panel>
    <nz-collapse-panel
    #p
    [nzHeader]="'Flyers'"
    [nzActive]="true"
  >
  <div nz-row [nzGutter]="8">
    <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="products-container" *ngFor="let product of flyers">
      <app-card-button [config]="product" cardType="product-card"></app-card-button>
    </div>
    <div *ngIf="flyers.length == 0 && !loading">
      <h3>No data found</h3>
    </div>
  </div>
  </nz-collapse-panel>
  </nz-collapse>
</ng-template>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="selectcontent"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="selectcontent"></ng-container>
</ng-template>
