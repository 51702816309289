import {ChangeDetectionStrategy, Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, inject} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
declare  const Calendly: any;
@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: [ './upgrade-dialog.component.scss' ],
  changeDetection: ChangeDetectionStrategy.Default
})

export class UpgradeDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef;
  @Input()
  facebookSupport = false;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private modal: NzModalRef,
  ) {}

  ngAfterViewInit(): void {
    Calendly.initInlineWidget({
      url: 'https://calendly.com/client-success-call/success-call',
      parentElement: this.container.nativeElement
    });
  }
  ngOnInit() {
    if(this.nzModalData.facebookSupport != undefined){
      this.facebookSupport = this.nzModalData.facebookSupport
    }
  }
  removeDialog () {
    this.modal.close();

  }
}
