<div class="notification-dropdown">
  <nz-list>
    <div style="margin: 15px">
      <i nz-icon nzType="bell" class="" nzTheme="fill">
      </i>
      <h2 class="analytics-heading-bell"> Notifications</h2>
    </div>
  </nz-list>
  <div infiniteScroll class="overflow-scroll" [infiniteScrollDistance]="4" [scrollWindow]="false"
    [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
    <nz-list [nzItemLayout]="'horizontal'" [nzLoading]="loading">
      <div *ngFor="let item of notifications">
        <nz-list-item class="active {{ item?.opened || mark_all_clear ? '' : 'un-read' }}"
          [id]="'notification-item-'+item.id">
          <a href="javascript:" (click)="openNotification(item, $event)">
            <nz-list-item-meta [nzDescription]="nzDescription" class="nf-list">
              <ng-template #nzDescription>
                <div class="noti-icon">
                  <i nz-icon [nzType]="getNotificationIcon(item)" nzTheme="outline"></i>
                </div>
                <div class="noti-text">
                  {{ item.text }}<br />
                  <span>{{item.created_at | date: 'MMM dd hh:mma'}}</span>
                </div>
              </ng-template>
            </nz-list-item-meta>
          </a>
          <nz-skeleton *ngIf="loading" [nzAvatar]="false" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
          </nz-skeleton>
          <nz-skeleton *ngIf="loading" [nzAvatar]="false" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
          </nz-skeleton>
          <nz-skeleton *ngIf="loading" [nzAvatar]="false" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
          </nz-skeleton>
          <nz-skeleton *ngIf="loading" [nzAvatar]="false" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
          </nz-skeleton>
          <nz-skeleton *ngIf="loading" [nzAvatar]="false" [nzActive]="true" [nzTitle]="true" [nzLoading]="true">
          </nz-skeleton>
        </nz-list-item>
      </div>
    </nz-list>
    <nz-list *ngIf="notifications && notifications.length === 0">
      <nz-empty nzNotFoundContent="You have no notifications yet" class="no-content" [style.margin-top]="'8px'">
      </nz-empty>
    </nz-list>
  </div>
  <nz-list>
    <a [routerLink]="['/notifications']" class="nf-view-all" (click)="visible = false">
      View All
    </a>
  </nz-list>
</div>
<ng-template #noNotifications>
  <nz-empty nzNotFoundContent="You have no notifications yet" class="no-content" [style.margin-top]="'5px'"></nz-empty>
</ng-template>
