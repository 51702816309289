import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'; // Import HttpParams
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { Contact } from '../../vos/contact/contact';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { DataResponse } from '../../models/data-response/data-response';

export class AiTemplateServiceConfig {
  uri = '';
}

@Injectable()
export class AiTemplateService extends CustomerResourceService<Contact> {
  public endpoint = 'api/v1/professional_realtor_templates';

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Contact, http, authService);
  }

  generate_ai_template(payload: any): Observable<any> {
    return this.http
    .post<DataResponse<any>>(
      `${this._uri}${this.endpoint}/generate_ai_template.json`,
      JSON.stringify({ payload }),
      { headers: this.headers }
    )
    .pipe(map(resp => resp));
  }

  generate_sms_campaign_ai_template(payload: any): Observable<any> {
    return this.http
    .post<DataResponse<any>>(
      `${this._uri}${this.endpoint}/generate_sms_campaign_ai_template.json`,
      JSON.stringify({ payload }),
      { headers: this.headers }
    )
    .pipe(map(resp => resp));
  }
}
