import { Component, OnInit } from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {NpsScoreService} from '../../services/nps-score/nps-score.service';
import {ReferralsService} from '../../services/referrals/referrals.service';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-nps-score-dialog',
  templateUrl: './nps-score-dialog.component.html',
  styleUrls: ['./nps-score-dialog.component.scss']
})

export class NpsScoreDialogComponent implements OnInit {

  scores = Array(11).fill(0);
  selectedScore: number | null = null;
  message = '';
  referral = false;
  giftType = '';
  referrals = [{
    name: null,
    phone: null,
    email: null,
    error: null,
    gift_type:  this.giftType
  }];

  constructor(
    private modalRef: NzModalRef,
    private npsService: NpsScoreService,
    private referralsService: ReferralsService,
    private messageService: NzMessageService,
  ) {}
  ngOnInit() {
  }

  selectScore(score: number) {
    this.selectedScore = score;
  }

  handleSubmit() {
    this.npsService.create({message: this.message, rating: this.selectedScore}).subscribe( res => {
      if (this.selectedScore >= 7){
        this.referral = true;
      }else{
        this.modalRef.destroy();
      }
    });
  }

  handleSubmitReferral(){
    const referralsWithMissingContact = [];

    this.referrals.forEach( ref => {
      if (!ref.phone && !ref.email) {
        referralsWithMissingContact.push(ref);
        ref.error = 'Please Add phone or Email for this referral!';
      }
      ref.gift_type = this.giftType;
    });
    if (referralsWithMissingContact.length > 0) {
      this.messageService.create('error', 'Please add missing information! Phone or email is required!');
      return true;
    }
    this.referralsService.create(this.referrals).subscribe(res => {
      this.modalRef.destroy();
    });
  }

  handleCancel() {
    this.modalRef.destroy();
  }

  addRef(){
    this.referrals.push({
      name: null,
      phone: null,
      email: null,
      error: null,
      gift_type:  this.giftType
    });
  }

  removeRef(i){
    this.referrals.splice(i, 1);
  }


}
