const allListingRequired = [
  'address',
  'beds',
  'baths',
  'listing_status',
  'baths',
  'price',
  // 'sq_ft',
  'images'
];
const allBrandingRequired = [
  'display_name',
  'preferred_title',
  'brokerage',
  'display_phone',
  'display_email',
  'image',
  'logo'
];
export const productFormFields: { [ key: string ]: { required: string[], shown: string[] } } = {
  congrats_videos: {
    required: [
      ...allListingRequired,
      'family_name',
      'buy_sell',
      'congrats',
      'locale',
      'transaction_type'
    ],
    shown: [
      'family_name',
      'congrats',
      'transaction_type'
    ]
  },
  listing_flyers: {
    required: [
      ...allListingRequired,
      'lot_size',
      'description',
      'listing_type',
      'year_build'
    ],
    shown: [
    ]
  },
  single_property_website: {
    required: [],
    shown: []
  },
  instagram_ads: {
    required: [ ...allListingRequired ],
    shown: []
  },
  open_house_videos: {
    required: [
      ...allListingRequired,
      'oh_from_date',
      'oh_start_time',
      'oh_end_time'
    ],
    shown: [
      'open_house',
    ]
  },
  open_house_flyers: {
    required: [
      ...allListingRequired,
      'oh_from_date',
      'oh_start_time',
      'oh_end_time'
    ],
    shown: [
      'open_house',
    ]
  },
  local_market_videos: {
    required: ['region_name', 'zips'],
    shown: ['region_name', 'zips']
  },
  listing_videos: {
    required: [
      ...allListingRequired
    ],
    shown: [
    ]
  },
  comparative_market_analysis: {
    required: [
      ...allListingRequired,
      'zip',
      'city',
      'state'
    ],
    shown: [
      'zip',
      'city',
      'state'
    ]
  },
  commercial_listing_videos: {
    required: [
      ...allListingRequired
    ],
    shown: [
    ]
  },
  banner_videos: {
    required: [
      // ...allBrandingRequired,
      // 'images',
      'banner_images'
    ],
    shown: [
      // 'display_name',
      // 'display_email',
      // 'preferred_title',
      // 'brokerage',
      // 'credential',
      // 'display_phone',
      // 'office_phone',
      // 'license_number',
      // 'website_url',
      // 'images',
      // 'team',
      'banner_images'
    ]
  },
  marketing_videos: {
    required: [
      ...allBrandingRequired,
      'images',
    ],
    shown: [
      'display_name',
      'display_email',
      'preferred_title',
      'brokerage',
      'display_phone',
      'office_phone',
      'license_number',
      'website_url',
      'images',
    ]
  },
  agent_website: {
    required: [],
    shown: []
  },
  facebook_ads: {
    required: [],
    shown: []
  },
  infographics: {
    required: [],
    shown: []
  },
};
