import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ProductsService} from '../../services/products/product-api.service';
import { RegisterGhlLocationDialogComponent } from 'src/app/shared/register-ghl-location-dialog/register-ghl-location-dialog.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import moment from 'moment';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  tabs = [
    { name: 'All Campaigns', type: 'all_campaigns' },
    { name: 'Active', type: 'active' },
    { name: 'Completed', type: 'completed' }
  ];
  smsCampaigns: any;
  filteredSmsCampaigns = [];
  loading = false;
  loadingProduct = true;
  selectedIndex = 0;
  page = 1;
  pageInfo = { total_entries: 0, per_page: 10, current_page: 1 };
  campaignProductAvailable = false;

  constructor(
    private campaignService: CampaignService,
    private router: Router,
    private authService: AuthenticationService,
    private productsService: ProductsService,
    private modalService: NzModalService,
    private breadcrumService: BreadCrumService,
  ) { }

  ngOnInit(): void {
    this.getSmsCampaigns();
    this.campaignAvailable();
    this.breadcrumService.set_breadcrum();
    const customer = this.authService.currentCustomerValue;
    if(!customer.isGhl && this.campaignProductAvailable){
      this.register_ghl_location();
    }
  }

  getSmsCampaigns() {
    this.loading = true;
    const page = `?page=${this.page}&q[s]=created_at desc,`;
    this.campaignService.list(page).subscribe(res => {
      this.smsCampaigns = res.data;
      this.pageInfo = { total_entries: res.total_entries, per_page: res.per_page, current_page: res.current_page };
      this.updateFilteredCampaigns();
      this.loading = false;
    });
  }

  updateFilteredCampaigns() {
    switch (this.tabs[this.selectedIndex].type) {
      case 'active':
        this.filteredSmsCampaigns = this.smsCampaigns.filter(campaign => campaign.status === 'active');
        break;
      case 'completed':
        this.filteredSmsCampaigns = this.smsCampaigns.filter(campaign => campaign.status === 'completed');
        break;
      default:
        this.filteredSmsCampaigns = this.smsCampaigns;
    }
  }

  onTabChange(index: number): void {
    this.selectedIndex = index;
    this.updateFilteredCampaigns();
  }


  newCampaign(): void {
    this.router.navigateByUrl('/campaigns/new');
  }

  editCampaign(id: any){
    this.router.navigateByUrl(`/campaigns/${id}/edit`);
  }

  deleteCampaign(id: any){
    this.campaignService.destroy(id).subscribe(res => {
      this.getSmsCampaigns();
    });
  }

  didPage(page: number) {
    this.page = page;
    this.getSmsCampaigns();
  }

  timeDifference(created_at: Date) {
    const createdAtMoment = moment(created_at);
    return createdAtMoment.fromNow();
  }

  register_ghl_location(){
    const modal = this.modalService.create({
      nzTitle: "Create GHL Location",
      nzContent: RegisterGhlLocationDialogComponent,
      nzFooter: null,
      nzWidth: '50%',
      nzCentered: true
    });
    modal.afterClose.subscribe(resp => {
    });
  }

  campaignAvailable(){
    const params: Record<string, any> = {
      per_page: 40,
    };
    this.productsService.productList(
      `customers/${this.authService.currentCustomerValue?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const products = res.data;
        const campaignProduct = products ? products?.find(p => p.abbreviation === 'SC' && p.locked === false) : null;
        if (campaignProduct) {
          this.campaignProductAvailable = true;
        } else{
          this.campaignProductAvailable = false;
        }
      }
      this.loadingProduct = false;
    });
  }

}
