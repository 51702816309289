import { Component, OnInit, Input, Output, EventEmitter, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  NgForm
} from '@angular/forms';
import { GlobalsService } from '../../services/globals/globals.service';
import { EmailUserService } from '../../services/email-user/email-user.service';
import { EmailContactListService } from '../../services/email-contact-list/email-contact-list.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-create-contact-list-dialog',
  templateUrl: './create-contact-list-dialog.component.html',
  styleUrls: ['./create-contact-list-dialog.component.scss']
})

export class CreateContactListDialogComponent implements OnInit {
  @Input()
  config: any;

  @Input()
  editList: any;

  @Output()
  closed: EventEmitter<any> = new EventEmitter();
  

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  loading = false;
  isAllContactsSelected = false;
  page = 1
  current = 0;
  list = {
    id: '',
    name: '',
    description: '',
    audience_ids: []
  };
  contacts = [];
  preSelected = [];
  @ViewChild('contactlistForm') contactListForm: NgForm;

  constructor(
    private modal: NzModalRef,
    private globalsService: GlobalsService,
    private router: Router,
    private emailUserService: EmailUserService,
    private emailContactListService: EmailContactListService,
    private message: NzMessageService,
  ) { }
  ngOnInit() {
    if(this.nzModalData.editList){
      this.editList = this.nzModalData.editList;
    }
    if (this.editList) {
      this.fetchListContacts();
      this.list.name = this.editList.name;
      this.list.id = this.editList.id;
    }
  }

  createList() {
    const body = {
      ...this.list,
      audience_ids: this.isAllContactsSelected ? null : this.list.audience_ids,
      select_all: this.isAllContactsSelected
    }
    this.emailContactListService.create(body)
      .subscribe(res => {
        this.close(res);
      }, err=>{

      })
  }

  updateList() {
    this.emailContactListService.update(this.list)
      .subscribe(res => {
        this.message.create('success', 'List is updated succesfully!')
        this.close(res);
      })
  }

  fetchListContacts(page?) {
    let queryParams = `?page=${ page || this.page}`;
    if(this.page === 1) {
      this.loading = true;
    } else {
      this.loading = false;
    }
    this.emailContactListService.fetchAudience(this.editList.id, queryParams)
      .subscribe(res => {
        if (res) {
          this.list.audience_ids = this.list.audience_ids.concat(res.data.map(c => c.id));
          this.preSelected = this.list.audience_ids;
          if(this.list.audience_ids.length < res.total_entries) {
            this.fetchListContacts(res.current_page+1);
          }
          this.loading = false;
        }
      }, err => {
        this.loading = false;
      });
  }

  onPageChange(page) {
    this.page = page;
    this.fetchListContacts();
  }

  changeSelection(contactList) {
    // this.contacts = contactList;
    this.list.audience_ids = contactList;
    if (this.contactListForm && this.contactListForm.controls) {
      (Object.keys(this.contactListForm.controls)).forEach(c => {
        this.contactListForm.controls[c].markAsTouched();
        this.contactListForm.controls[c].updateValueAndValidity();
      })
    }
  }

  changeSelectionAll(isAll) {
    this.isAllContactsSelected = isAll;
  }

  close(res?) {
    this.modal.close(res);
    this.closed.emit(res);
  }

  gotoStep(step) {
    if (this.current > step) {
      this.current = step;
    }
  }

  delayedNext() {
    setTimeout(() => {
      this.current += 1;
    });
  }

  delayedBack() {
    setTimeout(() => {
      this.current -= 1;
    });
  }
}
