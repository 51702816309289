<div class="title-header" *ngTemplateOutlet="brandTitle"></div>
<ng-template #brandTitle>
  <h1 class="section-title title">
    <i nz-icon nzType="crown"></i>
    {{title}}
  </h1>
</ng-template>
<button nz-button nzType="primary" *ngIf="!hideCreate" class="create-content-btn" (click)="createCustomContent()">
  <i nz-icon nzType="appstore-add" nzTheme="outline"></i>Create a Post</button>
<div nz-row>
  <div nz-col nzSpan="24">
    <div class="nz-mb-0 calender-table-sa-ui">
      <div>
        <!-- <ng-container *ngTemplateOutlet="contentTabs"></ng-container> -->
        <nz-empty nzNotFoundContent="You haven’t created any content yet" *ngIf="contents && contents.length === 0"
          class="no-content"></nz-empty>

        <div nz-row nzType="flex" nzJustify="end" *ngIf="contents && contents.length && currentTab < 3"
          class="pagination-pad">
          <nz-pagination nzShowSizeChanger [nzPageSizeOptions]="pageSizeOptions" [nzPageSize]="contentPagination.limit"
            [nzPageIndex]="contentPagination.page" [nzTotal]="contentPagination.total"
            (nzPageIndexChange)="didPage($event)">
          </nz-pagination>
        </div>
      </div>
      <ng-template #content>
        <div>
          <!-- <nz-spin nzTip="Loading..." *ngIf="loading">
            <nz-alert nzType="info" nzMessage="Please wait"
              nzDescription="Scheduled content is being loaded..."> </nz-alert>
          </nz-spin> -->

          <div class="loading-container" *ngIf="loading">
            <nz-spin [nzSize]="'large'"></nz-spin>
          </div>
          <full-calendar *ngIf='calendarOptions' [options]='calendarOptions'></full-calendar>
        </div>
      </ng-template>
      <ng-template #brandTitle>
        <h1 class="section-title title">
          <i nz-icon nzType="crown"></i>
          Content Calendar
        </h1>
      </ng-template>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</div>
<div nz-row class="top-spacing">
<p >
  <strong class="red-bullets">
    *
  </strong> Content posted to the calendar is subject to change without prior notice.
</p>
<p class="left-space">
  <nz-badge nzColor="green" nzText="Post created by us."></nz-badge>
</p>
<p class="left-space">
  <nz-badge nzColor="pink" nzText="Post created by you."></nz-badge>
</p>
</div>
