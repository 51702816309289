import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Contact } from '../../vos/contact/contact';
import { ContactsService } from '../../services/contacts/contacts.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { ContactsUploaderComponent } from '../contacts-uploader/contacts-uploader.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'app-contacts-selector',
  templateUrl: './contacts-selector.component.html',
  styleUrls: ['./contacts-selector.component.scss']
})
export class ContactsSelectorComponent implements OnInit, OnChanges {
  @Input()
  preSelected;
  @Input()
  selectedPage;

  selection = [];
  isAllDisplayDataChecked = false;
  isOperating = false;
  isIndeterminate = false;
  listOfDisplayData: Contact[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  contactsDataSource = [];
  numberOfChecked = 0;
  totalContacts = 0;
  loading = false;
  paginatedContacts = [];
  contactList = [];
  searchText: string;
  selectedIds = [];
  page = 1;
  isAllContactsSelected = false;
  pageInfo = { total_entries: 0, per_page: 10, current_page: 1 };
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;

  @Input()
  set selected(val: number[]) {
    this.selection = val;
    val.forEach(item => {
      this.mapOfCheckedId[item] = true;
    });
  }

  get selected(): number[] {
    this.selection = this.contacts ? this.contacts?.filter(item => this.mapOfCheckedId[item.id]).map(c => c.id) : [];
    return this.selection;
  }

  @Output()
  selectedChange = new EventEmitter();
  @Output()
  allSelectedChange = new EventEmitter();
  @Output()
  onPageChange = new EventEmitter();

  @Input()
  contacts: Contact[];
  showingAdd = false;
  columns = [
    'first_name',
    'last_name',
    'phone',
    'email',
    'company',
    'address',
  ];
  get displayedColumns(): string[] {
    return ['select', ...this.columns];
  }
  listOfSelection = [
    {
      text: 'Select All',
      onSelect: () => {
        this.isAllContactsSelected = true;
        this.isAllDisplayDataChecked = true;
        this.mapOfCheckedId = {};
        this.refreshStatus();
        this.allSelectedChange.emit(true);
      }
    },
    {
      text: 'None',
      onSelect: () => {
        this.isAllContactsSelected = false;
        this.isAllDisplayDataChecked = false;
        this.mapOfCheckedId = {};
        this.refreshStatus();
        this.allSelectedChange.emit(false);
      }
    }
  ];
  constructor(private contactService: ContactsService,
    private message: NzMessageService,
    private modalService: NzModalService) { }

  ngOnInit() {
    this.page = this.selectedPage ? this.selectedPage : this.page;
    console
    this.getContacts();
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        if (newText) {
          this.getContacts(`?q[first_name_or_last_name_or_name_or_full_name_or_email_matches]=${newText}`);
        } else {
          this.getContacts();
        }
      });
    this.setPreSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preSelected && changes.preSelected.previousValue && changes.preSelected.currentValue &&
      changes.preSelected.currentValue.toString() !== changes.preSelected.previousValue.toString()) {
      this.setPreSelected();
    }
  }

  getContacts(searchQuery?) {
    this.loading = true;
    let queryParams = `?page=${this.page}`;
    if (searchQuery) {
      queryParams = `${searchQuery}&page=${this.page}`;
    }
    this.contactService.list(queryParams).subscribe((c: any) => {
      if (c) {
        this.contactsDataSource = c.data;
        this.paginatedContacts = c.data;
        this.pageInfo = { total_entries: c.total_entries, per_page: c.per_page, current_page: c.current_page };
        this.totalContacts = c.total_entries;
        this.loading = false;
        this.refreshStatus();
      }
    });
  }

  didPage(page: number) {
    this.page = page;
    this.onPageChange.emit(page);
    this.getContacts();
  }

  appendContacts(c: Contact | Contact[]) {
    this.getContacts();
  }

  addContacts(formState?) {
    const modal = this.modalService.create<ContactsUploaderComponent, IModalData>({
      nzContent: ContactsUploaderComponent,
      nzFooter: null,
      nzWidth: '70%',
      nzData: {
        formState
      }

    });

    modal.afterClose.subscribe(r => {
      // if (r) {
      this.appendContacts(r);
      // }
    });
  }
  currentPageDataChange($event: Contact[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.isAllContactsSelected || this.contactsDataSource.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.contactsDataSource.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
    let selctedKeys = Object.keys(this.mapOfCheckedId);
    selctedKeys.forEach(k => {
      if (!this.mapOfCheckedId[k]) {
        delete this.mapOfCheckedId[k];
      }
    });
    const selctedKeysCount = Object.keys(this.mapOfCheckedId).length;
    selctedKeys = Object.keys(this.mapOfCheckedId);
    this.numberOfChecked = this.isAllContactsSelected ? this.totalContacts : selctedKeysCount;
    const selection = this.contactsDataSource ? this.contactsDataSource.filter(item => this.mapOfCheckedId[item.id]).map(c => c.id) : [];
    this.selection = selctedKeys.map(id => parseInt(id, 10));
    this.selectedChange.emit(this.selection);
    this.loading = false;
  }

  setPageData() {
    const page = this.page - 1;
    this.paginatedContacts = this.contactsDataSource.slice(page * 10, (page + 1) * 10);
  }

  checkAll(value: boolean): void {
    this.contactsDataSource.forEach(item => {
      if (!value && this.mapOfCheckedId[item.id]) {
        delete this.mapOfCheckedId[item.id];
      } else {
        this.mapOfCheckedId[item.id] = value;
      }
    });
    this.refreshStatus();
  }

  deleteContacts(contact?) {
    let ids = this.selection || [];
    if (contact) {
      ids = [contact.id];
    }
    if (this.isAllDisplayDataChecked) {
      ids = [];
      this.contactsDataSource.forEach(item => {
        ids.push(item.id);
      });
    }
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete contacts?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contactService.deleteContacts({ contact_ids: ids }).subscribe(r => {
          this.message?.create('success', `Contacts has been successfully deleted.`);
          this.mapOfCheckedId = {};
          this.isAllDisplayDataChecked = false;
          this.refreshStatus();
          this.getContacts();
        }, e => {
          this.loading = false;
          this.message?.remove();
          this.message?.create('error', 'Error deleting the contact. Please try again');
        });
      }
    });
  }

  exportContacts() {
    this.contactService.exportContacts().subscribe(
      res => {
        const options = { type: `text/csv;charset=utf-8;` };
        this.downloadCsv(res, options, 'contacts.csv');
      }
    );
  }

  downloadCsv(body, options, filename) {
    const blob = new Blob([body], options);
    const _navi = navigator as any
    if (_navi.msSaveBlob) {
      // IE 10+
      _navi.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document?.body.appendChild(link);
        link.click();
        document?.body.removeChild(link);
      }
    }
  }

  setPreSelected() {
    if (this.preSelected) {
      this.preSelected.forEach(id => {
        this.mapOfCheckedId[id] = true;
      });
      this.refreshStatus();
    }
  }

  operateData(): void {
    this.isOperating = true;
    setTimeout(() => {
      this.contacts.forEach(item => (this.mapOfCheckedId[item.id] = false));
      this.refreshStatus();
      this.isOperating = false;
    }, 1000);
  }
}
