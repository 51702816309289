import { Image } from '../image/image';
import { Resource } from '../resource/resource';
import { WebsiteAttributes } from 'src/app/shared/interfaces/website-attributes';
import { Product } from '../product/product';

export class TeamMember {
  id: number;
  name: string;
  title: string;
  email: string;
  phone: string;
  office_phone: string;
  license: string;
  website_url: string;
  image: Image = new Image();
  remote_image_url?: string;
  _destroy = 0;
  new = true;
}

export class Integration {
  id?: number;
  provider: string;
  status: string;
  token: string;
  expired?: boolean;
  share_with_team: boolean;
  auto_posting?: boolean;
  user_id_on_provider: any;
  meta_data: { [key: string]: string | any };
  display_name: string;
  error_message?: string;
  _destroy = 0;
  constructor(vals: any = {}) {
    Object.assign(this, vals);
  }
  statusMessage(): string {
    switch (this.status) {
      case 'failed':
        return this.error_message || 'Failed to add MLS Account';
      case 'pending':
        return 'Pending MLS Verification.\n(Usually takes 1-2 days)';
      case 'active':
        return 'MLS Account Connected and Active';
      default:
        return this.status.capitalize();
    }
  }
}

export class Customer extends Resource {
  /**
   * id
   */
  id: number;

  crm_id: string;

  billing_provider: string;

  products: Product[];
  /**
   * first_name
   */
  first_name: string;

  /**
   * last_name
   */
  last_name: string;

  /**
   * email
   */
  email: string;

  test: boolean;
  is_multiple_websites: boolean;

  password?: string;
  password_confirmation?: string;
  /**
   * office_phone
   */
  office_phone: string;

  /**
   * primary_phone
   */
  primary_phone: string;

  /**
   * primary_city
   */
  primary_city: string;

  /**
   * primary_region
   */
  primary_region: string;

  /**
   * primary_state
   */
  primary_state: string;

  /**
   * primary_zip
   */
  primary_zip: string;

  /**
   * display_name
   */
  display_name: string;

  /**
   * display_phone
   */
  display_phone: string;

  /**
   * display_email
   */
  display_email: string;

  /**
   * preferred_title
   */
  preferred_title: string;

  /**
   * brokerage
   */
  brokerage: string;

  fb_page_id: string;

  /**
   * credential
   */
  credential: string;

  linkedin_url?: string;
  fb_page_url?: string;
  fb_business_url?: string;
  instagram_url?: string;
  google_business_url?: string;
  youtube_url?: string;
  youtube?: any;
  license_number: string;
  nar_license?: string;
  tiktok_url?: string;
  time_zone?: string;

  /**
   * headshot
   */
  image: Image;

  /**
   * logo
   */
  logo: Image;

  /**
   * url for new headshot
   */
  remote_image_url: string;

  /**
   * url for new logo
   */
  remote_logo_url: string;

  team_name: string;

  team_phone: string;

  team_photo: Image;

  team_members_attributes: TeamMember[];
  images: Image[];
  images_attributes?: Image[];
  websites_attributes: WebsiteAttributes[];
  sign_in_count: number;
  last_sign_in_at: Date;
  confirmation_sent_at: Date;
  confirmed_at: Date;
  tracking: { [key: string]: any };
  settings: { [key: string]: any };
  website_url: string;
  trial_active: boolean;
  onboarded: boolean;
  needs_password_reset: boolean;
  days_left: number;
  source: string;
  tier: string;
  uid: string;
  region?: any;
  campaign_name: string;
  accepted_tos: boolean;
  render_only: boolean;
  account_name: string;
  ask_for_nps: boolean;
  slug: string;
  qrcode_url: string;
  allow_stripe_for_team: boolean;
  isGhl: boolean;
  constructor(vals: any = { images: [] }) {
    super(vals);
    if (!this.images) {
      this.images = [];
    }
    this.images.length = 5;
    this.settings = !this.settings ? { auto_posting: true, text_color: '#222222', bg_color: '#FFFFFF' } : this.settings;
  }
}
