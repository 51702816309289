import {Resource} from '../resource/resource';

export class OnboardingStep extends Resource {
  id: number;
  name: string;
  description: string;
  icon: string;
  locked: boolean;
  completed: boolean;
  path: string;
  video_url: string;
  step_code: string;
  step_number: number;
}
