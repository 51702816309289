import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Lead } from 'src/app/vos/lead/lead';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */

@Injectable()
/**
 * Service class.
 */
export class TipsService extends CustomerResourceService<Lead> {

  public endpoint = 'marketing_tips';
  public data_key = 'marketing_tips';

  public _urls = `${environment.api_url}`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Lead, http, authService);
  }

}
