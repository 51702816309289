import { Resource } from '../resource/resource';
import moment from 'moment';

export interface StripeCoupon {
  id: string;
  object: string;
  amount_off: number;
  created: number;
  currency: string;
  duration: string;
  duration_in_months: number;
  livemode: boolean;
  max_redemptions: number;
  metadata: any;
  name: string;
  percent_off: number;
  redeem_by: number;
  times_redeemed: number;
  valid: boolean;
}

export interface StripePlan {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: any;
  amount: number;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: any;
  nickname: string;
  product: string;
  tiers: any;
  tiers_mode: any;
  transform_usage: any;
  trial_period_days: number;
  usage_type: string;
}

export class Subscription extends Resource {
  id: string;
  object: string;
  application_fee_percent: any;
  billing: string;
  billing_cycle_anchor: number;
  billing_thresholds: any;
  cancel_at: any;
  cancel_at_period_end: boolean;
  canceled_at: any;
  created: number;
  coupon: string;
  current_period_end: moment.Moment;
  current_period_start: moment.Moment;
  customer: string;
  days_until_due: any;
  default_source: string;
  discount: any;
  ended_at: any;
  items: any;
  latest_invoice: string;
  livemode: boolean;
  metadata: any;
  plan: StripePlan;
  quantity: number;
  schedule: any;
  start: number;
  status: string;
  tax_percent: any;
  trial_end: any;
  trial_start: any;
  get trial_end_date(): number {
    return moment.unix(this.trial_end).valueOf();
  }
  get is_paid_active(): boolean {
    return !this.cancel_at_period_end && (this.status === 'active' || this.status === 'trialing');
  }
  constructor(vals) {
    vals[ 'current_period_start' ] = moment.unix(vals[ 'current_period_start' ]);
    vals[ 'current_period_end' ] = moment.unix(vals[ 'current_period_end' ]);
    super(vals);

  }
}

