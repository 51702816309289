<swiper [config]="swiperConfig" #usefulSwiper class="swiper-c mt-25">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let fileUrl of files">
      <div mat-card-image>
        <img [src]="fileUrl"  class="internal-content image-content" />
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</swiper>
