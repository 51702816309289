<div class="table-container mat-elevation-z1">
  <!-- <div class="text-center m-5" *ngIf="(!contactsDataSource || (contactsDataSource && !contactsDataSource.length)) && !loading">
    No contacts found! Please add contacts!
  </div> -->
</div>
<div class="operate">
  <div class="contact-actions">
    <!-- <button nz-button nzType="primary" class="add-contacts-button add-csv-contacts contact-btn" (click)="addContacts()">Add Contacts</button>
    <button nz-button nzType="default" class="contact-btn" *ngIf="!contacts" (click)="exportContacts()">Export Contacts</button>
    <button nz-button nzType="default" class="add-contacts-button add-csv-contacts" *ngIf="isIndeterminate || isAllDisplayDataChecked"
      (click)="deleteContacts()">Delete Contacts</button> -->


    <button class="add-contacts-button add-csv-contacts contact-btn"  nz-button nz-dropdown [nzDropdownMenu]="menu4">
      Add Contacts
      <i nz-icon nzType="down"></i>
    </button>

    <nz-dropdown-menu #menu4="nzDropdownMenu">
      <ul nz-menu>
        <li (click)="addContacts(1)" nz-menu-item>
          <i nz-icon nzType="form" nzTheme="outline"></i>
          Add 1 Contact</li>
          <li (click)="addContacts(2)" nz-menu-item>
            <i nz-icon nzType="form" nzTheme="outline"></i>
            Add From CSV</li>
      </ul>
    </nz-dropdown-menu>
    <span *ngIf="numberOfChecked">Selected {{ numberOfChecked }} contacts</span>
  </div>
  <div nz-row class="search" *ngIf="contactsDataSource">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="input search text" [ngModel]="searchText"
        (ngModelChange)='searchModelChanged.next($event)' />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</div>
<nz-table nzNoResult="" nzTemplateMode *ngIf="contactsDataSource && contactsDataSource.length">
  <thead>
    <tr>
      <th nzShowCheckbox nzShowRowSelection [nzSelections]="listOfSelection" [(nzChecked)]="isAllDisplayDataChecked"
        [nzIndeterminate]="isIndeterminate" (nzCheckedChange)="checkAll($event)" [nzDisabled]="isAllContactsSelected">
      </th>
      <th *ngFor="let field of columns">{{field.titleize()}}</th>
      <th class="text-center">Subscribed</th>
      <th class="text-center">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of contactsDataSource">
      <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.id]" *ngIf="!isAllContactsSelected"
        (change)="refreshStatus()"></td>
      <td nzShowCheckbox [(nzChecked)]="isAllContactsSelected" (nzCheckedChange)="refreshStatus()"
        *ngIf="isAllContactsSelected" [nzDisabled]="isAllContactsSelected"></td>
      <td *ngFor="let field of columns">{{ data[field] }}</td>
      <td class="ads-text-style text-center">
        <i nz-icon nzType="check" nzTheme="outline" *ngIf="data.subscribing_to_email"></i>
        <i nz-icon nzType="close" nzTheme="outline" *ngIf="!data.subscribing_to_email"></i>
      </td>
      <td class="ads-text-style text-center">
        <i (click)="deleteContacts(data)" nz-icon nzType="delete" class="delete-contact" nzTheme="fill"></i>
      </td>
    </tr>
  </tbody>
</nz-table>
<nz-empty *ngIf="contactsDataSource && !contactsDataSource.length"
  nzNotFoundContent="No contacts found! Please add contacts!"></nz-empty>
<div nz-row nzType="flex" nzJustify="end" style="margin-top: 30px;">
  <nz-pagination [nzPageIndex]="page" [nzTotal]="pageInfo.total_entries" nzPageSize="10" [nzShowTotal]="rangeTemplate"
    (nzPageIndexChange)="didPage($event)"></nz-pagination>
  <ng-template #rangeTemplate let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} of {{ total }} items
  </ng-template>
</div>
<div class="spinner" *ngIf="loading">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>