import moment from 'moment';
import {Resource} from '../resource/resource';
import {Product} from '../product/product';

class LocationAttributes extends Resource {
  zip_code: string;
}

export class MarketReport extends Resource {

    /**
     * id
     */
    id?: number;
    primary: boolean;

    /**
     * region_name
     */
    region_name: string;

    /**
     * zips
     */
    // zips: string[];
    zip: string;
    products: Product[];
    location_attributes:  LocationAttributes = new LocationAttributes();
    /**
     * last_made
     */
    // last_made: moment.Moment;

    /**
     * active
     */
    // active: boolean;

    /**
     * customer_id
     */
    customer_id: number;

    /**
     * created_at
     */
    created_at?: moment.Moment;

    /**
     * updated_at
     */
    // updated_at: moment.Moment;

    constructor(vals: any = {}) {
      super(vals);
      Object.assign(this, vals);
    }

}
