<ng-template #contentpreview>
  <div class="steps-content congrats-container" *ngIf="isRescheduled">
    <div class="congrats">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      <h2>Success</h2>
      <p>Your post has been rescheduled successfully.</p>
    </div>
    <nz-divider></nz-divider>
    <div class="ok-btn">
      <button nz-button nzType="primary" (click)="isRescheduled = false">Ok</button>
    </div>
  </div>
  <div *ngIf="!linkedinSuccess && !facebookSuccess && !isRescheduled">
    <div nz-row class="form-body">
      <div nz-col nzSpan="24" class="p-0 text-center">
        <div mat-card-image>
          <img [src]="feed.image_url" [alt]="feed.title" class="internal-content image-content" />
        </div>
      </div>
      <div nz-col nzSpan="24" class="left-spacing content-details-section" *ngIf="showActions">
        <div nz-row *ngIf="isEdit" class="edit-form-container">
          <div nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" *ngIf="showCaption">
            <span nz-typography>Caption:
            </span>
            <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="caption-item top-spacing">
              <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
                <nz-spin [nzSpinning]="aiLoading" [nzDelay]="500">
                  <textarea nz-input class="form-control content-caption-area" id="caption" [(ngModel)]="model.caption"
                    name="caption" required nzAutosize maxlength="2200" (ngModelChange)="onInput($event)" (keypress)="handlePreventDefault($event)"></textarea>
                </nz-spin>
              </nz-form-control>
              <span class="remaning">{{model.caption?.length ? model.caption?.length : 0}} / 2200</span>
              <div style="color: red;" *ngIf="model.caption?.length >= 2200">
                Caption must not exceed 2200 characters.
              </div>
              <div style="color: red;" *ngIf="isHashtagLimit">
                Hashtag Limit Exceeded: You've reached the maximum allowed number of hashtags. Limit: 30 hashtags
              </div>
              <button nz-dropdown nz-button nzTrigger="click" class="align-right" nzType="text" nzBlock
                [(nzVisible)]="showEmojiPicker" [nzDropdownMenu]="menu" nzPlacement="bottomRight"
                (click)="toggleEmojiPicker()">
                😀
              </button>
              <div class="hashtag">
                <span># {{ hashtags?.length ? hashtags?.length : 0}} / 30</span>
              </div>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <emoji-mart class="emoji-mart" set="{{set}}" isNative="false" (emojiSelect)="addEmoji($event);" title="Pick your emoji…">
                </emoji-mart>
              </nz-dropdown-menu>
            </nz-form-item>
          </div>
          <!-- <div nz-row > -->
          <div *ngIf="isScheduledPost" nz-col [nzLg]="12" [ngClass]="{
          'has-caption':showCaption}" class="schedule-post">
            <span nz-typography>Select a date and time in the future to post your content.</span>
            <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24" class="top-spacing">
              <nz-form-label [nzSm]="24" [nzXs]="24" for="scheduled_for_date">Date
              </nz-form-label>
              <!-- [nzDisabled]="!config.content.last_post" -->
              <nz-form-control [nzSm]="24" [nzXs]="24">
                <nz-date-picker class="schedule-picker_input" nzFormat="MM-dd-y" [(ngModel)]="schedule_date"
                  name="scheduled_for_date" [nzDisabledDate]="disabledDate" (ngModelChange)="changeDate($event);">
                </nz-date-picker>
                <h5 class="schedule-date-info">
                  <span nz-typography>you are only allowed to schedule within 2 months (60 days).</span>
                </h5>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item appearance="fill" nz-col [nzSm]="24" [nzXs]="24" [nzMd]="24">
              <nz-form-label [nzSm]="24" [nzXs]="24" for="scheduled_for_time">Time
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" *ngIf="!isDateLoading">

                <nz-time-picker class="schedule-picker_input" [nzDisabled]="!schedule_date" [(ngModel)]="schedule_time"
                  name="scheduled_for_time" [nzUse12Hours]="true" nzFormat="h:mm a" [nzDisabledHours]="disabledHours"
                  (ngModelChange)="changeTime($event);">
                </nz-time-picker>
              </nz-form-control>
              <nz-form-control [nzSm]="24" [nzXs]="24" *ngIf="isDateLoading">
                <nz-time-picker class="schedule-picker_input" name="scheduled_for_time_temp" [nzUse12Hours]="true"
                  nzFormat="h:mm a">
                </nz-time-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="left-spacing" nz-col [nzLg]="isScheduledPost ? 12: 24">
            <app-select-social-platform [submitted]="submitted" class="spacing-bottom" [isRssFeed]="true" [config]="socialConfig">
            </app-select-social-platform>
          </div>
        </div>

      </div>
    </div>

    <div nz-row nzType="flex" nzJustify="space-between" class="actions" *ngIf="isEdit && showActions">
      <button nz-button nzType="default" class="action-btn" (click)="removeDialog();">Cancel</button>
      <button nz-button nzType="primary" class="action-btn-primary" *ngIf="isScheduledPost"
        [disabled]="!((facebook || linkedin || feed.url) && schedule_time && schedule_date)"
        (click)="post()">Post</button>
      <button nz-button nz-dropdown nzType="primary" [disabled]="!model.caption || (!feed.url) || isHashtagLimit"
        [nzDropdownMenu]="menu4" *ngIf="!isScheduledPost">
        Post
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #menu4="nzDropdownMenu">
        <ul *ngIf="model.caption && !isHashtagLimit" nz-menu>
          <li (click)="isScheduledPost = true" nz-menu-item>
            <i nz-icon nzType="field-time" nzTheme="outline"></i>
            Schedule
          </li>
          <li nz-menu-item (click)="post()">
            <i nz-icon nzType="share-alt" [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}"
              class="down-icon" (click)="post()"></i>
            Post Now
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>

  <div class="steps-content congrats-container" *ngIf="facebookSuccess || linkedinSuccess">
    <div class="congrats">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      <h2>Success</h2>
      <p>Your post has been made successfully.</p>
    </div>
    <nz-divider></nz-divider>
    <div class="ok-btn">
      <button nz-button nzType="primary" (click)="removeDialog()">Ok</button>
    </div>
  </div>
</ng-template>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="contentpreview"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="contentpreview"></ng-container>
</ng-template>
