import { Component, Input, Output, OnInit, AfterViewChecked } from '@angular/core';
import { ViewChild, EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Listing } from '../../vos/listing/listing';
import { NgForm, ValidationErrors } from '@angular/forms';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormGroup, FormArray } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';
import { GlobalsService } from '../../services/globals/globals.service';

@Component({
  selector: 'app-commercial-property-type-selector',
  templateUrl: './commercial-property-type-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./commercial-property-type-selector.component.scss']
})

export class CommercialPropertyTypeSelector implements OnInit, AfterViewChecked {

  // Output
  @Output() validChange = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @Output() errors = new EventEmitter<ValidationErrors>();

  // Input
  @Input() valid = false;
  @Input() model = new Listing();
  selectedType;
  commercialPropertyTypes = [];

  constructor(
    private globalService: GlobalsService
  ) { }

  ngAfterViewChecked() {
  }
  ngOnInit() {
    this.commercialPropertyTypes = this.globalService.getCommercialPropertyTypes();
  }

  selectProperty(property) {
    this.model.listing_type = property?.title;
    this.selectedType = property?.value;
    this.change.emit(property);
  }

}
