<div class="overlay" *ngIf="config.buttonConfigs && !config.unavailable" nz-row nzType="flex" nzJustify="start">
  <button type="button" *ngFor="let bConf of config.buttonConfigs; index as i" class="upload-button"
    [class.upload-button-dark]="i % 2 === 1" [class.upload-button-light]="i % 2 === 0" fxFlex
    (click)="goToPath(bConf.path)">{{bConf.name}}
  </button>
</div>
<div class="card-body {{cardType}}" [style.backgroundColor]=" bgColor" [style.color]="textColor"
  [class.selected]="selected" (click)="onClick()">
  <nz-skeleton [nzAvatar]="{ shape: 'square' }" [nzParagraph]="{ rows: 2 }" class="card-skeleton" [nzLoading]="config.isSkeleton">
    <div nz-row nzType="flex" nzJustify="space-between"
      class="card-content {{disabled ? 'd-opacity' : 'f-opacity'}}" fxFill>
      <div *ngIf="config.topText" class="center-text">{{config.topText}}</div>
      <span class="card-icon-wrapper" *ngIf="config.icon && !config.antIcon && !config.image">
        <!-- <fa-icon [icon]="config.unavailable ? disabledIcon : icon" size="6x"></fa-icon> -->
      </span>
      <span class="card-icon-wrapper other-icons" *ngIf="config.antIcon && !config.icon && !config.image">
        <i nz-icon class="card-ant-icon" [nzType]="config.antIcon"
          [nzTheme]="config.antTheme ? config.antTheme : 'outline'"></i>
      </span>
      <img *ngIf="config.image && !config.icon && !config.antIcon" [src]="config.image" class="card-image" />
      <div *ngIf="config.centerText" class="center-text" [class.full-width]="!config.title && !config.actionText">
        {{config.centerText}}</div>
      <div class="title" *ngIf="config.title">
        {{config.title}}
      </div>
      <div class="action" *ngIf="!config.title">
        {{config.actionText}}
      </div>
      <div class="description" *ngIf="config.description">
        <!--{{config.description}}-->
        {{truncateChar(config.description)}}
      </div>
      <span class="card-date" *ngIf="config.createdAt">
        {{config.createdAt | date : "MM/dd/y"}}
      </span>
      <i nz-icon nzType="right" class="next-icon" nzTheme="outline" *ngIf="!!cardType"></i>
    </div>
  </nz-skeleton>
</div>
