<div class="container cont-card">
  <nz-card [style.width]="width" [style.marginBottom]="verticalMargin" [style.marginRight]="horizontalMargin"
    fxFlex="100%" (click)="setContectCard($event,content)"
    [className]="content.selected ? 'content-card img-card active': 'content-card img-card'" [nzExtra]="extraTemplate">
    <ng-template #extraTemplate>
      <i *ngIf="content.selected" class="selected-icon" nz-icon nzType="check-circle" nzTheme="fill"></i>
    </ng-template>
    <nz-card-meta [nzTitle]="content.caption" style="margin-bottom: 10px;"></nz-card-meta>

    <div *ngIf="content.status === 'rendering'">
      <div class="card-image">
        <img class="internal-content" src="assets/styles/Rendering.gif">
      </div>
    </div>
    <div *ngIf="content.status !== 'rendering'">
      <div *ngIf="content.media_type === 'image'" class="card-image">
        <img [src]="content.url" [alt]="content.caption" class="internal-content" *ngIf="content.url">
        <p *ngIf="isCoverPhotos" class="disclaimer" [style.color]=" content.caption === 'Rapid' ? '#29c388' : '#fff' ">
<!--          <i nz-icon nzType="info-circle" nzTheme="outline"></i>-->
<!--          Disclaimer: This style is for the new Facebook Page Experience layout.-->
        </p>
        <nz-empty *ngIf="!content.url" nzNotFoundContent="Style Missing" class="style-missing">
        </nz-empty>
      </div>
      <video preload="none" class="content-video" *ngIf="content.media_type === 'video' && !content.is_style" controls>
        <source [src]="content.url">
      </video>
      <video class="content-video" *ngIf="content.media_type === 'video' && content.is_style" autoplay loop>
        <source [src]="content.url">
      </video>
    </div>
    <div nz-row nzType="flex" nzJustify="end">
      <a nz-button *ngIf="content.cta_link" [href]="content.cta_link" nzType="link">Learn More</a>
    </div>
  </nz-card>
  <ng-template #avatarTemplate>
    <nz-avatar *ngIf="customer && customer.image" [nzSrc]="customer.image.thumb"></nz-avatar>
  </ng-template>
</div>
