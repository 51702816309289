<!-- <app-sidebar> -->
  <nz-collapse nzBordered="false" class="is_mobile">
    <nz-collapse-panel nzActive [nzHeader]="listingTitle">
      <h1 class="section-title section-title-small" *ngIf="!isChild">
        Add/Edit Listings
      </h1>
      <h5 class="section-description" *ngIf="!isChild">
        Create a listing before you make content for it.
      </h5>
      <div nz-row nzGutter="16" class="filters">
        <div class="search gutter-row" nz-col nzSpan="10">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="input search text" [ngModel]="searchText" (ngModelChange)='searchModelChanged.next($event)'/>
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
        </div>
          <button nz-button nz-dropdown [nzDropdownMenu]="mobilemenu4" (click)="toggelSortOrder()" nz-col class="gutter-row" nzSpan="8">
              <span>Sort By {{selectedSort.by | titlecase}}</span>
          <i nz-icon [nzType]=" this.selectedSort.order === 'asc' ? 'down' : 'up'"></i>
          </button>

          <nz-dropdown-menu #mobilemenu4="nzDropdownMenu">
              <ul nz-menu>
                  <li nz-menu-item *ngFor="let sort of sortings" (click)="setSortOption(sort)">
                    <a>Listing {{sort | titlecase}}</a>
                  </li>
                </ul>
          </nz-dropdown-menu>
      </div>
      <div nz-row nzType="flex" nzJustify="center">
        <app-card-button [config]="newCardButton" *ngIf="!isChild"></app-card-button>
        <nz-spin nzSimple [nzSize]="'large'" *ngIf="loading"></nz-spin>
        <ng-container *ngFor="let listing of listings">
          <app-card-button (click)="clickCard(listing)" [config]="configFor(listing)"></app-card-button>
        </ng-container>
      </div>
      <router-outlet></router-outlet>
    </nz-collapse-panel>
  </nz-collapse>

  <div class="is_desktop">
    <h1 class="section-title section-title-small" *ngIf="!isChild; else notchild">
      Add/Edit Listings
    </h1>
    <ng-template #notchild>
      <div class="title-header" *ngTemplateOutlet="listingTitle"></div>
    </ng-template>
    <h5 class="section-description" *ngIf="!isChild">
      Create a listing before you make content for it.
    </h5>

    <div nz-row class="search filters" *ngIf="listings">
      <nz-input-group [nzSuffix]="suffixIconSearch" nz-col nzSpan="8">
          <input type="text" nz-input placeholder="Search By Address" [ngModel]="searchText" (ngModelChange)='searchModelChanged.next($event)'/>
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>

        <button nz-button nz-dropdown [nzDropdownMenu]="menu4" (click)="toggelSortOrder()" class="sort gutter-row" nzOffset="2" nz-col nzSpan="4">
          <span>Sort By {{selectedSort.by | titlecase}}</span>
          <i nz-icon [nzType]=" this.selectedSort.order === 'asc' ? 'down' : 'up'"></i>
        </button>
        <nz-dropdown-menu #menu4="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item *ngFor="let sort of sortings" (click)="setSortOption(sort)">
                  <a>Listing {{sort | titlecase}}</a>
                </li>
              </ul>
        </nz-dropdown-menu>
  </div>
      <nz-spin [nzSize]="'large'" *ngIf="loading;else notloading">
        <ng-container *ngTemplateOutlet="Listings"></ng-container>
      </nz-spin>
      <ng-template #notloading>
        <ng-container *ngTemplateOutlet="Listings"></ng-container>
      </ng-template>
      <div nz-row nzType="flex" nzJustify="end">
        <nz-pagination nzShowSizeChanger [nzPageSizeOptions]="pageSizeOptions" [nzPageSize]="listingPagination.limit" [nzPageIndex]="listingPagination.page" [nzTotal]="listingPagination.total" (nzPageIndexChange)="didPage($event)">
        </nz-pagination>
      </div>
    <router-outlet></router-outlet>
  </div>

  <ng-template #listingTitle>
    <h1 [className]="ConfigListingTitle.class" *ngIf="!isChild">
      <!-- <mat-icon class="nav-icon" [style.color]="ConfigListingTitle.colors">{{ConfigListingTitle.icon}}</mat-icon> -->
      {{ConfigListingTitle?.title}}
    </h1>
    <nz-divider class="m-0"></nz-divider>
  </ng-template>
  <ng-template #Listings>
    <div nz-row nzType="flex" nzJustify="start">
    <app-card-button [config]="newCardButton" *ngIf="!isChild"></app-card-button>
    <ng-container *ngFor="let listing of listings">
      <app-card-button (click)="clickCard(listing)" pendo-data-id="listing" [config]="configFor(listing)"></app-card-button>
    </ng-container>
    </div>
  </ng-template>
<!-- </app-sidebar> -->
