<div nz-row>
  <div nz-col nzSpan="24">
    <div class="nz-mb-0">
        <nz-alert
        nzType="warning"
        nzMessage="Warning"
        nzDescription="Please be aware that including your website link, email, or phone number in the caption of a Google Business post will likely result in that post not going through."
        nzShowIcon
        class="mgb-12"
        nzCloseable="true"
        *ngIf="showGMB"
      ></nz-alert>
      <ng-template #content>
        <div >
          <div nz-row>
            <div nz-col nzSpan="5">
              <label class="d-block">Post Type:</label>
              <nz-select [(ngModel)]="selectedValue" nzAllowClear nzPlaceHolder="Post Type"
                (ngModelChange)="changeTypeSelection()" nzShowSearch>
                <nz-option nzValue="all" nzLabel="All"></nz-option>
                <nz-option nzValue="Listing" nzLabel="Listing"></nz-option>
                <nz-option nzValue="Marketing" nzLabel="Marketing"></nz-option>
                <nz-option nzValue="Market Report" nzLabel="Market Report"></nz-option>
                <nz-option *ngFor="let option of filterOptions" [nzValue]="option" [nzLabel]="option"></nz-option>
              </nz-select>
            </div>
            <div nz-col nzSpan="5">
              <label class="d-block">Created at:</label>

              <nz-range-picker [(ngModel)]="dateFilter" nzAllowClear (ngModelChange)="onChangeDateFilter($event)">
              </nz-range-picker>
            </div>
            <div nz-col nzSpan="6">

              <div class="search">
                <label class="d-block">Search(Address, Zip, Region):</label>

                <nz-input-group [nzSuffix]="suffixIconSearch">
                  <input type="text" nz-input placeholder="Search" [ngModel]="searchText"
                    (ngModelChange)='searchModelChanged.next($event)' />
                </nz-input-group>
                <ng-template #suffixIconSearch>
                  <i nz-icon nzType="search"></i>
                </ng-template>
              </div>
            </div>
            <div nz-col nzSpan="5" class="search">
              <label class="d-block">Content Type:</label>
              <nz-select [(ngModel)]="selectedContentType" nzAllowClear nzPlaceHolder="Post Type"
                (ngModelChange)="changeIndex($event)" nzShowSearch>
                <nz-option *ngFor="let option of contentTypes" [nzValue]="option" [nzLabel]="option"></nz-option>
              </nz-select>
            </div>
          </div>
          <nz-table [nzLoading]="loading" nzNoResult="" nzTemplateMode>
            <thead (nzSortOrderChange)="sort($event)">
              <tr>
                <th>
                  Preview
                </th>
                <th>Caption</th>
                <!-- <th>Post Status</th> -->
                <th nzShowSort nzColumnKey="created_at">Created Date</th>
                <th nzShowSort nzColumnKey="created_at">Published Date</th>
                <!-- <th nzShowSort nzColumnKey="created_at">Scheduled Date</th> -->
                <th class="text-center ">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let content of contents" (click)="selectContent(content);" [ngClass]="{
                'highlighted': currentContentId == content.id || newId == content.id
              }" class="selectable">
                <td>
                  <app-content-preview-item (onClose)="onClosePreview($event)" [content]="content">
                  </app-content-preview-item>
                </td>
                <td>{{ truncateChar(content.caption) }}</td>
                <!-- <td class="capitalize">{{(content.last_post && content.last_post.id) ?
                  ((!content.last_post?.scheduled_for
                  && content.last_post?.status ==='scheduled' ? content.status : content.last_post.status )) :
                  (content.status)}}</td> -->
                <td>{{ content.created_at | date : "MM-dd-y"}}</td>
                <td>
                  <span *ngIf="content.last_post?.posted_at">
                    {{content.last_post.posted_at | date : "MM-dd-y h:mm a"}}
                  </span>
                  <span *ngIf="!content.last_post || (content.last_post && !content.last_post?.posted_at )">
                    N/A
                  </span>
                </td>
                <!-- <td>
                  <span
                    *ngIf="content.last_post && content.last_post?.scheduled_for && content.last_post?.status !== 'successful'">
                    {{content.last_post?.scheduled_for | date : "MM-dd-y h:mm a"}}
                  </span>
                  <span
                    *ngIf="content.last_post && !content.last_post?.scheduled_for ||  content.last_post?.status !== 'scheduled'">
                    N/A
                  </span>
                </td> -->
                <td class="text-center ">
                  <button nz-button nzType="default" class="{{currentContentId == content.id ? 'active-btn ' : ''}}">
                    <i nz-icon nzType="check" nzTheme="outline" class="checked-icon"
                      *ngIf="currentContentId == content.id "></i>
                    {{currentContentId == content.id ? 'Selected' : 'Select'}}</button>
                  <!-- <button nz-button nz-dropdown [nzDropdownMenu]="menu4">
                    Select
                    <i nz-icon nzType="down"></i>
                  </button>
                  <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item nz-tooltip
                        [nzTooltipTitle]=" content.status === 'ready' ? 'Ready for Post' : 'Content is not ready for Post'">

                        <i nz-icon nzType="share-alt"
                          [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}" class="down-icon"
                          (click)="post(content)"></i>
                        Post
                      </li>
                      <li nz-menu-item nz-tooltip
                        [nzTooltipTitle]="content.status === 'ready' ? 'Ready for Download' : 'Content is not ready for Download'">
                        <span>
                          <i nz-icon nzType="download"
                            [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}" class="down-icon"
                            (click)="download(content)"></i>
                        </span> Download
                      </li>
                      <li (click)="cancelSchedule(content)"
                        *ngIf="content.status === 'ready' && content.last_post && content.last_post.id && content.scheduled_for"
                        nz-menu-item> <i nz-icon nzType="close" class="delete-icon" nzTheme="outline"></i> Cancel
                        Schedule</li>
                      <li (click)="showPreview(content, true)" nz-menu-item
                        *ngIf="content.status === 'ready' && content.last_post && content.last_post.id && content.scheduled_for">
                        <i [ngStyle]="{'color': '#0170f7'}" nz-icon nzType="edit" nzTheme="fill"></i> Edit</li>
                      <li (click)="contentDeleted(content)" nz-menu-item> <i nz-icon nzType="delete" class="delete-icon"
                          nzTheme="fill"></i> Delete</li>
                    </ul>
                  </nz-dropdown-menu> -->
                </td>
              </tr>
            </tbody>
          </nz-table>
          <nz-empty nzNotFoundContent="You haven’t created any content yet" *ngIf="contents && contents.length === 0"
          class="no-content"></nz-empty>
        </div>
        <div *ngIf="currentTab === 3">

          <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
            <scheduled-content-calendar [calendarData]="calenderContents"></scheduled-content-calendar>
          </nz-spin>
          <ng-template #notloading>
            <scheduled-content-calendar [calendarData]="calenderContents"></scheduled-content-calendar>
          </ng-template>

        </div>


      </ng-template>
      <ng-template #brandTitle>
        <h1 class="section-title title">
          <i nz-icon nzType="crown"></i>
          My Content
        </h1>
      </ng-template>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div nz-row nzType="flex" nzJustify="end" *ngIf="contents && contents.length && currentTab < 3"
      class="pagination-pad">
      <nz-pagination [nzPageSize]="contentPagination.limit"
        [nzPageIndex]="contentPagination.page " [nzTotal]="contentPagination.total" (nzPageIndexChange)="didPage($event)">
      </nz-pagination>
    </div>
  </div>
</div>
