<div class="container">
  <form #marketReportForm="ngForm" fxLayout="column" fxLayoutAlign="start stretch">
    <nz-form-item *ngIf="!limitShow || showFields.includes('region_name')" fxFlex="40%">
      <input nz-input placeholder="Region Name" nzSize="large" id="region_name" [(ngModel)]="model.region_name"
        name="region_name" maxlength="65" (ngModelChange)="checkForm()"/>
    </nz-form-item>
    <nz-form-item *ngIf="!limitShow || showFields.includes('zip')" fxFlex="40%">
      <input nz-input placeholder="Zip Code" nzSize="large" id="zip" [(ngModel)]="model.location_attributes.zip_code" name="zip"
        maxlength="65" (ngModelChange)="add($event)"/>
    </nz-form-item>
  </form>
</div>
