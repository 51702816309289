import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import Swiper, { SwiperOptions, Autoplay } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';


Swiper.use([Autoplay]);
@Component({
  selector: 'app-content-preview-carousel',
  templateUrl: './content-preview-carousel.component.html',
  styleUrls: ['./content-preview-carousel.component.scss']
})
export class ContentPreviewCarouselComponent implements OnInit {
  @ViewChild('usefulSwiper', { static: false }) usefulSwiper: SwiperComponent;

  @Input()
  files;

  swiperConfig: SwiperOptions = {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
  };

  constructor(
  ) { }
  ngOnInit() { }
}
