import { Component, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

import { EmailDomainService } from '../../services/email-domain/email-domain.service';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { JwtService } from '../../services/jwt/jwt.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { SenderIdentity } from '../../vos/sender-identity/sender-identity';
import { EmailSenderIdentityService } from '../../services/email-sender-identity/email-sender-identity.service';
import { ProductsService } from '../../services/products/product-api.service';

@Component({
  selector: 'app-onboarding-email',
  templateUrl: './onboarding-email.component.html',
  styleUrls: ['./onboarding-email.component.scss']
})

export class OnboardingEmailComponent implements OnInit {
  current = 0;
  loading = true;
  myDomain;
  domainData;
  dnsKeys;
  sender = new SenderIdentity();
  isEmailAuthorized = false;
  validationData;
  constructor(
    private router: Router,
    private emailDomainService: EmailDomainService,
    private authService: AuthenticationService,
    private jwtService: JwtService,
    private breadcrumservice: BreadCrumService,
    private message: NzMessageService,
    private emailSenderIdentityService: EmailSenderIdentityService,
    private globalsService: GlobalsService,
    private productsService: ProductsService,

  ) { }
  ngOnInit() {
    const c = this.jwtService.getSavedCustomer();
    if (!c) {
      this.authService.logout();
      this.router.navigate(['/login']);
      return;
    }
    // temp code
    const allowedEmails = this.globalsService.getEmailCustomers();

    this.sender.name = c.first_name + ' ' + c.last_name;
    this.sender.email = c.email;
    const params: Record<string, any> = {
      'per_page': 40,
    };
    this.productsService.productList(
      `customers/${c?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const emailProduct = data ? data?.find(p => p.abbreviation === 'PML') : null;
        if (emailProduct != null && !emailProduct.locked) {
          this.checkEmailAuth(true);
        } else {
          this.router.navigateByUrl('/404');
        }
      }
    }, err => {
      this.loading = false;
    })
    // temp code
    // if (allowedEmails.indexOf(c.id) === -1) {
    //   this.router.navigateByUrl('/404');
    // } else {
    // }
    this.breadcrumservice.set_breadcrum();
  }

  checkEmailAuth(fetchSender?) {
    this.authService.getEmailAuthentication()
      .subscribe(res => {
        if (fetchSender) {
          this.fetchEmailSenderIdentities();
        }
      }, (err) => {
        this.stopLoader();
      });
  }

  gotoStep(stepNumber) {
    if (this.current > stepNumber) {
      this.current = stepNumber;
    }
  }

  delayedNext() {
    setTimeout(() => {
      this.current += 1;
    });
  }


  emailAuth() {
    this.playLoader();
    this.authService.createEmailAuthentication()
      .subscribe(res => {
        this.isEmailAuthorized = true;
        this.authService.emailAuthentication()
          .subscribe(res => {
            this.checkEmailAuth();
          });
        this.stopLoader();
      }, err => {
        let errorMessage = err.error && err.error.message ? err.error.message : err.error;
        errorMessage = (typeof errorMessage == 'object') ? JSON.stringify(errorMessage) : errorMessage;
        errorMessage = errorMessage?.replace(/[{()}]/g, '');
        this.message?.remove();
        this.message?.create('error', errorMessage);
        console.error(err);
      });
  }

  fetchEmailSenderIdentities(showErrorMessage?) {
    this.loading = true;
    this.authService.emailAuthentication()
      .subscribe(res => {
        this.isEmailAuthorized = true;
        this.emailSenderIdentityService.get()
          .subscribe(res => {
            if (res && res.data.length) {
              const sender = res.data[0];
              this.sender = sender;
              this.current = sender.verified ? 2 : 1;
              if (showErrorMessage && !sender.verified) {
                this.message.create('error', 'Sender Email has not been confirmed!');
              }
            }
            this.loading = false;
          }, err => {
            if (showErrorMessage) {
              this.message.create('error', 'Internal Server Error in confirm verification!');
            }
            this.loading = false;
          });
      });
  }

  reSendSenderVerification() {
    this.emailSenderIdentityService.get()
      .subscribe(res => {
        if (res && res.data.length) {
          const sender = res.data[0];
          this.sender = sender;
          this.emailSenderIdentityService.reSendSenderVerification(this.sender.id)
            .subscribe(res => {
              this.sender = res;
              if (this.sender.verified) {
                this.current = 2;
              }
            }, err => {
              console.error(err);
            })
        }

      });


  }

  onSubmitSender(res) {
    this.sender = res && res?.data ? res.data : this.sender;
    this.current = this.sender.verified ? 2 : 1;
  }

  nextFromSender() {
    if (this.sender.verified) {
      this.current = 2;
    } else {
      this.current = 1;
    }
  }
  showMessage(msg?) {
    this.message.remove();
    this.message.info(`"${msg}" copied to clipboard!`);
  }

  gotoCampaignNew() {
    this.router.navigateByUrl('/email_campaigns/campaigns/new');
  }

  playLoader() {
    this.loading = true;
  }

  stopLoader() {
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  get cradDescription() {
    let domain = this.sender.email || 'example.com';
    return `<${this.sender.email ? this.sender.email : 'jane.doe@example.com'}>`
  }
}
