<nz-table nzNoResult="" nzTemplateMode>
  <thead (nzSortOrderChange)="sort($event)">
    <tr>
      <th [style.width]="(previewWidth + 10) + 'px'" >
        Preview
      </th>
      <th >Caption</th>
      <th *ngIf="!hideCols.includes('post_status')">Post Status</th>
      <th *ngIf="!hideCols.includes('created_at')" nzShowSort nzColumnKey="created_at">Created At</th>
      <!-- <th *ngIf="!hideCols.includes('scheduled_at')" nzShowSort nzColumnKey="scheduled_at">Scheduled</th> -->
      <th *ngIf="!hideCols.includes('action')" class="text-center ">
        Action
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="cursor-pointer" *ngFor="let content of contents" (click)="showPreview(content);">
      <td [style.width]="(previewWidth + 10) + 'px'" >
        <app-content-preview-item [previewWidth]="previewWidth" [textLimit]="textLimit" [content]="content">
        </app-content-preview-item>
      </td>
      <td>{{ truncateChar(content.caption) }}</td>
      <td *ngIf="!hideCols.includes('post_status')" class="capitalize">{{(content.last_post && content.last_post.id) ?
        ((!content.last_post?.scheduled_for
        && content.last_post?.status ==='scheduled' ? content.status : content.last_post.status )) :
        (content.status)}}</td>
      <td *ngIf="!hideCols.includes('created_at')">{{ content.created_at | date : "MM-dd-y"}}</td>
      <!-- <td *ngIf="!hideCols.includes('scheduled_at')">
        <span *ngIf="content.scheduled_for">
          {{content.scheduled_for | date : "MM-dd-y h:mm a"}}
        </span>
        <span *ngIf="!content.scheduled_for">
          No
        </span>
      </td> -->
      <td *ngIf="!hideCols.includes('action')" class="text-center ">
        <button nz-button nz-dropdown [nzDropdownMenu]="menu4">
          Select
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #menu4="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item nz-tooltip (click)="showPreview(content)"
              [nzTooltipTitle]=" content.status === 'ready' ? 'Ready for Post' : 'Content is not ready for Post'">
              <i nz-icon nzType="share-alt" [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}"
                class="down-icon"></i>
              Post
            </li>
            <li nz-menu-item nz-tooltip (click)="download(content)"
              [nzTooltipTitle]="content.status === 'ready' ? 'Ready for Download' : 'Content is not ready for Download'">
              <span>
                <i nz-icon nzType="download" [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}"
                  class="down-icon"></i>
              </span> Download
            </li>
            <li (click)="cancelSchedule(content)"
              *ngIf="content.status === 'ready' && content.last_post && content.last_post.id && content.scheduled_for"
              nz-menu-item> <i nz-icon nzType="close" class="delete-icon" nzTheme="outline"></i> Cancel Schedule</li>
            <li (click)="showPreview(content, true)" nz-menu-item
              *ngIf="content.status === 'ready' && content.last_post && content.last_post.id && content.scheduled_for">
              <i [ngStyle]="{'color': '#0170f7'}" nz-icon nzType="edit" nzTheme="fill"></i> Edit
            </li>
            <li nz-menu-item (click)="contentDeleted(content)"> <i nz-icon nzType="delete" class="delete-icon"
                nzTheme="fill"></i> Delete</li>
          </ul>
        </nz-dropdown-menu>

      </td>
    </tr>
  </tbody>
</nz-table>
<div class="empty" *ngIf="contents && !contents.length && !hideEmptyState">
  <img class="internal-content" src="../../../assets/images/content.png">
  <h2 class="create-title mar-zero" *ngIf="contentType==='listing'">Make Content for this Listing</h2>
  <h2 class="create-title mar-zero" *ngIf="contentType==='market-report'">Make Content for this Market Report</h2>
  <button nz-button nzType="primary" (click)="makeContent()" *ngIf="contentType==='market-report'">Let's Go</button>
</div>