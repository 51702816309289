import {AfterContentChecked, AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ConversationsService} from '../../services/conversations/conversations.service';
import {Conversation} from '../../vos/conversation/conversation';
import {DataResponse} from '../../models/data-response/data-response';
import {Testimony} from '../../vos/testimony/testimony';
import {ActionCableService, Channel} from 'angular2-actioncable';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ProductsService} from '../../services/products/product-api.service';
import {GhlService} from '../../services/ghl/ghl.service';
import moment from 'moment';
import {GenericDialogComponent} from '../../shared/generic-dialog/generic-dialog.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';
import {ActivatedRoute, Router, UrlSerializer, UrlTree} from '@angular/router';
import {AudiencesService} from '../../services/audiences/audiences.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
declare  const Calendly: any;
@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit, AfterContentChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input()
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  facebookSupport = false;
  loadingProduct = true;
  initLoading = true; // bug
  loadingMore = false;
  data = [];
  list: Conversation[]  = [];
  page=1;
  tabfilter;
  total_entries = 0;
  selectedConversation: Conversation;
  messages = [];
  newMessage;
  conversationProductAvailable;
  ghlNotConnected;
  locationId;
  searchPhone;
  searchPhoneQuery;
  searchContactId;
  audienceData;
  tabs = ['All', 'Unread', 'Read'];
  isSmallScreen = false;
  bodyClass;
  constructor(private conversationsService: ConversationsService,
              private cableService: ActionCableService,
              private authService: AuthenticationService,
              private productsService: ProductsService,
              private ghlService: GhlService,
              private modalService: NzModalService,
              private breadcrumService: BreadCrumService,
              private route: ActivatedRoute,
              private router: Router,
              private urlSerializer: UrlSerializer,
              private audienceService: AudiencesService,
  ) {
  }
  ngAfterContentChecked() {
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
       this.searchContactId = params['contact'];
       this.conversationAvailable();
    });
    this.breadcrumService.set_breadcrum();
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
      )
      .subscribe(newText => {
        this.searchPhone = newText;
        if (newText) {
          this.searchPhoneQuery = `q[audience_email_or_audience_phone_or_audience_first_name_or_audience_last_name_or_messages_body_cont]=${newText}`;
          this.conversationList(this.tabfilter , this.searchPhoneQuery);
        }else{
          this.searchPhoneQuery = null;
          this.conversationList('');
        }
      });

  }
  searchSelected(value){
    this.searchContactId = value;
    this.conversationList('');
  }
  clearSearch(){
    this.searchPhone = '';
    this.searchContactId = null;
    this.clearAllQueryParams();
    this.conversationList('');
  }
  conversationPageLoad(){
    this.page  = this.page + 1;
    this.conversationList('');
  }
  conversationList(status, searchText?){
    let query = '';
    if (status){
      this.tabfilter = status;
      status =  status === 'read' ? 1 : 0;
      query = query + `?q[status_eq]=${status}`;
    }else{
      this.tabfilter = null;
    }
    if (searchText){
      let symbol;
      if (query){
        symbol = '&';
      } else {
        symbol = '?';
      }
      query  =  query + symbol + searchText;
    }
    if (this.page){
      query  = query +  `&page=${this.page}`;
    }

    this.initLoading = true;
    this.selectedConversation = null;
    this.conversationsService.list(query).subscribe(response => {
      if (this.total_entries > this.list.length && !searchText){
        this.list = this.list.concat(response.data);
      } else {
        this.list = response.data;
      }

      this.page = response.current_page;
      this.total_entries = response.total_entries;
      this.initLoading = false;
      if (this.list[0]){
        this.onClickConversation(this.list[0]);
      }
    });
  }
  clearAllQueryParams(): void {
    const currentUrlTree: UrlTree = this.router.parseUrl(this.router.url);
    const urlTreeWithoutQueryParams: UrlTree = this.removeQueryParams(currentUrlTree);
    const newUrl: string = this.urlSerializer.serialize(urlTreeWithoutQueryParams);

    // Replace the current URL without navigation
    window.history.replaceState({}, '', newUrl);
  }

// Function to remove all query parameters from the UrlTree
  removeQueryParams(urlTree: UrlTree): UrlTree {
    return new UrlTree(urlTree.root, {});
  }
  onClickConversation(conversation){
    this.selectedConversation = conversation;
    this.conversationsService.mark_read(this.selectedConversation.id).subscribe(response => {});
    this.conversationsService.list_messages(this.selectedConversation.id).subscribe(response => {
      this.selectedConversation.messages = response.data;
      this.scrollToBottom();
      this.checkScreenSize();
    });
  }
  deleteConversation(){
    this.initLoading = true;
    this.conversationsService.destroy(this.selectedConversation).subscribe(response => {
      this.selectedConversation = null;
      this.conversationList('');
    });
  }
  delete() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this Conversation?',
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
       this.deleteConversation();
      }
    });
  }
  sendMessage() {
    this.conversationsService.send_messages(this.newMessage, this.selectedConversation.contactId).subscribe(response => {
      this.newMessage = '';
    });
  }
  clearMessage(){
    this.newMessage = '';
  }
  listenConversations() {
    const channel: Channel = this.cableService.cable(environment.wss_url).channel('ConversationChannel',
      {location_id: this.locationId});
    // Subscribe to incoming chats
    channel.connected().subscribe(data => {

    });
    channel.received().subscribe(message => {
      message = JSON.parse(message.data);
      const conversation = this.list.find(con => con.id === message.conversation_id);
      conversation.messages.push(message);
    });
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  onLoadMore() {
  }

  edit(item: any) {
  }
  conversationAvailable(){
    const params: Record<string, any> = {
      'per_page': 40,
    };
    this.productsService.productList(
      `customers/${this.authService.currentCustomerValue?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const products = res.data;
        const conversationProduct = products ? products?.find(p => p.abbreviation === 'CON' && p.locked === false) : null;
        this.ghlService.getGhlUserConnection().subscribe(resp => {
          const { user_id_on_provider = null } = resp?.body?.data?.length > 0 ? resp.body.data[0] : {};
          if (user_id_on_provider == null){
            this.ghlNotConnected = true;
          }
          if (user_id_on_provider && conversationProduct) {
            this.loadingProduct = false;
            this.locationId = user_id_on_provider;
            this.scrollToBottom();
            this.conversationList('');
            this.listenConversations();
            this.conversationProductAvailable = true;
          } else{
            this.loadingProduct = false;
            this.conversationProductAvailable = false;
            Calendly.initInlineWidget({
              url: 'https://calendly.com/client-success-call/success-call',
              parentElement: this.container.nativeElement
            });
          }
        });
      }
    });
  }
  toTimZone(date){
    const dateFormat = 'MM-DD-YYYY HH:mm';
    const convertDate = moment.utc(date);
    return moment(convertDate.local()).format(dateFormat);
  }
  display_conversation(item){
    if (item.lastMessageBody && item.lastMessageBody.length > 100) {
      return item.lastMessageBody.slice(0, 100) + '...';
    } else {
      return item.lastMessageBody;
    }
  }

  getAudiences(searchQuery?) {
    if (!searchQuery){
      searchQuery = '';
    }
    const queryParams = `${searchQuery}`;
    this.audienceService.list(queryParams).subscribe((c: any) => {
      if (c) {
        this.audienceData = [];
        this.audienceData = c.data;
      }
    });
  }
  getLabel(aud): string {
    return `${aud.phone} \n ${aud.first_name}`;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) { // Adjust this value as per your definition of a small screen
      this.isSmallScreen = true;
      this.bodyClass = 'hide-on-mobile'; // Add class if small screen
    } else {
      this.isSmallScreen = false;
      this.bodyClass = ''; // Remove class if not small screen
    }
  }
  back(){
    this.selectedConversation = null;
    this.bodyClass = '';
  }
}
