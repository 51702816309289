import { Injectable } from '@angular/core';
import { of, from, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { EmailResourceService } from '../email-resource.service';
import { switchMap, concatMap, toArray, map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConnectLinkedinService } from '../connect-linkedin/connect-linkedin.service';
import { CustomerResourceService } from '../customer-resource.service';
import { List } from '../../vos/list/list';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.email_api_url}`;
}

@Injectable()

export class EmailContactListService extends CustomerResourceService<any> {
  public data_key = 'list';
  public endpoint = 'lists';

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(List, http, authService);
  }

  audiences(listIds = []): Observable<any> {
    return of(listIds).pipe(
      switchMap(ids => from(ids).pipe(
        concatMap(id => forkJoin(
          this.fetchAudience(id)
        )),
        toArray(),
      ))
    );
  }

  fetchAudience(id, queryParams?): Observable<any> {
    let url = `${this._uri}${this.endpoint}/${id}/audiences.json`;
    if (queryParams) {
      url = url + queryParams;
    }
    return this.http.get<any>(url).pipe(
      map(resp => {
        return resp;
      }
      ));
  }
}
