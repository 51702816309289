import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Content } from 'src/app/vos/content/content';
import { ProductsService } from '../../services/products/product-api.service';
import { Product, ProductStyle } from 'src/app/vos/product/product';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ResourceService } from 'src/app/services/customer-resource.service';
import { Customer } from 'src/app/vos/customer/customer';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { Location } from '@angular/common';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';

@Component({
  selector: 'app-email-signature',
  templateUrl: './email-signature.component.html',
  styleUrls: ['./email-signature.component.scss']
})
export class EmailSignatureComponent implements OnInit {
  form: string;
  type: string;
  product: Product;
  content = new Content();
  isNew = true;
  isTeamMarketingVideo = false;
  isMarketingFlyer = false;
  page = 1;
  customer: Customer;
  contentSubject: Customer;
  resourceService: ResourceService<Customer>;
  styles: any;
  contentFilters = ['branded', 'unbranded'];
  contentFiltersAvailable = ['branded', 'unbranded'];
  contents: Content[] = [];
  current = 0;
  pageSize = 10;
  loading = false;
  // get filteredContents() {
  // NEED TO DISCUSSED
  // return this.contents.filter(c => {
  //   return (this.contentFilters.includes('branded'))
  //     || (this.contentFilters.includes('unbranded'));
  // });
  // return this.styles;
  // }

  get paginatedData(): Content[] {
    return this.contents;
    // return this.filteredContents.slice((this.page - 1) * this.pageSize, (this.page) * this.pageSize);
  }

  get pageInfo() {
    return { total_entries: this.styles ? this.styles.total_entries : 0, per_page: this.pageSize, current_page: this.page };
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private authService: AuthenticationService,
    protected _location: Location,
    private breadcrumService: BreadCrumService,
    private customerService: CustomersService,
  ) { }

  ngOnInit() {
    this.loading = true;
    if (this.router.url?.indexOf('team_marketing_videos') > -1) {
      this.isTeamMarketingVideo = true;
    }

    if (this.router.url?.indexOf('marketing_flyers') > -1) {
      this.isMarketingFlyer = true;
    }

    const content: Content = this.route.snapshot.data['content'];
    if (content) {
      this.isNew = false;
      this.content = content;
    }
    const contentSubject: { object: Customer; type: string } = this
      .route.snapshot.data['data'];
    if (contentSubject) {
      this.contentSubject = contentSubject.object;
      this.type = contentSubject.type;
      this.content.contentable_id = contentSubject.object.id;
      this.content.contentable_type = contentSubject.type;
      this.resourceService = this.customerService;
    } else {
      this.contentSubject = this.authService.currentCustomerValue;
      this.type = 'Customer';
      this.resourceService = this.customerService;
    }
    this.route.params.subscribe(params => {
      const parameters: Record<string, any> = {
        'q[name_eq]': 'email_signature',
      };


      this.customer = this.authService.currentCustomerValue;
      this.productService.productList(
        `customers/${this.customer?.id}/products`,
        parameters
      ).subscribe(resp => {
        if (resp) {
          const products = resp.data.map((product: Product) => new Product(product));
          this.product = products[0];
          this.content.category = this.product.abbreviation;
          this.content.contentable_type = 'Customer';
          this.content.contentable_id = this.authService.currentCustomerValue?.id;

          this.fetchStyles();
          this.breadcrumService.set_breadcrum();
          if (this.isTeamMarketingVideo) {
            this.breadcrumService.removeItem(`New`);
            this.breadcrumService.replaceItem(`Team Marketing Videos`, 'Marketing Videos', '/team/team_marketing_videos/new');
          } else {
            this.breadcrumService.replaceItem(`Marketing Videos`, 'Marketing Videos', this.router.url);
          }
        }
      });

    });

    if (this.isTeamMarketingVideo) {
      this.content.extra_attributes = {
        ...this.content.extra_attributes,
        team_content: true
      }
    }
  }

  fetchStyles() {
    this.loading = true;
    if (this.isTeamMarketingVideo) {
      this.contentSubject.tags = 'team';
    } else {
      this.contentSubject.tags = '';
    }
    this.productService.styles(this.product, this.contentSubject, this.page).subscribe(styles => {
      if (styles) {
        this.styles = styles;
        this.page = styles.current_page;
        this.pageInfo.current_page = styles.current_page;
        this.pageInfo.per_page = styles.per_page;
        this.pageInfo.total_entries = styles.total_entries;
        this.contents = styles.data.map(style => this.contentFor(style)).concat(this.product.content ? this.product.content.map(c => {
          const content = new Content({ ...c, category: this.content.category })
          content.extra_attributes = {
            ...content.extra_attributes,
            team_content: true
          };
          return content;
        })
          : [])
          .filter(c => c.status === 'ready' || c.is_template);
      }
      this.loading = false;
    });
  }
  paginateContent(page) {
    this.page = page;
    this.pageInfo.current_page = this.page;
    this.fetchStyles();
  }
  cancel() {
    this._location.back();
  }
  pathFor(form: string): string {
    return this.form !== undefined ? '../' + form : form;
  }

  contentFor(i: ProductStyle): Content {
    return new Content({
      caption: '',
      url: i.preview,
      category: this.content.category,
      style: i.name,
      thumbnail: i.preview,
      media_type: 'image',
      contentable_id: this.contentSubject.id,
      contentable_type: 'Customer',
      is_template: true,
      extra_attributes: this.content.extra_attributes
    });
  }

  toggleFilter(checked: boolean, filter: string): void {
    if (checked) {
      this.contentFilters.push(filter);
    } else {
      this.contentFilters = this.contentFilters.filter(t => t !== filter);
    }
    // this.filterContent(this.selectedCategories);
  }

  get diagnostic() {
    return JSON.stringify(this.content);
  }
}
