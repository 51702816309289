import {Image} from '../../vos/image/image';

export class AnnouncementAttributes {
  Message: string;
  photo0?: Image;
  photo1?: Image;
  photo2?: Image;
  photo3?: Image;
  photo4?: Image;
  CTA: string;
}
