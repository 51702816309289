import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CardButtonConfig } from 'src/app/models';
import { SwiperOptions  } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';
import { Content } from 'src/app/vos/content/content';

import { TemplatesService } from '../../../services/templates/templates.service';
import { BreadCrumService } from '../../../services/breadcrum/bread-crum.service';
import { WebsiteService } from '../../../services/websites/website.service';
import { ContentReceiptDialogComponent } from '../../../shared/content-receipt-dialog/content-receipt-dialog.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-create-website',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateWebsiteComponent implements OnInit {
  @ViewChild('usefulSwiper', {static: false}) usefulSwiper: SwiperComponent;
  selectedType = '';
  current = 0;
  tagId;
  loading = false;
  selectedTemplate;
  selectedListing;
  isCreated = false;
  listing ;
  templates: Content[] = [];
  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 10,
    slidesPerView: 3,
    slidesPerGroup: 3,
    allowTouchMove: false,
    breakpoints: {
      2500: {
        slidesPerView: 4,
        spaceBetween: 5,
        slidesPerGroup: 4
      },
      2200: {
        slidesPerView: 3,
        spaceBetween: 5,
        slidesPerGroup: 4
      },
      1920: {
        slidesPerView: 3,
        spaceBetween: 4,
        slidesPerGroup: 4
      },
      1850: {
        slidesPerView: 3,
        spaceBetween: 5,
        slidesPerGroup: 3
      },

      1700: {
        slidesPerView: 2,
        spaceBetween: 6,
        slidesPerGroup: 3
      },
      1250: {
        slidesPerView: 2,
        spaceBetween: 10,
        slidesPerGroup: 2
      },
      // 1024: {
      //   slidesPerView: 2,
      //   spaceBetween: 10,
      //   slidesPerGroup: 2
      // },
      940: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 2
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
        slidesPerGroup: 1
      }
    }
    // loopFillGroupWithBlank: true,
  };
  agentCardButton: CardButtonConfig = {
    id: 'new',
    title: 'Agent',
    actionText: 'NEW WEBSITE',
    link: 'websites/new',
    image: 'https://universal-promote.s3-us-west-1.amazonaws.com/icons/icon_AgentSite.svg',
    colors: {
      bg: '#30d289',
      textSelected: 'white'
    },
  };
  listCardButton: CardButtonConfig = {
    id: 'new',
    title: 'Listing',
    actionText: 'NEW WEBSITE',
    link: 'websites/new',
    image: 'https://universal-promote.s3-us-west-1.amazonaws.com/icons/icon_ListingSite.svg',
    colors: {
      bg: '#30d289',
      textSelected: 'white'
    },
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public message: NzMessageService,
    private modalService: NzModalService,
    private breadcrumservce: BreadCrumService,
    private templatesService: TemplatesService,
    private websiteService: WebsiteService
  ) { }

  ngOnInit() {
    this.breadcrumservce.set_breadcrum();
    this.listing = this.route.snapshot.data[ 'data' ];
    this.route.params.subscribe(params => {
      if (params.type === 'listings') {
        this.setType('Listing');
        this.selectedListing = this.listing;
        this.next();
      }
    });
  }

  getTemplates() {
    this.loading = true;
    const params = this.selectedType === 'Listing' ? 'typeId=1' : 'typeId=2';
    this.templatesService.getPublicUpTemplates(params)
      .subscribe(res => {
        this.templates = res.map(templ => {
          templ.url = templ?.image;
          templ.caption = templ.name;
          templ.media_type = 'image';
          return templ;
        });
        if (this.templates.length === 1){
          this.selectTemplate(this.templates[0]);
        }
        this.loading = false;
      });
  }

  selectList(list) {
    this.selectedListing = list;
    this.next();
  }

  done() {
    if (this.selectedType === 'Agent') {
      this.createAgentSite();
    } else {
      this.createListingSite();
    }
  }

  createAgentSite() {
    this.websiteService.create({template_id: this.selectedTemplate.id})
      .subscribe(res => {
        window.open(res.edit_url, '_blank');
        this.isCreated = true;
        this.router.navigateByUrl('websites');
        // this.receipt();
      });
  }

  createListingSite() {
    const url = `/listings/${this.selectedListing.id}/websites.json`;
    this.websiteService.createCustom({template_id: this.selectedTemplate.id}, url)
    .subscribe(res => {
      window.open(res.edit_url, '_blank');
      this.isCreated = true;
      this.router.navigateByUrl('websites');
      // this.receipt();
    });
  }

  setType(type) {
    this.selectedType = type;
    this.getTemplates();
  }

  next() {
    this.current++;
  }

  prev() {
    this.current--;
  }

  nextSlide() {
    this.usefulSwiper.swiper.slideNext();
  }

  prevSlide() {
    this.usefulSwiper.swiper.slidePrev();
  }

  selectTemplate(c) {
    this.selectedTemplate = c;
    this.templates.map(template => {
      if (template.id === this.selectedTemplate.id) {
        template.selected = true;
      } else {
        template.selected = false;
      }
    });
  }

  receipt() {
    this.router.navigateByUrl('websites');
    const modal = this.modalService.create<ContentReceiptDialogComponent, IModalData>({
      nzContent: ContentReceiptDialogComponent,
      nzData: {
        config: {
          type: 'website',
          link: this.router.url
        }
      },
      nzFooter: null,
      nzWidth: '40%'
    });
    modal.afterClose.subscribe(response => {
      if (response === 'true') {
        this.selectedTemplate = null;
        this.selectedListing = null;
        this.isCreated = false;
      }
    });
  }
}
