
<div class="card-body {{cardType}}" [style.backgroundColor]=" bgColor" [style.color]="textColor"
  [class.selected]="selected" (click)="onClick()">
  <nz-skeleton [nzAvatar]="{ shape: 'square' }" [nzParagraph]="{ rows: 2 }" class="card-skeleton" [nzLoading]="config.isSkeleton">
    <div nz-row nzType="flex" nzJustify="space-between"
      class="card-content {{disabled ? 'd-opacity' : 'f-opacity'}}" fxFill>
      <div *ngIf="config.topText" class="center-text">{{config.topText}}</div>
      <img *ngIf="config && config.image && !config.icon && !config.antIcon" src="../../../assets/icons/{{config.image}}" class="card-image" />

      <div *ngIf="config.centerText" class="center-text" [class.full-width]="!config.title && !config.actionText">
        {{config.centerText}}</div>
      <div class="title" *ngIf="config.title">
        {{config.title}}
      <i nz-icon nzType="right" class="next-icon" nzTheme="outline" *ngIf="!!cardType"></i>

      </div>
      <div class="action" *ngIf="!config.title">
        {{config.actionText}}
      </div>
      <div class="description" *ngIf="config.description">
        <!--{{config.description}}-->
        {{truncateChar(config.description)}}
      </div>
    </div>
  </nz-skeleton>
</div>
