<div *ngIf="!uploadState" nz-row nzType="flex" nzJustify="center" nzGutter="1">
  <button nz-button nzType="primary" class="margin-right-8" (click)="uploadState = 1">Add 1 Contact</button>
  <button nz-button nzType="primary" (click)="uploadState = 2">Add From CSV</button>
</div>
<div *ngIf="uploadState === 1">
  <h2 class="text-center">Contact Details</h2>
  <form nz-form [formGroup]="contactForm" (ngSubmit)="submitContact()" nzLayout="vertical">
    <nz-form-item *ngFor="let field of contactFields" class="margin-bottom-zero">
      <nz-form-label [nzRequired]="contactFieldsRequired.includes(field)" [nzFor]="field">
        {{field.titleize()}}</nz-form-label>
      <nz-form-control>
        <input nz-input [formControlName]="field" [id]="field" />
        <!-- <nz-form-explain *ngIf="contactForm.get(field)?.dirty && contactForm.get(field)?.errors">
          Invalid {{field.titleize()}}
        </nz-form-explain> -->
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-control>
        <!-- <nz-form-explain *ngIf="formError">{{formError}}</nz-form-explain> -->
        <button nz-button nzType="primary" [nzLoading]="loading">
          {{isEdit ? 'Update Contact' : 'Add Contact'}}
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
<div *ngIf="uploadState === 2">
  <div class="sample-csv">
    <a href="../../../assets/sample.csv" download>
      Sample CSV <i nz-icon nzType="file-text" nzTheme="outline"></i>
    </a>
  </div>
  <nz-alert *ngIf="parseResult && parseResult.errors.length > 0">{{parseResult.errors}}</nz-alert>
  <nz-upload nzType="drag" [nzBeforeUpload]="parseCsv" [nzFilter]="filters" nzAccept=".csv">
    <p class="ant-upload-drag-icon">
      <i nz-icon type="file-add"></i>
    </p>
    <p class="ant-upload-text">Click or drag a csv file to this area to upload</p>
    <p class="ant-upload-hint">
      Make sure your csv has a header row with at least an email address and name for each contact. Make sure your csv
      has identifiable column names. You will be asked to identify what data is in each column.
    </p>
  </nz-upload>
</div>
<div *ngIf="uploadState === 3">
  <h2 class="text-center">Select Fields</h2>
  <h4 class="text-center">Select the column from your document that contains the data for each field.</h4>
  <div nz-row>

    <div nz-col nzSpan="12" nzOffset="6" *ngFor="let field of contactFields">
      <nz-form-item>
        <nz-form-label [nzSm]="10" [nzXs]="24" [nzRequired]="contactFieldsRequired.includes(field)"  >{{field.titleize()}}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" >
          <nz-select style="width: 200px;" [(ngModel)]="headerMap[field]" nzSize="large">
            <nz-option *ngFor="let option of parseHeader" [nzLabel]="option.titleize()" [nzValue]="option"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <button nz-button nzType="primary" [disabled]="!isUploaderFormValid" [nzLoading]="loading" (click)="mapHeaders()">
    Next
  </button>
</div>
<div *ngIf="uploadState === 4">
  <h2>Preview Some of Your Contacts</h2>
  <h4>Take a look at a couple of your contacts make sure they look correct before submitting.</h4>

  <nz-table nzNoResult="" nzTemplateMode>
    <thead>
      <tr class="ads-hd-text-style">
        <th>First Name</th>
        <th>Last Name</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Company</th>
        <th>Address</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let contact of contactPreviewSource">
        <td class="ads-text-style">
          {{contact.first_name}}
        </td>
        <td class="ads-text-style">
          {{contact.last_name}}
        </td>
        <td class="ads-text-style">
          {{contact.phone}}
        </td>
        <td class="ads-text-style">
          {{contact.email}}
        </td>
        <td class="ads-text-style">
          {{contact.company}}
        </td>
        <td class="ads-text-style">
          {{contact.address}}
        </td>
      </tr>
    </tbody>
  </nz-table>

  <div nz-row nzType="flex" nzJustify="end" style="margin-top: 30px;">
    <nz-pagination [nzPageIndex]="page" [nzTotal]="parsedContacts.length" nzPageSize="5" [nzShowTotal]="rangeTemplate" (nzPageIndexChange)="didPage($event)"></nz-pagination>
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} of {{ total }} items
    </ng-template>
  </div>
  <nz-alert *ngIf="submitError">{{submitError.message}}</nz-alert>
  <button nz-button nzType="primary" [nzLoading]="loading" (click)="submitContacts()">
    Add Contacts
  </button>
</div>
