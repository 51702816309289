import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Content } from 'src/app/vos/content/content';
import { Customer } from 'src/app/vos/customer/customer';
import { ContentsService } from '../../services/contents/contents.service';
import { DownloaderService } from 'src/app/services/downloader.service';
import { ContentPreviewDialogComponent } from '../../shared/content-preview-dialog/content-preview-dialog.component';
import { SocialPostDialogComponent } from '../../shared/social-post-dialog/social-post-dialog.component';
import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { IModalData } from 'src/app/models/interfaces/modals/modal';


@Component({
  selector: 'app-content-table',
  templateUrl: './content-table.component.html',
  styleUrls: ['./content-table.component.scss']
})
export class ContentTableComponent implements OnInit {
  @Input()
  contents: Content[];
  @Input()
  hideCols: string[];
  @Input()
  contentCreatePath: string;
  @Input()
  previewWidth: number;
  @Input()
  textLimit: number = 25;
  @Input()
  contentType = 'listing';
  @Output()
  deleted = new EventEmitter<Content>();
  loading = true;
  @Input()
  public hideEmptyState: boolean = false;
  customer: Customer;
  contentUpdateSubscription: Subscription;

  constructor(
    private contentService: ContentsService,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private message: NzMessageService,
    public router: Router
  ) { }

  ngOnInit() {
    this.contentChannelSub();
  }

  contentDeleted(content: Content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentService.destroy(content)
          .subscribe(res => {
            this.contents = this.contents?.filter(c => c.id !== content.id);
            this.message?.create('success', `Content has been successfully deleted.`);
            this.deleted.emit(content);
          }, e => {
            this.loading = false;
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }

  contentChannelSub() {
    this.contentUpdateSubscription = this.contentService.contentUpdateChannel()
      .received().subscribe(res => {
        if (res) {
          const contentResponse = res.data;
          const contentIndex = this.contents?.findIndex(c => c.id === contentResponse.content_id);
          if (contentIndex > -1) {
            this.contents[contentIndex].status = contentResponse.status;
            if (this.contents[contentIndex].last_post) {
              this.contents[contentIndex].last_post.status = contentResponse.status;
            }
          }
        }
      })
  }

  download(content) {
    const newContenr = new Content(content);
    let url = content?.url || '';
    if (url) {
      let file_type_array = url.split('.')
      let file_type = file_type_array[file_type_array.length - 1];
      let filename = newContenr.filename || `download.${file_type}`;
      if (!url.includes('https')) {
        url = url?.replace('http', 'https');
      }
      this.downloader.save(url, filename);
    }
  }

  post(content) {
    if (content.status === 'ready') {
      const modal = this.modalService.create<SocialPostDialogComponent, IModalData>({
        nzContent: SocialPostDialogComponent,
        nzData: {
          content: content
        },
        nzFooter: null
      });
      // modal.afterClose.subscribe(response => {
      // });
    }
  }

  sort(sort: { key: string; value: string }) {
    const sortName = sort.key || 'descend';
    this.contents?.sort((a, b) =>
      sort.value === 'ascend'
        ? a[sortName!] > b[sortName!]
          ? 1
          : -1
        : b[sortName!] > a[sortName!]
          ? 1
          : -1
    );
  }

  showPreview(content, isEdit?) {
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content,
          isEdit
        }
      },
      nzFooter: null,
      nzWidth: '50%',
      // nzBodyStyle: {
      //   'height': '65vh',
      //   'overflow': 'scroll'
      // }
    });
    modal.afterClose.subscribe(response => {
    });
  }

  makeContent() {
    if (this.contentCreatePath) {
      this.router.navigate([this.contentCreatePath]);
    }
  }

  cancelSchedule(content: Content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to cancel your scheduled post?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentService.unschedulPost(content)
          .subscribe(res => {
            this.contents = this.contents?.filter(c => c.id !== content.id);
            this.message?.create('success', `Your scheduled post is cancelled`);
          }, e => {
            this.loading = false;
            this.message?.remove();
            this.message?.create('error', 'Error cancelling the scheduled post. Please try again');
          });
      }
    });
  }
  truncateChar(text: string): string {
    const charlimit = this.textLimit;
    if (!text || text.length <= charlimit) {
      return text;
    }
    const shortened = text.substring(0, charlimit) + '...';
    return shortened;
  }
}
