export class Resource {
  /**
   * id
   */
  id?: number | string;

  tags: string;
  extra_attributes?: any;
  image_ids?: any;
  images_attributes?: any;
  constructor(vals: any = {}) {
    Object.assign(this, vals);
  }
}
