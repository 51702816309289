<div nz-row nzJustify="center">
    <app-content-card nz-col nzSpan="5" *ngFor="let content of filteredContents" [showTopMenu]="!isMarketVideo" [content]="content" [caption_editable]="true"
                      (editingChange)="editingChange($event, true)" (posted)="posted.emit($event)">
    </app-content-card>
</div>

<div nz-row *ngIf="pagination">
  <nz-pagination [nzPageIndex]="page" [nzTotal]="pagination.total_entries" [nzShowTotal]="rangeTemplate"
    (nzPageIndexChange)="didPage($event)"></nz-pagination>
  <ng-template #rangeTemplate let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} of {{ total }} items
  </ng-template>
</div>
