import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, UntypedFormControl } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [
    trigger('flyInOut', [
      // state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s')
      ]),
      transition(':leave',
        animate('0.5s', style({ opacity: 0 })))
    ])
  ]
})
/**
 * Blank view.
 */
export class ResetPasswordComponent implements OnInit {
  passForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  state;
  code: string;
  errorMessage: string;
  emailValid = true;
  emailExists = false;
  email: string;
  loading = false;
  showSignupLink = false;
  hide = false;
  resendWait = 0;
  passwordVisible = false;
  /**
   * Component constructor and DI injection point.
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private onboardingService: OnboardingService
  ) {
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control?.value !== this.passForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  EmailExists: ValidatorFn = (fg: UntypedFormGroup) => {
    const valid = this.emailValid;
    return valid
      ? null
      : { missing: true };
  }

  ngOnInit() {
    const d = { ...this.route.snapshot.data, ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
    this.emailExists = false;
    this.state = 'set_pass';
    this.setFormsWith(d);
    if (d.user_logout) {
      this.authService.logout();
      this.router.navigateByUrl('/login');
    } else {
      this.code = d.code;
      this.email = d.email;
      this.resetForm.controls.email.setValue(d.email || '');
      this.setState(d.state ? d.state : 'login');
    }
  }

  setFormsWith({ state: _, ...obj }: any = {}) {
    this.passForm = this.fb.group({
      ...obj,
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      password_confirmation: new UntypedFormControl('', [
        Validators.required,
        this.confirmationValidator
      ])
    });
    this.resetForm = this.fb.group({
      ...obj,
      email: new UntypedFormControl('', [
        Validators.required
      ])
    },
      {
        validator: this.EmailExists
      });
  }

  setState(s: string) {
    this.state = s;
    window.history.pushState({}, '', `/${s}`);
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.passForm.controls.password_confirmation.updateValueAndValidity());
  }

  changePass() {
    const req = this.passForm.value;
    req.reset_password_token = req.code || req.reset_password_token;
    delete req.code;
    // if (this.state === 'set_pass') {
    // }
    this.loading = true;
    this.authService.resetPassword(req).subscribe(response => {
      this.loading = false;
      if (response.status === 204) {
        const queryParams = this.email ? { email: this.email } : null
        this.router.navigate(['/login'], { queryParams });
      }
    }, err => {
      if (err.status === 422) {
        this.loading = false;
        if (err.error?.indexOf('reset_password_token') > -1) {
          this.setState('expired_forgot_pass');
        } else {
          this.setState('resend_forgot_pass');
        }
        // this.errorMessage = 'Reset Link is timed out';
      }
    });
  }

  showForgotPass() {

    this.resetForm.controls.email.setValue(this.email);
    this.setState('forgot_pass');
    this.errorMessage = '';
    // this.router.navigate([ '/forgot_password' ],
    //   {
    //     queryParams: { email: this.authForm.controls.email.value },
    //     queryParamsHandling: 'merge'
    //   });
  }

  forgotPass() {
    this.email = this.resetForm.controls.email?.value;
    this.loading = true;
    this.authService.requestResetPassword(this.email).subscribe(success => {
      this.emailValid = success;
      this.loading = false;
      if (success) {
        this.state = 'reset_pass';
        this.resendWait = 30;
        const timer = setInterval(() => {
          this.resendWait -= 1;
          if (this.resendWait === 0) {
            clearInterval(timer);
          }
        }, 1000);
      }
    }, err => {
      this.loading = false;
      this.errorMessage = 'No account found for this email, Please enter valid email and try again.';
    });
  }

}
