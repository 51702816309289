import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { MarketReport } from 'src/app/vos/market-report/market-report';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
// import * as zipcodes from 'zipcodes';
/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class MarketReportsServiceConfig {
  uri = `${environment.api_url}`;
}

@Injectable()
/**
 * Service class.
 */
export class MarketReportsService extends CustomerResourceService<MarketReport> {
  public endpoint = 'market_reports';
  public data_key = 'market_report';
  _zips: string[];
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(MarketReport, http, authService);
  }
  /**
   * Pulls a list of MarketReport objects.
   * @returns {Observable<MarketReport[]>}
   */

  insights(id: number | string): Observable<any[]> {
    const url = `${this._uri}${this.endpoint}/${id}/insights.json`;
    return this.http.get<any[]>(url).pipe(
      map(resp => {
        return resp;
      }
      ));
  }
  data(id: number | string, propertyType?: any): Observable<any[]> {
    let url = `${this._uri}${this.endpoint}/${id}/data.json`;
    if (propertyType) {
      url = url + `?property_type=${propertyType}`;
    }
    return this.http.get<any[]>(url).pipe(
      map(resp => {
          return resp;
        }
      ));
  }

  dataPoints(id: number | string): Observable<any[]> {
    const url = `${this._uri}${this.endpoint}/${id}/data.json`;
    return this.http.get<any[]>(url).pipe(
      map(resp => {
        return resp;
      }
      ));
  }

  propertyTypes(): Observable<any[]> {
    const url = `${this._uri}${this.endpoint}/property_types.json`;
    return this.http.get<any[]>(url).pipe(
      map(resp => {
        return resp;
      }
      ));
  }

  dataSnapPoints(): Observable<any[]> {
    const url = `${this._uri}${this.endpoint}/data_points.json`;
    return this.http.get<any>(url).pipe(
      map(resp => {
        return resp.data;
      }
      ));
  }
  is_valid_zip(zip: string) {
    // if (zip.length !== 5) {
    //   return false;
    // }
    // const z = zipcodes.lookup(zip);
    // return z != null;
    // return true
    const url = `${this._uri}${this.endpoint}/search.json?zip=${zip}`;
    return this.http.get<any>(url).pipe(
      map(resp => {
        return resp;
      }
      ));
  }

  search(zipCode: number | string, dataPoints: any, propertyType?: any, options?: any): Observable<any[]> {
    let url;
    if (dataPoints.length > 0){
       url = `${this._uri}${this.endpoint}/search.json?zip=${zipCode}&indicators=${dataPoints}`;
    } else {
       url = `${this._uri}${this.endpoint}/search.json?zip=${zipCode}`;
    }
    if (propertyType) {
      url = url + `&property_type=${propertyType}`;
    }

    let headers = this.headers;
    if (options?.headers) {
      Object.keys(options.headers).forEach(key => {
        headers = headers.set(key, options.headers[key]);
      });
    }

    return this.http.get<any[]>(url, { headers }).pipe(
      map(resp => {
          return resp;
        }
      ));
  }

}
// export class MarketReportsService {

//     /**
//      * Path uri.
//      * @type {string}
//      * @private
//      */
//      private _uri = '/';

//     /**
//      * Url to endpoint api.
//      * @type {string}
//      */
//      private endpoint = 'market-report';

//     /**
//      * Endpoint request headers.
//      * @type {HttpHeaders}
//      */
//      private headers = new HttpHeaders({'Content-Type': 'application/json'});

//     /**
//      * Component constructor and DI injection point.
//      * @param {HttpClient} http
//      * @param {MarketReportsServiceConfig} config
//      */
//     constructor(private http: HttpClient, @Optional() config: MarketReportsServiceConfig) {
//         if (config) {
//             this._uri = config.uri;
//         }
//     }

//     /**
//      * Pulls a list of MarketReport objects.
//      * @returns {Observable<MarketReport[]>}
//      */
//     list(): Observable<MarketReport[]> {
//             return this.http.get<MarketReport[]>(`${this._uri}${this.endpoint}`);
//     }

//     /**
//      * Pulls a single MarketReport object.
//      * @param {number | string} id to retrieve.
//      * @returns {Observable<MarketReport>}
//      */
//     show(id: number | string): Observable<MarketReport> {
//         const url = `${this._uri}${this.endpoint}/${id}`;
//     return this.http.get<MarketReport>(url);
//     }

//     /**
//      * Creates a single MarketReport object.
//      * @param {} value to create.
//      * @returns {Observable<MarketReport>}
//      */
//     create(value: MarketReport): Observable<MarketReport> {
//         return this.http
//             .post<MarketReport>(`${this._uri}${this.endpoint}`, JSON.stringify(value), {headers: this.headers});
//     }

//     /**
//      * Updates a single MarketReport object.
//      * @param {} value to update.
//      * @returns {Observable<MarketReport>}
//      */
//     update(value: MarketReport): Observable<MarketReport> {
//         const url = `${this._uri}${this.endpoint}/${value.id}`;
//     return this.http
//         .put<MarketReport>(url, JSON.stringify(value), {headers: this.headers});
//     }

//     /**
//      * Destroys a single MarketReport object.
//      * @param {number | string} id to destroy.
//      * @returns {Observable<void>}
//      */
//     destroy(id: number | string): Observable<void> {
//         const url = `${this._uri}${this.endpoint}/${id}`;
//         return this.http.delete<void>(url, {headers: this.headers});
//     }

// }
