import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss']
})
export class CampaignDetailsComponent implements OnInit {
  @Input()
  template: any;

  @Input()
  model;

  name;
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.name = this.model.name;
  }
  changeModel() {
    setTimeout(() => {
      this.model.name = this.name;

    }, 500);

  }

}
