import moment from 'moment';
import { Resource } from '../resource/resource';
export class Setting extends Resource {
  /**
   * id
   */
  id?: number;

  /**
   * created_at
   */
  created_at?: moment.Moment;
  updated_at?: moment.Moment;

  /**
   * read status
   */
  target_type: string;
  target_id: number;
  key: string;
  subscribing?: boolean;
  subscribing_to_email?: boolean;
  subscribed_at?: moment.Moment;
  unsubscribed_at?: moment.Moment;
  subscribed_to_email_at?: moment.Moment;
  unsubscribed_to_email_at?: moment.Moment;
  optional_targets?: any;
  status: boolean;


}
