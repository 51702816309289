<ng-template #settings>
  <nz-layout>
    <nz-sider nzWidth="210px" class="background-white top-space">
      <ul nz-menu nzMode="inline">
        <li nz-menu-item (click)="changeTab('sender-identity')"
        [class.ant-menu-item-selected]="selectedTab == 'sender-identity'">
        <i nz-icon nzType="team"></i>
        <span>Sender Identity</span>
      </li>
        <!-- <li nz-menu-item (click)="changeTab('domain-verification')"
          [class.ant-menu-item-selected]="selectedTab == 'domain-verification'">
          <i nz-icon nzType="cloud-o"></i>
          <span>Domain Verification</span>
        </li> -->
       
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-content>
        <!-- <div *ngIf="!loading && selectedTab == 'domain-verification'" class="background-white domain-form-spacing">
          <nz-steps [nzCurrent]="current" class="content-stepper">
            <nz-step nzTitle="Add Domain" (click)="gotoStep(0)"></nz-step>
            <nz-step nzTitle="Verify DNS Records" (click)="gotoStep(1)"></nz-step>
          </nz-steps>
          <div *ngIf="current === 0">
            <h3 class="center-text copy">
              Please enter the domain you are going to send your emails from. For example, If you are using
              Peter@peterjohnsonrealty.com
              you will use Peterjohnsonrealty.com.
            </h3>
            <form #domainForm="ngForm">
              <div nz-row nzJustify="space-between">
                <div nz-col nzSpan="10">
                  <nz-form-item>
                    <span class="domain-label">Add Domain</span>
                    <nz-form-label class="sub-label" for="domain" [nzSm]="24" [nzXs]="24">This is the domain we'll use
                      to send
                      emails from.</nz-form-label>
                    <nz-form-control [nzSm]="24" [nzXs]="24"
                      nzErrorTip="Domain should be in the form example.com, not test@example.com">
                      <input nz-input maxlength="40" type="text" class="form-control"
                        pattern="[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+" id="domain" [(ngModel)]="myDomain" name="domain"
                        required [disabled]="domainData && domainData.id">
                    </nz-form-control>
                  </nz-form-item>
                  <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
                    <button *ngIf="!domainData || domainData && !domainData.id" nz-button nzType="primary"
                      [disabled]="!myDomain || !domainForm.valid" class="action-btn-primary"
                      (click)="createDomain()">Add
                      Domain</button>
                  </div>
                </div>
                <nz-divider class="splitter" nzType="vertical"></nz-divider>
                <div nz-col nzSpan="10">
                  <nz-card class="w-full domain-card">
                    <nz-card-meta [nzAvatar]="avatarTemplate" [nzTitle]="smpltitle" [nzDescription]="cradDescription">
                    </nz-card-meta>
                    <nz-skeleton [nzParagraph]="{ rows: 4 }"></nz-skeleton>
                  </nz-card>
                  <ng-template #smpltitle>
                    <h3 class="name-title">Jane Doe</h3>
                  </ng-template>
                  <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
                    <button *ngIf="domainData && domainData.id" nz-button nzType="primary" class="action-btn-primary"
                      (click)="delayedNext()">Next</button>
                  </div>
                </div>
                <ng-template #avatarTemplate>
                  <nz-avatar nzIcon="user"></nz-avatar>
                </ng-template>
              </div>
            </form>
          </div>
          <div *ngIf="current === 1">
            <app-domain-table [domainData]="domainData" [dnsKeys]="dnsKeys"></app-domain-table>
          </div>
        </div> -->
        <div *ngIf="!loading && selectedTab == 'sender-identity'" class="background-white domain-form-spacing">
          <app-sender-identity-form showStatus="showStatus"[actionButton]="'update'" [sender]="sender"></app-sender-identity-form>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="settings"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="settings"></ng-container>
</ng-template>