import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Content } from 'src/app/vos/content/content';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import {Subject} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */

@Injectable()
export class NpsScoreService extends CustomerResourceService<Content> {
  public endpoint = 'nps_scores';
  public data_key = '';
  private subject = new Subject<any>();
  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Content, http, authService);
  }
  nps_asked(): Observable<any> {
    return this.http
      .post<DataResponse<any>>(
        `${this._customerURI}${this.customerEndpoint}/nps_asked`,
        JSON.stringify({ }),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
}
