import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap, concatMap, toArray, map } from 'rxjs/operators';
import { of, from, forkJoin } from 'rxjs';

import { Contact } from '../../vos/contact/contact';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */

@Injectable()
/**
 * Service class.
 */
export class RegionService extends CustomerResourceService<Contact> {
  public endpoint = 'regions';
  public data_key = 'data';

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Contact, http, authService);
  }

  regionList(): Observable<any> {
    const url = `${this._uri}customers/${this.endpoint}.json`;
    return this.http.get(url);
  }

}
