import { Component, OnInit, Input, inject } from '@angular/core';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { ContentPreviewDialogComponent } from '../../../shared/content-preview-dialog/content-preview-dialog.component';
import { ContentsService } from '../../../services/contents/contents.service';
import { GenericDialogComponent } from '../../../shared/generic-dialog/generic-dialog.component';
import {PostsService} from '../../../services/posts/posts.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'content-event-preview',
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.scss']
})
export class EventPreviewComponent implements OnInit {
  @Input()
  calendarData;
  @Input() content?: any;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  cDate = new Date();
  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private contentsService: ContentsService,
    private postsService: PostsService,
  ) {

  }
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 120;
  };

  handleDate(date) {
    if (differenceInCalendarDays(date, new Date()) > 120) {
      this.message.create('error', 'You cannot select date after 4 months!');
      this.cDate = new Date();
    }
  }
  ngOnInit() {
    // this.calnderDataKeys = Object.keys(this.calendarData);
    this.content = this.nzModalData.content
    if (this.cDate.getUTCDate() > 25) {
      this.cDate = new Date(this.cDate.getFullYear(), this.cDate.getUTCMonth() + 1, 1);
    }
  }
  get calnderDataKeys() {
    return Object.keys(this.calendarData || [])
  }


  getMonthData(date: Date): number | null {
    if (date.getMonth() === 8) {
      return 1394;
    }
    return null;
  }

  getFormatedDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  showPreview(content, isEdit?) {
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content,
          isEdit,
        },
        showActions: false
      },
      nzFooter: null,
      nzWidth: '50%',
      // nzBodyStyle: {
      //   'height': '65vh',
      //   'overflow': 'scroll'
      // }
    });
    modal.afterClose.subscribe(response => {
    });
  }

  contentDeleted(content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        const payload = {
          ...content,
          id: content.id
        };
        this.postsService.destroy(payload)
          .subscribe(res => {
            this.message?.create('success', `Content has been successfully deleted.`);
            for (const [key, value] of Object.entries(this.calendarData)) {
              if (key && this.calendarData[key]) {
                this.calendarData[key] = this.calendarData[key]?.filter(c => c.content_id !== content.content_id);
              }
            }
          }, e => {
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }
  close(){
    this.modalService.closeAll();
  }
}
