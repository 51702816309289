import { Component, OnInit } from '@angular/core';
import { CardButtonConfig } from '../../models';
import { Product } from '../../vos/product/product';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Customer } from '../../vos/customer/customer';
import { LeadsService } from '../../services/leads/leads.service';
import { AdsService } from '../../services/ads/ads.service';
import { Ad } from '../../vos/ads/ads';
import { DataResponse } from '../../models/data-response/data-response';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import {ConversationsService} from '../../services/conversations/conversations.service';
import {GenericDialogComponent} from '../../shared/generic-dialog/generic-dialog.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AudiencesService} from '../../services/audiences/audiences.service';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

export interface PeriodicElement {
  name: string;
  phone: string;
  email: string;
  action: string;
}

@Component({
  selector: 'app-leads-selector',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})

export class LeadsComponent implements OnInit {
  loading = true;
  stylesExpanded = false;
  products: CardButtonConfig[];
  contents: Ad[] = [];
  customer: Customer;
  marketingProducts: Product[] = [];
  page = 1;
  pageInfo = { total_entries: 0, per_page: 10, current_page: 1 };
  displayedColumns: string[] = ['name', 'phone', 'email', 'action', 'transaction_in', 'address'];
  leadsDataSource = []; // new MatTableDataSource<PeriodicElement>();
  paginatedLeads = [];
  selectedExportLeads = [];
  searchText = '';
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;

  // @ViewChild(MatPaginator) paginator: MatPaginator;

  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  allLeads = [];

  mapOfSort = {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    source: null,
    created_at: null,
  };
  sorted: { key: string; value: string } = { key: 'created_at', value: 'descend' };

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private leadService: LeadsService,
    private audiencesService: AudiencesService,
    private adsService: AdsService,
    private breadcrumService: BreadCrumService,
    private conversationsService: ConversationsService,
    private router: Router,
    private modalService: NzModalService,

  ) { }

  ngOnInit() {
    this.customer = this.authService.currentCustomerValue;
    const customerId = this.customer?.id;
    this.authService.currentCustomer.subscribe(c => {
      if (c?.test) {
        this.router.navigate(['/404']);
      }
      if (c) {
        this.customer = c;
      }
    });
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        if (newText) {
          this.getLeads(`&q[email_or_name_or_phone_or_first_name_or_last_name_matches]=${newText}`);
        } else {
          this.getLeads();
        }
      });

    this.getLeads();
    this.breadcrumService.set_breadcrum();
  }
  exportLeads() {

    this.leadService.exportLeads().subscribe(
      res => {
        const options = { type: `text/csv;charset=utf-8;` };
        this.downloadCsv(res, options, 'contacts.csv');
      }
    );
  }
  downloadCsv(body, options, filename) {
    const blob = new Blob([body], options);
    const _navi = navigator as any
    if (_navi.msSaveBlob) {
      // IE 10+
      _navi.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document?.body.appendChild(link);
        link.click();
        document?.body.removeChild(link);
      }
    }
  }
  getLeads(params= '') {
    this.loading = true;
    const customerId = this.customer?.id;
    const page = `?page=${this.page}&q[s]=created_at desc`;
    params =  page + params;
    const filterParams = `&q[type_eq]=Lead`;
    params = params + filterParams;
    this.audiencesService.list(params).subscribe((res: any) => {
      if (res && res.data.length > 0) {
        this.allLeads = [];
        this.leadsDataSource = [];
        this.leadsDataSource = res.data;
        this.pageInfo = { total_entries: res.total_entries, per_page: res.per_page, current_page: res.current_page };
        this.leadsDataSource.forEach(element => {
          const index = this.allLeads?.findIndex(lead => lead.id === element.id);
          if (index === -1) {
            this.allLeads.push(element);
          }
        });
        this.refreshCheckedStatus();
        // this.leadsDataSource.paginator = this.paginator;
      }
      this.loading = false;
    });
  }
  deleteLead(lead){
    this.leadService.destroy(lead).subscribe(res => {
      this.getLeads();
    });
  }
  deleteReport(lead) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this Lead?',
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.deleteLead(lead);
      }
    });
  }
  didPage(page: number) {
    this.page = page;
    this.getLeads();
  }

  getAds() {
    this.loading = true;
    const customerId = this.customer?.id;
    const visibleStatus = ['active', 'paused'];
    this.adsService.list().subscribe(
      (res: DataResponse<Ad[]>) => {
        if (res && res.data.length > 0) {
          this.contents = res?.data?.filter(
            a => visibleStatus.includes(a.status.toLowerCase())
          );
        }
      },
      error => { },
      () => {
        this.loading = false;
      }
    );
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.allLeads.every(({ id }) => this.setOfCheckedId.has(id));
    this.selectedExportLeads = this.allLeads?.filter(lead => this.setOfCheckedId.has(lead.id));
    this.indeterminate = this.allLeads.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.allLeads?.filter(({ disabled }) => !disabled).forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }
  exportSelected() {
    this.leadService.downloadFile(this.selectedExportLeads);
  }
  createConversation(leadId){
    this.conversationsService.createConversation(leadId).subscribe(respone => {
      this.router.navigate(['/conversations']);
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sorted = sort;
    this.mapSortings();
  }
  mapSortings() {
    for (const key in this.mapOfSort) {
      this.mapOfSort[key] = key === this.sorted.key ? this.sorted?.value : null;
    }
    const valuesMap = {
      descend: 'desc',
      ascend: 'asc'
    };
    if (this.sorted.key) {
      this.getLeads(`&q[s]=${this.sorted.key} ${valuesMap[this.sorted?.value]}`);
    } else {
      this.getLeads();
    }
  }
}
