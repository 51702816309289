<!-- <app-sidebar> -->
<div class="container">
  <div class="title-header">
    <h1 class="section-title title">
      <i nz-icon nzType="solution"></i>
      Contacts List
    </h1>
  </div>
  <div class="contact-widget">
    <app-select-audience ></app-select-audience>
  </div>
</div>
<!-- </app-sidebar> -->
