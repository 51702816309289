import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { GlobalsService } from '../../services/globals/globals.service';
import { Customer } from '../../vos/customer/customer';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ConnectLinkedinService } from '../../services/connect-linkedin/connect-linkedin.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActionCableService } from 'angular2-actioncable';
import { CustomersService } from '../../services/customers/customers.service';
import { ContentsService } from '../../services/contents/contents.service';
import { Subscription } from 'rxjs';
import { Content } from '../../vos/content/content';
import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import { CustomersGoogleService } from '../../services/customers-google/customers-google.service';
import { GoogleOnboardingDialogComponent } from '../../shared/google-onboarding-dialog/google-onboarding-dialog.component';
import { ProductsService } from '../../services/products/product-api.service';

import { UpgradeDialogComponent } from '../upgrade-dialog/upgrade-dialog.component';
import { Router } from '@angular/router';
import {LinkedinOnboardingDialogComponent} from '../linkedin-onboarding-dialog/linkedin-onboarding-dialog.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-social-post-dialog',
  templateUrl: './social-post-dialog.component.html',
  styleUrls: ['./social-post-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class SocialPostDialogComponent implements OnInit {
  get getLinkedInCode() {
    const payload = this.linkedInService.getAuthorizationCode();
    if (payload) {
      this.linkedinConnected = (payload && payload.status === 'success');
      // this.cdr.detectChanges();
    }
    return payload;
  }

  subscription: Subscription;
  @Input()
  content: any;
  @Input()
  hideFacebook = true;
  @Input()
  hideLinkedIn = false;
  @Input()
  hideGoogle = false;
  @Input()
  hidePosting = false;
  @Input()
  config: any;
  all = false;
  facebook = false;
  google = false;
  linkedin = false;
  customer: Customer;
  linkedinConnected = false;
  loading = false;
  status = false;
  isFacebookConnected = false;
  isGoogleConnected = false;

  step = 0;
  @Output()
  posted = new EventEmitter<Content>();
  PaidTier = false;
  isInfomercials = false;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  constructor(
    public router: Router,
    private globalsService: GlobalsService,
    private modal: NzModalRef,
    private authService: AuthenticationService,
    private linkedInService: ConnectLinkedinService,
    private message: NzMessageService,
    private cableService: ActionCableService,
    private contentService: ContentsService,
    private modalService: NzModalService,
    private customersService: CustomersService,
    private customersFacebookService: CustomersFacebookService,
    private customersGoogleService: CustomersGoogleService,
    private productsService: ProductsService,
  ) {
  }

  ngOnInit() {
    this.setParameters();
    this.checkCustomerConectedFacebookPage();
    this.isInfomercials = this.router.url.includes('social_media_content');
    this.isInfomercials ? this.step = 0 : this.step = 1;
    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
        this.PaidTier = c?.tier === 'ultra';
      }
    });
    this.checkLinkedin();
    this.fetchCustomerProducts();
  }

  setParameters(){
    if(this.nzModalData.content){
      this.content = this.nzModalData.content;
    }
    if(this.nzModalData.hideFacebook != undefined){
      this.hideFacebook = this.nzModalData.hideFacebook;
    }
    if(this.nzModalData.hideGoogle != undefined){
      this.hideGoogle = this.nzModalData.hideGoogle;
    }
    if(this.nzModalData.hideLinkedIn != undefined){
      this.hideLinkedIn = this.nzModalData.hideLinkedIn;
    }
    if(this.nzModalData.hidePosting != undefined){
      this.hidePosting = this.nzModalData.hidePosting;
    }
  }

  checkCustomerConectedFacebookPage() {
    this.customersFacebookService.getCustomerConectedFacebookPage()
      .subscribe(res => {
        if (res) {
          const { user_id_on_provider = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
          if (!user_id_on_provider && !this.customer.fb_page_id) {
            this.isFacebookConnected = false;
          } else {
            this.isFacebookConnected = true;
          }
        }
      });
  }

  checkCustomerConectedGoogleBusiness() {
    this.customersGoogleService.getGoogleUserConnection()
      .subscribe(res => {
        if (res) {
          const { user_id_on_provider = null, meta_data = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
          if (!user_id_on_provider && !meta_data?.location_id_on_provider) {
            this.isGoogleConnected = false;
          } else {
            this.isGoogleConnected = true;
          }
        }
      });
  }

  showFacebnook() {
    const modal = this.modalService.create<FacebookOnboardingDialogComponent, IModalData>({
      nzContent: FacebookOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
    });
  }

  showGoogle() {
    const modal = this.modalService.create<GoogleOnboardingDialogComponent, IModalData>({
      nzContent: GoogleOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      this.checkCustomerConectedGoogleBusiness();
      // this.fetch_instagram_autopost_settings();
    });
  }

  connectLinkedIn() {
    this.linkedInService.authorizeLinkedIn();
  }

  checkLinkedin() {
    localStorage.removeItem('linkedInCode');
    this.customersService.getLinkedin().subscribe((c) => {
      if (c.length > 0 && c[0] && c[0].refresh_token_expires_at) {
        const now = new Date(); /* midnight in China on April 13th */
        now.toLocaleString('en-US', { timeZone: 'America/New_York' });
        const refreshExpireAt = new Date(c[0].refresh_token_expires_at);
        refreshExpireAt.toLocaleString('en-US', { timeZone: 'America/New_York' });
        if (now < refreshExpireAt && c.length > 0) {
          this.linkedinConnected = true;
        }
      }
    });
  }

  changeAll() {
    this.facebook = this.all;
    this.linkedin = this.all;
    this.google = this.all;
  }

  post() {
    if (this.facebook) {
      this.postFB();
    }
    if (this.linkedin) {
      this.postLN();
    }
  }

  postLN() {
    const uid = `${this.content.id}`;
    const request = this.contentService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'posted_on': 'linkedin'
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      // const channel: Channel = this.cableService.cable('wss://dev-api.zentap.com/cable').channel(`PostsChannel`, { uid: uid });
      // Subscribe to incoming messages
      // this.subscription = channel.received().subscribe(message => {
      //   if (message.status === 'failed')  {
      //     this.message.create('error', 'your post has been failed');
      //     this.status = false;
      //   } else {
      this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      // }
      this.content.status = 'posted';
      // });
      this.status = true;
      this.posted.emit(c);
    }, (error) => {
      this.message?.remove();
      this.message?.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }

  postFB() {
    const uid = `${this.content.id}`;
    const request = this.contentService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'facebook'
      }
    }, 'posts');
    request.subscribe(c => {
      const caption = this.content.caption;
      this.loading = false;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      // const channel: Channel = this.cableService.cable('wss://dev-api.zentap.com/cable').channel(`PostsChannel`, { uid: uid });
      // this.subscription = channel.received().subscribe(message => {
      //   if (message.status === 'failed')  {
      //     this.message.create('error', 'your post has been failed');
      //     this.status = false;
      //   } else {
      this.message?.create('success', 'Your facebook post has posted Sucessfully');
      this.status = true;
      // }
      this.content.status = 'posted';
      // });
      // this.message.create('success', 'successfully requested your posting, we will notify when its posted!');
      this.status = true;
      this.posted.emit(c);
    }, (error) => {
      this.message?.remove();
      this.message?.create('error', 'Your facebook post has failed');
      this.status = false;
    });
  }

  removeDialog() {
    this.modal.close();
  }

  upgradePlan() {
    this.modal.close();
    const modal = this.modalService.create({
      nzContent: UpgradeDialogComponent,
      nzFooter: null
    });
  }

  facebookSupport() {
    this.modal.close();
    const modal = this.modalService.create<UpgradeDialogComponent, IModalData>({
      nzContent: UpgradeDialogComponent,
      nzFooter: null,
      nzWidth: 600,
      nzData: {
        facebookSupport: true
      },
    });
  }

  next() {
    this.step = this.step + 1;
  }
  back() {
    this.step = this.step - 1;
  }

  fetchCustomerProducts() {
    if (this.globalsService?.getIsGMBAllowed) {
      return;
    }
    this.loading = true;
    const params: Record<string, any> = {
      'per_page': 40,
    };

    this.productsService.productList(
      `customers/${this.customer?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const googleProduct = data ? data?.find(p => p.abbreviation === 'GMB' && p.locked === false) : null;
        if (googleProduct && googleProduct != null && googleProduct?.tier === 'ultra') {
          this.globalsService.setIsGMBAllowed(true);
        }
        this.loading = false;
      }
    }, err => {
      this.loading = false;
    })

  }
  showLinkedinPages() {
    this.modal.close();
    const modal = this.modalService.create<LinkedinOnboardingDialogComponent, IModalData>({
      nzContent: LinkedinOnboardingDialogComponent,
      nzData: {
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
    });
  }

  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }
}
