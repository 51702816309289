<div class="modal-content">
  <h3>Social Links For Zentap Card</h3>
  <div nz-row>
      <nz-input-group>
        <h4 >Please select a Social Platform!</h4>
        <nz-select  [(ngModel)]="platform" >
          <nz-option nzValue="facebook" nzLabel="Facebook"></nz-option>
          <nz-option nzValue="linkedin" nzLabel="Linkedin"></nz-option>
          <nz-option nzValue="instagram" nzLabel="Instagram"></nz-option>
          <nz-option nzValue="google_business" nzLabel="Google_business"></nz-option>
          <nz-option nzValue="youtube" nzLabel="Youtube"></nz-option>
          <nz-option nzValue="tiktok" nzLabel="Tiktok"></nz-option>
        </nz-select>
      </nz-input-group>
    <ng-template #prefixTemplateUser><span nz-icon nzType="user" ></span></ng-template>


    <nz-input-group class="spac-row">
      <h4 >Please add your URL to Social platform</h4>
      <input type="text" nz-input placeholder="Please add social link url" [(ngModel)]="url"/>
    </nz-input-group>

  </div>



  <div class="modal-footer">
    <button nz-button nzType="default" class="right-space" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleSubmit()">Submit</button>
  </div>
</div>
