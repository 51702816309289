import { Component, OnInit, Input, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-automated-campaigns-dialog',
  templateUrl: './automated-campaigns-dialog.component.html',
  styleUrls: ['./automated-campaigns-dialog.component.scss']
})
export class AutomatedCampaignsDialogComponent implements OnInit {
  @Input() template: any

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);

  constructor(private modal: NzModalRef) { }

  ngOnInit(): void {
    this.template = this.nzModalData.template;
  }

  handleClose(value){
    if(value){
      this.modal.close(value);
    }else{
      this.modal.close(false);
    }
  }

}
