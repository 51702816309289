<div class="container" xmlns="http://www.w3.org/1999/html">
  <form #listingForm="ngForm">

    <div nz-row nzJustify="space-between">
      <div nz-col>
        <h1 class="section-title section-title-small" *ngIf="!limitShow || showFields.includes('images')"
            [class.invalid]="!imagesValid('images') && highlightInvalid">
            Add/Edit Photos
            <ng-container *ngIf="requiredFields.includes('images')">
                <span style="color: red">*</span> (5 required)
            </ng-container>
        </h1>
      </div>
      <!-- <div nz-col >
          <button nz-button nzType="primary" nzGhost (click)="openGallery()" class="space-right">Upload Images<i nz-icon nzType="upload"></i></button>
          <button nz-button nzType="primary" (click)="openGallery(1)" nzGhost>Gallery <i nz-icon nzType="eye"></i></button>
        </div> -->
    </div>
    <h5 class="section-description" *ngIf="!limitShow || showFields.includes('images')">Upload 5 shots of the property
      to show off.</h5>
    <!-- NEED TO HANDLE EXTRA LISTING IMAGES -->
    <app-images-reorder #imageReorder [images]="model.images_attributes" [content]="model" [imageable_type]="'Listing'">
    </app-images-reorder>
    <div nz-row nzGutter="16" class="listing-form" [ngClass]="{'max-width-xl': isExtraLargeScreen}">
      <div nz-col nzSpan="11" *ngIf="showFields.includes('locale')">
        <nz-form-item>
          <!-- <mat-label for="locale">Locale</mat-label> -->
          <nz-form-label [nzSm]="24" [nzXs]="24">Locale</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input maxlength="27" type="text" class="form-control" id="locale" [(ngModel)]="model.locale"
              name="locale" [required]="requiredFields.includes('locale')" [readonly]="lockedFields.includes('locale')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24" *ngIf="!limitShow || (showFields.includes('address'))">
        <nz-form-item>
          <nz-form-label 
            for="address" 
            [nzSm]="24" 
            [nzXs]="24" 
            [nzRequired]="requiredFields.includes('address')">
            Address
          </nz-form-label>
          <nz-form-control 
            [nzValidateStatus]=" loading ? 'validating' : model.address || !submitted ? 'sucess' : 'error'" 
            nzHasFeedback 
            [nzSm]="24" 
            [nzXs]="24" 
            nzExtra="What's shown in the address field above will show on your videos and flyers. We need street, city, state, and zip below to show your listing's address properly on your website.">
            <input 
              nz-input 
              role="presentation" 
              autocomplete="off" 
              maxlength="65" 
              type="text" 
              class="form-control" 
              id="address" 
              [(ngModel)]="model.address"
              name="address" 
              [nzAutocomplete]="auto"
              [required]="requiredFields.includes('address')" 
              (keyup)="onInputChangeSearchAddress($event.target.value)"
              [readonly]="lockedFields.includes('address')" 
            />
            <nz-autocomplete #auto>
              <nz-auto-option *ngFor="let suggestion of suggestions" [nzValue]="suggestion">
                {{ suggestion }}
              </nz-auto-option>
            </nz-autocomplete>
          </nz-form-control>
        </nz-form-item>
      </div>

<!--      <div nz-col nzSpan="7" *ngIf="!limitShow || (showFields.includes('address') && extraFields.includes('city'))">-->
<!--        <nz-form-item>-->
<!--          <nz-form-label for="address" [nzSm]="24" [nzXs]="24" [nzRequired]="requiredFields.includes('address')">Address-->
<!--          </nz-form-label>-->
<!--          <nz-form-control [nzSm]="24" [nzXs]="24">-->
<!--            <input nz-input maxlength="65" type="text" class="form-control" id="address" [(ngModel)]="model.address"-->
<!--              name="address" [required]="requiredFields.includes('address')"-->
<!--              [readonly]="lockedFields.includes('address')">-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--      </div>-->
      <ng-template #headerName>
        <span style="font-style: normal; font-size: 14px; font-weight: 400">Address Details For Website</span>
      </ng-template>
      <div nz-col nzSpan="24" style="margin-bottom: 25px;">
        <nz-collapse  nzGhost>
          <nz-collapse-panel
            [nzHeader]='headerName'
            [nzActive]="submitted">
            <div nz-row nzJustify="space-between">
              <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
                <nz-form-item>
                  <nz-form-label [nzSm]="24" [nzXs]="24" for="city" [nzRequired]="true">Street
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24">
                    <nz-input-group>
                      <input nz-input type="text" [required]="true"
                             [readonly]="lockedFields.includes('city')" placeholder="Street" [(ngModel)]="model.street"
                             name="street" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
                <nz-form-item>
                  <nz-form-label [nzSm]="24" [nzXs]="24" for="city" [nzRequired]="requiredFields.includes('city')">City
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24">
                    <nz-input-group>
                      <input nz-input type="text" [required]="requiredFields.includes('city')"
                             [readonly]="lockedFields.includes('city')" placeholder="City Name" [(ngModel)]="model.city"
                             name="city" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
                <nz-form-item>
                  <nz-form-label [nzSm]="24" [nzXs]="24" for="state" [nzRequired]="requiredFields.includes('state')">State
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24">
                    <nz-input-group>
                      <input nz-input type="text" [required]="requiredFields.includes('state')"
                             [readonly]="lockedFields.includes('state')" placeholder="State" [(ngModel)]="model.state"
                             name="state" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
                <nz-form-item>
                  <nz-form-label [nzSm]="24" [nzXs]="24" for="zip" [nzRequired]="requiredFields.includes('zip')">Zip Code
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24">
                    <nz-input-group>
                      <input nz-input type="text" maxlength="5" minlength="5" [required]="requiredFields.includes('zip')"
                             [readonly]="lockedFields.includes('zip')" placeholder="Enter Zip Code" [(ngModel)]="model.zip"
                             name="zip" (ngModelChange)='searchModelChanged.next($event)' />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

          </nz-collapse-panel>
        </nz-collapse>
      </div>

      <div nz-col nzSpan="8" nzXs="24" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!isCommercialListingVideo && (!limitShow || showFields.includes('listing_type'))">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="listing_type"
            [nzRequired]="requiredFields.includes('listing_type')">Property Type</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <!--<nz-select id="listing_type" [(ngModel)]="model.listing_type" name="listing_type" [ngClass]="{'has-error': !model.listing_type && submitted}"-->
            <!--[required]="requiredFields.includes('listing_type')" (ngModelChange)="changePropertyType($event)">-->
            <!--<nz-option *ngFor="let type of property_types" [nzValue]="type" [nzLabel]="type"></nz-option>-->
            <!--</nz-select>-->
            <input placeholder="Enter Property type" nz-input [(ngModel)]="model.listing_type" id="listing_type"
              (change)=changePropertyType($event.target?.value)
              [ngClass]="{'has-error': !model.listing_type && submitted}" name="listing_type"
              [nzAutocomplete]="autolistingtype" [required]="requiredFields.includes('listing_type')"
              (ngModelChange)="filterProperties($event)" />
            <nz-autocomplete [nzDataSource]="filteredPropertyTypes" #autolistingtype> </nz-autocomplete>

          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="8" nzXs="24" nzMd="12" nzLg="8" nzXl="8" *ngIf="showFields.includes('parking')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="parking" [nzRequired]="requiredFields.includes('parking')">Parking
            Spaces</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="number" class="form-control" placeholder="000" id="parking"
              [required]="requiredFields.includes('parking')" [(ngModel)]="model.parking" name="parking"
              [readonly]="lockedFields.includes('parking')">
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="8" nzXs="24" nzMd="12" nzLg="8" nzXl="8" *ngIf="showFields.includes('tenancy')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="tenancy" [nzRequired]="requiredFields.includes('tenancy')">Tenancy
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-select id="tenancy" [(ngModel)]="model.tenancy" name="tenancy"
              [ngClass]="{'has-error': requiredFields.includes('tenancy') && !model.tenancy && submitted}"
              [required]="requiredFields.includes('tenancy')" [disabled]="lockedFields.includes('tenancy')">
              <nz-option *ngFor="let ten of tenancies" [nzValue]="ten" [nzLabel]="ten"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="24" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('listing_status')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="listing_status"
            [nzRequired]="requiredFields.includes('listing_status')">Display Status
          </nz-form-label>

          <nz-form-control [nzSm]="24" [nzXs]="24" nzExtra="This is the listing status that will appear in your captions and content">
            <nz-select nzShowSearch id="listing_status" [(ngModel)]="model.listing_status" name="listing_status"
              [ngClass]="{'has-error': requiredFields.includes('listing_status') && !model.listing_status && submitted}"
              [required]="requiredFields.includes('listing_status')"
              [disabled]="lockedFields.includes('listing_status')" >

              <nz-option *ngFor="let stat of status" [nzValue]="stat" [nzLabel]="stat"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="10" nzXs="24" nzMd="12" nzLg="10" nzXl="10" *ngIf="showFields.includes('congrats')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="family_name"
            [nzRequired]="requiredFields.includes('family_name')">Recipient's Name</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input maxlength="27" type="text" class="form-control" id="family_name"
              [(ngModel)]="model.family_name" name="family_name" [required]="requiredFields.includes('family_name')">
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="10" nzXs="24" nzMd="12" nzLg="10" nzXl="10" *ngIf="showFields.includes('transaction_type')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="transaction_type"
            [nzRequired]="requiredFields.includes('transaction_type')">Congratulate them on a _________:
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input placeholder="Enter Transaction type" nz-input [(ngModel)]="model.transaction_type"
              (input)="changeTransactionType($event.target?.value)" id="transaction_type"
              [ngClass]="{'has-error': !model.transaction_type && submitted}" name="transaction_type"
              [nzAutocomplete]="autoCompleteTransaction" [required]="requiredFields.includes('transaction_type')" />
              <nz-autocomplete (change)=changePropertyType($event.target?.value) [nzDataSource]="transactionTypes" #autoCompleteTransaction> </nz-autocomplete>

          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('price')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="price" [nzRequired]="requiredFields.includes('price')">Price
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Only number is allowed!">
            <nz-input-group nzPrefix="$">
              <input nz-input type="text" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" (keyup)="onKey($event,'price')"
                class="form-control" id="price" [(ngModel)]="model.price" name="model.price"
                [required]="requiredFields.includes('price')" (ngModelChange)="setNumberFormatter('price')">
              <!--        <span matPrefix>$&nbsp;</span>-->
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('year_build')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="year_build" [nzRequired]="requiredFields.includes('year_build')">
            Year Built</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Only number is allowed!">
            <input nz-input type="text" pattern="\d*" (keyup)="onKey($event,'year_build')" maxLength="4"
              class="form-control" id="year_build" [(ngModel)]="model.year_build" name="model.year_build"
              [required]="requiredFields.includes('year_build')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="24" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('mls_status')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="mls_status" [nzRequired]="requiredFields.includes('mls_status')">
            MLS Status</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-select id="mls_status" [(ngModel)]="model.mls_status" name="mls_status"
              [ngClass]="{'has-error': requiredFields.includes('mls_status') && !model.mls_status && submitted}"
              [required]="requiredFields.includes('mls_status')" [disabled]="lockedFields.includes('mls_status')">
              <nz-option *ngFor="let stat of mlsStatusList" [nzValue]="stat.toLowerCase()" [nzLabel]="stat"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('beds')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="beds" [nzRequired]="requiredFields.includes('beds')">Beds
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Only number is allowed!">
            <input nz-input type="text" pattern="\d*" class="form-control" id="beds" [(ngModel)]="model.beds"
              name="beds" [required]="requiredFields.includes('beds')" [readonly]="lockedFields.includes('beds')">
            <!-- <div class="ant-form-explain err-msg">
                   <span>only number is allowed!</span>
                  </div> -->
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('baths')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="baths" [nzRequired]="requiredFields.includes('baths')">Baths
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Only number is allowed!">
            <input nz-input type="text" pattern="^\d*(\.\d{0,2})?$" class="form-control" id="baths"
              [(ngModel)]="model.baths" name="baths" [required]="requiredFields.includes('baths')"
              [readonly]="lockedFields.includes('baths')" (keyup)="onKey($event,'baths')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('sq_ft')">
        <nz-form-item>
          <nz-form-label [nzRequired]="requiredFields.includes('sq_ft')" [nzSm]="24" [nzXs]="24" for="sq_ft">Sq. Feet
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Only number is allowed!">
            <nz-input-group nzSuffix="sqft">
              <input nz-input type="text" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" class="form-control"
                (keyup)="onKey($event,'sq_ft')" id="sq_ft" [(ngModel)]="model.sq_ft" name="sq_ft"
                [readonly]="lockedFields.includes('sq_ft')" (ngModelChange)="setNumberFormatter('sq_ft')">
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="(!limitShow || showFields.includes('lot_size')) && model.lot_size_type !== 'N/A'">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="lot_size"
            [nzRequired]="requiredFields.includes('lot_size_type') || requiredFields.includes('lot_size')">Lot Size
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
              <ng-template #addOnAfterTemplate>
                <nz-select [(ngModel)]="model.lot_size_type"
                  name="lot_size_type" class="suffix-select lot-select"
                  [disabled]="lockedFields.includes('lot_size_type')" (ngModelChange)="changeLotsize()">
                  <nz-option *ngFor="let type of unitTypes" [nzValue]="type" [nzLabel]="type"></nz-option>
                </nz-select>
              </ng-template>
              <input pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" nz-input type="text" id="lot_size"
                [(ngModel)]="model.lot_size" name="lot_size" [required]="requiredFields.includes('lot_size')"
                [readonly]="lockedFields.includes('lot_size')" (ngModelChange)="setNumberFormatter('lot_size')"
                (keyup)="onKey($event,'lot_size')">
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="(!limitShow || showFields.includes('lot_size')) && model.lot_size_type === 'N/A'">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="lot_size"
            [nzRequired]="requiredFields.includes('lot_size_type') || requiredFields.includes('lot_size')">Lot Size
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
              <ng-template #addOnAfterTemplate>
                <nz-select [nzDisabled]="isCommercialListingVideo" [(ngModel)]="model.lot_size_type"
                  name="lot_size_type" class="suffix-select lot-select"
                  [disabled]="lockedFields.includes('lot_size_type')" (ngModelChange)="changeLotsize()">
                  <nz-option *ngFor="let type of unitTypes" [nzValue]="type" [nzLabel]="type"></nz-option>
                </nz-select>
              </ng-template>
              <input nz-input type="text" id="lot_size" [(ngModel)]="model.lot_size" name="lot_size"
                [required]="requiredFields.includes('lot_size')" [readonly]="lockedFields.includes('lot_size')"
                (ngModelChange)="setNumberFormatter('lot_size')" (keyup)="onKey($event,'lot_size')" disabled>

            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('price_per_sqft')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="price_per_sqft"
            [nzRequired]="requiredFields.includes('price_per_sqft')">Price Per Sqft</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" class="form-control"
              id="price_per_sqft" [(ngModel)]="model.price_per_sqft" name="price_per_sqft"
              [required]="requiredFields.includes('price_per_sqft')"
              (ngModelChange)="setNumberFormatter('price_per_sqft')"
              [readonly]="lockedFields.includes('price_per_sqft')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24" *ngIf="showFields.includes('description')">

        <nz-form-item>
          <div nzSm="24" nzXs="24">
            <div class="label-wrapper">
              <nz-form-label for="description" [nzRequired]="requiredFields.includes('description')">
                Description
              </nz-form-label>
              <label class="muted">
                (Description will only show on flyer and websites)
              </label>
            </div>
          </div>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <textarea nz-input type="text" class="form-control" id="description" [(ngModel)]="model.description"
              name="description" [required]="requiredFields.includes('description')" maxLength="600"></textarea>
            <span class="remaning">{{model.description ? model.description.length: 0}} / 600</span>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('zoning')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="zoning" [nzRequired]="requiredFields.includes('zoning')">Zoning
            Description</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" class="form-control" id="zoning" [(ngModel)]="model.zoning" name="zoning"
              [required]="requiredFields.includes('zoning')" [readonly]="lockedFields.includes('zoning')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('occupancy')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="occupancy" [nzRequired]="requiredFields.includes('occupancy')">
            Occupancy</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-input-number [nzMin]="0" nz-input type="text" class="form-control occupancy-number" id="occupancy"
              (ngModelChange)="occupancyChange($event)" [(ngModel)]="occupancy" name="occupancy"
              [required]="requiredFields.includes('occupancy')" [nzFormatter]="formatterPercent">
            </nz-input-number>
            <!-- <input nz-input type="text" class="form-control occupancy-number" id="occupancy" (ngModelChange)="occupancyChange()" [(ngModel)]="model.occupancy" name="occupancy" [required]="requiredFields.includes('occupancy')"> -->
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('docks')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="docks" [nzRequired]="requiredFields.includes('docks')">Num Docks
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" class="form-control" id="docks" [(ngModel)]="model.docks" name="docks"
              [required]="requiredFields.includes('docks')" [readonly]="lockedFields.includes('docks')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('units')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="units" [nzRequired]="requiredFields.includes('units')">Num Units
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" class="form-control" id="units" [(ngModel)]="model.units" name="units"
              [required]="requiredFields.includes('units')" [readonly]="lockedFields.includes('units')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('unit_mix')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="unit_mix" [nzRequired]="requiredFields.includes('unit_mix')">Unit
            Mix</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" class="form-control" id="unit_mix" [(ngModel)]="model.unit_mix" name="unit_mix"
              [required]="requiredFields.includes('unit_mix')" [readonly]="lockedFields.includes('unit_mix')">
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('price_per_unit')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="price_per_unit"
            [nzRequired]="requiredFields.includes('price_per_unit')">Price Per Unit</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" class="form-control" id="price_per_unit" [(ngModel)]="model.price_per_unit"
              name="price_per_unit" [required]="requiredFields.includes('price_per_unit')"
              [readonly]="lockedFields.includes('price_per_unit')" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]"
              (ngModelChange)="setNumberFormatter('price_per_unit')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('grm')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="grm" [nzRequired]="requiredFields.includes('grm')">GRM
          </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" class="form-control" id="grm" [(ngModel)]="model.grm" name="grm"
              [required]="requiredFields.includes('grm')" [readonly]="lockedFields.includes('grm')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('pro_forma_cap_rate')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="pro_forma_cap_rate"
            [nzRequired]="requiredFields.includes('pro_forma_cap_rate')">Pro Forma Cap Rate</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-input-number [nzMin]="0" nz-input type="text" class="form-control occupancy-number" id="pro_forma_cap_rate"
            (ngModelChange)="proFormaCapRateChange($event)"  [(ngModel)]="proFormaCapRate"  name="pro_forma_cap_rate"
            [required]="requiredFields.includes('pro_forma_cap_rate')" [nzFormatter]="formatterPercent">
          </nz-input-number>

          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8" *ngIf="!limitShow || showFields.includes('cap_rate')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="cap_rate" [nzRequired]="requiredFields.includes('cap_rate')">Cap
            Rate</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-input-number [nzMin]="0" nz-input type="text" class="form-control occupancy-number" id="cap_rate"
            (ngModelChange)="capRateChange($event)"  [(ngModel)]="capRate"  name="cap_rate"
            [required]="requiredFields.includes('cap_rate')" [nzFormatter]="formatterPercent">
          </nz-input-number>
            <!-- <input nz-input type="text" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]" class="form-control" id="cap_rate"
              [(ngModel)]="model.cap_rate" name="cap_rate" [required]="requiredFields.includes('cap_rate')"
              [readonly]="lockedFields.includes('cap_rate')"> -->
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('building_class')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="building_class"
            [nzRequired]="requiredFields.includes('building_class')">Building Class</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <!-- <input placeholder="Enter Building Class" nz-input [(ngModel)]="model.building_class" id="building_class"
               [ngClass]="{'has-error': !model.building_class && submitted}"
              name="building_class" [nzAutocomplete]="buildingclass"
              [required]="requiredFields.includes('building_class')" />
       <nz-autocomplete [nzDataSource]="buildingClasses" #buildingclass> </nz-autocomplete> -->
            <nz-select id="building_class" [(ngModel)]="model.building_class" name="building_class"
              [ngClass]="{'has-error': requiredFields.includes('building_class') && !model.building_class && submitted}"
              [required]="requiredFields.includes('building_class')"
              [disabled]="lockedFields.includes('building_class')">
              <nz-option *ngFor="let bc of buildingClasses" [nzValue]="bc" [nzLabel]="bc"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('lease_type')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="lease_type" [nzRequired]="requiredFields.includes('lease_type')">
            Lease Type</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input matInput type="text" class="form-control" id="lease_type" [(ngModel)]="model.lease_type"
              name="lease_type" [required]="requiredFields.includes('lease_type')"
              [readonly]="lockedFields.includes('lease_type')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" nzXs="12" nzMd="12" nzLg="8" nzXl="8"
        *ngIf="!limitShow || showFields.includes('lease_rate')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="lease_rate" [nzRequired]="requiredFields.includes('lease_rate')">
            Lease Rate</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input type="text" class="form-control" id="lease_rate" [(ngModel)]="model.lease_rate"
              name="lease_rate" [required]="requiredFields.includes('lease_rate')"
              [readonly]="lockedFields.includes('lease_rate')" pattern="([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]"
              (ngModelChange)="setNumberFormatter('lease_rate')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <!-- <h3 nz-col nzSpan="24" *ngIf="showFields.includes('congrats')">Recipient Details</h3> -->
      <!-- <div class="congrats-wrapper" nz-row> -->
      <div nz-col nzSpan="8" class="image-card-wrapper margin-image-card gutter-row"
        *ngIf="showFields.includes('congrats')">
        <nz-form-label [nzSm]="24" [nzXs]="24" *ngIf="showFields.includes('congrats')"
          [nzRequired]="requiredFields.includes('remote_family_photo_url')">Recipient Photo: </nz-form-label>
        <app-image-handler [content]="model" (imageObject)="onUpdateFamilyImage($event)"
          [imageable_type]="'family_photo'" [config]="familyPhotoConfig">
        </app-image-handler>
      </div>
      <div nz-col nzSpan="13" nzXs="24" nzMd="12" nzLg="13" nzXl="13"
        *ngIf="showFields.includes('congrats') && model.extra_attributes">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="cta" [nzRequired]="requiredFields.includes('congrats')">Call to
            Action </nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input placeholder="Select" nz-input [(ngModel)]="model.extra_attributes.CTA"
              (input)="changeCTA($event.target?.value)" id="cta"
              [ngClass]="{'has-error': !model.extra_attributes.CTA && submitted}" name="cta"
              [nzAutocomplete]="autoCompleteCtas" [required]="requiredFields.includes('congrats')" />
            <nz-autocomplete [nzDataSource]="ctas" #autoCompleteCtas> </nz-autocomplete>

          </nz-form-control>
        </nz-form-item>
      </div>
      <!-- <div nz-col nzSpan="24"> -->
      <!-- <div nz-col nzSpan="12" *ngIf="showFields.includes('congrats')">
          <nz-form-item>
            <nz-form-label [nzSm]="24" [nzXs]="24" for="family_name"
              [nzRequired]="requiredFields.includes('family_name')">Recipient's Name</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <input nz-input maxlength="27" type="text" class="form-control" id="family_name"
                [(ngModel)]="model.family_name" name="family_name" [required]="requiredFields.includes('family_name')">
            </nz-form-control>
          </nz-form-item>
        </div> -->
      <!-- <div nz-col nzSpan="12" *ngIf="showFields.includes('congrats')">
          <nz-form-item>
            <nz-form-label [nzSm]="24" [nzXs]="24" for="congrats" [nzRequired]="requiredFields.includes('congrats')">
              Message to Recipients</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <textarea maxlength="300" nz-input type="text" class="form-control" id="congrats"
                [(ngModel)]="model.congrats" name="congrats"
                [required]="requiredFields.includes('congrats')"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div> -->
      <!-- </div> -->
      <!-- </div> -->
      <div nz-col nzSpan="8" *ngIf="showFields.includes('open_house')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="congrats" [nzRequired]="requiredFields.includes('oh_from_date')">
            Open House Date</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input type="date" class="date-control" name="oh_from_date" [(ngModel)]="model.oh_from_date"
              [required]="requiredFields.includes('oh_from_date')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" *ngIf="showFields.includes('open_house')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="oh_start_time"
            [nzRequired]="requiredFields.includes('oh_start_time')">Open House Start Time</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input type="time" class="date-control" name="oh_start_time" [(ngModel)]="model.oh_start_time" step="900"
              [required]="requiredFields.includes('oh_start_time')">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8" *ngIf="showFields.includes('open_house')">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" for="oh_end_time"
            [nzRequired]="requiredFields.includes('oh_end_time')">Open House End Time</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input type="time" class="date-control" name="oh_end_time" [(ngModel)]="model.oh_end_time" step="900"
              [required]="requiredFields.includes('oh_end_time')">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!-- </div> -->
  </form>
</div>
