import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewChecked
} from '@angular/core';
import { MarketReport } from '../../vos/market-report/market-report';
import {
  NgForm,
  ValidationErrors,
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray
} from '@angular/forms';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';
import {NzMessageService} from 'ng-zorro-antd/message';
@Component({
  selector: 'app-market-report-form',
  templateUrl: './market-report-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./market-report-form.component.scss']
})
export class MarketReportFormComponent implements OnInit, AfterViewChecked {
  @ViewChild('marketReportForm') form: NgForm;
  @Output()
  validChange = new EventEmitter<boolean>();
  @Input()
  valid = false;
  @Output()
  errors = new EventEmitter<ValidationErrors>();
  @Input()
  requiredFields = [
    // 'region_name',
    'zip'
  ];
  @Input()
  showFields = [
    'region_name',
    'zip'
  ];
  @Input()
  limitShow = false;
  @Input()
  model = new MarketReport();
  @Input()
  extraFields = [];

  @Input()
  errorStateMatcher: any;

  @Input()
  lockedFields = [];
  highlightInvalid = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  zip;
  zipError = true;
  disabled = false;
  maxZips = 3;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  constructor(
    private marketReportService: MarketReportsService,
    private message: NzMessageService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngAfterViewChecked() {
    this.form.valueChanges.subscribe(() => {
      this.validChange.emit(this.form.valid);
      this.valid = this.form.valid;
    });
  }
  ngOnInit() {
    this.limitShow = true;
    // this.checkZips();
  }
  checkControls(controls: AbstractControl[]) {
    controls.forEach(c => {
      if (c instanceof UntypedFormControl) {
        c.markAsTouched();
        c.updateValueAndValidity();
      } else if (c instanceof UntypedFormGroup) {
        this.checkControls(Object.keys(c.controls).map(k => c.controls[k]));
      } else if (c instanceof UntypedFormArray) {
        this.checkControls(c.controls);
      }
    });
  }
  checkForm() {
    this.highlightInvalid = true;
    this.valid = this.form.valid;
    this.checkControls(Object.keys(this.form.controls).map(k => this.form.controls[k]));
    this.cdRef.detectChanges();
  }
  add(event): void {
    if (event && event.length) {
      this.model.location_attributes.zip_code = event;
      // this.checkZip(event);
    }
  }
   checkZip(zip) {

    if (zip.length >= 5) {
      this.zipValid(zip);
    }
  }
  zipValid(zip: string) {
    let foundInvalid = false;
    this.marketReportService.is_valid_zip(zip).subscribe( response => {
      this.model.location_attributes.zip_code = response.Zip;
      this.message?.create(
        'success',
        'Valid zip code'
      );
  }, error => {
    foundInvalid = true;
      this.model.location_attributes.zip_code = '';
      this.message?.remove();
      this.message?.create(
        'error',
        'zip code could not be found'
      );
  });
  this.zipError = foundInvalid || zip.length < 5;
  this.disabled = !this.zip;
  this.cdRef.detectChanges();
  }
}
