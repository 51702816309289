import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import {HttpClient} from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Ad } from 'src/app/vos/ads/ads';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Content} from '../../vos/content/content';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class AdsServiceConfig {
  uri = '';
}

interface AdsGoals {
  id: number;
  value: string;
  parent: number;
  children: Array <GoalDetails>;
}
interface GoalDetails {
  id: number;
  value: string;
}
interface Ads {
  id: number;
  thumbnail: string;
  title: string;
  type: string;
  created_at: string;
  views: number;
}

interface CampaignAttributes {
  customer_id: number;
  campaign_type: string;
  age_max: number;
  age_min: number;
  locations_attributes: Array <Locations>;
}
interface Locations {
  zip_code: number[];
}

@Injectable()
/**
 * Service class.
 */
export class AdsService extends CustomerResourceService<Ad> {

  public endpoint = 'ads';
  public data_key = 'ad';
  public campaign;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Ad, http, authService);
  }

  // public getAds(id) {
  //   return this.http.get(`${this._urls}/${id}/ads.json`);
  // }

  public getAdsGallery(): Observable<Ad[]> {
    return this.listFrom(this.customerEndpointFor('ads_gallery')).pipe(
      map(resp => {
        return resp.data.map(i => {
          return new Ad(i);
        });
      })
    );
    //   return this.http.get(`${this._urls}/${id}/ads_gallery.json`);
  }
  public getAdsList(page, adfilter?): Observable< any > {
    const params: Record<string, string> = {
      'page': page,
      'q[s]': 'created_at desc',
      'q[tags_name_eq]': adfilter
    };

    return super.listFrom(this.customerEndpointFor('ads'), params);
  }
  public adsList(adfilter, page): Observable<Ad[]> {

    const params: Record<string, string> = {
      'page': page,
      'q[s]': 'created_at desc',
      'q[tags_name_eq]': adfilter
    };

    return super.listFrom(this.customerEndpointFor('ads/defaults'), params).pipe(
      map(resp => {
        return resp.data;
      })
    );
  }
  public getAdsTags(): Observable<any> {
    return super.listTags(this.customerEndpointFor('ads/tags')).pipe(
      map( resp => {
        return resp.data;
      })
    );
  }
  // public showAd(id): Observable<Ad[]> {
  //   return super.show(id).pipe(
  //     map( resp =>{
  //       return resp;
  //     });
  //   );
  // }
  // public launchAd(campaign_type: string, content: Content, age: number[], location: any [] ): Observable <Ad> {
  public launchAd(campaign_type: string, content: Content, location: any [] ): Observable <Ad> {
    this.campaign  =  {
        post_attributes: {
          customer_id: this.authService.currentCustomerValue?.id,
          content_id: content.id
        },
        campaign_attributes: {
          customer_id: this.authService.currentCustomerValue?.id,
          campaign_type: campaign_type,
          locations_attributes: location
        }
    };
    return super.create(this.campaign).pipe(resp => {
      return resp;
      });
    // return ;
  }
  public detect(): boolean {
    const iframee = document.createElement('iframe');
    iframee.height = '1px';
    iframee.width = '1px';
    iframee.id = 'ads-text-iframe';
    iframee.src = 'https://' + window.location.hostname + '/ads.html';
    document?.body.appendChild(iframee);
    const iframe = document.getElementById('ads-text-iframe');
    return (iframe.style.display === 'none' || iframe.style.display === 'hidden' || iframe.style.visibility === 'hidden' || iframe.offsetHeight === 0);
  }
}
