import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { WelcomePackageService } from '../../services/welcome-package/welcome-package.service';

@Component({
  selector: 'app-welcome-package',
  templateUrl: './welcome-package.component.html',
  styleUrls: ['./welcome-package.component.scss']
})
export class WelcomePackageComponent implements OnInit {
  loading = true;
  customer: Customer;
  welcomeData = [];
  constructor(private route: ActivatedRoute, private authService: AuthenticationService,
    private breadcrumService: BreadCrumService,
    public router: Router,
    private welcomePackageService: WelcomePackageService) { }

  ngOnInit() {
  this.loading = true;
    this.getWelcomePackageData();
  }
  getWelcomePackageData() {
    this.welcomePackageService.list().subscribe(res => {
      this.welcomeData = res['welcome_package'];
      this.loading = false;
    });
  }
  goHome() {
    this.router.navigate(['/']);
  }
}
