<h1 class="section-title section-title-small" *ngIf="!limitShow">
  Campaign Details
</h1>
<div
  class="customer-form-realtor"
  nz-row
  nzType="flex"
  nzJustify="space-between"
>
  <nz-form-item
    class="gutter-row"
    nz-col
    [nzSpan]="11"
    nzOffset="1"
    *ngIf="!limitShow || showFields.includes('campaign_name')"
  >
    <nz-form-label nzFor="campaign_name">Campaign Name </nz-form-label>
    <nz-form-control nzErrorTip="Please input your Campaign Name">
      <input
        type="text"
        nz-input
        type="text"
        id="campaign_name"
        [(ngModel)]="model.campaign_name"
        (ngModelChange)="changeNme($event);"
        name="campaign_name"
        required
        (blur)="handleFocusChange()"
        placeholder="Campaign Name"
        [class.error]="hasError.campaign_name"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item
    class="gutter-row"
    nz-col
    nzSpan="11"
    nzOffset="1"
    *ngIf="!limitShow || showFields.includes('template')"
  >
    <nz-form-label class="text-left" [nzSm]="24" [nzXs]="24" nzFor="template"
      >Templates
    </nz-form-label>
    <nz-form-control nzErrorTip="Please input your template!">
      <input
        nz-input
        type="text"
        id="template"
        [(ngModel)]="model.template"
        name="template"
        placeholder="Select template"
        (ngModelChange)="changeTemplate($event)"
        [nzAutocomplete]="auto"
      />
      <nz-autocomplete #auto>
        <nz-auto-option *ngFor="let template of templates" [nzValue]="template.display_name">{{template.display_name}}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>
</div>
<div
  class="customer-form-realtor"
  nz-row
  nzType="flex"
  nzJustify="space-between"
>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="11" nzOffset="1">
    <nz-form-label [nzSm]="24" [nzXs]="24" for="start_date"
      >Campaign Start Date
    </nz-form-label>
    <nz-form-control
      [nzSm]="24"
      [nzXs]="24"
      nzErrorTip="Please select start date"
    >
      <nz-date-picker
        class="schedule-picker_input"
        [required]="requiredFields.includes('start_date')"
        placeholder="Start Date"
        nzFormat="MM-dd-yyyy"
        [nzDisabledDate]="disabledDate"
        [(ngModel)]="model.start_date"
        (ngModelChange)="changeTime($event);"
        name="start_date"
        [class.error]="hasError.start_date"
      >
      </nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="11" nzOffset="1">
    <nz-form-label [nzSm]="24" [nzXs]="24" for="start_time"
      >Campaign Start Time
    </nz-form-label>
    <nz-form-control
      [nzSm]="24"
      [nzXs]="24"
      nzErrorTip="Please select start time"
    >
      <nz-time-picker
        class="schedule-picker_input"
        [nzDisabled]="!model.start_date"
        [required]="requiredFields.includes('start_time')"
        [(ngModel)]="start_time"
        name="start_time"
        [nzUse12Hours]="true"
        nzFormat="h:mm a"
        [nzDisabledHours]="disabledHours"
        (ngModelChange)="changeTime($event);"
        [class.error]="hasError.start_time"
      >
      </nz-time-picker>
    </nz-form-control>
  </nz-form-item>
</div>
<div class="customer-form-realtor" nz-row nzType="flex">
  <nz-form-item
    class="gutter-row"
    nz-col
    [nzSpan]="24"
    nzOffset="1"
    *ngIf="selectedTemplateName === 'New Listing (Residential)' || selectedTemplateName == 'Open House' || selectedTemplateName == 'Recent Sale of Agent' || selectedTemplateName == 'Market Updates' || selectedTemplateName == 'New Review Spotlight' || selectedTemplateName == 'Request Google Review (Include QR code)' "
  >
    <nz-form-label for="allowDuplicateContact">Automate Campaign</nz-form-label>
    <nz-form-control [nzSm]="24" [nzXs]="24">
      <nz-input-group>
        <nz-switch
          [(ngModel)]="isAutomatedCampaign"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="OpenDialog($event);"
        ></nz-switch>
      </nz-input-group>
    </nz-form-control>
    <p style="color: grey; margin-top: -10px;">
      Whenever you create a listing of the type {{selectedTemplateName}}, it will
      automatically trigger an SMS campaign.
    </p>
  </nz-form-item>
</div>
<div class="customer-form-realtor" nz-row nzType="flex">
  <nz-form-item
    class="gutter-row"
    *ngIf="(selectedTemplateName === 'New Listing (Residential)' || selectedTemplateName == 'Open House' || selectedTemplateName == 'Recent Sale of Agent' || selectedTemplateName == 'Recent Sale of Another Agent')"
    nz-col
    [nzSpan]="11"
    nzOffset="1"
  >
    <nz-form-label [nzSm]="24" [nzXs]="24" for="start_date"
      >Selected Listing
    </nz-form-label>
    <nz-form-control [nzSm]="24" [nzXs]="24">
      <input
        type="text"
        [disabled]="true"
        nz-input
        type="text"
        id="campaign_name"
        [(ngModel)]="selectedAddress"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item
    class="gutter-row"
    class="justify-button"
    *ngIf="selectedTemplateName === 'New Listing (Residential)' || selectedTemplateName == 'Open House' || selectedTemplateName == 'Recent Sale of Agent' || selectedTemplateName == 'Recent Sale of Another Agent'"
    nz-col
    [nzSpan]="3"
    nzOffset="1"
  >
    <div class="listing-button">
      <button nz-button nzType="primary" (click)="selectListing()">
        Select Listing
      </button>
    </div>
  </nz-form-item>
</div>
<div
  class="customer-form-realtor"
  *ngIf="selectedTemplateName == 'Open House'"
  nz-row
  nzType="flex"
  nzJustify="space-between"
>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="8" nzOffset="1">
    <nz-form-label [nzSm]="24" [nzXs]="24" for="oh_start_date"
      >OH Start Date
    </nz-form-label>
    <nz-form-control
      [nzSm]="24"
      [nzXs]="24"
      nzErrorTip="Please select start date"
    >
      <nz-date-picker
        class="schedule-picker_input"
        placeholder="Start Date"
        nzFormat="MM-dd-yyyy"
        [nzDisabledDate]="disabledDate"
        [(ngModel)]="model.oh_start_date"
        (ngModelChange)="changeOhTime($event);"
        name="oh_start_date"
      >
      </nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="7" nzOffset="1">
    <nz-form-label [nzSm]="24" [nzXs]="24" for="oh_start_time"
      >OH Start Time
    </nz-form-label>
    <nz-form-control
      [nzSm]="24"
      [nzXs]="24"
      nzErrorTip="Please select start time"
    >
      <nz-time-picker
        class="schedule-picker_input"
        [nzDisabled]="!model.oh_start_date"
        [(ngModel)]="oh_start_time"
        name="oh_start_time"
        [nzUse12Hours]="true"
        nzFormat="h:mm a"
        [nzDisabledHours]="disabledHours"
        (ngModelChange)="changeOhTime($event);"
      >
      </nz-time-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="gutter-row" nz-col [nzSpan]="7" nzOffset="1">
    <nz-form-label [nzSm]="24" [nzXs]="24" for="oh_end_time"
      >OH End Time
    </nz-form-label>
    <nz-form-control
      [nzSm]="24"
      [nzXs]="24"
      nzErrorTip="Please select start time"
    >
      <nz-time-picker
        class="schedule-picker_input"
        [nzDisabled]="!model.oh_start_date"
        [(ngModel)]="oh_end_time"
        name="oh_end_time"
        [nzUse12Hours]="true"
        nzFormat="h:mm a"
        (ngModelChange)="changeOhTime($event);"
      >
      </nz-time-picker>
    </nz-form-control>
  </nz-form-item>
</div>
<nz-divider *ngIf="!limitShow" [style.margin-bottom]="'30px'"></nz-divider>
<div class="container">
  <div>
    <h2>SMS Template</h2>
  </div>
  <ng-template #templateflow class="mt-5">
    <div>
      <nz-form-item
        appearance="fill"
        nz-col
        [nzSm]="24"
        [nzXs]="24"
        [nzMd]="24"
        class="caption-item top-spacing"
      >
        <nz-form-control [nzSm]="24" [nzXs]="24" class="margin-zero">
          <textarea
            nz-input
            class="form-control content-caption-area"
            id="response"
            [(ngModel)]="model.response"
            name="response"
            required
            nzAutosize
            maxlength="2200"
            (blur)="handleFocusChange()"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </ng-template>
</div>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="templateflow"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="templateflow"></ng-container>
</ng-template>
