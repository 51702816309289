import moment from 'moment';
import { Resource } from '../resource/resource';
import { Content } from '../content/content';

export class SmsCampaign extends Resource {
  // public static categories: { [key: string]: { label: string, mime_type: string }} = {

  // }
  /**
   * id
   */
  id: number;
  name: string;
  template: string;
  customer_id: number;
  status: string;
  start_date_time: moment.Moment;
  total_entries: number;
  current_page: number;
  per_page: number;
  template_type: string;
  is_automated_campaign: boolean;
  /**
   * created_at
   */
  created_at?: moment.Moment;
  /**
   * updated_at
   */
  updated_at?: moment.Moment;

  constructor(vals: any = {}) {
    super(vals);
    Object.assign(this, vals);
  }
}