<div class="operate">
  <div class="contact-actions" *ngIf="contactsDataSource">
<!--    <div nz-row class=" action-btns">-->
<!--      <button nz-button nzType="primary" nz-col nzSpan="3" *ngIf="!isCampaign" (click)="export();"-->
<!--        class="push-right">Export</button>-->
<!--      <button nz-button nzType="primary" nz-col nzSpan="3" class="add-contacts-button add-csv-contacts push-right"-->
<!--        (click)="addContacts()">Add Contacts</button>-->
<!--      <div class="delete-button-container" nz-col nzSpan="4">-->
<!--        <button nz-button nzType="primary" class="add-contacts-button add-csv-contacts push-right"-->
<!--          (click)="deleteContacts()">Delete</button>-->
<!--        <span *ngIf="numberOfChecked">{{ numberOfChecked }} Selected</span>-->
<!--      </div>-->
<!--    </div>-->
    <div nz-row>
      <div nz-col nzSpan="6">
        <div class="search push-right">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search Audience" [ngModel]="searchText"
              (ngModelChange)='searchModelChanged.next($event)' />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
        </div>
      </div>
      <div nz-col nzSpan="18" >
        <button nz-button nzType="primary" *ngIf="!isCampaign" (click)="export();"
                class="push-right">Export</button>
        <button nz-button nzType="primary" class="push-right"
                (click)="addContacts()" *ngIf="campaignProductAvailable">Add Contacts</button>
          <button nz-button nzType="primary" class="push-right"
                  (click)="deleteContacts()">Delete</button>
          <span *ngIf="numberOfChecked">{{ numberOfChecked }} Selected</span>
      </div>
    </div>
    <br />
<!--    <span>Filter: </span>-->
<!--    <nz-tag *ngFor="let filter of audienceFiltersAvailable" nzMode="checkable"-->
<!--      (nzCheckedChange)="toggleFilter($event, filter)">-->
<!--      {{filter.capitalize()}}-->
<!--    </nz-tag>-->
  </div>

</div>
<nz-table nzNoResult="" nzTemplateMode *ngIf="contactsDataSource">
  <thead (nzSortOrderChange)="sort($event)">
    <tr>
      <th [nzSelections]="listOfSelection" nzShowCheckbox nzShowRowSelection [nzSelections]="listOfSelection"
        [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate" (nzCheckedChange)="checkAll($event)"
        [nzDisabled]="isAllContactsSelected"></th>
      <th class="text-center">Conversation</th>
      <th *ngFor="let field of columns" nzShowSort [nzColumnKey]="field" [(nzSortFn)]="mapOfSort[field]">{{field.titleize()}}</th>
<!--      <th class="text-center">Subscribed</th>-->
      <th class="text-center">Edit</th>
      <th class="text-center">Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of contactsDataSource">
      <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.id]" *ngIf="!isAllContactsSelected"
        (nzCheckedChange)="onCheckboxChange(data.id, $event)"></td>
      <td nzShowCheckbox [(nzChecked)]="isAllContactsSelected" (nzCheckedChange)="onCheckboxChange(data.id, $event)"
        *ngIf="isAllContactsSelected" [nzDisabled]="isAllContactsSelected"></td>
      <td class="text-center">
        <button nz-button nzType="primary" nzShape="circle" (click)="createConversation(data);"><span nz-icon nzType="wechat"></span></button>
      </td>
      <td *ngFor="let field of columns">
        <ng-container *ngIf="field !== 'created_at'; else elseBlock">
          {{ data[field] }}
        </ng-container>
        <ng-template #elseBlock>
          <span>{{ data[field] | date : "MM-dd-y" }}</span>
        </ng-template>
      </td>
<!--      <td class="ads-text-style text-center">-->
<!--        <i nz-icon nzType="check" nzTheme="outline" *ngIf="data.subscribing_to_email"></i>-->
<!--        <i nz-icon nzType="close" nzTheme="outline" *ngIf="!data.subscribing_to_email"></i>-->
<!--      </td>-->
      <td class="text-center">
        <a (click)="editContact(data)" class="grey-icon">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
        </a>
      </td>
      <td class="ads-text-style text-center">
        <i (click)="deleteContacts(data)" nz-icon nzType="delete"   nzTheme="twotone" class="delete-contact"></i>
      </td>
    </tr>
  </tbody>
</nz-table>
<div class="table-container">
  <div class="text-center m-5"
    *ngIf="(!contactsDataSource || (contactsDataSource && !contactsDataSource.length)) && !loading && !filter">
    <img src="../../../assets/images/WebsiteEmptyPage.png" />
    <h2 class="create-title mar-zero">Create Your Audience!</h2>
    <button nz-button nzType="primary" class="add-contacts-button add-csv-contacts push-right"
      (click)="addContacts()">Add Contacts</button>
    <!-- <p class="sub-title">After selecting what you want to make, pick your listing, and customize the content before it gets generated for you.
    </p>
    No Audience Found! -->
  </div>
</div>
<div nz-row nzType="flex" nzJustify="end" style="margin-top: 30px;">
  <nz-pagination *ngIf="contactsDataSource.length" [nzPageIndex]="page" [nzTotal]="pageInfo.total_entries"
    nzPageSize="10" [nzShowTotal]="rangeTemplate" (nzPageIndexChange)="didPage($event)"></nz-pagination>
  <ng-template #rangeTemplate let-range="range" let-total>
    {{ range[0] }}-{{ range[1] }} of {{ total }} items
  </ng-template>
</div>
<div class="spinner" *ngIf="loading">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>
