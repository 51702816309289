import { Component, OnInit, ChangeDetectorRef, Input, inject } from '@angular/core';
import * as Papa from 'papaparse';
import { Observable, Observer } from 'rxjs';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Contact } from 'src/app/vos/contact/contact';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

import { ContactsService } from 'src/app/services/contacts/contacts.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import {ImageConfig} from '../../models/interfaces/modals/image-config';
enum UploadState {
  Single = 1,
  Multi = 2,
  ParseHeader = 3,
  Preview = 4
}
@Component({
  selector: 'app-contacts-uploader',
  templateUrl: './contacts-uploader.component.html',
  styleUrls: ['./contacts-uploader.component.scss']
})
export class ContactsUploaderComponent implements OnInit {
  @Input()
  formState;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: any = inject(NZ_MODAL_DATA);

  @Input() contact: any;
  @Input() isEdit: boolean;

  contactPreviewSource: Contact[];
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  loading = false;
  page = 1;
  parseResult: Papa.ParseResult<any>;
  parseHeader: any;
  parseError: any;
  submitError: Error;
  csvFile: File;
  uploadState: UploadState;
  contactForm: UntypedFormGroup;
  parsedContacts: Contact[];
  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: NzUploadFile[]) => {
        const fileType = fileList[0].name.split('.');
        const fileTypeExt = fileType[fileType.length - 1];
        const filterFiles = fileList.filter(w => w.type.indexOf('text/csv') > -1 || fileTypeExt === 'csv');
        if (filterFiles.length !== fileList.length) {
          this.msg.error(`Invalid file type, you can upload only 'csv' format, please convert your file to csv format before uploading!`);
          return filterFiles;
        }
        return fileList;
      }
    },
    {
      name: 'async',
      fn: (fileList: NzUploadFile[]) => {
        return new Observable((observer: Observer<NzUploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      }
    }
  ];

  headerMap = {
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    company: null,
    address: null,
  };
  formError: Error;
  contactFields = [
    'first_name',
    'last_name',
    'email',
    'phone',
    'company',
    'address',
  ];
  contactFieldsRequired = [
    'first_name',
    'last_name',
    'email',
  ];
  constructor(
    private modal: NzModalRef,
    private fb: UntypedFormBuilder,
    private contactsService: ContactsService,
    private cd: ChangeDetectorRef,
    private msg: NzMessageService,

  ) { }

  ngOnInit() {
    if(this.nzModalData && this.nzModalData.contact){
      // this.formState = this.nzModalData.formState;
      this.contact = this.nzModalData.contact;
      this.isEdit = this.nzModalData.isEdit;
    }
    if (this.formState) {
      this.uploadState = this.formState;
    }
    this.contactForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      phone: [null],
      company: [null],
      address: [null]
    });

    if (this.isEdit) {
      this.uploadState = 1;
      this.contactForm.patchValue({
        email: this.contact.email,
        first_name: this.contact.first_name,
        last_name: this.contact.last_name,
        phone: this.contact.phone,
        company: this.contact.company,
        address: this.contact.address
      });
    } else {
      if (this.formState) {
        this.uploadState = this.formState;
      }
    }
  }



  parseCsv = (file): boolean => {
    this.parseError = null;

    Papa.parse(file, {
      dynamicTyping: true,
      complete: (results, f) => {
        this.csvFile = f;
        this.parseHeader = results.data[0];
        this.uploadState = this.parseHeader && !this.parseError ? UploadState.ParseHeader : UploadState.Multi;
      },
      error: (error, _) => {
        console.log(error);

        this.parseError = error;
      },
    });
    return false;
  }

  submitContact() {
    this.loading = true;
    if(this.isEdit){
      const payload = {
        id: this.contact.id,
        data: this.contactForm.value
      }
      this.contactsService.updateContact(payload).subscribe(c => {
        this.modal.destroy(c);
      }, (error) => {
        let eMsg = error.message;
          if (error.error) {
            const key = Object.keys(error.error)[0];
            eMsg = `${key} ${error.error[key][0]}`;
          }
          this.formError = eMsg;
          this.msg?.remove();
          this.msg?.create('error', eMsg);
          this.loading = false;
      });
    }
    else
    {
      this.contactsService.create(this.contactForm.value).subscribe(c => {
        this.modal.destroy(c);
      },
        (error) => {
          let eMsg = error.message;
          if (error.error) {
            const key = Object.keys(error.error)[0];
            eMsg = `${key} ${error.error[key][0]}`;
          }
          this.formError = eMsg;
          this.msg?.remove();
          this.msg?.create('error', eMsg);
          this.loading = false;
        });
    }
  }

  mapHeaders() {
    this.loading = true;
    Papa.parse(this.csvFile, {
      dynamicTyping: true,
      header: true,
      skipEmptyLines: true,
      complete: (results, file) => {
        this.loading = false;
        this.parsedContacts = results.data.map(d => {
          const newContact = {};
          this.contactFields.forEach(f => {
            if (f === 'first_name') {
              const names = d[this.headerMap[f]].split(' ');
              newContact[f] = names[0]
            } else if (f === 'last_name') {
              const names = d[this.headerMap[f]].split(' ');
              newContact[f] = names[names.length - 1]
            } else {
              newContact[f] = d[this.headerMap[f]];
            }
          });
          return new Contact(newContact);
        });
        // this.contactPreviewSource = this.parsedContacts;
        this.setPageData();
        // this.contactPreviewSource.data.length = 5;
        this.uploadState = UploadState.Preview;
        this.cd.detectChanges();
      },
      error: (error, file) => {
        this.loading = false;

        this.parseError = error;
      },
    });
    return false;
  }

  setPageData() {
    const page = this.page - 1;
    this.contactPreviewSource = this.parsedContacts.slice(page * 5, (page + 1) * 5);
  }

  didPage(page: number) {
    this.page = page;
    this.setPageData();
  }
  submitContacts() {
    this.loading = true;
    this.contactsService.createMultiple(this.parsedContacts).subscribe(c => {
      this.modal.destroy(c);
    }, error => {
      this.loading = false;
      this.formError = error;
    });
  }
  get isUploaderFormValid() {
    return this.headerMap.first_name && this.headerMap.last_name && this.headerMap.email
  }
}
