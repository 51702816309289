<div class="container">
  <div nz-row class="top-row-padding">
    <div nz-col nzSpan="16">
      <h1 class="title">New Campaign</h1>
    </div>
    <div nz-col nzSpan="8">
      <div nz-row style="justify-content: end;">
        <button nz-button [disabled]="isResponse" *ngIf="current === 0" nzType="primary" [disabled]="isNew"
          (click)="next();">
          Next</button>
        <button nz-button *ngIf="current === 1" nzType="primary" (click)="createSmsCampaign();">
          {{isNew ? 'Create SMS' : 'Update SMS'}} Campaign</button>
      </div>
    </div>
  </div>
  <nz-steps [nzCurrent]="current" class="content-stepper">
    <nz-step nzTitle="Create Template" (click)="gotoStep(0)"></nz-step>
    <nz-step nzTitle="Select Audience" (click)="gotoStep(1)"></nz-step>
  </nz-steps>
</div>

<div class="steps-content" *ngIf="current === 0">
  <app-campaign-templates (responseChange)="enableButton($event)"
    (handleResponse)="handleResponse($event)"></app-campaign-templates>
</div>

<div class="container" *ngIf="current === 1">
  <div class="audience-widget">
    <app-audience-selector [isCampaign]="true" [audiences]="audiences"
      (selectedChange)="getSelectedAudiences($event)"></app-audience-selector>
  </div>
</div>