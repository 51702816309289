<div class="modal-content" *ngIf="!referral">
  <h3>How likely are you to recommend Zentap to a friend or colleague?</h3>
  <div class="score-container">
          <span
            *ngFor="let score of scores; let i = index"
            (click)="selectScore(i)"
            [ngClass]="{ 'selected-score': selectedScore === i }"
          >
            {{ i }}
          </span>
  </div>
  <textarea rows="4" nz-input placeholder="Please add your message here" [(ngModel)]="message"></textarea>
  <div class="modal-footer">
    <button nz-button nzType="default" class="right-space" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleSubmit()">Submit</button>
  </div>
</div>



<div class="modal-content" *ngIf="referral">
  <h3>Referral Rewards Program</h3>
  <h4>Please provide us with your referral(s) information down below.</h4>
  <div nz-row>
    <div nz-col nzSpan="16" nzOffset="4">
      <h4 >Would you prefer a $50 Amazon Gift Card or 1 Free Month of Service?</h4>
      <nz-input-group>
        <nz-select  [(ngModel)]="giftType" >
          <nz-option nzValue="Gift Card" nzLabel="Gift Card"></nz-option>
          <nz-option nzValue="Free Month" nzLabel="Free Month"></nz-option>
        </nz-select>
      </nz-input-group>
    </div>


  <h4  nz-col nzSpan="16" nzOffset="4" class="spac-row">Who are you referring?</h4>

  <div  *ngFor="let ref of referrals; let i = index;" nz-col nzSpan="16" nzOffset="4" style="padding: 20px;
    border: 1px solid #8080805c;">
    <nz-alert nzType="error" [nzMessage]="ref.error" *ngIf="ref.error"></nz-alert>
    <nz-input-group [nzPrefix]="prefixTemplateUser" class="spac-row">
      <input type="text" nz-input placeholder="Please add referral name" [(ngModel)]="ref.name" />
    </nz-input-group>
    <ng-template #prefixTemplateUser><span nz-icon nzType="user" ></span></ng-template>


    <nz-input-group [nzPrefix]="prefixTemplateEmailr" class="spac-row">
      <input type="text" nz-input placeholder="Please add referral email" [(ngModel)]="ref.email"/>
    </nz-input-group>
    <ng-template #prefixTemplateEmailr><span nz-icon nzType="mail" ></span></ng-template>


    <nz-input-group [nzPrefix]="prefixTemplatePhone" class="spac-row">
      <input type="text" nz-input placeholder="Please add referral phone number" [(ngModel)]="ref.phone" />
    </nz-input-group>

    <span nz-icon nzType="delete" nzTheme="outline" (click)="removeRef(i)" class="pull-right spac-row"></span>
    <ng-template #prefixTemplatePhone><span nz-icon nzType="phone"></span></ng-template>
  </div>
    <div nz-col nzSpan="16" nzOffset="4" class="spac-row">
      <a (click)="addRef()" >Add More Referrals</a>
    </div>

  </div>



  <div class="modal-footer">
    <button nz-button nzType="default" class="right-space" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleSubmitReferral()">Submit</button>
  </div>
</div>
