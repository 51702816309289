import { Product } from '../product/product';
import { Resource } from '../resource/resource';

export interface Plan {
  id: string;
  tier: string;
  type: string;
  price: number;
}
export class Tier extends Resource {

  id: string;
  name: string;
  icon: string;
  description: string;
  trial_period: number;
  products: Product[];
  monthly_price: number;
  yearly_price: number;
  plans: Plan[];
  get uniqueProducts(): Product[] {
    const prods: Product[] = [];
    const prodNames = new Set<string>();
    this.products.forEach(p => {
      if (!prodNames.has(p.name)) {
        prods.push(p);
        prodNames.add(p.name);
      }
    });
    return prods;
  }
  constructor(vals: any) {
    super(vals);
    this.products = this.products.map(p => new Product(p));
  }

}
