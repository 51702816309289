<div *ngIf="step ==0">
  <div class="social-headings" *ngIf="!hidePosting">
    <h2>Before you Post...</h2>
    <p>Posting this content will not add your business card information to the end of the informercial.<br>If you wish to continue press "Post Content".</p>
  </div>
  <br>
  <br>
  <div nz-row>
    <div nz-col nzSpan="8">
      <button nz-button nzType="default" (click)="removeDialog()">Cancel</button>
    </div>
    <div nz-col nzSpan="8" nzOffset="8">
      <button nz-button nzType="primary" class="pull-right" (click)="next()">Confirm</button>
    </div>
  </div>

</div>
<div *ngIf="step == 1">
  <div class="social-connect-container" *ngIf="!status">
    <div class="social-headings" *ngIf="!hidePosting">
      <h2>Post to Social Media</h2>
      <p>Share Your content to connected social media platforms.</p>
      <p>Connect and choose your platform:</p>
    </div>
  <ul class="social-selection">
    <li *ngIf="hideFacebook && !hidePosting">
      <label class="fw-600" nz-checkbox [nzDisabled]="!this.customer.fb_page_id || !linkedinConnected" [(ngModel)]="all" (ngModelChange)="changeAll()">Select All</label>
    </li>
    <!-- <li *ngIf="hideFacebook">
      <label nz-checkbox [(ngModel)]="facebook" [nzDisabled]="!this.customer.fb_page_id">
        <img class="box-image" src=".././../../assets/icons/facebook.png"  alt=""/>
      </label>
      <span class="fw-600">Share your content to Facebook</span>
      <button nz-button nzType="default" *ngIf="!this.customer.fb_page_id" (click)="facebookSupport()">Contact Us</button>
      <button nz-button nzType="default" class="btn-connected" *ngIf="this.customer.fb_page_id">Connected!</button>
    </li> -->
    <li *ngIf="!hideFacebook">
      <div class="social-headings">
        <h2>Connect Your Account First!</h2>
        <p>Click the 'Connect' button to connect your account</p>
        <p>So we can auto-post all your content</p>
      </div>
      <label nz-checkbox [(ngModel)]="facebook" >
        <img class="box-image" src=".././../../assets/icons/facebook.png"  alt=""/>
      </label>
      <span class="fw-600">Share your content to Facebook</span>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade" *ngIf="!PaidTier">Upgrade</button>
      <button nz-button nzType="default" *ngIf="!isFacebookConnected && PaidTier" (click)="showFacebnook()">Connect</button>
      <button nz-button nzType="default" *ngIf="isFacebookConnected"  class="btn-connected" >Connected!</button>
    </li>
    <li *ngIf="!hidePosting && !hideLinkedIn">
      <label nz-checkbox [(ngModel)]="linkedin" [nzDisabled]="!linkedinConnected">
        <img class="box-image" src=".././../../assets/icons/linkedin.png"  alt=""/>
      </label>
      <span class="fw-600">Share your content to LinkedIn</span>
      <div class="spiner-center loading-overlay" *ngIf="loading">
        <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
      </div>
      <button nz-button nzType="default"  (click)="connectLinkedIn()" *ngIf="!linkedinConnected && PaidTier">Connect</button>
      <button nz-button nzType="default" class="btn-connected" *ngIf="(linkedinConnected || (getLinkedInCode && getLinkedInCode.status === 'success')) && PaidTier">Connected!</button>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade" *ngIf="!PaidTier">Upgrade</button>
    </li>
    <li *ngIf="showGMB && !hideGoogle && config">
      <label nz-checkbox [(ngModel)]="config.google" [nzDisabled]="!isGoogleConnected">
        <img class="box-image" src=".././../../assets/icons/google.png" alt="" />
      </label>
      <span class="fw-600">Google</span>
      <!--                  <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade" *ngIf="!PaidTier">Upgrade</button>-->
      <button nz-button nzType="default" *ngIf="!isGoogleConnected && PaidTier" (click)="showGoogle()">Connect</button>
      <button nz-button nzType="default" *ngIf="isGoogleConnected"  class="btn-connected" >Connected!</button>
      <button nz-button nzType="default" (click)="upgradePlan()" class="btn-upgrade social-btns right-spacing"
      *ngIf="!PaidTier">Upgrade</button>
      <nz-alert
      *ngIf="config.google"
      nzType="warning"
      nzMessage="Warning"
      nzDescription="Please be aware that including your website link, email, or phone number in the caption of a Google Business post will likely result in that post not going through."
      nzShowIcon
    ></nz-alert>
    </li>

    <li *ngIf="hidePosting && !hideLinkedIn" class="hidePosting">
      <div class="social-headings" *ngIf="!linkedinConnected">
        <h2>Connect Your Account First!</h2>
        <p>Click the 'Connect' button to connect your account</p>
        <p>So we can auto-post all your content</p>
      </div>
      <div class="hidePostingConnect" *ngIf="!linkedinConnected">
        <img class="box-image" src=".././../../assets/icons/linkedin.png"  alt=""/>
        <span class="fw-600 l-text">Share your content to LinkedIn</span>
        <div class="spiner-center loading-overlay" *ngIf="loading" >
          <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
        </div>
        <button nz-button nzType="default"   (click)="connectLinkedIn()">Connect</button>
<!--        <button nz-button nzType="default" class="btn-connected" *ngIf="linkedinConnected || (getLinkedInCode && getLinkedInCode.status === 'success')">Connected!</button>-->
      </div>
      <div class="hidePostingConnect" *ngIf="linkedinConnected || (getLinkedInCode && getLinkedInCode.status === 'success')">
        <div class="social-headings">
          <h2>Successfully Connected your personal profile!</h2>
        </div>
        <div class="social-connect-posted">
          <div class="status-container" >
            <div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
            <p>Click 'Select Page' if you want to connect your linkedin Business Page.</p>
            <button nz-button nzType="secondary" class="hidePostingDone custom-bt" (click)="showLinkedinPages()" >Select Page</button>
            <button nz-button nzType="default" class="hidePostingDone" (click)="removeDialog()" >Close</button>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div nz-row nzType="flex" nzJustify="end" *ngIf="!hidePosting">
  </div>
    <div nz-row>
      <div nz-col nzSpan="8">
        <button nz-button nzType="default" (click)="back()"  *ngIF="isInfomercials">Back</button>
      </div>
      <div nz-col nzSpan="8" nzOffset="8">
<!--        <button nz-button nzType="primary" class="pull-right" (click)="post()" [disabled]="!facebook && !linkedin">Post to Selected</button>-->
      </div>
    </div>
  </div>
  <div class="social-headings" *ngIf="status">
    <h2>Successfully Posted!</h2>
    <p>Click the 'Done' button to close this window.</p>
  </div>
  <div class="social-connect-posted" *ngIf="status">
    <div class="status-container" >
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
      <button nz-button nzType="primary" (click)="removeDialog()" >Done</button>
<!--      <button nz-button nzType="primary" (click)="removePoster()">Make another Post</button>-->
    </div>
  </div>
</div>
