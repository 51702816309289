import { NgModule } from '@angular/core';
import { CardButtonComponent } from '../../shared/card-button/card-button.component';
import { ContentCardButtonComponent } from '../../shared/content-card-button/content-card-button.component';

import {SupportPopupComponent} from '../../shared/support-popup/support-popup.component';
import { CommonModule } from '@angular/common';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { DemoNgZorroAntdModule } from '../../ng-zorro-antd.module';

@NgModule({
    declarations: [
        CardButtonComponent,
        SupportPopupComponent,
        ContentCardButtonComponent
    ],
    imports: [CommonModule, DemoNgZorroAntdModule],
    exports: [CommonModule,
        CardButtonComponent, SupportPopupComponent, ContentCardButtonComponent],
    providers: [{ provide: NZ_I18N, useValue: en_US }]
})
export class CardButtonModule {
  constructor() {
  }
}
