<div class="container" xmlns="http://www.w3.org/1999/html">
  <form #ghlForm="ngForm">
    <div
      class="customer-form-realtor"
      nz-row
      nzType="flex"
      nzJustify="space-between"
    >
      <nz-form-item
        class="gutter-row"
        *ngIf="showFields.includes('name')"
        nz-col
        [nzSpan]="7"
        nzOffset="1"
      >
        <nz-form-label nzRequired nzFor="name">Name </nz-form-label>
        <nz-form-control nzErrorTip="Please input your Name">
          <input
            type="text"
            nz-input
            type="text"
            id="name"
            [(ngModel)]="model.name"
            name="name"
            required
            placeholder="Enter Your Name"
            [required]="requiredFields.includes('name')"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item
        class="gutter-row"
        nz-col
        nzSpan="7"
        nzOffset="2"
        *ngIf="showFields.includes('phone')"
      >
        <nz-form-label for="phone" nzFor="phone">Phone Number</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            type="tel"
            id="phone"
            [(ngModel)]="model.phone"
            name="phone"
            placeholder="Enter Your Phone Number"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item
        class="gutter-row"
        *ngIf="showFields.includes('website')"
        nz-col
        [nzSpan]="7"
        nzOffset="1"
      >
        <nz-form-label nzFor="website">Website </nz-form-label>
        <nz-form-control>
          <input
            type="text"
            nz-input
            type="text"
            id="website"
            [(ngModel)]="model.website"
            name="website"
            placeholder="Add your website link"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div
      class="customer-form-realtor"
      nz-row
      nzType="flex"
      nzJustify="space-between"
    >
      <nz-form-item
        class="gutter-row"
        *ngIf="showFields.includes('timezone')"
        nz-col
        [nzSpan]="7"
        nzOffset="1"
      >
        <nz-form-label nzFor="website">Time Zone </nz-form-label>
        <nz-form-control>
          <input
            type="text"
            nz-input
            type="text"
            id="timezone"
            [(ngModel)]="model.timezone"
            name="timezone"
            placeholder="Select timezone"
            [nzAutocomplete]="auto"
          />
          <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let time of timezones" [nzValue]="time"
              >{{time}}
            </nz-auto-option>
          </nz-autocomplete>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="gutter-row" nz-col [nzSpan]="7" nzOffset="1">
        <nz-form-label nzFor="snapshotId">Snapshot ID </nz-form-label>
        <nz-form-control>
          <input
            type="text"
            nz-input
            type="text"
            id="snapshotId"
            [(ngModel)]="model.snapshotId"
            name="snapshotId"
            placeholder="Snapshot ID"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item
        class="gutter-row"
        *ngIf="showFields.includes('timezone')"
        nz-col
        [nzSpan]="7"
        nzOffset="1"
      ></nz-form-item>
    </div>
    <ng-template #headerName>
      <span style="font-style: normal; font-size: 14px; font-weight: 400;"
        >Address Details</span
      >
    </ng-template>
    <div nz-col nzSpan="24" style="margin-bottom: 25px;">
      <nz-collapse nzGhost>
        <nz-collapse-panel [nzHeader]="headerName">
          <div nz-row nzJustify="space-between">
            <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
              <nz-form-item>
                <nz-form-label for="city">City </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="City Name"
                      [(ngModel)]="model.city"
                      name="city"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
              <nz-form-item>
                <nz-form-label for="city">State </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="State Name"
                      [(ngModel)]="model.state"
                      name="state"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
              <nz-form-item>
                <nz-form-label for="country">Country </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Country"
                      [(ngModel)]="model.country"
                      name="country"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="5" nzMd="5" nzLg="5" nzXl="5">
              <nz-form-item>
                <nz-form-label for="postalCode">Postal Code </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      maxlength="5"
                      minlength="5"
                      placeholder="Enter Zip Code"
                      [(ngModel)]="model.postalCode"
                      name="postalCode"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <ng-template #headerInfo>
      <span style="font-style: normal; font-size: 14px; font-weight: 400;"
        >Prospect Info</span
      >
    </ng-template>
    <div nz-col nzSpan="24" style="margin-bottom: 25px;">
      <nz-collapse nzGhost>
        <nz-collapse-panel [nzHeader]="headerInfo" [nzActive]="submitted">
          <div nz-row nzJustify="space-between">
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label nzRequired for="firstName"
                  >First Name
                </nz-form-label>
                <nz-form-control
                  nzErrorTip="Please input your First Name"
                  [nzSm]="24"
                  [nzXs]="24"
                >
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="First Name"
                      [(ngModel)]="model.firstName"
                      name="firstName"
                      [required]="requiredFields.includes('firstName')"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label nzRequired for="city">Last Name </nz-form-label>
                <nz-form-control
                  nzErrorTip="Please input your Last Name"
                  [nzSm]="24"
                  [nzXs]="24"
                >
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Last Name"
                      [(ngModel)]="model.lastName"
                      name="lastName"
                      [required]="requiredFields.includes('lastName')"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label nzRequired for="email">Email </nz-form-label>
                <nz-form-control
                  nzErrorTip="Please input your Email"
                  [nzSm]="24"
                  [nzXs]="24"
                >
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Email"
                      [(ngModel)]="model.email"
                      name="email"
                      [required]="requiredFields.includes('email')"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <ng-template #social>
      <span style="font-style: normal; font-size: 14px; font-weight: 400;"
        >Social</span
      >
    </ng-template>
    <div nz-col nzSpan="24" style="margin-bottom: 25px;">
      <nz-collapse nzGhost>
        <nz-collapse-panel [nzHeader]="social">
          <div nz-row nzJustify="space-between">
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="facebookUrl">Facebook URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Facebook URL"
                      [(ngModel)]="model.facebookUrl"
                      name="facebookUrl"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="googlePlus">Googleplus URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Googleplus URL"
                      [(ngModel)]="model.googlePlus"
                      name="googlePlus"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="linkedIn">LinkedIn URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="LinkedIn URL"
                      [(ngModel)]="model.linkedIn"
                      name="linkedIn"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="foursquare">Foursquare URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Foursquare URL"
                      [(ngModel)]="model.foursquare"
                      name="foursquare"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="twitter">Twitter URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Twitter URL"
                      [(ngModel)]="model.twitter"
                      name="twitter"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="yelp">Yelp URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Yelp URL"
                      [(ngModel)]="model.yelp"
                      name="yelp"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="instagram">Instagram URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Instagram URL"
                      [(ngModel)]="model.instagram"
                      name="instagram"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="youtube">Youtube URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Youtube URL"
                      [(ngModel)]="model.youtube"
                      name="youtube"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="pinterest">Pinterest URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Pinterest URL"
                      [(ngModel)]="model.pinterest"
                      name="pinterest"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="blogRss">BlogRss URL </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="BlogRss URL"
                      [(ngModel)]="model.blogRss"
                      name="blogRss"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7">
              <nz-form-item>
                <nz-form-label for="googlePlacesId"
                  >Google Business Places ID
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-input-group>
                    <input
                      nz-input
                      type="text"
                      placeholder="Google Business Places ID"
                      [(ngModel)]="model.googlePlacesId"
                      name="googlePlacesId"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzMd="7" nzLg="7" nzXl="7"></div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="ok-btn" style="display: flex; justify-content: space-between;">
      <button nz-button nzType="default" (click)="handleClose()">
        Cancel
      </button>
      <button nz-button nzType="primary" (click)="createGhlLocation()">
        Create
      </button>
    </div>
  </form>
</div>
