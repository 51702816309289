import { Resource } from '../resource/resource';

export class Contact extends Resource {

  id: number;
  address: string;
  company: string;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;

  get full_name(): string {
    return `${this.first_name} ${this.last_name}`.capitalizeAll();
  }
  constructor(vals: any) {
    super(vals);
  }

}
