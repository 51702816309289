import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { DataResponse } from 'src/app/models/data-response/data-response';
import { Customer, Integration } from 'src/app/vos/customer/customer';
import { CustomerResourceService } from '../customer-resource.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConnectLinkedinService } from '../connect-linkedin/connect-linkedin.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.api_url}`;
}

@Injectable()

export class CustomersGoogleService extends CustomerResourceService<Customer> {
  public endpoint = 'customers';
  public data_key = 'customer';
  integration;
  public googleUri = environment.google_app_configs.auth_uri;
  public clientId = environment.google_app_configs.client_id;
  public redirectUri = environment.google_app_configs.redirect_uri;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Customer, http, authService);
  }

  authorizeGoogle(customerId?) {
    if(window?.location?.href.indexOf('beta.zentap') > -1) {
      this.redirectUri = 'https://beta.zentap.com/google'
    }
    localStorage.removeItem('linkedInCode');
    const state = customerId ? `&state=${customerId}` : '';
    const url = `${this.googleUri}&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&scope=openid https://www.googleapis.com/auth/plus.business.manage&include_granted_scopes=true&response_type=code&access_type=offline&prompt=consent`;

    window.open(url,
      'popUpWindow',
      'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }


  setGoogleAuthorizationCode(code) {
    if (BroadcastChannel) {
      const bc = new BroadcastChannel('googleConnection');
      bc.postMessage({ code }); /* send */
      localStorage.setItem('googleCode', JSON.stringify(code));
    }
  }

  getGoogleAuthorizationCode() {
    return JSON.parse(localStorage.getItem('googleCode'));
  }


  createGoogleUserConnection(code: string): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/google_business.json`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'google_business'
            },
            code
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
  updateGoogleUserConnection(code: string, id: any): Observable<any> {
    const url = `${this._uri}google_business/${id}`;
    return this.http
      .put<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'google_business'
            },
            code
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }

  updateGooglePageConnection(integration: any, id: any): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/google/page.json`;
    // @ts-ignore
    return this.http
      .put<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration,
            id
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }

  getGoogleUserConnection(): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/google_business.json`;
    return this.http
      .get<DataResponse<Customer>>(
        url,
        { headers: this.headers, observe: 'response' }
      )
  }

  getGoogleAccountLocations(page_token?): Observable<any> {
    let url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/google_business/locations`;
    if(page_token) {
      url = `${url}?page_token=${page_token}`
    }
    return this.http
      .get<DataResponse<Customer>>(
        url,
        { headers: this.headers, observe: 'response' }
      )
  }


  createCustomerConectedGoogleLocation(integrationId: string, locationId: string): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/google_business.json`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'google_business',
              meta_data: {
                            location_id_on_provider: locationId
                          }
            }
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }

  setAutopost(value: any, integration: any) {
    const url = `${this._uri}google_business/${integration?.id}`;
    return this.http
      .put(
        url,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
}
