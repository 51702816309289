<div nz-row class="login-form-first-div">
  <div class="login-form-second-div">
    <div nz-row class="flex-layout">
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-form">
        <div>
          <img width="200px"  src="assets/logos/blue-logo.png" />
          <h2  class="sign-in">Sign In</h2>
          <span class="error" *ngIf="errorMessage">{{ errorMessage }}
            <br>
              <a routerLink="/confirmation"  class="resend-confirmation" *ngIf="showConfirmationLink">Resend Confirmation email?</a>
            </span>
          <form nz-form [formGroup]="authForm" (ngSubmit)="login()" class="login-form">
            <nz-form-item>
              <!--<nz-form-label>Email</nz-form-label>-->
              <nz-form-control nzErrorTip="Email is required">
                <nz-input-group nzPrefixIcon="mail" [nzCompact]="true">
                  <input nz-input name="email" type="email" id="email" formControlName="email" placeholder="Email" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <!--<nz-form-label>Password</nz-form-label>-->
              <nz-form-control nzErrorTip="Please input your Password!" class="input-pad">
                <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                  <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" placeholder="Password" />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <i nz-icon class="show-password" [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                </ng-template>
                <span>
                  <a routerLink="/forgot_password"  class="login-form-forgot">Forgot Password?</a>
                </span>
              </nz-form-control>
            </nz-form-item>
            <button nz-button [nzLoading]="loading" nzType="primary" class="login-button" [disabled]="!authForm.valid && authForm.touched" size="large" type="submit">
              Sign In
            </button>
            <p class="btn-seperator">
              - or -
            </p>
            <button nz-button  class="magic-button" size="large" type="button" (click)="goto('/login/magic')">
              Sign In With Magic Link
            </button>
          </form>
        </div>


        <!--<form nz-form>-->
          <!--<nz-form-item>-->
            <!--<nz-form-control nzErrorTip="Please input your username!">-->
              <!--<nz-input-group nzPrefixIcon="user">-->
                <!--<input-->
                  <!--type="text"-->
                  <!--nz-input-->
                  <!--formControlName="userName"-->
                  <!--placeholder="Username"-->
                <!--/>-->
              <!--</nz-input-group>-->
            <!--</nz-form-control>-->
          <!--</nz-form-item>-->
          <!--<nz-form-item>-->
            <!--<nz-form-control nzErrorTip="Please input your Password!">-->
              <!--<nz-input-group nzPrefixIcon="lock">-->
                <!--<input-->
                  <!--type="password"-->
                  <!--nz-input-->
                  <!--formControlName="password"-->
                  <!--placeholder="Password"-->
                <!--/>-->
              <!--</nz-input-group>-->
            <!--</nz-form-control>-->
          <!--</nz-form-item>-->
          <!--<div nz-row class="login-form-margin">-->
            <!--<div nz-col [nzSpan]="6">-->
              <!--<button nz-button class="" [nzType]="'primary'">Log in</button>-->
            <!--</div><div nz-col [nzSpan]="18">-->
              <!--<a class="login-form-forgot">Forgot Password?</a>-->
            <!--</div>-->
          <!--</div>-->
        <!--</form>-->
      </div>
      <div nz-col  nzXs="24" nzSm="12" nzMd="12" nzLg="12" class="coloumn-center login-img second-bg">
        <img class="login-form-forgot" class="side-image" src="assets/images/media.png" />
      </div>
    </div>
  </div>
</div>
