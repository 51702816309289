import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { CustomerResourceService } from '../customer-resource.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../../models/data-response/data-response';
import { map } from 'rxjs/operators';
import { Resource } from '../../vos/resource/resource';
import { HttpClient } from '@angular/common/http';
import cryptoRandomString from 'crypto-random-string';

@Injectable({
  providedIn: 'root',
})
/**
 * Service class.
 */
export class ConnectLinkedinService extends CustomerResourceService<any> {
  public endpoint = 'linkedin';
  public linkedinUri = environment.linkedin_configs.linkedin_url;
  public clientId = environment.linkedin_configs.client_id;
  public redirectUri = environment.linkedin_configs.redirect_uri;
  public scope = environment.linkedin_configs.scope;
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Resource, http, authService);
  }
  authorizeLinkedIn(customerId?) {
    localStorage.removeItem('linkedInCode');
    const stringLength = Math.floor(Math.random() * 25) + 11;
    const distictString = cryptoRandomString({ length: stringLength, type: 'distinguishable' });
    localStorage.setItem('linkedin_state', distictString);
    const state = `&state=${distictString}`;
    const url = `${this.linkedinUri}&client_id=${this.clientId}${state}&redirect_uri=${this.redirectUri}&scope=${this.scope}`;
    window.open(url,
      'popUpWindow',
      'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }
  setAuthorizationCode(code) {
    localStorage.setItem('linkedInCode', JSON.stringify(code));
  }
  getAuthorizationCode() {
    return JSON.parse(localStorage.getItem('linkedInCode'));
  }
  linkLinkedIn(value: any) {
    return super.create(value);
  }
  setAutopost(value: any, integration: any) {
    return this.http
      .put(
        `${this._uri}linkedin/${integration?.id}.json`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
  public getAutopost(searchQuery?): Observable<DataResponse<any>> {
    const url = `${this._customerURI}${this.customerEndpoint}.json`;
    return this.http
      .get<DataResponse<any>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
}
