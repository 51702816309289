<ng-template #mlsconnns>
<div class="title-header">
  <h1 nz-row class="section-title section-title-small">MLS Connections</h1>
  <h5 nz-row class="section-description">Connect your MLS to unlock additional features.</h5>
  <div class="error" *ngIf="!model.license_number || !model.brokerage">{{mlsError}}</div>
</div>

<div  class="mls-section" nz-row nzType="flex" nzJustify="start">
  <ng-container *ngFor="let conn of mlsConnections; index as i">
    <div class="mls-connection mat-elevation-z8">
      <div [style.marginBottom]="'10px'">
        <h3 [style.display]="'inline-block'" class="mls-detail-header">MLS Details</h3>

        <button class="actn-btn mr-1" nzType="primary" nzShape="round" [ngClass]="conn.status" *ngIf=" conn.status !== 'failed' && (conn.id || i !== mlsConnections.length - 1)"
          nz-button>
          {{conn.status}}
        </button>

        <button class="actn-btn mr-1 failed-btn" nzType="default" nzShape="round" [ngClass]="conn.status" *ngIf=" conn.status === 'failed'"
          nz-button>
          {{conn.status}}
        </button>

        <button class="actn-btn" nzType="primary" nzShape="round" (click)="submitMls(mlsConnections[i])" [ngClass]="conn.status" *ngIf="(conn.id || i !== mlsConnections.length - 1 ) && conn.status ==='failed'"
          nz-button>
          Retry
        </button>
        <button class="actn-btn" nzShape="round" *ngIf="conn.id || i === mlsConnections.length - 1" [ngClass]="'remove'" (click)="deleteMLS(conn)"
          nz-button>
          Remove
        </button>
      </div>
      <div *ngIf="conn.error_message && conn.status === 'failed'" class="error-row">
        {{conn.error_message}}
      </div>
      <nz-form-control [nzValidateStatus]="!mlsConnections[i].user_id_on_provider ? 'error' : null">
        <nz-select nzShowSearch [nzServerSearch]="true"
                   [nzDisabled]="conn.status === 'pending' && conn.id" nzPlaceHolder="Search for your MLS" (ngModelChange)="onChangeMlsConnection(i, $event)"
          [(ngModel)]="conn.meta_data.feed_id" [ngModelOptions]="{standalone: true}" (nzOnSearch)="mlsSearchTerm.next($event)"
          required class="search-mls">
          <nz-option *ngFor="let mlsBoard of mlsBoardResults$ | async" [nzLabel]="mlsBoard.MLSName" [style.whiteSpace]="'normal'" [nzValue]="mlsBoard.FeedId.toString()">
          </nz-option>
        </nz-select>
      </nz-form-control>

      <nz-form-control [nzValidateStatus]="!conn.token ? 'error' : null">
        <input nz-input type="text" [attr.id]="i + '_mls_member_number'" [disabled]="conn.status === 'pending' && mlsConnections[i].id"
          placeholder="MLS Member Number" [(ngModel)]="mlsConnections[i].token" [name]="i + '_mls_member_number'"
          required>
      </nz-form-control>
<!--      <nz-switch [(ngModel)]="mlsConnections[i].share_with_team"-->
<!--                 (ngModelChange)="toggleShareWithTeam(mlsConnections[i], $event)"></nz-switch>-->
<!--      Share with team-->
<!--      <br>-->
<!--      <br>-->

      <label nz-checkbox [(ngModel)]="conn.meta_data.terms_and_conditions" [disabled]="conn.status === 'pending' && mlsConnections[i].id">
        By verifying your MLS membership with Zentap, you understand that your information will be shared with the MLS to validate your membership status.
        The MLS or our data partner, <a href="https://PropMix.io" target="_blank">PropMix.io</a>, may contact you for more information or for agreement to use the data herein
      </label>

      <div class="submit-mls" *ngIf="conn.token && conn.user_id_on_provider && !conn.id">
        <button nz-button nzType="primary" [nzLoading]="submitted" (click)="submitMls(conn)" [disabled]="!conn.meta_data.terms_and_conditions">
          Submit
        </button>
      </div>
    </div>
  </ng-container>
  <div class="mls-connection mls-connection-add mat-elevation-z8" >
    <button nz-button [style.color]="'#000'" (click)="addMLSConnection()" nzBlock>
      Add MLS Connection
    </button>
  </div>
</div>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="mlsconnns"></ng-container>
</nz-spin>
<ng-template #notloading>
    <ng-container [ngTemplateOutlet]="mlsconnns"></ng-container>
  </ng-template>
