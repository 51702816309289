import {Component, OnInit, ViewChild, AfterViewInit, input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Content } from 'src/app/vos/content/content';
import { BreadCrumService } from '../../../services/breadcrum/bread-crum.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ContentReceiptDialogComponent } from '../../../shared/content-receipt-dialog/content-receipt-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { TestimonialAttributes } from 'src/app/shared/interfaces/testmonial-attributes';
import { ContentsService } from 'src/app/services/contents/contents.service';
import { NgForm } from '@angular/forms';
import { ProductStyle, Product } from 'src/app/vos/product/product';
import { ProductsService } from 'src/app/services/products/product-api.service';
import { Customer } from 'src/app/vos/customer/customer';
import { ContentFormComponent } from 'src/app/shared/content-form/content-form.component';
import { GlobalsService } from '../../../services/globals/globals.service';
import { ConnectLinkedinService } from '../../../services/connect-linkedin/connect-linkedin.service';
import { CustomersService } from '../../../services/customers/customers.service';
import { Testimony } from 'src/app/vos/testimony/testimony';
import { TestimonyService } from '../../../services/testimonies/testimonies.service';
import {mergeAll} from 'rxjs';

@Component({
  selector: 'app-create-testimonial',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateTestimonialComponent implements OnInit, AfterViewInit {
  content = new Content();
  testimonial = new TestimonialAttributes();
  testimony = new Testimony();

  testimonialType;
  testimonialContentType;
  images = [];
  showSchedule = false;
  submitted = false;
  socialConfig;
  isVideo = false;
  integration;
  linkedinStatus = false;
  linkedinAutopost = false;
  ishashTagLimit = false;
  yes = true;
  textLength = 0;
  categoryMap = {
    testimonial_videos: 'TS',
    testimonial_flyers: 'TSF'
  };
  uid = this.globalService.randomString();
  galleryImage: any;
  @ViewChild('testimonialForm') form: NgForm;
  @ViewChild('contentForm2') contentForm2: ContentFormComponent;
  current = 0;
  requiredFields = [
    'FamilyName',
    'Message'
  ];
  isPhotoValid = false;
  isGalleryValid = false;
  valid = false;
  styles: ProductStyle[] = [];
  product: Product;
  customer: Customer;
  contentSubject: Customer;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public message: NzMessageService,
    private modalService: NzModalService,
    private breadCrumService: BreadCrumService,
    private authService: AuthenticationService,
    private contentService: ContentsService,
    private productService: ProductsService,
    private globalService: GlobalsService,
    private notification: NzNotificationService,
    private customerService: CustomersService,
    private linkedInService: ConnectLinkedinService,
    private testimonyService: TestimonyService,
  ) { }

  ngAfterViewChecked() {
    if (this.contentForm2) {
      this.content.title = this.contentForm2.model.title;
    }
  }
  ngAfterViewInit() {
    this.form.valueChanges.subscribe(() => {
      this.valid = this.form.valid;
    });
    this.setSocialContentConfig();
  }
  ngOnInit() {
    this.route.data?.subscribe(
      (res: { data: any }) => {
        if (res && res.data) {
        this.testimony = res.data;
         this.testimonial.Message = this.testimony.copy;
         this.testimonial.FamilyName = this.testimony.attestant_name;
         if(this.testimony?.image?.id) {
          this.images[0] = this.testimony?.image.id;
         }
        }
      });

    // Get type of testimonial from Url
    const routerUrl = this.router.url;
    const routerUrlHash = routerUrl.split('/');
    // this.testimonialType = routerUrlHash[2];
    this.testimonialType = routerUrlHash[4].indexOf('testimonial') > -1 ? routerUrlHash[4] : routerUrlHash[3];
    let testiType = this.testimonialType.split('_');
    testiType = testiType.length > 1 ? testiType[1] : 'Video';
    testiType = testiType[0].toUpperCase() + testiType.slice(1);
    this.testimonialContentType = testiType.substring(0, testiType.length - 1);

    this.contentSubject = this.authService.currentCustomerValue;
    this.route.params.subscribe(params => {
      this.customer = this.authService.currentCustomerValue;

      if (!this.customer) {
        this.authService.logout();
        this.router.navigate(['/login']);
        return;
      }
      const endpoint = `customers/${this.customer?.id}/products`;
      // use testimonialType to serch dynamically
      const searchParams = { 'q[name_eq]': this.testimonialType };
      this.productService.productList(endpoint, searchParams).subscribe(p => {
        if (p) {
          this.contentSubject.products = p.data;
          const products = this.contentSubject?.products?.map((product: Product) => new Product(product));
          // use testimonialType to filter products dynamically
          this.product = products?.find((prod) => prod.name === this.testimonialType);
          this.isVideo = this.product?.media_type === 'video';
          if (!this.socialConfig) {
            this.socialConfig = { hideGoogle: this.isVideo };
          }
          this.socialConfig.hideGoogle = this.isVideo;
          if (this.product && this.product.parent_type.includes('customer')) {
            this.content.contentable_type = 'Testimony';
            this.content.contentable_id = this.testimony.id;
            this.fetchStyles();
          }
        }
      });
    });
    this.breadCrumService.set_breadcrum();
    // this.breadCrumService.push_breadcrum({ name: `Testimonial ${this.testimonialContentType}` });
    this.breadCrumService.removeItem(`${this.testimony.id}`);

    this.content.category = this.categoryMap[this.testimonialType];
  }

  create(platforms?, skip_post?) {
    this.content.extra_attributes = {
      ...this.testimonial,
      title: this.content.title,
      privacy_status: 'public'
    };
    this.content.image_ids = this.images;
    this.content.contentable_type = 'Testimony';
    this.content.contentable_id = this.testimony.id;
    delete this.content.image_ids;

    // if(this.content.extra_attributes.Message) {
    //   this.content.extra_attributes.Message =  '"' + this.content.extra_attributes.Message + '"';
    // }
    const contentPayload: any = this.content;
    if (platforms && platforms.length) {
      contentPayload.platforms = platforms;
    }
    if (skip_post) {
      contentPayload.skip_post = true;
    }
    this.contentService?.create(contentPayload).subscribe(response => {
      this.notification?.create(
        'success',
        'Created',
        'Content has successfully been created'
      );
      this.router.navigateByUrl(`/content?new=${response.id}`);
      // this.receipt();
    }, err => {
      this.message?.remove();
      this.message?.create('error', err.message);
    });
  }

  finalSubmit() {
    this.submitted = true;
    const platforms = [];
    // if(this.showSchedule && !this.isScheduleFormValid) {
    //   return;
    // }

    if (this.socialConfig && this.socialConfig.facebook) {
      platforms.push('facebook');
    }
    if (this.socialConfig && this.socialConfig.linkedin) {
      platforms.push('linkedin');
    }
    if (this.socialConfig && this.socialConfig.instagram) {
      platforms.push('instagram');
    }
    if (this.socialConfig && this.socialConfig.google) {
      platforms.push('google_business');
    }
    if (this.socialConfig && this.socialConfig.youtube) {
      platforms.push('youtube');
    }
    if (this.socialConfig && this.socialConfig.tiktok) {
      platforms.push('tiktok');
    }
    if (platforms.length === 0) {
      this.message?.remove();
      this.message?.create('error', 'Please select platform!');
    } else {
      this.create(platforms);
    }
  }

  render() {
    this.submitted = true;
    if (this.showSchedule && !this.isScheduleFormValid) {
      return;
    }
    this.create(false, true);
  }

  // receipt() {
  //   const modal = this.modalService.create({
  //     nzContent: ContentReceiptDialogComponent,
  //     nzData: {
  //       config: {
  //         type: `listing_${this.testimonialContentType.toLowerCase()}`,
  //         link: `/content?content_category_eq=${this.categoryMap[this.testimonialType]}`
  //       }
  //     },
  //     nzFooter: null,
  //     nzWidth: '40%'
  //   });
  // }

  back() {
    this.router.navigate([
      '/'
    ]);
  }

  setFamilyImage(image) {
    if (image) {
      this.images[0] = image.id;
      this.testimony.image_attributes = {
        id: image.id,
        changed: true,
      };
    }
  }
  previous() {
    this.current -= 1;
  }
  next() {
    this.current += 1;
    this.updateTestimony();
    this.fetchStyles();
  }

  updateTestimony() {
    this.testimony.copy = this.testimonial.Message;
    this.testimony.attestant_name = this.testimonial.FamilyName;
    this.testimonyService.update(this.testimony)
      .subscribe(res => {
      })
  }

  fetchStyles() {
    this.productService.styles(this.product, this.contentSubject)
      .subscribe(
        res => {
          if (res) {
            this.styles = res.data;
            if (this.styles.length === 1) {
              this.content.style = this.styles[0].effective_name;
              this.styles[0].selected = true;
            }
          }
        }
      );
  }
  valueChange(value) {
    this.testimonial.Message = value;
    this.textLength = value.length;
  }

  fetch_linkedin_autopost_settings() {
    this.customerService.getLinkedin().subscribe((c) => {
      if (c && c.length > 0) {
        this.linkedinStatus = true;
        this.linkedInService.getAutopost().subscribe(int => {
          this.integration = int.data[0];
          this.linkedinAutopost = this.integration.auto_posting;
          this.socialConfig.linkedin = this.linkedinAutopost;
          this.socialConfig.facebook = this.customer?.settings?.auto_posting;
        });
      }
    });
  }

  setSocialContentConfig() {
    this.fetch_linkedin_autopost_settings();
    this.socialConfig = { content: this.content, facebook: false, linkedin: false };
    this.socialConfig.facebook = this.customer?.settings?.auto_posting;
  }

  get isScheduleFormValid() {
    return (this.socialConfig.facebook || this.socialConfig.linkedin || this.socialConfig.google) &&
      this.content.scheduled_for
  }

  get attestantPhotoConfig() {
    return {
      configMeta: {
        imageableType: 'Content',
        type: 'testimony_attestant_photo',
        aspectRatio: 1,
        minWidth: 200,
        minHeight: 200,
        uid: `attestant_photo_${this.uid}`,
      },
      channel: 'CreateImageChannel',
      image: this.testimony?.image?.original,
      isNew: !!this.testimony?.image?.original,
      target: {
        uid: `attestant_photo_${this.uid}`
      }
    }
  }

  hashTagLimit(value: boolean) {
    this.ishashTagLimit = value;
  }
}
