<app-support-popup [setVisible]="isVisible" (hideModal)="closeInfo()"></app-support-popup>
<!--<div nz-row *ngIf="showButton">-->
<!--<div nz-col nzSpan="18" nzXs="24" nzSm="24" nzMd="12" nzLg="18" nzXl="18">-->
<!--<p class="fb-colour">-->
<!--Connect to your facebook account to view performance statistics.-->
<!--</p>-->
<!--</div>-->
<!--<div nz-col nzSpan="6" nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">-->
<!--<button nz-button nzType="primary" class="link-fb-btn fb-connect" (click)="facebookSupport()">-->
<!--<i nz-icon nzType="facebook" class="fb-icon" nzTheme="fill"></i>-->
<!--Continue with Facebook-->
<!--</button>-->
<!--</div>-->
<!--</div>-->

<!-- <div nz-row nzGutter="16">
  <ng-container *ngFor="let insight of insights">
    <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="gutter-row">
      <div class="gutter-box">
        <nz-card class="custom-card">
          <span nz-tooltip [nzTooltipTitle]="insight.description" class="float-icon">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </span>
          <h2 class="analytics-heading">{{insight.display_name}}</h2>
          <nz-skeleton [nzActive]="isfbConnected" [nzTitle]="false" [nzParagraph]="{ rows: 1 }" [nzLoading]="loading">
            <div class="insight-container">
              <span class="analytics-count">{{insight.value | number}}<span class="unit"> {{insight.unit}}</span></span>
            </div>
            <div class="insights-card-footer">
              {{insight.change}}%
              <i nz-icon nzType="caret-up" nzTheme="outline" style="color:#52c41a;"
                *ngIf="insight.change > 0 || insight.change == 0"></i>
              <i nz-icon nzType="caret-down" nzTheme="outline" style="color:#f5222d;" *ngIf="insight.change < 0"></i>
            </div>
          </nz-skeleton>
        </nz-card>
      </div>
    </div>
  </ng-container>
</div> -->

<div nz-row class="padding-top" nzGutter="16">
  <div nz-col nzSpan="18" nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="14">
    <div class="gutter-box">
      <div class="custom-card p15" *ngIf="!customer.render_only" #calendarContainer>
        <app-content-calender  [hideCreate]="hideCreate" [title]="'Content Calendar'" [calendarHeader]="calendarHeader">
        </app-content-calender>
      </div>
      <div class="custom-card p15" *ngIf="customer.render_only">
        <app-tips [sliderView]="sliderView"></app-tips>
      </div>
    </div>
  </div>
  <div nz-col nzSpan="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="10">
    <div class="gutter-box second">
      <div class="custom-card second-row" #notificationContainer>
        <app-dashboard-notification></app-dashboard-notification>
      </div>
    </div>
  </div>
</div>
<div nz-row class="padding-top" nzGutter="16">
  <div nz-col nzSpan="18" nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="14">
    <div class="custom-card p15" *ngIf="!customer.render_only"si>
      <app-tips [sliderView]="sliderView"></app-tips>
    </div>
  </div>
  <div nz-col nzSpan="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="10">
    <div class="custom-card p15">
      <!-- <h1 class="m-0 section-title title"><i nz-icon nzType="read" class="side-nav-item"></i> News</h1> -->
      <div class="title-header">
        <h1 class="section-title title">
          <i nz-icon nzType="read"></i>
          News
        </h1>
      </div>
      <div class="text-center">
        <app-news [isHomePage]="true"></app-news>
        <!-- <iframe width="360" height="440" src="https://rss.app/embed/v1/carousel/_ByHheuxZaUEZMxe4"
          frameborder="0"></iframe> -->
      </div>
    </div>
  </div>
</div>
