import { Component, ElementRef, HostListener, OnInit, ViewChild , Input, ChangeDetectorRef, inject} from '@angular/core';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { ContentsService } from '../../services/contents/contents.service';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';
import { CustomersService } from '../../services/customers/customers.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Content } from '../../vos/content/content';
import { Customer } from '../../vos/customer/customer';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import numeral from 'numeral';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-content-approval-modal',
  templateUrl: './content-approval-modal.component.html',
  styleUrls: ['./content-approval-modal.component.scss']
})
export class ContentApprovalModalComponent implements OnInit {
  @Input()
  content: any;
  customer: Customer;
  current = 0;
  zipData: any;
  editId: string | null;
  dataPoints = [1, 2, 3, 4, 5, 6];
  index = 'First-content';

  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  @ViewChild(NzInputDirective, { read: ElementRef }) inputElement: ElementRef;

  @HostListener('window:click', ['$event'])
  handleClick(e: MouseEvent): void {
    if (this.editId && this.inputElement && this.inputElement.nativeElement !== e.target) {
      this.editId = null;
    }
  }
  constructor(
    private contentService: ContentsService,
    private cdrf: ChangeDetectorRef,
    private marketReportService: MarketReportsService,
    private customerService: CustomersService,
    private authService: AuthenticationService,
    private nzModalRef: NzModalRef,
  ) { }

  ngOnInit() {
    this.content = this.nzModalData.content;
    if (this.content.extra_attributes &&
      this.content.extra_attributes.data_points && this.content.extra_attributes.data_points.length > 0){
      this.dataPoints.length = this.content.extra_attributes.data_points.length;
    }
  }

  startEdit(id: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.editId = id;
  }

  pre(): void {
    this.current -= 1;
  }

  next(value): void {
    this.current += value;
  }
  deny() {
    const dataPoints = this.content.extra_attributes && this.content.extra_attributes.data_points ?
      this.content.extra_attributes.data_points : [];
    this.marketReportService.search(
      this.content.contentable.location_attributes.zip_code, dataPoints).subscribe(response => {
      this.zipData = response;
      this.current += 1;
    });
  }
  closeModal() {
    this.nzModalRef.close();
  }
  renderContent() {
    const payload  = {content: { contentable_to_render: this.zipData}};
    this.contentService.rerender(this.content, payload).subscribe(response => {
      this.current += 2;
    });
  }
  updateSetting() {
    this.customer = this.authService.currentCustomerValue;
    this.customer.settings['market_report_auto_posting'] = true;
    this.customerService.update(this.customer).subscribe((c) => {
      this.customer = c;
      this.closeModal();
    });
  }
  formatNumber(data, type) {
    const index = data + type;
    let displayValue;
    const value = this.zipData['Data' + index];

    switch (this.zipData['Data' + data + 'Unit']) {
      case '#':
        displayValue = numeral(value).format('0,0');
        break;
      case '$':
        displayValue = numeral(value).format('$0,0');
        break;
      case '%':
        displayValue = `${numeral(value).format('0,0')}%`;
        break;
      case 'sqft':
        displayValue = `${numeral(value).format('0,0')} Sq. Ft.`;
        break;
      case '$/sqft':
        displayValue = `${numeral(value).format('0,0')} Per Sq. Ft.`;
        break;
      case 'days':
        displayValue = `${numeral(value).format('0,0')} Days`;
        break;
      case 'months':
        displayValue = `${numeral(value).format('0,0')} Months`;
        break;
      case 'years':
        displayValue = `${numeral(value).format('0,0')} Years`;
        break;
      default:
        break;
    }
    this.zipData['DataDisplay' + index] = displayValue;
  }
  nextStepAfterPosting() {
    this.customer = this.authService.currentCustomerValue;
    if (this.customer.settings['market_report_auto_posting'] === true) {
      this.next(3);
    } else {
      this.next(2);
    }
  }
}
