import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Customer } from 'src/app/vos/customer/customer';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import {Observable} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.api_url}`;
}

@Injectable()

export class ImagesService extends CustomerResourceService<Customer> {
  public endpoint = 'images';
  private config; // Image Cropper Config FILE: image-cropper.component.ts[object]
  photoParams(image) {
    if (this.config.type === 'cover_photo') {
      return {
        cover_photo: {
          params: {
            image: {
              image: image,
              imageable_type: this.config.imageableType,
              imageable_id: null,
              order: this.config.order,
              uid: this.config.uid,
            }
          },
          isNew: true,
          url: `images`,
          requiredImages: 1
        }
      };
    }
    if (this.config.type === 'listing' && !this.config.id) {
      return {
        listing: {
          params: {
            image: {
              image: image,
              imageable_type: this.config.imageableType,
              imageable_id: this.config.content.id,
              order: this.config.order,
              uid: this.config.uid,
            }
          },
          isNew: true,
          url: `images`,
          requiredImages: 5
        }
      };
    }
    if (this.config.type === 'listing' && this.config.id) {
      return {
        listing: {
          params: {
            image: {
              image: image,
              imageable_type: this.config.imageableType,
              imageable_id: this.config.content.id,
              uid: this.config.uid
            }
          },
          requiredImages: 5,
          isNew: false,
          url: `images/${this.config.id}` // "images/${ImageId}"
        }
      };
    }
    return {
      headshot: {
        params: {
          customer: { image: image }
        },
        isNew: true,
        aspectRatio: 1,
        requiredImages: 1
      },
      logo: {
        maintainAspectRatio: false,
        params: {
          customer: {
            logo: image,
          }
        },
        isNew: true,
        aspectRatio: 1,
        requiredImages: 1
      },
      family_photo: {
        params: {
          listing: {
            family_photo: image
          }
        },
        isNew: true,
        requiredImages: 1,
        url: `listings/${this.config.id}/family_photo`
      },
      attestant_photo: {
        params: {
          image: {
            image: image,
            uid: this.config.uid,
            imageable_type: 'Content',
            key: 'FamilyPhoto',
          }
        },
        requiredImages: 1,
        isNew: true,
        url: `images`
      },
      testimony_attestant_photo: {
        params: {
          image: {
            image: image,
            uid: this.config.uid,
            imageable_type: 'Testimony',
            // key: 'attestant_photo',
          }
        },
        requiredImages: 1,
        isNew: true,
        url: `images`
      },
      announcement_video
      : {
        params: {
          image: {
            image: image,
            uid: this.config.uid,
            imageable_type: 'Content',
            key: 'AnnoucementPhoto',
          }
        },
        isNew: true,
        url: `images`
      },
      team_photo: {
        params: {
          customer: { team_photo: image }
        },
        isNew: true,
        aspectRatio: 1,
        requiredImages: 1
      },
      team_member_photo: {
        params: {
          team_member: { image: image, id: this.config.id }
        },
        isNew: true,
        aspectRatio: 1,
        requiredImages: 1
      }
    };
  }
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Customer, http, authService);
  }

  getPhotoParams(config, image) {
    this.config = config;
    return this.photoParams(image)[config.type];
  }
}
