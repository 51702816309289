<h1 *ngIf="config.title">{{config.title | titlecase}}</h1>
<h3 *ngIf="config.message">
  <pre class="message">{{config.message}}</pre>
</h3>
<!-- <div > -->
<div>
  <div class="video" *ngIf="config.content && config.content.type === 'video'">
    <video preload="none" controls [autoplay]="config.content.autoplay">
      <source [src]="config.content.url">
    </video>
  </div>
  <div *ngIf="config.singleSelect" nz-row nzType="flex" nzJustify="start">
    <div nz-row nzType="flex" nzJustify="start" class="select-option" *ngFor="let option of config.singleSelect"
      (click)="selectOption(option?.value)">
      <img *ngIf="option.image" [src]="option.image" />
      <div class="option-text">{{option.label}}</div>
    </div>
  </div>
  <!-- <form *ngIf="form" (ngSubmit)="onSubmit()" [formGroup]="form">

    <div *ngFor="let field of config.formFields" class="form-row">
      <mat-form-field [ngSwitch]="field.type">
        <label [attr.for]="field.key">{{field.label}}</label>

        <input *ngSwitchCase="'textbox'" [formControlName]="field.key" [id]="field.key" [type]="field.type">

        <select [id]="field.key" *ngSwitchCase="'dropdown'" [formControlName]="field.key">
          <option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</option>
        </select>

        <p *ngSwitchCase="'number'">

        </p>
        <p *ngSwitchDefault>

        </p>
      </mat-form-field>
    </div>

    <div class="form-row">
      <button type="submit" [disabled]="!form.valid">Save</button>
    </div>
  </form> -->

</div>

<!-- </div> -->
<div *ngIf="!config.hideButtons" align="center">
  <div nz-row nzType="flex" nzJustify="space-between" [style.width]="'100%'">
    <div class="links" nz-row nzType="flex" nzJustify="start" *ngIf="config.links">
      <ng-container *ngFor="let link of config.links">
        <a nz-button nzSize="large" *ngIf="!link.href.startsWith('/')" class="link" [style.backgroundColor]="link.color" [href]="link.href" (click)="selectOption();">
          <!-- <fa-icon *ngIf="link.icon" [icon]="link.icon.split(' ')" size="1x"></fa-icon> -->
          {{link.label}}
        </a>

        <button nzSize="large"  nz-button *ngIf="link.href.startsWith('/')" class="link" [style.backgroundColor]="link.color"
        [routerLink]="link.href" (click)="selectOption();">
        <!-- <fa-icon *ngIf="link.icon" [icon]="link.icon.split(' ')" size="1x"></fa-icon> -->
        {{link.label}}
        </button>
        <!-- <button type="button" *ngIf="link.href.startsWith('/')" mat-raised-button class="link" [style.backgroundColor]="link.color"
          [routerLink]="link.href" [mat-dialog-close]="">
          <fa-icon *ngIf="link.icon" [icon]="link.icon.split(' ')" size="1x"></fa-icon>{{link.label}}
        </button> -->
      </ng-container>

    </div>
    <button nz-button  (click)="selectOption();">{{buttonText}}</button>
    <button *ngFor="let b of config.extraButtons" nz-button nzType="primary" [ngStyle]="{'background-color': b.color, 'border-color': b.color}" (click)="selectOption(b.value);">{{b.label}}</button>
  </div>

</div>
