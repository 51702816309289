<div nz-row>
  <div nz-col nzSpan='24'>
    <div class='social-connect-container'>
      <div class='social-headings'>
        <h2>Reactivate Your Account and Save!</h2>
        <p>We miss having you with us! Schedule a call with our team to discuss reactivating your account and enjoy an exclusive discounted rate. Don't miss out on this limited-time offer to get back to the features and benefits you loved.</p>
        <p>Schedule a time for your Account Manager to call and upgrade your plan for you!</p>
      </div>
      <div class='preview'>
        <iframe
          src='https://calendly.com/client-success-call/success-call' width='100%' height='600' frameborder='0'
          allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>
