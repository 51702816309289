import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { CaptionsService } from 'src/app/services/captions/captions.service';
import {Caption} from '../../vos/caption/caption';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-captions',
  templateUrl: './captions.component.html',
  styleUrls: ['./captions.component.scss']
})
export class CaptionsComponent implements OnInit {
  @ViewChild('myTextArea', { static: false }) captionInput: ElementRef;

  captions: any;
  selectedContentType: any;
  currentCaption: Caption = new Caption();
  currentAllowedFields: string[];
  currentSelectedField: string;
  caretPos: any;
  showPreview: boolean;
  preview: any;
  showEmojiPicker = false;
  isHashtagLimit = false;
  hashtags: string[];
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];
  set = 'twitter';
  constructor(
    private captionsService: CaptionsService,
    private notification: NzNotificationService,
  ) { }

  ngOnInit() {
    this.captionsService.list().subscribe(res => {
      this.captions = res;
      this.currentCaption = this.captions[0];
      this.onInput(this.currentCaption.text);
      this.selectedContentType = this.currentCaption.caption_type;
      this.currentAllowedFields = this.currentCaption.fields;
    }, err => {
      console.error(err);
    });
  }

 changeType(event){
    this.currentCaption = this.captions?.filter(c => c.caption_type === event)[0];
    this.currentAllowedFields = this.currentCaption.fields;
    this.onInput(this.currentCaption.text);
 }
 selectFieldype(event){
   this.currentCaption.text = this.currentCaption.text.slice(0, this.caretPos) + event + this.currentCaption.text.slice(this.caretPos);

 }
  saveCaption(){
    this.captionsService.update(this.currentCaption).subscribe(res => {
      this.notification.create(
        'success',
        'Updated',
        'Caption updated successfully!'
      );
    });
  }
  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      this.caretPos = oField.selectionStart;
    }
  }
  fetchPreview(){
    this.preview = '';
    if (this.showPreview){
      this.showPreview = false;
    }else{
      this.showPreview = true;
      this.captionsService.show(this.currentCaption.id).subscribe(res => {
        this.preview = res["text"];
      });
    }
  }
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event) {
    this.currentCaption.text;
    const caption = this.currentCaption.text;
    const text = `${caption}${event.emoji.native}`;
    this.currentCaption.text = text;
    this.captionInput.nativeElement.focus();
    this.captionInput.nativeElement.click();
    this.showEmojiPicker = false;
  }

  onInput(value: string): void {
    this.hashtags = value.match(/#\S+/g) || [];
    if (this.hashtags.length > 25) {
      this.isHashtagLimit = true;
    } else {
      this.isHashtagLimit = false;
    }
  }

  handlePreventDefault(evt){
    evt = (evt) ? evt : window.event;
    if(this.hashtags.length >= 25 && evt.key == '#'){
      evt.preventDefault();
    }
  }

}
