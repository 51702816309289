<nz-layout class="overflow">
  <div class="overflow">
    <nz-content class="set-background">
      <div nz-row nzJustify="center" nzAlign="middle" class="overflow">
        <div nz-col style="margin-top: 50px;" nzXs="24" nzSm="24" nzMd="14" nzLg="8" nzXl="8">
          <div class="heading-text">
            <h2 class="heading" *ngIf="name">{{name}}</h2>
            <h2 class="heading" *ngIf="brokerage">{{brokerage}}</h2>
            <h2 class="heading">Watch: Your current listing in action!</h2>
            <h1 class="heading"> 2,000+ Real Estate Agents Nationwide Trust Zentap for Digital Marketing Automations!
            </h1>
          </div>
          <div mat-card-image class="content-media">
            <video class="internal-content" *ngIf="videoUrl" #videoPlayer autoplay loop controls
              (play)="onVideoPlayed()">
              <source [src]="videoUrl" />
            </video>
          </div>
          <div class="carousel">
            <nz-carousel nzAutoPlay [nzDots]="false">
              <div nz-carousel-content *ngFor="let item of items">
                <div class="carousel-item">
                  <nz-avatar nz-page-header-avatar [nzSize]="100" [nzSrc]="item.image"></nz-avatar>
                  <div class="avatar-info">
                    <nz-page-header-title class="heading">{{item.title}}</nz-page-header-title>
                    <p><nz-rate [ngModel]="item.rating" nzDisabled></nz-rate></p>
                  </div>
                </div>
                <div>
                  <p nz-paragraph class="heading" style="margin-top: 10px;">
                    {{item.description}}
                  </p>
                </div>
              </div>
            </nz-carousel>
          </div>

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="16" nzLg="14" nzXl="14" id="calendly">
          <div class="calendly-inline-widget"
            data-url="https://calendly.com/account-exec/zentap-automate?month=2024-01">
          </div>
        </div>
      </div>
    </nz-content>
    <div nz-row nzSpan="24" style="margin-top: -80px;">
      <img class="img2" src="https://www.zentap.com/wp-content/uploads/2022/01/bsgwhite.png" alt="" srcset="">
    </div>
    <div nz-row nzSpan="24" nzJustify="middle" class="footer">
      <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="3" nzXl="3">
        <h2 class="text-color"> As Featured in.. </h2>
      </div>
      <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="3" nzXl="3"><img class="img"
          src="https://www.zentap.com/wp-content/uploads/2022/01/forbes-logo-black-transparent.png" alt="" srcset="">
      </div>
      <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="3" nzXl="3"><img class="img"
          src="https://www.zentap.com/wp-content/uploads/2022/01/th.png" alt="" srcset="">
      </div>
      <div nz-col nzXs="8" nzSm="8" nzMd="8" nzLg="3" nzXl="3"><img class="img"
          src="https://www.zentap.com/wp-content/uploads/2022/01/1200px-Yahoo_Finance_Logo_2019.png" alt="" srcset="">
      </div>
      <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="4" nzXl="4"><img class="img3"
          src="https://www.zentap.com/wp-content/uploads/2022/01/th-2.png" alt="" srcset="">
      </div>
      <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="4" nzXl="4"><img class="img3"
          src="https://www.zentap.com/wp-content/uploads/2022/01/Digital-Journal-Logo.png" alt="" srcset="">
      </div>
    </div>
  </div>
</nz-layout>
