import {
  Component,
  OnInit,
  HostListener
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { Product } from 'src/app/vos/product/product';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductsService } from '../../services/products/product-api.service';
import { CardButtonConfig } from '../../models/interfaces';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-create-content',
  templateUrl: './select-create-content.component.html',
  styleUrls: ['./select-create-content.component.scss']
})
export class SelectCreateContentComponent implements OnInit {
  productsConfigs: CardButtonConfig[] = [];
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  isListing = false;
  isMarketing = false;
  isInformative = false;

  search = false;
  searchText: string;

  loading = false;
  currentRouteName;
  productSwipe = this.globalsService.getSwiperOptions();
  videos = [];
  flyers = [];
  allVideos = [];
  allFlyers = [];
  public innerWidth: any;
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  customer: Customer;
  cardType = 'dashboard-card';
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.productSwipe.slidesPerView = Math.floor(event.target.innerWidth / 250);
    this.productSwipe.slidesPerGroup = Math.floor(event.target.innerWidth / 250);
  }
  constructor(
    private authService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private productsService: ProductsService,
    private breadcrumService: BreadCrumService,
    private globalsService: GlobalsService,
    private router: Router,
  ) {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        // this.listingPagination.page = 1;
        if (newText) {
          this.search = true;
          // this.fetchContent(newText);
          this.searchFilters();
        } else {
          this.searchFilters();
          // this.fetchContent();
        }
      });
  }
  ngOnInit() {
    this.checkRouting();
    this.customer = this.authService.currentCustomerValue;
    for (let i = 0; i < 8; i++) {
      this.productsConfigs.push({ id: '', title: '', actionText: '', isSkeleton: true });
    }
    this.fetchCustomerProducts();
    this.breadcrumService.set_breadcrum();
    this.detectDevice();
  }

  checkRouting() {
    this.isListing = this.router.url.indexOf('listings') > -1;
    this.isMarketing = this.router.url.indexOf('marketing') > -1;
    this.isInformative = this.router.url.indexOf('informative') > -1;
    if (this.isListing) {
      this.currentRouteName = 'listings';
    }
    if (this.isMarketing) {
      this.currentRouteName = 'marketing';
    }
    if (this.isInformative) {
      this.currentRouteName = 'informative';
    }
  }

  detectDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }
  fetchCustomerProducts() {
    this.loading = true;
    const params: Record<string, any> = {
      'q[additional_service_true]': 'false',
      'q[featured_true]': 'true'
    };

    this.productsService.productList(
      `customers/${this.customer?.id}/products`,
      params
    ).subscribe(resp => {
      if (resp) {
        const products: Product[] = resp.data?.map((product: Product) => new Product(product));
        if (this.isListing) {

          this.videos = products?.filter(product => product.parent_type.indexOf('listing') > -1 && product.media_type.indexOf('video') > -1);
          this.flyers = products?.filter(product => product.parent_type.indexOf('listing') > -1 && product.media_type.indexOf('video') === -1);
        }
        if (this.isMarketing) {

          this.videos = products?.filter(product => {
            return (product.parent_type.indexOf('customer') > -1 || product.parent_type.indexOf('marketing') > -1)
              && product.media_type.indexOf('video') > -1 &&
              product.name.indexOf('branded_infomercial') === -1;
          });
          this.flyers =
            products?.filter(product => {
              return (product.parent_type.indexOf('customer') > -1 || product.parent_type.indexOf('marketing') > -1)
                && product.media_type.indexOf('video') === -1 &&
                product.name.indexOf('branded_infomercial') === -1;
            });
        }
        if (this.isInformative) {

          this.videos = products?.filter(product => {
            return (product.parent_type.indexOf('market_report') > -1 ||
              product.name.indexOf('branded_infomercial') > -1) && product.media_type.indexOf('video') > -1;
          });
          this.flyers = products?.filter(product => {
            return (product.parent_type.indexOf('market_report') > -1 ||
              product.name.indexOf('branded_infomercial') > -1) && product.media_type.indexOf('video') === -1;
          });
        }
        const routeName = this.currentRouteName
        this.videos = this.videos?.map(pd => pd.cardButton(this.customer, pd.parent_type[0], null, routeName));
        this.flyers = this.flyers?.map(pd => pd.cardButton(this.customer, pd.parent_type[0], null, routeName));
        this.allVideos = [...this.videos];
        this.allFlyers = [...this.flyers];
        this.productsConfigs = products?.map(pd => pd.cardButton(this.customer, pd.parent_type[0]));
        this.loading = false;
      }
    });
  }

  searchFilters() {
    if (this.searchText) {
      this.videos = this.allVideos?.filter(vd => {
        return vd.id.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
          vd.title.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) > -1;
      });
      this.flyers = this.allFlyers?.filter(vd => {
        return vd.id.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
          vd.title.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) > -1;
      });
    } else {
      this.videos = [...this.allVideos];
      this.flyers = [...this.allFlyers];
    }
  }

}
