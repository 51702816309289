import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { DataResponse } from 'src/app/models/data-response/data-response';
import { Customer, Integration } from 'src/app/vos/customer/customer';
import { CustomerResourceService } from '../customer-resource.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConnectLinkedinService } from '../connect-linkedin/connect-linkedin.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class CustomersServiceConfig {
  uri = `${environment.api_url}`;
}

@Injectable()

export class CustomersTiktokService extends CustomerResourceService<Customer> {
  public endpoint = 'customers';
  public data_key = 'customer';
  integration;
  public clientKey = environment.tiktok_app_configs.tiktok_client_key;
  public redirectUri = environment.tiktok_app_configs.redirect_uri;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Customer, http, authService);
  }

  authorizeTiktok(customerId?) {
    // if(window?.location?.href.indexOf('beta.zentap') > -1) {
    //   this.redirectUri = 'https://beta.zentap.com/youtube'
    // }
    localStorage.removeItem('tiktokCode');
    const state = customerId ? `&state=${customerId}` : '';
    const url = `https://www.tiktok.com/v2/auth/authorize/?client_key=${this.clientKey}&response_type=code&scope=video.publish,user.info.profile,user.info.basic,video.list,video.upload&redirect_uri=${this.redirectUri}`;

    window.open(url,
      'popUpWindow',
      'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  setTiktokAuthorizationCode(code) {
    if (BroadcastChannel) {
      const bc = new BroadcastChannel('tiktok');
      bc.postMessage({ code }); /* send */
      localStorage.setItem('tiktokCode', JSON.stringify(code));
    }
  }

  getTiktokAuthorizationCode() {
    return JSON.parse(localStorage.getItem('tiktokCode'));
  }


  createTiktokUserConnection(code: string): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/tiktok.json`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'tiktok'
            },
            code
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
  updateTiktokUserConnection(code: string, id: any): Observable<any> {
    const url = `${this._uri}tiktok/${id}`;
    return this.http
      .put<DataResponse<Customer>>(
        url,
        JSON.stringify(
          {
            integration: {
              provider: 'tiktok'
            },
            code
          }
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
  getTiktokUserConnection(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(`${this._uri}${this.customerEndpointFor('tiktok.json')}`,
        { headers: this.headers })
      .pipe(map(resp => (resp.data)));
  }
  deleteTiktokConnection(youtube): Observable<any> {
    return this.http
        .delete<DataResponse<any>>(`${this._uri}tiktok/${youtube.id}`,
            { headers: this.headers })
        .pipe(map(resp => (resp.data)));
  }

  setAutopost(value: any, integration: any) {
    const url = `${this._uri}tiktok/${integration?.id}`;
    return this.http
      .put(
        url,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
}
