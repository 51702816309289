import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import {environment} from '../../../environments/environment';
import {CustomerResourceService} from '../customer-resource.service';
import {Observable} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
import {Resource} from '../../vos/resource/resource';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
/**
 * Service class.
 */
export class ConnectInstagramService extends CustomerResourceService<any> {
  public endpoint = 'instagram';
  public data_key = 'integration';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Resource, http, authService);
  }
  setAutopost(value: any, integration: any) {
    return this.http
      .put(
        `${this._uri}instagram/${integration?.id}.json`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
  public getAutopost(searchQuery?): Observable<DataResponse<any>> {
    const url = `${this._customerURI}${this.customerEndpoint}.json`;
    return this.http
      .get<DataResponse<any>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
  public updateIgName(): any {
    const url = `${this._customerURI}${this.customerEndpoint}/update_user_name`;
    return this.http
      .put<DataResponse<any>>(url, JSON.stringify({}), {
        headers: this.headers
      })
      .pipe(map(resp => resp.data));
  }
}
