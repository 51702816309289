<div class="container" *ngIf="customer">
  <div *ngIf="customer.team_name && customer.team_phone">
  <div nz-row nzType="flex" nzJustify="space-between" class="title-container">
    <h1 class="section-title title">
      <nz-avatar class="user-icon-button mat-elevation-z8" nzIcon="user" [nzSrc]="customer.team_photo.thumb" style="margin-right: 8px;"></nz-avatar>
      Team ({{customer.team_name}})
    </h1>
    <div class="edit-container">
      <a (click)="teamModalFormOpen()">
        <i nz-icon [style.color]="'#fff'" nzType="edit" nzTheme="outline"></i>
      </a>
    </div>
  </div>
  <br/>
  <div nz-row nzType="flex" nzJustify="space-between" class="title-container" style="margin: 41px;">
    <i nz-icon nzType="phone" nzTheme="outline"></i>
    {{customer.team_phone}}
  </div>
  <nz-divider></nz-divider>
  <div nz-row>
    <div nz-col nzSpan="12">
      <h1 class="section-title section-title-small" >Team members</h1>
      <h5 class="section-description">Please input team member details, 4 members are allowed</h5>
    </div>
    <div nz-col nzSpan="4" nzOffset="8">
      <button nz-button  nzType="primary" (click)="teamMemberFormSubmitSet()" [disabled]="this.customer.team_members_attributes.length >= 4">Add Team Member</button>
    </div>
  </div>



  <br />
  <br />
  <nz-table nzTemplateMode>
    <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Title</th>
      <th>Phone</th>
      <th>License</th>
      <th>Website</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of customer.team_members_attributes" class="editable-row">
      <td>
        <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
          <nz-avatar class="user-icon-button mat-elevation-z8" nzIcon="user" [nzSrc]="data.image.thumb" style="margin-right: 8px;"></nz-avatar>
          {{ data.name }}
        </div>
      </td>
      <td>
        <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
          {{ data.email }}
        </div>
      </td>
      <td>
        <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
          {{ data.title }}
        </div>
      </td>
      <td>
        <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
          {{ data.phone }}
        </div>
      </td>
      <td>
        <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
          {{ data.license }}
        </div>
      </td>
      <td>
        <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
          {{ data.website_url }}
        </div>
      </td>
      <td>
        <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this team member?" (nzOnConfirm)="deleteRow(data)" style="padding-left: 5px;">
          <i nz-icon nzType="delete" nzTheme="outline" ></i>
        </a>
        <a style="padding-left: 5px;"  (click)="teamMemberFormEdit(data)" ><i nz-icon nzType="edit" nzTheme="outline"></i></a>
      </td>
    </tr>
    </tbody>
  </nz-table>

  <!-- <app-sidebar> -->
<nz-collapse nzBordered="false" class="is_mobile">
  <nz-collapse-panel nzActive [nzHeader]="creation">
    <ng-container *ngTemplateOutlet="creationCards"></ng-container>
  </nz-collapse-panel>
</nz-collapse>

<div class="is_desktop">

  <div class="title-header" *ngTemplateOutlet="creation"></div>
  <ng-container *ngTemplateOutlet="creationCards"></ng-container>
</div>

<!--    CREATION CARD HEADING & TEMPLATE   -->
<ng-template #creation>
  <nz-divider></nz-divider>
  <!-- <h1 class="sec-title title-space">
    Create Content
  </h1> -->
</ng-template>
<ng-template #creationCards>
  <div nz-row [nzGutter]="8">
    <div nz-col [nzSpan]="6" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" class="products-container" *ngFor="let product of productsConfigs">
      <app-card-button [config]="product" cardType="product-card"></app-card-button>
    </div>
  </div>
</ng-template>
  <nz-modal
    [(nzVisible)]="modalFormVisible"
    nzTitle="Create Team Member"
    nzCentered
    [nzFooter]="modalFooter"
    (nzOnCancel)="modalFormCancel()"
    [nzContent]="modalContent">

    <ng-template #modalContent>
      <nz-steps [nzCurrent]="current" *ngIf="member.new">
        <nz-step nzTitle="Basic Information"></nz-step>
        <nz-step nzTitle="Headshot"></nz-step>
      </nz-steps>
      <br/>
      <br/>
      <div *ngIf="current == 0">
        <div *ngIf="!member.new">
          <div class="label-text" style="margin-left: 55px;"><span>*</span>Team member Photo:</div>
          <app-image-handler [content]="member" (imageObject)="setTeamMemberPhoto($event, member)" [imageable_type]="'team_member_photo'" [config]="teamMemberPhotoConfig(member)"></app-image-handler>
        </div>
        <form nz-form [formGroup]="validateTeamMemberForm">
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">Name</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input formControlName="name" [(ngModel)]="member.name" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber" nzRequired>Email</nz-form-label>
          <nz-form-control
            [nzSm]="14"
            [nzXs]="24">
            <input nz-input formControlName="email" [(ngModel)]="member.email" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="title">Phone</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input formControlName="phone"  [(ngModel)]="member.phone"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="title">Title</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input formControlName="title"  [(ngModel)]="member.title"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="License">License</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input formControlName="license" [(ngModel)]="member.license" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="website">Website</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input formControlName="website_url" [(ngModel)]="member.website_url" />
          </nz-form-control>
        </nz-form-item>
        </form>


      </div>
      <div *ngIf="current == 1">
        <div class="label-text" style="padding-bottom: 38px;">Team member Photo:</div>
        <app-image-handler [content]="member" (imageObject)="setTeamMemberPhoto($event, member)" [imageable_type]="'team_member_photo'" [config]="teamMemberPhotoConfig(member)"></app-image-handler>
      </div>

    </ng-template>
    <ng-template #modalFooter>
      <div nz-row>
        <div nz-col nzSpan="4">
         <button nz-button nzType="default" (click)="modalFormCancel()">Cancel</button>
        </div>
        <div nz-col nzSpan="8" nzOffset="12">
         <button nz-button nzType="primary" *ngIf="current == 0" (click)="teamMemberFormSubmit()" [nzLoading]="saving">{{member.new ? 'Create' : 'Update'}}</button>
          <button nz-button nzType="primary" *ngIf="current == 1" (click)="modalFormCancel()">Done</button>
        </div>
       </div>

    </ng-template>
  </nz-modal>
  </div>

  <div class="image-holder" *ngIf="!customer.team_name && !customer.team_phone">
    <img src="../../../assets/images/listings-empty.png" />
    <h2 class="create-title mar-zero">Create your Team!</h2>
    <p class="sub-title">After creating team, you can add team members.
    </p>
    <button nz-button nzType="primary" (click)="teamModalFormOpen();">Let's Go</button>
  </div>



  <nz-modal
    [(nzVisible)]="teamModalFormVisible"
    nzTitle="Team Details"
    nzCentered
    [nzFooter]="teamModalFooter"
    (nzOnCancel)="teamModalFormCancel()"
    [nzContent]="teamModalContent">

    <ng-template #teamModalContent>
      <form nz-form [formGroup]="validateForm">
        <div>
          <div>
            <div class="label-text" style="margin-left: 23px;">Team Photo:</div>
            <app-image-handler [content]="customer" (imageObject)="setTeamPhoto($event)" [imageable_type]="'team_photo'" [config]="teamPhotoConfig"></app-image-handler>
          </div>

          <div>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24"  nzRequired nzFor="email">Team Name</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your Team name!">
                <input nz-input formControlName="team_name" id="email" [(ngModel)]="customer.team_name" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber" nzRequired>Team Phone</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24"
                               [nzValidateStatus]="validateForm.controls['phoneNumber']"
                               nzErrorTip="Please input your Team phone!">
                <input nz-input formControlName="team_phone" id="phone"  [(ngModel)]="customer.team_phone"/>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template #teamModalFooter>
      <div nz-row>
        <div nz-col nzSpan="4">
          <button nz-button nzType="default" (click)="teamModalFormCancel()">Cancel</button>
        </div>
        <div nz-col nzSpan="8" nzOffset="12">
          <button nz-button nzType="primary" *ngIf="current == 0" (click)="teamFormSubmit()" [nzLoading]="saving">Save</button>
        </div>
      </div>

    </ng-template>
  </nz-modal>


</div>
