<div class="container">
  <div class="title-header" *ngTemplateOutlet="MarketContent"></div>
  <ng-container *ngTemplateOutlet="listin"></ng-container>
  <div class="empty-listings" *ngIf="!search && marketreports && !marketreports.length && !loading">
    <img src="../../../assets/images/listings-empty.png" />
    <h2 class="create-title mar-zero">Promote your Market Reports with Style!</h2>
    <p class="sub-title">After selecting what you want to make, pick your market report, and customize the content
      before it gets generated for you.
    </p>
    <button nz-button nzType="primary" *ngIf="disableNew" (click)="createMarketReport();">Create New</button>
  </div>
</div>
<ng-template #listin>
<!--  <div class="search" *ngIf="marketreports">-->
<!--    <nz-input-group [nzSuffix]="suffixIconSearch">-->
<!--      <input type="text" nz-input placeholder="input search text" [ngModel]="searchText"-->
<!--        (ngModelChange)='searchModelChanged.next($event)' />-->
<!--    </nz-input-group>-->
<!--    <ng-template #suffixIconSearch>-->
<!--      <i nz-icon nzType="search"></i>-->
<!--    </ng-template>-->
<!--  </div>-->
  <nz-table [nzLoading]="loading" nzNoResult="No Market Report found!" nzTemplateMode>
    <thead (nzSortOrderChange)="sort($event)">
      <tr>
        <th nzShowSort nzColumnKey="region_name" [(nzSortFn)]="mapOfSort.region_name">Region Name</th>
        <th class="text-center wd-160 center">Primary
          <span nz-tooltip nzTooltipTitle="This is the market the will be used for automatic posts" class="float-icon">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </span>
        </th>
        <th nzShowSort nzColumnKey="created_at" [(nzSortFn)]="mapOfSort.created_at">Created At</th>
        <th class="text-center wd-160 center">Create Content</th>
        <th class="text-center wd-60">Edit</th>
        <th class="text-center wd-75">Delete</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let marketReport of marketreports" class="cursor-pointer" (click)="viewDetail(marketReport.id)">
        <td>
          <a>{{marketReport.region_name}} ({{marketReport.location_attributes.zip_code}})</a>
        </td>
        <td class="text-center" (click)="$event.stopPropagation();$event.preventDefault();">
          <ng-template #star>
            <i *ngIf="marketReport.primary" nz-icon nzType="star" nzTheme="fill" style="color: #1890ff;"
              class="hover-item"></i>
            <i *ngIf="!marketReport.primary" nz-icon nzType="star" nzTheme="fill" class="hover-item"></i>
          </ng-template>
          <nz-rate [nzCharacter]="star" [nzCount]="1" [nzDisabled]="marketReport.primary"
            [ngModel]="marketReport.primary ? 1 : 0" (ngModelChange)="updateMarketReport($event, marketReport)">
          </nz-rate>
        </td>
        <td>{{ marketReport.created_at | date : "MM-dd-y"}}</td>
        <td class="text-center">
          <a (click)="$event.stopPropagation()" [routerLink]="'/my_markets/'+marketReport.id+'/create_content'" class="grey-icon">
            <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
          </a>
        </td>
        <td class="text-center grey-icon">
          <div class="cursor-pointer" (click)="editReport(marketReport);$event.stopPropagation()">
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </div>
        </td>
        <td class="text-center grey-icon">
          <div class="cursor-pointer"  (click)="deleteReport(marketReport); $event.stopPropagation();">
            <i nz-icon nzType="delete" nzTheme="outline" ></i>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <div fxLayout="row" fxLayoutAlign="end start" class="create-cont">
    <nz-pagination class="pagination-bottom" *ngIf="marketreports && marketreports.length"
      [nzPageIndex]="ReportsPagination.page" [nzTotal]="ReportsPagination.total" (nzPageIndexChange)="didPage($event)">
    </nz-pagination>
  </div>
</ng-template>
<ng-template #MarketContent>
  <div nz-row class="top-row-padding">
    <div nz-col nzSpan="20"></div>
    <div nz-col nzSpan="4" class="pdb-16 text-end" *ngIf="!search && marketreports && marketreports.length && !loading">
      <button *ngIf="disableNew" nz-button (click)="createMarketReport();" class="create-btn" nzType="primary">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Add Market
      </button>
    </div>
  </div>
</ng-template>
<router-outlet></router-outlet>
