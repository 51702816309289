import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js'
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

import { JwtService } from './jwt.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private isRefreshing = false;
  errorMessages: string[] = [];

  private errorKeyToLabelMap: Record<string, string> = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email Address',
    primary_phone: 'Phone Number',
    'location.zip_code': 'Zip code',
    zip_code: 'Zip Code',
  };

  constructor(
    private jwtService: JwtService,
    private authService: AuthenticationService,
    private router: Router,
    private notification: NzNotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const hideErrorNotifications = req.headers.get('X-Hide-Error-Notifications');

    if (req.url.indexOf(environment.email_api_url) > -1) {
      const headersConfig = {
        'Accept': 'application/json'
      };

      const token = this.jwtService.getEmailToken();
      let headers = req.headers.append('Accept', 'application/json');

      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`;
        headers = headers.append('Authorization', `Bearer ${token}`);
      } else {
        headers = headers.delete('Authorization');
      }
      const request = req.clone({ headers });
      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401 && req.url.indexOf(environment.email_api_url) === -1) {
          return this.handle401Error(req, next);
        } else {
          // this.handleBugSnagError(error);
          return throwError(error);
        }
      }));
    } else if (
      req.url.indexOf('herokuapp.com') !== -1
      || req.url.indexOf('zentap.com') !== -1
      || req.url.indexOf('localhost') !== -1
      || req.url.indexOf('appscrip.co') !== -1
      || req.url.indexOf('dubly.xyz') !== -1
    ) {
      const headersConfig = {
        'Accept': 'application/json'
      };

      const token = this.jwtService.getToken();
      let headers = req.headers.append('Accept', 'application/json');

      if (token) {
        headersConfig['Authorization'] = `${token}`;
        headers = headers.append('Authorization', `${token}`);
      } else {
        headers = headers.delete('Authorization');
      }
      const request = req.clone({ headers });
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorMessages = [];
          if (error && error.status !== 401) {
            if (error?.error && typeof error.error === 'object') {
              this.findErrorMessages(error.error);
            } else {
              this.errorMessages = [error.error];
            }
            let errors = this.errorMessages.join('<br/>');
            this.notification?.remove();
            if (error.url.indexOf('email_sender.json') === -1 && !hideErrorNotifications) {
              this.notification.create(
                'error',
                'Error',
                errors
              );
            }
            const customError = JSON.parse(JSON.stringify(error));
            if (customError.error && customError.error.errors) {
              customError.errorKeys = Object.keys(customError.error.errors);
              customError.error = customError.errorKeys + ' ' + errors;
            } else {
              customError.error = errors;
            }
            return throwError(new HttpErrorResponse(customError));
          } else if (error && error.status === 401) {
            this.authService.logout();
            this.router.navigateByUrl('/login');
            return throwError(error);
          } else {
            return throwError(error);
          }
        })
      );
    } else {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error && error.status !== 401) {
            console.log(error);
            // this.handleBugSnagError(error);
            return throwError(error);
          } else if (error && error.status === 401) {
            this.authService.logout();
            this.router.navigateByUrl('/login');
            return throwError(error);
          } else {
            return throwError(error);
          }
        })
      );
    }
  }

  findErrorMessages(obj: any, parentKey: string = ''): void {
    const processObject = (obj: any, parentKey: string) => {
      if (obj) {
        for (const [key, value] of Object.entries(obj)) {
          const fieldName = parentKey ? `${parentKey}.${key}` : key;
          const label = this.errorKeyToLabelMap[key] || key;

          if (Array.isArray(value)) {
            value.forEach(val => {
              this.errorMessages.push(`${label} ${val}`);
            });
          } else if (typeof value === 'object') {
            processObject(value, fieldName);
          } else {
            this.errorMessages.push(`${label} ${value}`);
          }
        }
      }
    }

    processObject(obj, parentKey);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.emailAuthentication().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.auth_token);
          return next.handle(this.addEmailToken(request, token.auth_token));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addEmailToken(request, jwt));
        }));
    }
  }

  private addEmailToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handleBugSnagError(error) {
    if (environment.production) {
      Bugsnag?.notify(new Error(error));
    }
  }
}
