<ng-template #fbonboarding>
  <div *ngIf="!showPages; else fbpages">
    <div class="content-media">
      <div mat-card-image class="text-center">
        <img src="assets/images/media.png" class="internal-content" />
      </div>

    </div>
    <h1 class="center-text">Welcome!
    </h1>
    <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
<!--      <button nz-button nzType="primary" class="action-btn-primary" (click)="loginWithFacebook()">Connect my Facebook-->
<!--        Page</button>-->
      <button nz-button nzType="primary" class="action-btn-primary" [routerLink]="'/settings/content_posting'" (click)="close()">Connect my Social
        Accounts</button>
    </div>
    <h3 class="center-text">Before you can leverage the full power of Zentap, you'll need to connect your Social Accounts
    </h3>
  </div>
  <ng-template #fbpages>
    <div *ngIf="!isDone">
      <h2 class="center-text">
        Select which Facebook page Zentap should manage
      </h2>
      <div *ngIf="facebookPages && facebookPages.length > 0 && !loading">
        <div nz-row nzType="flex" nzJustify="start">
          <!-- <nz-radio-group [(ngModel)]="selectedPageId"> -->
          <div nz-row nzType="flex" nzJustify="start" class="select-option" *ngFor="let option of facebookPages"
            (click)="selectOption(option)">
            <label class="page-check" nz-checkbox (ngModelChange)="selectOption(option)"
              [(ngModel)]="option.checked"></label>
            <img *ngIf="option.picture && option.picture.data.url" [src]="option.picture.data.url" />
            <div class="option-text">{{option.name}}</div>
            <hr/>
              <div class="insta-font" style="flex-basis: 100%;padding-left: 100px;" *ngIf="option.instagram_business_account && PaidTier">
                <img class="insta-img" src=".././../../assets/icons/instagram.png" alt="" /> {{option.instagram_business_account.name}}
              </div>
          </div>
          <!-- </nz-radio-group> -->
        </div>


        <div nz-row nzJustify="space-between" *ngIf="paging.cursors">
          <div nz-col nzSpan="4">
            <a *ngIf="paging.cursors.before" (click)="previuosPage(paging.cursors.before)"  nz-button nzType="link" nzBlock>{{"<<"}} Previous  </a>
          </div>
          <div nz-col nzSpan="4">
            <a *ngIf="paging.cursors.after" (click)="nextPage(paging.cursors.after)"   nz-button nzType="link" nzBlock>Next >></a>
          </div>
        </div>
        <div nz-row nzJustify="end" class="cta">
          <div nz-col nzSpan="4">
            <button nz-button nzType="primary" (click)="createPage()" [disabled]="!selectedPageId">Done</button>
          </div>
        </div>

      </div>

      <div *ngIf="facebookPages && facebookPages.length == 0 && !loading">
        <span nz-typography>No page found</span>
      </div>
    </div>
  </ng-template>

  <div class="steps-content congrats-container" *ngIf="isDone">
    <div class="congrats">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      <h2>Success</h2>
      <p>Your Facebook page has been connected successfully.</p>
    </div>
    <nz-divider></nz-divider>
    <div class="ok-btn">
      <button nz-button nzType="primary" (click)="close()">Ok</button>
    </div>
  </div>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="fbonboarding"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="fbonboarding"></ng-container>
</ng-template>
