import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { JwtService } from 'src/app/services/jwt/jwt.service';
import { MarketReportsService } from '../../services/market-reports/market-reports.service';


@Injectable()
/**
 * Blank view guard for view access and data preloading.
 */
export class OnboardingGuard  {

  /**
   * Method to determine if we can activate the view based on custom logic.
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private jwtService: JwtService,
    private marketReportsService: MarketReportsService
  ) { }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
    return this.checkCustomer();
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkCustomer();
  }

  checkCustomer(): Observable<boolean> | Promise<boolean> | boolean | any {
    if (!this.authService?.currentCustomerValue || !this.jwtService.getToken()) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return this.authService.refresh().pipe(
      map(async (c) => {
        if (c && !c.onboarded) {
          return true;
        } else if (c) {
          const response = await this.marketReportsService.list().toPromise();
          if (response && response.data && response.data.length > 0) {
            this.router.navigateByUrl('/');
            return false;
          } else {
            // this.router.navigateByUrl('/registration');
            return true;
          }
        } else {
          this.router.navigateByUrl('/login');
          return false;
        }
      }),
      catchError(e => {
        this.router.navigateByUrl('/login');
        return of(false);
      }));
  }
}
