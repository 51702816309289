import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss']
})

export class GoogleConsentAuthComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() {
  }
}
