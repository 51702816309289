<ng-template #contactlistedit>
  <div>
    <h2 class="center-text copy">
      {{editList ? 'Edit' : 'Create'}} List
    </h2>
    <form #contactlistForm="ngForm">
      <div nz-row nzJustify="space-around">
        <div nz-col nzMd="10" nzSm="24" nzXs="24">
          <nz-form-item class="margin-bottom-0">
            <nz-form-label class="text-left" for="name" [nzSm]="24" [nzXs]="24" nzRequired>List Name</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please input list name!">
              <input nz-input maxlength="27" type="text" class="form-control" id="name" [(ngModel)]="list.name"
                name="name" required>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzMd="10" nzSm="24" nzXs="24">
          <nz-form-item class="margin-bottom-0">
            <nz-form-label class="text-left" for="description" [nzSm]="24" [nzXs]="24">Description</nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <input nz-input maxlength="27" type="text" class="form-control" id="description"
                [(ngModel)]="list.description" name="description">
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
    <div class="contact-widget spacing" *ngIf="!loading">
      <!-- <div *ngIf="list.name && list.audience_ids.length === 0" class="error">Please select at least one contact!
      </div> -->
      <app-contacts-selector (selectedChange)="changeSelection($event)" [selectedPage]="page" (onPageChange)="onPageChange($event)" (allSelectedChange)="changeSelectionAll($event)"
        [preSelected]="preSelected"></app-contacts-selector>
    </div>
  </div>
  <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
    <button *ngIf="!editList" [disabled]="!(list.name && list.audience_ids.length > 0 ) || !isAllContactsSelected" nz-button nzType="primary"
      class="action-btn-primary" (click)="createList()">Create</button>
    <button *ngIf="editList" [disabled]="!(list.name && list.audience_ids.length > 0 )" nz-button nzType="primary"
      class="action-btn-primary" (click)="updateList()">Update</button>
  </div>
</ng-template>


<ng-template #contactlistcreate>
  <div class="top-spacing ">
    <nz-steps [nzCurrent]="current" class="content-stepper">
      <nz-step nzTitle="List Details" (click)="gotoStep(0)"></nz-step>
      <nz-step nzTitle="Select Contacts" (click)="gotoStep(1)"></nz-step>
    </nz-steps>
    <div class="steps-content" *ngIf="current === 0">

      <form #contactlistForm="ngForm">
        <div nz-row nzJustify="space-around">
          <div nz-col nzMd="10" nzSm="24" nzXs="24">
            <nz-form-item class="margin-bottom-0">
              <nz-form-label class="text-left" for="name" [nzSm]="24" [nzXs]="24" nzRequired>List Name</nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please input list name!">
                <input nz-input maxlength="27" type="text" class="form-control" id="name" [(ngModel)]="list.name"
                  name="name" required>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzMd="10" nzSm="24" nzXs="24">
            <nz-form-item class="margin-bottom-0">
              <nz-form-label class="text-left" for="description" [nzSm]="24" [nzXs]="24">Description</nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24">
                <input nz-input maxlength="27" type="text" class="form-control" id="description"
                  [(ngModel)]="list.description" name="description">
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>
    <div class="steps-content" *ngIf="current === 1">
      <!-- <div *ngIf="list.name && list.audience_ids.length === 0" class="error">Please select at least one contact!
      </div> -->
      <app-contacts-selector (selectedChange)="changeSelection($event)" [selectedPage]="page" (onPageChange)="onPageChange($event)" (allSelectedChange)="changeSelectionAll($event)"
        [preSelected]="preSelected"></app-contacts-selector>
    </div>
  </div>
  <div nz-row nzJustify="end" *ngIf="current === 0" >
    <button [disabled]="!list.name" nz-button nzSpan="12" class="fl-r" nzType="primary" color="primary"
    *ngIf="current === 0" (click)="delayedNext()">Next</button>
  </div>
  <div class="actions" nz-row nzJustify="space-around" *ngIf="current === 1" >
    <div>
      <button nz-button nzSpan="12" *ngIf="current === 1" class="back-btn" (click)="delayedBack();">
        Back</button>
    </div>
    <div >
      <button nz-button nzSpan="12" class="fl-r" nzType="primary" color="primary"
      (click)="createList()" [disabled]="!(isAllContactsSelected && list.name  || list.name && list.audience_ids.length > 0 )" >Create </button>
    </div>
  </div>
  <div class="spinner-custom" *ngIf="loading">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <!-- <div nz-row nzJustify="space-around" nzAlign="middle" class="actions">
    <button *ngIf="!editList" [disabled]="!(list.name && list.audience_ids.length > 0 )" nz-button nzType="primary"
      class="action-btn-primary" (click)="createList()">Create</button>
    <button *ngIf="editList" [disabled]="!(list.name && list.audience_ids.length > 0 )" nz-button nzType="primary"
      class="action-btn-primary" (click)="updateList()">Update</button>
  </div> -->
</ng-template>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="maincontent"></ng-container>
</nz-spin>

<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="maincontent"></ng-container>
</ng-template>

<ng-template #maincontent>
  <div *ngIf="(list && list.id); else contactlistcreate">
    <ng-container [ngTemplateOutlet]="contactlistedit"></ng-container>
  </div>
</ng-template>