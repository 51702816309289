import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss']
})

export class PdfViewerDialogComponent implements OnInit {
  @Input()
  pdfSrc: any;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor() {}
  ngOnInit() {
    if(this.nzModalData.pdfSrc){
      this.pdfSrc = this.nzModalData.pdfSrc;
    }
  }

}
