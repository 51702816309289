import {Resource} from '../resource/resource';


export class Topic extends Resource {
  id: string;
  title: string;
  category: string;
  thumbnail: string;
  description: string;
  script: string;

}
