import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Caption } from 'src/app/vos/caption/caption';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
@Injectable()

export class SmsTemplatesService extends CustomerResourceService<Caption> {

  public endpoint = 'sms_templates';
  public data_key = 'sms_template';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Caption, http, authService);
  }
  list_all(): Observable<any> {
    return this.http
      .get<DataResponse<any>>(
        `${environment.api_url}/sms_templates.json`,
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }

  renderTemplate(id: any, body): Observable<any> {
    return this.http
      .post<DataResponse<any>>(
        `${environment.api_url}/sms_templates/${id}/render_body`,
        JSON.stringify(body),
        { headers: this.headers },
      )
      .pipe(map(resp => resp));
  }

}
