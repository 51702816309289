<ng-template #fbonboarding>
    <div>
      <h2 class="center-text">
        Select which Linkedin page Zentap should manage
      </h2>
      <div>
        <div nz-row nzType="flex" nzJustify="start">
          <!-- <nz-radio-group [(ngModel)]="selectedPageId"> -->
          <div nz-row nzType="flex" nzJustify="start" class="select-option" *ngFor="let page of linkedPages">
            <label class="page-check" nz-checkbox [(ngModel)]="page.checked" (ngModelChange)="selectOption(page)"></label>
              <div class="option-text">{{page.name}}</div>
            <hr/>
          </div>
        </div>
        <div nz-row nzJustify="end" class="cta">
          <div nz-col nzSpan="4">
            <button nz-button nzType="primary" (click)="connectLinkedinPage()" [disabled]="false">Done</button>
          </div>
        </div>

      </div>
      <div *ngIf="linkedPages && linkedPages.length == 0 && !loading">
        <span nz-typography>No page found</span>
      </div>
    </div>

  <div class="steps-content congrats-container" *ngIf="isDone">
    <div class="congrats">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      <h2>Success</h2>
      <p>Your Facebook page has been connected successfully.</p>
    </div>
    <nz-divider></nz-divider>
<!--    <div class="ok-btn">-->
<!--      <button nz-button nzType="primary" (click)="close()">Ok</button>-->
<!--    </div>-->
  </div>
</ng-template>
<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="fbonboarding"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="fbonboarding"></ng-container>
</ng-template>
