<ng-template #senderformcontainer>
  <form #senderForm="ngForm" class="spacing">
    <span class="domain-label">Sender Identity</span>
    <span class="left-spacing" *ngIf="sender.id && sender.verified && showStatus">
      (Verified)
      <i nz-icon nzType="check-circle" class="verified font-18" nzTheme="fill"></i>
    </span>
    <span class="left-spacing" *ngIf="sender.id && !sender.verified && !loading && showStatus">
      (Failed)
      <i nz-icon class="non-verified font-18" nzType="close-circle" nzTheme="fill"></i>
    </span>
    <button *ngIf="sender.id && !sender.verified && showStatus" nzSize="small" nz-button nzType="default" class="action-btn-primary left-spacing"
      (click)="reSendSenderVerification()">Resend Verification</button>
    <span class="sub-label d-block">
      This is the info that is legally supposed to appear on the bottom of your emails.
    </span>
    <div nz-row nzJustify="center" nzAlign="top">
      <div nz-col nzMd="24" nzSm="24" nzXs="24">
        <p>
          <nz-form-item class="margin-b-0">
            <nz-form-label class="text-left" for="name" [nzSm]="4" [nzXs]="4" nzRequired>Name</nz-form-label>
            <nz-form-control class="sender-form-item" [nzSm]="18" [nzXs]="18">
              <input nz-input type="text" class="form-control" id="name" [(ngModel)]="sender.name" name="name" required>
            </nz-form-control>
          </nz-form-item>
        </p>
      </div>
    </div>
    <div nz-row nzJustify="center" nzAlign="top">
      <div nz-col nzMd="24" nzSm="24" nzXs="24">
        <p>
          <nz-form-item class="margin-b-0">
            <nz-form-label class="text-left" for="email" [nzSm]="4" [nzXs]="4" nzRequired>Email</nz-form-label>
            <nz-form-control class="sender-form-item" [nzSm]="18" [nzXs]="18">
              <input nz-input type="email" class="form-control" id="email" [(ngModel)]="sender.email" name="email"
                required pattern="^[^ ]+@[^ ]+\.[a-z]{2,6}$">
            </nz-form-control>
          </nz-form-item>
        </p>
      </div>
    </div>
    <div nz-row nzJustify="center" nzAlign="top">
      <div nz-col nzMd="24" nzSm="24" nzXs="24">

        <nz-form-item class="margin-b-0">
          <nz-form-label class="text-left" [nzSm]="4" [nzXs]="4" nzRequired>Address</nz-form-label>
          <nz-form-control class="sender-form-item" [nzSm]="18" [nzXs]="18" class="address-fields">
            <div nz-col nzSpan="24">
              <nz-form-item class="margin-b-0">
                <nz-form-control class="sender-form-item" [nzSm]="24" [nzXs]="24">
                  <nz-form-label class="text-left" for="saddress" [nzSm]="24" [nzXs]="24" nzRequired>Street Address
                  </nz-form-label>
                  <input nz-input type="text" class="form-control" id="saddress" [(ngModel)]="sender.address"
                    name="saddress" required maxlength="500">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item class="margin-b-0">
                <nz-form-control class="sender-form-item" [nzSm]="24" [nzXs]="24">
                  <nz-form-label class="text-left" for="address_2" [nzSm]="24" [nzXs]="24">Street Address 2
                  </nz-form-label>
                  <input nz-input type="text" class="form-control" id="address_2" [(ngModel)]="sender.address_2"
                    name="address">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-row nzJustify="space-between">
              <div nz-col nzSpan="11">
                <nz-form-item class="margin-b-0">
                  <nz-form-label class="text-left" for="city" [nzSm]="24" [nzXs]="24" nzRequired>City</nz-form-label>
                  <nz-form-control class="sender-form-item" [nzSm]="24" [nzXs]="24">
                    <input nz-input type="text" class="form-control" id="city" [(ngModel)]="sender.city" name="city"
                      required>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="11">
                <nz-form-item class="margin-b-0">
                  <nz-form-label class="text-left" for="senderstate" [nzSm]="24" [nzXs]="24" nzRequired>State
                  </nz-form-label>
                  <nz-form-control class="sender-form-item" [nzSm]="24" [nzXs]="24">
                    <input nz-input type="text" class="form-control" id="senderstate" [(ngModel)]="sender.state"
                      name="senderstate" required>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="11">
                <nz-form-item class="margin-b-0">
                  <nz-form-label class="text-left" for="zip" [nzSm]="24" [nzXs]="24" nzRequired>Zip</nz-form-label>
                  <nz-form-control class="sender-form-item" [nzSm]="24" [nzXs]="24">
                    <input nz-input type="text" class="form-control" id="zip" [(ngModel)]="sender.zip" name="zip"
                      required>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="11">
                <nz-form-item class="margin-b-0">
                  <nz-form-label class="text-left" for="country" [nzSm]="24" [nzXs]="24" nzRequired>Country
                  </nz-form-label>
                  <nz-form-control class="sender-form-item" [nzSm]="24" [nzXs]="24">
                    <input nz-input type="text" class="form-control" id="country" [(ngModel)]="sender.country"
                      name="country" required>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzJustify="space-around" nzAlign="middle" class="actions"
      *ngIf="(actionButton && actionButton != 'update') ||  !actionButton">
      <button *ngIf="!sender.id" nz-button nzType="primary" class="action-btn-primary" [disabled]="!senderForm.valid"
        (click)="CreateSender(senderForm.valid)">Next</button>
      <!-- <ng-template #updateIdentity>
        <button nz-button nzType="primary" class="action-btn-primary" (click)="next()">Next</button>
      </ng-template> -->
    </div>
    <div nz-row nzJustify="space-around" nzAlign="middle" class="actions"
      *ngIf="actionButton && actionButton == 'update'">
      <button *ngIf="!sender.id; else updateIdentity" nz-button nzType="primary" class="action-btn-primary"
        [disabled]="!senderForm.valid" (click)="CreateSender(senderForm.valid)">Create</button>
      <ng-template #updateIdentity>
        <button nz-button nzType="primary" class="action-btn-primary" [disabled]="!senderForm.valid"
          (click)="updateIdenity(senderForm.valid)">Update</button>
      </ng-template>
    </div>
  </form>
</ng-template>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="senderformcontainer"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="senderformcontainer"></ng-container>
</ng-template>