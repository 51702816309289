<nz-calendar [(ngModel)]="cDate" [nzDisabledDate]="disabledDate" (nzSelectChange)="handleDate($event)">
  <ul *nzDateCell="let date" class="events">
    <ng-container [ngSwitch]="getFormatedDate(date)">
      <ng-container *ngFor="let keyItem of calnderDataKeys">
        <ng-container *ngSwitchCase="keyItem">
          <li *ngFor="let content of calendarData[keyItem]" class="content-item">
            <ng-template #contentTemplate>
              <div>
                <div *ngIf="content.content_attributes.status !== 'rendering'"
                  (click)="showPreview(content.content_attributes)" class="calendar-media">
                  <div mat-card-image *ngIf="content.content_attributes.media_type === 'image'">
                    <img [src]="content.content_attributes.url" [alt]="content.content_attributes.caption"
                      class="internal-content calendar-image" />
                  </div>
                  <video height="200" width="300" class="internal-content"
                    *ngIf="content.content_attributes.media_type === 'video'" autoplay loop [muted]="true" controls>
                    <source [src]="content.content_attributes.url" />
                  </video>
                </div>
                <p class="tb-border"><strong>Status:</strong> {{content.status}}</p>

                <p class="tb-border" *ngIf="content.scheduled_for "><strong>Scheduled at:</strong> {{content.scheduled_for | date : "MM-dd-y h:mm a"}} <i class="cl-iuie" nz-icon nzType="calendar" nzTheme="outline"></i></p>
                <p class="tb-border" *ngIf="content.posted_at"><strong>Posted at:</strong> {{content.posted_at || content.created_at | date : "MM-dd-y h:mm
                  a"}}</p>
                <p class="tb-border" *ngIf="content.caption">Caption: {{content.caption.length > 45 ? content.caption.slice(0, 40)+'.....'
                  :
                  content.caption }}</p>
                <p class="tb-border" *ngIf="!content.caption && (content.content_attributes && !content.content_attributes.caption)">
                  Caption: N/A
                </p>
                <p class="tb-border" *ngIf=" content.content_attributes && content.content_attributes.caption">
                  <strong>Caption:</strong> {{content.content_attributes.caption.length > 45
                  ? content.content_attributes.caption.slice(0, 40)+'.....' :
                  content.content_attributes.caption }}</p>
                <p class="tb-border">
                  <strong>Posted on :</strong>
                  <img *ngIf="content.posted_on && content.posted_on === 'facebook'" class="box-image"
                    src=".././../../assets/icons/facebook.png" alt="" />
                  <img *ngIf="content.posted_on && content.posted_on === 'linkedin'" class="box-image"
                    src=".././../../assets/icons/linkedin.png" alt="" />
                  <img *ngIf="content.posted_on && content.posted_on === 'instagram'" class="box-image"
                    src=".././../../assets/icons/instagram.png" alt="" />
                    <img *ngIf="content.posted_on && content.posted_on === 'google_business'" class="box-image"
                    src=".././../../assets/icons/google.png" alt="" />
                  <img *ngIf="content.posted_on && content.posted_on === 'tiktok'" class="box-image"
                       src=".././../../assets/icons/tiktok.png" alt="" />
                  <span *ngIf="!content.posted_on">NA</span>
                </p>
                <div *ngIf="content.content_id" nz-row nzType="flex" nzJustify="space-between" class="actions">
                  <button nz-button nzType="default" nzDanger (click)="contentDeleted(content)">
                    <i nz-icon nzType="delete"></i>Delete</button>
                </div>
              </div>
            </ng-template>
            <ng-template #titleTemplate>
              <nz-badge nzColor="green" [nzText]="content.display_name || content.content_attributes.display_name"
                *ngIf="content.auto_requested"></nz-badge>
              <nz-badge nzColor="pink" [nzText]="content.display_name || content.content_attributes.display_name"
                *ngIf="!content.auto_requested"></nz-badge>
            </ng-template>
            <button nz-button nzSize="large" nzType="default" nz-popover [nzPopoverTitle]="titleTemplate"
              [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" [ngStyle]="{'background-color': content.auto_requested ? '#52c41a' : '#eb2e95', 'color': content.auto_requested ? '#52c41a' : '#eb2e95' }">
              <img *ngIf="content.posted_on && content.posted_on === 'facebook'" class="platform-image"
                src=".././../../assets/icons/facebook_new.png" alt="" />
              <img *ngIf="content.posted_on && content.posted_on === 'linkedin'" class="platform-image"
                src=".././../../assets/icons/linkedin_new.png" alt="" />
              <img *ngIf="content.posted_on && content.posted_on === 'instagram'" class="platform-image"
                src=".././../../assets/icons/instagram_new.png" alt="" />
              {{content.display_name || content.content_attributes.display_name}}
              <!-- <nz-badge nzColor="green" [nzText]="content.display_name || content.content_attributes.display_name"
                *ngIf="content.auto_requested"></nz-badge>
              <nz-badge nzColor="pink" [nzText]="content.display_name || content.content_attributes.display_name"
                *ngIf="!content.auto_requested"></nz-badge> -->
            </button>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</nz-calendar>
<p class="top-spacing">
  <strong class="red-bullets">
    *
  </strong> Content posted to the calendar is subject to change without prior notice.
</p>
<p>
  <nz-badge nzColor="green" nzText="Post created by us."></nz-badge>
</p>
<p>
  <nz-badge nzColor="pink" nzText="Post created by you."></nz-badge>
</p>
