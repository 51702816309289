import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Insights } from 'src/app/vos/insignts/insights';
import { AuthenticationService } from '../authentication/authentication.service';
import { CustomerResourceService } from '../customer-resource.service';
import { map } from 'rxjs/operators';
import { DataResponse } from '../../models/data-response/data-response';
import { of } from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class InsightsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class InsightsService extends CustomerResourceService<Insights> {
  public endpoint = `insights`;
  public data_key = 'date_presents';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Insights, http, authService);
  }


  public allCustomerInsignts(searchText?, pagination?, sorting?) {
    let url = `${this._uri}customers/${this.customer_id}/insights.json`;
    if (searchText) {
      url = url + `?q[name_eq]=${searchText}`;
    }
    if (pagination) {
      const queryIndex = url.indexOf('?');
      url = queryIndex > -1 ? url + `&page=${pagination.page}` : url + `?page=${pagination.page}`;
    }
    if (sorting) {
      const queryIndex = url.indexOf('?');
      url = queryIndex > -1 ? url + `&q[s]=${sorting.by}+${sorting.order}` : url + `?q[s]=${sorting.by}+${sorting.order}`;
    }
    return this.http.get<DataResponse<Insights>>(
      url
    ).pipe(map((resp) => {
      return resp;
    }));
  }
  public fetchInsightsDates() {
    const customer = this.authService.currentCustomerValue;
    if (customer) {
      const url = `${this._uri}customers/${customer?.id}/${this.endpoint}/date_presets.json`;
      return this.http.get<DataResponse<Insights>>(
        url
      ).pipe(map((resp) => {
        return resp;
      }));
    } else {
      this.authService.logout();
      return of(null);
    }
  }
  public fetchRoiInsights(timePreset: string) {
    const customer = this.authService.currentCustomerValue;
    let presetParams = '';
    if (timePreset) {
      presetParams = `?time_preset=${timePreset}`;

    }
    const url = `${this._uri}customers/${customer.id}/${this.endpoint}/roi_retrieve.json${presetParams}`;
    return this.http.get<DataResponse<Insights>>(
      url
    ).pipe(map((resp) => {
      return resp;
    }));
  }

  public fetchRoiReport(since?: string, untilDate?: string) {
    const customer = this.authService.currentCustomerValue;
    let presetParams = '';
    if (since && untilDate) {
      presetParams = `?since=${since}&until_date=${untilDate}`;
    }
    const url = `${this._uri}customers/${customer.id}/${this.endpoint}/notification_roi_retrieve.json${presetParams}`;
    return this.http.get<DataResponse<Insights>>(
      url
    ).pipe(map((resp) => {
      return resp;
    }));
  }


  public fetchFacebookInsights(timePreset: string) {
    const customer = this.authService.currentCustomerValue;
    let presetParams = '';
    if (timePreset) {
      presetParams = `?time_preset=${timePreset}`;

    }
    const url = `${this._uri}customers/${customer.id}/${this.endpoint}/facebook_retrieve.json${presetParams}`;
    return this.http.get<DataResponse<Insights>>(
      url
    ).pipe(map((resp) => {
      return resp;
    }));
  }


}
