import { Component, OnInit, Input, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { DownloaderService } from 'src/app/services/downloader.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-content-receipt-dialog',
  templateUrl: './content-receipt-dialog.component.html',
  styleUrls: ['./content-receipt-dialog.component.scss']
})

export class ContentReceiptDialogComponent implements OnInit {
  @Input()
  config: any;

  receipt;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  constructor(
    private globalsService: GlobalsService,
    private modal: NzModalRef,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private router: Router,
  ) { }
  ngOnInit() {
    if(this.nzModalData.config){
      this.config = this.nzModalData.config;
    }
    this.receipt = this.globalsService.getReceipt()[this.config.type] || this.globalsService.getReceipt()['content'];
  }

  openLink() {
    this.router.navigateByUrl(this.config.link);
    this.modal.close('true');
  }
  close() {
    if ( this.config.overridelink ) {
      this.router.navigateByUrl('/');
    }
    this.modal.close();
  }
}
