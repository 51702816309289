import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-magic-login-link',
  templateUrl: './magic-login-link.component.html',
  styleUrls: ['./magic-login-link.component.scss'],
  animations: [
    trigger('flyInOut', [
      // state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s')
      ]),
      transition(':leave',
        animate('0.5s', style({ opacity: 0 })))
    ])
  ]
})
/**
 * Blank view.
 */
export class MagicLoginLinkComponent implements OnInit {
  code: string;
  errorMessage: string;
  loading = true;
  invalidLink = false;


  /**
   * Component constructor and DI injection point.
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    const d = { ...this.route.snapshot.data, ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
    if (d.user_logout) {
      this.authService.logout();
    } else {
      this.code = d.code;
      if (this.code) {
        this.validateLink();
      } else {
        this.invalidLink = true;
        this.loading = false;
      }
    }

  }


  validateLink() {
    this.loading = true;
    this.authService.validateMagicLink(this.code).subscribe(response => {
      this.goto('/')
      this.loading = false;
    },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.invalidLink = true;
        this.errorMessage = err.error.message;
      });
  }

  goto(url) {
    this.router.navigateByUrl(url);
  }
}
