<div>
  <div class="container" nz-row nzType="flex" nzJustify="start">
    <div class="market-reports-details " nz-row nzType="flex" nzJustify="space-around">
      <div class="report" nz-row nzType="flex" nzJustify="start">
        <div class="report-title"> {{ marketreport.region_name }} ({{marketreport.location_attributes.zip_code}})</div>
        <i *ngIf="marketreport.primary" nz-icon nzType="star" nzTheme="fill"  style="color: #1890ff;font-size: 23px;padding: 2px;" class="hover-item"></i>
        <div class="edit-container">
          <div class="edit-icon" (click)="updateMarketReport()">
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </div>
        </div>
        <div class="delete-container" (click)="deleteReport()">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </div>
      </div>
    </div>
  </div>
  <div nz-row class="title-header-btn" *ngIf="contents && contents.length">
    <button nz-button nzType="primary" (click)="makeContent()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Make Content</button>
  </div>
  <div nz-row class="title-header">
    <h2 class="title">
      This Market Report's Content
    </h2>
  </div>

  <nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
    <ng-container *ngTemplateOutlet="contentListing"></ng-container>
  </nz-spin>
  <ng-template #notloading>
    <ng-container *ngTemplateOutlet="contentListing"></ng-container>
  </ng-template>

</div>

<ng-template #contentListing>
  <app-content-table [hideCols]="[]" class="content-table" [contents]="contents" [contentCreatePath]="makeContentPath"
                     (deleted)="fetchContent()" contentType="market-report"></app-content-table>
  <div nz-row nzType="flex" nzJustify="end" class="spacing" *ngIf="contents && contents.length">
    <nz-pagination nzShowSizeChanger [nzPageSizeOptions]="pageSizeOptions" [nzPageSize]="contentPagination.limit"
                   [nzPageIndex]="contentPagination.page"
                   [nzTotal]="contentPagination.total" (nzPageIndexChange)="didPage($event)">
    </nz-pagination>
  </div>
  <router-outlet></router-outlet>
</ng-template>
