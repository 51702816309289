import { Component, OnInit } from '@angular/core';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {

  constructor(
    private breadcrumService: BreadCrumService,
  ) { }

  ngOnInit() {
    this.breadcrumService.set_breadcrum();
  }
}
