import moment from 'moment';
import { Resource } from '../resource/resource';
import { Content } from '../content/content';
import {Post} from '../posts/posts';
import {Campaign} from '../campaign/campaign';

export class Ad extends Resource {
  // public static categories: { [key: string]: { label: string, mime_type: string }} = {

  // }
  /**
   * id
   */
  id?: number;

  ad_type?: string;
  ad_spend?: number;
  ad_id: number;
  ad_set_id: number;
  post_attributes: Post;
  content: Content;
  campaign_attributes: Campaign;
  /**
   * created_at
   */
  created_at?: moment.Moment;
  /**
   * updated_at
   */
  updated_at?: moment.Moment;

  status: string;
}
