import { Component, OnInit, Input } from '@angular/core';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-bread-crum',
  templateUrl: './bread-crum.component.html',
  styleUrls: [ './bread-crum.component.scss' ]
})

export class BreadCrumComponent implements OnInit {
  @Input() breadcrums: BreadCrum[];
  queryParams;
  constructor(private router: Router, private route: ActivatedRoute, private breadCrumService: BreadCrumService) {}
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
  })
  }

  get breadcrumbs(): any {
    return this.breadCrumService?.breadcrumsList?.filter(br => br);
  }
}

export class BreadCrum {
  name: string;
  url?: string;
  icon?: string;
  tittleClass?: string;
  iconClass?: string;
}
