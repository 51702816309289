import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Lead } from 'src/app/vos/lead/lead';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DataResponse } from 'src/app/models/data-response/data-response';
import { map } from 'rxjs/operators';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class AudiencesServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class AudiencesService extends CustomerResourceService<Lead> {

  public endpoint = 'audiences';
  public data_key = 'audience';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Lead, http, authService);
  }

  exportAudiences(): Observable<any> {
    return this.http.get(
      `${this._customerURI}${this.customerEndpoint}.csv`,
      { responseType: 'text' }
    );
  }
  deleteContacts(payload): Observable<any> {
    return this.http.post(
      `${this._customerURI}${this.customerEndpoint}/destroy_mutiple`,
      payload,
    );
  }

}
