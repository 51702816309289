import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Contact } from 'src/app/vos/contact/contact';
import { ContactsService } from 'src/app/services/contacts/contacts.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { ContactsUploaderComponent } from '../contacts-uploader/contacts-uploader.component';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { ContentsService } from 'src/app/services/contents/contents.service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import {ConversationsService} from '../../services/conversations/conversations.service';
import {Router, RouterModule} from '@angular/router';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ProductsService} from '../../services/products/product-api.service';

@Component({
  selector: 'app-audience-selector',
  templateUrl: './audience-selector.component.html',
  styleUrls: ['./audience-selector.component.scss']
})

export class AudienceSelectorComponent implements OnInit {
  selection = [];
  isAllDisplayDataChecked = false;
  isOperating = false;
  isIndeterminate = false;
  listOfDisplayData: Contact[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  contactsDataSource = [];
  numberOfChecked = 0;
  loading = false;
  paginatedContacts = [];
  contactList = [];
  searchText: string;
  selectedIds = [];
  selectAudiencesIds = [];
  page = 1;
  isAllContactsSelected = false;
  pageInfo = { total_entries: 0, per_page: 10, current_page: 1 };
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  @Input() audiences: any;
  @Input() filter: any;
  @Input() isCampaign: Boolean;
  @Input()
  set selected(val: number[]) {
    this.selection = val;
    val.forEach(item => {
      this.mapOfCheckedId[item] = true;
    });
  }

  get selected(): number[] {
    this.selection = this.contacts ? this.contacts?.filter(item => this.mapOfCheckedId[item.id]).map(c => c.id) : [];
    return this.selection;
  }

  @Output()
  selectedChange = new EventEmitter();
  @Output()
  allSelectedChange = new EventEmitter();
  @Input()
  contacts: Contact[];
  showingAdd = false;
  columns = ['first_name',
    'last_name',
    'phone',
    'email',
    'company',
    'address',
    'created_at'
  ];

  mapOfSort: { [key: string]: string | null } = {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    company: null,
    address: null,
    created_at: null,
  };
  public sorted: { key: string; value: string } = { key: 'created_at', value: 'descend' };
  audienceFiltersAvailable = ['Contact', 'Lead'];
  audienceFilters = [];
  audiencesDataSource = [];
  paginatedLeads = [];
  // filter = 'Contact';
  campaignProductAvailable = false;
  get displayedColumns(): string[] {
    return ['select', ...this.columns];
  }
  listOfSelection = [
    {
      text: 'Select All',
      onSelect: () => {
        this.isAllContactsSelected = true;
        this.isAllDisplayDataChecked = true;
        this.mapOfCheckedId = {};
        this.refreshStatus();
        this.allSelectedChange.emit(true);
      }
    },
    {
      text: 'None',
      onSelect: () => {
        this.isAllContactsSelected = false;
        this.isAllDisplayDataChecked = false;
        this.mapOfCheckedId = {};
        this.refreshStatus();
        this.allSelectedChange.emit(false);
      }
    }
  ];

  constructor(private audienceService: AudiencesService,
              private contactService: ContactsService,
              private message: NzMessageService,
              private leadService: LeadsService,
              private conversationsService: ConversationsService,
              private router: Router,
              private authService: AuthenticationService,
              private productsService: ProductsService,
              private modalService: NzModalService) { }

  ngOnInit() {
    this.getAudiences();
    if(this.audiences){
      this.selectAudiencesIds = this.audiences.map(audience => audience.id);
    }
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        if (newText) {
          this.getAudiences(`&q[email_or_name_or_phone_or_first_name_or_last_name_matches]=${newText}`);
        } else {
          this.getAudiences();
        }
      });
  }

  getAudiences(searchQuery?) {
    this.loading = true;
    const pageParams = `?page=${this.page}&q[s]=created_at desc`;
    let filterParams = '';
    if (this.filter) {
      filterParams = `&q[type_eq]=${this.filter}`;
    }
    if (!searchQuery){
      searchQuery = '';
    }
    const queryParams = `${pageParams}${searchQuery}${filterParams}`;
    this.audienceService.list(queryParams).subscribe((c: any) => {
      if (c) {
        this.contactsDataSource = c.data;
        this.paginatedContacts = c.data;
        this.pageInfo = { total_entries: c.total_entries, per_page: c.per_page, current_page: c.current_page };
        this.loading = false;
        this.refreshStatus();
      }
    });
  }

  didPage(page: number) {
    this.page = page;
    this.getAudiences();
  }

  appendContacts(c: Contact | Contact[]) {
    this.getAudiences();
  }

  addContacts() {
    const modal = this.modalService.create({
      nzContent: ContactsUploaderComponent,
      nzFooter: null,
      nzWidth: '70%'
    });
    modal.afterClose.subscribe(r => {
      if (r) {
        this.appendContacts(r);
      }
    });
  }
  campaignAvailable(){
    const params: Record<string, any> = {
      per_page: 40,
    };
    this.productsService.productList(
      `customers/${this.authService.currentCustomerValue?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const products = res.data;
        const campaignProduct = products ? products?.find(p => p.abbreviation === 'SC' && p.locked === false) : null;
        if (campaignProduct) {
          this.campaignProductAvailable = true;
        } else{
          this.campaignProductAvailable = false;
        }
      }
    });
  }
  currentPageDataChange($event: Contact[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  onCheckboxChange(id: number, checked: boolean): void {
    if (checked) {
      this.selectAudiencesIds.push(id);
    } else {
      this.selectAudiencesIds = this.selectAudiencesIds.filter(audienceId => audienceId !== id);
    }

    this.refreshStatus();
  }

  editContact(contact: any){
    const modal = this.modalService.create({
      nzContent: ContactsUploaderComponent,
      nzData: {
        contact: contact,
        isEdit: true
      },
      nzFooter: null,
      nzWidth: '70%'
    });
    modal.afterClose.subscribe(r => {
      if (r) {
        this.appendContacts(r);
      }
    });
  }


  refreshStatus(): void {
    if (this.audiences) {
      this.selectAudiencesIds.forEach(audienceId => {
        if (this.contactsDataSource.some(item => item.id === audienceId)) {
          this.mapOfCheckedId[audienceId] = true;
        }
      });
    }

    this.isAllDisplayDataChecked = this.isAllContactsSelected || this.contactsDataSource.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate = this.contactsDataSource.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;

    let selectedKeys = Object.keys(this.mapOfCheckedId);
    selectedKeys.forEach(k => {
      if (!this.mapOfCheckedId[k]) {
        delete this.mapOfCheckedId[k];
      }
    });

    selectedKeys = Object.keys(this.mapOfCheckedId);
    this.numberOfChecked = selectedKeys.length;

    const selection = this.contactsDataSource
      .filter(item => this.mapOfCheckedId[item.id])
      .map(c => c.id);

    this.selection = selectedKeys.map(id => parseInt(id, 10));
    this.selectedChange.emit(this.selection);
  }



  setPageData() {
    const page = this.page - 1;
    this.paginatedContacts = this.contactsDataSource.slice(page * 10, (page + 1) * 10);
  }

  checkAll(value: boolean): void {
    this.contactsDataSource.forEach(item => {
      if (!value && this.mapOfCheckedId[item.id]) {
        delete this.mapOfCheckedId[item.id];
      } else {
        this.mapOfCheckedId[item.id] = value;
      }
    });
    this.refreshStatus();
  }

  deleteContacts(contact?) {
    let ids = this.selection || [];
    if (contact) {
      ids = [contact.id];
    }
    if (this.isAllDisplayDataChecked) {
      ids = [];
      this.contactsDataSource.forEach(item => {
        ids.push(item.id);
      });
    }
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete contacts?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.audienceService.deleteContacts({ audience_ids: ids }).subscribe(r => {
          this.message?.create('success', `Contacts has been successfully deleted.`);
          this.mapOfCheckedId = {};
          this.isAllDisplayDataChecked = false;
          this.refreshStatus();
          this.getAudiences();
        }, e => {
          this.loading = false;
          this.message?.remove();
          this.message?.create('error', 'Error deleting the contact. Please try again');
        });
      }
    });
  }
  export() {
    this.audienceService.exportAudiences().subscribe(
      res => {
        const options = { type: `text/csv;charset=utf-8;` };
        this.downloadCsv(res, options, 'audience.csv');
      }
    );
  }

  downloadCsv(body, options, filename) {
    const blob = new Blob([body], options);
    const _navi = navigator as any
    if (_navi.msSaveBlob) {
      // IE 10+
      _navi.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document?.body.appendChild(link);
        link.click();
        document?.body.removeChild(link);
      }
    }
  }

  operateData(): void {
    this.isOperating = true;
    setTimeout(() => {
      this.contacts.forEach(item => (this.mapOfCheckedId[item.id] = false));
      this.refreshStatus();
      this.isOperating = false;
    }, 1000);
  }
  toggleFilter(checked: boolean, filter: string): void {
    this.page = 1;
    if (checked) {
      this.audienceFilters.push(filter);
    } else {
      this.audienceFilters = this.audienceFilters?.filter(f => f.indexOf(filter) === -1);
    }
    this.getFilters(filter);
    this.getAudiences();
  }
  getFilters(filter) {
    // this.audienceFilters.filter(f => {  return f.indexOf(filter) === -1;})
    if (this.audienceFilters.length === 2 || this.audienceFilters.length === 0) {
      this.filter = '';
    }
    if (this.audienceFilters.length === 1) {
      this.filter = filter;
    }

  }
  createConversation(lead){
    if (lead && lead.ghl_id){
      this.router.navigate(['/conversations'], { queryParams: { contact: lead.ghl_id } });
    }else{
      this.conversationsService.createConversation(lead.id).subscribe(resposne => {
        this.router.navigate(['/conversations'], { queryParams: { contact: resposne.body.ghl_id    } });
      });
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sorted = sort;
    this.mapSortings();
  }
  mapSortings() {
    for (const key in this.mapOfSort) {
      this.mapOfSort[key] = key === this.sorted.key ? this.sorted?.value : null;
    }
    const valuesMap = {
      descend: 'desc',
      ascend: 'asc'
    };
    if (this.sorted.key) {
      this.getAudiences(`&q[s]=${this.sorted.key} ${valuesMap[this.sorted?.value]}`);
    } else {
      this.getAudiences();
    }
  }
}
