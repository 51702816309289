import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Testimony } from 'src/app/vos/testimony/testimony';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import {DataResponse} from '../../models/data-response/data-response';
import {Customer} from '../../vos/customer/customer';
import {map} from 'rxjs/operators';
import {Resource} from '../../vos/resource/resource';
import {Conversation} from '../../vos/conversation/conversation';

@Injectable()

export class ConversationsService extends CustomerResourceService<Conversation> {
  public endpoint = 'conversations';
  public data_key = '';
  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Conversation, http, authService);
  }
  list_messages(id: string): Observable<any> {
    const url = `${this._uri}/conversations/${id}/messages`;
    return this.http.get<Conversation[]>(url).pipe(
      map(resp => {
          return resp;
        }
      ));
  }
  mark_read(id: string): Observable<any> {
    const url = `${this._uri}/conversations/${id}/mark_read`;
    return this.http.get<Conversation[]>(url).pipe(
      map(resp => {
          return resp;
        }
      ));
  }
  send_messages(message: string, contactId: string): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/conversations/send_message`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          { message, contact_id: contactId}
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
  createConversation(leadId: any): Observable<any> {
    const url = `${this._customerURI}${this.authService.currentCustomerValue?.id}/conversations`;
    return this.http
      .post<DataResponse<Customer>>(
        url,
        JSON.stringify(
          { lead_id: leadId}
        ),
        { headers: this.headers, observe: 'response' }
      );
  }
}
