import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Template } from 'src/app/vos/template/template';
import { AuthenticationService } from '../authentication/authentication.service';
import { CustomerResourceService } from '../customer-resource.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DataResponse } from '../../models/data-response/data-response';
import { environment } from 'src/environments/environment';
import { JwtService } from '../jwt/jwt.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class TemplatesServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class TemplatesService extends CustomerResourceService<Template> {
  public endpoint = `templates`;
  public data_key = 'template';
  private httpWithoutInterceptor: HttpClient;
  constructor(http: HttpClient, authService: AuthenticationService, private jwtService: JwtService,
    private httpBackend: HttpBackend) {
    super(Template, http, authService);
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }
  public customerData = this.jwtService.getSavedCustomer();

  previewURLFor(template: Template, resource_id: number): string {
    return `${this._uri}templates/${template.id}/preview?templateable_id=${resource_id}&customer_id=${this.customer_id}`;
  }
  public allTemplates(selectedType?, templateType?, searchText?, pagination?, sorting?) {
    let url = `${this._uri}templates.json?q[status_eq]=1`;
    if (selectedType || selectedType === 0) {
      url = url + `&q[templateable_type_eq]=${selectedType}`;
    }
    if (templateType) {
      const queryIndex = url.indexOf('?');
      // url = url + `?q[template_type_eq]=${templateType}`;
      url = queryIndex > -1 ? url + `&q[template_type_eq]=${templateType}` : url + `?q[template_type_eq]=${templateType}`;
    }

    if (pagination) {
      const queryIndex = url.indexOf('?');
      url = queryIndex > -1 ? url + `&page=${pagination.page}` : url + `?page=${pagination.page}`;
    }
    if (sorting) {
      const queryIndex = url.indexOf('?');
      url = queryIndex > -1 ? url + `&q[s]=${sorting.by}+${sorting.order}` : url + `?q[s]=${sorting.by}+${sorting.order}`;
    }
    return this.http.get<DataResponse<Template[]>>(
      url
    ).pipe(map((resp) => {
      resp.data = resp.data?.filter(t=>t['alias'] !== 'mortgage-lender');
      return resp;
    }));
  }

  public allCustomerTemplates(searchText?, pagination?, sorting?) {
    let url = `${this._uri}customers/${this.customer_id}/templates.json`;

    if (searchText) {
      url = url + `?q[name_eq]=${searchText}`;
    }
    if (pagination) {
      const queryIndex = url.indexOf('?');
      url = queryIndex > -1 ? url + `&page=${pagination.page}` : url + `?page=${pagination.page}`;
    }
    if (sorting) {
      const queryIndex = url.indexOf('?');
      url = queryIndex > -1 ? url + `&q[s]=${sorting.by}+${sorting.order}` : url + `?q[s]=${sorting.by}+${sorting.order}`;
    }
    return this.http.get<DataResponse<Template[]>>(
      url
    ).pipe(map((resp) => {
      resp.data = resp.data?.filter(t=>t['alias'] !== 'mortgage-lender');
      return resp;
    }));
  }

  public updateTemplate(template: Template) {
    return this.http
      .put(`${this._uri}templates/${template.id}.json`, JSON.stringify({
        template
      }),
      )
      .pipe(
        map(_ => true),
        catchError(_ => {
          return of(false);
        })
      );
  }

  public createTemplate(template: Template) {
    return this.http
      .post(`${this._uri}templates.json`, JSON.stringify({
        template
      }),
      )
      .pipe(
        map(_ => true),
        catchError(_ => {
          return of(false);
        })
      );
  }
  public blast(template: number, contacts: number[], resource: number, select_all?: boolean): Observable<any> {
    return this.http
      .post(`${this._uri}${this.customerEndpointFor('templates')}/${template}/blast`, JSON.stringify({
        contact_ids: contacts,
        templateable_id: resource,
        select_all: select_all || false
      }),
      )
      .pipe(
        map(_ => true),
        catchError(_ => {
          return of(false);
        })
      );
  }

  public getPublicUpTemplates(queryParams?) {
    // let url = 'https://accounts.uproperties.us/api/v1/templates';
    let url = `${this._uri}websites/templates.json`
    if (queryParams) {
      url = `${url}?${queryParams}`;
    }
    return this.http.get<any[]>(
      url
    ).pipe(map((resp) => {
      resp = resp?.filter(t=>t.alias !== 'mortgage-lender');
      return resp;
    }));
  }

  // get dynamic data for email template
  getDynamicDataFor() {
    const URL = `${environment.api_url}customers/${this.customerData.id}.json`;
    return this.http.get(URL,
    ).pipe(map((resp) => {
      return resp;
    }));
  }
  getTemplateDynamicData(templateNo) {
    let URL = '';
    const url = `${environment.api_url}market_reports/search.json?zip=90210&`;
    templateNo === 1 ? URL = `${url}indicators=["medianNewListPrice","medianSoldPrice","avgDom","soldCount",
    "newListCount","avgListPerSqft”]`
      : URL = `${url}indicators=["medianNewListPrice","medianSoldPrice","avgDom",
    "soldCount","newListCount","leastExpensive","mostExpensive","monthsOfSupply]` ;
    return this.http.get<any[]>(URL).pipe(
      map(resp => {
        return resp;
      }
      ));
  }

  // get cusomer data and their repsective lising data

  getCustomerListingData() {
    const URL = `${environment.api_url}customers/${this.customerData.id}/listings.json`;
    return this.http.get<any[]>(URL).pipe(
      map(resp => {
        return resp;
      }
      ));
  }

  getListingdDataBasedOnLisitngId(listingId) {
    const URL = `${environment.api_url}listings/${listingId}.json`;
    return this.http.get<any[]>(URL).pipe(
      map(resp => {
        return resp;
      }
      ));
  }
}
