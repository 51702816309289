import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from './services/authentication/authentication.service';
import { Customer } from './vos/customer/customer';
declare global {
  interface String {
    capitalize(): string;
    capitalizeAll(): string;
    titleize(): string;
    regEscape(): string;
  }
  interface Number {
    niceString(): string;
    asDuration(): string;
  }

}

String.prototype.regEscape = function (): string {
  return this.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};
String.prototype.capitalize = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
String.prototype.capitalizeAll = function (): string {
  return this.split(' ')
    .map(s => s.capitalize())
    .join(' ');
};
String.prototype.titleize = function (): string {
  return this
    .split('_')
    .join(' ')
    .capitalizeAll();
};
Number.prototype.niceString = function (): string {
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

declare const identifyUser;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

/**
 * Main App Component.
 */
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Universal Promote';
  customer: Customer;
  constructor(
    // iconRegistry: MatIconRegistry,
    router: Router,
    sanitizer: DomSanitizer,
    updates: SwUpdate,
    private authService: AuthenticationService,
  ) {
    // iconRegistry.addSvgIcon(
    //   'uplogo',
    //   sanitizer.bypassSecurityTrustResourceUrl('assets/logos/up-logo.svg')
    // );
    // updates.versionUpdates.subscribe(event => {
    //   updates.activateUpdate().then(() => document.location.reload());
    // });
    if (updates.isEnabled) {
      updates.checkForUpdate();
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate());
    }
  }
  /**
   * Called part of the component lifecycle. Best first
   * place to start adding your code.
   */
  ngOnInit(): void {
    this.checkActive();
  }
  ngAfterViewInit(): void {
    this.authService.currentCustomer.subscribe(customer => {
      if (customer) {
        this.customer = customer;
      }
      // setTimeout(() => {
      //   this.setIframeStyling();
      // }, 8000);
    });
  }


  checkActive() {
    let lastMove = Date.now();

    document.onmousemove = function () {
      lastMove = Date.now();
    }

    setInterval(() => {
      let diff = Date.now() - lastMove;
      if (diff > (3600000 * 6)) {
        window.location.reload();
      }
    }, 1000);
  }

  setIframeStyling() {
    let myiFrame = document.getElementsByTagName("iframe");
    for (var i = 0; i < myiFrame.length; i++) {
      let doc = myiFrame[i].contentDocument;
      if (doc?.body?.innerHTML) {
        doc.body.innerHTML = doc?.body.innerHTML + '<style>.beacon { box-shadow: none !important;}</style>';
      }
    };
  }

}
