import { Component, OnInit, Input } from '@angular/core';
import { CampaignService } from 'src/app/services/campaigns/campaign.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SmsCampaign } from 'src/app/vos/sms-campaign/sms-campaign';
import {BreadCrumService} from '../../../services/breadcrum/bread-crum.service';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateCampaignComponent implements OnInit {
  current = 0;
  campaign: SmsCampaign;
  leads: any[] = [];
  checked = false;
  isResponse = true;
  payload: any;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  audienceIds = [];
  audiences = [];
  campaignPayload: any;
  allLeads = [];
  isNew = true;
  selectedFile: any;
  @Input()  model: any = { lead: [] }
  constructor(
    private campaignService: CampaignService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumService: BreadCrumService,
    ) { }

  ngOnInit(): void {
    const campaignId = this.route.snapshot.paramMap.get('id');
    if (campaignId) {
      this.campaignService.get_sms_campaign(campaignId).subscribe(res => {
        this.campaign = res;
        this.audiences = res.audiences;
        this.isNew = false;
      });
    }

    this.breadcrumService.set_breadcrum();
  }

  gotoStep(stepNumber: any) {
    if (this.current > stepNumber) {
      this.current = stepNumber;
    }
  }

  next() {
    this.current += 1;
  }

  createSmsCampaign() {
    if(this.campaignPayload){
      this.campaignPayload.audience_ids = this.audienceIds;
      if(this.isNew)
        this.campaignService.create_sms_campaign(this.campaignPayload).subscribe(res => {
          this.router.navigateByUrl('/campaigns');
        });
      else{
        const campaignId = this.route.snapshot.paramMap.get('id');
        this.campaignService.update(this.campaignPayload, campaignId).subscribe(res => {
          this.router.navigateByUrl('/campaigns');
        });
      }
    }
  }

  enableButton(value: boolean){
    this.isResponse = value;
  }

  handleResponse(response: any){
    this.campaignPayload = response;
  }

  getSelectedAudiences(audiences: any) {
    this.audienceIds = audiences;
  }

}
