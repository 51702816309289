<div class="container">
  <ng-container *ngTemplateOutlet="Listings"></ng-container>
</div>
<ng-template #Listings>
  <div class="contact-actions">
    <span *ngIf="numberOfChecked">Selected {{ numberOfChecked }} lists</span>
  </div>
  <div nz-row>
    <div class="search">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text" nz-input placeholder="input search text" [ngModel]="searchText"
          (ngModelChange)='searchModelChanged.next($event)' />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </div>
    <div class="padding-top right-button">
      <button nz-button (click)="addList()" class="create-btn" nzType="default">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Add List
      </button>
    </div>
  </div>
  <nz-table nzNoResult="" nzTemplateMode>
    <thead>
      <tr>
        <th [nzSelections]="listOfSelection" nzShowCheckbox nzShowRowSelection [nzSelections]="listOfSelection"
          [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
          (nzCheckedChange)="checkAll($event)" [nzDisabled]="isAllListingsSelected"></th>
        <th nzColumnKey="name">List Name</th>
        <th class="text-center" nzColumnKey="population">Count</th>
        <th class="text-center wd-130">Edit</th>
        <th class="text-center wd-130">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let listing of audienceListings">
        <td class="check-box" nzShowCheckbox [(nzChecked)]="mapOfCheckedId[listing.id]" *ngIf="!isAllListingsSelected"
          (nzCheckedChange)="refreshStatus()"></td>
        <td class="check-box" nzShowCheckbox [(nzChecked)]="isAllListingsSelected" (nzCheckedChange)="refreshStatus()"
          *ngIf="isAllListingsSelected" [nzDisabled]="isAllListingsSelected"></td>
        <td>{{listing.name}}</td>
        <td class="text-center">
          {{ listing.count}}
        </td>
        <td class="text-center">
          <i (click)="editList(listing)" nz-icon nzType="edit" class="edit-contact" nzTheme="fill"></i>
        </td>
        <td class="text-center">
          <i (click)="deleteList(listing)" nz-icon nzType="delete" class="delete-contact" nzTheme="fill"></i>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <div fxLayout="row" fxLayoutAlign="end start" class="mg-t-30">
    <nz-pagination nzShowSizeChanger [nzPageSizeOptions]="pageSizeOptions" class="pagination-right"
      [nzPageSize]="listingPagination.limit" [nzPageIndex]="listingPagination.page" [nzTotal]="listingPagination.total"
      (nzPageIndexChange)="didPage($event)">
    </nz-pagination>
  </div>
</ng-template>