import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('flyInOut', [
      // state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s')
      ]),
      transition(':leave',
        animate('0.5s', style({ opacity: 0 })))
    ])
  ]
})
/**
 * Blank view.
 */
export class LoginComponent implements OnInit {
  authForm: UntypedFormGroup;
  code: string;
  errorMessage: string;
  emailValid = true;
  emailExists = false;
  email: string;
  loading = false;
  showSignupLink = false;
  showConfirmationLink = false;
  hide = false;
  resendWait = 0;
  passwordVisible = false;
  returnUrl: string;

  /**
   * Component constructor and DI injection point.
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
    private modalService: NzModalService,
  ) {

  }
  EmailExists: ValidatorFn = (fg: UntypedFormGroup) => {
    const valid = this.emailValid;
    return valid
      ? null
      : { missing: true };
  }
  /**
 * Called part of the component lifecycle. Best first
 * place to start adding your code.
 */
  ngOnInit() {

    this.modalService.closeAll();
    const d = { ...this.route.snapshot.data, ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
    this.emailExists = false;
    this.setFormsWith(d);
    if (d.user_logout) {

      this.authService.logout();
    } else {
      this.code = d.code;
      this.email = d.email;
    }
    this.route.queryParams.subscribe(params => {
      this.email = params['email'] || this.email;
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    // const initParams: InitParams = {
    //   appId: '210551162312621',
    //   xfbml: true,
    //   version: 'v2.8'
    // };
    // this.fbService?.init(initParams);

  }

  setFormsWith({ state: _, ...obj }: any = {}) {
    this.authForm = this.fb.group({
      ...obj,
      email: [this.email, Validators.required],
      password: ['', Validators.required]
    });
  }

  checkEmail() {
    this.loading = true;
    this.errorMessage = null;
    this.checkAuthForm();
    const creds = this.authForm?.value;
    this.email = creds.email;
    this.authService.checkEmail(creds.email).subscribe(valid => {
      this.emailExists = valid;
      this.email = creds.email;
      this.loading = false;
    },
      (err: HttpErrorResponse) => {
        this.loading = false;
        if (err.status === 423) {
          this.emailExists = true;
          this.email = creds.email;
          this.router.navigateByUrl('/confirmation');
        } else {
          this.emailExists = false;
          this.errorMessage = err.error.message;
        }

      });
  }

  checkAuthForm() {
    for (const i in this.authForm.controls) {
      this.authForm.controls[i].markAsDirty();
      this.authForm.controls[i].updateValueAndValidity();
    }
  }

  login() {
    this.loading = true;
    this.errorMessage = null;
    this.checkAuthForm();
    const creds = this.authForm?.value;
    this.hide = true;
    this.authService.login(creds.email, creds.password).subscribe(customer => {
      this.router.navigateByUrl(this.returnUrl);
    },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.hide = false;
        this.errorMessage = err.error;
        if (this.errorMessage === 'Please confirm your email to access your account.') {
          // this.showConfirmationLink = true;
          this.router.navigateByUrl(`confirmation?resend=true&email=${creds.email}`);
        }
      });
  }

  // loginWithFacebook(code?: string): void {
  //   this.loading = true;
  //   this.errorMessage = null;
  //   this.fbService?.getLoginStatus()
  //     .then((status) => {
  //       if (status.authResponse && status.status === 'connected') {
  //         this.handleFBLogin(status.authResponse);
  //       } else {
  //         this.fbService?.login()
  //           .then((response: LoginResponse) => code ? this.handleFBReset(response) : this.handleFBLogin(response.authResponse))
  //           .catch((error: any) => {
  //             this.loading = false;
  //             console.error(error);
  //           });
  //       }
  //     })
  //     .catch((error: any) => {
  //       this.errorMessage = 'Could not sign in with Facebook. An error occurred';
  //       this.loading = false;
  //       console.error(error);
  //     });
  // }

  // handleFBLogin(response: AuthResponse) {
  //   this.loading = true;
  //   this.authService.loginFacebook(response.accessToken, false).subscribe(customer => {

  //     this.loading = false;
  //     this.router.navigateByUrl('/');
  //   },
  //     (err: HttpErrorResponse) => {
  //       this.loading = false;
  //       this.errorMessage = err.error.message;
  //       // switch (err.status) {
  //       //   case 404:
  //       //     this.errorMessage = 'No account found.';
  //       //     break;
  //       //   default:
  //       //     this.errorMessage = 'No account found.';
  //       // }
  //     });
  // }

  // handleFBReset(response: LoginResponse) {
  //   this.loading = true;
  //   this.authService.resetFacebook(response.authResponse.accessToken, this.code).subscribe(
  //     customer => {
  //       this.router.navigateByUrl('/');
  //     },
  //     (err: HttpErrorResponse) => {
  //       this.loading = false;
  //       this.errorMessage = err.error.message;
  //       // switch (err.status) {
  //       //   case 422:
  //       //     this.handleFBLogin(response.authResponse);
  //       //     return;
  //       //   case 404:
  //       //     this.errorMessage = 'No account found. Please <a href="">contact us</a> to signup.';
  //       //     break;
  //       //   default:
  //       //     this.errorMessage = 'Could not login';
  //       // }
  //     });
  // }

  goto(url) {
    this.router.navigateByUrl(url);
  }
}
