<!--<br>-->
<!--&lt;!&ndash;<nz-steps [nzCurrent]="current" >&ndash;&gt;-->
  <!--&lt;!&ndash;<nz-step nzTitle="View"></nz-step>&ndash;&gt;-->
  <!--&lt;!&ndash;<nz-step nzTitle="Edit"></nz-step>&ndash;&gt;-->
  <!--&lt;!&ndash;<nz-step nzTitle="Post"></nz-step>&ndash;&gt;-->
  <!--&lt;!&ndash;<nz-step nzTitle="Render"></nz-step>&ndash;&gt;-->
<!--&lt;!&ndash;</nz-steps>&ndash;&gt;-->
<!--<br>-->


<div *ngIf="current == 0 && content">
  <div class="steps-content">

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="6">
        <h1 class="main-title">Approve your data</h1>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" nzOffset="3">
        <p>Check data for this month, does it look ready to go?</p>
      </div>
    </div>
    <div>
      <div>
        <div mat-card-image *ngIf="content.media_type === 'image'" >
          <img [src]="content.url" [alt]="content.caption" class="internal-content" />
        </div>
        <video class="internal-content" *ngIf="content.media_type === 'video'" controls>
          <source [src]="content.url" />
        </video>
      </div>
    </div>
  </div>
  <br>
  <div nz-row>
    <div nz-col nzSpan="4">
      <button nz-button nzType="default" (click)="deny()">
        <span>Deny</span>
      </button>
    </div>
    <div nz-col nzSpan="4" nzOffset="16">
      <button  class="float-right" nz-button nzType="primary" (click)="next(2)">
        <span>Approve</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="current == 1">
  <div class="steps-content">

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="6">
        <h1 class="main-title">Oh No! Let's fix it</h1>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" nzOffset="3">
          <p>You can click on numbers to manually adjust the data.</p>
      </div>
    </div>

    <nz-table  #basicTable [nzShowPagination]="false" [nzSize]="'small'" [nzData]="dataPoints">
      <thead>
      <tr>
        <th>Category</th>
        <th>{{zipData.PresentMonthYear}}</th>
        <th>{{zipData.LastMonthYear}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ zipData['Title'+data] }}</td>
        <td>
          <div class="editable-cell" *ngIf="editId!=='Data'+data+'A'; else editDataA">
            <div class="editable-cell-value-wrap" (click)="startEdit('Data'+data+'A', $event)">
              {{ zipData['DataDisplay'+data+'A'] }}
            </div>
          </div>
          <ng-template #editDataA>
            <input type="number" nz-input [(ngModel)]="zipData['Data'+data+'A']" (ngModelChange)="formatNumber(data,'A')" />
          </ng-template>
        </td>
        <td>
          <div class="editable-cell" *ngIf="editId!=='Data'+data+'B'; else editDataB">
            <div class="editable-cell-value-wrap" (click)="startEdit('Data'+data+'B', $event)">
              {{ zipData['DataDisplay'+data+'B'] }}
            </div>
          </div>
          <ng-template #editDataB>
            <input type="number" nz-input [(ngModel)]="zipData['Data'+data+'B']" (ngModelChange)="formatNumber(data,'B')" />
          </ng-template>
        </td>
      </tr>
      </tbody>
    </nz-table>

  </div>
  <br>
  <div nz-row>
    <div nz-col nzSpan="4">
      <button nz-button nzType="default" (click)="pre()">
        <span>Back</span>
      </button>
    </div>
    <div nz-col nzSpan="4" nzOffset="16">
      <button  class="float-right" nz-button nzType="primary" (click)="renderContent()">
        <span>Recreate</span>
      </button>
    </div>
  </div>
</div>


<div *ngIf="current == 2">
  <app-social-post-dialog [content]="content" (posted)="nextStepAfterPosting()"></app-social-post-dialog>
</div>

<div *ngIf="current == 3">

  <div class="steps-content">

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="6">
        <h1 class="main-title">Sent for rendering!</h1>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="4">
        <p>Click the 'Done' button to close this window.</p>
      </div>
    </div>
  </div>
  <div class="social-connect-posted">
    <div class="status-container" >
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div nz-row>
    <div nz-col nzSpan="4" nzOffset="10" class="center-close-btn">
      <button nz-button nzType="primary" (click)="closeModal()">
        <span>Done</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="current == 4">

  <div class="steps-content">

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="6">
        <h1 class="main-title">Posted Successfully!</h1>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="5">
        <p>Do you want us to AutoPost from now on?</p>
      </div>
    </div>
  </div>
  <br>
  <div nz-row>
    <div nz-col nzSpan="4" class="center-close-btn">
      <button nz-button (click)="closeModal()">
        <span>No</span>
      </button>
    </div>
    <div nz-col nzSpan="4" nzOffset="16" class="center-close-btn">
      <button nz-button nzType="primary" (click)="updateSetting()">
        <span>Yes</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="current == 5">

  <div class="steps-content">

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="6">
        <h1 class="main-title">Successfully Posted!</h1>

      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="18" nzOffset="4">
        <p>Click the 'Done' button to close this window.</p>
      </div>
    </div>
  </div>
  <div class="social-connect-posted">
    <div class="status-container" >
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div nz-row>
    <div nz-col nzSpan="4" nzOffset="10" class="center-close-btn">
      <button nz-button nzType="primary" (click)="closeModal()">
        <span>Done</span>
      </button>
    </div>
  </div>
</div>
