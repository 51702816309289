import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Topic } from 'src/app/vos/topic/topic';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
@Injectable()

export class CloudinaryService extends CustomerResourceService<Topic> {

  public endpoint = 'cloudinary';
  public data_key = 'cloudinary';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Topic, http, authService);
  }
  clear_resource_cache(id: any): Observable<any> {
    return this.http
      .post<DataResponse<any>>(
        `${this._uri}${this.endpoint}/clear_resource_cache`,
        JSON.stringify({  public_id: id }),
        { headers: this.headers }
      )
      .pipe(map(resp => resp));
  }
}
