import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BreadCrumService} from '../../services/breadcrum/bread-crum.service';
import {Cloudinary} from '@cloudinary/url-gen';
import {source} from '@cloudinary/url-gen/actions/overlay';
import {subtitles } from '@cloudinary/url-gen/qualifiers/source';
import { TextStyle } from '@cloudinary/url-gen/qualifiers/textStyle';
import * as cloudinary from 'cloudinary-core';

import {TopicsService} from '../../services/topics/topics.service';
import {ActionCableService, Channel} from 'angular2-actioncable';
import {environment} from '../../../environments/environment';
import {UpgradeDialogComponent} from '../../shared/upgrade-dialog/upgrade-dialog.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ProductsService} from '../../services/products/product-api.service';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {CustomersTiktokService} from '../../services/customers-tiktok/customers-tiktok.service';
import {Observable} from 'rxjs';
import {Content} from '../../vos/content/content';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ContentsService} from '../../services/contents/contents.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CloudinaryService} from '../../services/cloudinary/cloudinary.service';
import {UploadResponse} from 'aws-s3-upload-ash/dist/types';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
// import {sha1} from '@angular/compiler/src/i18n/digest';
declare var MediaRecorder: any;
const CryptoJS = require('crypto-js');



@Component({
  selector: 'app-record-video',
  templateUrl: './record-video.component.html',
  styleUrls: ['./record-video.component.scss']
})
export class RecordVideoComponent implements OnInit {
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef | any;
  @ViewChild('video') videoElementRef: ElementRef | any;
  htmlVideoElement: HTMLVideoElement | any;
  recordVideoElement: HTMLVideoElement | any;
  mediaRecorder: any;
  recordedBlobs: Blob[] | any;
  isRecording = false;
  downloadUrl = null;
  stream: MediaStream | any;
  range = [0, 0];
  muted = true;
  start;
  duration;
  cropping = false;
  topics: any;
  currenTopic: any;
  current = 0;
  public_id;
  loading = false;
  tiktokAllowed = false;
  instagramAllowed = false;
  tiktokConnected = false;
  instagramConnected = false;
  customer;
  video_url;
  transcript_file;
  transscript;
  tiktokSelected = false;
  instagramSelected = false;
  parsedSrt;
  cldVideo;
  recordVideo = false;
  caption;
  hashtags;
  constructor(
    private breadcrumService: BreadCrumService,
    private topicsService: TopicsService,
    private cableService: ActionCableService,
    private modalService: NzModalService,
    private productsService: ProductsService,
    private authService: AuthenticationService,
    private customersTiktokService: CustomersTiktokService,
    private message: NzMessageService,
    private contentService: ContentsService,
    private router: Router,
    private http: HttpClient,
    private cloudinaryService: CloudinaryService
  ) { }

  ngOnInit() {
    this.topicsService.list().subscribe(respone => {
      this.topics = respone;
    });
    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
      }
    });
    this.checkCustomerConectedTiktok();
  }
  startRecording() {
    this.muted = true;
    this.downloadUrl = null;
    this.recordedBlobs = [];
    const options: any = { mimeType: 'video/webm;codecs=vp9,opus' };
    this.mediaRecorder = new MediaRecorder(this.stream, options);
    this.start = Date.now();
    this.mediaRecorder.start();
    this.isRecording = !this.isRecording;
    this.onDataAvailableEvent();
    this.onStopRecordingEvent();
  }
  stopRecording() {
    this.loading = false;
    const millis = Date.now() - this.start;
    this.duration = Math.floor(millis / 1000);
    this.range[1] = this.duration;
    this.mediaRecorder.stop();
    this.isRecording = !this.isRecording;
  }
  playRecording() {
    if (!this.recordedBlobs || !this.recordedBlobs.length) {
      console.log('cannot play.');
      return;
    }
    this.muted = false;
    this.recordVideoElement.play();
  }
  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }
  onStopRecordingEvent() {
    this.mediaRecorder.onstop = (event: Event) => {
      const videoBuffer = new Blob(this.recordedBlobs, {
        type: 'video/webm'
      });
      this.downloadUrl = window.URL.createObjectURL(videoBuffer);
      this.recordVideoElement.src = this.downloadUrl;
      console.log(this.downloadUrl);
    };
  }
  async trimVideo() {
    this.cropping = true;
    const  startTime = this.range[0];
    const  endTime = this.range[1];
    const videoBuffer = await new Blob(this.recordedBlobs, {
      type: 'video/webm'
    });
    const buf = await videoBuffer.arrayBuffer();
    const trimmed = await new Promise((res, rej) => {
      const worker = new Worker(this.getWorkerURL());
      worker.onerror = rej;
      // tslint:disable-next-line:only-arrow-functions
      worker.onmessage = function(e) {
        const msg = e.data;
        switch (msg.type) {
          case 'ready':
            worker.postMessage({
              type: 'run',
              arguments: ['-ss', startTime.toFixed(2), '-to', endTime.toFixed(2), '-i', 'in.webm', '-c', 'copy', 'out.webm'],
              MEMFS: [{name: 'in.webm', data: buf}]});
            break;
          case 'done':
            const arr = msg.data.MEMFS?.[0]?.data;
            if (arr) {
              res(arr);
            }
            else { console.log(msg.data); }
            break;
        }
      };
    });
    // @ts-ignore
    const buffer = await new Blob([trimmed], { type: 'video/webm' });
    this.downloadUrl = await window.URL.createObjectURL(buffer);
    this.recordVideoElement.src = this.downloadUrl;
    this.cropping = false;
  }
  // We can't start a Worker from a cross-origin script
  getWorkerURL() {
    return URL.createObjectURL(new Blob([`importScripts("https://cdn.jsdelivr.net/npm/ffmpeg.js@4.2.9003/ffmpeg-worker-webm.js");`], { type: 'text/javascript' }));
  }
   getVideoDuration(e) {
     if ( e.target.duration === Infinity) {
       e.target.currentTime = 1e101;
       e.ontimeupdate = function() {
         this.ontimeupdate = () => { return; };
         this.range[1] = e.target.duration;
       };
     } else {
       this.range[1] = e.target.duration;
     }
       // this.range[1] = e.target.duration;
   }
  // *********** Upload file to Cloudinary ******************** //
   async uploadFile() {
     this.loading = true;
     const cloudName = environment.cloudinary_uploads.cloud_name;
     const unsignedUploadPreset = environment.cloudinary_uploads.unsigned_preset;
     const url = `${environment.cloudinary_uploads.api_url}/${cloudName}/upload`;
     const xhr = new XMLHttpRequest();
     const fd = new FormData();
     xhr.open('POST', url, true);
     xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

     xhr.onreadystatechange = e => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        const response = JSON.parse(xhr.responseText);
        this.listenClodinary(response.public_id);
      }
    };

     const blob = await fetch(this.downloadUrl).then(r => r.blob());
     const  unixtime = Math.round((new Date).getTime() / 1000);
     const file = new File([blob], `${unixtime}`);

     fd.append('upload_preset', unsignedUploadPreset);
     fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
     fd.append('file', file);
     xhr.send(fd);
  }


  // tslint:disable-next-line:variable-name
  listenClodinary(public_id) {
    this.public_id = public_id;
    const channel: Channel = this.cableService.cable(environment.wss_url).channel('CloudinaryChannel',
      {public_id});
    // Subscribe to incoming chats
    channel.connected().subscribe(data => {

    });
    channel.received().subscribe(message => {
      this.clearCacheForCloudinary(this.public_id);
      this.next();
      // this.addGoogleAi(this.public_id);
    });
  }

  addGoogleAi(id){
    this.readTranscript();
    const cld = new Cloudinary({
      cloud: {
        cloudName: environment.cloudinary_uploads.cloud_name
      },
      url: {
        secure: true // force https, set to false to force http
      }
    });
    const video = cld.video(`${id}`).overlay(
      source(
        subtitles(`${id}.srt`)
          .textStyle(new TextStyle('arial', 10))
          .textColor('yellow')
          .backgroundColor('black')
      ));
    this.cldVideo = video;
    console.log(video.toURL());
    this.video_url = video.toURL() + `${Date.now()}`;
    this.muted = false;
    this.loading = false;
    // this.recordVideoElement.src = video.toURL();
    // this.next();

  }
  pre(): void {
    this.current -= 1;
    this.recordVideo = false;
  }

  next(): void {
    // this.public_id = 'sesx6y6ifg3vbxwhoujt';
    // this.clearCacheForCloudinary(this.public_id);
    this.current += 1;
    if (this.current === 1){
    this.initCamera();
  }
  }

  readTranscript(){
    const cloudinaryInstance = cloudinary.Cloudinary.new({
      cloud_name: environment.cloudinary_uploads.cloud_name,
      secure: true,
    });
    const version = `${Date.now()}`;
    const url = cloudinaryInstance.url(`${this.public_id}.srt`, {
      resource_type: 'raw',
      version
    });
    this.transcript_file = url;
    this.http.get(this.transcript_file, {responseType: 'text'}).subscribe(response => {
      this.transscript = response;
      this.parsedSrt = this.parseSRT(this.transscript);
    });
  }

  async saveSubtitles(){
    this.loading = true;
    const cloudName = environment.cloudinary_uploads.cloud_name;
    const unsignedUploadPreset = environment.cloudinary_uploads.srt_signed_preset;
    const url = `${environment.cloudinary_uploads.api_url}/${cloudName}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onreadystatechange = e => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        const response = JSON.parse(xhr.responseText);
        this.clearCacheForCloudinary(this.public_id);
      }
    };
    const str = this.remakeSRT(this.parsedSrt);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/text;'
    });
    const file = new File([blob], `${this.public_id}.srt`);
    // tslint:disable-next-line:no-bitwise
    const  unixtime = Math.round((new Date).getTime() / 1000);
    const sign =  CryptoJS.SHA1(`invalidate=true&overwrite=true&public_id=${this.public_id}&timestamp=${unixtime}&upload_preset=transcript${environment.cloudinary_uploads.secret}`).toString();

    fd.append('upload_preset', unsignedUploadPreset);
    fd.append('file', file);
    fd.append('public_id', this.public_id);
    fd.append('overwrite', 'true');
    fd.append('invalidate', 'true');
    fd.append('signature', sign);
    fd.append('api_key', environment.cloudinary_uploads.api_key);
    fd.append('timestamp', unixtime.toString());
    xhr.send(fd);
  }

  done(): void {
    console.log('done');
  }
  async initCamera(){
    // create audio and video constraints
    const constraintsVideo = {
      audio: false,
      video: {
        width: 450,
        height: 800
      }
    };
    const constraintsAudio = {audio: true};

// create audio and video streams separately
    const audioStream = await navigator.mediaDevices.getUserMedia(constraintsAudio);
    const videoStream = await navigator.mediaDevices.getUserMedia(constraintsVideo);

// combine the streams
    const combinedStream = new MediaStream([...videoStream.getVideoTracks(), ...audioStream.getAudioTracks()]);

    this.htmlVideoElement = this.videoElementRef.nativeElement;
    this.recordVideoElement = this.recordVideoElementRef.nativeElement;
    this.stream = combinedStream;
    this.htmlVideoElement.srcObject = this.stream;
    this.htmlVideoElement.muted = true;
  }
  selectTopic(topic){
    this.currenTopic = topic;
    this.caption = this.currenTopic.caption;
    this.hashtags = this.currenTopic.hashtags;
    this.next();
  }
  upgradePlan() {
    const modal = this.modalService.create({
      nzContent: UpgradeDialogComponent,
      nzFooter: null
    });
  }
  facebookSupport() {
    const modal = this.modalService.create<UpgradeDialogComponent, IModalData>({
      nzContent: UpgradeDialogComponent,
      nzFooter: null,
      nzWidth: 600,
      nzData: {
        facebookSupport: true
      },
    });
  }
  fetchCustomerProducts() {
    this.loading = true;
    const params: Record<string, any> = {
      per_page: 40,
    };

    this.productsService.productList(
      `customers/${this.customer?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const tiktokProduct = data ? data?.find(p => p.abbreviation === 'TT' && p.locked === false) : null;
        const instagramProduct = data ? data?.find(p => p.abbreviation === 'IG' && p.locked === false) : null;
        if (tiktokProduct && tiktokProduct != null) {
          this.tiktokAllowed = true;
          this.tiktokConnected = true;
          this.tiktokSelected = true;
        }
        if (instagramProduct && instagramProduct != null) {
          this.instagramAllowed = true;
          this.instagramConnected = true;
          this.instagramSelected = true;
        }
        this.loading = false;
      }
    }, err => {
      this.loading = false;
    });

  }
  checkCustomerConectedTiktok() {
    this.fetchCustomerProducts();
  }
  submit() {
    const platforms = [];
    if (this.tiktokSelected){
       platforms.push('tiktok');
     }
    if (this.instagramSelected){
      platforms.push('instagram');
    }
    let contentRequest: Observable<Content>;
    const contentPayload: any = {customer_id: this.customer.id, url:
      this.video_url, skip_render: true, status: 'ready', media_type: 'video', platforms:  platforms, category: 'REEL' };
    contentRequest = this.contentService.create(contentPayload);
    contentRequest.subscribe(
      res => {
        if (res) {
          this.message.create(
            'success',
            'Content has successfully been created'
          );
          this.router.navigateByUrl('/');
        }
      },
      err => {
        this.loading = false;
        this.message?.create('error', 'Something went wrong');
      });

  }
  clearCacheForCloudinary(publicId: string) {
    this.loading = true;
    this.readTranscript();
    this.cloudinaryService.clear_resource_cache(publicId).subscribe(resp => {
      console.log(resp);
      this.video_url = resp.result.eager[0].secure_url + `?t=${Date.now()}`;
      this.loading = false;
    });
  }

  generateToken(publicId: string, timestamp: number) {
    const data = `invalidate=true&public_id=${publicId}.transcript&timestamp=${timestamp}${environment.cloudinary_uploads.secret}`;
    const token =  CryptoJS.SHA1(data).toString();
    return token;
  }
  parseSRT(srtText: string): Array<any> {
    const subtitles = [];
    const lines = srtText.trim().split('\n');
    let currentSub = null;
    const index = -1;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (!currentSub) {
        currentSub = {
          index: 0,
          startTime: null,
          endTime: null,
          text: '',
        };
      }

      if (line.trim() === '') {
        if (currentSub.startTime && currentSub.endTime && currentSub.text) {
          subtitles.push(currentSub);
        }
        currentSub = null;
      } else if (currentSub.index === 0) {
        currentSub.index = parseInt(line.trim(), 10);
      } else if (currentSub.startTime === null) {
        const timeParts = line.trim().split(' --> ');
        currentSub.startTime = this.parseTime(timeParts[0]);
        currentSub.endTime = this.parseTime(timeParts[1]);
      } else {
        if (currentSub.text.length > 0) {
          currentSub.text += '\n';
        }
        currentSub.text += line.trim();
      }
    }
    if (currentSub !== null && currentSub.startTime && currentSub.endTime && currentSub.text) {
      subtitles.push(currentSub);
    }
    return subtitles;
  }
  parseTime(timeStr: string): number {
    const parts = timeStr.split(':').map((p) => parseFloat(p.replace(',', '.')));
    return parts[0] * 60 * 60 + parts[1] * 60 + parts[2];
  }
  remakeSRT(subtitles: Array<any>): string {
    let srtText = '';

    for (let i = 0; i < subtitles.length; i++) {
      const sub = subtitles[i];
      srtText += sub.index + '\n';
      srtText += this.formatTime(sub.startTime) + ' --> ' + this.formatTime(sub.endTime) + '\n';
      srtText += sub.text + '\n\n';
    }

    return srtText.trim();
  }

  formatTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time - Math.floor(time)) * 1000);
    return (
      this.padZero(hours, 2) +
      ':' +
      this.padZero(minutes, 2) +
      ':' +
      this.padZero(seconds, 2) +
      ',' +
      this.padZero(milliseconds, 3)
    );
  }

  padZero(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
  uploadCustomFile = async (item: any) => {
    this.downloadUrl = window.URL.createObjectURL(item.file);
    await this.uploadFile();
  }
}

